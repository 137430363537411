import { fieldNameToCapitalize } from 'common/helpers/stringsConverter';
import { AssortmentConfigFieldSlug } from 'graphql/__generated__/types';
import { cloneDeep } from 'lodash-es';
import {
  AssortmentConfig,
  ExtractedFormField,
  FormField,
  SubstitutionProduct
} from './AssortmentPage';
import { AllTpAssortmentsForExportSubstitution } from './components/ExportActiveAssortmentByConfig';

export const SPECIAL_ASSORTMENT_FORM_FIELD: ExtractedFormField = {
  slug: 'SPECIAL_ASSORTMENT',
  name: 'Vendor Flex',
  required: false
};

export const SHIP_TO_FORM_FIELD: ExtractedFormField = {
  slug: 'SHIP_TO',
  name: 'Ship-To',
  required: false
};

export const TRADING_PARTNER_FORM_FIELD: ExtractedFormField = {
  slug: 'TRADING_PARTNER',
  name: 'Trading Partner',
  required: false
};

export const UPDATED_AT_FORM_FIELD: ExtractedFormField = {
  slug: 'UPDATED_AT',
  name: 'Updated At',
  required: false
};

export const SUBSTITUTION_ORDER_FORM_FIELD: ExtractedFormField = {
  slug: 'SUBSTITUTION_ORDER',
  name: 'UPC Priority',
  required: false
};

export const DEFAULT_FORM_FIELDS_ORDER_ON_FORM = [
  'TRADING_PARTNER',
  'SHIP_TO',
  'EXTERNAL_ID',
  'DISTRIBUTION_CENTER_IDS',
  'SPECIAL_ASSORTMENT',
  'UPC',
  'SUBSTITUTION_ORDER',
  'SAP_MATERIAL_ID',
  'NAME',
  'TO_CASE_QUANTITY',
  'GROSS_WEIGHT',
  'CASES_PER_PALLET',
  'WIDTH',
  'HEIGHT',
  'DEPTH',
  'INVENTORY_RESERVE',
  'SHIPS_IN_OWN_CONTAINER',
  'ORACLE_INVEN_ID'
];

export const DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING = [
  'UPC',
  'GTIN14',
  'SAP_MATERIAL_ID',
  'ORACLE_INVEN_ID',
  'EXTERNAL_ID',
  'DISTRIBUTION_CENTER_IDS',
  'SPECIAL_ASSORTMENT',
  'SUBSTITUTION_ORDER',
  'NAME',
  'TO_CASE_QUANTITY',
  'GROSS_WEIGHT',
  'CASES_PER_PALLET',
  'WIDTH',
  'HEIGHT',
  'DEPTH',
  'INVENTORY_RESERVE',
  'SHIPS_IN_OWN_CONTAINER'
];

export const getFieldNameBySlug = (slug: string) => {
  switch (slug) {
    case 'CASES_PER_PALLET':
      return 'palletCount';
    case 'MOQ_UNIT_OF_MEASURE':
      return 'productMoqUnitOfMeasure';
    case 'PRICE':
      return 'productPrice';
    case 'MOQ_MINIMUM':
      return 'productMoqMinimum';
    case 'REQUIRED_ORDER_UNIT_OF_MEASURE':
      return 'productRequiredOrderUnitOfMeasure';
    case 'CURRENCY':
      return 'productCurrency';
    case 'CASES_PER_LAYER':
      return 'productCasesPerLayer';
    case 'LAYERS_PER_PALLET':
      return 'productLayersPerPallet';
    case 'ORACLE_INVEN_ID':
      return 'productOracleInvenId';
    default:
      return fieldNameToCapitalize(slug);
  }
};

export const getShowCountData = (formField: ExtractedFormField | FormField) => {
  if (formField.validation?.rules && formField.validation.rules.length > 0) {
    const rules = [...formField.validation.rules].sort((rule1, rule2) =>
      !rule1.value ? 1 : !rule2.value ? -1 : 0
    );
    // if has at least on counting rule
    return rules.find((rule) => rule.value) ? getStringRules(rules, true) : undefined;
  }
};

export const getStringRules = (
  rules: {
    value?: string | null;
    type: string;
  }[],
  forCountLength?: boolean
) =>
  rules
    .map(
      (rule) =>
        `${
          !rule.value
            ? 'unlimited'
            : rule.value.includes(',')
            ? rule.value.split(',')[0] && rule.value.split(',')[1]
              ? rule.value.replaceAll(',', '-')
              : rule.value.split(',')[0]
              ? `not less than ${rule.value.split(',')[0]}`
              : `not more than ${rule.value.split(',')[1]}`
            : `${rule.value}`
        } ${rule.type.toLowerCase().replaceAll('_', ' ')} characters${
          rule.value?.includes(',') && rule.value.split(',')[0] ? ' and not 0' : ''
        }`
    )
    .join(' or ');

export const getAssortmentItemFieldValueBySlug = (
  slug: string,
  substitutionProduct: SubstitutionProduct | AllTpAssortmentsForExportSubstitution
) => (substitutionProduct as any)[fieldNameToCapitalize(slug)];

export const sorterBySlugs =
  (order: string[]) =>
  (
    s1: {
      slug?:
        | 'SPECIAL_ASSORTMENT'
        | AssortmentConfigFieldSlug
        | 'SHIP_TO'
        | 'TRADING_PARTNER'
        | 'UPDATED_AT'
        | 'SUBSTITUTION_ORDER'
        | string
        | null
        | undefined;
    },
    s2: {
      slug?:
        | 'SPECIAL_ASSORTMENT'
        | AssortmentConfigFieldSlug
        | 'SHIP_TO'
        | 'TRADING_PARTNER'
        | 'UPDATED_AT'
        | 'SUBSTITUTION_ORDER'
        | string
        | null
        | undefined;
    }
  ) => {
    if (!s1.slug) return 1;
    if (!s2.slug) return -1;
    if (!order.includes(s1.slug)) return 1;
    if (!order.includes(s2.slug)) return -1;
    return order.indexOf(s1.slug) - order.indexOf(s2.slug);
  };

export const getItemHeaders = (assortmentConfig: AssortmentConfig, extended?: boolean) => {
  const unsortedHeaders = cloneDeep(assortmentConfig.fields) as ExtractedFormField[];
  if (extended) {
    if (assortmentConfig.specialAssortment) {
      unsortedHeaders.push(SPECIAL_ASSORTMENT_FORM_FIELD);
    }
    unsortedHeaders.push(SHIP_TO_FORM_FIELD);
    unsortedHeaders.push(TRADING_PARTNER_FORM_FIELD);
    unsortedHeaders.push(UPDATED_AT_FORM_FIELD);
    if (assortmentConfig.multipleUpc) {
      unsortedHeaders.push(SUBSTITUTION_ORDER_FORM_FIELD);
    }
  }
  const headers = unsortedHeaders
    .sort(sorterBySlugs(DEFAULT_FORM_FIELDS_ORDER_ON_FORM))
    .map((field) => ({
      label: `${field.name}${field.slug === 'SHIPS_IN_OWN_CONTAINER' ? ' (Y/N)' : ''}${
        field.slug === 'DISTRIBUTION_CENTER_IDS' ? ' (Codes)' : ''
      }${
        field.required || field.validation?.regexp
          ? ` - ${field.required ? 'Required' : ''}${
              field.required && field.validation?.regexp ? ', ' : ''
            }${field.validation?.regexp ? getStringRules(field.validation.rules) : ''}`
          : ''
      }`,
      key: fieldNameToCapitalize(field.slug || '')
    }));
  return headers;
};
