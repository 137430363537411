import React from 'react';
import { Select as AntSelect, SelectProps } from 'ant5';
import { BaseOptionType, DefaultOptionType } from 'ant5/lib/select';
import { OptGroup, Option } from 'rc-select';

export function AlloySelect<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
>({ ...otherProps }: SelectProps<ValueType, OptionType>) {
  return <AntSelect<ValueType, OptionType> {...otherProps} />;
}

AlloySelect.Option = Option;
AlloySelect.OptGroup = OptGroup;

// Re-exporting types
export type { SelectProps };
