import React, { useMemo, useState } from 'react';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTable, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';
import { GetPurchaseOrderOrderDetailsForCustomizationDocument } from './gql/__generated__/getSalesOrderDetails.query';
import { DistributionCenter } from 'graphql/__generated__/types';
import { BulkGenerateAndSendSalesOrdersCustomizationsDocument } from './gql/__generated__/bulkGenerateAndSendCustomizations.mutation';
import { useQuery, useMutation } from '@apollo/client';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import s from './ResendCustomizationsModal.module.scss';
import { Spin } from 'ant5';
import { LoadingOutlined } from '@ant-design/icons';

interface DistributionCenterWithCount extends Partial<DistributionCenter> {
  count: number;
  id: string;
}

interface ResendCustomizationsModalProps {
  purchaseOrderIds: string[];
  open: boolean;
  close: () => void;
}

export const ResendCustomizationsModal = ({
  purchaseOrderIds,
  open,
  close
}: ResendCustomizationsModalProps) => {
  const [selectedDCs, setSelectedDCs] = useState<string[]>([]);
  const [mergeByDc, setMergeByDc] = useState<boolean>(true);
  const { notification } = App.useApp();

  const { data, loading } = useQuery(GetPurchaseOrderOrderDetailsForCustomizationDocument, {
    skip: !open,
    variables: {
      ids: purchaseOrderIds
    }
  });

  let purchaseOrdersWithoutCustomizations = 0;
  const purchaseOrders = useMemo(() => {
    return getNodesFromEdges(data?.purchaseOrders).filter((po) => {
      if (po.salesOrders.find((so) => so.hasCustomizations)) {
        return true;
      } else {
        purchaseOrdersWithoutCustomizations++;
        return false;
      }
    });
  }, [data]);

  const DCs: DistributionCenterWithCount[] = useMemo(() => {
    const dcList = purchaseOrders.reduce((dcList: any[], po) => {
      const dcs: DistributionCenter[] = dcList.concat(
        po.salesOrders.filter((so) => !!so.hasCustomizations).map((so) => so.distributionCenter)
      );
      for (const dc of dcs) {
        const existingDc = dcList.find((dcToCheck) => dcToCheck.id === dc.id);
        if (!existingDc) {
          dcList.push({ ...dc, count: 1 });
        } else {
          existingDc.count++;
        }
      }
      return dcList;
    }, []);

    setSelectedDCs(dcList.map((dc) => dc.id));

    return dcList;
  }, [purchaseOrders]);

  const [resendCustomizations, { loading: requestLoading }] = useMutation(
    BulkGenerateAndSendSalesOrdersCustomizationsDocument
  );

  const onContinue = async () => {
    try {
      await resendCustomizations({
        variables: {
          input: {
            mergeCustomizationsByDc: mergeByDc,
            purchaseOrderIds: purchaseOrders.map((po) => po.id),
            distributionCenterIds: selectedDCs
          }
        }
      });
      notification.success({
        message: `Customizations Resent`,
        description: `Customizations for ${selectedDCs.length} DCs have been regenerated and resent`
      });
      close();
    } catch (error) {
      notification.error({
        message: 'Error resending customizations',
        description: error.message
      });
    }
  };

  const columns: ColumnType<DistributionCenterWithCount>[] = [
    {
      title: 'DC',
      width: 350,
      render: (_, dc) => dc.name
    },
    {
      title: 'Customizations',
      width: 100,
      align: 'center',
      render: (_, dc) => dc.count
    }
  ];

  const content = loading ? (
    <div className={s.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
    </div>
  ) : (
    <>
      {purchaseOrders.length === 0 && (
        <div className={s.no_files}>No customization files for the selected purchase orders.</div>
      )}
      {purchaseOrders.length > 0 && (
        <>
          <div className={s.summary}>
            Of the {purchaseOrderIds.length} purchase orders selected, {purchaseOrders.length}{' '}
            contain customizations for {DCs.length} distribution centers. Please select the DCs you
            would like to regenerate and resend customizations for.
          </div>
          <AlloyTable
            columns={columns}
            dataSource={DCs}
            rowKey={(row) => row.id}
            rowSelection={{
              selections: true,
              hideSelectAll: true,
              selectedRowKeys: selectedDCs,
              onSelect: (dc, selected, selectedRows) => {
                setSelectedDCs(selectedRows.map((dc) => dc.id));
              }
            }}
            pagination={false}
          />
        </>
      )}
    </>
  );

  return (
    <AlloyModal
      width={600}
      title="Regenerate & Resend Customizations"
      onCancel={close}
      open={open}
      footer={
        <div className={s.footer}>
          <div className={s.merge_checkbox}>
            <AlloyCheckbox checked={mergeByDc} onChange={() => setMergeByDc(!mergeByDc)}>
              Merge customization files going to the same DC.
            </AlloyCheckbox>
          </div>
          <div className={s.right}>
            <AlloyButton>Cancel</AlloyButton>
            <AlloyButton
              type="primary"
              onClick={onContinue}
              loading={requestLoading}
              disabled={!purchaseOrders.length || !selectedDCs.length}
            >
              Continue
            </AlloyButton>
          </div>
        </div>
      }
    >
      <div className={s.resend_customization_modal}>{content}</div>
    </AlloyModal>
  );
};
