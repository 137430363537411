import React from 'react';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'common/constants';
import { ParsedQuery } from 'query-string';
import s from './Paginator.module.scss';

interface PaginatorProps {
  pageSize: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  handlePageSizeChange: (value: number) => void;
  prevPage: () => void;
  nextPage: () => void;
  onlyButtons: boolean;
  pageSizeOptions?: number[];
}

export const getPageSize = (location: ParsedQuery<string>, defaultPageSize?: number) =>
  location.limit && !isNaN(Number(location.limit))
    ? Number(location.limit)
    : defaultPageSize || DEFAULT_PAGE_SIZE;

export const Paginator = ({
  prevPage,
  nextPage,
  handlePageSizeChange,
  hasNextPage,
  hasPreviousPage,
  onlyButtons,
  pageSize,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS
}: PaginatorProps) => {
  return (
    <div>
      <AlloyButton
        data-testid="prev-page-btn"
        className={s.pagination_btn}
        onClick={prevPage}
        disabled={!hasPreviousPage}
      >
        {'<'}
      </AlloyButton>
      <AlloyButton
        data-testid="next-page-btn"
        className={s.pagination_btn}
        onClick={nextPage}
        disabled={!hasNextPage}
      >
        {'>'}
      </AlloyButton>
      {!onlyButtons && (
        <AlloySelect
          data-testid="drop-page-size"
          value={pageSize}
          className={s.page_size_select}
          onChange={handlePageSizeChange}
          options={pageSizeOptions.map((value) => ({ value }))}
          popupMatchSelectWidth={false}
        />
      )}
    </div>
  );
};
