import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryRepackWeeklyReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryRepackFilters>;
}>;


export type InventoryRepackWeeklyReportQuery = { __typename?: 'RootQueryType', inventoryRepackWeeklyReport?: { __typename?: 'InventoryRepackWeeklyReport', metrics?: Array<{ __typename?: 'InventoryRepackWeeklyReportMetrics', manualFillRate?: any | null, manualTransportationCostsPerCase?: any | null, period: number, projectedFillRate?: any | null, projectedTransportationCostsPerCase?: any | null, week: number, year: number }> | null } | null };


export const InventoryRepackWeeklyReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryRepackWeeklyReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryRepackFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryRepackWeeklyReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"manualFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"manualTransportationCostsPerCase"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"projectedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"projectedTransportationCostsPerCase"}},{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}}]}}]}}]} as unknown as DocumentNode<InventoryRepackWeeklyReportQuery, InventoryRepackWeeklyReportQueryVariables>;