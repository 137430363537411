import React, { ReactElement } from 'react';
import s from './HighlightedText.module.scss';

interface HighlightedTextProps {
  text: string;
  highlight: string;
  color?: string;
}

const DEFAULT_COLOR = '#FDB515';

export const HighlightedText = ({ text, highlight, color }: HighlightedTextProps) => {
  if (!text || !highlight) return <>{text}</>;
  const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

  const prepareHilightedString = () => {
    const parts = text.split(new RegExp(regEscape(highlight), 'ig'));
    const element = parts.reduce(
      (result: { textToSplit: string; items: ReactElement[] }, part: string, index: number) => {
        const textToHighlight = result.textToSplit.substr(part.length, highlight.length);

        return {
          textToSplit: result.textToSplit.substring(part.length + highlight.length),
          items: [
            ...result.items,
            <span key={index}>{part}</span>,
            <span
              key={`h${index}`}
              className={s.highlighted}
              style={{ backgroundColor: color || DEFAULT_COLOR }}
            >
              {textToHighlight}
            </span>
          ]
        };
      },
      { textToSplit: String(text), items: [] as ReactElement[] }
    );

    const itemsToShow = element.items;
    return <>{itemsToShow}</>;
  };

  return prepareHilightedString();
};
