import React from 'react';
import { Table as AntTable } from 'ant5';
import type { TableProps, ColumnType, ColumnsType } from 'ant5/es/table';
import type { SorterResult, FilterValue, TablePaginationConfig } from 'ant5/es/table/interface';
import clsx from 'clsx';
import s from './AlloyTable.module.scss';

// Taken from ant design implements
type AnyObject = Record<PropertyKey, any>;
type AlloyTableProps<T extends AnyObject> = Omit<TableProps<T>, 'bordered'> & {
  bordered?: boolean | 'outer-only';
};

export function AlloyTable<T extends AnyObject>({
  onHeaderRow: userOnHeaderRow,
  rowClassName: userRowClassName,
  className: userClassName,
  bordered: userBordered = 'outer-only',
  ...otherProps
}: AlloyTableProps<T>) {
  const onHeaderRow: NonNullable<TableProps<T>['onHeaderRow']> = React.useCallback(
    (columns, index) => {
      const userHeaderProps = userOnHeaderRow ? userOnHeaderRow(columns, index) : {};
      return {
        ...userHeaderProps,
        className: clsx(s.header, userHeaderProps.className)
      };
    },
    [userOnHeaderRow]
  );

  const rowClassName: NonNullable<TableProps<T>['rowClassName']> = React.useCallback(
    (columns, index, indent) => {
      const userRowClassNameString =
        typeof userRowClassName === 'function'
          ? userRowClassName(columns, index, indent)
          : userRowClassName;
      return clsx(s.row, userRowClassNameString);
    },
    [userRowClassName]
  );

  const bordered = typeof userBordered === 'boolean' ? userBordered : false;
  return (
    <AntTable<T>
      {...otherProps}
      onHeaderRow={onHeaderRow}
      bordered={bordered}
      className={clsx({ [s.outerBorder]: userBordered === 'outer-only' }, userClassName)}
      rowClassName={rowClassName}
    />
  );
}

AlloyTable.SELECTION_COLUMN = AntTable.SELECTION_COLUMN;
AlloyTable.Summary = AntTable.Summary;
AlloyTable.EXPAND_COLUMN = AntTable.EXPAND_COLUMN;

// Re-exporting types
export type {
  ColumnType,
  ColumnsType,
  SorterResult,
  FilterValue,
  TablePaginationConfig,
  AnyObject,
  AlloyTableProps
};
