import React, { useMemo } from 'react';
import s from './NotCuttingModal.module.scss';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { FieldArray } from 'react-final-form-arrays';
import { Form } from 'react-final-form';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import arrayMutators from 'final-form-arrays';
import { useWindowSize } from '@reactuses/core';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import { CutsItem } from '../CutsTable/CutsTable';
import { useMutation } from '@apollo/client';
import { MoveRepackCutsToFulfillDocument } from './gql/__generated__/moveRepackCutsToFulfill.mutation';
import { App } from 'ant5';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { parseToInt } from 'pages/RepackPlanning/helpers';
import { RepackOptimizerStage } from 'graphql/__generated__/types';

interface NotCuttingForm {
  changes: Change[];
  reason?: string;
  comment?: string | null;
  plant?: 'Memphis' | 'Grand Prairie' | 'Hamburg';
}

interface Change {
  title: string;
  itemId: string;
  id: string;
  quantity: number;
}

export const NotCuttingModal = ({
  items,
  isModalVisible,
  setIsModalVisible,
  resetSelection,
  stage
}: {
  items: CutsItem[];
  isModalVisible: boolean;
  setIsModalVisible: (shown: boolean) => void;
  resetSelection: () => void;
  stage: RepackOptimizerStage;
}) => {
  const { message } = App.useApp();

  const [mutate, { loading }] = useMutation(MoveRepackCutsToFulfillDocument, {
    onCompleted: () => {
      message.success(`Successfully moved items to production`);
      resetSelection();
      setIsModalVisible(false);
    },
    onError: (error) => {
      message.error(`Could not move items to production: ${error}`);
    },
    refetchQueries: [
      'inventoryRepackFulfillReport',
      'inventoryRepackCutsReport',
      'inventoryRepackWeeklyTotalsReport'
    ]
  });

  const { height } = useWindowSize();

  // Some magic numbers
  const tableHeight = Math.max(height - 80 - 70 - 190 - 140 - 75, 100);

  const initialValues: NotCuttingForm = useMemo(
    () => ({
      reason: undefined,
      comment: null,
      plant: undefined,
      changes: (items || []).map((x) => ({
        title: x.productTitle || '',
        id: x.id,
        itemId: x.itemId,
        quantity: x.cutQty || 0
      }))
    }),
    [items]
  );

  const onSubmit = async (values: NotCuttingForm) => {
    await mutate({
      variables: {
        input: {
          reason: values.reason || '',
          // There's a bug on BE, we need to send null
          comment: values.comment || null,
          changes: values.changes
            .map((change) => ({
              howMany: parseToInt(change.quantity),
              id: change.id,
              plant: values.plant || ''
            }))
            .filter((x) => x.howMany),
          stage
        }
      }
    });
  };

  const columns: ColumnsType<Change> = [
    {
      title: 'Item ID',
      render: (_, { itemId }) => <span className={s.number}>{itemId}</span>,
      width: 90
    },
    {
      title: 'Product Title',
      dataIndex: 'title',
      ellipsis: true,
      sorter: (a, b) => safeLocaleCompare(a.title, b.title)
    },
    {
      title: 'QTY',
      render: (_, _record, index) => (
        <FinalFormInput
          name={`changes[${index}].quantity`}
          title=""
          type="number"
          min={0}
          hideValidation
          validate={(value) => {
            const parsedValue = parseFloat(value);

            // Usual required doesn't work because it breaks on 0
            if (value === '' || value === null || value === undefined) {
              return 'Should not be empty';
            }

            if (!Number.isInteger(parsedValue)) {
              return "Can't be fractional";
            }

            if (parsedValue && parsedValue < 0) {
              return `Can't be less than 0 `;
            }

            if (parsedValue > (items[index]?.cutQty || 0)) {
              return `Can't exceed ${items[index]?.cutQty}`;
            }

            return undefined;
          }}
        />
      ),
      width: 160
    }
  ];

  return (
    <Form<NotCuttingForm>
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, form }) => (
        <AlloyModal
          open={isModalVisible}
          title="Not Cutting Confirmation"
          afterOpenChange={(visible) => {
            // Doing it only on "visible" to avoid visual glitch
            if (visible) {
              form.restart();
            }
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          okText="Not Cut"
          okButtonProps={{
            onClick: () => handleSubmit(),
            disabled: form.getState().invalid || loading
          }}
          width={530}
        >
          <AlloySpin spinning={loading} className={s.window}>
            <p>Do you want to move this qty. to production?</p>
            <form onSubmit={handleSubmit}>
              <FieldArray name="changes">
                {({ fields }) => (
                  <>
                    <AlloyTable
                      className={s.table}
                      dataSource={fields.value}
                      columns={columns}
                      rowKey="id"
                      pagination={false}
                      bordered={false}
                      size="small"
                      scroll={{ y: tableHeight }}
                    />
                  </>
                )}
              </FieldArray>
              <FinalFormSelect
                selectProps={{ placeholder: 'Select Option' }}
                name="plant"
                title="Select a plant"
                required
                options={[
                  { label: 'Memphis', value: 'Memphis' },
                  { label: 'Grand Prairie', value: 'Grand Prairie' },
                  { label: 'Hamburg', value: 'Hamburg' }
                ]}
              />
              <FinalFormSelect
                selectProps={{ placeholder: 'Select Option' }}
                name="reason"
                title="Reason"
                required
                options={[
                  { label: 'Due-in Opportunity', value: 'Due-in Opportunity' },
                  { label: 'Non-produced', value: 'Non-produced' },
                  { label: 'BOM/Inventory Discrepancy', value: 'BOM/Inventory Discrepancy' }
                ]}
              />
              {/* TODO: use src/components/ui/formFields/AlloyFormField/AlloyFormField.tsx */}
              <FinalFormInput name="comment" title="Add a comment (optional)" textarea />
            </form>
          </AlloySpin>
        </AlloyModal>
      )}
    />
  );
};
