import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DistributionCentersAdvancedFiltersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.DistributionCenterFilters>;
}>;


export type DistributionCentersAdvancedFiltersQuery = { __typename?: 'RootQueryType', distributionCenters?: { __typename?: 'DistributionCenterConnection', edges: Array<{ __typename?: 'DistributionCenterEdge', node?: { __typename?: 'DistributionCenter', id: string, code: string, name: string, externalId: string } | null }> } | null };


export const DistributionCentersAdvancedFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"distributionCentersAdvancedFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DistributionCenterFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"distributionCenters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"100000"}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]}}]}}]} as unknown as DocumentNode<DistributionCentersAdvancedFiltersQuery, DistributionCentersAdvancedFiltersQueryVariables>;