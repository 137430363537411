import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FeFeatureFlagsQueryVariables = Types.Exact<{
  flags: Array<Types.InputMaybe<Types.FeFeatureFlagInput>> | Types.InputMaybe<Types.FeFeatureFlagInput>;
}>;


export type FeFeatureFlagsQuery = { __typename?: 'RootQueryType', feFeatureFlags: Array<{ __typename?: 'FeFeatureFlag', enabled: boolean, name: string }> };


export const FeFeatureFlagsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"feFeatureFlags"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"flags"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FeFeatureFlagInput"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"feFeatureFlags"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"flags"},"value":{"kind":"Variable","name":{"kind":"Name","value":"flags"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<FeFeatureFlagsQuery, FeFeatureFlagsQueryVariables>;