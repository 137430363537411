import { useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { getPageSize } from 'components/Paginator/Paginator';
import { RddSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import {
  FullDataRetailerDeliveryDestinationsDocument,
  FullDataRetailerDeliveryDestinationsQuery
} from 'pages/ShipToPage/gql/__generated__/rddFullData.query';
import { dataToRows, itemHeaders } from 'pages/ShipToPage/helper';
import { parse } from 'query-string';
import React, { useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { searchStringToQueryFilter } from '../../AdvancedFilters/AdvancedFilters';
import { VALIDATION_RULES_ROW } from '../BulkAddStoreModal';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { useMemo } from 'react';

export type DeliveryDestination = InferNodeType<
  FullDataRetailerDeliveryDestinationsQuery,
  'retailerDeliveryDestinations'
>;

export const DownloadCurrentShipTos = () => {
  const history = useHistory();
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const [loadFullStoresData, { data, loading }] = useLazyQuery(
    FullDataRetailerDeliveryDestinationsDocument,
    {}
  );

  const fileName = useMemo(() => {
    const rows = getNodesFromEdges(data?.retailerDeliveryDestinations);
    return `${
      rows && rows.length > 0 && rows[0]?.vendorMarket?.externalId
        ? rows[0].vendorMarket.externalId.toLowerCase() + '_'
        : ''
    }stores.csv`;
  }, [data]);

  return (
    <Spin spinning={loading}>
      <div
        className="download_link"
        onClick={async () => {
          const location = parse(history.location.search);
          const search = typeof location.search === 'string' ? location.search : '';
          const { filterData, advancedFilterData } = searchStringToQueryFilter(
            history.location.search
          );
          await loadFullStoresData({
            variables: {
              first:
                location.after || !location.before
                  ? getPageSize(location, DEFAULT_PAGE_SIZE)
                  : null,
              last:
                !location.after && location.before
                  ? getPageSize(location, DEFAULT_PAGE_SIZE)
                  : null,
              after: location.after
                ? typeof location.after === 'string'
                  ? location.after
                  : location.after[0]
                : null,
              before:
                location.before && !location.after
                  ? typeof location.before === 'string'
                    ? location.before
                    : location.before[0]
                  : null,
              sort: {
                column: (location.column as RddSortColumn) || 'VENDOR_MARKET_NAME',
                direction: (location.order as SortOrderDirection) || 'ASC'
              },
              searchLike: search,
              filter: filterData,
              advancedFilter: advancedFilterData
            }
          });
          csvLinkRef?.current?.link.click();
        }}
      >
        Download an export of the current table
      </div>
      <CSVLink
        ref={csvLinkRef}
        filename={fileName}
        headers={itemHeaders}
        data={[
          VALIDATION_RULES_ROW,
          ...dataToRows(getNodesFromEdges(data?.retailerDeliveryDestinations))
        ]}
      />
    </Spin>
  );
};
