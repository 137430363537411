import { useMutation, useQuery } from '@apollo/client';
import { Button, Popover, Spin, Table, Input, Row, Col, Tooltip, message } from 'antd';
import { ProductsFillRateFilters } from 'graphql/__generated__/types';
import { upperFirst, sumBy, uniqBy } from 'lodash-es';
import React, { useEffect, useState, useRef, LegacyRef, useContext, useMemo } from 'react';
import s from './ProductsViewTable.module.scss';
import { DeleteFilled, RightOutlined } from '@ant-design/icons';
import commentAddIcon from 'assets/icons/comment_add_icon.svg';
import commentAddedIcon from 'assets/icons/comment_added_icon.svg';
import { EMPTY } from 'common/constants';
import { ColumnsWithExportRender, removeExportConfig } from 'common/helpers/tableExport';
import { dateFormat, setDateToNullIf1900 } from 'common/helpers/date';
import moment from 'moment';
import { MultipleOrdersByProductFillRateReportDocument } from 'pages/Visibility/gql/__generated__/multipleOrdersByProductFillRateReport.query';
import { UpsertProductFillRateCommentDocument } from '../../gql/__generated__/upsertProductFillRateComment.mutation';
import { safeNumberComparator } from 'common/helpers/comparators';
import currency from 'currency.js';
import {
  ProductDetails,
  SapOrderDetailsEnhanced,
  mapOrderDetailsToOrderDetailsEnhanced
} from './mapper';
const { TextArea } = Input;
import { ProductViewContext } from './ProductViewContext';
import { useUrlParams } from 'common/helpers/urlParams';
import { SortOrder } from 'antd/lib/table/interface';

const EmptyCell = () => {};

const isDateAfterTarget = (
  date: string | null | undefined,
  targetDate: string | null | undefined
): boolean => (date && targetDate ? moment(date).isAfter(moment(targetDate)) : false);

const CommentsPopover = ({
  comment,
  salesOrderId,
  vendorProductId,
  purchaseOrderId,
  commentId,
  refetch
}: {
  comment: string | null | undefined;
  salesOrderId: number | null | undefined;
  vendorProductId: number;
  purchaseOrderId: number;
  commentId: number | null | undefined;
  refetch: () => void;
}) => {
  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const [commentText, setCommentText] = useState(comment || '');
  const [upsertProductComment, { loading: upserting }] = useMutation(
    UpsertProductFillRateCommentDocument,
    {
      onCompleted: (data) => {
        const updatedComment = data.upsertProductFillRateComment?.comment || '';
        updatedComment ? message.success('Comment updated') : message.success('Comment removed');
        refetch();
        handleVisibleChange(false);
      },
      onError: (error) => {
        console.error(error.message);
        message.error(`Error happened during comment saving: ${error.message}`);
      }
    }
  );

  const saveComment = () => {
    upsertProductComment({
      variables: {
        input: {
          comment: commentText,
          id: commentId,
          purchaseOrderId,
          salesOrderId,
          vendorProductId
        }
      }
    });
  };

  const removeComment = () => {
    upsertProductComment({
      variables: {
        input: {
          comment: '',
          id: commentId,
          purchaseOrderId,
          salesOrderId,
          vendorProductId
        }
      }
    });
  };

  const handleVisibleChange = (visibility: boolean) => {
    setCommentText(comment || '');
    setPopoverVisibility(visibility);
  };

  const popoverContent = (
    <>
      <TextArea
        disabled={upserting}
        rows={6}
        value={commentText}
        placeholder="Here is a comment"
        style={{ minWidth: '200px', height: 120, resize: 'none' }}
        onChange={(e) => setCommentText(e.currentTarget.value)}
      />
      <Row style={{ marginTop: '15px' }} gutter={6} justify="space-between" align="middle">
        <Col span={6}>
          <DeleteFilled disabled={upserting} style={{ fontSize: '22px' }} onClick={removeComment} />
        </Col>
        <Col span={18}>
          <Row justify="space-between" align="middle">
            <Button onClick={() => handleVisibleChange(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={saveComment}
              disabled={commentText.length < 1 || upserting}
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
  return (
    <Popover
      overlayClassName={s.comment_popover}
      placement="left"
      content={popoverContent}
      open={popoverVisibility}
      onOpenChange={handleVisibleChange}
      trigger="click"
    >
      <Button type="text" size="small">
        {comment ? (
          <img src={commentAddIcon} alt="Add comment" />
        ) : (
          <img src={commentAddedIcon} alt="View comment" />
        )}
      </Button>
    </Popover>
  );
};

export const sapColumnsWithCsv: ColumnsWithExportRender<SapOrderDetailsEnhanced> = [
  {
    title: 'PO',
    key: 'po',
    width: 110,
    render: EmptyCell
  },
  {
    title: 'SAP Order Number',
    key: 'sap',
    width: 110,
    exportConfig: {
      render: ({ alternateSoId }) => alternateSoId || EMPTY
    },
    render: (_, { alternateSoId }) => alternateSoId || EMPTY
  },
  {
    title: 'Customer Original Qty (CS)',
    width: 100,
    align: 'right',
    key: 'customerOriginalQuantity',
    render: EmptyCell
  },
  {
    title: 'Customer Accepted Qty (CS)',
    width: 100,
    align: 'right',
    key: 'customerAcceptedQuantity',
    render: EmptyCell
  },
  {
    title: 'SAP Accepted Qty (CS)',
    width: 100,
    align: 'right',
    key: 'sapOrderQty',
    exportConfig: {
      render: ({ sapAcceptedQuantity }) => sapAcceptedQuantity ?? EMPTY
    },
    render: (_, { sapAcceptedQuantity }) => sapAcceptedQuantity ?? EMPTY
  },
  {
    title: 'Cuts',
    width: 100,
    align: 'right',
    key: 'cuts',
    exportConfig: {
      render: ({ cuts }) => cuts ?? EMPTY
    },
    render: (_, { cuts }) => cuts ?? EMPTY
  },
  {
    title: 'Ship Qty',
    width: 100,
    align: 'right',
    key: 'shipQty',
    exportConfig: {
      render: ({ shippedQuantity }) => shippedQuantity ?? EMPTY
    },
    render: (_, { shippedQuantity }) => shippedQuantity ?? EMPTY
  },
  {
    title: 'Remaining To Ship',
    width: 100,
    align: 'right',
    key: 'remainingToShipQty',
    exportConfig: {
      render: ({ remainingToShip }) => remainingToShip ?? EMPTY
    },
    render: (_, { remainingToShip }) => (
      <span style={{ fontWeight: 'bold' }}>{remainingToShip ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.remainingToShip, b.remainingToShip)
  },
  {
    title: 'Net Variance',
    width: 100,
    align: 'right',
    key: 'netVariance',
    render: EmptyCell
  },
  {
    title: 'Submitted Fill-Rate',
    width: 100,
    key: 'submittedFillRate',
    render: EmptyCell
  },
  {
    title: 'Accepted Fill-Rate',
    width: 100,
    key: 'acceptedFillRate',
    render: EmptyCell
  },
  {
    title: 'Planned Ship Date (SAP)',
    width: 100,
    key: 'plannedShipDate',
    align: 'right',
    exportConfig: {
      render: ({ plannedShipDate }) => dateFormat(setDateToNullIf1900(plannedShipDate)) || EMPTY
    },
    render: (_, { plannedShipDate }) => dateFormat(setDateToNullIf1900(plannedShipDate)) || EMPTY
  },
  {
    title: 'Actual Ship Date (SAP)',
    width: 100,
    key: 'actualShipDate',
    align: 'right',
    exportConfig: {
      render: ({ actualShipDate }) => dateFormat(setDateToNullIf1900(actualShipDate)) || EMPTY
    },
    render: (_, { actualShipDate }) => dateFormat(setDateToNullIf1900(actualShipDate)) || EMPTY
  },
  {
    title: 'Req Delivery Date (SAP)',
    width: 100,
    key: 'requestedDeliveryDate',
    align: 'right',
    exportConfig: {
      render: ({ requestedDeliveryDate }) =>
        dateFormat(setDateToNullIf1900(requestedDeliveryDate)) || EMPTY
    },
    render: (_, { requestedDeliveryDate }) =>
      dateFormat(setDateToNullIf1900(requestedDeliveryDate)) || EMPTY
  },
  {
    title: 'Window End Date (Customer)',
    width: 100,
    key: 'windowEndDate',
    align: 'right',
    exportConfig: {
      render: ({ windowEndDate }) => dateFormat(setDateToNullIf1900(windowEndDate)) || EMPTY
    },
    render: (_, { windowEndDate }) => dateFormat(setDateToNullIf1900(windowEndDate)) || EMPTY
  },
  {
    title: 'Window End Alert',
    width: 65,
    key: 'windowEndAlert',
    align: 'right',
    exportConfig: {
      render: ({ requestedDeliveryDate, windowEndDate }) => {
        if (!requestedDeliveryDate || !windowEndDate) return 0;
        const diff = moment(windowEndDate).diff(moment(requestedDeliveryDate), 'd');

        return diff < 0 ? Math.abs(diff) : 0;
      }
    },
    render: (_, { requestedDeliveryDate, windowEndDate }) => {
      if (!requestedDeliveryDate || !windowEndDate) return null;
      const diff = moment(windowEndDate).diff(moment(requestedDeliveryDate), 'd');
      const isGreaterThan = Math.abs(diff) > 20;

      return (
        <Tooltip title="RDD > Window End Date" placement="right">
          {isGreaterThan && (
            <div className={s.window_end_greater_than}>
              <RightOutlined />
            </div>
          )}
          <span>
            {diff < 0 ? <div className={s.alert}>{isGreaterThan ? 20 : Math.abs(diff)}</div> : null}
          </span>
        </Tooltip>
      );
    }
  },
  {
    title: 'Vendor Code',
    width: 100,
    key: 'vendorCode',
    render: EmptyCell
  },
  {
    title: 'BU',
    width: 100,
    key: 'bu',
    render: EmptyCell
  },
  {
    title: 'Ship From',
    width: 100,
    key: 'shipFrom',
    exportConfig: {
      render: ({ dcCode }) => dcCode || EMPTY
    },
    render: (_, { dcCode }) => dcCode || EMPTY
  },
  {
    title: 'Sold To Number',
    width: 100,
    key: 'soldTo',
    exportConfig: {
      render: ({ soldToNumber }) => soldToNumber || EMPTY
    },
    render: (_, { soldToNumber }) => soldToNumber || EMPTY
  },
  {
    title: 'Ship-To Name',
    width: 170,
    key: 'shipTo',
    exportConfig: {
      render: ({ shipToName }) => shipToName || EMPTY
    },
    render: (_, { shipToName }) => shipToName || EMPTY
  },
  {
    title: 'Order Date',
    width: 100,
    key: 'orderDate',
    align: 'right',
    render: EmptyCell
  },
  {
    title: 'Gross Value',
    width: 145,
    align: 'right',
    key: 'grossValue',
    render: (_, { grossValue }) =>
      grossValue === null
        ? EMPTY
        : currency(grossValue, { precision: 3 }).format({ pattern: '! #' }),
    exportConfig: {
      render: ({ grossValue }) =>
        grossValue === null ? EMPTY : currency(grossValue, { precision: 3 }).format()
    }
  },
  {
    title: 'Customer Name',
    width: 160,
    key: 'customerName',
    render: (_, { customerName }) => upperFirst(customerName || EMPTY),
    exportConfig: {
      render: ({ customerName }) => upperFirst(customerName || EMPTY)
    }
  },
  {
    title: 'Comments',
    width: 75,
    key: 'comments',
    exportConfig: {
      render: ({ comment }) => comment || EMPTY
    },
    render: (_, { comment, salesOrderId, purchaseOrderId, productId, commentId, refetch }) =>
      refetch && (
        <CommentsPopover
          comment={comment}
          commentId={commentId}
          vendorProductId={productId}
          purchaseOrderId={purchaseOrderId}
          salesOrderId={salesOrderId}
          refetch={refetch}
        />
      )
  }
];

export const poColumnsWithCsv: ColumnsWithExportRender<ProductDetails> = [
  {
    title: 'PO',
    key: 'po',
    width: 110,
    exportConfig: {
      render: ({ customerPo }) => customerPo || EMPTY,
      modes: ['NO_TOTALS']
    },
    render: (_, { customerPo }) => customerPo || EMPTY,
    sorter: (a, b) => (a?.customerPo || '').localeCompare(b?.customerPo || '')
  },
  {
    title: 'SAP Order Number',
    key: 'sap',
    width: 110,
    render: EmptyCell
  },
  {
    title: 'Customer Original Qty (CS)',
    width: 100,
    align: 'right',
    key: 'customerOriginalQuantity',
    exportConfig: {
      render: ({ customerOriginalQuantity }) => customerOriginalQuantity ?? EMPTY
    },
    render: (_, { customerOriginalQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{customerOriginalQuantity ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.customerOriginalQuantity, b.customerOriginalQuantity)
  },
  {
    title: 'Customer Accepted Qty (CS)',
    width: 100,
    align: 'right',
    key: 'customerAcceptedQuantity',
    exportConfig: {
      render: ({ customerAcceptedQuantity }) => customerAcceptedQuantity ?? EMPTY
    },
    render: (_, { customerAcceptedQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{customerAcceptedQuantity ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.customerAcceptedQuantity, b.customerAcceptedQuantity)
  },
  {
    title: 'SAP Accepted Qty (CS)',
    width: 100,
    align: 'right',
    key: 'sapOrderQty',
    exportConfig: {
      title: 'Total SAP Accepted Qty (CS)',
      render: ({ sapAcceptedQuantity }) => sapAcceptedQuantity ?? EMPTY
    },
    render: (_, { sapAcceptedQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{sapAcceptedQuantity ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.sapAcceptedQuantity, b.sapAcceptedQuantity)
  },
  {
    title: 'Cuts',
    width: 100,
    align: 'right',
    showSorterTooltip: {
      title: 'SAP Accepted Qty - Ship Qty',
      placement: 'bottom'
    },
    key: 'cuts',
    exportConfig: {
      title: 'Total Cuts',
      render: ({ cuts }) => cuts ?? EMPTY
    },
    render: (_, { cuts }) => <span style={{ fontWeight: 'bold' }}>{cuts ?? EMPTY}</span>,
    sorter: (a, b) => safeNumberComparator(a.cuts, b.cuts || 0)
  },
  {
    title: 'Ship Qty',
    width: 100,
    align: 'right',
    key: 'shipQty',
    exportConfig: {
      title: 'Total Ship Qty',
      render: ({ shippedQuantity }) => shippedQuantity ?? EMPTY
    },
    render: (_, { shippedQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{shippedQuantity ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.shippedQuantity, b.shippedQuantity)
  },
  {
    title: 'Remaining To Ship',
    width: 100,
    align: 'right',
    key: 'remainingToShipQty',
    showSorterTooltip: {
      title: 'Zero if Ship Qty exists otherwise SAP Accepted Qty',
      placement: 'bottom'
    },
    exportConfig: {
      title: 'Remaining To Ship (PO Total)',
      render: ({ remainingToShip }) => remainingToShip ?? EMPTY
    },
    render: (_, { remainingToShip }) => (
      <span style={{ fontWeight: 'bold' }}>{remainingToShip ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.remainingToShip, b.remainingToShip)
  },
  {
    title: 'Net Variance',
    width: 100,
    align: 'right',
    key: 'netVariance',
    showSorterTooltip: {
      title: 'Customer Accepted Qty - (Ship Qty or SAP Order Qty)',
      placement: 'bottom'
    },
    exportConfig: {
      render: ({ netVariance }) => netVariance ?? EMPTY
    },
    render: (_, { netVariance }) => (
      <span style={{ fontWeight: 'bold' }}>{netVariance ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.netVariance, b.netVariance)
  },
  {
    title: 'Submitted Fill-Rate',
    width: 100,
    key: 'submittedFillRate',
    align: 'right',
    showSorterTooltip: {
      title: 'Ship Qty / Customer Original Qty',
      placement: 'bottom'
    },
    exportConfig: {
      render: ({ submittedFillRate }) => submittedFillRate ?? EMPTY,
      modes: ['NO_TOTALS']
    },
    render: (_, { submittedFillRate }) => (submittedFillRate ? submittedFillRate + '%' : EMPTY),
    sorter: (a, b) => safeNumberComparator(a.submittedFillRate, b.submittedFillRate)
  },
  {
    title: 'Accepted Fill-Rate',
    width: 100,
    key: 'acceptedFillRate',
    align: 'right',
    showSorterTooltip: {
      title: 'Ship Qty / Customer Accepted Qty',
      placement: 'bottom'
    },
    exportConfig: {
      render: ({ acceptedFillRate }) => acceptedFillRate ?? EMPTY,
      modes: ['NO_TOTALS']
    },
    render: (_, { acceptedFillRate }) => (acceptedFillRate ? acceptedFillRate + '%' : EMPTY),
    sorter: (a, b) => safeNumberComparator(a.acceptedFillRate, b.acceptedFillRate)
  },
  {
    title: 'Planned Ship Date (SAP)',
    width: 100,
    key: 'plannedShipDate',
    align: 'right',
    render: EmptyCell
  },
  {
    title: 'Actual Ship Date (SAP)',
    width: 100,
    key: 'actualShipDate',
    align: 'right',
    render: EmptyCell
  },
  {
    title: 'Req Delivery Date (SAP)',
    width: 100,
    key: 'requestedDeliveryDate',
    align: 'right',
    render: EmptyCell
  },
  {
    title: 'Window End Date (Customer)',
    width: 100,
    key: 'windowEndDate',
    align: 'right',
    render: EmptyCell
  },
  {
    title: 'Window End Alert',
    width: 65,
    align: 'right',
    key: 'windowEndAlert',
    exportConfig: {
      title: 'Window End Alert (PO Total)',
      render: ({ orders }) =>
        (orders || [])?.filter(
          (x) =>
            x &&
            isDateAfterTarget(
              setDateToNullIf1900(x?.requestedDeliveryDate),
              setDateToNullIf1900(x?.windowEndDate)
            )
        ).length || 0
    },
    render: (_, { orders }) => (
      <Tooltip title="RDD > Window End Date" placement="right">
        <span style={{ fontWeight: 'bold' }}>
          {(orders || [])?.filter(
            (x) =>
              x &&
              isDateAfterTarget(
                setDateToNullIf1900(x?.requestedDeliveryDate),
                setDateToNullIf1900(x?.windowEndDate)
              )
          ).length || 0}
        </span>
      </Tooltip>
    )
  },
  {
    title: 'Vendor Code',
    width: 100,
    key: 'vendorCode',
    exportConfig: {
      render: ({ vendorCode }) => vendorCode || EMPTY,
      modes: ['NO_TOTALS']
    },
    render: (_, { vendorCode }) => vendorCode || EMPTY,
    sorter: (a, b) => (a?.vendorCode || '').localeCompare(b?.vendorCode || '')
  },
  {
    title: 'BU',
    width: 100,
    key: 'bu',
    exportConfig: {
      render: ({ businessUnitName }) => businessUnitName || EMPTY,
      modes: ['NO_TOTALS']
    },
    render: (_, { businessUnitName }) => businessUnitName || EMPTY,
    sorter: (a, b) => (a?.businessUnitName || '').localeCompare(b?.businessUnitName || '')
  },
  {
    title: 'Ship From',
    width: 100,
    key: 'shipFrom',
    render: EmptyCell
  },
  {
    title: 'Sold To Number',
    width: 100,
    key: 'soldTo',
    render: EmptyCell
  },
  {
    title: 'Ship-To Name',
    width: 170,
    key: 'shipTo',
    render: EmptyCell
  },
  {
    title: 'Order Date',
    width: 100,
    key: 'orderDate',
    align: 'right',
    exportConfig: {
      render: ({ orderDate }) => dateFormat(setDateToNullIf1900(orderDate)) || EMPTY,
      modes: ['NO_TOTALS']
    },
    render: (_, { orderDate }) => dateFormat(setDateToNullIf1900(orderDate)) || EMPTY
  },
  {
    title: 'Gross Value',
    width: 145,
    align: 'right',
    key: 'grossValue',
    render: EmptyCell
  },
  {
    title: 'Customer Name',
    width: 160,
    key: 'customerName',
    render: EmptyCell
  },
  {
    title: 'Comments',
    width: 75,
    key: 'comments',
    render: EmptyCell
  }
];

const poColumns = removeExportConfig(poColumnsWithCsv);
const sapColumns = removeExportConfig(sapColumnsWithCsv);

export const ProductDetailsTable = ({
  productId,
  filters,
  onReady
}: {
  productId: number;
  filters: ProductsFillRateFilters;
  onReady?: () => void;
}) => {
  const viewContext = useContext(ProductViewContext);
  const [urlParams, setUrlParams] = useUrlParams();
  const [sort, setSort] = useState<{ column: string; direction: string }>({
    column: (urlParams.poColumn as string) ?? 'acceptedFillRate',
    direction: (urlParams.poDirection as string) ?? 'ascend'
  });
  const container = useRef<HTMLDivElement>();
  const tableWrapper = useRef<HTMLDivElement>();
  const componentWrapper = useRef<HTMLDivElement>();

  //The columns are exported, but also need access to component data for the sortOrder key. Originally I added sortOrder to the column objects programmatically, but it was too slow to render on state change.
  const poColumnsWithSort: ColumnsWithExportRender<ProductDetails> = [
    {
      title: 'PO',
      key: 'po',
      width: 110,
      exportConfig: {
        render: ({ customerPo }) => customerPo || EMPTY,
        modes: ['NO_TOTALS']
      },
      render: (_, { customerPo }) => customerPo || EMPTY,
      sorter: (a, b) => (a?.customerPo || '').localeCompare(b?.customerPo || ''),
      sortOrder: sort.column === 'po' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'SAP Order Number',
      key: 'sap',
      width: 110,
      render: EmptyCell
    },
    {
      title: 'Customer Original Qty (CS)',
      width: 100,
      align: 'right',
      key: 'customerOriginalQuantity',
      exportConfig: {
        render: ({ customerOriginalQuantity }) => customerOriginalQuantity ?? EMPTY
      },
      render: (_, { customerOriginalQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>{customerOriginalQuantity ?? EMPTY}</span>
      ),
      sorter: (a, b) =>
        safeNumberComparator(a.customerOriginalQuantity, b.customerOriginalQuantity),
      sortOrder: sort.column === 'customerOriginalQuantity' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Customer Accepted Qty (CS)',
      width: 100,
      align: 'right',
      key: 'customerAcceptedQuantity',
      exportConfig: {
        render: ({ customerAcceptedQuantity }) => customerAcceptedQuantity ?? EMPTY
      },
      render: (_, { customerAcceptedQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>{customerAcceptedQuantity ?? EMPTY}</span>
      ),
      sorter: (a, b) =>
        safeNumberComparator(a.customerAcceptedQuantity, b.customerAcceptedQuantity),
      sortOrder: sort.column === 'customerAcceptedQuantity' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'SAP Accepted Qty (CS)',
      width: 100,
      align: 'right',
      key: 'sapOrderQty',
      exportConfig: {
        title: 'Total SAP Accepted Qty (CS)',
        render: ({ sapAcceptedQuantity }) => sapAcceptedQuantity ?? EMPTY
      },
      render: (_, { sapAcceptedQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>{sapAcceptedQuantity ?? EMPTY}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.sapAcceptedQuantity, b.sapAcceptedQuantity),
      sortOrder: sort.column === 'sapOrderQty' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Cuts',
      width: 100,
      align: 'right',
      showSorterTooltip: {
        title: 'SAP Accepted Qty - Ship Qty',
        placement: 'bottom'
      },
      key: 'cuts',
      exportConfig: {
        title: 'Total Cuts',
        render: ({ cuts }) => cuts ?? EMPTY
      },
      render: (_, { cuts }) => <span style={{ fontWeight: 'bold' }}>{cuts ?? EMPTY}</span>,
      sorter: (a, b) => safeNumberComparator(a.cuts, b.cuts || 0),
      sortOrder: sort.column === 'cuts' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Ship Qty',
      width: 100,
      align: 'right',
      key: 'shipQty',
      exportConfig: {
        title: 'Total Ship Qty',
        render: ({ shippedQuantity }) => shippedQuantity ?? EMPTY
      },
      render: (_, { shippedQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>{shippedQuantity ?? EMPTY}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.shippedQuantity, b.shippedQuantity),
      sortOrder: sort.column === 'shipQty' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Remaining To Ship',
      width: 100,
      align: 'right',
      key: 'remainingToShipQty',
      showSorterTooltip: {
        title: 'Zero if Ship Qty exists otherwise SAP Accepted Qty',
        placement: 'bottom'
      },
      exportConfig: {
        title: 'Remaining To Ship (PO Total)',
        render: ({ remainingToShip }) => remainingToShip ?? EMPTY
      },
      render: (_, { remainingToShip }) => (
        <span style={{ fontWeight: 'bold' }}>{remainingToShip ?? EMPTY}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.remainingToShip, b.remainingToShip),
      sortOrder: sort.column === 'remainingToShipQty' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Net Variance',
      width: 100,
      align: 'right',
      key: 'netVariance',
      showSorterTooltip: {
        title: 'Customer Accepted Qty - (Ship Qty or SAP Order Qty)',
        placement: 'bottom'
      },
      exportConfig: {
        render: ({ netVariance }) => netVariance ?? EMPTY
      },
      render: (_, { netVariance }) => (
        <span style={{ fontWeight: 'bold' }}>{netVariance ?? EMPTY}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.netVariance, b.netVariance),
      sortOrder: sort.column === 'netVariance' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Submitted Fill-Rate',
      width: 100,
      key: 'submittedFillRate',
      align: 'right',
      showSorterTooltip: {
        title: 'Ship Qty / Customer Original Qty',
        placement: 'bottom'
      },
      exportConfig: {
        render: ({ submittedFillRate }) => submittedFillRate ?? EMPTY,
        modes: ['NO_TOTALS']
      },
      render: (_, { submittedFillRate }) => (submittedFillRate ? submittedFillRate + '%' : EMPTY),
      sorter: (a, b) => safeNumberComparator(a.submittedFillRate, b.submittedFillRate),
      sortOrder: sort.column === 'submittedFillRate' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Accepted Fill-Rate',
      width: 100,
      key: 'acceptedFillRate',
      align: 'right',
      showSorterTooltip: {
        title: 'Ship Qty / Customer Accepted Qty',
        placement: 'bottom'
      },
      exportConfig: {
        render: ({ acceptedFillRate }) => acceptedFillRate ?? EMPTY,
        modes: ['NO_TOTALS']
      },
      render: (_, { acceptedFillRate }) => (acceptedFillRate ? acceptedFillRate + '%' : EMPTY),
      sorter: (a, b) => safeNumberComparator(a.acceptedFillRate, b.acceptedFillRate),
      sortOrder: sort.column === 'acceptedFillRate' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Planned Ship Date (SAP)',
      width: 100,
      key: 'plannedShipDate',
      align: 'right',
      render: EmptyCell
    },
    {
      title: 'Actual Ship Date (SAP)',
      width: 100,
      key: 'actualShipDate',
      align: 'right',
      render: EmptyCell
    },
    {
      title: 'Req Delivery Date (SAP)',
      width: 100,
      key: 'requestedDeliveryDate',
      align: 'right',
      render: EmptyCell
    },
    {
      title: 'Window End Date (Customer)',
      width: 100,
      key: 'windowEndDate',
      align: 'right',
      render: EmptyCell
    },
    {
      title: 'Window End Alert',
      width: 65,
      align: 'right',
      key: 'windowEndAlert',
      exportConfig: {
        title: 'Window End Alert (PO Total)',
        render: ({ orders }) =>
          (orders || [])?.filter(
            (x) =>
              x &&
              isDateAfterTarget(
                setDateToNullIf1900(x?.requestedDeliveryDate),
                setDateToNullIf1900(x?.windowEndDate)
              )
          ).length || 0
      },
      render: (_, { orders }) => (
        <Tooltip title="RDD > Window End Date" placement="right">
          <span style={{ fontWeight: 'bold' }}>
            {(orders || [])?.filter(
              (x) =>
                x &&
                isDateAfterTarget(
                  setDateToNullIf1900(x?.requestedDeliveryDate),
                  setDateToNullIf1900(x?.windowEndDate)
                )
            ).length || 0}
          </span>
        </Tooltip>
      )
    },
    {
      title: 'Vendor Code',
      width: 100,
      key: 'vendorCode',
      exportConfig: {
        render: ({ vendorCode }) => vendorCode || EMPTY,
        modes: ['NO_TOTALS']
      },
      render: (_, { vendorCode }) => vendorCode || EMPTY,
      sorter: (a, b) => (a?.vendorCode || '').localeCompare(b?.vendorCode || ''),
      sortOrder: sort.column === 'vendorCode' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'BU',
      width: 100,
      key: 'bu',
      exportConfig: {
        render: ({ businessUnitName }) => businessUnitName || EMPTY,
        modes: ['NO_TOTALS']
      },
      render: (_, { businessUnitName }) => businessUnitName || EMPTY,
      sorter: (a, b) => (a?.businessUnitName || '').localeCompare(b?.businessUnitName || ''),
      sortOrder: sort.column === 'bu' ? (sort.direction as SortOrder) : null
    },
    {
      title: 'Ship From',
      width: 100,
      key: 'shipFrom',
      render: EmptyCell
    },
    {
      title: 'Sold To Number',
      width: 100,
      key: 'soldTo',
      render: EmptyCell
    },
    {
      title: 'Ship-To Name',
      width: 170,
      key: 'shipTo',
      render: EmptyCell
    },
    {
      title: 'Order Date',
      width: 100,
      key: 'orderDate',
      align: 'right',
      exportConfig: {
        render: ({ orderDate }) => dateFormat(setDateToNullIf1900(orderDate)) || EMPTY,
        modes: ['NO_TOTALS']
      },
      render: (_, { orderDate }) => dateFormat(setDateToNullIf1900(orderDate)) || EMPTY
    },
    {
      title: 'Gross Value',
      width: 145,
      align: 'right',
      key: 'grossValue',
      render: EmptyCell
    },
    {
      title: 'Customer Name',
      width: 160,
      key: 'customerName',
      render: EmptyCell
    },
    {
      title: 'Comments',
      width: 75,
      key: 'comments',
      render: EmptyCell
    }
  ];

  const { data, loading, error, refetch } = useQuery(
    MultipleOrdersByProductFillRateReportDocument,
    {
      variables: {
        productIds: [productId],
        filters
      }
    }
  );

  // TODO: remove filter after backend removes nonNull productId. We are filtering here just in case if productId 0 returns something :)
  const metrics = uniqBy(data?.ordersByProductFillRateReport?.metrics || [], 'customerPo').filter(
    (x) => x.productId === productId
  );

  const width = sumBy(poColumns, 'width');

  /* 
  this adds the component container element to an array in the ProductViewContext. When the primary product view table is scrolled, 
  an onScroll event will transform:translate this element by an equal amount sliding it left and right to keep it within the view
  */
  const containerOnMount = (node: HTMLDivElement | null) => {
    if (node) {
      //a width of 100% will allow overscroll and white space. It's odd.
      if (viewContext.primaryTable.current) {
        node.style.width = `${
          viewContext.primaryTable.current.getBoundingClientRect().width - 1
        }px`;
      }

      componentWrapper.current = node;
      if (!viewContext.subscribers.current.includes(node)) {
        viewContext.subscribers.current.push(node);
      }

      viewContext.evaluateSubscriberPosition();
    }
  };

  const tableRendered = () => {
    //When the inner table is rendered, set it's left position to match the horizontal scroll of the parent table.
    viewContext.evaluateSubscriberPosition();

    //the parent will decide which scrollbar should be displayed
    if (onReady) onReady();
  };

  useEffect(() => {
    /*
    This onScroll magic is required because position:sticky only works with an element whos parent does not have horizontal scroll.
    Because the parent ProductViewTable has horizontal scroll we have to do the sticky header manually. We also have to avoid using state
    and interact with the elements directly because a rerender loop in this usecase would cause a stitter in the animation.
    */

    const onScroll = (event: any) => {
      const elements = document.elementsFromPoint(document.body.clientWidth / 2, 0);

      /*This ensures the parent table is not using translate3d which would prevent the header from being set to position:fixed
      The translate3d is used in ProductViewTable to smooth the horizontal scroll */
      if (componentWrapper.current && viewContext.primaryTable.current) {
        componentWrapper.current.style.left = `${viewContext.primaryTable.current.scrollLeft}px`;
        componentWrapper.current.style.transform = '';
      }

      if (container.current && elements.includes(container.current as Element)) {
        const box = container.current.getBoundingClientRect();
        const top = box.top;
        const scrollRemaining = box.height - Math.abs(box.top) - 80;
        const left = box.left;

        if (top <= 0) {
          container.current.className = 'stick';
          if (tableWrapper.current) {
            const header = container.current.querySelector('.ant-table-header') as HTMLElement;
            const width = tableWrapper.current.getBoundingClientRect().width;
            if (header) {
              header.style.width = `${width}px`;
              header.style.left = `${left}px`;
              if (scrollRemaining < 0) {
                //slide the header out of view as the scroll exits the table
                header.style.transform = `translate3d(0px,${scrollRemaining.toFixed(0)}px, 0px)`;
              } else {
                header.style.transform = `translate3d(0px,0px, 0px)`;
              }
            }
          }
        } else {
          const header = container.current.querySelector('.ant-table-header') as HTMLElement;
          if (header) {
            header.style.transform = `translate3d(0px,0px, 0px)`;
          }
          container.current.className = '';
        }
      } else if (container.current) {
        const header = container.current.querySelector('.ant-table-header') as HTMLElement;

        if (header) {
          header.style.transform = `translate3d(0px,0px, 0px)`;
        }
        container.current.className = '';
      }
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    //when the component unmounts this will clear the onScroll listener and prevent the parent component from attempting to update it's position
    return () => {
      if (componentWrapper.current) {
        //unsubscribe from parent updates
        const index = viewContext.subscribers.current.findIndex(
          (elem) => elem === componentWrapper.current
        );
        if (index > -1) viewContext.subscribers.current.splice(index, 1);
      }

      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, []);

  return (
    <div className={s.table_container} ref={containerOnMount}>
      {error ? (
        <div>Could not load data due to an error. Try again.</div>
      ) : (
        <div className={s.details_table_wrapper} ref={tableWrapper as LegacyRef<HTMLDivElement>}>
          <div className="spacer"></div>
          {loading ? (
            <div className={s.spinner}>
              <Spin />
            </div>
          ) : (
            <div ref={container as LegacyRef<HTMLDivElement>}>
              <Table
                loading={loading}
                ref={tableRendered}
                rowKey="customerPo"
                showSorterTooltip={false}
                columns={poColumnsWithSort}
                dataSource={metrics}
                pagination={false}
                rowClassName={s.innterTableRow}
                scroll={{ x: width }}
                sticky={true}
                //@ts-ignore
                onChange={(
                  pagination,
                  filters,
                  sorter: { columnKey: string; order: string },
                  extra
                ) => {
                  if (extra.action === 'sort') {
                    setSort({
                      column: sorter.columnKey,
                      direction: sorter.order
                    });

                    setUrlParams({
                      ...urlParams,
                      poColumn: sorter.columnKey,
                      poDirection: sorter.order
                    });
                  }
                }}
                expandable={{
                  defaultExpandAllRows: true,
                  expandedRowRender: (po) => (
                    <div className={s.sap_table_wrapper}>
                      <Table
                        rowKey="alternateSoId"
                        ref={() => {
                          if (onReady) onReady();
                        }}
                        columns={sapColumns}
                        dataSource={(po.orders || []).map((order) =>
                          mapOrderDetailsToOrderDetailsEnhanced(order, po, refetch)
                        )}
                        pagination={false}
                        expandable={{ expandedRowRender: () => {} }}
                      />
                    </div>
                  )
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
