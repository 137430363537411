import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReleaseSalesOrdersMutationVariables = Types.Exact<{
  input: Types.ReleaseSalesOrdersInput;
}>;


export type ReleaseSalesOrdersMutation = { __typename?: 'RootMutationType', releaseSalesOrders?: { __typename?: 'ReleaseSalesOrdersPayload', salesOrders: Array<{ __typename?: 'SalesOrder', id: string }> } | null };


export const ReleaseSalesOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"releaseSalesOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReleaseSalesOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"releaseSalesOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<ReleaseSalesOrdersMutation, ReleaseSalesOrdersMutationVariables>;