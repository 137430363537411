import React from 'react';
import s from './CutsTrends.module.scss';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import {
  convertWeekToPeriodWeekString,
  getFinancialInfo,
  isMOPDayToday
} from 'common/helpers/fiscalCalendar';
import { useQuery } from '@apollo/client';
import {
  ConsecutivelyCutOrdersDocument,
  ConsecutivelyCutOrdersQuery
} from './gql/__generated__/consecutivelyCutOrders.query';
import { EMPTY } from 'common/constants';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { getWeekAndYearAfterSteps } from 'common/helpers/periodHelper';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

const today = new Date();

const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
// At Monday, we shoud prev period sice it's before MOP
const referenceDate = isMOPDayToday() ? yesterday : today;

const currentPeriod = getFinancialInfo(referenceDate);

type TopOpportunities = ConsecutivelyCutOrdersQuery['consecutivelyCutOrders'][number];

const trendsOptions = [5, 10, 15, 20].map((value) => ({
  value,
  label: `${value} Trends`
}));

const weeksOptions = [2, 3, 5, 10].map((value) => ({
  value,
  label: `${value} Weeks`
}));

const formatMoney = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `$${parsedValue.toLocaleString('en-US')}`;
};

const columns: ColumnsType<TopOpportunities> = [
  {
    key: 'UPC',
    title: 'UPC',
    render: (_, { upc }) => <span className={s.number}>{upc}</span>,
    sorter: (a, z) => safeLocaleCompare(a.upc, z.upc),
    width: '160px'
  },
  {
    title: 'Product Name',
    dataIndex: 'productName',
    sorter: (a, z) => safeLocaleCompare(a.productName, z.productName)
  },
  {
    title: 'Cut Units',
    align: 'right',
    render: (_, { cutUnits }) => <span className={s.number}>{cutUnits.toLocaleString()}</span>,
    sorter: (a, z) => safeNumberComparator(a.cutUnits, z.cutUnits),
    width: '120px'
  },
  {
    title: 'Cut Reason',
    dataIndex: 'cutReason',
    sorter: (a, z) => safeLocaleCompare(a.cutReason, z.cutReason)
  },
  {
    title: 'Approx. Lost Opportunity',
    render: (_, { lostOpportunity }) => formatMoney(lostOpportunity),
    width: '220px',
    sorter: (a, z) => safeNumberComparator(a.lostOpportunity, z.lostOpportunity)
  }
];

export const CutsTrends = () => {
  const [top, setTop] = useQueryParam('top', withDefault(NumberParam, 5));
  const [weeks, setWeeks] = useQueryParam('weeks', withDefault(NumberParam, 5));

  const firstPeriodWeekAndYear = getWeekAndYearAfterSteps(
    currentPeriod.week,
    currentPeriod.year,
    -(weeks - 1)
  );

  const currentPeriodText =
    currentPeriod.year === firstPeriodWeekAndYear.year
      ? `${convertWeekToPeriodWeekString(
          firstPeriodWeekAndYear.week
        )} - ${convertWeekToPeriodWeekString(currentPeriod.week)}`
      : `${firstPeriodWeekAndYear.year} ${convertWeekToPeriodWeekString(
          firstPeriodWeekAndYear.week
        )} - ${currentPeriod.year} ${convertWeekToPeriodWeekString(currentPeriod.week)}`;

  const { data, loading, error } = useQuery(ConsecutivelyCutOrdersDocument, {
    variables: {
      filters: {
        first: top,
        lookbackCalendarWeek: {
          year: currentPeriod.year,
          week: currentPeriod.week,
          period: currentPeriod.period
        },
        numWeeksToLookBack: weeks
      }
    }
  });
  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>
        Top{' '}
        <AlloySelect
          value={top}
          onChange={(value) => {
            setTop(value);
          }}
          variant="borderless"
          dropdownStyle={{ minWidth: '120px', fontWeight: 'bold' }}
          options={trendsOptions}
          className={s.borderlessSelect}
        />
        in Cuts in the last{' '}
        <AlloySelect
          value={weeks}
          onChange={(value) => {
            setWeeks(value);
          }}
          variant="borderless"
          dropdownStyle={{ minWidth: '120px', fontWeight: 'bold' }}
          options={weeksOptions}
          className={s.borderlessSelect}
        />{' '}
        ({currentPeriodText})
      </h2>
      {error ? (
        'Something went wrong while loading Top 5 opportunities...'
      ) : (
        <AlloyTable
          columns={columns}
          dataSource={data?.consecutivelyCutOrders}
          loading={loading}
          pagination={false}
        />
      )}
    </div>
  );
};
