import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DcSourceSystemParamsQueryVariables = Types.Exact<{
  source: Types.DistributionCenterSource;
  code: Types.Scalars['String'];
}>;


export type DcSourceSystemParamsQuery = { __typename?: 'RootQueryType', dcSourceSystemParams?: { __typename?: 'DcSourceSystemParams', listUrl?: string | null, viewUrl?: string | null, existenceChecked?: boolean | null } | null };


export const DcSourceSystemParamsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"dcSourceSystemParams"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"source"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DistributionCenterSource"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dcSourceSystemParams"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"source"},"value":{"kind":"Variable","name":{"kind":"Name","value":"source"}}},{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listUrl"}},{"kind":"Field","name":{"kind":"Name","value":"viewUrl"}},{"kind":"Field","name":{"kind":"Name","value":"existenceChecked"}}]}}]}}]} as unknown as DocumentNode<DcSourceSystemParamsQuery, DcSourceSystemParamsQueryVariables>;