import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdatePurchaseOrderDetailsMabdMutationVariables = Types.Exact<{
  input: Types.UpdatePurchaseOrderInput;
}>;


export type UpdatePurchaseOrderDetailsMabdMutation = { __typename?: 'RootMutationType', updatePurchaseOrder?: { __typename?: 'UpdatePurchaseOrderPayload', purchaseOrder?: { __typename?: 'PurchaseOrder', id: string, deliveryWindowEnd?: any | null } | null } | null };


export const UpdatePurchaseOrderDetailsMabdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePurchaseOrderDetailsMABD"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePurchaseOrderInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePurchaseOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"deliveryWindowEnd"}}]}}]}}]}}]} as unknown as DocumentNode<UpdatePurchaseOrderDetailsMabdMutation, UpdatePurchaseOrderDetailsMabdMutationVariables>;