export interface Batch {
  openVariance: number;
  batchNumber: string;
}

export type Action = 'Add' | 'Remove';

const findMaximumAmountForTransfer = (
  fromBatchId: string | undefined,
  toBatchId: string | undefined,
  batches: Batch[]
) => {
  const fromAmount = batches.find((batch) => batch.batchNumber === fromBatchId)?.openVariance || 0;
  const toAmount = batches.find((batch) => batch.batchNumber === toBatchId)?.openVariance || 0;

  if (fromAmount < 0 || toAmount > 0) {
    return 0;
  }

  if ((fromAmount >= 0 && toAmount >= 0) || (fromAmount <= 0 && toAmount <= 0)) return 0;
  return Math.min(Math.abs(fromAmount), Math.abs(toAmount));
};

export const findMaxAmountWithAction = (
  firstBatchId: string | undefined,
  secondBatchId: string | undefined,
  batches: Batch[],
  firstAction: Action
) => {
  if (firstAction === 'Remove') {
    return findMaximumAmountForTransfer(firstBatchId, secondBatchId, batches);
  } else {
    return findMaximumAmountForTransfer(secondBatchId, firstBatchId, batches);
  }
};
