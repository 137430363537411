import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CutReasonsByCategoryQueryVariables = Types.Exact<{
  filters: Types.CutOrdersReasonsPercentageByCategoryFilters;
}>;


export type CutReasonsByCategoryQuery = { __typename?: 'RootQueryType', cutReasonsByCategory: Array<{ __typename?: 'CutReasonPercentageForCategory', percentage: any, reason: string }> };


export const CutReasonsByCategoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"cutReasonsByCategory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CutOrdersReasonsPercentageByCategoryFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cutReasonsByCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"percentage"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}}]}}]}}]} as unknown as DocumentNode<CutReasonsByCategoryQuery, CutReasonsByCategoryQueryVariables>;