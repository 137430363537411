import React from 'react';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

interface ChangeDcModalProps {
  visibility: boolean;
  toggleModalVisibility: () => void;
  changeDc: () => void;
  numberOfProducts: number;
}

const ChangeDcModal = ({
  visibility,
  toggleModalVisibility,
  changeDc,
  numberOfProducts
}: ChangeDcModalProps) => {
  return (
    <AlloyModal
      title="Divert to new DC"
      open={visibility}
      onOk={changeDc}
      onCancel={toggleModalVisibility}
      footer={[
        <AlloyButton key="back" onClick={toggleModalVisibility} data-testid="cancel-divert">
          Cancel
        </AlloyButton>,
        <AlloyButton key="submit" type="primary" onClick={changeDc} data-testid="divert">
          Divert
        </AlloyButton>
      ]}
    >
      <div data-testId="change-dc-modal-confirmation-text">
        Are you sure you want to divert{' '}
        {numberOfProducts > 1 ? `${numberOfProducts} products` : `${numberOfProducts} product`} with
        this sales order to new DC?
      </div>
    </AlloyModal>
  );
};

export default ChangeDcModal;
