import * as Types from '../../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GeneratePurchaseOrderConfirmationsMutationVariables = Types.Exact<{
  input: Types.GeneratePurchaseOrderConfirmationsInput;
}>;


export type GeneratePurchaseOrderConfirmationsMutation = { __typename?: 'RootMutationType', generatePurchaseOrderConfirmations?: { __typename?: 'GeneratePurchaseOrderConfirmationsPayload', confirmationsGenerated: number } | null };


export const GeneratePurchaseOrderConfirmationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"generatePurchaseOrderConfirmations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GeneratePurchaseOrderConfirmationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generatePurchaseOrderConfirmations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmationsGenerated"}}]}}]}}]} as unknown as DocumentNode<GeneratePurchaseOrderConfirmationsMutation, GeneratePurchaseOrderConfirmationsMutationVariables>;