import React, { useState, useMemo, useEffect } from 'react';
import { notEmpty } from 'common/helpers/notEmpty';
import { Button, Dropdown, Modal, Table } from 'antd';
import clsx from 'clsx';
import s from './ReconciliationTable.module.scss';
import { EMPTY } from 'common/constants';
import { ColumnsType } from 'antd/lib/table';
import { useQuery } from '@apollo/client';
import { uniq } from 'lodash-es';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import { InventoryMetric } from 'pages/Inventory/types';
import { InventoryReconciliationFilters } from 'graphql/__generated__/types';
import { BatchSwapForm } from '../BatchSwapForm/BatchSwapForm';
import {
  DiscrepancyReason,
  ReconcileBatchDetailsMetric,
  ReconcileMetric,
  makeReasonsReadable
} from '../types';
import { EditReasonsForm } from '../EditReasonsForm/EditReasonsForm';
import { InventoryReconciliationDiscrepanciesDocument } from '../gql/__generated__/inventoryReconciliationDiscrepancies.query';
import { InventoryReconciliationBatchDiscrepanciesDocument } from '../gql/__generated__/inventoryReconciliationBatchDiscrepancies.query';

function parseNumberAndFormat(
  number: string,
  useGrouping: boolean | undefined = undefined
): string {
  return number === null
    ? EMPTY
    : (parseFloat(number) || 0).toLocaleString(undefined, {
        minimumFractionDigits: 3,
        useGrouping
      });
}

function paginate<T>(array: T[], page: number, size: number): T[] {
  const start = (page - 1) * size;
  const end = start + size;
  return array.slice(start, end);
}

function showLengthOrValue(array: string[], word: string) {
  if (array.length > 1) {
    return `${array.length} ${word}`;
  } else {
    return array[0];
  }
}

const TABLE_WIDTH = 1265;

const getKey = (record: ReconcileMetric): string => `${record?.sapMaterialId}-${record?.plant}`;
const EmptyCell = () => {};

const FAKE_COLUMN_OF_EXPANDER_WIDTH = {
  title: '',
  key: 'expander',
  width: 48,
  render: EmptyCell
};

const LAST_COLUMN_WIDTH = 150;
const columns: ColumnsType<ReconcileMetric> = [
  Table.SELECTION_COLUMN,
  Table.EXPAND_COLUMN,
  {
    title: 'Sap Material ID',
    key: 'sapMaterialId',
    width: 160,
    render: (_, { sapMaterialId }) => sapMaterialId || EMPTY
  },
  {
    title: 'Description',
    key: 'description',
    render: (_, { name }) => <span className={s.description}>{name || EMPTY}</span>
  },
  {
    title: 'Plant',
    key: 'plant',
    width: 65,
    render: (_, { plant }) => plant || EMPTY,
    sorter: (a, b) => (a?.plant || '').localeCompare(b?.plant || '')
  },
  FAKE_COLUMN_OF_EXPANDER_WIDTH,
  {
    title: 'Batch',
    key: 'batch',
    width: 135,
    render: (_, { batchNumbers }) =>
      batchNumbers.length > 0 ? showLengthOrValue(uniq(batchNumbers), 'Batches') : EMPTY
  },
  {
    title: 'Base UOM',
    width: 50,
    key: 'baseUnitOfMeasure',
    render: (_, { baseUnitOfMeasure }) => <span>{baseUnitOfMeasure}</span>
  },
  {
    title: 'Sap On Hand',
    width: 100,
    align: 'right',
    key: 'sapOnHand',
    render: (_, { sapQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(sapQuantity)}</span>
    )
  },
  {
    title: 'WMS On Hand',
    width: 100,
    align: 'right',
    key: 'thirdPartyOnHand',
    render: (_, { wmsQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(wmsQuantity)}</span>
    )
  },
  {
    title: 'Discrepancy Count',
    width: 100,
    align: 'right',
    key: 'discrepancy',
    render: (_, { discrepancy }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(discrepancy)}</span>
    )
  },
  {
    title: 'Open Variance',
    width: 100,
    align: 'right',
    key: 'openVariance',
    render: (_, { openVariance }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(openVariance)}</span>
    )
  },
  {
    title: 'Reason Code',
    width: LAST_COLUMN_WIDTH,
    align: 'left',
    key: 'reason',
    render: () => <span>--</span>
  }
];

const reasonsColumns: ColumnsType<DiscrepancyReason> = [
  Table.SELECTION_COLUMN,
  FAKE_COLUMN_OF_EXPANDER_WIDTH,
  {
    title: 'Sap Material ID',
    key: 'sapMaterialId',
    width: 160,
    render: EmptyCell
  },
  {
    title: 'Description',
    key: 'description',
    render: EmptyCell
  },
  {
    title: 'Plant',
    key: 'plant',
    width: 65,
    render: EmptyCell
  },
  FAKE_COLUMN_OF_EXPANDER_WIDTH,
  {
    title: 'Batch',
    key: 'batch',
    width: 135,
    render: EmptyCell
  },
  {
    title: 'Base UOM',
    width: 50,
    key: 'baseUnitOfMeasure',
    render: EmptyCell
  },
  {
    title: 'Sap On Hand',
    width: 100,
    align: 'right',
    key: 'sapOnHand',
    render: EmptyCell
  },
  {
    title: 'WMS On Hand',
    width: 100,
    align: 'right',
    key: 'thirdPartyOnHand',
    render: EmptyCell
  },
  {
    title: 'Discrepancy Count',
    width: 100,
    align: 'right',
    key: 'discrepancy',
    render: EmptyCell
  },
  {
    title: 'Open Variance',
    width: 100,
    align: 'right',
    key: 'openVariance',
    render: (_, { quantity }) => <span>{quantity}</span>
  },
  {
    title: 'Reason Code',
    // Need to do this because of borders
    width: LAST_COLUMN_WIDTH - 1,
    align: 'left',
    key: 'reason',
    render: (_, { reason }) => <span>{makeReasonsReadable(reason)}</span>
  }
];

export const ReconciliationTable = ({
  rowsToReconcile,
  filters,
  parentFilters
}: {
  rowsToReconcile: InventoryMetric[] | undefined;
  filters: Array<String>;
  parentFilters: InventoryReconciliationFilters;
}) => {
  const { data, loading, error } = useQuery(InventoryReconciliationDiscrepanciesDocument, {
    variables: {
      filters: parentFilters,
      discrepancyMainKeys: rowsToReconcile
        ? rowsToReconcile.map((x) => ({
            plantNumber: x.plant,
            sapMaterialId: x.sapMaterialId,
            processDate: x.processDate
          }))
        : []
    }
  });

  const tableData = useMemo(
    () => data?.inventoryReconciliationDiscrepancies?.metrics || [],
    [data]
  );

  const [selectedRows, setSelectedRows] = useState<ReconcileMetric[]>([]);
  const resetSelectedRows = () => setSelectedRows([]);

  const INITIAL_PAGINATION = 50;
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION);
  const [page, setPage] = useState(1);
  const isSinglePage = tableData.length <= pageSize;
  const isPaginationHidden = tableData.length <= INITIAL_PAGINATION;

  // Reset page after filters/mode change
  useEffect(() => {
    setPage(1);
  }, [filters]);

  const currentPageItemsExpandableKeys = useMemo(
    () => paginate(tableData, page, pageSize).map(getKey),
    [tableData, page, pageSize]
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>(currentPageItemsExpandableKeys);

  useEffect(() => {
    setExpandedRowKeys(currentPageItemsExpandableKeys);
  }, [currentPageItemsExpandableKeys]);

  const allExpanded = useMemo(
    () => currentPageItemsExpandableKeys.every((item) => expandedRowKeys.includes(item)),
    [expandedRowKeys, currentPageItemsExpandableKeys]
  );

  return (
    <div>
      {error ? (
        <div style={{ marginTop: '40px' }}>Could not load data due to an error. Try again.</div>
      ) : (
        <>
          <div
            className={clsx(s.totalAndSelectedWrapper, isPaginationHidden && s.tableNoPagination)}
          >
            <div className={s.totalAndSelected}>
              <div data-testid="inventory-total-products">
                {loading ? '...' : tableData.length} product
                {tableData.length === 1 ? '' : 's'}
                {selectedRows.length > 0 && ', '}
              </div>
              {selectedRows.length > 0 && (
                <>
                  <div>{selectedRows.length} selected</div>
                  <Button
                    className="filled_grey_btn_no_border"
                    onClick={resetSelectedRows}
                    data-testid="visibility-clear-selection"
                  >
                    Clear selection
                  </Button>
                </>
              )}
            </div>
          </div>
          <Table
            rowKey={getKey}
            scroll={{ x: TABLE_WIDTH }}
            rowSelection={{
              preserveSelectedRowKeys: true,
              onChange: (_keys, rows) => {
                setSelectedRows(rows);
              },
              type: 'checkbox',
              selectedRowKeys: selectedRows.map(getKey),
              //@ts-ignore
              getCheckboxProps(record) {
                return { 'data-testid': getKey(record) };
              }
            }}
            columns={columns}
            loading={loading}
            dataSource={tableData}
            bordered
            className={clsx(
              // 'legacy_borderless_bordered',
              `styled_report_table`,
              s.table,
              isPaginationHidden && s.tableNoPagination
            )}
            rowClassName={(record) => {
              if (expandedRowKeys.includes(getKey(record))) return s.expanded;
              return s.collapsed;
            }}
            size="small"
            pagination={
              isPaginationHidden
                ? false
                : {
                    position: ['topRight'],
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200'],
                    pageSize: pageSize,
                    size: 'small',
                    onShowSizeChange: (_current, size) => setPageSize(size),
                    current: page,
                    onChange: (page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                    }
                  }
            }
            expandable={{
              // It's not the best idea to use internal antd classes, but it's unlikely they change them
              columnTitle: currentPageItemsExpandableKeys.length > 0 && (
                <ExpandButton
                  expanded={allExpanded}
                  onClick={(expanded) => {
                    expanded
                      ? setExpandedRowKeys(
                          expandedRowKeys.filter((x) => !currentPageItemsExpandableKeys.includes(x))
                        )
                      : setExpandedRowKeys([
                          ...new Set([...currentPageItemsExpandableKeys, ...expandedRowKeys])
                        ]);
                  }}
                  collapseMessage="Collapse all"
                  expandMessage="Expand all"
                />
              ),
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
              rowExpandable: () => true,
              expandedRowRender: (record, _index, _indent, expanded) =>
                expanded && (
                  <ProductDetails
                    record={record}
                    filters={parentFilters}
                    rowsToReconcile={rowsToReconcile}
                  />
                )
            }}
          />
        </>
      )}
    </div>
  );
};

export const ProductDetails = ({
  record,
  filters,
  rowsToReconcile = []
}: {
  record: ReconcileMetric;
  filters: InventoryReconciliationFilters;
  rowsToReconcile: InventoryMetric[] | undefined;
}) => {
  const [swapModalVisible, setSwapModalVisible] = useState(false);
  const [swapBatch, setSwapBatch] = useState<ReconcileBatchDetailsMetric | undefined>(undefined);
  const [editBatch, setEditBatch] = useState<ReconcileBatchDetailsMetric | undefined>(undefined);
  const [reasonsModalVisible, setReasonsModalVisible] = useState(false);

  const detailsColumns: ColumnsType<ReconcileBatchDetailsMetric> = [
    Table.SELECTION_COLUMN,
    FAKE_COLUMN_OF_EXPANDER_WIDTH,
    {
      title: 'Sap Material ID',
      key: 'sapMaterialId',
      width: 160,
      render: EmptyCell
    },
    {
      title: 'Description',
      key: 'description',
      render: EmptyCell
    },
    {
      title: 'Plant',
      key: 'plant',
      width: 65,
      render: EmptyCell
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Batch',
      key: 'batch',
      width: 135,
      render: (_, { batchNumber }) => batchNumber || EMPTY
    },
    {
      title: 'Base UOM',
      width: 50,
      key: 'baseUnitOfMeasure',
      render: (_, { baseUnitOfMeasure }) => <span>{baseUnitOfMeasure}</span>
    },
    {
      title: 'Sap On Hand',
      width: 100,
      align: 'right',
      key: 'sapOnHand',
      render: (_, { sapQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(sapQuantity)}</span>
      )
    },
    {
      title: 'WMS On Hand',
      width: 100,
      align: 'right',
      key: 'thirdPartyOnHand',
      render: (_, { wmsQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(wmsQuantity)}</span>
      )
    },
    {
      title: 'Discrepancy Count',
      width: 100,
      align: 'right',
      key: 'discrepancy',
      render: (_, { discrepancy }) => (
        <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(discrepancy)}</span>
      )
    },
    {
      title: 'Open Variance',
      width: 100,
      align: 'right',
      key: 'openVariance',
      render: (_, { openVariance }) => (
        <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(openVariance)}</span>
      )
    },
    {
      title: 'Reason Code',
      // Need to do this because of borders
      width: LAST_COLUMN_WIDTH - 1,
      align: 'left',
      key: 'reason',
      render: (_, record) => (
        <span>
          <Dropdown
            menu={{
              items: [
                {
                  label: 'Edit Reason',
                  key: 'Edit Reason',
                  onClick: () => {
                    setReasonsModalVisible(true);
                    setEditBatch(record);
                  }
                },
                {
                  label: 'Swap Batch',
                  key: 'Swap Batch',
                  onClick: () => {
                    setSwapModalVisible(true);
                    setSwapBatch(record);
                  }
                }
              ]
            }}
          >
            <button className={s.reasonButton}>Edit reasons</button>
          </Dropdown>
        </span>
      )
    }
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const { data, loading, error } = useQuery(InventoryReconciliationBatchDiscrepanciesDocument, {
    variables: {
      filters: filters,
      discrepancyMainKeys: {
        sapMaterialId: record.sapMaterialId,
        plantNumber: record.plant,
        processDate: record.processDate
      }
    }
  });

  useEffect(() => {
    setExpandedRowKeys(
      data?.inventoryReconciliationBatchDiscrepancies?.metrics.map((x) => x.batchNumber) || []
    );
  }, [data]);

  const details = useMemo(
    () => (data?.inventoryReconciliationBatchDiscrepancies?.metrics || []).filter(notEmpty),
    [data]
  );

  return error ? (
    <div>Could not load data due to an error. Try again.</div>
  ) : (
    <>
      <Modal
        open={swapModalVisible}
        title={'Swap batches'}
        width={430}
        footer={null}
        destroyOnClose
        onCancel={() => setSwapModalVisible(false)}
      >
        <BatchSwapForm
          onCancel={() => setSwapModalVisible(false)}
          /* TODO: replace with actual data */
          initialBatch={swapBatch}
          batches={details}
        />
      </Modal>
      <Modal
        open={reasonsModalVisible}
        title={'Edit batch'}
        width={396}
        footer={null}
        destroyOnClose
        onCancel={() => setReasonsModalVisible(false)}
      >
        <EditReasonsForm
          onCancel={() => setReasonsModalVisible(false)}
          /* TODO: replace with actual data */
          editBatch={editBatch}
        />
      </Modal>
      <Table
        scroll={{ x: TABLE_WIDTH }}
        rowKey="batchNumber"
        columns={detailsColumns}
        dataSource={details}
        loading={loading}
        rowClassName={(record, index) => {
          return clsx({
            [s.last_row]:
              (index === details.length - 1 && !expandedRowKeys.includes(record.batchNumber)) ||
              record.reasons.length === 0,
            [s.expanded_batch]: expandedRowKeys.includes(record.batchNumber)
          });
        }}
        pagination={false}
        className={clsx(s.table_no_header)}
        expandable={{
          rowExpandable: (record) => record.reasons.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
          expandedRowRender: (record, index, _indent, expanded) =>
            expanded && (
              <ReasonsTable reasons={record.reasons} isLast={index === details.length - 1} />
            )
        }}
        rowSelection={{
          renderCell: EmptyCell
        }}
      />
    </>
  );
};

export const ReasonsTable = ({
  reasons,
  isLast
}: {
  reasons: DiscrepancyReason[];
  isLast: boolean;
}) => {
  return (
    <Table
      scroll={{ x: TABLE_WIDTH }}
      rowKey="reason"
      columns={reasonsColumns}
      dataSource={reasons}
      rowClassName={(_record, index) => {
        return clsx({
          [s.last_row_batch]: index === reasons.length - 1,
          [s.last_row]: index === reasons.length - 1 && isLast
        });
      }}
      pagination={false}
      className={clsx(s.table_no_header)}
      rowSelection={{
        renderCell: EmptyCell
      }}
    />
  );
};
