import React from 'react';
import { calculateBgColorForRatio, calculateBgColorRedOnly } from 'common/helpers/calculateColor';
import s from './RateCellWithTarget.module.scss';

export const RateCellWithTargetCompact = ({
  value,
  target = 50,
  moreIsWorse = false,
  absoluteValue = false
}: {
  value: number;
  target?: number;
  moreIsWorse?: boolean;
  absoluteValue?: boolean;
}) => {
  // We will use it for color calculation, but still will display a negative value
  const adjustedValue = absoluteValue ? Math.abs(value) : value;
  const backgroundColor = moreIsWorse
    ? calculateBgColorRedOnly(adjustedValue)
    : calculateBgColorForRatio(adjustedValue, target);

  return (
    <div
      className={s.rate_component}
      style={{
        background: backgroundColor
      }}
    >
      {`${Math.round(value)}%`}
    </div>
  );
};

export const onCellRateWithTargetCompact = (value: number, target = 50, moreIsWorse = false) => ({
  style: {
    background: moreIsWorse
      ? calculateBgColorRedOnly(value)
      : calculateBgColorForRatio(value, target),
    padding: 0
  }
});
