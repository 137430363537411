import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import { notification as AntNotification } from 'antd';
import PurchaseOrderDetails from 'pages/OrderDetailsPage/components/PurchaseOrderDetails/PurchaseOrderDetails';
import LoaderSpinner from 'components/LoaderSpinner';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { difference } from 'lodash-es';
import { OrderDetailsDocument } from './gql/__generated__/orderDetails.query';
import { GetPoDetailsCutSummariesDocument } from './gql/__generated__/poDetailsCutSummaries.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';

interface MatchParams {
  id: string;
}

export interface ValidationError {
  type: string;
  count: number;
  msg: string;
}

const OrderDetailsPage = (props: RouteComponentProps<MatchParams>) => {
  const orderId = props.match.params.id;

  const { loading, error, data } = useQuery(OrderDetailsDocument, {
    variables: { id: orderId },
    errorPolicy: 'all'
  });

  const purchaseOrderData = useMemo(() => {
    if (!data || !data.purchaseOrder) return;
    if ((data.purchaseOrder?.missingOrderStatusSlaAudits || [])?.length > 0) {
      return {
        ...data.purchaseOrder,
        overdue: true
      };
    }
    return data.purchaseOrder;
  }, [data]);

  const poWarnings = useMemo(() => {
    const HARDCODED_WARNINGS = ['PRICE_MISMATCH', 'UNDER_MOQ', 'WRONG_UOM'];

    const invalidItems = data?.purchaseOrder?.purchaseOrderItems
      .map((invalidItem) => ({
        ...invalidItem,
        errorsWithoutWarning: difference(
          difference(invalidItem.purchaseOrderItemErrors, invalidItem.purchaseOrderItemWarnings),
          HARDCODED_WARNINGS
        )
      }))
      .filter(({ errorsWithoutWarning }) => errorsWithoutWarning.length > 0);

    if (!invalidItems || !invalidItems.length) {
      return [];
    } else {
      if (invalidItems.length) {
        return [
          {
            type: 'MISSING_DATA',
            count: invalidItems.length,
            msg: 'Will release only valid items'
          }
        ];
      }
    }
    return [];
  }, [data]);

  const { data: cutSummariesData, loading: cutSummariesDataLoading } = useQuery(
    GetPoDetailsCutSummariesDocument,
    {
      variables: {
        first: 100,
        purchaseOrderId: orderId
      },

      skip: !purchaseOrderData?.cutSummaries,
      onError: (error) => {
        AntNotification.error({
          message: 'Failed to fetch cut summaries',
          description: error.message
        });
      }
    }
  );

  const cutSummariesList = getNodesFromEdges(cutSummariesData?.poDetailsCutSummaries);

  if (loading) {
    return <LoaderSpinner />;
  }
  if (error && !data) {
    return <ErrorDisplay data-testid="error-display" error={error} />;
  }

  if (!purchaseOrderData) {
    return <div data-testid="purchase-order-not-found-message">Purchase Order Not Found</div>;
  }

  return (
    <PurchaseOrderDetails
      cutSummariesList={cutSummariesList}
      cutSummariesDataLoading={cutSummariesDataLoading}
      orderId={orderId}
      purchaseOrderData={purchaseOrderData}
      warnings={poWarnings}
    />
  );
};

export default OrderDetailsPage;
