import React from 'react';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { OTSGraph } from './OTSGraph/OTSGraph';
import { OTSTable } from './OTSTable/OTSTable';

export const OnTimeShipments = ({
  period,
  businessUnitIds,
  vendorMarketIds
}: {
  period: CalendarValue;
  businessUnitIds: string[];
  vendorMarketIds: string[];
}) => {
  return (
    <>
      <OTSGraph
        period={period}
        businessUnitIds={businessUnitIds}
        vendorMarketIds={vendorMarketIds}
      />
      <OTSTable
        period={period}
        businessUnitIds={businessUnitIds}
        vendorMarketIds={vendorMarketIds}
      />
    </>
  );
};
