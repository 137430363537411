import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VendorMarketsAdvancedFiltersOrdersPageQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.VendorMarketFilters>;
}>;


export type VendorMarketsAdvancedFiltersOrdersPageQuery = { __typename?: 'RootQueryType', vendorMarkets?: { __typename?: 'VendorMarketConnection', edges: Array<{ __typename?: 'VendorMarketEdge', node?: { __typename?: 'VendorMarket', id: string, externalId: string, name: string } | null }> } | null };


export const VendorMarketsAdvancedFiltersOrdersPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"vendorMarketsAdvancedFiltersOrdersPage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"VendorMarketFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendorMarkets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"100000"}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<VendorMarketsAdvancedFiltersOrdersPageQuery, VendorMarketsAdvancedFiltersOrdersPageQueryVariables>;