export const MockData = [
  {
    id: '123',
    name: 'Walmart 2024 - Copy 2',
    createdBy: 'Jamie L.',
    lastUpdated: '2024-05-17'
  },
  {
    id: '134',
    name: 'Walmart 2024 - Copy',
    createdBy: 'Gowri H.',
    lastUpdated: '2024-05-17'
  },
  {
    id: '145',
    name: 'Walmart 2024',
    createdBy: 'Brian G.',
    lastUpdated: '2024-05-17'
  },
  {
    id: '156',
    name: 'Walmart 2023',
    createdBy: 'Chelsy S.',
    lastUpdated: '2024-05-17'
  }
];
