import React from 'react';
import ScenarioList from './Components/ScenarioList/ScenarioList';

export const InventoryOptimizationPage = () => {
  return (
    <div>
      <ScenarioList />
    </div>
  );
};
