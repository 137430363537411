import { useMutation } from '@apollo/client';
import { Spin } from 'antd';
import { Vendor } from 'common/interfaces';
import { validateRequired } from 'components/Common/fields/Utils';
import { UpsertVendorDocument } from 'pages/RetailersPage/gql/__generated__/upsertVendor.mutation';
import React from 'react';
import { Form } from 'react-final-form';
import { FinalFormInput } from '../../../../components/FinalFormFields/FinalFormInput/FinalFormInput';
import s from './AddVendorForm.module.scss';
import { EditVendorMarketInitDataDocument } from 'pages/RetailerChannelsPage/gql/__generated__/editVendorMarketInitData.query';
import { UpsertVendorInput } from 'graphql/__generated__/types';

interface AddVendorFormProps {
  onVendorAdded: (id: string) => void;
}

export const AddVendorForm = ({ onVendorAdded }: AddVendorFormProps) => {
  const vendor = undefined as Vendor | undefined;

  const [upsertVendor, { loading: upsertingVendor }] = useMutation(UpsertVendorDocument, {
    refetchQueries: ['vendors', EditVendorMarketInitDataDocument],
    onCompleted: (data) => {
      onVendorAdded(data?.upsertVendor?.vendor?.id || '');
    }
  });

  const submitForm = async (values: UpsertVendorInput) => {
    await upsertVendor({
      variables: {
        input: {
          id: values.id,
          name: values.name ?? '',
          code: values.code ?? '',
          description: values.description ?? ''
        }
      }
    });
  };

  return (
    <Spin spinning={upsertingVendor}>
      <Form<UpsertVendorInput>
        initialValues={{
          name: vendor?.name || '',
          code: vendor?.code || '',
          description: vendor?.description || ''
        }}
        onSubmit={submitForm}
        render={({ handleSubmit, form }) => (
          <form
            style={{ display: 'flex', alignItems: 'flex-end' }}
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  form.reset();
                  form.resetFieldState('name');
                  form.resetFieldState('code');
                });
              return promise;
            }}
          >
            <div className={s.form_container}>
              <div className={s.row}>
                <FinalFormInput name="name" title="Name" validate={validateRequired} required />
                <FinalFormInput name="code" title="Code" validate={validateRequired} required />
              </div>
              <div>
                <FinalFormInput name="description" title="Description" />
              </div>
            </div>
            <button
              className="ant-btn ant-btn-primary"
              type="submit"
              style={{ marginBottom: '28px', marginRight: '16px' }}
            >
              + Add
            </button>
          </form>
        )}
      ></Form>
    </Spin>
  );
};
