import React from 'react';
import s from './Label.module.scss';
import { Label as RadixLabel } from '@radix-ui/react-label';

interface LabelProps {
  disabled?: boolean;
  error?: string;
  filled?: boolean;
  htmlFor?: string;
  label: string;
  required?: boolean;
  style?: React.CSSProperties;
}

export const Label = ({ disabled, required, error, filled, label, htmlFor, style }: LabelProps) => {
  return (
    <RadixLabel
      className={s.label}
      aria-disabled={disabled}
      aria-required={required}
      data-haserror={!!error}
      data-filled={filled}
      htmlFor={htmlFor}
      style={style}
    >
      {label}
    </RadixLabel>
  );
};
