import React from 'react';
import s from './ExecutiveIntelligence.module.scss';
import { JsonParam, useQueryParam, withDefault } from 'use-query-params';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { range } from 'lodash-es';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Chargebacks } from './components/Chargebacks/Chargebacks';
import { transformCalendarValueToPeriodWeekYear } from 'components/PeriodCalendar/helpers';
import { useDeepCompareMemo } from 'use-deep-compare';
import { getReferenceDate } from 'common/helpers/getReferenceDate';
import { SettingOutlined } from '@ant-design/icons';

const CURRENT_YEAR = new Date().getFullYear();
const STARTING_YEAR = 2024;

const getDefaultPeriod = () => {
  const { week } = getFinancialInfo(getReferenceDate());
  const end = week;
  return { start: 1, end };
};
const defaultPeriod = getDefaultPeriod();

export const ExecutiveIntelligence = () => {
  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [CURRENT_YEAR],
      ...defaultPeriod
    })
  );

  const fiscalCalendarWeeks = useDeepCompareMemo(() => {
    return transformCalendarValueToPeriodWeekYear(period);
  }, [period]);

  // TODO: make sticky header configurable?
  return (
    <div>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}>Executive Intelligence</PageHeader>
        </div>
        <div className={s.page_filters}>
          <PeriodCalendar
            value={period}
            onChange={setPeriod}
            years={
              STARTING_YEAR === CURRENT_YEAR ? undefined : range(STARTING_YEAR, CURRENT_YEAR + 1)
            }
            // If we have more years, we want to allow to select prev periods, else nope
            pastOnly={STARTING_YEAR === CURRENT_YEAR}
          />
          <AlloyButton size="large" type="primary" icon={<SettingOutlined />} />
        </div>
      </div>
      <div>
        <div className={s.section_wrapper}>
          <h2 className={s.section_title}>Chargebacks</h2>
          <div className={s.section}>
            <Chargebacks fiscalCalendarWeeks={fiscalCalendarWeeks} />
          </div>
        </div>
      </div>
    </div>
  );
};
