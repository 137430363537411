import { Tooltip } from 'antd';
import DatePicker from 'components/DatePicker/DatePicker';
import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import s from './FinalFormDatePicker.module.scss';
import { Moment } from 'moment';

interface FinalFormDatePickerProps {
  name: string;
  title: string;
  disabled?: boolean;
  required?: boolean;
  validate?: FieldValidator<string>;
  parse?: (value: Moment | null | undefined) => string;
  dataType?: 'date' | 'time';
}

export const FinalFormDatePicker = ({
  name,
  title,
  disabled,
  validate,
  required,
  parse,
  dataType
}: FinalFormDatePickerProps) => {
  return (
    <div className={s.field}>
      {required && (
        <Tooltip overlay="Required" className={s.required}>
          *{' '}
        </Tooltip>
      )}
      {title}
      <Field name={name} required validate={validate} parse={parse}>
        {({ input, meta }) => {
          return (
            <div>
              <DatePicker
                {...input}
                autoComplete="off"
                disabled={disabled}
                picker={dataType || 'date'}
                style={{ width: '100%' }}
              />
              <ValidationMessage meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
};
