/*
This component contains source code that will list all the Inventory Allocation details of the generated Optimzer results
User can view Inventory Allocation details for a selected scenario, once the optimizer results are generated.
*/
import React from 'react';
import { Table, message } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import s from './InventoryAllocationTable.module.scss';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { ApolloError } from '@apollo/client';

interface InventoryTableData {
  key: string | undefined;
  dc: string | undefined;
  currentStock: number | undefined;
  optimalStock: number | undefined;
  currentPallets: number;
  optimalPallets: number;
  currentLayers: number | undefined;
  optimalLayers: number | undefined;
}
interface InventoryAllocationProps {
  data: InventoryTableData[] | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}

// Define, set table columns and  map data to display Inventory Allocation details of a scenario
const columns: ColumnsType<InventoryTableData> = [
  {
    title: 'DC',
    dataIndex: 'dc',
    key: 'dc',
    width: 160,
    fixed: 'left',
    sorter: (a, b) => safeLocaleCompare(a.dc, b.dc)
  },
  {
    title: 'CURRENT STOCK',
    dataIndex: 'currentStock',
    key: 'currentStock',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.currentStock, b.currentStock),
    render: (_, { currentStock }) => {
      return currentStock?.toLocaleString('en-US');
    }
  },
  {
    title: 'OPTIMAL STOCK',
    dataIndex: 'optimalStock',
    key: 'optimalStock',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.optimalStock, b.optimalStock),
    render: (_, { optimalStock }) => {
      return optimalStock?.toLocaleString('en-US');
    }
  },
  {
    title: 'CURRENT PALLETS',
    key: 'currentPallets',
    dataIndex: 'currentPallets',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.currentPallets, b.currentPallets),
    render: (_, { currentPallets }) => {
      return currentPallets?.toLocaleString('en-US');
    }
  },
  {
    title: 'OPTIMAL PALLETS',
    dataIndex: 'optimalPallets',
    key: 'optimalPallets',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.optimalPallets, b.optimalPallets),
    render: (_, { optimalPallets }) => {
      return optimalPallets?.toLocaleString('en-US');
    }
  },
  {
    title: 'CURRENT LAYERS',
    dataIndex: 'currentLayers',
    key: 'currentLayers',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.currentLayers, b.currentLayers),
    render: (_, { currentLayers }) => {
      return currentLayers?.toLocaleString('en-US');
    }
  },
  {
    title: 'OPTIMAL LAYERS',
    dataIndex: 'optimalLayers',
    key: 'optimalLayers',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.optimalLayers, b.optimalLayers),
    render: (_, { optimalLayers }) => {
      return optimalLayers?.toLocaleString('en-US');
    }
  }
];

const onChange: TableProps<InventoryTableData>['onChange'] = (filters, sorter, extra) => {};

const InventoryAllocationTable: React.FC<InventoryAllocationProps> = ({ data, error, loading }) => {
  return (
    <div className={s.landingPageBrandTable}>
      {error && message.error(error.message)}
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={onChange}
        data-testid="inv-allInfoPage-inventoryAllocationTable"
        sticky
      />
    </div>
  );
};

export default InventoryAllocationTable;
