import React from 'react';
import { Modal } from 'antd';
import './ConfirmationModal.scss';
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

export interface ConfirmationModalProps {
  visible: boolean;
  message: string;
  details?: JSX.Element;
  confirmed: (confirmed: boolean) => void;
  type?: 'info' | 'warning' | 'deleting';
}

const ConfirmationModal = ({
  visible,
  message,
  confirmed,
  details,
  type = 'info'
}: ConfirmationModalProps) => {
  return (
    <Modal
      className="confirmation_modal"
      open={visible}
      footer={null}
      onCancel={() => confirmed(false)}
      destroyOnClose
    >
      {type === 'deleting' && (
        <div className="deleting_icon">
          <ExclamationCircleOutlined />
        </div>
      )}
      <div className="message">{message}</div>
      {details && <div>{details}</div>}
      <div className="actions" data-testid="confirmation-modal">
        <Button
          className="button"
          size="large"
          onClick={() => confirmed(false)}
          data-testid="cancel"
        >
          Cancel
        </Button>

        <Button
          className={clsx('button', { deleting: type === 'deleting' })}
          type="primary"
          size="large"
          onClick={() => confirmed(true)}
          data-testid="confirm"
          danger={type === 'deleting'}
        >
          Yes, Continue
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
