import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validateRequired } from '../../Common/fields/Utils';
import { FormSelect, FormSelectProps } from 'components/alloy/formFields/FormSelect/FormSelect';

export interface FinalFormMultipleSelectProps extends Omit<FormSelectProps, 'multiple'> {
  name: string;
  validate?: FieldValidator<any>;
}

export const FinalFormMultipleSelect = ({
  name,
  required,
  validate,
  ...props
}: FinalFormMultipleSelectProps) => {
  return (
    <Field
      name={name}
      multiple={true}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
    >
      {({ input, meta }) => {
        return (
          <FormSelect<true>
            {...input}
            {...(props as FormSelectProps<true>)}
            required={required}
            error={meta.error && (meta.modified || meta.touched) ? meta.error : undefined}
            multiple={true}
          />
        );
      }}
    </Field>
  );
};
