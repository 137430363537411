import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AuditTrailValueFragment_AuditTrailListOfPrimitives_Fragment = { __typename: 'AuditTrailListOfPrimitives', values: Array<string> };

export type AuditTrailValueFragment_AuditTrailPrimitive_Fragment = { __typename: 'AuditTrailPrimitive', value: string };

export type AuditTrailValueFragmentFragment = AuditTrailValueFragment_AuditTrailListOfPrimitives_Fragment | AuditTrailValueFragment_AuditTrailPrimitive_Fragment;

export type AuditTrailDiffItemFragment_AuditTrailAddition_Fragment = { __typename: 'AuditTrailAddition', fieldPath: string, value: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } };

export type AuditTrailDiffItemFragment_AuditTrailChange_Fragment = { __typename: 'AuditTrailChange', fieldPath: string, previousValue: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string }, newValue: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } };

export type AuditTrailDiffItemFragment_AuditTrailRemoval_Fragment = { __typename: 'AuditTrailRemoval', fieldPath: string, value: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } };

export type AuditTrailDiffItemFragmentFragment = AuditTrailDiffItemFragment_AuditTrailAddition_Fragment | AuditTrailDiffItemFragment_AuditTrailChange_Fragment | AuditTrailDiffItemFragment_AuditTrailRemoval_Fragment;

export type VendorAuditTrailVersionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type VendorAuditTrailVersionsQuery = { __typename?: 'RootQueryType', vendor?: { __typename?: 'Vendor', id: string, updatedAt: any, name: string, auditTrailVersions: Array<{ __typename?: 'AuditTrailVersion', action: Types.AuditTrailAction, actor: Types.AuditTrailActor, appliedAt: any, diff: Array<{ __typename: 'AuditTrailAddition', fieldPath: string, value: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } } | { __typename: 'AuditTrailChange', fieldPath: string, previousValue: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string }, newValue: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } } | { __typename: 'AuditTrailRemoval', fieldPath: string, value: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } }>, user?: { __typename?: 'User', id: string, name: string, email: string } | null }> } | null };

export const AuditTrailValueFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuditTrailValueFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailPrimitive"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailListOfPrimitives"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"values"}}]}}]}}]} as unknown as DocumentNode<AuditTrailValueFragmentFragment, unknown>;
export const AuditTrailDiffItemFragmentFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuditTrailDiffItemFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailDiffItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailAddition"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fieldPath"}},{"kind":"Field","name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuditTrailValueFragment"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailRemoval"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fieldPath"}},{"kind":"Field","name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuditTrailValueFragment"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuditTrailChange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fieldPath"}},{"kind":"Field","name":{"kind":"Name","value":"previousValue"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuditTrailValueFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"newValue"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuditTrailValueFragment"}}]}}]}}]}},...AuditTrailValueFragmentFragmentDoc.definitions]} as unknown as DocumentNode<AuditTrailDiffItemFragmentFragment, unknown>;
export const VendorAuditTrailVersionsDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"vendorAuditTrailVersions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"ignoreAllowedTradingPartners"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"auditTrailVersions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"action"}},{"kind":"Field","name":{"kind":"Name","value":"actor"}},{"kind":"Field","name":{"kind":"Name","value":"diff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuditTrailDiffItemFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"appliedAt"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]}},...AuditTrailDiffItemFragmentFragmentDoc.definitions]} as unknown as DocumentNode<VendorAuditTrailVersionsQuery, VendorAuditTrailVersionsQueryVariables>;