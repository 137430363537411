import { Col, Row } from 'antd';
import { ModalAction } from 'common/constants';
import { useEnumValue } from 'common/useEnumValue';
import {
  getFieldNameBySlug,
  getShowCountData,
  getStringRules
} from 'pages/AssortmentPage/fieldsHelper';
import { FinalFormInput } from 'components/FinalFormFields/FinalFormInput/FinalFormInput';
import { FinalFormSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormSelect';
import { FinalFormMultipleSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormMultipleSelect';
import { FinalFormCheckbox } from 'components/FinalFormFields/FinalFormCheckbox/FinalFormCheckbox';
import {
  composeValidators,
  getMatchRegExpValidation,
  validateRequired
} from 'components/Common/fields/Utils';
import React, { useMemo } from 'react';
import { Field, useForm } from 'react-final-form';
import { ExternalIdField, VendorProduct } from '../ExternalIdField';
import { Product, UpcField } from '../UpcField';
import { CatalogProduct, CatalogProductUpcField } from '../CatalogProductUpcField';
import {
  AssortmentConfig,
  DistributionCenter,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import { DeliveryDestination } from '../../EditTradingPartnerAssortment';
import { FormApi } from 'final-form';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { FormSelect } from 'components/alloy/formFields/FormSelect/FormSelect';
import { FinalFormInputNumber } from 'components/FinalFormFields/FinalFormInputNumber/FinalFormInputNumber';
import s from './FormFieldComponent.module.scss';
import { capitalize } from 'lodash-es';

export const Condition = ({ when, is, children }: any) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const getValidators = (formField: ExtractedFormField | FormField) => {
  const validators = [];
  if (formField.required) {
    validators.push(validateRequired);
  }
  if (formField.validation?.regexp) {
    // Temporary decision. Should be replaced after adding field types on backend
    const lengthRule = formField.validation.rules.find((rule) => rule.value);
    if (lengthRule) {
      validators.push((value: string | number) =>
        value === 0 || value === '0' ? 'Should not be 0' : null
      );
    }
    validators.push(
      getMatchRegExpValidation(
        formField.validation?.regexp,
        `Must be ${getStringRules(formField.validation.rules)}`
      )
    );
  }
  return validators;
};

const getSpecialAssortmentField = (deliveryDestinations: DeliveryDestination[]) => (
  <>
    <FinalFormCheckbox
      data-testid="aa-edit-modal-vendor-flex-checkbox"
      name="vendorFlex"
      label="Vendor Flex"
      inline
    />
    <Condition when="vendorFlex" is={true}>
      <div className={s.field}>
        Vendor Flex Destinations
        <Field name="vendorFlexLocations">
          {({ input, meta }) => {
            return (
              <FormSelect<true>
                {...input}
                multiple={true}
                placeholder="Flex locations"
                data-testid="aa-edit-modal-vendor-flex-locations-select"
                options={deliveryDestinations
                  .filter((rdd) => rdd.specialAssortment)
                  ?.map(({ id, name }) => ({
                    label: name || '',
                    value: id
                  }))}
              />
            );
          }}
        </Field>
      </div>
    </Condition>
  </>
);

const getDistributionCentersField = (
  formField: ExtractedFormField | FormField,
  distributionCenters: DistributionCenter[]
) => (
  <FinalFormMultipleSelect
    name="distributionCenters"
    data-testid="aa-edit-modal-distribution-centers-select"
    label={formField.name}
    options={distributionCenters.map(({ id, name, code }) => ({
      value: id,
      label: `${name} ${code}`
    }))}
    required={formField.required}
    validate={formField.required ? validateRequired : undefined}
  />
);

const getSelectField = (formField: ExtractedFormField | FormField, values: string[]) => (
  <FinalFormSelect
    data-testid={`aa-edit-modal-${getFieldNameBySlug(formField.slug || '')}-select`}
    name={getFieldNameBySlug(formField.slug || '')}
    label={formField.name}
    options={values.map((value) => ({
      value: value,
      label: value
    }))}
    required={formField.required}
  />
);

const getNumberField = (formField: ExtractedFormField | FormField, tabKey?: string) => (
  <FinalFormInputNumber
    data-testid={`aa-edit-modal-${
      tabKey
        ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
        : getFieldNameBySlug(formField.slug || '')
    }-field`}
    name={
      tabKey
        ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
        : getFieldNameBySlug(formField.slug || '')
    }
    required={formField.required}
    validate={composeValidators(getValidators(formField))}
    title={formField.name}
    showCount={getShowCountData(formField)}
  />
);

const getRowOfFields = (
  formField: ExtractedFormField | FormField,
  associatedFields: string[],
  assortmentConfig: AssortmentConfig,
  tabKey?: string
) => {
  const firstFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[0]);
  const secondFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[1]);
  const thirdFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[2]);
  if (formField.slug === associatedFields[0]) {
    return !secondFormField && !thirdFormField ? (
      getNumberField(formField, tabKey)
    ) : (
      <Row gutter={16}>
        <Col span={secondFormField && thirdFormField ? 8 : 12}>
          {getNumberField(formField, tabKey)}
        </Col>
        {secondFormField && (
          <Col span={secondFormField && thirdFormField ? 8 : 12}>
            {getNumberField(secondFormField, tabKey)}
          </Col>
        )}
        {thirdFormField && (
          <Col span={secondFormField && thirdFormField ? 8 : 12}>
            {getNumberField(thirdFormField, tabKey)}
          </Col>
        )}
      </Row>
    );
  } else if (formField.slug === associatedFields[1]) {
    return !firstFormField ? (
      thirdFormField ? (
        <Row gutter={16}>
          <Col span={12}>{getNumberField(formField, tabKey)}</Col>
          <Col span={12}>{getNumberField(thirdFormField, tabKey)}</Col>
        </Row>
      ) : (
        getNumberField(formField, tabKey)
      )
    ) : null;
  } else {
    return !firstFormField && !secondFormField ? getNumberField(formField, tabKey) : null;
  }
};

interface FormFieldComponentProps {
  isNewAssortmentEnabled?: boolean;
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
  distributionCenters: DistributionCenter[];
  deliveryDestinations: DeliveryDestination[];
  vendorId: string;
  tabKey?: string;
  changeToUpdate: (product: VendorProduct) => void;
  action: ModalAction;
  fillFormWithProduct: (product: Product, form: FormApi) => void;
  fillFormWithCatalogProduct: (product: CatalogProduct, form: FormApi) => void;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
}

export const FormFieldComponent = ({
  isNewAssortmentEnabled,
  formField,
  assortmentConfig,
  distributionCenters,
  deliveryDestinations,
  vendorId,
  action,
  changeToUpdate,
  tabKey,
  fillFormWithProduct,
  fillFormWithCatalogProduct,
  retailerProductExternalIdType
}: FormFieldComponentProps) => {
  const form = useForm();
  const { enumValues: moqUnitOfMeasureEnums } = useEnumValue(
    'TradingPartnerActiveAssortmentMoqUnitOfMeasure'
  );
  const { enumValues: requiredOrderUnitOfMeasureEnums } = useEnumValue(
    'TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure'
  );

  const conditionalUpcField = useMemo(() => {
    if (isNewAssortmentEnabled) {
      return (
        <CatalogProductUpcField
          vendorId={vendorId}
          action={action}
          formField={formField}
          tabKey={tabKey}
          fillFormWithCatalogProduct={(product) => fillFormWithCatalogProduct(product, form)}
          assortmentConfig={assortmentConfig}
        />
      );
    } else {
      return (
        <UpcField
          vendorId={vendorId}
          action={action}
          formField={formField}
          tabKey={tabKey}
          fillFormWithProduct={(product) => fillFormWithProduct(product, form)}
          assortmentConfig={assortmentConfig}
        />
      );
    }
  }, [
    action,
    assortmentConfig,
    fillFormWithCatalogProduct,
    fillFormWithProduct,
    form,
    formField,
    isNewAssortmentEnabled,
    tabKey,
    vendorId
  ]);

  if (!formField.slug) return null;

  switch (formField.slug) {
    case 'DISTRIBUTION_CENTER_IDS':
      return getDistributionCentersField(formField, distributionCenters);
    case 'SPECIAL_ASSORTMENT':
      return getSpecialAssortmentField(deliveryDestinations);
    case 'CASES_PER_PALLET':
    case 'GROSS_WEIGHT':
      return getRowOfFields(
        formField,
        ['CASES_PER_PALLET', 'GROSS_WEIGHT'],
        assortmentConfig,
        tabKey
      );
    case 'WIDTH':
    case 'HEIGHT':
    case 'DEPTH':
      return getRowOfFields(formField, ['WIDTH', 'HEIGHT', 'DEPTH'], assortmentConfig, tabKey);
    case 'PRICE':
    case 'MOQ_MINIMUM':
    case 'CASES_PER_LAYER':
    case 'LAYERS_PER_PALLET':
    case 'TO_CASE_QUANTITY':
    case 'INVENTORY_RESERVE':
      return getNumberField(formField, tabKey);
    case 'MOQ_UNIT_OF_MEASURE':
      return getSelectField(formField, moqUnitOfMeasureEnums || []);
    case 'REQUIRED_ORDER_UNIT_OF_MEASURE':
      return getSelectField(formField, requiredOrderUnitOfMeasureEnums || []);
    case 'NAME':
      return (
        <FinalFormInput
          data-testid={`aa-edit-modal-${
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
              : getFieldNameBySlug(formField.slug || '')
          }-field`}
          name={
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug)}`
              : getFieldNameBySlug(formField.slug)
          }
          title={formField.name}
          textarea
          validate={composeValidators(getValidators(formField))}
          required={formField.required}
          showCount={getShowCountData(formField)}
        />
      );
    case 'SHIPS_IN_OWN_CONTAINER':
      return (
        <FinalFormCheckbox
          data-testid={`aa-edit-modal-${
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
              : getFieldNameBySlug(formField.slug || '')
          }-checkbox`}
          inline
          name={
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug)}`
              : getFieldNameBySlug(formField.slug)
          }
          label={formField.name}
        />
      );
    case 'UPC':
      return conditionalUpcField;
    case 'PRODUCT_TYPE':
      return <ProductTypeField formField={formField} tabKey={tabKey} />;
    case 'EXTERNAL_ID':
      return (
        <ExternalIdField
          vendorId={vendorId}
          changeToUpdate={changeToUpdate}
          action={action}
          formField={formField}
          retailerProductExternalIdType={retailerProductExternalIdType}
          assortmentConfig={assortmentConfig}
        />
      );
    default:
      return (
        <FinalFormInput
          data-testid={`aa-edit-modal-${
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
              : getFieldNameBySlug(formField.slug || '')
          }-field`}
          name={
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug)}`
              : getFieldNameBySlug(formField.slug)
          }
          title={formField.name}
          validate={composeValidators(getValidators(formField))}
          required={formField.required}
          showCount={getShowCountData(formField)}
        />
      );
  }
};

const ProductTypeField = ({
  formField,
  tabKey
}: {
  formField: ExtractedFormField | FormField;
  tabKey?: string;
}) => {
  const { enumValues: productTypeEnums } = useEnumValue('ProductType');

  return (
    <FinalFormSelect
      label={formField.name ?? 'Product Type'}
      data-testid={`aa-edit-modal-${tabKey ? `${tabKey}.` : ''}product-type-field`}
      name={tabKey ? `${tabKey}.productProductType` : 'productProductType'}
      required={formField.required}
      options={productTypeEnums.map((pt) => ({
        label: capitalize(pt),
        value: pt
      }))}
      allowClear={!formField.required}
    />
  );
};
