import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTable, SorterResult, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import { SearchField } from 'components/Common/SearchField/SearchField';
import { Paginator } from 'components/Paginator/Paginator';
import { UpsertVendorInput, VendorSortColumn } from 'graphql/__generated__/types';
import { VendorsDocument, VendorsQuery } from './gql/__generated__/vendors.query';
import { RetailerViewDrawer } from './components/ViewRetailer/RetailerViewDrawer';
import { EditRetailerModal } from './components/EditRetailer/EditRetailerModal';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useQuery } from '@apollo/client';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { SortOrderDirection } from 'graphql/__generated__/enums';
import { getColumnSortOrder } from 'common/helpers/sorting';
import s from './RetailersPage.module.scss';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';

export type Vendor = InferNodeType<VendorsQuery, 'vendors'>;

export const RetailersPage = () => {
  const [editingRetailer, setEditingRetailer] = useState<UpsertVendorInput>();
  const [viewChangeHistoryRetailer, setViewHistoryRetailer] = useState<Vendor>();

  const [viewRetailerId, setViewRetailerId] = useQueryParam('retailer', StringParam);
  const [pageSize, setPageSize] = useQueryParam('limit', NumberParam);
  const [sortColumn, setSortColumn] = useQueryParam('column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order', StringParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);

  const { data, loading, error } = useQuery(VendorsDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      after: after,
      before: before,
      sort: {
        column: sortColumn as VendorSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      searchLike: search
    },
    skip: !pageSize || !sortColumn || !sortOrder
  });

  const vendorsList = useMemo<Vendor[]>(() => getNodesFromEdges(data?.vendors) ?? [], [data]);

  useEffect(() => {
    // needs to show this params in URL every time (in the first openning too)
    if (!sortColumn || !sortOrder || !pageSize) {
      setSortColumn('UPDATED_AT');
      setSortOrder('DESC');
      setPageSize(DEFAULT_PAGE_SIZE);
    }
  }, [setSortColumn, setSortOrder, setPageSize, sortColumn, sortOrder, pageSize]);

  useEffect(() => {
    if (viewRetailerId) setViewHistoryRetailer(undefined);
  }, [viewRetailerId]);

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== (value || undefined)) {
        setSearch(value || undefined);
        setAfter(undefined);
        setBefore(undefined);
      }
    },
    [search, setAfter, setBefore, setSearch]
  );

  if (error)
    return <ErrorDisplay error={error} header="An error occurred while loading retailers" />;

  const columns: ColumnsType<Vendor> = [
    {
      title: 'Name',
      render: (_, record) => <HighlightedText text={record.name} highlight={search ?? ''} />,
      key: 'NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'NAME'),
      sorter: true,
      width: '30%'
    },
    {
      title: 'Code',
      render: (_, record) => <HighlightedText text={record.code || ''} highlight={search ?? ''} />
    },
    {
      title: 'Last updated',
      width: 160,
      key: 'UPDATED_AT',
      render: (_, record) => (
        <UpdatedAt
          onClick={(e) => {
            setViewHistoryRetailer(record);
            e.stopPropagation();
          }}
          updatedAt={record.updatedAt}
        />
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'UPDATED_AT'),
      sorter: true
    }
  ];

  const nextPage = async () => {
    setAfter(data?.vendors?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.vendors?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setAfter(undefined);
    setBefore(undefined);
    setPageSize(value);
  };

  const handleTableSorting = (column: string, order: string) => {
    setAfter(undefined);
    setBefore(undefined);
    setSortColumn(column);
    setSortOrder(order);
  };

  return (
    <div className={s.page}>
      <div className={s.header}>
        <PageHeader className={s.trading_partners_title}>Retailers</PageHeader>
        <div>
          <AlloyButton
            onClick={() => {
              setEditingRetailer({} as UpsertVendorInput);
            }}
            data-testid="add-new-retailer"
          >
            Add new Retailer
          </AlloyButton>
        </div>
      </div>
      <SearchField handleSearch={handleSearch} loading={loading} value={search ?? ''} />
      <div className={s.pagination_container}>
        <QuantityDisplay
          count={data?.vendors?.totalCount}
          titleSingular="Retailer"
          titleMultiple="Retailers"
        />
        <Paginator
          hasNextPage={!!data?.vendors?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.vendors?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={false}
        />
      </div>
      <AlloyTable
        loading={loading}
        sticky
        tableLayout="auto"
        columns={columns}
        dataSource={vendorsList}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              setViewRetailerId(record.id);
            }
          };
        }}
        onChange={(_, __, sorter) => {
          const column = String((sorter as SorterResult<Vendor>).columnKey) || 'UPDATED_AT';
          const order =
            (sorter as SorterResult<Vendor>).order === 'ascend' ||
            !(sorter as SorterResult<Vendor>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
      />
      <div className={s.pagination_container_end}>
        <Paginator
          hasNextPage={!!data?.vendors?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.vendors?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={true}
        />
      </div>
      <RetailerViewDrawer
        onClose={() => setViewRetailerId(undefined)}
        vendorId={editingRetailer || !viewRetailerId ? undefined : viewRetailerId}
        startEditing={(retailer) => {
          setEditingRetailer({
            id: retailer.id,
            code: retailer.code ?? '',
            description: retailer.description,
            name: retailer.name
          });
        }}
      />
      <EditRetailerModal
        vendor={editingRetailer}
        onClose={() => {
          setEditingRetailer(undefined);
          setViewRetailerId(undefined);
        }}
      />
      <ChangeHistory
        entity={
          viewChangeHistoryRetailer
            ? {
                id: viewChangeHistoryRetailer.id || '',
                name: viewChangeHistoryRetailer.name || '',
                type: 'Retailer'
              }
            : undefined
        }
        onClose={() => setViewHistoryRetailer(undefined)}
      />
    </div>
  );
};
