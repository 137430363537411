import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';

interface UploadFileModalProps {
  visible: boolean;
  toggleUploadFileModal: () => void;
  onConfirmUpload: (file: File) => Promise<void>;
  uploadButtonTitle?: string;
}

export const UploadFileModal = ({
  visible,
  toggleUploadFileModal,
  onConfirmUpload,
  uploadButtonTitle
}: UploadFileModalProps) => {
  const ref = useRef<HTMLLabelElement>(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState<File>();

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const handleUpload = async (event: any) => {
    const {
      target: {
        validity,
        files: [file]
      }
    } = event;
    if (validity.valid) {
      setFile(file);
      nextStep();
    }
  };

  const steps = [
    {
      title: 'Select file',
      content: (
        <div>
          <input
            id="import-seed-data-input11"
            accept=".yaml"
            type="file"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <label htmlFor="import-seed-data-input11" ref={ref}>
            <AlloyButton
              onClick={() => {
                ref.current?.click();
              }}
            >
              <UploadOutlined /> Click to Upload
            </AlloyButton>
          </label>
        </div>
      )
    },
    {
      title: 'Confirm',
      content: `Confirm importing data from ${file?.name}`
    }
  ];

  return (
    <AlloyModal
      title="Upload File"
      open={visible}
      className="upload_file_modal"
      footer={
        <div>
          <AlloyButton style={{ marginRight: '8px' }} onClick={toggleUploadFileModal}>
            Cancel
          </AlloyButton>
          {currentStep === steps.length - 1 && file && (
            <>
              <AlloyButton style={{ marginRight: '8px' }} onClick={prevStep}>
                Previous
              </AlloyButton>
              <AlloyButton type="primary" onClick={() => onConfirmUpload(file)}>
                {uploadButtonTitle || 'Upload'}
              </AlloyButton>
            </>
          )}
        </div>
      }
      onCancel={toggleUploadFileModal}
      destroyOnClose
    >
      <AlloySteps current={currentStep} items={steps.map((item) => ({ title: item.title }))} />
      <div className="steps-content">{steps[currentStep].content}</div>
    </AlloyModal>
  );
};
