import React, { useState, useEffect } from 'react';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/es/upload/interface';
import Papa from 'papaparse';
import './ModalSteps.scss';
import { PurchaseOrderFileItem } from 'common/interfaces';
import { ItemsToUpdateFromFile } from 'common/types';
import { PurchaseOrderItem } from 'pages/OrderDetailsPage/types';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyUpload } from 'components/ui/AlloyUpload/AlloyUpload';

interface ModalStepsProps {
  closeModal: () => void;
  updatePurchaseOrderQuantity: (
    itemsToUpdate: ItemsToUpdateFromFile[],
    itemsToDelete: PurchaseOrderItem[]
  ) => void;
  itemsForCompare?: PurchaseOrderItem[];
  poNumber?: string;
  loading?: boolean;
}

const ModalSteps = ({
  closeModal,
  itemsForCompare,
  updatePurchaseOrderQuantity,
  poNumber,
  loading = false
}: ModalStepsProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fileData, setFileData] = useState<PurchaseOrderFileItem[]>([]);
  const [dataToDelete, setDataToDelete] = useState<PurchaseOrderItem[]>([]);
  const [dataToError, setDataToError] = useState<PurchaseOrderFileItem[]>([]);
  const [dataIncomplete, setDataIncomplete] = useState<PurchaseOrderFileItem[]>([]);
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const compareData = (
    itemsFromFile: PurchaseOrderFileItem[],
    itemsForCompare?: PurchaseOrderItem[]
  ) => {
    if (itemsForCompare) {
      const arrayToDelete = itemsForCompare.filter((item) => {
        const gtin = item?.product?.upc || '';
        return !itemsFromFile.map((item) => item.GTIN).includes(gtin);
      });

      const arrayToError = itemsFromFile.filter(
        (compareItem) =>
          !itemsForCompare.map((item) => item?.product?.upc).includes(compareItem.GTIN)
      );

      setDataToDelete(arrayToDelete);
      setDataToError(arrayToError);
    } else {
      const arrayIncomplete = itemsFromFile.filter((item) => !item['PO #']);
      setDataIncomplete(arrayIncomplete);
      return arrayIncomplete;
    }
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const parseData = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data, errors } = results;
        if (errors.length) {
          console.log(errors);
        }
        setFileData(data as PurchaseOrderFileItem[]);
        const erroredData = compareData(data as PurchaseOrderFileItem[], itemsForCompare);

        if (!poNumber && !erroredData?.length) {
          setIsLoading(true);
          updatePurchaseOrderQuantity(data as PurchaseOrderFileItem[], dataToDelete);
        } else {
          nextStep();
        }
      }
    });
  };

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    customRequest: dummyRequest,
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        parseData(file.originFileObj as File);
      }
    }
  };

  const bulkChange = (data?: PurchaseOrderFileItem[]) => {
    updatePurchaseOrderQuantity(data || fileData, dataToDelete);
    closeModal();
  };

  let columns: ColumnsType<PurchaseOrderFileItem> = [
    {
      title: 'GTIN',
      key: 'GTIN',
      dataIndex: 'GTIN'
    },
    {
      title: 'Quantity',
      key: 'Qty',
      dataIndex: 'Qty'
    },
    {
      title: 'Item Description',
      key: 'Item Description',
      dataIndex: 'Item Description'
    }
  ];

  if (!poNumber) {
    columns.unshift({
      title: 'PO #',
      key: 'PO #',
      dataIndex: 'PO #'
    });
  }

  const getData = () => {
    if (dataToError?.length) return dataToError;
    else if (dataIncomplete?.length) return dataIncomplete;
    else return fileData;
  };

  const steps = [
    {
      title: 'Select file',
      content: (
        <AlloyUpload {...uploadProps}>
          <AlloyButton>
            <UploadOutlined /> Click to Upload
          </AlloyButton>
        </AlloyUpload>
      )
    },
    {
      title: `${!poNumber ? 'Upload' : 'Review Data'}`,
      content: (
        <>
          {dataToError?.length > 0 && (
            <div className="upload_file_error">
              These GTIN's are not in the PO
              <br /> Please, remove them from file and retry the upload.
            </div>
          )}
          {dataIncomplete?.length > 0 && (
            <div className="upload_file_error">
              All rows require a PO #
              <br /> Please, remove them from file or include a PO # and retry the upload.
            </div>
          )}
          <AlloyTable
            className="orders_table"
            dataSource={getData()}
            columns={columns}
            rowKey={(record) => record.GTIN}
            pagination={false}
          />
        </>
      )
    }
  ];

  if (isLoading) {
    return (
      <div className="uploadloader">
        <AlloySpin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div>
    );
  }

  return (
    <>
      <AlloySteps current={currentStep} items={steps.map((item) => ({ title: item.title }))} />
      <div className="steps-content">{steps[currentStep].content}</div>
      <div>
        {currentStep === steps.length - 1 &&
          (dataToError?.length > 0 || dataIncomplete?.length ? (
            <AlloyButton type="primary" style={{ margin: 8 }} onClick={prevStep}>
              Previous
            </AlloyButton>
          ) : (
            <AlloyButton type="primary" onClick={() => bulkChange()}>
              Set quantities
            </AlloyButton>
          ))}
      </div>
    </>
  );
};

export default ModalSteps;
