import React, { useState } from 'react';
import './styles.scss';
import { Modal, Table, message } from 'antd';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { RetailerDeliveryDestinationFromList } from 'pages/ShipToPage/ShipToPage';
import { UpdateStatusOfRddsDocument } from 'pages/ShipToPage/gql/__generated__/updateStatusOfRdds.mutation';
import { ColumnsType } from 'antd/lib/table';
import { safeLocaleCompare } from 'common/helpers/comparators';

interface BulkActivateStoreModalProps {
  storesToChangeActivating: RetailerDeliveryDestinationFromList[];
  show: boolean;
  onClose: (completed: boolean) => void;
  active: boolean;
}

export const BulkActivateStoreModal = ({
  storesToChangeActivating,
  show,
  onClose,
  active
}: BulkActivateStoreModalProps) => {
  const [updateStatusOfRdds] = useMutation(UpdateStatusOfRddsDocument, {
    refetchQueries: ['retailerDeliveryDestinations']
  });
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    const updatedStores = storesToChangeActivating.map((rdd) => {
      return {
        ...rdd,
        active: false
      };
    });

    try {
      await updateStatusOfRdds({
        variables: {
          input: {
            ids: updatedStores.map((store) => store.id).filter((id): id is string => !!id),
            active: active
          }
        }
      });

      message.success(
        `${storesToChangeActivating.length} Ship-Tos ${active ? 'activated' : 'deactivated'}`
      );
      onClose(true);
    } catch (error) {
      Sentry.addBreadcrumb({
        message: `Deactivate rdds: ${JSON.stringify(storesToChangeActivating)}`
      });
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<RetailerDeliveryDestinationFromList> = [
    {
      title: 'STORE',
      key: 'externalId',
      render: (_, rdd) => rdd.externalId
    },
    {
      title: 'REGION',
      render: (_, rdd) => {
        let address = `${rdd.addressCity ?? ''}`;
        if (address.length && rdd.addressState) address += `, ${rdd.addressState}`;
        else address += `${rdd.addressState ?? ''}`;
        return address;
      }
    },
    {
      title: 'TRADING PARTNERS',
      render: (_, rdd) =>
        rdd.tradingPartners
          .map((tradingPartner) => tradingPartner.name)
          .sort((a, b) => safeLocaleCompare(a, b))
          .join(', ')
    }
  ];

  return (
    <Modal
      open={show}
      className="bulk-remove-store-modal"
      okText={active ? 'Activate' : 'Deactivate'}
      okButtonProps={{
        danger: !active,
        loading
      }}
      onOk={submit}
      onCancel={() => onClose(false)}
      title="Confirm updates"
    >
      <section>
        <div className="title">
          {active ? 'Activate' : 'Deactivate'} Ship-Tos ({storesToChangeActivating.length})
        </div>
        <Table
          dataSource={storesToChangeActivating}
          columns={columns}
          pagination={false}
          rowKey={(rdd) => rdd.externalId || ''}
        />
      </section>
    </Modal>
  );
};
