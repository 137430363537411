import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { PageInfo } from 'graphql/__generated__/types';
import { useQueryParam, withDefault, StringParam, NumberParam } from 'use-query-params';

interface UsePaginatorProps {
  pageInfo?: PageInfo;
  scrollToTop?: boolean;
  setBefore: (value: string | null | undefined) => void;
  setAfter: (value: string | null | undefined) => void;
  pageLimit: number;
  setPageLimit: (value: number) => void;
}

interface UsePaginationProps {
  before: string | undefined | null;
  after: string | undefined | null;
  pageLimit: number;
}

/**
 * Custom hook to create all required functions for paginator and for queries using paginator.
 *
 * @returns An object containing filters for queries using the paginator.
 */
export const usePaginationFilters = ({ before, after, pageLimit }: UsePaginationProps) => {
  const filters = {
    before,
    after,
    first: after || !before ? pageLimit : undefined,
    last: !after && before ? pageLimit : undefined
  };

  return filters;
};

/**
 * Custom hook to create all required functions for paginator and for queries using paginator.
 *
 * @returns An object containing  props for Paginator component
 */
export const usePaginatorProps = ({
  pageInfo,
  scrollToTop = false,
  setBefore,
  setAfter,
  pageLimit,
  setPageLimit
}: UsePaginatorProps) => {
  const hasNextPage = !!pageInfo?.hasNextPage;
  const hasPreviousPage = !!pageInfo?.hasPreviousPage;

  const handlePageSizeChange = (value: number) => {
    setPageLimit(value);
    if (scrollToTop) window.scrollTo(0, 0);
  };

  const nextPage = () => {
    setAfter(pageInfo?.endCursor);
    setBefore(null);
    if (scrollToTop) window.scrollTo(0, 0);
  };

  const prevPage = () => {
    setAfter(null);
    setBefore(pageInfo?.startCursor);
    if (scrollToTop) window.scrollTo(0, 0);
  };

  const props = {
    hasNextPage,
    hasPreviousPage,
    nextPage,
    prevPage,
    pageSize: pageLimit,
    handlePageSizeChange
  };

  return props;
};
/**
 * Custom hook to create all the state required for paginator
 */
export const usePaginatorStateInQueryParams = () => {
  const [after, setAfter] = useQueryParam('after', withDefault(StringParam, null));
  const [before, setBefore] = useQueryParam('before', withDefault(StringParam, null));

  const [pageLimit, setPageLimit] = useQueryParam(
    'limit',
    withDefault(NumberParam, DEFAULT_PAGE_SIZE)
  );

  return {
    after,
    setAfter,
    before,
    setBefore,
    pageLimit,
    setPageLimit
  };
};
