import React, { useState } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { prepareRecordsXLSXForExport } from 'common/helpers/tableExportAlloy';
import { InventoryRepackFilters, RepackOptimizerStage } from 'graphql/__generated__/types';
import { utils as XLSXutils, writeFileXLSX } from '@/src/non_npm_dependencies/xlsx';
import { RepackPlanningExportDocument } from './gql/__generated__/repackPlanningExport.query';
import moment from 'moment';

import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import s from './Export.module.scss';
import { getFulfillColumns } from '../FulfillTable/FulfillTable';
import { getCutsColumns } from '../CutsTable/CutsTable';
import { notEmpty } from 'common/helpers/notEmpty';

const getStageNumber = (stage: RepackOptimizerStage | undefined | null): number => {
  const stageMap: { [key in RepackOptimizerStage]: number } = {
    MAIN: 1,
    SECOND: 2
  };

  return stageMap[stage || 'MAIN'] || 1;
};

const prepareOrdersForExport = async (
  client: ApolloClient<object>,
  filters: InventoryRepackFilters,
  periodString: string
) => {
  const { data } = await client.query({
    query: RepackPlanningExportDocument,
    variables: { filters }
  });

  const {
    inventoryRepackWeeklyTotalsReport,
    inventoryRepackFulfillReport,
    inventoryRepackCutsReport,
    inventoryRepackCutsComponentsReport
  } = data;

  const commonTotalColumns = {
    'Period/Week': { v: periodString },
    'Date/Time of Export': { v: moment().format('MM/DD/YYYY h:mm A'), t: 'd' },
    Step: getStageNumber(filters.stage)
  };

  const stageSpecificTotalColumns =
    getStageNumber(filters.stage) === 1
      ? {
          'In Inventory': {
            v: inventoryRepackWeeklyTotalsReport?.metrics.inInventoryFillRate,
            t: 'n',
            z: '0.00\\%'
          },
          'Projected Fill Rate': {
            v: inventoryRepackWeeklyTotalsReport?.metrics.projectedFillRate,
            t: 'n',
            z: '0.00\\%'
          },
          Orders: {
            v: inventoryRepackWeeklyTotalsReport?.metrics?.poQuantity,
            t: 'n',
            z: '#,##0.000'
          }
        }
      : {
          'Produced/Forecast': {
            v: inventoryRepackWeeklyTotalsReport?.metrics.forecastFillRate,
            t: 'n',
            z: '0.00\\%'
          },
          'Total Forecasted Demand': {
            v: inventoryRepackWeeklyTotalsReport?.metrics?.forecastedDemand,
            t: 'n',
            z: '#,##0.000'
          }
        };

  const totals = [
    {
      ...commonTotalColumns,
      ...stageSpecificTotalColumns
    }
  ];

  const fulfills = prepareRecordsXLSXForExport(
    inventoryRepackFulfillReport?.metrics || [],
    getFulfillColumns()
  );
  const cuts = prepareRecordsXLSXForExport(
    inventoryRepackCutsReport?.metrics || [],
    getCutsColumns()
  ).map((cut) => {
    // Will fail as soon as we rename the column.
    const foundDetails = inventoryRepackCutsComponentsReport?.metrics?.find(
      (details) => details.itemId === cut['Item ID']
    );
    const cutComponentIds = (foundDetails?.components || [])
      .map((x) => x?.componentId)
      .filter(notEmpty)
      .join(',');
    const cutComponentNames = (foundDetails?.components || [])
      .map((x) => x?.componentName)
      .filter(notEmpty)
      .join(',');

    return {
      ...cut,
      'Cut Component IDs': {
        v: cutComponentIds,
        t: 's'
      },
      'Cut Component Names': cutComponentNames
    };
  });

  return {
    totals,
    fulfills,
    cuts
  };
};

export const Export = ({
  filters,
  isExportModalVisible,
  setIsExportModalVisible,
  periodString
}: {
  filters: InventoryRepackFilters;
  isExportModalVisible: boolean;
  setIsExportModalVisible: (value: boolean) => void;
  periodString: string;
}) => {
  const client = useApolloClient();

  const [exportAsync, setExportAsync] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });

  const exportViewData = async () => {
    try {
      setExportAsync({ loading: true, error: false });
      const { totals, fulfills, cuts } = await prepareOrdersForExport(
        client,
        filters,
        periodString
      );

      const step = getStageNumber(filters.stage);
      const workbook = XLSXutils.book_new();

      const totalsSheet = XLSXutils.json_to_sheet(totals);
      // TODO: move into function?
      totalsSheet['!cols'] = [
        { wpx: 70 },
        { wpx: 120 },
        { wpx: 30 },
        { wpx: 100 },
        { wpx: step === 1 ? 100 : 130 },
        { wpx: 100 }
      ];

      XLSXutils.book_append_sheet(workbook, totalsSheet, 'Totals');

      const fulfillSheet = XLSXutils.json_to_sheet(fulfills);
      // TODO: move into function?
      fulfillSheet['!cols'] = [
        { wpx: 60 },
        { wpx: 400 },
        { wpx: 75 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 80 }
      ];
      XLSXutils.book_append_sheet(workbook, fulfillSheet, step === 1 ? 'Fulfill' : 'Production');

      const custSheet = XLSXutils.json_to_sheet(cuts);
      // TODO: move into function?
      custSheet['!cols'] = [
        { wpx: 60 },
        { wpx: 400 },
        { wpx: 75 },
        { wpx: 80 },
        { wpx: 200 },
        { wpx: 80 },
        { wpx: 85 },
        { wpx: 160 },
        { wpx: 160 }
      ];

      XLSXutils.book_append_sheet(workbook, custSheet, 'Cuts');

      const filename = `repack_planning_step_${getStageNumber(filters.stage)}_${moment().format(
        'MM-DD-YYYY_hh-mm-ss_A'
      )}.xlsx`;
      writeFileXLSX(workbook, filename, { compression: true });

      setIsExportModalVisible(false);
      setExportAsync({
        loading: false,
        error: false
      });
    } catch {
      setExportAsync({ loading: false, error: true });
    }
  };

  return (
    <AlloyModal
      title="Export repack planning"
      open={isExportModalVisible}
      onCancel={() => setIsExportModalVisible(false)}
      destroyOnClose
      footer={
        <>
          <div className={s.modal_buttons}>
            <AlloyButton
              type="secondary"
              size="large"
              onClick={() => setIsExportModalVisible(false)}
              data-testid="repack-planning-export-modal-close-button"
            >
              Cancel
            </AlloyButton>
            <AlloyButton
              size="large"
              style={{ width: 'auto' }}
              type="primary"
              onClick={() => exportViewData()}
              loading={exportAsync.loading}
              data-testid="repack-planning-export-download"
              disabled={exportAsync.loading}
            >
              Download
            </AlloyButton>
          </div>
          {exportAsync.error && (
            <p className={s.error}>
              Error occured while loading <code>.xlsx</code>, try again later.
            </p>
          )}
        </>
      }
    >
      <AlloySpin spinning={exportAsync.loading}>
        <p>
          We will export Repack Planning data for <b>Step {getStageNumber(filters.stage)}</b> of{' '}
          <b>{periodString}</b> into a <code>.xlsx</code> file.
        </p>
      </AlloySpin>
    </AlloyModal>
  );
};
