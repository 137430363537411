import { NOT_AVAILABLE } from 'common/constants';
import { Filter } from 'components/TableFilters/TableFilters';
import { RepackItemType } from 'graphql/__generated__/types';

export const CUTS_SEARCH_QUERY_PARAM = 'cuts_search';
export const CUTS_FILTERS_QUERY_PARAM = 'cuts_filters';

export const FULFILL_SEARCH_QUERY_PARAM = 'fulfill_search';
export const FULFIL_FILTERS_QUERY_PARAM = 'fulfill_filters';

export const TYPE_CONST: Record<RepackItemType | typeof NOT_AVAILABLE, string> = {
  PRODUCED: 'Produced',
  NON_PRODUCED: 'Non-Produced',
  [NOT_AVAILABLE]: NOT_AVAILABLE
};

type FilterKeys = 'type' | 'isMoved';

export const repackPlanningTableFilters: Filter<FilterKeys>[] = [
  {
    name: 'Is Produced',
    type: 'single',
    field: 'type',
    options: [
      {
        name: 'Produced',
        value: 'produced'
      },
      {
        name: 'Non-Produced',
        value: 'non_produced'
      }
    ]
  },
  {
    name: 'Is Moved',
    type: 'single',
    field: 'isMoved',
    options: [
      {
        name: 'Moved',
        value: 'moved'
      },
      {
        name: 'Non-Moved',
        value: 'non_moved'
      }
    ]
  }
];
