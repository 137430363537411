import React, { useRef } from 'react';
import { CSVLink } from 'react-csv';
import s from './ExportPoModal.module.scss';
import { AlignType } from '../../../../common/types';
import { dateFormat } from 'common/helpers/date';
import { PurchaseOrder } from 'pages/OrdersPage/types';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';

interface ExportPoModalProps {
  visibility: boolean;
  toggleModal: () => void;
  selectedRows: PurchaseOrder[];
}

const ExportPoModal = ({ visibility, toggleModal, selectedRows }: ExportPoModalProps) => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const csvData = selectedRows.map((row) => {
    return {
      id: row.id,
      customerPo: row.customerPo || '',
      customer: row.retailerDeliveryDestination?.vendorMarket?.name || '',
      shipTo:
        row.retailerDeliveryDestination?.externalId ||
        row.retailerDeliveryDestinationExternalId ||
        '',
      orderDate: dateFormat(row.orderDate),
      deliveryWindowEnd: dateFormat(row.deliveryWindowEnd),
      bu: row.businessUnit?.code || ''
    };
  });

  const csvHeaders = [
    { label: 'Purchase Order', key: 'customerPo' },
    { label: 'Customer', key: 'customer' },
    { label: 'Ship-To', key: 'shipTo' },
    { label: 'Ordered', key: 'orderDate' },
    { label: 'MABD', key: 'deliveryWindowEnd' },
    { label: 'Business Unit', key: 'bu' }
  ];

  const tableHeaders = [
    {
      title: 'PO #',
      dataIndex: 'customerPo',
      align: 'center' as AlignType
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      align: 'center' as AlignType
    },
    {
      title: 'Ship-To',
      dataIndex: 'shipTo',
      align: 'center' as AlignType
    },
    {
      title: 'Ordered',
      dataIndex: 'orderDate',
      align: 'center' as AlignType
    },
    {
      title: 'MABD',
      dataIndex: 'deliveryWindowEnd',
      align: 'center' as AlignType
    },
    {
      title: 'BU',
      dataIndex: 'bu',
      align: 'center' as AlignType
    }
  ];

  const controls = [
    <AlloyButton key="cancel" onClick={toggleModal}>
      Cancel
    </AlloyButton>,
    <AlloyButton
      key="export"
      type="primary"
      onClick={() => {
        csvLinkRef.current?.link.click();
        toggleModal();
      }}
      disabled={!(csvData.length > 0)}
    >
      Download csv
    </AlloyButton>,
    <CSVLink
      key="purchase-orders"
      filename={'purchase-orders.csv'}
      headers={csvHeaders}
      data={csvData}
      ref={csvLinkRef}
    />
  ];

  return (
    <AlloyModal
      className={s.export_po_modal}
      title={
        <div className={s.header}>
          Export Purchase Orders
          <div key="controls" className={s.controls}>
            {controls}
          </div>
        </div>
      }
      open={visibility}
      onCancel={toggleModal}
      width={900}
      footer={controls}
      closable={false}
      classNames={{
        footer: s.footer
      }}
    >
      <AlloyTable
        rowKey={(record) => record.id}
        pagination={false}
        dataSource={csvData}
        columns={tableHeaders}
        bordered
      />
    </AlloyModal>
  );
};

export default ExportPoModal;
