import React, { useEffect, useState } from 'react';
import s from './NewEventModal.module.scss';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';
import { AlloyInputForEventTracker } from 'components/ui/AlloyInputForEventTracker/AlloyInputForEventTracker';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyDatePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import { AlloyRangePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { InfoCircleFilled, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { EventsTrackerProvider } from 'pages/EventsTracker/EventsTracker.context';
import { FinalFormInput } from 'components/FinalFormFields/FinalFormInput/FinalFormInput';
import { FinalFormSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormSelect';
import { FinalFormCheckbox } from 'components/FinalFormFields/FinalFormCheckbox/FinalFormCheckbox';
import { Form } from 'react-final-form';

interface FormErrors {
  [index: string]: string;
}

interface NewEventModal {
  open: boolean;
  close: () => void;
}

export const NewEventModal = ({ open = false, close }: NewEventModal) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const onClose = () => {
    setCurrentStep(0);
    close();
  };

  const validateEventDetails = (errors: FormErrors) => {};

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const addWatchlist = () => {};

  const EventDetails = (
    <div className={s.event_details}>
      <div className={s.input_group}>
        <AlloyInputForEventTracker
          placeholder="Choose a unique name (eg. Prime day 2024)"
          label="Event name"
        />
        <div className={s.sub_option}>
          <FinalFormCheckbox name="private" />
          <span>Make event private</span>
        </div>
        <AlloyTooltip title="Private events are only visible to you. Public events are visible to any individual with access to the same Trading Partners in Schip.">
          <InfoCircleFilled className={s.info_icon} />
        </AlloyTooltip>
      </div>
      <div className={s.input_group}>
        <div className={s.small_details}>Date range of event hypercare</div>
        <AlloyRangePicker className={s.date_picker} />
      </div>
      <div className={s.input_group}>
        <div>Customer</div>
        <FinalFormSelect
          placeholder="Choose a customer"
          className={s.select}
          name="customer"
          options={[]}
        />
      </div>
      <div className={s.input_group}>
        <div>BU</div>
        <FinalFormSelect placeholder="Choose a BU" className={s.select} name="bu" options={[]} />
      </div>
    </div>
  );

  const EventWatchlist = (
    <div className={s.event_watchlist}>
      <div className={s.upload_prompt}>
        <div className={s.label}>
          Add <b>PO List</b> and/or <b>External ID</b> list
        </div>
        <AlloyButton type="primary">PO list</AlloyButton>
        <AlloyButton type="primary">External ID</AlloyButton>
      </div>

      <div className={s.filelist}>
        <div className={s.header}>
          <div>File name</div>
          <div>Remove</div>
        </div>

        <div className={s.file}>
          <div className={s.left}>
            <div className={s.icon}>
              <FileOutlined />
            </div>
            <div className={s.name_group}>
              <div className={s.name}>myfile.csv</div>
              <div className={s.date}>Updated: 6/12/24</div>
            </div>
          </div>
          <div className={s.right}>
            <div className={s.download_link}>Download</div>
            <div className={s.icon}>
              <DeleteOutlined />
            </div>
          </div>
        </div>
      </div>

      <div className={s.template_wrapper}>
        <div>Don't have a template?</div>
        <div className={s.links}>
          <div className={s.file}>
            <FileOutlined className={s.icon} />
            <span>PO template</span>
          </div>
          <div className={s.file}>
            <FileOutlined className={s.icon} />
            <span>External ID template</span>
          </div>
        </div>
      </div>
    </div>
  );

  const ConfirmDetails = (
    <div className={s.confirm_details}>
      <div>Found orders and external IDs to be added:</div>
      <ul>
        <li>10 purchase orders</li>
        <li>25 external IDs</li>
      </ul>
    </div>
  );

  if (!open) return null;

  return (
    <EventsTrackerProvider>
      <Form
        onSubmit={(values, form) => {
          console.log('values', values);
          console.log('form', form);
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AlloyModal
              className={s.new_event_modal}
              open={true}
              title="Create new event"
              width={600}
              onCancel={onClose}
              footer={false}
            >
              <div className={s.header_border}></div>
              <AlloySteps
                className={s.steps}
                size="small"
                current={currentStep}
                items={[
                  {
                    title: 'Add Event details'
                  },
                  {
                    title: 'Add Event watchlist'
                  },
                  {
                    title: 'Confirm Products'
                  }
                ]}
              />

              {currentStep === 0
                ? EventDetails
                : currentStep === 1
                  ? EventWatchlist
                  : ConfirmDetails}

              <div className={s.footer_border}></div>
              <div className={s.footer}>
                <AlloyButton type="link" onClick={onClose}>
                  Cancel
                </AlloyButton>
                <div className={s.right}>
                  <AlloyButton onClick={previousStep}>Back</AlloyButton>
                  {currentStep < 2 && (
                    <AlloyButton type="primary" onClick={nextStep}>
                      Next
                    </AlloyButton>
                  )}
                  {currentStep == 2 && (
                    <AlloyButton
                      type="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Add
                    </AlloyButton>
                  )}
                </div>
              </div>
            </AlloyModal>
          </form>
        )}
      ></Form>
    </EventsTrackerProvider>
  );
};
