import React, { useEffect, useState, useRef } from 'react';
import s from './CustomizationHistoryModal.module.scss';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { useMutation } from '@apollo/client';
import { GetCustomizationsHistoryDocument } from './gql/__generated__/getCustomizationsHistory.mutation';
import { SalesOrderCustomizationHistory } from 'graphql/__generated__/types';
import { SalesOrder } from '@/src/pages/OrderDetailsPage/types';
import moment from 'moment';
import { FileOutlined, LoadingOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { parseCustomizationContent } from '../ResendCustomizationModal/ResendCustomizationModal';
import { Spin } from 'ant5';
import { compareDates } from 'common/helpers/date';

interface CustomizationHistoryModalProps {
  open: boolean;
  close: () => void;
  salesOrders: SalesOrder[];
}

interface CsvRefs {
  [index: string]: HTMLAnchorElement;
}

export const CustomizationHistoryModal = ({
  open,
  close,
  salesOrders
}: CustomizationHistoryModalProps) => {
  const [customizations, setCustomizations] = useState<SalesOrderCustomizationHistory[]>([]);
  const [selectedCustomizations, setSelectedCustomizations] = useState<string[]>([]);
  const csvLinks = useRef<CsvRefs>({});

  const [getCustomizationHistory, { loading }] = useMutation(GetCustomizationsHistoryDocument, {
    variables: {
      input: {
        salesOrderIds: salesOrders.map((so) => so.id)
      }
    }
  });

  const onClose = () => {
    setSelectedCustomizations([]);
    close();
  };

  const requestCustomizationHistory = () => {
    getCustomizationHistory().then((customizations) => {
      setCustomizations(
        (customizations.data?.listSalesOrdersCustomizationsHistory
          ?.customizations as SalesOrderCustomizationHistory[]) || []
      );
    });
  };

  const toggleSelection = (key: string) => {
    const index = selectedCustomizations.findIndex((target) => target === key);
    if (index === -1) {
      setSelectedCustomizations([...selectedCustomizations, key]);
    } else {
      setSelectedCustomizations(selectedCustomizations.toSpliced(index, 1));
    }
  };

  useEffect(() => {
    if (open) requestCustomizationHistory();
  }, [open]);

  const displayCustomizations = (soId: string) => {
    const customizationsForSo = customizations.find(
      (customization) => customization.salesOrder?.id === soId
    );

    return customizationsForSo?.customizationsHistory
      .sort((a, b) => compareDates(b.generatedAt, a.generatedAt))
      .map((history) => {
        const key = `${soId}:${history.generatedAt}`;
        const filename = `${salesOrders[0].purchaseOrder.customerPo}_${
          history.distributionCenter?.name
        }_${moment(history.generatedAt).format('MM-DD-YY_h-mmA')}.csv`;

        return (
          <li key={key} onClick={() => toggleSelection(key)}>
            <div className={s.details}>
              <AlloyCheckbox checked={selectedCustomizations.includes(key)} />
              <div className={s.date}>{moment(history.generatedAt).format('MM/DD/YY')}</div>
              <div className={s.time}>{moment(history.generatedAt).format('hh:mm A')}</div>
            </div>
            <AlloyTooltip title="Download">
              <div className={s.download} onClick={(e) => e.stopPropagation()}>
                <div onClick={(e) => csvLinks.current[key].click()}>
                  <FileOutlined className={s.icon} />
                </div>

                <div
                  ref={(element) => {
                    if (element?.children[0])
                      csvLinks.current[key] = element?.children[0] as HTMLAnchorElement;
                  }}
                >
                  <CSVLink
                    data={parseCustomizationContent(history.content || '')}
                    filename={filename}
                    enclosingCharacter=""
                  />
                </div>
              </div>
            </AlloyTooltip>
          </li>
        );
      });
  };

  return (
    <AlloyModal
      open={open}
      onCancel={onClose}
      title="Download Historical Customization Files"
      footer={
        <div className={s.footer}>
          <AlloyButton onClick={onClose}>Cancel</AlloyButton>
          <AlloyButton
            type="primary"
            disabled={!selectedCustomizations.length}
            onClick={() => {
              selectedCustomizations.forEach((selection) => {
                csvLinks.current[selection].click();
              });
              onClose();
            }}
          >
            Download
          </AlloyButton>
        </div>
      }
    >
      {loading && (
        <div className={s.loading}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
        </div>
      )}
      {!loading && (
        <div className={s.customizations}>
          {salesOrders
            .filter((so) => !!so.hasCustomizations)
            .map((salesOrder, index) => (
              <div className={s.sales_order} key={salesOrder.id}>
                <div className={s.title}>
                  <div>{salesOrder.distributionCenter?.name}</div>
                  <div>Sales Order #{index + 1}</div>
                </div>
                <ul className={s.history}>{displayCustomizations(salesOrder.id)}</ul>
              </div>
            ))}
        </div>
      )}
    </AlloyModal>
  );
};
