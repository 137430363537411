import React from 'react';
import { useBuildDetails } from 'common/helpers/buildDetails';
import s from './BuildDetails.module.scss';
import { useBuildDetailsShown } from './useBuildDetailsShown';
import { createPortal } from 'react-dom';

const BuildDetails = () => {
  const build = useBuildDetails();
  const isShown = useBuildDetailsShown();

  return (
    <>
      {isShown &&
        createPortal(
          <div data-test-id="build-details" className={s.build_details}>
            <div className={s.env}>{build.beEnv}</div>
            <div className={s.sha}>fe {build.fe}</div>
            <div className={s.sha}>be {build.be}</div>
          </div>,
          document.body
        )}
      {/* This div is providing build details to f/e w/o showing them. It is appended to the body */}
      {createPortal(
        <div fe-commit={build.fe} fe-env={build.feEnv} be-commit={build.be} be-env={build.beEnv} />,
        document.body
      )}
    </>
  );
};

export default BuildDetails;
