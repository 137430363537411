import React from 'react';
import { Segmented as AntSegmented, SegmentedProps } from 'ant5';
import { SegmentedValue } from 'antd/lib/segmented';
import s from './AlloySegmented.module.scss';
import clsx from 'clsx';

export function AlloySegmented<ValueType>({
  className,
  ...otherProps
}: SegmentedProps<ValueType> & React.RefAttributes<HTMLDivElement>) {
  return <AntSegmented<ValueType> className={clsx(s.alloy_segmented, className)} {...otherProps} />;
}

// Re-exporting types
export type { SegmentedProps, SegmentedValue };
