import React from 'react';
import { Menu as AntMenu, MenuProps } from 'ant5';
import MenuItem from 'ant5/es/menu/MenuItem';
import SubMenu from 'ant5/es/menu/SubMenu';

export function AlloyMenu({ ...otherProps }: MenuProps) {
  return <AntMenu {...otherProps} />;
}

export { SubMenu as AlloySubMenu, MenuItem as AlloyMenuItem };

// Re-exporting types
export type { MenuProps };
