import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { AlloyModal } from '@/src/components/ui/AlloyModal/AlloyModal';
import s from './CoastFlipsModal.module.scss';
import { AlloyRow } from '@/src/components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from '@/src/components/ui/AlloyCol/AlloyCol';
import { AlloyTable } from '@/src/components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from '@/src/components/ui/AlloyButton/AlloyButton';
import noOptionsIcon from 'assets/icons/no_options_available_icon.svg';
import chevronRight from 'assets/icons/chevron_right.svg';
import { EMPTY } from '@/src/common/constants';
import { ColumnsType } from 'ant5/es/table';
import {
  GeographicDistributionsDocument,
  GeographicDistributionsQuery
} from './gql/__generated__/geographicDistributions.query';
import { notEmpty } from '@/src/common/helpers/notEmpty';
import { InferNodeType } from '@/src/common/helpers/mappingHelper';
import LoaderSpinner from '@/src/components/LoaderSpinner';
import {
  GeographicDistributionMetadataDocument,
  GeographicDistributionMetadataQuery
} from './gql/__generated__/geographicDistributionOptions.query';
import clsx from 'clsx';

interface CoastFlipsModalProps {
  open: boolean;
  close: () => void;
  poNumbers: (string | null | undefined)[];
}

type CoastFlipsDcTableColumns = InferNodeType<
  GeographicDistributionsQuery,
  'geographicDistributions'
>;

type CoastFlipsOrdersTableColumns = InferNodeType<
  GeographicDistributionMetadataQuery,
  'geographicDistributionMetadata'
>;

export const CoastFlipsModal = ({ open, close, poNumbers }: CoastFlipsModalProps) => {
  const [selectedDc, setSelectedDc] = useState(0);

  const { data: geographicDcList, loading: geographicDcListLoading } = useQuery(
    GeographicDistributionsDocument,
    {
      variables: {
        purchaseOrderNumbers: poNumbers.filter(notEmpty)
      },
      onCompleted: (data) => setSelectedDc(data?.geographicDistributions[0]?.distributionCenterId)
    }
  );

  const { data: geographicDcOrdersData, loading: geographicDcOrdersDataLoading } = useQuery(
    GeographicDistributionMetadataDocument,
    {
      variables: {
        distributionCenterId: selectedDc,
        purchaseOrderNumbers: poNumbers.filter(notEmpty)
      }
    }
  );

  const dcListColumns: ColumnsType<CoastFlipsDcTableColumns> = [
    {
      title: 'DC',
      key: 'dcName',
      render: (_, { distributionCenterName }) => distributionCenterName || EMPTY
    },
    {
      title: 'Actual',
      key: 'actual',
      render: (_, { actual }) => (actual ? `${actual}%` : EMPTY)
    },
    {
      title: '',
      key: 'icon',
      render: () => <img alt="chevron right icon" src={chevronRight} />
    }
  ];

  const poListColumns: ColumnsType<CoastFlipsOrdersTableColumns> = [
    {
      title: 'PO',
      width: '100px',
      render: (_, { purchaseOrderNumber }) => purchaseOrderNumber || EMPTY
    },
    {
      title: 'DC',
      width: '80px',
      render: (_, { distributionCenterName }) => distributionCenterName || EMPTY
    },
    {
      title: 'SHIP-TO',
      width: '175px',
      render: (_, { retailerDeliveryDestination }) => retailerDeliveryDestination || EMPTY
    },
    {
      title: 'MILES',
      width: '70px',
      render: (_, { miles }) => miles?.toFixed(0) || EMPTY
    },
    {
      title: '# PRODUCTS',
      width: '105px',
      render: (_, { products }) => products || EMPTY
    },
    {
      title: '# CASES',
      width: '80px',
      render: (_, { cases }) => cases || EMPTY
    },
    // {
    //   title: 'PO PRODUCT COVERAGE',
    //   width: '120px',
    //   render: (_, { poProductCoverage }) => poProductCoverage || EMPTY
    // },
    {
      title: 'COMBINED WEIGHT (LBS)',
      width: '125px',
      render: (_, { combinedWeight }) => combinedWeight?.toFixed(0) || EMPTY
    }
    // {
    //   title: 'SHIP-TO "BUSY-NESS"',
    //   width: '110px',
    //   render: (_, { shipToBusyNess }) => shipToBusyNess || EMPTY
    // },
    // {
    //   title: 'ACTION',
    //   width: '85px',
    //   render: (_, { action }) => action || EMPTY
    // }
  ];

  const dcListData = geographicDcList?.geographicDistributions;

  const poListDemoData = geographicDcOrdersData?.geographicDistributionMetadata;

  return (
    <AlloyModal
      className={s.modal_frame}
      classNames={{
        header: s.modal_header,
        body: s.modal_body,
        footer: s.modal_footer,
        content: s.modal_content
      }}
      title="Pre-Source for coast flip"
      onCancel={close}
      open={open}
      footer={<AlloyButton onClick={close}>Close</AlloyButton>}
    >
      <section className={s.modal_body_full_height}>
        <AlloyRow gutter={[24, 16]}>
          <AlloyCol span={4}>
            <AlloyTable
              columns={dcListColumns}
              dataSource={dcListData}
              rowKey={(record) => record.distributionCenterId}
              onRow={(record) => {
                return {
                  onClick: () => setSelectedDc(record.distributionCenterId)
                };
              }}
              rowClassName={(record) =>
                clsx({ [s.selected]: record.distributionCenterId === selectedDc })
              }
              pagination={false}
            />
          </AlloyCol>
          <AlloyCol span={20}>
            {geographicDcOrdersDataLoading ? (
              <LoaderSpinner />
            ) : geographicDcOrdersData ? (
              <div className={s.dc_orders_table}>
                <AlloyTable
                  columns={poListColumns}
                  dataSource={poListDemoData}
                  pagination={false}
                  rowKey={(record) => record.purchaseOrderNumber}
                  // expandable={{
                  //   showExpandColumn: false,
                  //   indentSize: 0,
                  //   defaultExpandAllRows: true
                  // }}
                />
              </div>
            ) : (
              <div className={s.empty_state_wrapper}>
                <img alt="" className={s.empty_state_icon} src={noOptionsIcon} />
                <div>No options available</div>
                <div>Refresh or select another set of orders</div>
              </div>
            )}
          </AlloyCol>
        </AlloyRow>
      </section>
    </AlloyModal>
  );
};
