import { useQuery } from '@apollo/client';
import { FillRateReportMetricFragment } from 'pages/FillRateReport/gql/__generated__/fillRateReportMetric.fragment';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import { calculateColor } from 'common/helpers/calculateColor';
import s from './DcCutTreeMap.module.scss';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { DcsFillRateReportDocument } from 'pages/FillRateReport/gql/__generated__/dcsFillRateReport.query';

interface DcCutTreeMapProps {
  metricsByDcs: {
    distributionCenterId?: string | null;
    metrics: FillRateReportMetricFragment;
  }[];
}

export const DcCutTreeMap = ({ metricsByDcs }: DcCutTreeMapProps) => {
  const { data, loading } = useQuery(DcsFillRateReportDocument, {
    variables: {
      first: 100000
    }
  });

  if (!data || loading) {
    return null;
  }

  const getNameById = (id: string) =>
    getNodesFromEdges(data.distributionCenters).find((dc) => dc.id === id)?.name;

  const getColorByValue = (value: number, minValue: number, maxValue: number) =>
    calculateColor('CF1322', 'FFEAE9', ((value - minValue) / (maxValue - minValue)) * 100);

  const mapData = metricsByDcs.sort((v1, v2) => v2.metrics.cutQuantity - v1.metrics.cutQuantity);
  const labels = mapData.map(
    (metricsByDc) => getNameById(metricsByDc?.distributionCenterId || '') || 'Unknown'
  );

  return (
    <div className={s.chart_container}>
      {data && !loading && (
        <Chart
          type="treemap"
          data={{
            datasets: [
              {
                label: 'Cut quantity',
                data: mapData.map((metricsByDc) => metricsByDc.metrics.cutQuantity),
                borderColor: '#FFFFFF',
                borderWidth: 1,
                spacing: 0,
                backgroundColor: (ctx) =>
                  getColorByValue(
                    ctx.raw?.v,
                    Math.min(...((ctx.dataset.tree || []) as number[])),
                    Math.max(...((ctx.dataset.tree || []) as number[]))
                  ),
                labels: {
                  display: true,
                  align: 'left',
                  position: 'top',
                  color: '#FFFFFF',
                  font: {
                    size: 14,
                    lineHeight: 1.4
                  },
                  padding: 8,
                  formatter: (ctx) => [labels[ctx.dataIndex], ctx.raw?.v?.toLocaleString()]
                }
              }
            ]
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  label: (tootipItem) => [
                    labels[tootipItem.dataIndex],
                    `${tootipItem.dataset.label}: ${(tootipItem.raw as any)?.v?.toLocaleString()}`
                  ]
                }
              }
            }
          }}
        />
      )}
    </div>
  );
};
