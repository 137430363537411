import React, { Dispatch, SetStateAction } from 'react';
import s from './ReattemptReleaseOrders.module.scss';
import LoaderSpinner from 'components/LoaderSpinner';
import { AvailableActionSort } from '../../BulkProcessSelect';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface ReattemptReleaseOrdersModalProps {
  loading: boolean;
  onConfirm: () => void;
  reattemptPOsList: AvailableActionSort;
  setShowReattemptModal: Dispatch<SetStateAction<boolean>>;
}

const ReattemptReleaseOrdersModal = ({
  loading,
  onConfirm,
  reattemptPOsList,
  setShowReattemptModal
}: ReattemptReleaseOrdersModalProps) => {
  const hasAllowedPOs = reattemptPOsList.allowed.length > 0;
  const DisplayPOs = () => {
    return (
      <>
        {hasAllowedPOs ? (
          <>
            <div data-testid="accepted-reattempt-pos-title" className={s.title}>
              {`The following (${reattemptPOsList.allowed.length}) purchase orders will be reattempted:`}
            </div>
            <div data-testid="accepted-reattempt-pos-list" className={s.table}>
              {reattemptPOsList?.allowed.map((po) => {
                return (
                  <AlloyRow key={po.id} data-testid={`${po.id}-row`} className={s.row}>
                    <AlloyCol span={9} data-testid={`${po.id}-col`} className={s.col_po}>
                      {po.customerPo}
                    </AlloyCol>
                    <AlloyCol
                      span={15}
                      data-testid={`${po.retailerDeliveryDestination?.id}-col`} // added conditional to accommodate for no RDD in testing
                      className={s.col_rdd}
                    >
                      {po.retailerDeliveryDestination
                        ? `${po.retailerDeliveryDestination.name} - ${po.retailerDeliveryDestination.externalId}`
                        : po.retailerDeliveryDestinationExternalId}
                    </AlloyCol>
                  </AlloyRow>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}

        {reattemptPOsList.disallowed.length ? (
          <>
            <div data-testid="rejected-reattempt-pos-title" className={s.title_rejected_pos}>
              {`The following (${reattemptPOsList.disallowed.length}) purchase orders will NOT be reattempted:`}
            </div>
            <div data-testid="rejected-reattempt-pos-list" className={s.table}>
              {reattemptPOsList.disallowed?.map((po) => {
                return (
                  <AlloyRow key={po.id} data-testid={`${po.id}-row`} className={s.row}>
                    <AlloyCol span={9} data-testid={`${po.id}-col`} className={s.col_po}>
                      {po.customerPo}
                    </AlloyCol>
                    <AlloyCol
                      span={15}
                      data-testid={`${po.retailerDeliveryDestination?.id}-col`} // added conditional to accommodate for no RDD in testing
                      className={s.col_rdd}
                    >
                      {po.retailerDeliveryDestination
                        ? `${po.retailerDeliveryDestination.name} - ${po.retailerDeliveryDestination.externalId}`
                        : po.retailerDeliveryDestinationExternalId}
                    </AlloyCol>
                  </AlloyRow>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <AlloyModal
      open={true}
      centered
      title={
        <span data-testid="bulk-process-reattempt-pos-modal-title">
          Bulk Reattempt Release Orders
        </span>
      }
      okText={<span data-testid="confirm-button">Yes, Continue</span>}
      okButtonProps={{ disabled: !hasAllowedPOs }}
      onOk={() => {
        onConfirm();
        setShowReattemptModal(false);
      }}
      onCancel={() => setShowReattemptModal(false)}
      destroyOnClose={true}
    >
      {loading ? <LoaderSpinner /> : <DisplayPOs />}
    </AlloyModal>
  );
};

export default ReattemptReleaseOrdersModal;
