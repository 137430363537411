import { ParsedQuery } from 'query-string';
import { SortingType } from '../types';

export const getSortOrder = (location: ParsedQuery<string>, columnKey: string) =>
  location.column === columnKey
    ? ((location.order === 'ASC' ? 'ascend' : 'descend') as SortingType)
    : undefined;

export const getColumnSortOrder = (
  sortColumn: string | null | undefined,
  sortOrder: string | null | undefined,
  columnKey: string
) =>
  sortColumn === columnKey
    ? ((sortOrder === 'ASC' ? 'ascend' : 'descend') as SortingType)
    : undefined;
