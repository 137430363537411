import { get } from 'lodash-es';
import { useQueryParam, withDefault, JsonParam, StringParam } from 'use-query-params';
import { FilterField, MetricItem, MetricItemWithArrayIndex } from './types';
import { useMemo } from 'react';
import { Filter, FilterIncomplete } from '../../components/TableFilters/TableFilters';
import { notEmpty } from 'common/helpers/notEmpty';

const presetFiltersRaw: FilterIncomplete<FilterField>[] = [
  {
    name: 'On Time Status',
    short: 'OTS',
    type: 'single',
    field: 'onTimeShipmentStatus',
    options: [
      {
        name: 'At Risk',
        value: 'At Risk'
      },
      {
        name: 'Due Today',
        value: 'Due Today'
      },
      {
        name: 'On Time',
        value: 'On Time'
      }
    ]
  },
  {
    name: 'Pick Status',
    type: 'single',
    field: 'pickStatus',
    options: [
      {
        name: 'Open',
        value: 'Open'
      },
      {
        name: 'Completed',
        value: 'Completed'
      }
    ]
  },
  {
    name: 'Ship Status',
    type: 'single',
    field: 'shipStatus',
    options: [
      {
        name: 'Open',
        value: 'Open'
      },
      {
        name: 'Completed',
        value: 'Completed'
      }
    ]
  },
  {
    name: 'DC',
    type: 'multiple',
    field: 'distributionCenterName'
  },
  {
    name: 'Ship-To',
    type: 'multiple',
    field: 'retailerDeliveryDestination'
  },
  {
    name: 'Vendor Code',
    type: 'multiple',
    field: 'tpExternalId'
  }
];

const presetFilters = presetFiltersRaw.sort((a, b) => a.name.localeCompare(b.name));

export const getTableFilters = (metrics: MetricItem[]) => {
  return presetFilters.map((x) => {
    // TODO: put type guards?
    if (x.options) return x as Filter<FilterField>;
    const options = (metrics || [])
      .map((metric) => getValue(metric, x.field))
      .filter(notEmpty)
      .map((x) => ({ name: x.toString(), value: x.toString() }));
    return { ...x, options } as Filter<FilterField>;
  });
};

// Need this since we get deeper value for retailerDeliveryDestination
const getValue = (item: MetricItem, field: string) => {
  return (
    get(
      item,
      field === 'retailerDeliveryDestination' ? 'retailerDeliveryDestination.name' : field
    ) || ''
  ).toString();
};

// TODO: we REALLY want to remove this later and to it at backend.
export const useFiltersOnFrontend = (data: MetricItem[]): MetricItemWithArrayIndex[] => {
  // TODO: move to separate hooks?
  const [filters] = useQueryParam('filters', withDefault(JsonParam, {}));
  const [mode] = useQueryParam('mode', withDefault(StringParam, 'upcoming'));

  const filteredData = useMemo(
    () =>
      data
        .map((x, indexInResultArray) => ({ ...x, indexInResultArray }))
        .filter((x) => {
          // Filter-out "open" shipments from past-due
          if (mode !== 'upcoming' && x.shipStatus?.toLowerCase() !== 'open') {
            return false;
          }

          for (const filter of presetFilters) {
            const { type, field } = filter;
            const value = getValue(x, field).toLowerCase();
            const currentFilterValue = filters?.[field];

            if (currentFilterValue) {
              if (type === 'single' && !Array.isArray(currentFilterValue)) {
                if (currentFilterValue.toString().toLowerCase() !== value.toLowerCase())
                  return false;
              } else if (type === 'multiple' && Array.isArray(currentFilterValue)) {
                if (!currentFilterValue.map((x) => x.toString().toLowerCase()).includes(value))
                  return false;
              }
            }
          }
          return true;
        }),
    [data, filters, mode]
  );
  return filteredData;
};
