import { Button } from 'components/alloy/Button';
import React from 'react';
import { utils as XLSXutils, writeFileXLSX } from 'non_npm_dependencies/xlsx';
import moment from 'moment';
import { PoDetailsCutSummaryOrderDetailsPage, PurchaseOrderWithOverdue } from '../types';
import { message } from 'antd';
import { get } from 'lodash-es';
import { getOrderSource } from './CollapsibleCutSummariesTable/CollapsibleCutSummariesTable';

const CUTS_HEADERS = [
  {
    title: 'PO',
    width: 76,
    name: 'po.customerPo'
  },
  {
    title: 'Trading Partner',
    width: 190,
    name: 'po.tradingPartner.name'
  },
  {
    title: 'ShipTo',
    width: 66,
    name: 'po.retailerDeliveryDestination.sapCustomerId'
  },
  {
    title: 'UPC',
    width: 90,
    name: 'cut.product.upc'
  },
  {
    title: 'External ID',
    width: 100,
    name: 'cut.product.externalId'
  },
  {
    title: 'Description',
    width: 270,
    name: 'cut.product.name'
  },
  {
    title: 'Order QTY',
    width: 64,
    name: 'cutItem.orderQuantity'
  },
  {
    title: 'Total Order QTY',
    width: 88,
    name: 'cutItem.totalOrderQuantity'
  },
  {
    title: 'Allocated QTY',
    width: 76,
    name: 'cutItem.allocatedQuantity'
  },
  {
    title: 'Cut QTY',
    width: 56,
    name: 'cutItem.cutQuantity'
  },
  {
    title: 'Cut Reasons',
    width: 104,
    name: 'cutItem.reason'
  },
  {
    title: 'Order Number',
    width: 90,
    name: 'cutItem.soAlternateIds'
  },
  {
    title: 'Order Source',
    width: 70,
    name: 'cutItem.orderSource'
  },
  {
    title: 'Cut Source',
    width: 60,
    name: 'cutItem.cutSource'
  },
  {
    title: 'Date',
    width: 136,
    name: 'cutItem.datetime'
  }
];

const prepareItemsForExport = async (
  po: PurchaseOrderWithOverdue,
  cuts: PoDetailsCutSummaryOrderDetailsPage[]
) => {
  return cuts.flatMap((cut) =>
    cut.items.flatMap((cutItem) => {
      const base = { po, cut, cutItem };
      return Object.fromEntries(
        CUTS_HEADERS.map((header) => {
          const value = get(base, header.name);
          let formattedValue;
          if (Array.isArray(value)) {
            formattedValue = value.join(', ');
          } else if (header.name === 'cutItem.datetime') {
            formattedValue = moment(value).toDate();
          } else if (header.name === 'cutItem.orderSource') {
            formattedValue = getOrderSource(value);
          } else if (typeof value === 'string') {
            formattedValue = value.replace(/(\r\n|\n|\r)/gm, ' ');
          } else {
            formattedValue = value;
          }
          return [header.title, formattedValue];
        })
      );
    })
  );
};

export const ExportCuts = ({
  purchaseOrder,
  cuts
}: {
  purchaseOrder: PurchaseOrderWithOverdue;
  cuts: PoDetailsCutSummaryOrderDetailsPage[] | null | undefined;
}) => {
  const onExportClick = async (cuts: PoDetailsCutSummaryOrderDetailsPage[]) => {
    try {
      const itemsWithDetails = await prepareItemsForExport(purchaseOrder, cuts);

      const filename = `Cuts_${purchaseOrder.customerPo ?? ''}_${
        purchaseOrder.tradingPartner?.externalId
      }_${moment().format('MM-DD-YYYY_hh-mm-ss_A')}.xlsx`;

      const worksheet = XLSXutils.json_to_sheet(itemsWithDetails, {
        header: CUTS_HEADERS.map((cutHeader) => cutHeader.title),
        dateNF: 'MMM DD, YYYY, h:mm AM/PM'
      });
      worksheet['!cols'] = CUTS_HEADERS.map((cutHeader) => ({ wpx: cutHeader.width }));
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, 'Cuts');
      writeFileXLSX(workbook, filename, { compression: true });
    } catch {
      message.error(`An error occurred while exporting data`);
    }
  };

  return (
    <Button
      type="secondary"
      onClick={() => {
        if (cuts) onExportClick(cuts);
      }}
    >
      Export
    </Button>
  );
};
