import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import s from './Stats.module.scss';
import { StatsTile } from 'components/StatsTile/StatsTile';
import { PurchaseOrdersAtRiskReportDocument } from 'pages/Visibility/gql/__generated__/atRisk.query';
import { AppliedFillRateReportFilters } from 'graphql/__generated__/types';
import { DisplayMode } from 'pages/Visibility/types';
import { useFiltersOnFrontend } from '../../frontendFilter';

const UpcomingStats = ({ filters }: { filters: AppliedFillRateReportFilters }) => {
  const { data, loading, error, called } = useQuery(PurchaseOrdersAtRiskReportDocument, {
    variables: {
      appliedFilters: filters
    },
    skip: filters.subBusinessUnitIds?.length === 0 || filters.vendorMarketIds.length === 0
  });

  const metrics = useFiltersOnFrontend(data?.purchaseOrdersAtRiskReport?.metrics || []);

  const totalOrders = (metrics || []).length;
  const atRiskOrders = useMemo(
    () => (metrics || []).filter((x) => x.onTimeShipmentStatus?.toLowerCase() === 'at risk').length,
    [metrics]
  );
  const dueTodayOrders = useMemo(
    () =>
      (metrics || []).filter((x) => x.onTimeShipmentStatus?.toLowerCase() === 'due today').length,
    [metrics]
  );
  const onTimeOrders = useMemo(
    () => (metrics || []).filter((x) => x.onTimeShipmentStatus?.toLowerCase() === 'on time').length,
    [metrics]
  );
  const pickedOrders = useMemo(
    () => (metrics || []).filter((x) => x.pickStatus?.toLowerCase() === 'completed').length,
    [metrics]
  );
  const shippedOrders = useMemo(
    () => (metrics || []).filter((x) => x.shipStatus?.toLowerCase() === 'completed').length,
    [metrics]
  );

  const shouldShowLoading = loading || !called;
  return (
    <div className={s.stats}>
      <StatsTile
        title="Total Orders"
        count={totalOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
      <StatsTile
        title="Orders At Risk"
        count={atRiskOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
      <StatsTile
        title="Orders Due Today"
        count={dueTodayOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
      <StatsTile
        title="On-Time Shipment"
        count={onTimeOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
      <StatsTile
        title="Orders Picked Status"
        count={pickedOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
      <StatsTile
        title="Orders Shipped Status"
        count={shippedOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
    </div>
  );
};

const PastDueStats = ({ filters }: { filters: AppliedFillRateReportFilters }) => {
  const { data, loading, error, called } = useQuery(PurchaseOrdersAtRiskReportDocument, {
    variables: {
      appliedFilters: filters
    },
    skip: filters.subBusinessUnitIds?.length === 0 || filters.vendorMarketIds.length === 0
  });

  const metrics = useFiltersOnFrontend(data?.purchaseOrdersAtRiskReport?.metrics || []);
  const totalOrders = (metrics || []).length;

  const shouldShowLoading = loading || !called;
  return (
    <div className={s.stats}>
      <StatsTile
        title="Past Due Shipment"
        count={totalOrders}
        loading={shouldShowLoading}
        error={!!error}
      />
    </div>
  );
};

export const Stats = ({
  filters,
  mode
}: {
  filters: AppliedFillRateReportFilters;
  mode: DisplayMode;
}) => {
  return mode === 'upcoming' ? (
    <UpcomingStats filters={filters} />
  ) : (
    <PastDueStats filters={filters} />
  );
};
