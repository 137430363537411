import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetCustomizationsHistoryMutationVariables = Types.Exact<{
  input: Types.ListSalesOrdersCustomizationsHistoryInput;
}>;


export type GetCustomizationsHistoryMutation = { __typename?: 'RootMutationType', listSalesOrdersCustomizationsHistory?: { __typename?: 'ListSalesOrdersCustomizationsHistoryPayload', customizations: Array<{ __typename?: 'SalesOrderCustomizationHistory', salesOrder?: { __typename?: 'SalesOrder', id: string } | null, customizationsHistory: Array<{ __typename?: 'CustomizationHistory', content?: string | null, generatedAt?: any | null, distributionCenter?: { __typename?: 'DistributionCenter', id: string, name: string } | null }> }> } | null };


export const GetCustomizationsHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetCustomizationsHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ListSalesOrdersCustomizationsHistoryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listSalesOrdersCustomizationsHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customizationsHistory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"generatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCustomizationsHistoryMutation, GetCustomizationsHistoryMutationVariables>;