import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryRepackCutsComponentsReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryRepackFilters>;
}>;


export type InventoryRepackCutsComponentsReportQuery = { __typename?: 'RootQueryType', inventoryRepackCutsComponentsReport?: { __typename?: 'InventoryRepackCutsComponentsReport', metrics?: Array<{ __typename?: 'InventoryRepackCutsComponentsReportMetrics', itemId: string, components?: Array<{ __typename?: 'InventoryRepackCutsComponentsMetrics', componentId: string, componentName?: string | null, day1?: any | null, day2?: any | null, day3?: any | null, day4?: any | null, day5?: any | null, recoveryPeriod?: string | null, shortfallQty?: any | null, week1?: any | null, week2?: any | null, plant: string } | null> | null }> | null } | null };


export const InventoryRepackCutsComponentsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryRepackCutsComponentsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryRepackFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryRepackCutsComponentsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"components"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"componentId"}},{"kind":"Field","name":{"kind":"Name","value":"componentName"}},{"kind":"Field","name":{"kind":"Name","value":"day1"}},{"kind":"Field","name":{"kind":"Name","value":"day2"}},{"kind":"Field","name":{"kind":"Name","value":"day3"}},{"kind":"Field","name":{"kind":"Name","value":"day4"}},{"kind":"Field","name":{"kind":"Name","value":"day5"}},{"kind":"Field","name":{"kind":"Name","value":"recoveryPeriod"}},{"kind":"Field","name":{"kind":"Name","value":"shortfallQty"}},{"kind":"Field","name":{"kind":"Name","value":"week1"}},{"kind":"Field","name":{"kind":"Name","value":"week2"}},{"kind":"Field","name":{"kind":"Name","value":"plant"}}]}},{"kind":"Field","name":{"kind":"Name","value":"itemId"}}]}}]}}]}}]} as unknown as DocumentNode<InventoryRepackCutsComponentsReportQuery, InventoryRepackCutsComponentsReportQueryVariables>;