import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import React from 'react';
import {
  DistributionCenterDocument,
  DistributionCenterQuery
} from 'pages/DistributionCentersPage/gql/__generated__/distributionCenter.query';
import { useQuery } from '@apollo/client';
import { GeneralSettingsViewTab } from './components/GeneralSettingsViewTab/GeneralSettingsViewTab';
import { IntegrationsViewTab } from './components/IntegrationViewTab/IntegrationsViewTab';
import { CustomizationViewTab } from './components/CustomizationViewTab/CustomizationViewTab';
import { ViewTabs } from 'components/Common/ViewMode/ViewTabs/ViewTabs';

interface DistributionCenterDrawerProps {
  distributionCenterId?: string;
  onClose: (id?: string) => void;
  startEditing: (distributionCenter: DistributionCenterFullData) => void;
  isDCFacilityManagementEnabled: boolean | undefined;
}

export type DistributionCenterFullData = NonNullable<DistributionCenterQuery['distributionCenter']>;
export type DistributionCenterCode = DistributionCenterFullData['codes'][number]; //TODO: delete this after schip_dc_facility_management FF removed
export type DistributionCenterSiblings = DistributionCenterFullData['siblings'][number];

export type CustomizationHeader = NonNullable<
  NonNullable<DistributionCenterFullData['customizationHeaders']>[number]
>;

export const DistributionCenterViewDrawer = ({
  onClose,
  distributionCenterId,
  startEditing,
  isDCFacilityManagementEnabled
}: DistributionCenterDrawerProps) => {
  const { data, loading } = useQuery(DistributionCenterDocument, {
    variables: {
      id: distributionCenterId || ''
    },
    skip: !distributionCenterId
  });

  const tabs = data?.distributionCenter
    ? [
        {
          label: 'General Settings',
          key: 'general',
          children: (
            <GeneralSettingsViewTab
              distributionCenter={data?.distributionCenter}
              isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
            />
          )
        },
        {
          label: 'Integrations',
          key: 'integrations',
          children: <IntegrationsViewTab distributionCenter={data?.distributionCenter} />
        },
        {
          label: 'Customization',
          key: 'customization',
          children: <CustomizationViewTab distributionCenter={data?.distributionCenter} />
        }
      ]
    : [];

  return (
    <EntityDrawer
      title={data?.distributionCenter?.name || data?.distributionCenter?.code || ''}
      open={!!distributionCenterId}
      onClose={onClose}
      viewMode={<ViewTabs tabs={tabs} />}
      loading={!data?.distributionCenter || loading}
      startEditing={() => data?.distributionCenter && startEditing(data?.distributionCenter)}
      withTabsContent
    />
  );
};
