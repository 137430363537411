import React from 'react';
import s from './FormLabel.module.scss';

interface LabelProps {
  disabled?: boolean;
  error?: string;
  filled?: boolean;
  htmlFor?: string;
  title: string;
  required?: boolean;
  style?: React.CSSProperties;
}

export const FormLabel = ({
  disabled,
  required,
  error,
  filled,
  title,
  htmlFor,
  style
}: LabelProps) => {
  return (
    <label
      className={s.label}
      aria-disabled={disabled}
      aria-required={required}
      data-haserror={!!error}
      data-filled={filled}
      htmlFor={htmlFor}
      style={style}
    >
      {title}
    </label>
  );
};
