/*
This component contains source code that will display the details of the various metrics of the Optimizer result for a seletec sceanario.
User can view optimizer results like Transportation Cost, Stressed Inventory Value, Holding Cost against the baseLine and optimal value.
User can view Constraints applied while creating the scenario.
User can download the constraints and results of the scenario.
*/
import React, { useState, useMemo } from 'react';
import { Spin, Button } from 'antd';
import s from './DCAnalysisHeader.module.scss';
import DcAnalysisTable from '../DCAnalysisTable/DCAnalysisTable';
import PositioningConstraintsTable from '../PositioningConstraintsTable/PositioningContraintsTable';
import { LeftOutlined } from '@ant-design/icons';
import dcAnalysisImage from '../../../../assets/icons/inventoryOptimization/dc_analysis.svg';
import dcAnalysisDownloadImage from '../../../../assets/icons/inventoryOptimization/dc_download.svg';
import scenarioConstraintsIcon from '../../../../assets/icons/inventoryOptimization/scenario_constraints_icon.svg';
import { Link, useParams } from 'react-router-dom';
import {
  GetScenarioDcListDocument,
  GetCostInfoDataDocument
} from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioDetails.query';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GetScenarioConstraintsDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioDetails.query';
import { GetInventoryOptimizerExportDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/exportInventoryOptimizer.query';

const DCAnalysisHeader: React.FC = () => {
  const [isdDcAnalysisTableShow, setIsDcAnalysisTableShow] = useState(false);
  const [showConstraints, setShowConstraints] = useState(false);
  const { key } = useParams<{ key: string }>();

  /* 
  GraphQL query to get the details like Transportation Cost, Stressed Inventory Value, Holding Cost
  */
  const { data: costInfoData } = useQuery(GetCostInfoDataDocument, {
    variables: { scenarioId: key }
  });

  /* 
  GraphQL query to get the DC level details like Transportation Cost, Stressed Inventory Value, Holding Cost
  */
  const [scenarioDCList, { data: scenarioDC, loading: dcLoading, error: dcError }] =
    useLazyQuery(GetScenarioDcListDocument);

  /* 
  Function to call an API that takes scenario ID and triggers download of the constraints and results of the scenario.
  */
  const [exportInventoryOptimizer, { loading }] = useLazyQuery(
    GetInventoryOptimizerExportDocument,
    {
      variables: { scId: key }
    }
  );
  /* 
  Function to get the scenario Name to display in the page.
  */
  const scenarioName = useMemo(
    () => costInfoData?.getScenariosList && costInfoData.getScenariosList[0]?.scenarioName,
    [costInfoData]
  );
  /* 
  Function to check if the selected scenario is a Base Network or Custom one.
  If Custom, use this flag to show the Scenario Constraints, else hide the Postioning Constraints button on the screen.
  */
  const isBase = useMemo(
    () => (costInfoData?.getScenariosList ? costInfoData?.getScenariosList[0]?.base : true),
    [costInfoData]
  );

  const dcTableData = useMemo(
    () =>
      scenarioDC?.getScenarioDcList?.map((dcData) => {
        return {
          key: dcData?.id,
          dc: dcData?.dcDetails?.name,
          operatingCost: dcData?.operatingCost,
          wastageCost: dcData?.wastageCost,
          holdingCost: dcData?.holdingCost
        };
      }),
    [scenarioDC]
  );
  /* 
  GraphQL query to get the scenario Constraints applied by user during scenario creation
  */
  const [
    scenarioConstraints,
    {
      data: scenarioConstraintsData,
      loading: scenarioConstraintsLoading,
      error: scenarioConstraintsError
    }
  ] = useLazyQuery(GetScenarioConstraintsDocument);

  const scenarioConstraintsTableData = useMemo(
    () =>
      scenarioConstraintsData?.getScenarioConstraints?.map((constraintsData) => {
        return {
          key: constraintsData?.id,
          website: constraintsData?.tradingPartner?.name,
          gtin: constraintsData?.gtin14Product?.gtin14,
          description: constraintsData?.gtin14Product?.description,
          currentDC: constraintsData?.existingScenarioConstraintDcDetails?.map((dc) => dc?.name),
          scenarioDC: constraintsData?.scenarioConstraintDcDetails?.map((dc) => dc?.name)
        };
      }),
    [scenarioConstraintsData]
  );
  /* 
  Function to hide/show DC Analyser table in a pop up modal.
  */
  const handleShowTable = () => {
    setIsDcAnalysisTableShow(true);
    scenarioDCList({ variables: { scenarioId: key } });
  };

  /* 
  Function to show Positioning Constraints table in a pop up modal.
  */
  const handleShowConstraintsTable = () => {
    setShowConstraints(true);
    scenarioConstraints({ variables: { scenarioId: key } });
  };

  /* 
  Function to hide DC Analyser table in a pop up modal.
  */
  const handleHideTable = () => {
    setIsDcAnalysisTableShow(false);
  };

  /* 
  Function to hide Positioning Constraints table in a pop up modal.
  */
  const handleHideConstraintsTable = () => {
    setShowConstraints(false);
  };

  /* 
  Function to handle download of the Positioning Constraints and results of a scenario.
  */
  const handleDownload = async () => {
    const { data } = await exportInventoryOptimizer();
    try {
      if (data && data.getInventoryOptimizerExport) {
        const presignedUrl = data.getInventoryOptimizerExport;
        window.open(presignedUrl, '_blank');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className={s.mainContainer}>
      <div className={s.subMainContainerFirst}>
        <Link
          to={'/inventory-optimization/'}
          data-testid="inv-allInfoPage-currentNetworkBackIcon"
          className={s.subMainContainerFirst}
        >
          <LeftOutlined style={{ color: 'black', width: '16px', height: '16px' }} />
          <span data-testid="inv-allInfoPage-currentNetwork">{scenarioName}</span>
        </Link>
      </div>
      <div className={s.subMainContainerSecond}>
        {!isBase && (
          <>
            <div className={s.subMainContainerSecondChild} onClick={handleShowConstraintsTable}>
              <img
                data-testid="inv-allInfoPage-headerScenarioConstraintsImg"
                src={scenarioConstraintsIcon}
                alt="scenario_Constraints-overview-img"
              />
              <span data-testid="inv-allInfoPage-scenarioConstraintsTitle">
                Positioning Constraints
              </span>
              <div className={s.verticalLine}></div>
            </div>
          </>
        )}
        <div className={s.subMainContainerSecondChild}>
          <span>
            <Button
              type="primary"
              onClick={handleShowTable}
              icon={
                <img
                  data-testid="inv-allInfoPage-headerImage"
                  src={dcAnalysisImage}
                  alt="dcAnalysis-overview-img"
                  style={{ marginRight: '4px', paddingBottom: '2px' }}
                />
              }
            >
              <span data-testid="inv-allInfoPage-dcOverviewHeading">DC Overview</span>
            </Button>
          </span>
        </div>
        <div className={s.subMainContainerSecondChild}>
          <span>
            <Button
              type="primary"
              onClick={handleDownload}
              icon={
                <img
                  data-testid="inv-allInfoPage-dcDownloadImage"
                  src={dcAnalysisDownloadImage}
                  alt="dcAnalysis-download-img"
                  style={{ marginRight: '4px', paddingBottom: '2px' }}
                />
              }
            >
              <span>Export</span>
            </Button>
          </span>
        </div>
        {loading && <Spin />}
        {isdDcAnalysisTableShow && (
          <DcAnalysisTable
            handleHideTable={handleHideTable}
            data={dcTableData}
            error={dcError}
            loading={dcLoading}
          />
        )}
        {showConstraints && (
          <PositioningConstraintsTable
            sharedGroupSkusList={scenarioConstraintsTableData}
            handleHideTable={handleHideConstraintsTable}
            loading={scenarioConstraintsLoading}
            error={scenarioConstraintsError}
          />
        )}
      </div>
    </div>
  );
};

export default DCAnalysisHeader;
