import React from 'react';
import s from './QuantityDisplay.module.scss';
import clsx from 'clsx';

interface QuantityDisplayProps {
  count?: number;
  titleSingular: string;
  titleMultiple: string;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const QuantityDisplay: React.FC<QuantityDisplayProps> = ({
  count,
  titleSingular,
  titleMultiple,
  className = ''
}) => {
  const isPresent = typeof count !== 'undefined';
  return (
    <div className={clsx(s.quantity_display, className)}>
      {isPresent && `${count.toLocaleString()} ${count === 1 ? titleSingular : titleMultiple}`}
    </div>
  );
};
