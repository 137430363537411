import { Input, Select } from 'antd';
import React from 'react';
import s from './AllowedDistributionCentersList.module.scss';
import { PrioritizedList } from '../../../../components/PrioritizedList/PrioritizedList';
import update from 'immutability-helper';

const { Option } = Select;

export interface DistributionCenterItem {
  id: string;
  text: string;
  externalId?: string;
}

export const AllowedDistributionCentersList = ({
  selected,
  allDistributionCenters,
  onChange,
  'data-testid': dataTestId
}: {
  selected: DistributionCenterItem[];
  allDistributionCenters: DistributionCenterItem[];
  onChange: (items: DistributionCenterItem[]) => void;
  'data-testid': string;
}) => {
  const onChangeFieldValue = (
    index: number,
    fieldName: string,
    value: string | number | undefined
  ) => {
    const previous = [...selected];
    const newVal = update(previous, { [index]: { [fieldName]: { $set: value } } });
    onChange(newVal);
  };

  return (
    <>
      <div className={s.select_dc_container} data-testid={dataTestId}>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (allDistributionCenters.find((dc) => dc.id === option?.value)?.text || '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          placeholder="Choose a DC to add"
          className={s.select_dc}
          value={null}
          onChange={(value) => {
            if (value) {
              const foundDc = allDistributionCenters.find(
                (distributionCenter) => distributionCenter.id === value
              );
              if (foundDc) {
                onChange([...selected, foundDc]);
              }
            }
          }}
        >
          {allDistributionCenters
            .filter(
              (distributionCenter) =>
                !selected.find((currentSelected) => currentSelected.id === distributionCenter.id)
            )
            .sort((dc1, dc2) => dc1.text.localeCompare(dc2.text))
            .map((card) => (
              <Option key={card.id}>
                <div className={s.select_dc_item}>
                  <p>{card.text}</p>
                  <p>Click to add</p>
                </div>
              </Option>
            ))}
        </Select>
      </div>
      <PrioritizedList<DistributionCenterItem>
        selected={selected}
        onChange={onChange}
        data-testid={dataTestId}
        itemBodyRender={(item, index) => (
          <>
            <span className={s.name}>{item.text}</span>
            <Input
              className={s.external_field}
              placeholder="External ID (optional)"
              value={item.externalId}
              onChange={(event) =>
                onChangeFieldValue(index, 'externalId', event.currentTarget.value)
              }
            />
          </>
        )}
        itemKey={(item) => item.id}
      />
    </>
  );
};
