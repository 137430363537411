import React from 'react';
import { Table } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { calculateColor } from 'common/helpers/calculateColor';
import { RateCellWithTarget } from 'components/RateCellWithTarget/RateCellWithTarget';

interface StyledReportTableItem {
  [key: string]: any;
  submittedQuantity: number;
  acceptedQuantity: number;
  deliveredQuantity: number;
  cutQuantity: number;
  submittedFillRate: number;
  acceptedFillRate: number;
}

interface StyledReportTableProps {
  data: StyledReportTableItem[];
  firstColumns: (ColumnGroupType<StyledReportTableItem> | ColumnType<StyledReportTableItem>)[];
}

export const StyledReportTable = ({ data, firstColumns }: StyledReportTableProps) => {
  const columns = [
    ...firstColumns,
    {
      title: 'PO Orig. QTY',
      key: 'poOriginQty',
      render: (item: StyledReportTableItem) => item.submittedQuantity.toLocaleString(),
      sorter: (i1: StyledReportTableItem, i2: StyledReportTableItem) =>
        i1.submittedQuantity - i2.submittedQuantity
    },
    {
      title: 'Accepted QTY',
      key: 'acceptedQty',
      render: (item: StyledReportTableItem) => item.acceptedQuantity.toLocaleString(),
      sorter: (i1: StyledReportTableItem, i2: StyledReportTableItem) =>
        i1.acceptedQuantity - i2.acceptedQuantity
    },
    {
      title: 'Delivered QTY',
      key: 'deliveredQty',
      render: (item: StyledReportTableItem) => item.deliveredQuantity.toLocaleString(),
      sorter: (i1: StyledReportTableItem, i2: StyledReportTableItem) =>
        i1.deliveredQuantity - i2.deliveredQuantity
    },
    {
      title: <span className="cut_cell_content">Cuts</span>,
      key: 'cuts',
      className: 'cut_cell',
      render: (item: StyledReportTableItem) => (
        <span
          className="cut_cell_content"
          style={{ background: calculateColor('FFEAE9', 'FA3545', item.submittedFillRate) }}
        >
          {item.cutQuantity.toLocaleString()}
        </span>
      ),
      sorter: (i1: StyledReportTableItem, i2: StyledReportTableItem) =>
        i1.cutQuantity - i2.cutQuantity
    },
    {
      title: 'Fill Rate',
      key: 'fillRate',
      width: 220,
      render: (item: StyledReportTableItem) => (
        <RateCellWithTarget value={item.submittedFillRate} />
      ),
      sorter: (i1: StyledReportTableItem, i2: StyledReportTableItem) =>
        i1.submittedFillRate - i2.submittedFillRate
    }
  ];

  return (
    <Table
      rowKey={(item) => `${item.id}`}
      className="styled_report_table legacy_borderless_bordered"
      size="small"
      dataSource={data}
      columns={columns}
      pagination={false}
      bordered
    />
  );
};
