import React, { useMemo } from 'react';
import s from './OTSGraph.module.scss';
import { Line } from 'react-chartjs-2';
import truckTickIcon from 'assets/icons/stats/truck-tick.svg';
import { ChartData } from 'chart.js';
import { getLineChartLabels, isCalendarValueContinuous } from 'common/helpers/graph';
import { Metrics } from '../types';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { groupBy } from 'lodash-es';
import { safeNumberComparator } from 'common/helpers/comparators';
import { getDataSetColor } from 'common/helpers/graph';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { notEmpty } from 'common/helpers/notEmpty';
import { transformCalendarValueToFiscalCalendarWeekInput } from 'components/PeriodCalendar/helpers';
import { ExecutiveSummaryShipmentOnTimeRateTrendDocument } from '../gql/__generated__/executiveSummaryShipmentOnTimeRateTrend.query';

const getDataSet = (metrics: Metrics, period: CalendarValue) => {
  const grouped = groupBy(metrics, 'year');
  const labels = getLineChartLabels(period);

  return period.years.map((year) => {
    const data = (grouped[year] || [])
      .sort((a, b) =>
        safeNumberComparator(a.period * 4 + (a?.week || 0), b.period * 4 + (a?.week || 0))
      )
      .map((metric) => ({
        x: metric.week ? `P${metric.period}W${metric.week}` : `P${metric.period}`,
        y: parseFloat(metric.onTimeShipmentRate)
      }))
      .filter((point) => labels.includes(point.x));
    return {
      label: `${year}`,
      backgroundColor: getDataSetColor(year),
      borderColor: getDataSetColor(year),
      data,
      pointStyle:
        data.length === 1 || !isCalendarValueContinuous(period)
          ? ('circle' as const)
          : (false as const)
    };
  });
};

export const OTSGraph = ({
  period,
  businessUnitIds,
  vendorMarketIds
}: {
  period: CalendarValue;
  businessUnitIds: string[];
  vendorMarketIds: string[];
}) => {
  const { data, loading } = useQuery(ExecutiveSummaryShipmentOnTimeRateTrendDocument, {
    variables: {
      filters: {
        businessUnitIds,
        vendorMarketIds,
        fiscalCalendarWeeks: transformCalendarValueToFiscalCalendarWeekInput(period)
      },
      aggregateBy: period.mode === Mode.PERIOD ? 'PERIOD' : 'WEEK'
    }
  });

  const metrics = useMemo(
    () => (data?.executiveSummaryShipmentOnTimeRateTrend?.metrics || []).filter(notEmpty),
    [data]
  );

  const chartData: ChartData<'line', { x: string; y: number }[], string> = useMemo(
    () => ({
      labels: getLineChartLabels(period),
      datasets: getDataSet(metrics, period)
    }),
    [metrics, period]
  );

  const shouldDisplayLines = isCalendarValueContinuous(period);

  return (
    <div>
      <h2 className={s.title}>
        <img src={truckTickIcon} alt="" />
        YOY On Time Shipment (OTS) Trend
      </h2>
      <Spin spinning={loading}>
        <div style={{ height: '200px' }}>
          <Line
            options={{
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                line: {
                  borderJoinStyle: 'round',
                  borderWidth: shouldDisplayLines ? 2 : 0
                },
                point: {
                  pointStyle: shouldDisplayLines ? false : 'circle'
                }
              },
              plugins: {
                legend: {
                  position: 'top',
                  align: 'end'
                },
                tooltip: {
                  callbacks: {
                    label: (context) => context.parsed.y + '%'
                  }
                }
              },
              scales: {
                x: {
                  offset: true,
                  grid: {
                    drawOnChartArea: false
                  },
                  ticks: {
                    maxRotation: 90,
                    minRotation: 90,
                    labelOffset: 0,
                    autoSkip: false
                  }
                },
                y: {
                  min: 0,
                  suggestedMax: 100,
                  ticks: {
                    callback: (value) => value + '%'
                  },
                  grid: {
                    drawOnChartArea: false
                  }
                }
              }
            }}
            data={chartData}
          />
        </div>
      </Spin>
    </div>
  );
};
