import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllStoresForBulkUploadQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RddFilters>;
}>;


export type AllStoresForBulkUploadQuery = { __typename?: 'RootQueryType', retailerDeliveryDestinations?: { __typename?: 'RetailerDeliveryDestinationConnection', edges: Array<{ __typename?: 'RetailerDeliveryDestinationEdge', node?: { __typename?: 'RetailerDeliveryDestination', id: string, externalId?: string | null, vendorMarket?: { __typename?: 'VendorMarket', id: string, externalId: string } | null } | null }> } | null };


export const AllStoresForBulkUploadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"allStoresForBulkUpload"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RddFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"retailerDeliveryDestinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"1000000"}},{"kind":"Argument","name":{"kind":"Name","value":"ignoreTprdds"},"value":{"kind":"BooleanValue","value":true}},{"kind":"Argument","name":{"kind":"Name","value":"ignoreAllowedTradingPartners"},"value":{"kind":"BooleanValue","value":true}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"vendorMarket"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<AllStoresForBulkUploadQuery, AllStoresForBulkUploadQueryVariables>;