import { DeleteOutlined, DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Popover, Button, Radio, Space, Checkbox, Input } from 'antd';
import React, { useState } from 'react';
import { useQueryParam, withDefault, JsonParam, UrlUpdateType } from 'use-query-params';
import st from './TableFilters.module.scss';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { isEqual, unionBy } from 'lodash-es';
import { notEmpty } from 'common/helpers/notEmpty';
import VirtualList from 'rc-virtual-list';

type FilterType = 'single' | 'multiple';

type FilterOption = {
  name: string;
  value: string;
};

type FilterBase<T extends string = string> = {
  name: string;
  type: FilterType;
  short?: string;
  field: T;
};

export type Filter<T extends string = string> = FilterBase<T> & {
  options: FilterOption[];
};

export type FilterIncomplete<T extends string = string> = FilterBase<T> & {
  options?: FilterOption[];
};

type FilterDropdownProps = {
  name: string;
  field: string;
  short?: string;
  options: FilterOption[];
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string | string[]>>>;
  type: FilterType;
};

const FilterMenuOption = ({
  options,
  type,
  name,
  setOuterPopover,
  disabled,
  setFilters,
  field
}: FilterDropdownProps & {
  disabled: boolean;
  setOuterPopover: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      open={disabled ? false : open}
      onOpenChange={setOpen}
      placement="right"
      overlayClassName={st.filterPopowerWrapper}
      content={
        <FilterDropdownContent
          name={name}
          field={field}
          setFilters={setFilters}
          options={options}
          type={type}
          onCancel={() => {
            setOpen(false);
            setOuterPopover(false);
          }}
        />
      }
      trigger="click"
    >
      <Button type="text" block disabled={disabled} data-testid={`add-filter-${name}`}>
        {name}
      </Button>
    </Popover>
  );
};

const ExistingFilter = ({
  options,
  type,
  name,
  short,
  setFilters,
  filters,
  field,
  loading
}: FilterDropdownProps & { filters: Record<string, string | string[]>; loading: boolean }) => {
  const [open, setOpen] = useState(false);

  const value = filters?.[field];
  const displayValue = Array.isArray(value)
    ? value
        .map((x) => (x ? options.find((option) => option.value === x)?.name || x : '[N/A]'))
        .slice(0, 8)
        .join(', ')
    : options.find((option) => option.value === value)?.name || value;

  return (
    <Popover
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      placement="bottomLeft"
      overlayClassName={st.popoverWrapper}
      destroyTooltipOnHide
      title={type === 'single' ? name : null}
      content={
        <FilterDropdownContent
          name={name}
          field={field}
          setFilters={setFilters}
          options={options}
          type={type}
          onCancel={() => {
            setOpen(false);
          }}
          existing
          filters={filters}
        />
      }
      trigger="click"
    >
      <Button
        style={{ height: '40px', display: 'flex', alignItems: 'center', gap: '4px' }}
        data-testid={`edit-filter-${name}`}
        disabled={loading}
      >
        {short || name}: {loading ? '...' : <span className={st.displayValue}>{displayValue}</span>}
        <DownOutlined style={{ fontSize: '8px' }} />
      </Button>
    </Popover>
  );
};

const MultipleSelect = ({
  values,
  setValues,
  options
}: {
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  options: FilterOption[];
}) => {
  const [search, setSearch] = useState('');
  // TODO: fix value-name
  const valuesAsOptions = values.map((value) => ({ name: value, value: value }));
  const optionsNoDuplicates = unionBy(options, valuesAsOptions, 'value').sort((a, b) =>
    safeLocaleCompare(a.name, b.name)
  );

  const filteredOptions = optionsNoDuplicates.filter((x) =>
    (x?.name || '').toString().toLowerCase().includes(search.toLowerCase())
  );

  const removeSelected = (x: string) =>
    setValues((values) => values.filter((v) => v.toLowerCase() !== x.toLowerCase()));

  return (
    <div>
      <div className={st.searchInput}>
        <Input
          size="large"
          placeholder="Search"
          prefix={<SearchOutlined />}
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={st.multipleSelectedValues}>
        {values.map((x) => (
          <Button
            data-testid={`remove-option-${x || 'n-a'}`}
            key={x}
            size="small"
            onClick={() => removeSelected(x)}
            style={{
              background: '#2A2A2A',
              color: 'white',
              borderRadius: '4px'
            }}
          >
            {options.find((option) => option.value === x)?.name || x || '[N/A]'}
          </Button>
        ))}
      </div>
      <VirtualList data={filteredOptions} height={128} itemHeight={22.5} itemKey="value">
        {(x) => (
          <div>
            <Checkbox
              checked={values.includes(x.value)}
              onChange={(e) =>
                e.target.checked
                  ? setValues([...new Set([...values, x.value])])
                  : removeSelected(x.value)
              }
              value={x.value}
              data-testid={`option-${x.name || 'n-a'}`}
            >
              {x.name || '[N/A]'}
            </Checkbox>
          </div>
        )}
      </VirtualList>
    </div>
  );
};

const FilterDropdownContent = ({
  options,
  type,
  onCancel,
  setFilters,
  field,
  existing,
  filters
}: FilterDropdownProps & {
  onCancel: () => void;
  existing?: boolean;
  filters?: Record<string, string | string[]>;
}) => {
  const initial =
    filters && existing ? filters[field] : type === 'single' ? options?.[0].value || '' : [];
  const [state, setState] = useState(initial);
  const [error, setError] = useState(false);

  const checkIfArrayIsEmpty = (value: string | string[]): boolean => {
    if (!Array.isArray(value)) {
      return false;
    } else {
      return value.length === 0;
    }
  };

  const applyFilter = () => {
    if (checkIfArrayIsEmpty(state)) {
      setError(true);
      return;
    }
    setError(false);

    setFilters((prev) => ({
      ...prev,
      [field]: state
    }));
    onCancel();
  };

  const deleteFilter = () => {
    setFilters((prev) => {
      const { [field]: toDelete, ...rest } = prev;
      return { ...rest };
    });
    onCancel();
  };

  return (
    <div className={st.filterDropdown}>
      {type === 'single' ? (
        <>
          <Radio.Group onChange={(e) => setState(e.target.value)} value={state}>
            <Space direction="vertical">
              {(options || []).map(({ name, value }) => (
                <Radio value={value} key={name} data-testid={`option-${value || 'n-a'}`}>
                  {name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </>
      ) : (
        <MultipleSelect
          values={state as string[]}
          setValues={setState as React.Dispatch<React.SetStateAction<string[]>>}
          options={options}
        />
      )}
      <div className={st.buttons}>
        {existing ? (
          <Button
            data-testid={`delete-filter`}
            icon={<DeleteOutlined />}
            size="small"
            type="text"
            onClick={() => deleteFilter()}
          />
        ) : (
          <></>
        )}
        <Button
          size="small"
          onClick={() => onCancel()}
          block={type === 'multiple'}
          data-testid={`cancel-filter-change`}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => applyFilter()}
          block={type === 'multiple'}
          data-testid={`apply-filter-change`}
        >
          Apply Filter
        </Button>
      </div>
      {error && checkIfArrayIsEmpty(state) ? (
        <div className={st.error}>Please select at least one value</div>
      ) : null}
    </div>
  );
};

export const TableFilters = ({
  filters,
  resetSelectedRows = () => {},
  queryParamName = 'filters', // TODO: move outside?
  loading = false
}: {
  filters: Filter[];
  resetSelectedRows?: () => void;
  queryParamName?: string;
  loading?: boolean;
}) => {
  // TODO: rewrite using useQueryParams to increase type safety?
  const [queryFilters, setQueryFilters] = useQueryParam(queryParamName, withDefault(JsonParam, {}));

  // Clever function to reset filters on query change. Anything to avoid useEffects.
  const setQueryFiltersAndResetRows = (newValue: any, updateType?: UrlUpdateType | undefined) => {
    if (!isEqual(newValue, queryFilters)) {
      resetSelectedRows();
    }
    setQueryFilters(newValue, updateType);
  };

  const [addOpen, setAddOpen] = useState(false);
  const displayedFilters = Object.keys(queryFilters)
    .map((x) => filters.find(({ field }) => field === x))
    .filter(notEmpty);

  return (
    <div className={st.filters}>
      {displayedFilters.map(({ name, options, type, short, field }) => (
        <ExistingFilter
          key={name}
          short={short}
          name={name}
          field={field}
          setFilters={setQueryFiltersAndResetRows}
          options={options}
          type={type}
          filters={queryFilters}
          loading={loading}
        />
      ))}
      <Popover
        open={addOpen}
        onOpenChange={(visible) => setAddOpen(visible)}
        placement="bottomLeft"
        overlayClassName={st.popoverWrapper}
        destroyTooltipOnHide
        content={
          <div className={st.menu}>
            {filters && filters.length > 0 ? (
              filters.map(({ name, options, type, field }) => (
                <FilterMenuOption
                  setFilters={setQueryFiltersAndResetRows}
                  disabled={!!queryFilters?.[field]}
                  key={name}
                  name={name}
                  field={field}
                  options={options}
                  type={type}
                  setOuterPopover={setAddOpen}
                />
              ))
            ) : (
              <div className={st.empty}>No filters provided</div>
            )}
          </div>
        }
        trigger="click"
      >
        <Button
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setAddOpen(true)}
          data-testid="add-filter"
          disabled={loading}
        >
          Add Filter
        </Button>
      </Popover>
      {displayedFilters.length > 0 ? (
        <Button
          type="link"
          style={{ fontWeight: 'bold' }}
          onClick={() => setQueryFiltersAndResetRows({})}
          size="large"
          data-testid="clear-filters"
          disabled={loading}
        >
          Clear
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};
