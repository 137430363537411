import React, { useMemo, useRef } from 'react';
import {
  StatChangeIndicator,
  StatChangeIndicatorProps
} from 'components/ui/StatChangeIndicator/StatChangeIndicator';
import s from './TotalWithGraph.module.scss';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  DotProps,
  Tooltip,
  TooltipProps
} from 'recharts';
import { useElementSize } from '@reactuses/core';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import clsx from 'clsx';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

export interface TotalWithGraphProps {
  total: string;
  title: string;
  change?: StatChangeIndicatorProps;
  changeText?: React.ReactNode;
  changeTooltip?: string;
  graph: Omit<GraphProps, 'size'>;
  loading?: boolean;
  size?: 'large' | 'small';
}

interface GraphProps {
  data: {
    label: string;
    value: number | undefined;
  }[];
  isContinious?: boolean;
  loading?: boolean;
  valueTickFormatter?: (value: number, index: number) => string;
  labelTickFormatter?: (value: string, index: number) => string;
  size?: 'large' | 'small';
}

export const TotalWithGraph = ({
  total,
  title,
  change,
  changeText,
  changeTooltip,
  graph,
  loading = false,
  size = 'large'
}: TotalWithGraphProps) => {
  return (
    <div className={clsx(s.wrapper, { [s.small]: size === 'small' })}>
      {size === 'large' && <div className={s.title}>{title}</div>}
      <div className={clsx(s.dataWrapper, { [s.small]: size === 'small' })}>
        <div className={clsx(s.totalWrapper, { [s.small]: size === 'small' })}>
          {loading ? <div className={s.titleLoading} /> : <div className={s.total}>{total}</div>}
          <div className={s.changeAndTotalWrapper}>
            {size === 'small' && <div className={s.title}>{title}</div>}
            <AlloyTooltip title={changeTooltip}>
              <div className={clsx(s.changeWrapper, { [s.small]: size === 'small' })}>
                {change && <StatChangeIndicator {...change} />}
                {change && changeText && <div className={s.changeText}>{changeText}</div>}
              </div>
            </AlloyTooltip>
          </div>
        </div>
        <div className={s.graph}>
          <Graph {...graph} size={size} />
        </div>
      </div>
    </div>
  );
};

const Graph = ({
  data,
  isContinious,
  loading,
  valueTickFormatter = (value) => `${value}`,
  labelTickFormatter = (value) => value,
  size
}: GraphProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [graphWidth] = useElementSize(ref);

  // If skipLabels = 1, we don't skip anything, if skipLabels = 2, we skip each second, and so on.
  const skipLabels = useMemo(() => {
    const MAX_TICK_WIDTH = 40;
    return Math.ceil((data.length * MAX_TICK_WIDTH) / graphWidth);
  }, [graphWidth, data.length]);

  // Some "Clever" tricks to calculate width of data points
  const dotWidth = useMemo(() => {
    const availableWidth = graphWidth / data.length;
    if (availableWidth > 12) {
      return 2.5;
    }
    if (availableWidth > 6) {
      return 1.5;
    }
    return 1;
  }, [graphWidth, data.length]);

  return (
    <div ref={ref}>
      <AlloySpin spinning={loading}>
        <ResponsiveContainer width="100%" height={size === 'large' ? 100 : 72}>
          <LineChart data={data} margin={{ top: 4, right: 16, bottom: -10, left: 20 }}>
            <XAxis
              axisLine={{ stroke: '#B1B1B1' }}
              dataKey="label"
              tick={{ textAnchor: 'middle', fill: '#B1B1B1', fontSize: '10px' }}
              tickSize={0}
              tickMargin={10}
              interval={0}
              tickFormatter={(value, index) =>
                value && index % skipLabels === 0 ? labelTickFormatter(value, index) : ''
              }
            />
            <YAxis
              axisLine={{ stroke: '#B1B1B1' }}
              domain={['auto', 'auto']}
              width={40}
              tick={{ fill: '#B1B1B1', fontSize: '10px' }}
              tickSize={0}
              tickMargin={5}
              tickFormatter={(value, index) => valueTickFormatter(value, index)}
            />
            <Line
              strokeWidth={2}
              type="linear"
              dataKey="value"
              stroke={isContinious ? '#0066FF' : 'none'}
              dot={(props) => <CustomDot {...props} radius={dotWidth} />}
              connectNulls={false}
              isAnimationActive={false}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: 'black', strokeDasharray: '3 3' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </AlloySpin>
    </div>
  );
};

const CustomDot = (props: DotProps & { value: number | undefined }) => {
  const { cx, cy, radius, value } = props;
  return value === undefined ? <></> : <circle cx={cx} cy={cy} r={radius} fill="#0066FF" />;
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const value = payload[0].payload;
    return (
      <div className={s.tooltip}>
        <div>
          Week of: <span className={s.value}>{label}</span>
        </div>
        <div>
          Value: <span className={s.value}>{value.value}</span>
        </div>
      </div>
    );
  }
  return null;
};
