import React from 'react';
import clsx from 'clsx';
import s from './StatChangeIndicator.module.scss';

export interface StatChangeIndicatorProps {
  value: React.ReactNode;
  isPositive: boolean;
  change?: 'increase' | 'decrease';
  className?: string;
}

export const StatChangeIndicator: React.FC<StatChangeIndicatorProps> = ({
  value,
  isPositive,
  change,
  className
}) => {
  return (
    <div className={clsx(className, s.wrapper, isPositive ? s.green : s.red)}>
      {change && (
        <svg
          className={clsx({ [s.down]: change === 'decrease' })}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.202 10.325 8.778 4.987a.845.845 0 0 0-.331-.235A1.181 1.181 0 0 0 8 4.667c-.156 0-.31.029-.447.085a.845.845 0 0 0-.331.235l-4.424 5.338a.54.54 0 0 0-.131.334.53.53 0 0 0 .117.336c.08.103.194.188.333.248.14.059.298.09.46.09h8.847c.161 0 .32-.031.459-.09a.828.828 0 0 0 .333-.248.53.53 0 0 0 .117-.336.54.54 0 0 0-.13-.334Z"
            fill="currentColor"
          />
        </svg>
      )}
      {value}
    </div>
  );
};
