export const calculateColor = (color1: string, color2: string, ratio: number) => {
  const hex = (x: number) => {
    const hexValue = x.toString(16);
    return hexValue.length === 1 ? '0' + hexValue : hexValue;
  };

  const value = ratio / 100;

  const r = Math.ceil(
    parseInt(color1.substring(0, 2), 16) * value +
      parseInt(color2.substring(0, 2), 16) * (1 - value)
  );
  const g = Math.ceil(
    parseInt(color1.substring(2, 4), 16) * value +
      parseInt(color2.substring(2, 4), 16) * (1 - value)
  );
  const b = Math.ceil(
    parseInt(color1.substring(4, 6), 16) * value +
      parseInt(color2.substring(4, 6), 16) * (1 - value)
  );

  return '#' + hex(r) + hex(g) + hex(b);
};

const calculateRatio = (rate: number, target = 50, range: [number, number] = [0, 100]) => {
  // Our rate should be between 0 and 100;
  const [min, max] = range;
  const limitedRate = Math.max(min, Math.min(max, rate));

  // Just-in-case safe calculations
  if (target === 100) return limitedRate / target;
  if (target === 0) return 1;

  if (limitedRate < target) {
    return limitedRate / target;
  } else {
    return (limitedRate - target) / (100 - target);
  }
};

export const calculateBgColorForRatio = (
  rate: number,
  target = 50,
  range: [number, number] = [0, 100]
) =>
  rate < target
    ? calculateColor('FFEAE9', 'FA3545', calculateRatio(rate, target, range) * 100)
    : calculateColor('91C48A', 'C9E2C6', calculateRatio(rate, target, range) * 100);

export const calculateBgColorRedOnly = (rate: number, range: [number, number] = [0, 100]) => {
  if (rate === 0) return '#FFFFFF';
  return calculateColor('FA3545', 'FFEAE9', calculateRatio(rate, 100, range) * 100);
};
