import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validateRequired } from '../../Common/fields/Utils';
import { FormInput, FormInputProps } from 'components/alloy/formFields/FormInput/FormInput';

interface FinalFormInputProps extends FormInputProps {
  name: string;
  title?: string;
  validate?: FieldValidator<string>;
  parse?: (value: string) => string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  showCount?: string;
}

export const FinalFormInput = ({
  name,
  title,
  disabled,
  validate,
  required,
  parse,
  onBlur,
  textarea = false,
  showCount,
  'data-testid': dataTestid = '',
  placeholder,
  className,
  hideFieldInfo
}: FinalFormInputProps) => {
  return (
    <Field
      name={name}
      required={required}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
      parse={parse}
    >
      {({ input, meta }) => {
        return (
          <FormInput
            {...input}
            label={title}
            data-testid={dataTestid}
            disabled={disabled}
            onBlur={(event) => {
              onBlur && onBlur(event);
              input.onBlur(event);
            }}
            textarea={textarea}
            className={className}
            placeholder={placeholder}
            error={meta.error && (meta.modified || meta.touched) ? meta.error : undefined}
            required={required}
            hideFieldInfo={hideFieldInfo}
            info={showCount ? `${input.value.length}/${showCount}` : undefined}
          />
        );
      }}
    </Field>
  );
};
