import React, { useEffect, useState } from 'react';
import './PoHistoryDrawer.scss';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PurchaseOrderEvent } from 'pages/OrdersPage/types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyDrawer } from 'components/ui/AlloyDrawer/AlloyDrawer';

interface PoHistoryDrawerProps {
  poHistory: PurchaseOrderEvent[];
  poNumber: string;
  toggleDrawer: () => void;
  visible: boolean;
}

export const PoHistoryDrawer = ({
  poHistory,
  poNumber,
  toggleDrawer,
  visible
}: PoHistoryDrawerProps) => {
  const { Option } = AlloySelect;
  const [filteredPOHistory, setFilteredPOHistory] = useState<PurchaseOrderEvent[]>();

  useEffect(() => {
    setFilteredPOHistory(
      [...poHistory].sort((a, b) => moment(b.actionTime).diff(moment(a.actionTime)))
    );
  }, [setFilteredPOHistory, poHistory]);

  const handlePOHistoryFilter = (value: string) => {
    switch (value) {
      case 'all':
        setFilteredPOHistory(poHistory);
        break;
      case 'user':
        setFilteredPOHistory(
          poHistory.filter(
            (event) => event.user && event.user.name !== null && event.source !== 'USER_SIDE_EFFECT'
          )
        );
        break;
      case 'system':
        setFilteredPOHistory(
          poHistory.filter(
            (event) =>
              event.source === 'SYSTEM' ||
              event.source === 'SYSTEM_SIDE_EFFECT' ||
              event.user === null
          )
        );
        break;
      default:
        return null;
    }
  };

  const columns: ColumnsType<PurchaseOrderEvent> = [
    {
      title: 'PROCESS',
      key: 'action',
      render: (_, record) => (
        <div data-testid="po-history-table-action" className="po_history_action">
          <div className="action">{record.action.replaceAll('_', ' ')}</div>
          {record.action === 'UPDATE_DESTINATION' && record.context ? (
            <div className="sub_detail">{`${record.context.updateDestination?.previousValue} to ${record.context.updateDestination?.currentValue}`}</div>
          ) : null}
          {record.details?.map((detail) => (
            <div key={detail} className="sub_detail">
              {detail}
            </div>
          ))}
        </div>
      )
    },
    {
      title: 'TIMELINE',
      key: 'actionTime',
      render: (_, record) => (
        <div data-testid="po-history-table-action-time">
          {moment(record.actionTime).format('D MMM YYYY, hh:mm')} hrs
        </div>
      )
    },
    {
      title: 'USER',
      key: 'user',
      render: (_, record) => (
        <div data-testid="po-history-table-user">
          {record.user && record.source === 'USER_SIDE_EFFECT'
            ? `${record.user.name} (User Side Effect)`
            : record.user?.name || '-'}
        </div>
      )
    }
  ];

  return (
    <AlloyDrawer
      data-testid="po-history-drawer"
      className="po_history_drawer"
      title={`PO: ${poNumber} Log Status`}
      closeIcon={<CloseOutlined data-testid="po-history-drawer-close-icon" />}
      onClose={toggleDrawer}
      destroyOnClose={true}
      open={visible}
      width={window.innerWidth > 900 ? 700 : window.innerWidth - 100}
    >
      <div className="po_history_select_container">
        <AlloySelect
          data-testid="po-history-select"
          className="po_history_select"
          defaultValue="all"
          onChange={(value) => handlePOHistoryFilter(value)}
        >
          <Option data-testid="option-all-logs" key="all" value="all">
            Show All Logs
          </Option>
          <Option data-testid="option-user-logs" key="user" value="user">
            Show User Generated
          </Option>
          <Option data-testid="option-system-logs" key="system" value="system">
            Show System Generated
          </Option>
        </AlloySelect>
      </div>
      <AlloyTable
        data-testid="po-history-table"
        className="po_history_table"
        columns={columns}
        dataSource={filteredPOHistory}
        rowKey={(row) => `${row.action}${row.actionTime}`}
        pagination={false}
      />
    </AlloyDrawer>
  );
};
