import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateSalesOrderItemTableAllocatedQtyMutationVariables = Types.Exact<{
  input: Types.UpdateSalesOrderItemInput;
}>;


export type UpdateSalesOrderItemTableAllocatedQtyMutation = { __typename?: 'RootMutationType', updateSalesOrderItem?: { __typename?: 'UpdateSalesOrderItemPayload', salesOrderItem?: { __typename?: 'SalesOrderItem', id: string, comment?: string | null, weightInLbs?: number | null, acceptedUnits?: number | null, pallets?: number | null, palletsWeightInLbs?: number | null } | null } | null };


export const UpdateSalesOrderItemTableAllocatedQtyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateSalesOrderItemTableAllocatedQty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateSalesOrderItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateSalesOrderItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrderItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"weightInLbs"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedUnits"}},{"kind":"Field","name":{"kind":"Name","value":"pallets"}},{"kind":"Field","name":{"kind":"Name","value":"palletsWeightInLbs"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateSalesOrderItemTableAllocatedQtyMutation, UpdateSalesOrderItemTableAllocatedQtyMutationVariables>;