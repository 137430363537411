import React, { useMemo, useState } from 'react';
import s from './LastUpdated.module.scss';
import { useQuery } from '@apollo/client';
import { ProductFillRateReportUpdateStatsDocument } from 'pages/Visibility/gql/__generated__/productFillRateReportUpdateStats.query';
import { Tooltip } from 'antd';
import moment from 'moment';
import { throttle } from 'lodash-es';

const formatString = 'M/D/YY h:mm A';
const MINIMUM_REFETCH_TIME = 30_000;

export const LastUpdated = () => {
  const [isOpen, setOpen] = useState(false);
  const { data, loading, error, refetch } = useQuery(ProductFillRateReportUpdateStatsDocument);

  // Refetch no more often than 1 time in MINIMUM_REFETCH_TIME.
  const throttledRefetch = useMemo(
    () => throttle(refetch, MINIMUM_REFETCH_TIME, { leading: true, trailing: false }),
    [refetch]
  );

  if (error) return <></>;
  const onOpenChange = (open: boolean) => {
    if (open) {
      throttledRefetch();
    }
    setOpen(open);
  };

  return (
    <Tooltip
      placement="bottomLeft"
      overlayClassName={s.overlay}
      open={isOpen}
      onOpenChange={(open) => onOpenChange(open)}
      overlay={
        <>
          {data?.productFillRateReportUpdateStats?.poUpdatedAt && (
            <div className={s.updated_string}>
              <span>Customer last updated at:</span>{' '}
              <span>
                {loading
                  ? '...'
                  : moment(data?.productFillRateReportUpdateStats?.poUpdatedAt).format(
                      formatString
                    )}
              </span>
            </div>
          )}
          {data?.productFillRateReportUpdateStats?.soUpdatedAt && (
            <div className={s.updated_string}>
              <span>SAP last updated at:</span>{' '}
              <span>
                {loading
                  ? '...'
                  : moment(data?.productFillRateReportUpdateStats?.soUpdatedAt).format(
                      formatString
                    )}
              </span>
            </div>
          )}
        </>
      }
    >
      <span className={s.last_update_date}>Last Updated</span>
    </Tooltip>
  );
};
