import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parse, stringify, ParsedQuery } from 'query-string';

interface ParamUpdates {
  [name: string]: string | number | null | undefined | string[] | number[];
}

type UseUrlParamsReturn = [
  ParsedQuery<string>,
  (updates: ParamUpdates, options?: { atomic?: boolean }) => void
];

export const useUrlParams = (): UseUrlParamsReturn => {
  const history = useHistory();
  const params = parse(history.location.search, { arrayFormat: 'comma' });
  const [paramState, setParamState] = useState(params);

  useEffect(() => {
    if (stringify(paramState) != stringify(params)) setParamState(params);
  }, [params]);

  const setParams = useCallback(
    (updates: ParamUpdates, options?: { atomic?: boolean }) => {
      if (!updates) return;
      const params = parse(history.location.search);
      const originalParams = { ...params };

      //if the update is atomic, we assume any url parameter that isn't included in the updates object should be removed from the url
      if (options?.atomic) {
        Object.keys(params).forEach((key: string) => {
          if (updates[key] == null) {
            delete params[key];
          }
        });
      }

      //remove params that are intentionally set as null or undefined, and set params for every valid key value pair in the updates object
      Object.keys(updates).forEach((key) => {
        if (
          updates[key] === null ||
          (Array.isArray(updates[key]) && !(updates[key] as string[])?.length)
        ) {
          delete params[key];
        } else if (!!updates[key]) {
          params[key] = `${updates[key]}`;
        }
      });

      if (stringify(originalParams) != stringify(params)) {
        setParamState(params);
        history.push({
          search: stringify(params, { arrayFormat: 'comma' })
        });
      }
    },
    [history]
  );

  return [paramState, setParams];
};
