import React, { ReactElement } from 'react';
import s from './PurchaseOrderDetailsInformationItem.module.scss';
import clsx from 'clsx';

interface PurchaseOrderDetailsInformationItemProps {
  itemTitle: string | ReactElement;
  itemData: string | ReactElement | undefined;
  error?: boolean;
  'data-testid'?: string;
}

const PurchaseOrderDetailsInformationItem = ({
  itemTitle,
  itemData,
  error,
  'data-testid': testId
}: PurchaseOrderDetailsInformationItemProps) => {
  return (
    <div data-testid={testId} className={s.po_info_item}>
      <div className={s.po_info_item_title}>{itemTitle}</div>
      <div className={clsx(error ? s.error : '', s.po_info_item_data)}>{itemData}</div>
    </div>
  );
};

export default PurchaseOrderDetailsInformationItem;
