import React, { ReactNode } from 'react';
import s from './PageHeader.module.scss';
import clsx from 'clsx';

interface PageHeaderProps {
  children: ReactNode;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const PageHeader: React.FC<PageHeaderProps> = ({ children, className, ...otherProps }) => (
  <h1 className={clsx(s.header, className)} {...otherProps}>
    {children}
  </h1>
);
