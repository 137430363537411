import { FilterOperator } from 'common/constants';
import { DateRange, Filter, LocationUpdate, NumberRange } from 'common/interfaces';
import { uniq } from 'lodash-es';
import { parse, stringify } from 'query-string';

export const EXCLUDE_PREFIX = 'remove_';

export const filtersToLocalUpdate = (filters: Filter[]): LocationUpdate =>
  filters.reduce((previousValue: LocationUpdate, currentValue: Filter) => {
    if (!currentValue.value) return previousValue;
    if ((currentValue.value as DateRange).start) {
      return {
        ...previousValue,
        [`${currentValue.name!}_start`]: (currentValue.value as DateRange).start,
        [`${currentValue.name!}_end`]: (currentValue.value as DateRange).end
      } as LocationUpdate;
    } else if (
      ((currentValue.value as NumberRange).min !== null &&
        (currentValue.value as NumberRange).min !== undefined) ||
      ((currentValue.value as NumberRange).max !== null &&
        (currentValue.value as NumberRange).max !== undefined)
    ) {
      return {
        ...previousValue,
        [`${currentValue.name!}_min`]: (currentValue.value as NumberRange).min,
        [`${currentValue.name!}_max`]: (currentValue.value as NumberRange).max
      } as LocationUpdate;
    } else {
      if (currentValue.option) {
        const existingValue = previousValue[currentValue.name!];
        return {
          ...previousValue,
          [currentValue.name!]: uniq([
            ...((existingValue as string[]) || []),
            ...(currentValue.value as string[]).map(
              (value) =>
                `${currentValue.option === FilterOperator.EXCLUDE ? EXCLUDE_PREFIX : ''}${value}`
            )
          ])
        } as LocationUpdate;
      } else {
        return {
          ...previousValue,
          [currentValue.name!]: currentValue.value as string | string[]
        } as LocationUpdate;
      }
    }
  }, {} as LocationUpdate);

export const updatePagingCallback = (history: any, update?: LocationUpdate) => {
  const location = parse(history.location.search);
  if (update) {
    Object.keys(update).forEach((key) => {
      const t = update[key];
      location[key] = t === undefined ? null : typeof t === 'number' ? `${t}` : t;
      if (!update[key]) {
        delete location[key];
      }
    });
  }

  history.push({
    search: stringify(location)
  });
};
