import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ScrubOrdersWithUploadedDataMutationVariables = Types.Exact<{
  input: Types.ScrubOrdersWithUploadedDataInput;
}>;


export type ScrubOrdersWithUploadedDataMutation = { __typename?: 'RootMutationType', scrubOrdersWithUploadedData?: { __typename?: 'ScrubOrdersWithUploadedDataPayload', salesOrderIds: Array<string> } | null };


export const ScrubOrdersWithUploadedDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"scrubOrdersWithUploadedData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ScrubOrdersWithUploadedDataInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scrubOrdersWithUploadedData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrderIds"}}]}}]}}]} as unknown as DocumentNode<ScrubOrdersWithUploadedDataMutation, ScrubOrdersWithUploadedDataMutationVariables>;