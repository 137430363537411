import { Button, Row, Table } from 'antd';
import { FinalFormSwitch } from 'components/Common/fields/FinalFormSwitch/FinalFormSwitch';
import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import s from './ActiveAssortmentTab.module.scss';
import { EditableCell, EditableRow } from './components/EditableCell';
import { ValidationRulesModal } from './components/ValidationRulesModal/ValidationRulesModal';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
import { FormField } from '../../EditTradingPartnerModal/EditTradingPartnerModal';
import { useEnumValue } from 'common/useEnumValue';
import { AssortmentFieldValidation, CatalogItemType } from 'graphql/__generated__/types';
import { CatalogItemType as CatalogItemTypeEnum } from 'graphql/__generated__/enums';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import { getDefaultFieldTitles } from 'components/ChangeHistory/helpers/baseHelper';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';

enum IdFields {
  UPC = 'UPC',
  GTIN14 = 'GTIN14',
  SAP_MATERIAL_ID = 'SAP_MATERIAL_ID',
  ORACLE_INVEN_ID = 'ORACLE_INVEN_ID'
}

const CatalogItemTypeToIdMap = new Map<CatalogItemType, IdFields[]>([
  [CatalogItemTypeEnum.GTIN12, [IdFields.UPC]],
  [CatalogItemTypeEnum.GTIN14, [IdFields.GTIN14]],
  [CatalogItemTypeEnum.SAP, [IdFields.GTIN14, IdFields.SAP_MATERIAL_ID]],
  [CatalogItemTypeEnum.ORACLE, [IdFields.UPC, IdFields.ORACLE_INVEN_ID]]
]);

const getFeatures = (useNewAssortment: boolean) => {
  const features = [
    {
      feature: 'multipleUpc',
      featureTitle: 'Multiple UPCs'
    },
    {
      feature: 'specialAssortment',
      featureTitle: 'Vendor flex'
    }
  ];
  if (useNewAssortment) {
    features.splice(
      1,
      0,
      {
        feature: 'useMultipleSubstitutions',
        featureTitle: 'Use Multiple Substitutions'
      },
      {
        feature: 'useDcAvailabilities',
        featureTitle: 'Use DC Availabilities'
      }
    );
  }
  return features;
};

const IdFieldsDefaultValidation = new Map<IdFields, AssortmentFieldValidation>([
  [
    IdFields.UPC,
    {
      rules: [
        {
          type: 'NUMERIC',
          value: '12'
        }
      ]
    }
  ],
  [
    IdFields.GTIN14,
    {
      rules: [
        {
          type: 'NUMERIC',
          value: '14'
        }
      ]
    }
  ],
  [
    IdFields.SAP_MATERIAL_ID,
    {
      rules: [
        {
          type: 'NUMERIC',
          value: '18'
        }
      ]
    }
  ],
  [
    IdFields.ORACLE_INVEN_ID,
    {
      rules: [
        {
          type: 'NUMERIC',
          value: undefined
        }
      ]
    }
  ]
]);

export const ActiveAssortmentTab = () => {
  const [editingValidationRules, setEditingValidationRules] = useState<
    FormField & { arrayName: string }
  >();

  const { enumValues, loading } = useEnumValue('CatalogItemType');

  const form = useForm();

  const { enabled: useNewAssortment } = useFeatureFlag({
    name: 'schip_assortment_v3_management_for_tp',
    tradingPartnerExternalId: form.getState().values.externalId
  });

  const getFieldsConfigTable = (fields: (FormField & { arrayName: string })[]) => (
    <Table<FormField & { arrayName: string }>
      className={clsx(s.table, 'legacy_borderless_bordered')}
      bordered
      components={components}
      rowClassName={(record) => (record.active ? s.editable_row : s.disabled_row)}
      rowKey={(record) => record.slug || ''}
      sticky={{
        offsetHeader: 0
      }}
      columns={[
        {
          title: 'Form fields',
          render: (record: any) => (
            <span data-testid={`edit-trading-partner-${record.slug.toLowerCase()}-name`}>
              {record.name}
            </span>
          ),
          onCell: (record) => ({
            record,
            editable: record.active,
            dataIndex: 'name',
            title: 'Form fields',
            handleSave: handleSaveName
          })
        },
        {
          title: 'On/Off',
          render: (record) => {
            const disabled = record.slug === 'NAME' || Object.keys(IdFields).includes(record.slug);
            return (
              <FinalFormSwitch
                classname={clsx(s.without_bottom_margin, s.disabled_checked_blue)}
                title=""
                name={`${record.arrayName}.active`}
                disabled={disabled}
                data-testid={`edit-trading-partner-${record.slug.toLowerCase()}-toggle`}
              />
            );
          },
          width: 56
        },
        {
          title: 'Required',
          render: (record) => {
            const disabled =
              !record.active ||
              record.slug === 'NAME' ||
              Object.keys(IdFields).includes(record.slug) ||
              record.validate;
            return (
              <FinalFormSwitch
                classname={clsx(s.without_bottom_margin, s.disabled_checked_blue)}
                title=""
                checkbox
                name={`${record.arrayName}.required`}
                disabled={disabled}
                data-testid={`edit-trading-partner-${record.slug.toLowerCase()}-required-checkbox`}
              />
            );
          },
          width: 70
        },
        {
          title: 'Validation rules',
          render: (value: FormField, record: FormField & { arrayName: string }, index: number) => (
            <div className={s.edit_container}>
              <FinalFormSwitch
                classname={clsx(s.without_bottom_margin, s.disabled_checked_blue)}
                checkbox
                title=""
                name={`${record.arrayName}.validate`}
                disabled={!record.active || Object.keys(IdFields).includes(record.slug || '')}
                data-testid={`edit-trading-partner-${value.slug?.toLowerCase()}-validation-rules-checkbox`}
              />
              <OnChange name={`${record.arrayName}.validate`}>
                {(value: boolean) => {
                  form.mutators.setAssortmentValidation(
                    record.arrayName,
                    value
                      ? Object.keys(IdFields).includes(record.slug || '')
                        ? IdFieldsDefaultValidation.get(record.slug as IdFields)
                        : {
                            rules: [
                              {
                                type: 'TEXT',
                                value: undefined
                              }
                            ]
                          }
                      : undefined
                  );
                }}
              </OnChange>
              <Button
                data-testid={`edit-trading-partner-${record.slug?.toLowerCase()}-validation-rules-edit`}
                disabled={!record.active || !record.validate}
                type="link"
                onClick={() =>
                  setEditingValidationRules({
                    ...value,
                    arrayName: record.arrayName
                  })
                }
              >
                {Object.keys(IdFields).includes(record.slug || '') ? 'View' : 'Edit'}
              </Button>
            </div>
          ),
          width: 114
        }
      ]}
      dataSource={fields}
      pagination={false}
      size="small"
    />
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const handleSaveName = (arrayName: string, newValue: string) => {
    form.mutators.setAssortmentName(arrayName, newValue);
  };

  return (
    <>
      <Row
        className={s.title_row}
        data-testid="edit-trading-partner-assortment-tab-additional-features-title"
      >
        Additional features
      </Row>
      <Row className={s.subtitle_row}>Choose additional features for Active assortment.</Row>
      <Table
        className={clsx(s.table, 'legacy_borderless_bordered')}
        bordered
        rowKey={(record) => record.feature}
        columns={[
          {
            title: 'Features to include',
            render: (record) => (
              <span data-testid={`edit-trading-partner-assortment-tab-${record.feature}-title`}>
                {record.featureTitle}
              </span>
            )
          },
          {
            render: (record) => (
              <FinalFormSwitch
                classname={s.without_bottom_margin}
                name={`assortmentConfig.${record.feature}`}
                title=""
                data-testid={`edit-trading-partner-${record.feature}-feature-toggle`}
              />
            ),
            width: 50
          }
        ]}
        dataSource={getFeatures(!!useNewAssortment)}
        pagination={false}
        size="small"
      />
      <Row
        className={s.title_row}
        data-testid="edit-trading-partner-assortment-tab-configure-fields-title"
      >
        Configure Active assortment form fields
      </Row>
      <Row className={s.subtitle_row}>
        Specify the rules associated with each field when adding a new product.
      </Row>
      {useNewAssortment && (
        <>
          <Row
            className={s.title_row}
            data-testid="edit-trading-partner-assortment-tab-product-identifiers-title"
          >
            Product Identifiers
          </Row>
          <FinalFormSelect
            title="Product Identifier Type"
            name="assortmentConfig.catalogItemType"
            required
            loading={loading}
            options={enumValues
              ?.map((type) => ({
                label: `${type} (${CatalogItemTypeToIdMap.get(type)
                  ?.map((fieldName) => getDefaultFieldTitles(fieldName))
                  .join(' + ')})`,
                value: type
              }))
              .sort((dc1, dc2) => dc1.label.localeCompare(dc2.label))}
          />
          <OnChange name="assortmentConfig.catalogItemType">
            {(value: CatalogItemType) => {
              // update identifiers config
              const fieldsToSelect = CatalogItemTypeToIdMap.get(value) ?? [];
              for (let i = 0; i < fieldsToSelect.length; i++) {
                const field = fieldsToSelect[i];
                form.mutators.selectConfigField(field, IdFieldsDefaultValidation.get(field));
              }
              const fieldsToUnselect = Object.values(IdFields).filter(
                (f) => !fieldsToSelect.includes(f as IdFields)
              );
              for (let i = 0; i < fieldsToUnselect.length; i++) {
                const field = fieldsToUnselect[i];
                form.mutators.unselectConfigField(field);
              }
            }}
          </OnChange>
          <FieldArray name={'assortmentConfig.fields'}>
            {({ fields }) => {
              return (
                <>
                  {getFieldsConfigTable(
                    fields
                      .map((name, index) => {
                        const value = fields.value[index];
                        return {
                          ...value,
                          arrayName: name
                        } as FormField & { arrayName: string };
                      })
                      .filter(
                        (value) =>
                          Object.keys(IdFields).includes(value.slug || '') ||
                          value.slug === 'EXTERNAL_ID'
                      )
                  )}
                  <Row
                    className={s.title_row}
                    data-testid="edit-trading-partner-assortment-tab-product-identifiers-title"
                  >
                    Additional Fields
                  </Row>
                  {getFieldsConfigTable(
                    fields
                      .map((name, index) => {
                        const value = fields.value[index];
                        return {
                          ...value,
                          arrayName: name
                        } as FormField & { arrayName: string };
                      })
                      .filter(
                        (value) =>
                          !Object.keys(IdFields).includes(value.slug || '') &&
                          value.slug !== 'EXTERNAL_ID'
                      )
                  )}
                </>
              );
            }}
          </FieldArray>
        </>
      )}
      {!useNewAssortment && (
        <FieldArray name={'assortmentConfig.fields'}>
          {({ fields }) => (
            <Table<FormField & { arrayName: string }>
              className={clsx(s.table, 'legacy_borderless_bordered')}
              bordered
              components={components}
              rowClassName={(record) => (record.active ? s.editable_row : s.disabled_row)}
              rowKey={(record) => record.slug || record.arrayName}
              sticky={{
                offsetHeader: 0
              }}
              columns={[
                {
                  title: 'Form fields',
                  render: (record: any) => (
                    <span data-testid={`edit-trading-partner-${record.slug.toLowerCase()}-name`}>
                      {record.name}
                    </span>
                  ),
                  onCell: (record) => ({
                    record,
                    editable: record.active,
                    dataIndex: 'name',
                    title: 'Form fields',
                    handleSave: handleSaveName
                  })
                },
                {
                  title: 'On/Off',
                  render: (record: any) => {
                    const disabled = record.slug === 'NAME' || record.slug === 'UPC';
                    return (
                      <FinalFormSwitch
                        classname={clsx(s.without_bottom_margin, disabled && s.disabled_blue)}
                        title=""
                        name={`${record.arrayName}.active`}
                        disabled={disabled}
                        data-testid={`edit-trading-partner-${record.slug.toLowerCase()}-toggle`}
                      />
                    );
                  },
                  width: 56
                },
                {
                  title: 'Required',
                  render: (record) => {
                    const disabled =
                      !record.active ||
                      record.slug === 'NAME' ||
                      record.slug === 'UPC' ||
                      record.validate;
                    return (
                      <FinalFormSwitch
                        classname={clsx(s.without_bottom_margin, disabled && s.disabled_blue)}
                        title=""
                        checkbox
                        name={`${record.arrayName}.required`}
                        disabled={disabled}
                        data-testid={`edit-trading-partner-${record.slug.toLowerCase()}-required-checkbox`}
                      />
                    );
                  },
                  width: 70
                },
                {
                  title: 'Validation rules',
                  render: (
                    value: FormField,
                    record: FormField & { arrayName: string },
                    index: number
                  ) => (
                    <div className={s.edit_container}>
                      <FinalFormSwitch
                        classname={s.without_bottom_margin}
                        checkbox
                        title=""
                        name={`${record.arrayName}.validate`}
                        disabled={!record.active}
                        data-testid={`edit-trading-partner-${value.slug?.toLowerCase()}-validation-rules-checkbox`}
                      />
                      <OnChange name={`${record.arrayName}.validate`}>
                        {(value: boolean) => {
                          form.mutators.setAssortmentValidation(
                            record.arrayName,
                            value
                              ? {
                                  rules: [
                                    {
                                      type: 'TEXT',
                                      value: undefined
                                    }
                                  ]
                                }
                              : undefined
                          );
                        }}
                      </OnChange>
                      <Button
                        data-testid={`edit-trading-partner-${record.slug?.toLowerCase()}-validation-rules-edit`}
                        disabled={!record.active || !record.validate}
                        type="link"
                        onClick={() =>
                          setEditingValidationRules({
                            ...value,
                            arrayName: record.arrayName
                          })
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  ),
                  width: 114
                }
              ]}
              dataSource={fields.map((name, index) => {
                const value = fields.value[index];
                return {
                  ...value,
                  arrayName: name
                } as FormField & { arrayName: string };
              })}
              pagination={false}
              size="small"
            />
          )}
        </FieldArray>
      )}
      {editingValidationRules && editingValidationRules.validation && (
        <ValidationRulesModal
          fieldName={editingValidationRules.name}
          initValidation={editingValidationRules.validation}
          onSaveValidationRules={(validation) => {
            form.mutators.setAssortmentValidation(editingValidationRules.arrayName, validation);
            setEditingValidationRules(undefined);
          }}
          onClose={() => setEditingValidationRules(undefined)}
          viewOnly={
            useNewAssortment
              ? Object.keys(IdFields).includes(editingValidationRules.slug || '')
              : false
          }
        />
      )}
    </>
  );
};
