import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import s from './SalesOrderShipment.module.scss';
import { LeftOutlined, EllipsisOutlined } from '@ant-design/icons';
import { App } from 'ant5';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import manualIcon from '../../../../assets/icons/purchaseOrders/manual_source_icon.svg';
import schipIcon from '../../../../assets/icons/purchaseOrders/schip_source_icon.svg';
import { Moment } from 'moment';
import PurchaseOrderStatus from '../../../../components/PurchaseOrders/PurchaseOrderStatus/PurchaseOrderStatus';
import {
  DeliveryType,
  PO_STATUS_ACKNOWLEDGED,
  PO_STATUS_SCRUBBED,
  PurchaseOrderRefetchQueries
} from 'common/constants';
import { ParsedShipment } from 'common/helpers/purchaseOrderDataHelper';
import { SalesOrderShipmentDateField } from './SalesOrderShipmentDateField';
import SalesOrderItemsTable from './SalesOrderItemsTable/SalesOrderItemsTable';
import ChangeDcModal from 'pages/OrderDetailsPage/components/ChangeDcModal/ChangeDcModal';
import { UpdateSalesOrderProductNoteDocument } from './gql/__generated__/updateSalesOrderProductNote.mutation';
import { PurchaseOrderWithOverdue, SalesOrderItem } from 'pages/OrderDetailsPage/types';

interface SalesOrderShipmentProps {
  shipments: ParsedShipment[];
  shipmentGroup: string;
  purchaseOrder: PurchaseOrderWithOverdue;
  onSelectAction: (selectedRows: SalesOrderItem[]) => void;
  selectedKeys: string[];
}

interface ChangeDcActionProps {
  visibility: boolean;
  id: string;
  dcId: string;
  oldDc: string;
  newDc: string;
  numberOfProducts: number;
}

const SalesOrderShipment = ({
  shipments,
  shipmentGroup,
  purchaseOrder,
  onSelectAction,
  selectedKeys
}: SalesOrderShipmentProps) => {
  const { message } = App.useApp();

  const [updateSalesOrderDc] = useMutation(UpdateSalesOrderProductNoteDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });

  const changeDcActionDefaultProps = {
    visibility: false,
    id: '',
    dcId: '',
    oldDc: '',
    newDc: '',
    numberOfProducts: 0
  };

  const [changeDcActionProps, setChangeDcActionProps] = useState<ChangeDcActionProps>(
    changeDcActionDefaultProps
  );

  const changeSalesOrderDc = async () => {
    try {
      await updateSalesOrderDc({
        variables: {
          input: {
            id: changeDcActionProps.id,
            distributionCenterId: changeDcActionProps.dcId
          }
        }
      });
    } catch (e) {
      message.error(e);
    } finally {
      setChangeDcActionProps(changeDcActionDefaultProps);
      message.success(
        <span data-testid="change-SO-DC-success-message">{`DC: ${changeDcActionProps.oldDc}, Changed to DC: ${changeDcActionProps.newDc}`}</span>
      );
    }
  };

  const deliveryType = purchaseOrder.tradingPartner?.deliveryType;

  const editSalesOrderMenu = (editableItem: ParsedShipment, oldDc: string) => [
    {
      icon: null,
      expandIcon: <></>,
      key: 'CHANGE_DC',
      popupClassName: s.changeDcPopup,
      label: (
        <span data-testId="change-dc-menu">
          <LeftOutlined style={{ paddingRight: '8px' }} />
          Change DC
        </span>
      ),
      children:
        purchaseOrder?.tradingPartner?.distributionCenters?.map(({ id, code, name }) => ({
          key: id,
          label: `${name} ${code}`,
          onClick: () =>
            setChangeDcActionProps({
              visibility: true,
              id: editableItem.id,
              dcId: id,
              oldDc: oldDc,
              newDc: `${name} ${code}`,
              numberOfProducts: editableItem.items.length
            })
        })) || []
    }
  ];

  const getShipmentHeader = (
    index: number,
    itemCount: number,
    shipmentGroup: string,
    status: string,
    tracking: string | undefined,
    pickupDate: Moment | undefined,
    shipmentSource: string | null | undefined,
    name: string | undefined
  ) => {
    return (
      <div className={s.shipment_summary}>
        <span data-testid="shipment-source-icon">
          {shipmentSource === 'SAP' || shipmentSource === 'ORACLE' ? (
            <AlloyTooltip data-testid="manual-source-tooltip" title="Manual" placement="bottom">
              <img className={s.source_icon} src={manualIcon} alt="" />
            </AlloyTooltip>
          ) : shipmentSource === 'OREX' ? (
            <AlloyTooltip data-testid="schip-source-tooltip" title="SCHIP" placement="bottom">
              <img className={s.source_icon} src={schipIcon} alt="" />
            </AlloyTooltip>
          ) : null}
        </span>
        <span>{`${shipmentGroup} `}</span>
        <span className={s.summary_counts}>
          {`(${name}: ${itemCount} item${itemCount > 1 ? 's' : ''}) `}
        </span>
        {status ? <PurchaseOrderStatus status={status} /> : null}
      </div>
    );
  };

  return (
    <div key={shipmentGroup}>
      {shipments.map((shipment, index) => {
        const {
          id,
          status,
          items,
          date,
          zeroPromise,
          ochDummySapId,
          tracking,
          pickupDate,
          alternateOrderId,
          externalId,
          shipmentSource,
          name
        } = shipment;
        if (zeroPromise) return null;

        return (
          <div className={s.sales_order} key={id}>
            <div className={s.shipment_header}>
              {getShipmentHeader(
                index,
                items.length,
                shipmentGroup,
                status || '',
                tracking,
                pickupDate,
                shipmentSource,
                name
              )}
              <div data-testid="sales-order-number" className={s.shipment_details}>
                {deliveryType === 'DSD' ? (
                  <>
                    {externalId ? (
                      <span className={s.shipment_id}>
                        OCH Order ID:
                        <span className={s.shipment_value}>{externalId}</span>
                      </span>
                    ) : null}
                  </>
                ) : deliveryType === 'ECOMM' ? (
                  <>
                    {externalId ? (
                      <span className={s.shipment_id}>
                        External ID:
                        <span className={s.shipment_value}>{externalId}</span>
                      </span>
                    ) : null}

                    {tracking ? (
                      <span className={s.shipment_id}>
                        Tracking:
                        <span className={s.shipment_value}>{tracking}</span>
                      </span>
                    ) : null}

                    {pickupDate ? (
                      <span className={s.pickup_date}>
                        Pickup Date:
                        <span className={s.shipment_value}>
                          {pickupDate ? pickupDate.format('M/D/YY') : 'TBD'}
                        </span>
                      </span>
                    ) : null}
                  </>
                ) : deliveryType === 'WHD' ? (
                  <>
                    {externalId ? (
                      <span className={s.shipment_id}>
                        OCH Order ID:<span className={s.shipment_value}>{externalId}</span>
                      </span>
                    ) : null}

                    {ochDummySapId && !alternateOrderId ? (
                      <span className={s.shipment_id}>
                        Dummy Order#:<span className={s.shipment_value}>{ochDummySapId}</span>
                      </span>
                    ) : null}

                    {alternateOrderId || status === PO_STATUS_ACKNOWLEDGED ? (
                      <span className={s.shipment_id}>
                        Sales Order#:
                        <span className={s.shipment_value}>{alternateOrderId}</span>
                      </span>
                    ) : null}
                  </>
                ) : null}

                {deliveryType === DeliveryType.WHD && status === PO_STATUS_SCRUBBED ? (
                  <div className={[s.date_field, s.date_container].join(' ')}>
                    <span className={s.date_field_shipment_id}>RDD:</span>
                    <SalesOrderShipmentDateField salesOrderId={id} deliveryWindowEnd={date} />
                  </div>
                ) : deliveryType === DeliveryType.WHD && date ? (
                  <span>
                    RDD:
                    <span className={s.shipment_value}>{date.format('MM/DD/YY')}</span>
                  </span>
                ) : deliveryType === DeliveryType.DSD && date ? (
                  <span>
                    RDD:
                    <span className={s.shipment_value}>{date.format('MM/DD/YY')}</span>
                  </span>
                ) : null}
              </div>

              {deliveryType === DeliveryType.WHD ? (
                <div>
                  <span className={s.edit_sales_order}>
                    <AlloyDropdown
                      trigger={['click']}
                      placement="bottomLeft"
                      menu={{
                        items: editSalesOrderMenu(
                          shipment,
                          `${shipmentGroup}, Shipment ${index + 1} | ${items.length} item ${
                            items.length > 1 ? 's' : ''
                          }`
                        )
                      }}
                    >
                      <span data-testid="edit-sales-order-dropdown">
                        <EllipsisOutlined rotate={90} style={{ fontSize: 25, marginTop: 5 }} />
                      </span>
                    </AlloyDropdown>
                  </span>
                </div>
              ) : null}
            </div>
            <SalesOrderItemsTable
              purchaseOrder={purchaseOrder}
              orderStatus={status || ''}
              items={items}
              onSelectAction={onSelectAction}
              selectedKeys={selectedKeys}
              // zeroPromise={zeroPromise} //TODO: use cutSummaries
            />
          </div>
        );
      })}
      <ChangeDcModal
        visibility={changeDcActionProps.visibility}
        toggleModalVisibility={() =>
          setChangeDcActionProps({
            ...changeDcActionProps,
            visibility: !changeDcActionProps.visibility
          })
        }
        changeDc={changeSalesOrderDc}
        numberOfProducts={changeDcActionProps.numberOfProducts}
      />
    </div>
  );
};

export default SalesOrderShipment;
