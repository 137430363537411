import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validateRequired } from '../../Common/fields/Utils';
import { FormSelect, FormSelectProps } from 'components/alloy/formFields/FormSelect/FormSelect';

interface FinalFormSelectProps extends Omit<FormSelectProps, 'multiple'> {
  name: string;
  validate?: FieldValidator<any>;
}

export const FinalFormSelect = ({ name, validate, required, ...props }: FinalFormSelectProps) => {
  return (
    <Field
      name={name}
      multiple={false}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
    >
      {({ input, meta }) => {
        return (
          <FormSelect<false>
            {...input}
            {...props}
            required={required}
            error={meta.error && (meta.modified || meta.touched) ? meta.error : undefined}
            multiple={false}
          />
        );
      }}
    </Field>
  );
};
