import { InputProps, TextAreaProps } from 'components/ui/AlloyInput/AlloyInput';
import React from 'react';
import { Field } from 'react-final-form';
import { FormLabel } from '../FormLabel/FormLabel';
import { FieldInfo } from 'components/ui/formFields/FieldInfo/FieldInfo';
import { FieldValidator } from 'final-form';
import { composeValidators, validateRequired } from 'components/Common/fields/Utils';
import { SelectProps } from 'components/ui/AlloySelect/AlloySelect';
import { CheckboxProps } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import s from './AlloyFormField.module.scss';
import clsx from 'clsx';

export interface FormFieldProps<FieldType> {
  name: string;
  fieldProps: FieldType;
  component: React.ComponentType<FieldType>;
  validate?: FieldValidator<string | number | bigint | readonly string[]>;
  hideInfo?: boolean;
  parse?: (value: string) => string;
  countRule?: string;
  required?: boolean;
  inline?: 'before' | 'after';
}

export const AlloyFormField = <
  FieldType extends InputProps | SelectProps | TextAreaProps | CheckboxProps
>({
  component: Component,
  validate,
  hideInfo,
  countRule,
  fieldProps,
  required,
  inline,
  ...rest
}: FormFieldProps<FieldType>) => {
  return (
    <Field
      {...rest}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
    >
      {({ input, meta }) => {
        const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
        return (
          <div className={clsx(s.form_field, { [s[`inline_${inline}`]]: !!inline })}>
            <div>
              {fieldProps.title && (
                <FormLabel
                  title={fieldProps.title}
                  disabled={fieldProps.disabled}
                  required={required}
                  error={error}
                />
              )}
              <Component
                {...fieldProps}
                {...(input as any)}
                checked={!!input.value}
                status={error ? 'error' : undefined}
              />
            </div>
            {!hideInfo && (
              <FieldInfo
                error={error}
                info={countRule ? `${input.value.length}/${countRule}` : undefined}
                disabled={fieldProps.disabled}
              />
            )}
          </div>
        );
      }}
    </Field>
  );
};
