import React, { useMemo } from 'react';
import s from './TotalsGraphs.module.scss';

import { useQuery } from '@apollo/client';
import {
  InventoryRepackWeeklyReportDocument,
  InventoryRepackWeeklyReportQuery
} from 'pages/RepackPlanning/gql/__generated__/inventoryRepackWeeklyReport.query';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import currency from 'currency.js';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { EMPTY } from 'common/constants';
import { yearPeriodWeekSorter } from 'common/helpers/periodHelper';
import { InventoryRepackFiltersWeekInFilter } from 'pages/RepackPlanning/types';

const formatCurrency = (value: any) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  const money = currency(value, { symbol: '$', precision: 2, separator: ',', decimal: '.' });
  return `${money.format()} USD`;
};

const formatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  return value.toFixed(2) + '%';
};

const graphMapper = (
  data: InventoryRepackWeeklyReportQuery | undefined,
  filters: InventoryRepackFiltersWeekInFilter | undefined
) => {
  const metrics = data?.inventoryRepackWeeklyReport?.metrics || [];

  // TODO: in 2025 – add multiple lines to the graph? :)
  const shouldShowYearInLabel = (filters?.fiscalCalendarWeeks || []).some(
    (x) => x.year !== filters?.fiscalCalendarWeeks?.[0]?.year
  );

  // Since backend sends us data for only "found" items, we add empty data points for selected period
  const metricsWithEmptySpaces = (filters?.fiscalCalendarWeeks || []).map((filterPeriod) => {
    const found = metrics.find(
      (m) =>
        filterPeriod.period === m.period &&
        filterPeriod.week === m.week &&
        filterPeriod.year === m.year
    );

    const parsedValues = found
      ? {
          projectedFillRate: parseFloat(found.projectedFillRate),
          manualFillRate: parseFloat(found.manualFillRate),
          projectedTransportationCostsPerCase: parseFloat(
            found.projectedTransportationCostsPerCase
          ),
          manualTransportationCostsPerCase: parseFloat(found.manualTransportationCostsPerCase)
        }
      : undefined;
    return {
      label: `${shouldShowYearInLabel ? filterPeriod.year : ''} P${filterPeriod.period}W${
        filterPeriod.week
      }`.trim(),
      ...filterPeriod,
      ...parsedValues
    };
  });

  // TODO: remove as soon as BE is ready
  const sortedMetrics = [...metricsWithEmptySpaces].sort(yearPeriodWeekSorter);

  return { metrics: sortedMetrics };
};

type GraphPoint = ReturnType<typeof graphMapper>['metrics'][number];

export const TotalsGraphs = ({
  filters,
  isContinious
}: {
  filters: InventoryRepackFiltersWeekInFilter;
  isContinious: boolean;
}) => {
  const { data, loading } = useQuery(InventoryRepackWeeklyReportDocument, {
    variables: {
      filters: {
        ...filters,
        // We hardcode "main" stage on purpose: graph should always reflect 1st stage
        stage: 'MAIN'
      }
    }
  });

  const { metrics } = useMemo(() => graphMapper(data, filters), [data, filters]);

  const SYNC_ID = 'total-graphs';

  return (
    <div className={s.wrapper}>
      <AlloySpin spinning={loading}>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart
            data={metrics}
            margin={{ top: 0, right: 30, bottom: 0, left: 0 }}
            syncId={SYNC_ID}
          >
            <XAxis
              axisLine={{ stroke: '#B1B1B1' }}
              dataKey="label"
              tickFormatter={() => ''}
              interval={0}
              tickLine={false}
            />
            <YAxis
              axisLine={{ stroke: '#B1B1B1' }}
              padding={{ top: 30 }}
              domain={[80, 100]}
              width={90}
              tick={{ fill: 'black' }}
              tickSize={0}
              tickMargin={5}
              tickFormatter={(value) => (value ? `${value}%` : '')}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: 'black', strokeDasharray: '3 3' }}
            />
            <Legend wrapperStyle={{ top: 5, right: 30, position: 'absolute', width: 'auto' }} />
            <Line
              strokeWidth={2}
              name="Projected FR"
              type="linear"
              dataKey="projectedFillRate"
              stroke={isContinious ? '#0A59F1' : 'none'}
              dot={{ stroke: '#0A59F1', strokeWidth: isContinious ? 2 : 16 }}
              legendType={'square'}
              connectNulls={false}
              isAnimationActive={false}
            />
            <text x={110} y={20} fontSize={14} textAnchor="left" className={s.graph_title}>
              Projected Fill Rate
            </text>
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={220} className={s.second_graph_wrapper}>
          <LineChart
            data={metrics}
            margin={{ top: 0, right: 30, bottom: 40, left: 0 }}
            syncId={SYNC_ID}
          >
            <XAxis
              axisLine={{ stroke: '#B1B1B1' }}
              dataKey="label"
              tick={{ textAnchor: 'end', fill: 'black' }}
              dx={-5}
              tickLine={{ transform: 'translate(0, -3)', stroke: '#B1B1B1' }}
              angle={-90}
              tickMargin={5}
              interval={0}
            />
            <YAxis
              axisLine={{ stroke: '#B1B1B1' }}
              padding={{ top: 30 }}
              tickSize={0}
              tickMargin={5}
              width={90}
              tick={{ fill: 'black' }}
              tickFormatter={(value) => (value ? formatCurrency(value) : '')}
            />
            <Tooltip
              content={<CustomTooltip type="case" />}
              cursor={{ stroke: 'black', strokeDasharray: '3 3' }}
            />
            <Legend wrapperStyle={{ top: 5, right: 30, position: 'absolute', width: 'auto' }} />
            <Line
              strokeWidth={2}
              name="Projected TC/Case"
              type="linear"
              dataKey="projectedTransportationCostsPerCase"
              legendType={'square'}
              stroke={isContinious ? '#24A129' : 'none'}
              dot={{ stroke: '#24A129', strokeWidth: isContinious ? 2 : 16 }}
              connectNulls={false}
              isAnimationActive={false}
            />
            <text x={110} y={20} fontSize={14} textAnchor="left" className={s.graph_title}>
              Projected Transportation Cost / Case
            </text>
          </LineChart>
        </ResponsiveContainer>
      </AlloySpin>
    </div>
  );
};

// TODO: instead of awkward "case", do more reusable component?
const CustomTooltip: React.FC<TooltipProps<number, string> & { type?: 'case' }> = ({
  active,
  payload,
  label,
  type
}) => {
  if (active && payload && payload.length) {
    // Unfortunately, there's no proper type for a payload.
    const value = payload[0].payload as GraphPoint;
    return (
      <div className={s.tooltip}>
        <div>
          Week of: <span className={s.value}>{label}</span>
        </div>
        {type === 'case' ? (
          <>
            <div>
              Projected TC/Case:{' '}
              <span className={s.value}>
                {value.projectedTransportationCostsPerCase
                  ? formatCurrency(value.projectedTransportationCostsPerCase)
                  : EMPTY}
              </span>
            </div>
            <div>
              Manual TC/Case:{' '}
              <span className={s.value}>
                {value.manualTransportationCostsPerCase
                  ? formatCurrency(value.manualTransportationCostsPerCase)
                  : EMPTY}
              </span>
            </div>
          </>
        ) : (
          <>
            <div>
              Projected FR:{' '}
              <span className={s.value}>{formatPercentage(value.projectedFillRate)}</span>
            </div>
            <div>
              Manual FR: <span className={s.value}>{formatPercentage(value.manualFillRate)}</span>
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
};
