import React from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import s from './Checkbox.module.scss';
import clsx from 'clsx';

import '@fontsource/roboto';
import { BaseProperties } from '../Utils';

export interface CheckboxProps extends BaseProperties {
  onChange?: (checked: boolean) => void;
  label?: string;
  checked?: boolean | 'indeterminate';
}

const checkedIcon = (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8713 2.12132L7.06066 12.932L0 5.87132L2.12132 3.75L7.06066 8.68934L15.75 0L17.8713 2.12132Z"
      fill="white"
    />
  </svg>
);

const checkedIconSmall = (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9142 1.41421L4.70711 8.62132L0 3.91421L1.41421 2.5L4.70711 5.79289L10.5 0L11.9142 1.41421Z"
      fill="white"
    />
  </svg>
);

export const Checkbox = ({
  onChange,
  className,
  disabled,
  size = 'regular',
  label,
  'data-testid': dataTestid,
  checked,
  style
}: CheckboxProps) => {
  return (
    <div
      data-testid={dataTestid}
      data-size={size}
      className={clsx(className, s.checkbox)}
      style={style}
    >
      <RadixCheckbox.Root
        checked={checked}
        disabled={disabled}
        className={s.root}
        onCheckedChange={onChange}
        aria-label={label}
      >
        <RadixCheckbox.Indicator className={s.indicator}>
          {checked === 'indeterminate' && <span className={s.indeterminate} />}
          {checked === true && (size === 'regular' ? checkedIcon : checkedIconSmall)}
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
    </div>
  );
};
