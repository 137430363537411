import React, { useMemo, useRef } from 'react';
import s from './ExecutiveSummaryGraph.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { EMPTY } from 'common/constants';
import { useQuery } from '@apollo/client';
import { OtifReportsByPeriodDocument } from 'pages/OnTimeInFull/gql/__generated__/otifReportsByPeriod.query';
import { mapDataWithPeriods } from 'pages/OnTimeInFull/helpers';
import { useElementSize } from '@reactuses/core';
import { OtifFiltersOnlyWeekInFilter } from 'pages/OnTimeInFull/types';

const formatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  return value.toFixed(2) + '%';
};

export const ExecutiveSummaryGraph = ({
  filters,
  isContinious
}: {
  filters: OtifFiltersOnlyWeekInFilter;
  isContinious: boolean;
}) => {
  const { loading, data } = useQuery(OtifReportsByPeriodDocument, { variables: { filters } });
  const { metrics, areMultipleYearsSelected } = useMemo(
    () => mapDataWithPeriods(data?.otifReportsByPeriod, filters.fiscalCalendarWeeks),
    [data?.otifReportsByPeriod, filters.fiscalCalendarWeeks]
  );

  const ref = useRef<HTMLDivElement>(null);
  const [graphWidth] = useElementSize(ref);

  // If skipLabels = 1, we don't skip anything, if skipLabels = 2, we skip each second, and so on.
  const skipLabels = useMemo(() => {
    // 20 is a needed width for each label
    return Math.ceil((metrics.length * 20) / graphWidth);
  }, [graphWidth, metrics.length]);

  // Some "Clever" tricks to calculate width of data points
  const dotWidth = useMemo(() => {
    const availableWidth = graphWidth / metrics.length;
    let result;
    if (availableWidth > 24) {
      result = 16;
    } else {
      const value = availableWidth / 3;
      const roundedUpValue = Math.ceil(value);
      // Round up to the next even number if it's odd
      result = roundedUpValue % 2 === 0 ? roundedUpValue : roundedUpValue + 1;
    }

    return Math.max(result, 2);
    // Ensure the value is never
  }, [graphWidth, metrics.length]);

  return (
    <div className={s.wrapper} ref={ref}>
      <AlloySpin spinning={loading}>
        <ResponsiveContainer width="100%" height={areMultipleYearsSelected ? 240 : 200}>
          <LineChart
            data={metrics}
            margin={{ top: 0, right: 10, bottom: areMultipleYearsSelected ? 60 : 20, left: 0 }}
          >
            <XAxis
              axisLine={{ stroke: '#B1B1B1' }}
              dataKey="label"
              tick={{ textAnchor: 'end', fill: 'black' }}
              dx={-5}
              tickLine={{ transform: 'translate(0, -3)', stroke: '#B1B1B1' }}
              angle={-90}
              tickMargin={5}
              interval={0}
              tickFormatter={(value, index) => (value && index % skipLabels === 0 ? value : '')}
            />
            <YAxis
              axisLine={{ stroke: '#B1B1B1' }}
              domain={[0, 100]}
              width={40}
              tick={{ fill: 'black' }}
              tickSize={0}
              tickMargin={5}
              tickFormatter={(value) => (value ? `${value}%` : '')}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: 'black', strokeDasharray: '3 3' }}
            />
            <Line
              strokeWidth={2}
              name="OTIF"
              type="linear"
              dataKey="otif"
              stroke={isContinious ? '#0597F2' : 'none'}
              dot={isContinious ? false : { stroke: '#0597F2', strokeWidth: dotWidth }}
              legendType={'square'}
              connectNulls={false}
              isAnimationActive={false}
            />
            <Line
              strokeWidth={2}
              name="OT"
              type="linear"
              dataKey="onTime"
              stroke={isContinious ? '#FDB515' : 'none'}
              dot={isContinious ? false : { stroke: '#FDB515', strokeWidth: dotWidth }}
              legendType={'square'}
              connectNulls={false}
              isAnimationActive={false}
            />
            <Line
              strokeWidth={2}
              name="IF"
              type="linear"
              dataKey="inFull"
              stroke={isContinious ? '#CF1322' : 'none'}
              dot={isContinious ? false : { stroke: '#CF1322', strokeWidth: dotWidth }}
              legendType={'square'}
              connectNulls={false}
              isAnimationActive={false}
            />
            <Legend verticalAlign="top" align="right" />
          </LineChart>
        </ResponsiveContainer>
      </AlloySpin>
    </div>
  );
};

// TODO: instead of awkward "case", do more reusable component?
const CustomTooltip: React.FC<TooltipProps<number, string> & { type?: 'case' }> = ({
  active,
  payload,
  label,
  type
}) => {
  if (active && payload && payload.length) {
    const value = payload[0].payload;
    return (
      <div className={s.tooltip}>
        <div>
          Week of: <span className={s.value}>{label}</span>
        </div>
        <div>
          OTIF:{' '}
          <span className={s.value}>
            {value.otif || value.otif === 0 ? formatPercentage(value.otif) : EMPTY}
          </span>
        </div>
        <div>
          OT:{' '}
          <span className={s.value}>
            {value.onTime || value.onTime === 0 ? formatPercentage(value.onTime) : EMPTY}
          </span>
        </div>
        <div>
          IF:{' '}
          <span className={s.value}>
            {value.inFull || value.inFull === 0 ? formatPercentage(value.inFull) : EMPTY}
          </span>{' '}
        </div>
      </div>
    );
  }

  return null;
};
