import { useLazyQuery } from '@apollo/client';
import { Button } from 'ant5';
import React, { useRef } from 'react';
import { GetUsersListDocument } from '../gql/__generated__/listUsers.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { CSVLink } from 'react-csv';

const headers = [
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'roles', label: 'Roles' },
  { key: 'tradingPartners', label: 'Trading Partners' },
  { key: 'distributionCenters', label: 'Distribution Centers' },
  { key: 'status', label: 'Status' }
];

export const ExportAllUsers = () => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [getAllUsers, { data, loading }] = useLazyQuery(GetUsersListDocument, {});

  return (
    <div>
      <Button
        loading={loading}
        onClick={async () => {
          await getAllUsers({
            variables: {
              first: 1000000,
              sort: {
                column: 'NAME',
                direction: 'ASC'
              }
            }
          });
          csvLinkRef?.current?.link.click();
        }}
      >
        Export All
      </Button>
      <CSVLink
        filename="all-users-list.csv"
        headers={headers}
        ref={csvLinkRef}
        data={getNodesFromEdges(data?.listUsers).map((user) => ({
          name: user.name,
          email: user.email,
          tradingPartners: user.scopedAuthorizations
            .reduce((result, auth) => {
              if (auth?.tradingPartner?.externalId) {
                result.push(auth.tradingPartner.externalId);
              }
              return result;
            }, [] as string[])
            .join(', '),
          distributionCenters: user.scopedAuthorizations
            .filter((auth) => !!auth?.distributionCenter)
            .map((auth) => `${auth?.distributionCenter?.name} ${auth?.distributionCenter?.code}`)
            .join(', '),
          roles: user.roles.join(', '),
          status: user.deletedAt ? 'Inactive' : 'Active'
        }))}
      />
    </div>
  );
};
