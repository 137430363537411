export const download = (url: string, filename: string) => {
  fetch(url).then((t) => {
    return t.blob().then((b) => {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(b);
      a.setAttribute('download', filename);
      a.click();
    });
  });
};
