import React from 'react';
import { Radio } from 'antd';
import './index.scss';

export const RoundedRadioButton = <Key extends string = string>({
  buttons,
  onChange,
  value
}: {
  buttons: {
    key: Key;
    title: string;
    disabled?: boolean;
  }[];
  onChange: (key: Key) => void;
  value?: Key;
}) => {
  return (
    <Radio.Group
      value={value}
      buttonStyle="solid"
      className="rounded_radio_button_group"
      data-testid="rounded-radio-button-group"
      size="small"
    >
      {buttons.map((button) => (
        <Radio.Button
          data-testid="rounded-radio-button"
          value={button.key}
          key={button.key}
          onClick={(e) => {
            onChange(button.key);
          }}
          disabled={button.disabled}
        >
          <span data-testid="rounded-radio-button-title">{button.title}</span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
