import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CutOrdersPercentagesQueryVariables = Types.Exact<{
  filters: Types.CutOrdersFilters;
}>;


export type CutOrdersPercentagesQuery = { __typename?: 'RootQueryType', cutOrdersPercentages: Array<{ __typename?: 'CutOrdersPercentages', customerCutsPercent: any, planningCutsPercent: any, totalCutsPercent: any, transportCutsPercent: any, uncategorizedCutsPercent: any, warehouseCutsPercent: any }> };


export const CutOrdersPercentagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"cutOrdersPercentages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CutOrdersFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cutOrdersPercentages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customerCutsPercent"}},{"kind":"Field","name":{"kind":"Name","value":"planningCutsPercent"}},{"kind":"Field","name":{"kind":"Name","value":"totalCutsPercent"}},{"kind":"Field","name":{"kind":"Name","value":"transportCutsPercent"}},{"kind":"Field","name":{"kind":"Name","value":"uncategorizedCutsPercent"}},{"kind":"Field","name":{"kind":"Name","value":"warehouseCutsPercent"}}]}}]}}]} as unknown as DocumentNode<CutOrdersPercentagesQuery, CutOrdersPercentagesQueryVariables>;