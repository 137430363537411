import React from 'react';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import s from './RetailerViewDrawer.module.scss';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import { useQuery } from '@apollo/client';
import { VendorDocument, VendorQuery } from 'pages/RetailersPage/gql/__generated__/vendor.query';

interface RetailerDrawerProps {
  vendorId?: string;
  onClose: () => void;
  startEditing: (vendor: VendorFullData) => void;
}

export type VendorFullData = NonNullable<VendorQuery['vendor']>;

export const RetailerViewDrawer = ({ onClose, vendorId, startEditing }: RetailerDrawerProps) => {
  const { data, loading } = useQuery(VendorDocument, {
    variables: {
      id: vendorId || ''
    },
    skip: !vendorId
  });
  return (
    <EntityDrawer
      title={data?.vendor?.name || data?.vendor?.code || ''}
      open={!!vendorId}
      onClose={onClose}
      viewMode={
        <div>
          <ViewRow
            title="General setting"
            bordered={false}
            content={
              <>
                <ViewField title="Code" type="INPUT" value={data?.vendor?.code} />
                <ViewField title="Name" type="INPUT" value={data?.vendor?.name} />
                <ViewField title="Description" type="INPUT" value={data?.vendor?.description} />
              </>
            }
          />
        </div>
      }
      loading={!data?.vendor || loading}
      startEditing={() => data?.vendor && startEditing(data?.vendor)}
    />
  );
};
