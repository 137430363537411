import { useCombobox } from 'downshift';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import s from '../Select.module.scss';
import { Label } from '../../Label/Label';
import dropdownArrowIcon from 'assets/icons/common/dropdown_arrow.svg';
import dropdownArrowDisabledIcon from 'assets/icons/common/dropdown_arrow_disabled.svg';
import removeIcon from 'assets/icons/common/remove.svg';
import { usePopper } from 'react-popper';
import { SelectProps } from '../Select';
import { DropdownList } from 'components/alloy/DropdownList/DropdownList';
import { useElementBounding } from '@reactuses/core';

export const SingleSelect = ({
  allowClear,
  className,
  'data-testid': dataTestid,
  disabled,
  dropdownRender,
  error,
  label,
  options,
  placeholder,
  prefixIcon,
  required,
  size,
  value,
  defaultValue,
  onBlur,
  onChange,
  loading = false,
  style
}: Omit<SelectProps<false>, 'multiple'>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null | undefined>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'auto-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-start', 'top-start'],
          rootBoundary: 'viewport',
          flipVariations: true,
          allowedAutoPlacements: ['bottom-start', 'top-start']
        }
      }
    ]
  });

  const { left, top } = useElementBounding(referenceElement);

  useEffect(() => {
    update && update();
  }, [left, top]);

  const [inputValue, setInputValue] = useState<string>('');
  const [focused, setFocused] = useState(false);
  const selectedOption = useMemo(
    () => options.find((opt) => opt.value === value),
    [value, options]
  );

  const items = useMemo(() => {
    const lowerCasedInputValue = inputValue?.toLowerCase() || '';
    return options.filter((option) => option.label.toLowerCase().includes(lowerCasedInputValue));
  }, [inputValue, options]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.label : '';
    },
    selectedItem: options.find((opt) => opt.value === value),
    defaultSelectedItem: defaultValue
      ? options.find((opt) => opt.value === defaultValue)
      : undefined,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.InputBlur:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            inputValue: ''
          };
        default:
          return changes;
      }
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      onChange && onChange(newSelectedItem?.value || undefined);
    },
    onInputValueChange(changes) {
      const { inputValue } = changes;
      setInputValue(inputValue || '');
    },
    onIsOpenChange(changes) {
      const { isOpen } = changes;
      isOpen && update && update();
    }
  });
  return (
    <div data-testid={dataTestid} className={clsx(s.select, className)} style={style}>
      {!!label && (
        <Label
          label={label}
          required={required}
          focused={focused}
          error={error}
          {...getLabelProps({
            disabled
          })}
        />
      )}
      <div
        ref={setReferenceElement}
        className={clsx(s.control, {
          [s.has_icon_left]: !!prefixIcon
        })}
        data-size={size}
        aria-disabled={disabled}
        data-focused={focused}
        data-open={isOpen}
        data-haserror={!!error}
        data-notempty={!!selectedOption}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {prefixIcon && (
          <span className={s.prefix_icon} data-size={size}>
            <i>{prefixIcon}</i>
          </span>
        )}
        <input
          className={s.select_input}
          data-hasvalue={!!selectedOption}
          size={selectedOption?.label.length}
          {...getInputProps({
            ref: inputRef,
            disabled,
            onClick: () => {
              if (!isOpen) openMenu();
            },
            onFocus: () => {
              setFocused(true);
            },
            onBlur: (event) => {
              setFocused(false);
              onBlur && onBlur(event);
              getInputProps().onBlur(event);
            },
            placeholder: selectedOption?.label || placeholder
          })}
        />
        {allowClear && (
          <button
            className={s.remove_button}
            onClick={(event) => {
              event.stopPropagation();
              onChange && onChange(undefined);
              setInputValue('');
            }}
            type="button"
            disabled={disabled}
          >
            <img src={removeIcon} />
          </button>
        )}
        <button
          aria-label="toggle menu"
          className={s.toggle_button}
          type="button"
          style={styles.arrow}
          {...getToggleButtonProps({
            disabled: disabled || loading
          })}
        >
          <img
            src={disabled ? dropdownArrowDisabledIcon : dropdownArrowIcon}
            className={clsx({ [s.rotated]: isOpen })}
          />
        </button>
        <DropdownList
          data-testid={`${dataTestid}-dropdown`}
          dropdownRender={dropdownRender}
          menuProps={{
            ...getMenuProps({
              ref: setPopperElement
            }),
            ...attributes.popper,
            style: { zIndex: 1001, minWidth: referenceElement?.clientWidth, ...styles.popper }
          }}
          items={items.map((item, index) => ({
            ...item,
            props: getItemProps({ item, index, disabled: item.disabled })
          }))}
          isOpen={isOpen}
          selectedItem={value}
          highlightedIndex={highlightedIndex}
        />
      </div>
    </div>
  );
};
