import React from 'react';

import { MetricIndicator } from 'components/MetricIndicator/MetricIndicator';
import shipmentIcon from 'assets/icons/stats/shipment.svg';
import timerIcon from 'assets/icons/stats/timer.svg';
import truckRemoveIcon from 'assets/icons/stats/truck-remove.svg';
import truckTickIcon from 'assets/icons/stats/truck-tick.svg';
import truckIcon from 'assets/icons/stats/truck.svg';
import quakerIcon from 'assets/icons/stats/test_quaker.png';
import s from './ExecutiveMetrics.module.scss';

const fakeExecutiveStats = [
  {
    title: 'NAN (OTS)',
    value: 96.01,
    prevValue: 94.85,
    isPercentage: true,
    treshold: 98,
    iconSrc: quakerIcon,
    isLogo: true
  },
  {
    title: 'TOTAL ORDERS',
    value: 12021,
    prevValue: 12553,
    iconSrc: shipmentIcon
  },
  {
    title: 'ON TIME',
    value: 9890,
    prevValue: 9451,
    iconSrc: truckTickIcon
  },
  {
    title: 'SHIPPED LATE',
    value: 1002,
    prevValue: 470,
    iconSrc: truckRemoveIcon,
    lessIsBetter: true
  },
  {
    title: 'PENDING TO SHIP',
    value: 187,
    prevValue: 182,
    iconSrc: truckIcon,
    lessIsBetter: true
  },
  {
    title: 'CANCELLED',
    value: 134,
    prevValue: 144,
    iconSrc: timerIcon,
    lessIsBetter: true
  }
];

export const ExecutiveMetrics = () => {
  return (
    <div className={s.wrapper}>
      {fakeExecutiveStats.map((item) => (
        <MetricIndicator
          key={item.title}
          value={item.value}
          title={item.title}
          icon={{
            src: item.iconSrc,
            isLogo: item.isLogo
          }}
          isPercentage={item.isPercentage}
          previous={{
            value: item.prevValue,
            tooltip: 'P1W1 - P2W2',
            text: 'than last week',
            lessIsBetter: item.lessIsBetter
          }}
          treshold={item.treshold}
        />
      ))}
    </div>
  );
};
