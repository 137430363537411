import * as Types from '../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateItemsAcceptedUnitsMutationVariables = Types.Exact<{
  input: Types.UpdateItemsAcceptedUnitsInput;
}>;


export type UpdateItemsAcceptedUnitsMutation = { __typename?: 'RootMutationType', updateItemsAcceptedUnits?: { __typename?: 'UpdateItemsAcceptedUnitsPayload', salesOrderItems: Array<{ __typename?: 'SalesOrderItem', id: string }> } | null };


export const UpdateItemsAcceptedUnitsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateItemsAcceptedUnits"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateItemsAcceptedUnitsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateItemsAcceptedUnits"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrderItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateItemsAcceptedUnitsMutation, UpdateItemsAcceptedUnitsMutationVariables>;