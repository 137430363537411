import React, { useMemo, useState } from 'react';
import s from './OnTimeInFull.module.scss';
import { ArrayParam, JsonParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import clsx from 'clsx';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import {
  StatChangeIndicator,
  StatChangeIndicatorProps
} from 'components/ui/StatChangeIndicator/StatChangeIndicator';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { EMPTY, NOT_AVAILABLE } from 'common/constants';
import { useDeepCompareMemo } from 'use-deep-compare';
import { isSundayToday, getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { transformCalendarValueToPeriodWeekYear } from 'components/PeriodCalendar/helpers';
import { range } from 'lodash-es';
import { isCalendarValueContinuous } from 'common/helpers/graph';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { ExecutiveSummaryGraph } from './components/ExecutiveSummaryGraph/ExecutiveSummaryGraph';
import { ExecutiveSummaryTable } from './components/ExecutiveSummaryTable/ExecutiveSummaryTable';
import { useQuery } from '@apollo/client';
import { OtifTotalsReportDocument } from './gql/__generated__/otifTotalsReport.query';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import { IN_FULL } from './featureFlags';
import { AvailableOtifFiltersDocument } from './gql/__generated__/availableOtifFilters.query';
import { safeLocaleCompare } from 'common/helpers/comparators';
import LoaderSpinner from 'components/LoaderSpinner';
import { CutsTable } from './components/CutsTable/CutsTable';
import ErrorState from '@/src/components/ErrorState/ErrorState';
import { CutsTrends } from './components/CutsTrends/CutsTrends';
import { OldCutsByReasonGraph } from './components/CutsByReasonGraph/OldCutsByReasonGraph/OldCutsByReasonGraph';
import { OldCutsGraph } from './components/CutsGraph/OldCutsGraph/OldCutsGraph';
import { OTIF_NEW_GRAPHS } from 'common/featureFlags';
import { CutsGraph } from './components/CutsGraph/CutsGraph';
import { CutsByReasonGraph } from './components/CutsByReasonGraph/CutsByReasonGraph';
import { convertToMM } from 'common/helpers/convertToMM';

const CURRENT_YEAR = new Date().getFullYear();
const STARTING_YEAR = 2022;

const getDefaultPeriod = () => {
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // If past-mode only, we need to set previous day's period, since otherwise it will be empty.
  // This is a hacky way for past-due, we might rewrite it in future
  const referenceDate = isSundayToday() ? yesterday : today;

  const { week } = getFinancialInfo(referenceDate);
  const end = week;
  const start = Math.max(1, week - 3);
  return { start, end };
};

export type DisplayMode = 'summary' | 'on-time' | 'in-full';
const DEFAULT_TAB: DisplayMode = 'summary';

type OTIFTab = { value: DisplayMode; label: string; disabled?: boolean };
const EXECUTIVE_SUMMARY_TAB: OTIFTab = { value: 'summary', label: 'Executive Summary' };
const ON_TIME_TAB: OTIFTab = { value: 'on-time', label: 'On-Time', disabled: true };

export const OnTimeInFull = () => {
  const { enabled: inFullEnabled } = useFeatureFlag({ name: IN_FULL });
  const { enabled: newGraphsEnabledFF } = useFeatureFlag({ name: OTIF_NEW_GRAPHS });

  // While Anastasia haven't merged her PR, let's play it safe
  const [newGraphsEnabledQP] = useQueryParam(OTIF_NEW_GRAPHS);
  const newGraphsEnabled = newGraphsEnabledQP || newGraphsEnabledFF;

  const [exportModalVisible, setExportModalVisible] = useState(false);

  const IN_FULL_TAB: OTIFTab = useMemo(
    () => ({ value: 'in-full', label: 'In Full', disabled: !inFullEnabled }),
    [inFullEnabled]
  );
  const tabs = useMemo(() => [EXECUTIVE_SUMMARY_TAB, ON_TIME_TAB, IN_FULL_TAB], [IN_FULL_TAB]);
  const filtersQuery = useQuery(AvailableOtifFiltersDocument);

  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));
  // Well, it's not an actual type transformation, it's for convinience only.
  const modeWithType = mode as DisplayMode;
  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));
  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [CURRENT_YEAR],
      ...getDefaultPeriod()
    })
  );
  const isContinious = useMemo(() => isCalendarValueContinuous(period), [period]);

  const [customers, setCustomers] = useQueryParam('customers', withDefault(ArrayParam, ['all']));

  const customersOptions = useMemo(
    () =>
      [...(filtersQuery.data?.availableOtifFilters?.vendorMarkets || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filtersQuery]
  );
  const businessUnitsOptions = useMemo(
    () =>
      [...(filtersQuery.data?.availableOtifFilters?.businessUnits || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filtersQuery]
  );

  const businessUnitIds = useMemo(
    () =>
      getValueWithAll(
        bus as string[],
        businessUnitsOptions.map((x) => x.id)
      ),
    [bus, businessUnitsOptions]
  );

  const vendorMarketIds = useMemo(
    () =>
      getValueWithAll(
        customers as string[],
        customersOptions.map((x) => x.id)
      ),
    [customers, customersOptions]
  );

  // TODO: enable filters LATER, when filtering starts fworking on BE.
  const filters = useDeepCompareMemo(
    () => ({
      fiscalCalendarWeeks: transformCalendarValueToPeriodWeekYear(period),
      businessUnitIds,
      customers: vendorMarketIds
    }),
    [businessUnitIds, period, vendorMarketIds]
  );

  const totalsData = useQuery(OtifTotalsReportDocument, {
    variables: {
      filters
    }
  });

  if (filtersQuery.loading) return <LoaderSpinner />;
  if (customersOptions.length === 0 || businessUnitsOptions.length === 0)
    return (
      <ErrorState
        type="unauthorized"
        title="401 - Unauthorized Access"
        explanation="Access to the requested resource is denied because valid authentication credentials were not provided."
        instruction={
          'To get or confirm your access:\n' +
          '1. Send a slack message to #schip-all-pods on slack\n' +
          '2. Include which Trading Partner(s) you need access to (eg. PEPSV, FRJJX, HEAXT)'
        }
      />
    );

  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}>OTIF</PageHeader>
          <span className={s.last_update_date}>All sources up to date</span>
        </div>
        <div className={s.page_filters}>
          <AlloySelect
            loading={filtersQuery.loading}
            className={clsx(s.select, s.select_repack_centers, bus.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={customers}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], customers as string[], setCustomers);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'All Customers', value: 'all' },
              ...customersOptions.map((customer) => ({
                label: customer.name,
                value: customer.id
              }))
            ]}
          />
          <AlloySelect
            loading={filtersQuery.loading}
            className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={bus}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], bus as string[], setBus);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL BUs', value: 'all' },
              ...businessUnitsOptions.map((bu) => ({
                label: bu.name,
                value: bu.id
              }))
            ]}
          />
          <PeriodCalendar
            value={period}
            onChange={setPeriod}
            years={
              STARTING_YEAR === CURRENT_YEAR ? undefined : range(STARTING_YEAR, CURRENT_YEAR + 1)
            }
            // If we have more years, we want to allow to select prev periods, else nope
            pastOnly={STARTING_YEAR === CURRENT_YEAR}
          />
          <AlloyButton type="default" size="large" onClick={() => setExportModalVisible(true)}>
            Export
          </AlloyButton>
        </div>
      </div>
      <div className={s.stats}>
        <StatsRow
          title="Count of orders"
          stats={[
            {
              title: 'OTIF',
              value: (
                <NumberAndText
                  number={formatPercentage(totalsData.data?.otifTotalsReport?.otif)}
                  text="%"
                />
              ),
              tooltip: 'On Time In Full',
              loading: totalsData.loading
            },
            {
              title: 'In-Full',
              value: (
                <NumberAndText
                  number={formatPercentage(totalsData.data?.otifTotalsReport?.inFull)}
                  text="%"
                />
              ),
              loading: totalsData.loading
            },
            {
              title: 'On-Time',
              value: (
                <NumberAndText
                  number={formatPercentage(totalsData.data?.otifTotalsReport?.onTime)}
                  text="%"
                />
              ),
              loading: totalsData.loading
            },
            {
              title: 'RSV',
              value: (
                <NumberAndText
                  number={convertToMM(totalsData.data?.otifTotalsReport?.rsvValue)}
                  tooltip={formatMoney(totalsData.data?.otifTotalsReport?.rsvValue)}
                />
              ),
              loading: totalsData.loading,
              tooltip: 'Retail Sales Value'
            }
          ]}
        />
        <StatsRow
          title="Count of units"
          stats={[
            {
              title: 'SFR',
              value: (
                <NumberAndText
                  number={formatPercentage(totalsData.data?.otifTotalsReport?.submittedFillRate)}
                  text="%"
                />
              ),
              tooltip: 'Submitted Fill Rate',
              loading: totalsData.loading
            },
            {
              title: 'AFR',
              value: (
                <NumberAndText
                  number={formatPercentage(totalsData.data?.otifTotalsReport?.acceptedFillRate)}
                  text="%"
                />
              ),
              tooltip: 'Accepted Fill Rate',
              loading: totalsData.loading
            },
            {
              title: 'Approx. Lost Opportunity',
              value: (
                <NumberAndText
                  number={convertToMM(
                    totalsData.data?.otifTotalsReport?.approximateLostOpportunity
                  )}
                  tooltip={formatMoney(
                    totalsData.data?.otifTotalsReport?.approximateLostOpportunity
                  )}
                />
              ),
              loading: totalsData.loading
            }
          ]}
        />
      </div>
      <div className={s.mode_selector}>
        <AlloySegmented options={tabs} value={mode} onChange={(value) => setMode(value)} />
      </div>
      <div className={s.section_wrapper}>
        {modeWithType === 'summary' && (
          <div>
            <ExecutiveSummaryGraph isContinious={isContinious} filters={filters} />
            <ExecutiveSummaryTable
              filters={filters}
              period={period}
              setExportModalVisible={setExportModalVisible}
              exportModalVisible={exportModalVisible}
            />
          </div>
        )}
        {modeWithType === 'on-time' && <div>TBD: On time</div>}
        {modeWithType === 'in-full' && (
          <div>
            <CutsTrends />
            {newGraphsEnabled ? (
              <div className={s.graphs}>
                <CutsGraph filters={filters} period={period} />
                <CutsByReasonGraph filters={filters} />
              </div>
            ) : (
              <div className={s.graphs}>
                <OldCutsGraph filters={filters} period={period} />
                <OldCutsByReasonGraph filters={filters} />
              </div>
            )}
            <CutsTable filters={filters} />
          </div>
        )}
      </div>
    </>
  );
};

interface StatsTile {
  title: string;
  value: number | string | React.ReactChild;
  loading?: boolean;
  tooltip?: string;
  change?: StatChangeIndicatorProps;
  changeText?: string;
}

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  // TODO: WHY it's 1 HERE??
  return `${(parsedValue * 100).toFixed(1)}`;
};

const formatMoney = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `$${parsedValue.toLocaleString('en-US')}`;
};

const StatsRow = ({ stats, title }: { stats: StatsTile[]; title: string }) => {
  return (
    <div className={s.row}>
      <h2 className={s.row_title}>{title}</h2>
      <div className={s.row_wrapper}>
        {stats.map((x) => (
          <div className={s.tile} key={x.title + x.value}>
            <div className={s.title}>
              {x.title}
              {x.tooltip && (
                <AlloyTooltip title={x.tooltip} placement="topRight">
                  <InfoCircleOutlined className={s.tooltip_icon} />
                </AlloyTooltip>
              )}
            </div>
            <div className={s.bottom}>
              {x.loading ? (
                <div className={s.loading} />
              ) : (
                <>
                  <div className={s.value}>{x.value}</div>
                </>
              )}
            </div>
            <div className={s.change}>
              {x.loading ? (
                <div className={s.loading_additional} />
              ) : (
                <>
                  {x.change && <StatChangeIndicator {...x.change} />}
                  {x.change && x.changeText && <div className={s.changeText}>{x.changeText}</div>}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const NumberAndText = ({
  text,
  number = '',
  tooltip
}: {
  text?: string;
  number: string | number;
  tooltip?: string;
}) => {
  return (
    <div className={s.number_and_text}>
      {tooltip ? (
        <AlloyTooltip title={tooltip} placement="bottom">
          <span className={s.number}>{number}</span>
        </AlloyTooltip>
      ) : (
        <span className={s.number}>{number}</span>
      )}
      {text && number !== EMPTY && <span className={s.text}>{text}</span>}
    </div>
  );
};
