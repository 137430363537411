import React, { Dispatch, SetStateAction, useState } from 'react';
import './UploadShortMonitorModal.scss';
import { useMutation } from '@apollo/client';
import { ScrubOrdersWithUploadedDataDocument } from './gql/__generated__/scrubOrdersWithUploadedData.mutation';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyUpload, UploadChangeParam } from 'components/ui/AlloyUpload/AlloyUpload';
import { App } from 'ant5';

const { Dragger } = AlloyUpload;

interface UploadShortMonitorModalProps {
  visible: boolean;
  toggleUploadShortMonitor: () => void;
  setShortMonitorErrorState: Dispatch<
    SetStateAction<{ visible: boolean; errors: string[]; fileName: string }>
  >;
}

const UploadShortMonitorModal = ({
  visible,
  toggleUploadShortMonitor,
  setShortMonitorErrorState
}: UploadShortMonitorModalProps) => {
  const { message } = App.useApp();
  const [monitorFileName, setMonitorFileName] = useState('');
  // const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [sendShortMonitorData] = useMutation(ScrubOrdersWithUploadedDataDocument, {
    refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts'],
    onCompleted: () => {
      message.success('Data was successfully uploaded.');
      toggleUploadShortMonitor();
    },
    onError: (error) => {
      console.error(error.graphQLErrors);
      setShortMonitorErrorState({
        visible: true,
        errors: error.graphQLErrors.map(({ message }) => message),
        fileName: monitorFileName
      });
      toggleUploadShortMonitor();
    }
  });
  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const props = {
    accept: '.xls,.xlsx,.csv',
    name: 'file',
    multiple: false,
    customRequest: dummyRequest,
    // onRemove: (file) => {
    //   const index = fileList.indexOf(file);
    //   const newFileList = fileList.slice();
    //   newFileList.splice(index, 1);
    //   setFileList(newFileList);
    // },
    onChange: ({ file }: UploadChangeParam) => {
      setMonitorFileName(file.name);
      if (file.originFileObj) {
        if (file.status === 'done') {
          sendShortMonitorData({
            variables: {
              input: {
                data: file.originFileObj
              }
            }
          });
        }
      }
    }
    // beforeUpload: (file: UploadFile) => {
    //   setFileList([...fileList, file]);
    //   return false;
    // },
    //This part should be used for multiple upload update
    // itemRender: ()=> {
    //
    //    return(
    //      <table>
    //        <tr>
    //          <th>File name</th>
    //          <th>Status</th>
    //          <th>Remove</th>
    //        </tr>
    //        {fileList.map((file: UploadFile) =>
    //          <tr key={file.uid}>
    //            <td>
    //              {file.name}
    //            </td>
    //            <td>
    //              {file.status}
    //            </td>
    //            <td>
    //              remove icon
    //            </td>
    //          </tr>
    //        )}
    //      </table>
    //    )
    //
    // }
  };

  return (
    <AlloyModal
      title="Upload Short Monitor File"
      open={visible}
      footer={
        null
        // <div>
        //   <Button style={{ marginRight: '8px' }} onClick={toggleUploadShortMonitor}>
        //     Cancel
        //   </Button>
        //
        //   <Button type="primary" onClick={() => sendShortMonitorData({
        //     variables: {
        //       input: {
        //         data: fileList[0],
        //         salesOrderIds: []
        //       }
        //     }
        //   })}>
        //     Upload
        //   </Button>
        // </div>
      }
      onCancel={toggleUploadShortMonitor}
      destroyOnClose
    >
      <Dragger {...props}>
        <p className="ant-upload-text">
          Drag and Drop files here (or) <AlloyButton type="primary">Upload Files</AlloyButton>
        </p>
        <p className="short_monitor_upload-hint">
          Supported file types: csv, excel. Maximum upload file size: 75MB.
        </p>
      </Dragger>
    </AlloyModal>
  );
};

export default UploadShortMonitorModal;
