import React, { useMemo } from 'react';
import s from './AccuracyGraph.module.scss';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import {
  getLineChartLabels,
  generateFakeDataSets,
  isCalendarValueContinuous
} from 'common/helpers/graph';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip as ChartTooltip,
  LineController
} from 'chart.js';

import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTooltip,
  Legend,
  LineController,
  annotationPlugin
);

export const AccuracyGraph = ({ graphPeriod }: { graphPeriod: CalendarValue }) => {
  const fakeChartData: ChartData<'line', (number | null)[], string> = useMemo(
    () => ({
      labels: getLineChartLabels(graphPeriod),
      datasets: generateFakeDataSets(graphPeriod).sort(
        (a, b) => parseInt(b.label) - parseInt(a.label)
      )
      // We don't want graph to rerender each time. lol.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [JSON.stringify(graphPeriod)]
  );

  const shouldDisplayLines = isCalendarValueContinuous(graphPeriod);

  return (
    <div>
      <div className={s.header}>
        <h2 className={s.title}>Accuracy over time</h2>
      </div>

      <div style={{ height: '200px' }}>
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              line: {
                borderJoinStyle: 'round',
                borderWidth: shouldDisplayLines ? 2 : 0
              },
              point: {
                pointStyle: shouldDisplayLines ? false : 'circle'
              }
            },
            plugins: {
              legend: {
                position: 'top',
                align: 'end'
              },
              tooltip: {
                callbacks: {
                  label: (context) => context.parsed.y + '%'
                }
              }
            },
            scales: {
              x: {
                offset: true,
                grid: {
                  drawOnChartArea: false
                },
                ticks: {
                  maxRotation: 90,
                  minRotation: 90,
                  labelOffset: 0,
                  autoSkip: false
                }
              },
              y: {
                min: 0,
                max: 100,
                ticks: {
                  callback: (value) => value + '%'
                },
                grid: {
                  drawOnChartArea: false
                }
              }
            }
          }}
          data={fakeChartData}
        />
      </div>
    </div>
  );
};
