import React from 'react';
import { useMutation } from '@apollo/client';
import { App } from 'ant5';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import DatePicker from 'components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
// import { DatePicker } from 'components/alloy/DatePicker/DatePicker'; //TODO: use when Storybook DatePicker component is implemented
import { UpdateSalesOrderShipmentMabdDocument } from './gql/__generated__/updateSalesOrderShipmentMABD.mutation';

export const SalesOrderShipmentDateField = ({
  salesOrderId,
  deliveryWindowEnd
}: {
  salesOrderId: string;
  deliveryWindowEnd: Moment | undefined;
}) => {
  const { message } = App.useApp();

  const [updateSalesOrderDate, { loading }] = useMutation(UpdateSalesOrderShipmentMabdDocument, {
    onCompleted: (data) => {
      message.success('Sales Order Date was successfully changed');
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`Error happened during Sales Order Date changing: ${error.message}`);
    }
  });

  return (
    <AlloySpin spinning={loading}>
      <DatePicker
        size={'small'}
        placeholder=""
        allowClear={false}
        //TODO: BELOW COMMENTED OUT CODE IS FOR STORYBOOK DATEPICKER COMPONENT IMPLEMENTATION
        // value={!loading && deliveryWindowEnd ? deliveryWindowEnd?.toDate() : null}
        // dateFormat="MMM d, yyyy"
        // onChange={(date) => {
        //   updateSalesOrderDate({
        //     variables: {
        //       input: {
        //         id: salesOrderId,
        //         mustArriveByDate: date ? moment(date).format('YYYY-MM-DD') : ''
        //       }
        //     }
        //   });
        value={!loading ? deliveryWindowEnd : undefined}
        format="M/D/YY"
        onClick={(e) => {
          e.preventDefault();
        }}
        onChange={(date) => {
          updateSalesOrderDate({
            variables: {
              input: {
                id: salesOrderId,
                mustArriveByDate: date ? moment(date).format('YYYY-MM-DD') : ''
              }
            }
          });
        }}
      />
    </AlloySpin>
  );
};
