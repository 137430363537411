import { useCallback } from 'react';
import React from 'react';
import s from './PrioritizedList.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { ListCard } from './ListCard';

interface PrioritizedListProps<T> {
  selected: T[];
  onChange: (items: T[]) => void;
  'data-testid': string;
  itemBodyRender: (item: T, index: number, disabled: boolean) => void;
  itemKey: (item: T) => string;
  disabledItems?: string[];
  overrideDisabledIndex?: (item: T) => number;
  preventRemovalLastElement?: boolean;
}

export const isItemDisabled = (itemKey: string, disabledItems: string[]) =>
  (disabledItems || []).includes(itemKey);

export const PrioritizedList = <T extends {}>({
  selected,
  onChange,
  'data-testid': dataTestId,
  itemBodyRender,
  itemKey,
  disabledItems,
  overrideDisabledIndex,
  preventRemovalLastElement = false
}: PrioritizedListProps<T>) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const previous = [...selected];
      const item = previous[dragIndex];
      if (!isItemDisabled(itemKey(item), disabledItems || [])) {
        const newVal = update(previous, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, item as T]
          ]
        });
        onChange(newVal);
      }
    },
    [onChange, selected]
  );

  const renderDraggableItem = useCallback(
    (item: T, index: number) => {
      return (
        <ListCard<T>
          key={itemKey(item)}
          data-testid={`${dataTestId}-container-item`}
          itemIndex={
            !isItemDisabled(itemKey(item), disabledItems || []) || !overrideDisabledIndex
              ? index
              : overrideDisabledIndex(item)
          }
          item={item}
          moveCard={moveCard}
          onDeleteItem={() => {
            if (!isItemDisabled(itemKey(item), disabledItems || [])) {
              const previous = [...selected];
              const newVal = update(previous, {
                $splice: [[index, 1]]
              });
              onChange(newVal);
            }
          }}
          bodyRender={itemBodyRender}
          disabled={isItemDisabled(itemKey(item), disabledItems || [])}
          preventRemoval={preventRemovalLastElement && selected.length <= 1}
        />
      );
    },
    [moveCard, onChange, selected]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={s.items_container} data-testid={`${dataTestId}-container`}>
        {selected.map((currentSelected, i) => renderDraggableItem(currentSelected, i))}
      </div>
    </DndProvider>
  );
};
