import React from 'react';
import s from './MetricIndicator.module.scss';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { StatChangeIndicator } from 'components/ui/StatChangeIndicator/StatChangeIndicator';

interface PreviousProps {
  value: number;
  text?: string;
  tooltip?: string;
  lessIsBetter?: boolean;
}

interface MetricIndicatorProps {
  title: string;
  icon?: {
    src: string;
    isLogo?: boolean;
  };
  value: number;
  treshold?: number;
  previous?: PreviousProps;
  isPercentage?: boolean;
}

const getRateClass = (value: number, positiveTreshold?: number) => {
  if (!positiveTreshold) return '';
  return value >= positiveTreshold ? s.positive : s.negative;
};

const getDifference = (value: number, previousValue: number) => {
  return value - previousValue;
};

export const MetricIndicator = ({
  title,
  icon,
  value,
  treshold,
  previous,
  isPercentage = false
}: MetricIndicatorProps) => {
  return (
    <div className={s.indicator}>
      <div className={s.indicator_title_wrapper}>
        <div className={s.indicator_title}>{title}</div>
        {icon && (
          <img alt="" width="14" src={icon.src} className={clsx(icon.isLogo ? s.logo : '')} />
        )}
      </div>
      <div className={clsx(s.indicator_value, getRateClass(value, treshold))}>
        {value.toLocaleString()}
        {isPercentage && '%'}
      </div>
      {previous && <Previous initialValue={value} isPercentage={isPercentage} {...previous} />}
    </div>
  );
};

const Previous = ({
  value,
  initialValue,
  isPercentage,
  tooltip,
  text,
  lessIsBetter = false
}: PreviousProps & { initialValue: number; isPercentage: boolean }) => {
  const difference = getDifference(initialValue, value);

  if (difference === 0) return <></>;

  const isPositive = lessIsBetter ? difference < 0 : difference > 0;
  const changeDirection = difference > 0 ? 'increase' : 'decrease';
  const displayValue = `${Math.abs(difference).toLocaleString()}${isPercentage ? '%' : ''}`;

  const statChangeIndicator = (
    <div className={s.stats_change}>
      <StatChangeIndicator
        className={s.stat_change}
        value={displayValue}
        isPositive={isPositive}
        change={changeDirection}
      />
      <span className={s.previous_description}>{text}</span>
    </div>
  );

  return (
    <div className={s.previous_container}>
      {tooltip ? (
        <Tooltip title={tooltip} placement="bottom">
          {statChangeIndicator}
        </Tooltip>
      ) : (
        { statChangeIndicator }
      )}
    </div>
  );
};
