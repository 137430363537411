import React from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { AdvancedFilter } from 'common/interfaces';
import s from './AdvancedFilterComponent.module.scss';

interface ConfirmDeleteFilterModalProps {
  onConfirm: (confirmed: boolean) => void;
  filter: AdvancedFilter;
}

export const ConfirmDeleteFilterModal = ({ onConfirm, filter }: ConfirmDeleteFilterModalProps) => {
  return (
    <Modal
      className={s.delete_filter_modal}
      title="Delete Filter"
      open={true}
      maskClosable={false}
      onCancel={() => onConfirm(false)}
      okText="Delete"
      okType="danger"
      okButtonProps={{
        type: 'primary',
        // @ts-ignore
        'data-testid': 'delete-saved-filter-confirm'
      }}
      closeIcon={<CloseOutlined />}
      closable={true}
      onOk={() => onConfirm(true)}
    >
      Are you sure you want to delete filter <b>{filter?.name}</b>? This change is not reversible.
    </Modal>
  );
};
