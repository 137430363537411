import { User } from 'graphql/__generated__/types';
import React, { Dispatch, ReactNode, useState } from 'react';

type ProviderProps = {
  children: ReactNode;
};

interface UserContextData {
  user: User | undefined;
  isAdmin: () => boolean;
  isOperations: () => boolean; // for error visibility
  setUser: Dispatch<React.SetStateAction<User | undefined>>;
}

export const UserContext = React.createContext({} as UserContextData);

export const UserContextProvider = (props: ProviderProps) => {
  const [user, setUser] = useState<User>();

  const isAdmin = () => {
    return !!(user?.roles.includes('ADMIN') || user?.roles.includes('SUPERADMIN'));
  };

  // for error visibility
  const isOperations = () => {
    return !!user?.roles.includes('OPERATIONS');
  };

  return (
    <UserContext.Provider value={{ user, isAdmin, isOperations, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
