import { useMutation, useQuery } from '@apollo/client';
import { message, Spin } from 'antd';
import { TpAssortment } from 'common/interfaces';
import React from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { AddShipToStepper } from './components/AddShipToStepper/AddShipToStepper';
import arrayMutators from 'final-form-arrays';
import { AddStoreInput, getDateValue, getTimeValue } from 'pages/ShipToPage/helper';
import moment from 'moment';
import {
  ShipToEditingInitDataDocument,
  ShipToEditingInitDataQuery
} from 'pages/ShipToPage/gql/__generated__/shipToEditingInitData.query';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { RetailerDeliveryDestinationToCopy } from './components/SelectShipToModal/SelectShipToModal';
import { RetailerChannelsForShipTo } from 'pages/ShipToPage/ShipToPage';
import { UpsertRetailerDeliveryDestinationsDocument } from 'pages/ShipToPage/gql/__generated__/upsertRetailerDeliveryDestinations.mutation';

interface EditingShipToDrawerProps {
  deliveryDestination?: RetailerDeliveryDestinationToCopy;
  onClose: () => void;
  vendorMarkets: RetailerChannelsForShipTo[];
  onSelectShipTo: (shipTo: RetailerDeliveryDestinationToCopy | undefined, tpId?: string) => void;
  tradingPartnerId?: string;
}

export type DistributionCenter = InferNodeType<ShipToEditingInitDataQuery, 'distributionCenters'>;
export type TradingPartner = InferNodeType<ShipToEditingInitDataQuery, 'tradingPartners'>;

export const AddShipToModal = ({
  onClose,
  deliveryDestination,
  vendorMarkets,
  onSelectShipTo,
  tradingPartnerId
}: EditingShipToDrawerProps) => {
  const { data: initData, loading: initLoading } = useQuery(ShipToEditingInitDataDocument, {});

  const distributionCenters = getNodesFromEdges(initData?.distributionCenters);
  const tradingPartners = getNodesFromEdges(initData?.tradingPartners);

  const [upsertRetailerDeliveryDestinations, { loading: upserting }] = useMutation(
    UpsertRetailerDeliveryDestinationsDocument,
    {
      refetchQueries: ['retailerDeliveryDestinations', 'retailerDeliveryDestination']
    }
  );

  const submitForm = async (values: any, form: FormApi<any, any>) => {
    try {
      const {
        id,
        name,
        tradingPartnerId,
        active,
        address,
        addressCity,
        addressState,
        addressZip,
        allowedDcs,
        cisId,
        cofId,
        duns,
        externalId,
        externalStoreNumber,
        metroArea,
        openingDate,
        sanCode,
        sapCustomerId,
        soldToSapCustomerId,
        specialAssortment,
        storeType,
        timezone,
        vendorMarketId,
        tpAssortments,
        autoForecast,
        autoFulfill,
        businessUnitDeliveryDestinationCode,
        capacityCubicFeetBoh,
        defaultForecastingSource,
        deliverySchedule,
        extraDayOfCover,
        firstDeliveryDate,
        frontOfHouseFillPercentage,
        overrideExpirationDateFromShipmentItem,
        releaseMethod,
        rounding,
        routeNumber,
        storeCapacity,
        dcSoldTos
      } = values;

      const editedTpConfiguration = {
        autoForecast,
        autoFulfill,
        alternateSoldTo:
          dcSoldTos.find((dcSoldTo: { dcCode?: string; soldTo?: string }) => !dcSoldTo.dcCode)
            ?.soldTo ?? null,
        dcSoldTos: dcSoldTos
          .filter(
            (dcSoldTo: { dcCode?: string; soldTo?: string }) =>
              !!dcSoldTo.dcCode && !!dcSoldTo.soldTo
          )
          .map((dcSoldTo: { dcCode?: string; soldTo?: string }) => ({
            dcCode: distributionCenters.find((dc) => dc.id === dcSoldTo.dcCode)?.code,
            soldTo: dcSoldTo.soldTo
          })),
        businessUnitDeliveryDestinationCode,
        capacityCubicFeetBoh: capacityCubicFeetBoh ? Number(capacityCubicFeetBoh) : undefined,
        defaultForecastingSource,
        deliverySchedule: (deliverySchedule || []).map(
          (schedule: {
            dayOfWeek: string;
            cutoffTime: moment.Moment;
            expectedAsnHoursBeforeDelivery?: string;
            expectedDeliveryTime?: moment.Moment;
            expectedInvoiceHoursAfterDelivery?: string;
            expectedPoAcknowledgementHoursBeforeDelivery?: string;
            expectedPurchaseOrderHoursBeforeDelivery?: string;
            expectedSalesOrderHoursBeforeDelivery?: string;
          }) => ({
            ...schedule,
            dayOfWeek: schedule.dayOfWeek ?? undefined,
            cutoffTime: getTimeValue(schedule.cutoffTime),
            expectedDeliveryTime: getTimeValue(schedule.expectedDeliveryTime),
            expectedAsnHoursBeforeDelivery: schedule.expectedAsnHoursBeforeDelivery
              ? Number(schedule.expectedAsnHoursBeforeDelivery)
              : undefined,
            expectedInvoiceHoursAfterDelivery: schedule.expectedInvoiceHoursAfterDelivery
              ? Number(schedule.expectedInvoiceHoursAfterDelivery)
              : undefined,
            expectedPoAcknowledgementHoursBeforeDelivery:
              schedule.expectedPoAcknowledgementHoursBeforeDelivery
                ? Number(schedule.expectedPoAcknowledgementHoursBeforeDelivery)
                : undefined,
            expectedPurchaseOrderHoursBeforeDelivery:
              schedule.expectedPurchaseOrderHoursBeforeDelivery
                ? Number(schedule.expectedPurchaseOrderHoursBeforeDelivery)
                : undefined,
            expectedSalesOrderHoursBeforeDelivery: schedule.expectedSalesOrderHoursBeforeDelivery
              ? Number(schedule.expectedSalesOrderHoursBeforeDelivery)
              : undefined
          })
        ),
        extraDayOfCover,
        firstDeliveryDate: getDateValue(firstDeliveryDate),
        frontOfHouseFillPercentage: frontOfHouseFillPercentage
          ? Number(frontOfHouseFillPercentage)
          : undefined,
        overrideExpirationDateFromShipmentItem,
        releaseMethod,
        rounding,
        routeNumber,
        storeCapacity: storeCapacity ? Number(storeCapacity) : undefined,
        tradingPartnerId
      };
      const tradingPartnerConfiguration = id
        ? [
            ...(deliveryDestination?.tradingPartnerRetailerDeliveryDestinations || []).map(
              (tpRdd) => ({
                autoForecast: tpRdd?.autoForecast ?? undefined,
                autoFulfill: tpRdd?.autoFulfill ?? undefined,
                businessUnitDeliveryDestinationCode:
                  tpRdd?.businessUnitDeliveryDestinationCode ?? undefined,
                capacityCubicFeetBoh: tpRdd?.capacityCubicFeetBoh ?? undefined,
                defaultForecastingSource: tpRdd?.defaultForecastingSource ?? undefined,
                deliverySchedule: tpRdd?.deliverySchedule?.map((ds) => ({
                  dayOfWeek: ds?.dayOfWeek ?? undefined,
                  cutoffTime: getTimeValue(ds?.cutoffTime),
                  expectedDeliveryTime: getTimeValue(ds?.expectedDeliveryTime),
                  expectedAsnHoursBeforeDelivery: ds?.expectedAsnHoursBeforeDelivery
                    ? Number(ds.expectedAsnHoursBeforeDelivery)
                    : undefined,
                  expectedInvoiceHoursAfterDelivery: ds?.expectedInvoiceHoursAfterDelivery
                    ? Number(ds.expectedInvoiceHoursAfterDelivery)
                    : undefined,
                  expectedPoAcknowledgementHoursBeforeDelivery:
                    ds?.expectedPoAcknowledgementHoursBeforeDelivery
                      ? Number(ds.expectedPoAcknowledgementHoursBeforeDelivery)
                      : undefined,
                  expectedPurchaseOrderHoursBeforeDelivery:
                    ds?.expectedPurchaseOrderHoursBeforeDelivery
                      ? Number(ds.expectedPurchaseOrderHoursBeforeDelivery)
                      : undefined,
                  expectedSalesOrderHoursBeforeDelivery: ds?.expectedSalesOrderHoursBeforeDelivery
                    ? Number(ds.expectedSalesOrderHoursBeforeDelivery)
                    : undefined
                })),
                extraDayOfCover: tpRdd?.extraDayOfCover ?? undefined,
                firstDeliveryDate: getDateValue(tpRdd?.firstDeliveryDate),
                frontOfHouseFillPercentage: tpRdd?.frontOfHouseFillPercentage ?? undefined,
                overrideExpirationDateFromShipmentItem:
                  tpRdd?.overrideExpirationDateFromShipmentItem ?? undefined,
                releaseMethod: tpRdd?.releaseMethod ?? undefined,
                rounding: tpRdd?.rounding ?? undefined,
                routeNumber: tpRdd?.routeNumber ?? undefined,
                storeCapacity: tpRdd?.storeCapacity ?? undefined,
                tradingPartnerId: tpRdd?.tradingPartner?.id,
                alternateSoldTo: tpRdd?.alternateSoldTo,
                dcSoldTos: tpRdd?.dcSoldTos.map((dcSoldTo) => ({
                  dcCode: dcSoldTo.dcCode,
                  soldTo: dcSoldTo.soldTo
                }))
              })
            ),
            editedTpConfiguration
          ]
        : [editedTpConfiguration];
      const inputValues = {
        id,
        name,
        active,
        address,
        addressCity,
        addressState,
        addressZip,
        allowedDcs: allowedDcs.map(
          (
            dc: { externalId: string; distributionCenterId: string; text: string },
            index: number
          ) => ({
            priority: index + 1,
            distributionCenterId: dc.distributionCenterId,
            externalId: dc.externalId
          })
        ),
        cisId,
        cofId,
        duns,
        externalId,
        externalStoreNumber,
        metroArea,
        openingDate: getDateValue(openingDate),
        sanCode,
        sapCustomerId,
        soldToSapCustomerId,
        specialAssortment,
        storeType,
        timezone,
        vendorMarketId,
        tpAssortments: tpAssortments.map((assortment: TpAssortment) => ({
          ...assortment,
          tradingPartnerId: tradingPartnerId
        })),
        tradingPartnerConfiguration
      };

      await upsertRetailerDeliveryDestinations({
        variables: {
          input: {
            stores: [
              {
                ...inputValues
              }
            ]
          }
        }
      });
      form.reset();
      message.success('Ship-To successfully added');
      onClose();
    } catch (e) {
      form.initialize(values);
      console.log({ e });
      message.error(`Error happened during Ship-To saving. ${e.message}`);
    }
  };

  return (
    <Spin spinning={upserting} style={{ height: '100%' }}>
      <Form<AddStoreInput>
        initialValues={{
          id: deliveryDestination?.id,
          name: deliveryDestination?.name ?? undefined,
          active: true,
          address: deliveryDestination?.address ?? undefined,
          addressCity: deliveryDestination?.addressCity ?? undefined,
          addressState: deliveryDestination?.addressState ?? undefined,
          addressZip: deliveryDestination?.addressZip ?? undefined,
          allowedDcs: (deliveryDestination?.allowedDistributionCenters || []).map((value) => ({
            distributionCenterId: value?.distributionCenter?.id || '',
            externalId: value?.externalId ?? '',
            priority: value?.priority ?? 1
          })),
          cisId: deliveryDestination?.cisId ?? undefined,
          cofId: deliveryDestination?.cofId ?? undefined,
          duns: deliveryDestination?.duns ?? undefined,
          externalId: deliveryDestination?.externalId ?? undefined,
          externalStoreNumber: deliveryDestination?.externalStoreNumber ?? undefined,
          metroArea: deliveryDestination?.metroArea ?? undefined,
          openingDate:
            deliveryDestination && deliveryDestination?.openingDate
              ? moment(deliveryDestination?.openingDate)
              : undefined,
          sanCode: deliveryDestination?.sanCode ?? undefined,
          sapCustomerId: deliveryDestination?.sapCustomerId ?? undefined,
          soldToSapCustomerId: deliveryDestination?.soldToSapCustomerId ?? undefined,
          specialAssortment: deliveryDestination?.specialAssortment,
          storeType: deliveryDestination?.storeType ?? undefined,
          timezone: deliveryDestination?.timezone ?? undefined,
          tpAssortments: [],
          tradingPartnerConfiguration: [],
          vendorMarketId: deliveryDestination?.vendorMarket?.id,
          // only for adding
          tradingPartnerId: tradingPartnerId,
          autoForecast: undefined,
          autoFulfill: undefined,
          businessUnitDeliveryDestinationCode: '',
          capacityCubicFeetBoh: undefined,
          defaultForecastingSource: undefined,
          deliverySchedule: [],
          extraDayOfCover: undefined,
          firstDeliveryDate: '',
          frontOfHouseFillPercentage: undefined,
          overrideExpirationDateFromShipmentItem: undefined,
          releaseMethod: undefined,
          rounding: undefined,
          routeNumber: '',
          storeCapacity: undefined,
          alternateSoldTo: undefined,
          dcSoldTos: [{ dcCode: undefined, soldTo: undefined }]
        }}
        onSubmit={submitForm}
        mutators={{
          ...arrayMutators,
          setAllowedDcs: (args, state, utils) => {
            utils.changeValue(state, 'allowedDcs', () => args[0]);
          },
          setTpAssortments: (args, state, utils) => {
            utils.changeValue(state, 'tpAssortments', () => args[0]);
          },
          setRetailerChannel: (args, state, utils) => {
            utils.changeValue(state, 'vendorMarketId', () => args[0]);
          }
        }}
        render={({ handleSubmit, form }) => {
          return (
            <form
              id="add-ship-to-modal-form"
              onSubmit={(event) => {
                const promise = handleSubmit(event);
                promise &&
                  promise.then(() => {
                    // do nothing
                  });
                return promise;
              }}
            >
              <AddShipToStepper
                form={form}
                deliveryDestination={deliveryDestination}
                distributionCenters={distributionCenters}
                upserting={upserting}
                onCancel={onClose}
                tradingPartners={tradingPartners}
                vendorMarkets={vendorMarkets}
                onSelectShipTo={onSelectShipTo}
              />
            </form>
          );
        }}
      />
    </Spin>
  );
};
