import React, { Dispatch, ReactNode, useState } from 'react';

type ProviderProps = {
  children: ReactNode;
};

interface Errors {
  [index: string]: string;
}

interface EventsTrackerContextData {
  errors?: Errors;
  setErrors?: Dispatch<React.SetStateAction<Errors>>;
}

export const EventsTrackerContext = React.createContext({} as EventsTrackerContextData);

export const EventsTrackerProvider = (props: ProviderProps) => {
  const [errors, setErrors] = useState<Errors>({});

  return (
    <EventsTrackerContext.Provider value={{ errors, setErrors }}>
      {props.children}
    </EventsTrackerContext.Provider>
  );
};
