import React, { useState, useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import s from './SalesOrderListToolbar.module.scss';
import clsx from 'clsx';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloySpace } from 'components/ui/AlloySpace/AlloySpace';
import { App } from 'ant5';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  PO_STATUS_NEW,
  PO_STATUS_SCRUBBED,
  PO_STATUS_SUBMITTED,
  PurchaseOrderRefetchQueries
} from 'common/constants';
import { SalesOrderItem, PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';
import { ReleaseSalesOrdersDocument } from '../gql/__generated__/releaseSalesOrders.mutation';
import { UserContext } from 'context/userContext';
import { SubscribedAction } from '../PurchaseOrderDetails/PurchaseOrderDetails';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { Filter, TableFilters } from 'components/TableFilters/TableFilters';
import { SelectedShipmentGroup } from 'common/helpers/purchaseOrderDataHelper';
import { MenuProps } from 'components/ui/AlloyMenu/AlloyMenu';

export type SoFilterKeys = 'orderSource';
export const SO_FILTERS_QUERY_PARAM_STRING = 'saleOrderFilters';

//TODO: replace purchaseOrder.salesOrders with salesOrders
interface SalesOrderListToolbarProps {
  selectedItems: SalesOrderItem[];
  onSelectAction: (selectedRows: SalesOrderItem[]) => void;
  createNewShipment: (moveToId?: string) => void;
  selectedSalesOrder?: string;
  splitSalesOrder: (id: string) => Promise<void>;
  purchaseOrder: PurchaseOrderWithOverdue;
  fullSalesOrdersList: SelectedShipmentGroup[];
  setSubscribedActionInProgress: (value: SubscribedAction) => void;
  search: string[];
  setSearch: React.Dispatch<React.SetStateAction<string[]>>;
  existingOrderSources: { name: string; value: string }[];
}

export const SalesOrderListToolbar = ({
  selectedItems,
  onSelectAction,
  createNewShipment,
  selectedSalesOrder,
  splitSalesOrder,
  purchaseOrder,
  fullSalesOrdersList,
  setSubscribedActionInProgress,
  search,
  setSearch,
  existingOrderSources
}: SalesOrderListToolbarProps) => {
  const { message, notification } = App.useApp();

  const { user } = useContext(UserContext);
  const [isReleasing, setIsReleasing] = useState<boolean>(false);
  const [releaseSalesOrders] = useMutation(ReleaseSalesOrdersDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });

  const tableFilters = useMemo(() => {
    const tableFiltersRaw: Filter<SoFilterKeys>[] = [
      {
        name: 'Order Source',
        type: 'multiple',
        field: 'orderSource',
        options: existingOrderSources
      }
    ];
    return tableFiltersRaw.sort((a, b) => a.name.localeCompare(b.name));
  }, [existingOrderSources]);

  const moveToDropdown = useMemo(() => {
    // TODO: remove deprecated zeroPromise and replace with purchaseOrder.cutSummaries logic
    const filteredSO = purchaseOrder.salesOrders.filter(
      (so) => !so.zeroPromise && ['NEW', 'SCRUBBED'].includes(so.status || '')
    );

    const shipmentsMenu: MenuProps['items'] =
      filteredSO.length > 1
        ? fullSalesOrdersList
            .map((shipmentGroup) => {
              return shipmentGroup.shipments.map((shipment) => ({
                key: shipment.id,
                disabled: selectedItems[0]?.salesOrder?.id === shipment.id,
                onClick: () => createNewShipment(shipment.id),
                label: (
                  <div>
                    {shipmentGroup.shipmentGroup || ''} {`- ${shipment.name}`}
                    {selectedItems[0]?.salesOrder?.id === shipment.id ? '(current)' : null}
                  </div>
                )
              }));
            })
            .flat()
        : [];

    return [
      ...shipmentsMenu,
      {
        key: 'new_shipment',
        label: 'New shipment',
        onClick: () => createNewShipment()
      }
    ];
  }, [createNewShipment, purchaseOrder?.salesOrders, selectedItems, fullSalesOrdersList]);

  const release = async (salesOrderIds: string[]) => {
    setIsReleasing(true);

    try {
      await releaseSalesOrders({ variables: { input: { salesOrderIds } } });
      message.success('Successfully Released');
    } catch (error) {
      notification.error({
        message: 'Release Failed',
        description: error.message
      });
    }

    setIsReleasing(false);
  };

  const salesOrder = purchaseOrder.salesOrders.find((so) => so.id === selectedSalesOrder);

  const isMoveToAllowed = useMemo(() => {
    if (salesOrder?.status === 'NEW' || salesOrder?.status === 'SCRUBBED') {
      return true;
    }
  }, [salesOrder]);

  const addMenu: MenuProps['items'] = [
    {
      key: '1',
      label: 'Move to',
      disabled: !isMoveToAllowed,
      children: moveToDropdown,
      popupClassName: s.move_to_popup
    },
    {
      key: '2',
      label: 'Auto-split order',
      disabled:
        !purchaseOrder.availableActions.includes('AUTO_TRUCK_SPLIT') ||
        !user?.availableActions.includes('AUTO_TRUCK_SPLIT'),
      onClick: () => {
        if (!selectedSalesOrder) return null;
        setSubscribedActionInProgress(SubscribedAction.AUTO_SPLIT);
        splitSalesOrder(selectedSalesOrder);
        //TODO: do I need loading here? isSplitting was previously used for this action, but seems to do the same thing as setSubscribedActionInProgress
      }
    }
  ];

  /* NOTE ABOUT FUNCTION BELOW: 
This function conditionally renders the dropdown, for move to it works for status and available actions, for autosplit it works for available actions.
TODO: further investigate BE availableActions for these in the future to only rely on them instead of PO status. Also investigate if all conditions on BE are being met.
*/
  const displayActionsDropdown = () => {
    if (
      (purchaseOrder.availableActions.includes('MOVE_SO_ITEM') &&
        user?.availableActions.includes('MOVE_SO_ITEM') &&
        [PO_STATUS_NEW, PO_STATUS_SCRUBBED].includes(
          (purchaseOrder.statuses?.primary || '').toUpperCase()
        ) &&
        [PO_STATUS_NEW, PO_STATUS_SCRUBBED].filter((status) =>
          purchaseOrder.statuses?.secondary.includes(status.toLowerCase())
        ).length) ||
      (purchaseOrder.availableActions.includes('AUTO_TRUCK_SPLIT') &&
        user?.availableActions.includes('AUTO_TRUCK_SPLIT'))
    ) {
      return (
        <AlloyDropdown
          data-testid="so-toolbar-actions-dropdown"
          className={s.so_toolbar_button}
          trigger={['click']}
          menu={{ items: addMenu }}
        >
          <AlloySpace>
            Actions
            <DownOutlined />
          </AlloySpace>
        </AlloyDropdown>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {purchaseOrder.tradingPartner?.deliveryType !== 'ECOMM' ? (
        <AlloyRow id="salesOrderListToolbar" className={s.toolbar_container}>
          <div className={s.filters_search_container}>
            <div className={s.search}>
              <MultipleValuesInput
                data-testid={'sales_orders_search'}
                placeholder={'Search by UPC, ASIN, Description or SO#'}
                value={search}
                onChange={setSearch}
                allowClear={true}
                prefix={<SearchOutlined width="14px" height="14px" />}
                splitInputValue={/[^0-9a-zA-Z-,.()&]+/g}
              />
            </div>
            {existingOrderSources.length > 1 && (
              <div className={s.filters}>
                <TableFilters //TODO: migrate to ant5 - this is a global change so should be done in a separate ticket
                  filters={tableFilters}
                  queryParamName={SO_FILTERS_QUERY_PARAM_STRING}
                />
              </div>
            )}
          </div>

          <div className={s.action_buttons_container}>
            {selectedItems.length > 0 ? (
              <>
                {/* NUMBER OF ROWS SELECTED */}
                <div data-testid="so-toolbar-selected-rows-counter" className={s.itemsSelected}>{`${
                  selectedItems.length
                } ${selectedItems.length === 1 ? 'item' : 'items'} selected`}</div>

                {/* ACTIONS DROPDOWN */}
                {displayActionsDropdown()}

                {/* CLEAR SELECTED BUTTON */}
                <AlloyButton
                  data-testid="so-toolbar-clear-selected-button"
                  className={s.so_toolbar_button}
                  onClick={() => onSelectAction([])}
                >
                  Clear selected
                </AlloyButton>

                {/*TODO:fix RELEASE_SO type on BE*/}
                {/* Release all OR Release selected button for SO Tab */}
                {selectedSalesOrder &&
                  purchaseOrder.availableActions.includes('RELEASE_SO') &&
                  user?.availableActions.includes('RELEASE_SO') && (
                    <AlloyButton
                      data-testid="so-toolbar-release-selected-sales-orders-button"
                      className={clsx(s.release_button, s.so_toolbar_button)}
                      loading={isReleasing}
                      onClick={() => release([selectedSalesOrder])}
                    >
                      Release selected
                    </AlloyButton>
                  )}
              </>
            ) : (
              <>
                {/* Release all OR Release selected button for Entire PO */}
                {purchaseOrder?.availableActions?.includes('RELEASE_PO') &&
                  purchaseOrder.salesOrders.length > 0 &&
                  user?.availableActions.includes('RELEASE_PO') && (
                    <AlloyButton
                      data-testid="so-toolbar-release-all-button"
                      className={clsx(s.release_button, s.so_toolbar_button)}
                      onClick={() =>
                        release(
                          purchaseOrder.salesOrders
                            .filter((so) => !so.zeroPromise && so.status !== PO_STATUS_SUBMITTED) //TODO: remove zeroPromise since it is deprecated. Need to rely on cutSummaries
                            .map((so) => so.id)
                        )
                      }
                      loading={isReleasing}
                    >
                      Release all
                    </AlloyButton>
                  )}
              </>
            )}
          </div>
        </AlloyRow>
      ) : null}
    </>
  );
};
