import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import './style.scss';

interface LoaderSpinnerProps {
  loadingText?: string;
}

const LoaderSpinner = ({ loadingText = 'Loading...' }: LoaderSpinnerProps) => {
  return (
    <div className="loader_spinner">
      <LoadingOutlined marginHeight={300} color="#757575" />
      <div className="loader_text">{loadingText}</div>
    </div>
  );
};

export default LoaderSpinner;
