import React, { useState, useEffect, useMemo } from 'react';
import './styles.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import { UpdatePurchaseOrderItemsDocument } from './gql/__generated__/updatePurchaseOrderItems.mutation';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  GetPurchaseOrdersByIdsUpcSelectDocument,
  GetPurchaseOrdersByIdsUpcSelectQuery
} from './gql/__generated__/getPurchaseOrdersByIdsUpcSelect.query';
import { PurchaseOrderRefetchQueries } from 'common/constants';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyRadio, RadioChangeEvent } from 'components/ui/AlloyRadio/AlloyRadio';

type PurchaseOrder = InferNodeType<GetPurchaseOrdersByIdsUpcSelectQuery, 'purchaseOrders'>;
type SalesOrder = PurchaseOrder['salesOrders'][number];
type SalesOrderItem = SalesOrder['salesOrderItems'][number];
type DistributionCenter = NonNullable<SalesOrder['distributionCenter']>;
type POItem = PurchaseOrder['purchaseOrderItems'][number];
type Product = POItem['products'][number];

interface UpcSelectProps {
  onCancel: () => void;
  onContinue: () => void;
  purchaseOrderIds: string[];
}

interface DistributionCenterGroupUnmapped {
  dc: DistributionCenter | undefined | null;
  items: Record<string, ItemGroup>;
}

interface ProductInstance extends Product {
  salesOrderItem?: SalesOrderItem;
  onPo?: boolean;
  inventoryOnHand: number | null;
  poItemId: string;
}

interface ItemInstance {
  quantityOrdered: number | null | undefined;
  purchaseOrder: PurchaseOrder;
  products: ProductInstance[];
}

interface ItemGroup {
  externalId: string;
  instances: ItemInstance[];
  name: string;
  uom: string;
}

type DistributionCenterGroupWithOptionalDc = {
  dc: DistributionCenter | undefined | null;
  items: ItemGroup[];
};

type Selection = Record<
  string,
  { originalProduct: string; selectedProduct: string; instances: string[] }
>;

export const UpcSelect = ({ onContinue, onCancel, purchaseOrderIds }: UpcSelectProps) => {
  const { notification } = App.useApp();
  const { data, loading } = useQuery(GetPurchaseOrdersByIdsUpcSelectDocument, {
    variables: { ids: purchaseOrderIds },
    skip: !purchaseOrderIds || !purchaseOrderIds.length
  });

  const poData = useMemo(() => getNodesFromEdges(data?.purchaseOrders), [data?.purchaseOrders]);

  const [, setSkip] = useState(true);
  const [groupedItems, setGroupedItems] = useState<DistributionCenterGroupWithOptionalDc[]>([]);
  const [selections, setSelections] = useState<Selection>({});
  const [saving, setSaving] = useState(false);
  const [updateUpcSelection] = useMutation(UpdatePurchaseOrderItemsDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });

  useEffect(() => {
    if (loading || !poData) return;

    const multipleUpcItems = upcSelectParser(poData);
    setGroupedItems(multipleUpcItems);

    if (multipleUpcItems.length) setSkip(false);
    // TODO: decide if we actually want to skip emtpy UPCs
    // else if (onContinue) onContinue();
  }, [poData, onContinue, loading]);

  useEffect(() => {
    const updatedSelections: Selection = {};
    for (const dc of groupedItems) {
      for (const item of dc.items) {
        const originalProduct =
          item.instances[0].products.find((product) => product.onPo)?.id || 'NO ID'; // Just to be safe, we have an "empty" string value
        updatedSelections[item.externalId] = {
          originalProduct,
          selectedProduct: originalProduct,
          instances: item.instances.map((instance) => instance.products[0].poItemId)
        };
      }
    }

    setSelections(updatedSelections);
  }, [groupedItems]);

  const itemCount = groupedItems.reduce((count, dc) => {
    return (count += dc.items.length);
  }, 0);

  useEffect(() => {
    //reset the display boolean on unmounting
    return onCancel;
  }, [onCancel]);

  // TODO: decide if we actually want to skip emtpy UPCs
  // if (skip) {
  //   return null;
  // }

  const modalTitle =
    itemCount === 0 ? (
      <div data-testid="multiple_upc_unavailable_modal_title" className="multiple-upc-title">
        <div data-testid="multiple_upc_unavailable_title" className="title">
          There are no multi-UPC products in this list
        </div>
        <div data-testid="multiple_upc_unavailable_subtitle" className="subtitle">
          {itemCount} item(s) available
        </div>
      </div>
    ) : (
      <div data-testid="multiple_upc_available_modal_title" className="multiple-upc-title">
        <div data-testid="multiple_upc_available_title" className="title">
          Multiple UPC available
        </div>
        <div data-testid="multiple_upc_available_subtitle" className="subtitle">
          {itemCount} item(s)
        </div>
      </div>
    );

  const cancel = () => {
    if (onCancel) onCancel();
  };

  const columns: ColumnsType<ItemGroup> = [
    {
      title: 'PRODUCT NAME',
      key: 'product_name',
      render: (_, product) => product.name
    },
    {
      key: 'asin',
      title: 'External ID',
      render: (_, product) => product.externalId
    },
    {
      title: 'ORDER QTY',
      key: 'order-qty',
      align: 'center' as const,
      render: (_, product) => {
        return `${product.instances.reduce(
          (total, instance) => (total += instance.quantityOrdered || 0),
          0
        )} ${product.uom || ''}`;
      }
    },
    {
      title: 'UPC',
      key: 'upc',
      className: 'empty-cell',
      render: (_, product) => {
        return (
          <div>
            {product.instances[0].products.map((subProd) => {
              return (
                <div data-testid="upc_sub_row" className="sub-row" key={subProd.id}>
                  {subProd.upc}
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      title: 'INVENTORY QTY',
      key: 'inventory-qty',
      className: 'empty-cell',
      render: (_, product) => {
        return (
          <div className="centered">
            {product.instances[0].products.map((subProd) => {
              return (
                <div data-testid="inventory_qty_sub_row" className="sub-row" key={subProd.id}>
                  {subProd.inventoryOnHand !== null &&
                    `${subProd.inventoryOnHand} ${product.uom || ''}`}
                  {subProd.inventoryOnHand === null && (
                    <AlloyTooltip
                      data-testid="inventory_unavailable_tooltip"
                      title="Inventory level unavailable"
                    >
                      <QuestionCircleOutlined
                        data-testid="unknown_sub_prod_inventory_qty"
                        className="unknown"
                      />
                    </AlloyTooltip>
                  )}
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      title: '',
      key: 'select',
      className: 'empty-cell',
      render: (_, product) => {
        return (
          <UpcRadioGroup
            data-testid="upc_radio_group"
            current={selections[product.externalId]?.selectedProduct}
            onSelection={(productId) => {
              selections[product.externalId].selectedProduct = productId;
              setSelections({ ...selections });
            }}
            substitutionProducts={product.instances[0].products}
          />
        );
      }
    }
  ];

  const changed = () => {
    for (const row of Object.values(selections)) {
      if (row.originalProduct !== row.selectedProduct) return 'Send Dummy Order';
    }
    return 'Keep Unchanged & Send Dummy Order';
  };

  const onOk = async () => {
    setSaving(true);
    const updates: { id: string; productId: string }[] = Object.values(selections)
      .filter((selection) => selection.originalProduct !== selection.selectedProduct)
      .reduce((updates: { id: string; productId: string }[], selection) => {
        return updates.concat(
          selection?.instances?.map((poItemId) => {
            return {
              id: poItemId,
              productId: selection.selectedProduct
            };
          })
        );
      }, []);

    //if there are no updates, continue with dummy order creation
    if (!updates.length) {
      if (onContinue) onContinue();
      return;
    }

    updateUpcSelection({
      variables: {
        input: {
          toUpdate: updates.map((update) => {
            return {
              id: update.id,
              productId: update.productId
            };
          })
        }
      }
    })
      .then(() => {
        if (onContinue) onContinue();
      })
      .catch(() => {
        notification.error({
          message: 'Failed to save',
          description: `Some of the changes could not be saved. An error has been logged for review`
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const footer = (
    <div className="footer">
      <AlloyButton data-testid="cancel_button" onClick={cancel}>
        Cancel
      </AlloyButton>
      <AlloyButton data-testid="changed_button" onClick={onOk} loading={saving} type="primary">
        {changed()}
      </AlloyButton>
    </div>
  );

  return (
    <>
      <AlloyModal
        data-testid="multiple_upc_modal"
        className="multiple-upc-modal"
        title={!loading ? modalTitle : null}
        confirmLoading={saving}
        footer={!loading ? footer : null}
        width="900px"
        onCancel={cancel}
        open={true}
      >
        {loading && (
          <div className="loader">
            <LoaderSpinner />
            <div className="desc">retrieving purchase orders</div>
          </div>
        )}

        {!loading &&
          groupedItems.map((data, index) => {
            return (
              <div
                data-testid="distribution_center_container"
                className="distribution-center"
                key={`${data?.dc?.id}-${index}`}
              >
                <div data-testid="distribution_center_title" className="title">
                  {data?.dc?.name}
                </div>
                <AlloyTable
                  data-testid="distribution_center_table"
                  pagination={false}
                  columns={columns}
                  rowKey={(product) => product.externalId}
                  className="legacy_borderless_bordered"
                  bordered={true}
                  dataSource={data.items}
                />
              </div>
            );
          })}
      </AlloyModal>
    </>
  );
};

interface UpcRadioGroupProps {
  substitutionProducts: ProductInstance[];
  onSelection: (upc: string) => void;
  current: string;
}

const UpcRadioGroup = ({ substitutionProducts, onSelection, current }: UpcRadioGroupProps) => {
  const [value, setValue] = useState(current);
  const onChange = (selection: RadioChangeEvent) => {
    setValue(selection.target.value);
    onSelection(selection.target.value);
  };

  useEffect(() => {
    setValue(current);
  }, [current]);

  return (
    <div>
      <AlloyRadio.Group data-testid="sub_prod_radio_group" onChange={onChange} value={value}>
        {substitutionProducts?.map((subProd) => {
          return (
            <div data-testid="sub_prod_row" className="sub-row" key={subProd?.id}>
              <AlloyRadio data-testid="sub_prod_radio_button" value={subProd?.id} />
            </div>
          );
        })}
      </AlloyRadio.Group>
    </div>
  );
};

function upcSelectParser(purchaseOrders: PurchaseOrder[]): DistributionCenterGroupWithOptionalDc[] {
  const distributionCenters: Record<string, DistributionCenterGroupUnmapped> = {};
  for (const purchaseOrder of purchaseOrders) {
    for (const poItem of purchaseOrder.purchaseOrderItems) {
      /* check each purchase order item to see if there are substitution upcs available. If there are, pull those out for user action */
      if (poItem.products.length <= 1) continue;

      for (const salesOrder of purchaseOrder.salesOrders) {
        for (const soItem of salesOrder.salesOrderItems) {
          if (soItem?.product?.id !== poItem?.product?.id) continue;
          if (
            !distributionCenters[
              salesOrder.distributionCenter?.code || salesOrder.distributionCenterCode || ''
            ]
          ) {
            distributionCenters[
              salesOrder.distributionCenter?.code || salesOrder.distributionCenterCode || ''
            ] = {
              dc: salesOrder.distributionCenter,
              items: {}
            };
          }

          const dc =
            distributionCenters[
              salesOrder.distributionCenter?.code || salesOrder.distributionCenterCode || ''
            ];
          if (poItem.externalId && !dc.items[poItem.externalId]) {
            dc.items[poItem.externalId] = {
              externalId: poItem.externalId,
              name: poItem?.product?.name || '',
              instances: [],
              uom: poItem.submittedUnitOfMeasure || ''
            };
          }

          if (
            poItem.externalId &&
            !dc.items[poItem.externalId].instances.find(
              (instance) => instance.purchaseOrder.id === purchaseOrder.id
            )
          ) {
            dc.items[poItem.externalId].instances.push({
              purchaseOrder: purchaseOrder,
              quantityOrdered: poItem.quantityOrdered,
              products: poItem.products.map((product) => {
                const productInstance: ProductInstance = {
                  ...product,
                  inventoryOnHand:
                    product.inventory?.find(
                      (dcInventory) => dcInventory.dcCode === salesOrder.distributionCenter?.code
                    )?.inventoryOnHand || null,
                  poItemId: poItem.id
                };

                for (const salesOrder of purchaseOrder.salesOrders) {
                  for (const soItem of salesOrder.salesOrderItems) {
                    if (soItem?.product?.id === product.id) productInstance.salesOrderItem = soItem;
                  }
                }
                for (const poItem of purchaseOrder.purchaseOrderItems) {
                  if (poItem.product && poItem.product.id === product.id)
                    productInstance.onPo = true;
                }

                return productInstance;
              })
            });
          }
        }
      }
    }
  }

  return Object.values(distributionCenters).map((row) => {
    return {
      dc: row.dc,
      items: Object.values(row.items)
    };
  });
}
