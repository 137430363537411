import { getItem } from 'common/services/persistentStorageServices';
import { getTempItem } from '../services/temporaryStorageServices';

export const isLoggedIn = () => {
  return !!getItem('user');
};

export const isLoggedInThroughSso = () => {
  return getTempItem('sso_login_flag');
};
