import moment from 'moment';
import { AuditTrailVersion, RetailerDeliveryDestinationForAudit } from '../ChangeHistory';
import {
  ChangeHistoryEntityType,
  ChangeHistoryItem,
  DistributionCenterForShipToAudit,
  TradingPartnerForAudit,
  getEventNameByAction
} from './baseHelper';

export const prepareShipToAuditVersions = (
  versions: AuditTrailVersion[],
  entity: RetailerDeliveryDestinationForAudit
) => {
  return [
    ...versions,
    ...((
      entity as unknown as RetailerDeliveryDestinationForAudit
    )?.tradingPartnerRetailerDeliveryDestinations?.flatMap((tprdd) =>
      tprdd?.auditTrailVersions.map((version: AuditTrailVersion) => ({
        ...version,
        diff: version.diff.map((d) => ({
          ...d,
          fieldPath: `.tradingPartnerRetailerDeliveryDestinations[${tprdd?.tradingPartner?.id}::${
            (entity as unknown as RetailerDeliveryDestinationForAudit)?.id || ''
          }${d.fieldPath}]`
        }))
      }))
    ) || [])
  ].sort(
    (r1, r2) => moment(r2?.appliedAt).toDate().getTime() - moment(r1?.appliedAt).toDate().getTime()
  );
};

export const getShipToEventName = (
  path: string,
  simpleChanges: ChangeHistoryItem[],
  type: ChangeHistoryEntityType,
  eventType: string,
  tradingPartners: TradingPartnerForAudit[],
  disctributionCenters: DistributionCenterForShipToAudit[]
) => {
  if (path.startsWith('.allowedDistributionCenters')) {
    const allowedDcId = getShipToNestedObjectIdFromPath(path);
    const allowedDc = disctributionCenters?.find((dc) => dc.id === allowedDcId);
    const allowedDcTitle = allowedDc ? allowedDc.name + ' ' + allowedDc.code : '';
    return getEventNameByAction(
      simpleChanges,
      allowedDcTitle,
      undefined,
      { before: true, value: 'Remove' },
      'Allowed DCs'
    );
  } else if (path.startsWith('.tradingPartnerRetailerDeliveryDestinations')) {
    const tpId = getShipToNestedObjectIdFromPath(path);
    const tpRddName = tradingPartners?.find((tp) => tp.id === tpId)?.name || '';
    if (path.includes('deliverySchedule')) {
      return getEventNameByAction(simpleChanges, `${tpRddName} delivery schedule`);
    } else if (path.includes('dcSoldTos')) {
      return getEventNameByAction(simpleChanges, `${tpRddName} Sold To - DC pair`);
    } else {
      if (simpleChanges.find((change) => change.fieldName === 'tradingPartnerId')) {
        return `Add ${tpRddName} settings`;
      }
      return `Update ${tpRddName} settings`;
    }
  }
  return `${eventType} ${type}`;
};

const getShipToNestedObjectIdFromPath = (path: string) => {
  const pathParts = path.split('.');
  const key = pathParts[2];
  const keyParts = key.split('::');
  return keyParts[0];
};
