import { RetailerDeliveryDestination } from 'pages/ShipToPage/components/ShipToViewDrawer/ShipToViewDrawer';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import React from 'react';
import s from './GeneralSettingsViewTab.module.scss';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';

export const GeneralSettingsViewTab = ({
  shipTo
}: {
  shipTo: RetailerDeliveryDestination | null | undefined;
}) => {
  const configurationDetails = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="External ID" value={shipTo?.externalId} />
        <ViewField type="INPUT" title="Retailer Channel" value={shipTo?.vendorMarket?.name} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Name" value={shipTo?.name} />
        <ViewField type="INPUT" title="Oracle COF Number" value={shipTo?.cofId} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Store Number" value={shipTo?.externalStoreNumber} />
        <ViewField type="INPUT" title="SAP Ship-To Number" value={shipTo?.sapCustomerId} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="CIS Number" value={shipTo?.cisId} />
        <ViewField type="INPUT" title="SAP Sold To Number" value={shipTo?.soldToSapCustomerId} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="SAN Number" value={shipTo?.sanCode} />
        <ViewField type="INPUT" title="DUNS" value={shipTo?.duns} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Store Type" value={shipTo?.storeType} />
        <ViewField type="INPUT" title="Opening Date" value={shipTo?.openingDate} />
      </div>
      <ViewField type="INPUT" title="Timezone" value={shipTo?.timezone} />
      <ViewField
        type="CHECKBOX"
        title="Special Assortment (Vendor Flex)"
        value={shipTo?.specialAssortment}
        className={s.margin_bottom}
      />
    </>
  );

  const address = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="Address" value={shipTo?.address} />
        <ViewField type="INPUT" title="City" value={shipTo?.addressCity} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="State" value={shipTo?.addressState} />
        <ViewField type="INPUT" title="Zip" value={shipTo?.addressZip} />
      </div>
      <ViewField type="INPUT" title="Metro Area" value={shipTo?.metroArea} />
    </>
  );

  return (
    <>
      <ViewRow title="Configuration details" content={configurationDetails} bordered={true} />
      <ViewRow title="Address" content={address} bordered={false} />
    </>
  );
};
