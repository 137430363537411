import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SyncChannelAdvisorMutationVariables = Types.Exact<{
  input: Types.SyncPurchaseOrderFromChannelAdvisorInput;
}>;


export type SyncChannelAdvisorMutation = { __typename?: 'RootMutationType', syncPurchaseOrderFromChannelAdvisor?: { __typename?: 'SyncPurchaseOrderFromChannelAdvisorPayload', success?: boolean | null } | null };


export const SyncChannelAdvisorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"syncChannelAdvisor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SyncPurchaseOrderFromChannelAdvisorInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"syncPurchaseOrderFromChannelAdvisor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<SyncChannelAdvisorMutation, SyncChannelAdvisorMutationVariables>;