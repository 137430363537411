import { FocusEventHandler, ReactNode } from 'react';
import { MultipleSelect } from './MultipleSelect/MultipleSelect';
import React from 'react';
import { SingleSelect } from './SingleSelect/SingleSelect';
import { BaseProperties } from '../Utils';

export type OptionType = {
  disabled?: boolean;
  label: string;
  value: string;
  children?: Omit<OptionType, 'children'>[];
};

export interface SelectProps<Multiple extends boolean | undefined = undefined>
  extends BaseProperties {
  allowClear?: boolean;
  dropdownRender?: (menu: React.ReactNode) => React.ReactNode;
  error?: string;
  label?: string;
  loading?: boolean;
  multiple?: Multiple;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: (
    value: Multiple extends false | undefined ? string | undefined : string[] | undefined
  ) => void;
  options: OptionType[];
  placeholder?: string;
  prefixIcon?: ReactNode;
  required?: boolean;
  value?: Multiple extends false | undefined ? string | undefined : string[] | undefined;
  defaultValue?: Multiple extends false | undefined ? string | undefined : string[] | undefined;
  sort?: (opt1: OptionType, opt2: OptionType) => number;
}

export const optionTypeSorter = (opt1: OptionType, opt2: OptionType) =>
  opt1.value === 'all' ? -1 : opt2.value === 'all' ? 1 : opt1.label.localeCompare(opt2.label);

export const Select = <Multiple extends boolean | undefined = undefined>(
  props: SelectProps<Multiple>
) =>
  !!props.multiple ? (
    <MultipleSelect
      {...(props as SelectProps<true>)}
      options={props.options.sort(props.sort ?? optionTypeSorter)}
    />
  ) : (
    <SingleSelect
      {...(props as SelectProps<false>)}
      options={props.options.sort(props.sort ?? optionTypeSorter)}
    />
  );
