import React, { useState, useRef, useEffect } from 'react';
import { Button, Input, InputRef } from 'antd';

interface ItemsToUpdate {
  id: string;
  quantityOrdered: number;
  productId: string;
}

interface OrderQuantityUpdateProps {
  quantity: string;
  updatePurchaseOrderSingleQuantity: (itemToUpdate: ItemsToUpdate[]) => void;
  itemId: string;
  productId: string;
  unit?: string;
}

const PurchaseOrderQuantityUpdate = ({
  quantity,
  updatePurchaseOrderSingleQuantity,
  itemId,
  unit,
  productId
}: OrderQuantityUpdateProps) => {
  const [editing, setEditing] = useState(false);
  const [productQty, setProductQty] = useState(quantity);
  const inputRef = useRef<InputRef>(null);
  const toggleEditing = () => setEditing(!editing);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
      inputRef?.current?.select();
    }
  }, [editing]);

  useEffect(() => {
    setProductQty(quantity);
  }, [quantity]);

  const saveField = (e: React.FormEvent<HTMLInputElement>) => {
    if (parseInt(e.currentTarget.value) >= 0 && e.currentTarget.value !== productQty) {
      updatePurchaseOrderSingleQuantity([
        { id: itemId, quantityOrdered: parseInt(e.currentTarget.value), productId: productId }
      ]);
    }
    toggleEditing();
  };

  return editing ? (
    <div className="qty_edit_container">
      <Input ref={inputRef} defaultValue={productQty} onPressEnter={saveField} onBlur={saveField} />
      <div className="unit">{unit}</div>
    </div>
  ) : (
    <Button type="link" onClick={toggleEditing}>
      {productQty} {unit}
    </Button>
  );
};

export default PurchaseOrderQuantityUpdate;
