import React, { FocusEventHandler, LegacyRef, ReactNode, useRef, useState } from 'react';
import s from './Input.module.scss';
import clsx from 'clsx';
import { BaseProperties } from '../Utils';

export interface InputProps<T = number | string> extends BaseProperties {
  error?: string;
  id?: string;
  prefix?: string;
  prefixIcon?: ReactNode;
  suffix?: string;
  suffixIcon?: ReactNode;
  placeholder?: string;
  onChange?: (value: T | undefined) => void;
  label?: string;
  value?: T;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  textarea?: boolean;
}

export const Input = ({
  error,
  prefix,
  prefixIcon,
  suffix,
  suffixIcon,
  placeholder,
  onChange,
  className,
  disabled,
  size = 'regular',
  'data-testid': dataTestid,
  value,
  onBlur,
  id,
  textarea = false,
  style
}: InputProps<string>) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(false);
  const [filled, setFilled] = useState(!!value);

  return (
    <div data-testid={dataTestid} className={clsx(className, s.input_container)} style={style}>
      <div className={clsx(s.field, { [s.is_small]: size === 'small' })}>
        <p
          className={clsx(s.control, {
            [s.has_icons_left]: !!prefixIcon,
            [s.has_icons_right]: !!suffixIcon,
            [s.is_small]: size === 'small'
          })}
          aria-disabled={disabled}
          data-haserror={!!error}
          data-focused={focused}
          data-filled={filled}
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          {prefixIcon && (
            <span className={clsx(s.icon, { [s.is_small]: size === 'small' }, s.is_left)}>
              <i>{prefixIcon}</i>
            </span>
          )}
          {prefix && (
            <span className={clsx(s.prefix, { [s.is_small]: size === 'small' })}>{prefix}</span>
          )}
          {textarea ? (
            <textarea
              id={id}
              ref={inputRef as LegacyRef<HTMLTextAreaElement>}
              className={s.input}
              rows={3}
              placeholder={placeholder}
              onChange={(event) => {
                setFilled(!!event.currentTarget.value);
                onChange && onChange(event.currentTarget.value);
              }}
              disabled={disabled}
              aria-errormessage={error}
              value={value}
              autoComplete="off"
              onBlur={(event) => {
                setFocused(false);
                onBlur && onBlur(event);
              }}
              onFocus={() => setFocused(true)}
            />
          ) : (
            <input
              id={id}
              ref={inputRef as LegacyRef<HTMLInputElement>}
              className={s.input}
              type="text"
              placeholder={placeholder}
              onChange={(event) => {
                setFilled(!!event.currentTarget.value);
                onChange && onChange(event.currentTarget.value);
              }}
              disabled={disabled}
              aria-errormessage={error}
              value={value}
              autoComplete="off"
              onBlur={(event) => {
                setFocused(false);
                onBlur && onBlur(event);
              }}
              onFocus={() => setFocused(true)}
            />
          )}
          {suffix && (
            <span className={clsx(s.suffix, { [s.is_small]: size === 'small' })}>{suffix}</span>
          )}
          {suffixIcon && (
            <span className={clsx(s.icon, { [s.is_small]: size === 'small' }, s.is_right)}>
              <i>{suffixIcon}</i>
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
