import { message } from 'antd';

export const logGraphQLError = ({
  graphQLErrors,
  networkError
}: {
  graphQLErrors: any;
  networkError: any;
}) => {
  graphQLErrors?.map((errorMsg: any) => message.error(`[GraphQL Error] ${errorMsg.message}`));
  networkError && message.error(`[Network Error] ${networkError.message}`);
};
