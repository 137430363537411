import React from 'react';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import { ViewRetailerChannel } from './ViewRetailerChannel/ViewRetailerChannel';
import { useQuery } from '@apollo/client';
import { VendorMarketDocument, VendorMarketQuery } from '../gql/__generated__/vendorMarket.query';

export type VendorMarketFullData = NonNullable<VendorMarketQuery['vendorMarket']>;
interface RetailerChannelViewDrawerProps {
  vendorMarketId?: string;
  onClose: () => void;
  startEditing: (vendorMarket: VendorMarketFullData) => void;
}

export const RetailerChannelViewDrawer = ({
  onClose,
  vendorMarketId,
  startEditing
}: RetailerChannelViewDrawerProps) => {
  const { data, loading } = useQuery(VendorMarketDocument, {
    variables: {
      id: vendorMarketId || ''
    },
    skip: !vendorMarketId
  });

  return (
    <EntityDrawer
      title={data?.vendorMarket?.name || data?.vendorMarket?.externalId || ''}
      open={!!vendorMarketId}
      onClose={onClose}
      viewMode={<ViewRetailerChannel vendorMarket={data?.vendorMarket} />}
      loading={!data?.vendorMarket || loading}
      startEditing={() => data?.vendorMarket && startEditing(data?.vendorMarket)}
    />
  );
};
