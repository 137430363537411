import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryRepackWeeklyTotalsReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryRepackFilters>;
}>;


export type InventoryRepackWeeklyTotalsReportQuery = { __typename?: 'RootQueryType', inventoryRepackWeeklyTotalsReport?: { __typename?: 'InventoryRepackWeeklyTotalsReport', metrics: { __typename?: 'InventoryRepackWeeklyTotalsReportMetrics', cuts: number, forecastFillRate: any, forecastedDemand: number, inInventoryFillRate: any, manualTransportationCosts?: any | null, poQuantity: number, projectedFillRate: any, projectedProduction: number, projectedTransportationCosts?: any | null, requiredProduction: number, revenueGain?: any | null, citiesFulfillQuantity?: Array<{ __typename?: 'CityFulfillQuantity', name: string, quantity?: number | null }> | null } } | null };


export const InventoryRepackWeeklyTotalsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryRepackWeeklyTotalsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryRepackFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryRepackWeeklyTotalsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"citiesFulfillQuantity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"quantity"}}]}},{"kind":"Field","name":{"kind":"Name","value":"cuts"}},{"kind":"Field","name":{"kind":"Name","value":"forecastFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"forecastedDemand"}},{"kind":"Field","name":{"kind":"Name","value":"inInventoryFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"manualTransportationCosts"}},{"kind":"Field","name":{"kind":"Name","value":"poQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"projectedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"projectedProduction"}},{"kind":"Field","name":{"kind":"Name","value":"projectedTransportationCosts"}},{"kind":"Field","name":{"kind":"Name","value":"requiredProduction"}},{"kind":"Field","name":{"kind":"Name","value":"revenueGain"}}]}}]}}]}}]} as unknown as DocumentNode<InventoryRepackWeeklyTotalsReportQuery, InventoryRepackWeeklyTotalsReportQueryVariables>;