import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryReconciliationMainTableQueryVariables = Types.Exact<{
  filters: Types.InventoryReconciliationFilters;
}>;


export type InventoryReconciliationMainTableQuery = { __typename?: 'RootQueryType', inventoryReconciliationMainTable?: { __typename?: 'InventoryReconciliationMainTable', metrics: Array<{ __typename?: 'InventoryReconciliationMainTableMetric', batchNumbers: Array<string>, baseUnitOfMeasure: string, daysOutOfSync: number, discrepancyCount: any, expirationDate?: any | null, isGrouped: boolean, name: string, plant: string, sapMaterialId: string, sapQuantity: any, unitsOfMeasure: Array<string>, gtins: Array<string>, variance: boolean, wmsQuantity: any, processDate: any }> } | null };


export const InventoryReconciliationMainTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryReconciliationMainTable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryReconciliationFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryReconciliationMainTable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"batchNumbers"}},{"kind":"Field","name":{"kind":"Name","value":"baseUnitOfMeasure"}},{"kind":"Field","name":{"kind":"Name","value":"daysOutOfSync"}},{"kind":"Field","name":{"kind":"Name","value":"discrepancyCount"}},{"kind":"Field","name":{"kind":"Name","value":"expirationDate"}},{"kind":"Field","name":{"kind":"Name","value":"isGrouped"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"plant"}},{"kind":"Field","name":{"kind":"Name","value":"sapMaterialId"}},{"kind":"Field","name":{"kind":"Name","value":"sapQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"unitsOfMeasure"}},{"kind":"Field","name":{"kind":"Name","value":"gtins"}},{"kind":"Field","name":{"kind":"Name","value":"variance"}},{"kind":"Field","name":{"kind":"Name","value":"wmsQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"processDate"}}]}}]}}]}}]} as unknown as DocumentNode<InventoryReconciliationMainTableQuery, InventoryReconciliationMainTableQueryVariables>;