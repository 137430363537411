import { EMPTY, NOT_AVAILABLE } from 'common/constants';
import { Currency } from 'graphql/__generated__/types';

//Abbreviation for stats tiles
export const abbreviate = (value: any) => {
  let number = parseFloat(value);
  if (number < 100000) {
    return { number: number.toFixed(0), unit: '' };
  } else if (number < 1000000) {
    return { number: (number / 1000000).toFixed(2), unit: 'MM' };
  } else if (number >= 100000000) {
    return { number: (number / 1000000).toFixed(0), unit: 'MM' };
  } else {
    return { number: (number / 1000000).toFixed(2), unit: 'MM' };
  }
};
//Pagination
export function paginate<T>(array: T[], page: number, size: number): T[] {
  const start = (page - 1) * size;
  const end = start + size;
  return array.slice(start, end);
}

//Formats currency of gross value - showed in tool tip
type MoneyValue = number | string | Currency | undefined;

export const formatMoney = (value: MoneyValue): string => {
  if (value === undefined) return NOT_AVAILABLE;
  let parsedValue: number;
  let units: string = 'USD';

  if (typeof value === 'object' && 'amount' in value) {
    parsedValue = parseFloat(value.amount);
    units = value.units;
  } else {
    parsedValue = parseFloat(value as string);
  }

  if (Number.isNaN(parsedValue)) return EMPTY;

  switch (units) {
    case 'USD':
      return `${parsedValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}`.replace(/^(\D+)/, '$1 ');
    case 'CAD':
      return `${parsedValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'CAD'
      })}`.replace(/^(\D+)/, '$1 ');
    case 'GBP':
      return `${parsedValue.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })} `.replace(/^(\D+)/, '$1 ');
    case 'PLN':
      return `${parsedValue.toLocaleString('pl-PL', {
        style: 'currency',
        currency: 'PLN'
      })} `.replace(/^(\D+)/, '$1 ');
    case 'EUR':
      return `${parsedValue.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'EUR'
      })} `.replace(/^(\D+)/, '$1 ');
    default:
      return `${parsedValue.toLocaleString()}`.replace(/^(\D+)/, '$1 ');
  }
};
//both abbreviates and formats currency of gross value -> this is what's shown in the gross value tile
export const abbreviateAndFormatGrossValue = (value: MoneyValue) => {
  if (!value || typeof value === 'undefined') {
    return NOT_AVAILABLE;
  }

  if (typeof value === 'object' && 'amount' in value) {
    const abbreviated = abbreviate(parseFloat(value.amount));
    if (isNaN(parseFloat(abbreviated.number))) {
      return NOT_AVAILABLE;
    }
    const formattedCurrency = formatMoney({ amount: abbreviated.number, units: value.units });
    return `${formattedCurrency}${abbreviated.unit}`;
  }

  return NOT_AVAILABLE;
};
//formats the demand number to have the commas and periods correctly
export const formatDemand = (value: number | string | undefined) => {
  if (value === undefined) return EMPTY;
  const parsedValue = parseFloat(value.toString());
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${parsedValue.toLocaleString()}`;
};
//combines the abbreviate and format for the demand -> this is what's shown in the demand tile
export const abbreviateAndFormatDemand = (value: string | number | undefined) => {
  if (value === undefined || value === '' || isNaN(parseFloat(value.toString()))) {
    return NOT_AVAILABLE;
  }
  const parsedValue = parseFloat(value.toString());
  const abbreviated = abbreviate(parsedValue);
  if (isNaN(parseFloat(abbreviated.number))) {
    return NOT_AVAILABLE;
  }
  const formattedNumber = formatDemand(abbreviated.number);
  return `${formattedNumber}${abbreviated.unit}`;
};
