/*
This component contains source code that will list all the scenarios created by Users in Inventory Optimization module.
User can view, search scenarios, refresh and delete scenarios created by self.
Two types of scenarios:
1. Base Network ( A scenario created by admin with Default constraints).
2. Custom Scenarios ( created by all other users).
*/
import {
  Tag,
  Input,
  Table,
  Dropdown,
  Pagination,
  message,
  Modal,
  Spin,
  Tooltip,
  Badge
} from 'antd';
import React, { useMemo, useState, useContext } from 'react';
import Button from 'antd/es/button';
import s from '../ScenarioList/ScenarioList.module.scss';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, SearchOutlined, SyncOutlined, MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GetScenarioListDataDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioList.query';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { RemoveScenariosDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/removeScenario.mutation';
import { RefreshScenarioDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/refreshScenario.mutation';
import { notEmpty } from 'common/helpers/notEmpty';
import { UserContext } from 'context/userContext';
import { DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE } from 'common/constants';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';

interface ScenarioListData {
  key: string;
  scenarioName: string | undefined;
  scenarioDescription: string | undefined;
  createdBy: string | undefined;
  createdOn: Date;
  totalTranspCost: number | null | undefined;
  holdingCost: number | null | undefined;
  wastage: number | null | undefined;
  status: string | undefined;
  isBase: boolean | null | undefined;
  userId: string;
}

message.config({
  top: 60,
  duration: 2
});

const ScenarioList = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<(string | number)[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const userData = useContext(UserContext);
  /* GraphQL query to get the scenarios created by users.
     Performs polling every 10 mins if any of the scenarios are "In Progress" status
  */
  const {
    data: scenarioData,
    loading: scenarioLoading,
    startPolling,
    stopPolling
  } = useQuery(GetScenarioListDataDocument, {
    onCompleted: (data) => {
      let scenarioData = data?.getScenariosList;
      let result = scenarioData?.some((arr) => {
        return arr?.status === 'In Progress';
      });
      if (!result) {
        stopPolling();
        !isDelete && isCompleted && message.success('Scenario generation completed successfully');
      } else {
        startPolling(600000);
        setIsCompleted(true);
      }
    }
  });

  // GraphQL mutation to delete the scenario/s created by users. It updates the status to Inactive
  const [removeScenarios, { data: deleteScenario, loading: deleteLoading }] = useMutation(
    RemoveScenariosDocument,
    {
      refetchQueries: [GetScenarioListDataDocument],
      onCompleted: () => {
        message.success(deleteScenario?.removeScenarios?.message);
        setIsDelete(true);
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );
  // GraphQL mutaiton to refresh the scenarios created by users.
  const [refreshScenarios, { data: refreshScenario, loading: refreshLoading }] = useMutation(
    RefreshScenarioDocument,
    {
      refetchQueries: [GetScenarioListDataDocument],
      onCompleted: () => {
        message.success('Scenario refreshed successfully');
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );

  // creating a table data as per the interface after getting scenario list from GraphQL query
  const scenarioTableData = useMemo(
    () =>
      scenarioData?.getScenariosList?.filter(notEmpty).map((scenario): ScenarioListData => {
        const createdOnDate = new Date(scenario.updatedAt);
        if (scenario.status === 'Infeasible') {
          return {
            key: scenario.id,
            scenarioName: scenario.scenarioName,
            scenarioDescription: scenario.description,
            createdBy: scenario.user.name,
            createdOn: createdOnDate,
            totalTranspCost: null,
            holdingCost: null,
            wastage: null,
            userId: scenario.user.id,
            status: scenario.status,
            isBase: scenario.base
          };
        } else {
          return {
            key: scenario.id,
            scenarioName: scenario.scenarioName,
            scenarioDescription: scenario.description,
            createdBy: scenario.user.name,
            createdOn: createdOnDate,
            totalTranspCost: scenario.scenarioResult?.transportationCost,
            holdingCost: scenario.scenarioResult?.holdingCost,
            wastage: scenario.scenarioResult?.wastageCost,
            userId: scenario.user.id,
            status: scenario.status,
            isBase: scenario.base
          };
        }
      }),
    [scenarioData]
  );

  // function to filter the scenarios to identify if it is a Base Network. If isBase is true, Scenario is a Base Network.
  const baseNetwork = useMemo(
    () => scenarioTableData?.filter((data) => data.isBase),
    [scenarioTableData]
  );
  // function to sort the scenarios to display the latest created Scenario on top.
  const customScenarios = useMemo(
    () =>
      scenarioTableData
        ?.filter((data) => !data?.isBase)
        .sort(function (a, b) {
          return new Date(b.createdOn ?? '').getTime() - new Date(a.createdOn ?? '').getTime();
        }),
    [scenarioTableData]
  );
  // Define, set table columns and  map data to display scenarios created by users
  const columns: ColumnsType<ScenarioListData> = [
    Table.SELECTION_COLUMN,
    {
      title: <span style={{ marginLeft: '8px' }}>SCENARIO NAME</span>,
      dataIndex: 'scenarioName',
      key: 'scenarioName',
      width: 90,
      fixed: 'left',
      render: (_, { key, scenarioName }) => (
        <Link to={`/inventory-optimization/scenario/${key}`} style={{ marginLeft: '8px' }}>
          {scenarioName}
        </Link>
      )
    },
    {
      title: 'SCENARIO DESCRIPTION',
      dataIndex: 'scenarioDescription',
      width: 90,
      key: 'scenarioDescription',
      render: (_, { scenarioDescription }) =>
        scenarioDescription && scenarioDescription?.length > 20 ? (
          <Tooltip placement="right" title={scenarioDescription}>
            <p className={s.scenarioDescription}>{scenarioDescription}</p>
          </Tooltip>
        ) : (
          `${scenarioDescription}`
        )
    },
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
      width: 80,
      key: 'CreatedBy',
      render: (_, { createdBy, isBase }) => {
        return (
          <div className={isBase ? s.scenarioBaseUser : s.scenarioCustomUser}>
            {isBase ? '-' : createdBy}
          </div>
        );
      }
    },
    {
      title: 'UPDATED',
      dataIndex: 'createdOn',
      width: 45,
      key: 'CreatedOn',
      render: (_, { createdOn }) => {
        return moment(createdOn).format('MM/DD/YY');
      }
    },
    {
      title: 'TOTAL TRANSP. COST',
      dataIndex: 'totalTranspCost',
      width: 80,
      key: 'totalTranspCost',
      render: (_, { totalTranspCost }) => {
        return (
          <div className={s.costFont}>
            {totalTranspCost && `$ ${totalTranspCost.toLocaleString('en-US')}`}
          </div>
        );
      }
    },
    {
      title: 'HOLDING COST',
      dataIndex: 'holdingCost',
      width: 70,
      key: 'holdingCost',
      render: (_, { holdingCost }) => {
        return (
          <div className={s.costFont}>
            {holdingCost && `$ ${holdingCost.toLocaleString('en-US')}`}
          </div>
        );
      }
    },
    {
      title: 'STRESSED INVENTORY VALUE',
      dataIndex: 'wastage',
      width: 60,
      key: 'wastage',
      render: (_, { wastage }) => {
        return (
          <div className={s.costFont}>{wastage && `$ ${wastage.toLocaleString('en-US')}`}</div>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: 60,
      key: 'status',
      render: (_, { status }) => {
        const color =
          status === 'Completed' ? 'green' : status === 'Infeasible' ? 'orange' : 'blue';
        return (
          <div className={s.scenarioStatus}>
            <Tag
              color={color}
              style={{
                border: 'none',
                background: 'none',
                fontWeight: 'bold',
                fontSize: '14px',
                paddingTop: '5px'
              }}
            >
              {status && status.length > 1 ? (
                <div>
                  <Badge status="success" style={{ width: '8px', height: '8px' }} color={color} />
                  {status}
                </div>
              ) : (
                status
              )}
            </Tag>
          </div>
        );
      }
    },
    {
      title: '',
      key: 'Actions',
      width: 20,
      fixed: 'right',
      render: (_, { key, userId, status }) => {
        return (
          <div>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'delete',
                    label: (
                      <span>
                        <DeleteOutlined style={{ marginRight: '8px' }} />
                        Delete
                      </span>
                    ),
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      handleDelete(key);
                    },
                    disabled:
                      (selectedRowsKeys.length > 1 && selectedRowsKeys.includes(key)) ||
                      baseNetwork?.some((object) => object.key === key) ||
                      userId !== userData.user?.id
                  },
                  {
                    key: 'Refresh',
                    label: (
                      <span>
                        <SyncOutlined style={{ marginRight: '8px' }} />
                        Refresh
                      </span>
                    ),
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      handleRefresh(key);
                    },
                    disabled: status === 'In Progress'
                  }
                ]
              }}
              placement="bottomRight"
              trigger={['click']}
            >
              <MoreOutlined
                style={{ height: '10px' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Dropdown>
          </div>
        );
      }
    }
  ];
  // function to set the search text to state and set the current page of Pagination to 1.
  const fnSearchScenarios = (searchStr: string) => {
    setSearchText(searchStr);
    setCurrentPage(1);
  };

  // function to filter the scenarios to based on search text. Filter scenarios by Name, Description, user created, status...
  const filteredData = customScenarios?.filter(
    (item: ScenarioListData) =>
      item?.scenarioName?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      item?.scenarioDescription?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdBy?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      item?.status?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdOn?.toLocaleDateString('en-US').includes(searchText) ||
      item?.totalTranspCost?.toString().includes(searchText) ||
      item?.holdingCost?.toString().includes(searchText) ||
      item?.wastage?.toString().includes(searchText)
  );

  const totalItems = filteredData?.length;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const paginatedData = filteredData?.slice(startIndex, endIndex);
  // function to set the page size and current page number for Pagination when user changes Page size in Pagination Dropdown
  const onShowSizeChange = (current: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };
  // function to set current page number for Pagination when user clicks arrow buttons in Pagination.
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onSelectChange = (newSelectedRowKeys: (string | number)[]) => {
    setSelectedRowsKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowsKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: ScenarioListData) => ({
      disabled:
        baseNetwork?.some((object) => object.key === record.key) ||
        record.userId !== userData.user?.id
    })
  };
  /* 
 function to handle the refresh of scenarios. 
 When user clicks on refresh action, this function calls and API to intiate re - run of sceanrio optimizer
 */
  const handleRefresh = (key: string) => {
    Modal.confirm({
      title: 'Are you sure to refresh the scenario?',
      content: "This action can't be undone.",
      onOk() {
        refreshScenarios({
          variables: {
            input: {
              scenarioIds: [key],
              refresh: true
            }
          }
        });
      },
      onCancel() {}
    });
  };
  /* 
 function to handle the delete of a scenario. 
 When user clicks on Delete action, this function calls and API to intiate inactive a sceanrio.
 */
  const handleDelete = (key: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete scenario ?',
      content: "This action can't be undone.",
      onOk() {
        if (key) {
          removeScenarios({ variables: { scenarioIds: [key] } });
          setSelectedRowsKeys([]);
        } else {
          return;
        }
      },
      onCancel() {}
    });
  };
  /* 
 function to handle the delete of multiple scenarios. 
 When user clicks on Delete action, this function calls and API to inactive sceanrios optimizer
 */
  const handleMultiPleDelete = async () => {
    selectedRowsKeys.length > 1 &&
      Modal.confirm({
        title: 'Are you sure to delete all selected Scenarios?',
        content: "This action can't be undone.",
        onOk() {
          if (selectedRowsKeys.length > 1) {
            removeScenarios({
              variables: { scenarioIds: selectedRowsKeys.map((val) => val.toString()) }
            });
            setSelectedRowsKeys([]);
          } else {
            return;
          }
        },
        onCancel() {}
      });
  };

  const tableData = useMemo(
    () => baseNetwork && paginatedData && [...baseNetwork, ...paginatedData],
    [baseNetwork, paginatedData]
  );

  return (
    <>
      {
        <div className={s.customTableContainer}>
          <div className={s.scene}>
            <PageHeader data-testid="inv-scenarioList-title">Inventory Optimization</PageHeader>
            <Link
              data-testid="inv-scenarioList-scenarioButton"
              to={'/inventory-optimization/create-scenario'}
            >
              <Button type="primary" className={s.invScenarioPlanningButton}>
                Create Scenario
              </Button>
            </Link>
          </div>
          <div className={s.listSceneriosHeader}>
            <div className={s.listSceneriosSearch}>
              <div className={s.totalScenarios} data-testid="inv-scenarioList-count">
                {totalItems} Total Scenarios
              </div>
              <Input
                data-testid="inv-scenarioList-search"
                placeholder="Search Scenario"
                className={s.scenarioSearch}
                prefix={<SearchOutlined width="14px" height="14px" />}
                onChange={(e) => fnSearchScenarios(e.target.value)}
              />
            </div>
            {(deleteLoading || refreshLoading) && <Spin />}
            <div className={s.scenarioPagination}>
              <div className={s.scenarioDeleteAll}>
                <Button
                  data-testid="inv-scenarioList-deleteAllScenario"
                  className={selectedRowsKeys.length > 1 ? s.multiDelete : s.deleteInactive}
                  onClick={handleMultiPleDelete}
                >
                  <DeleteOutlined /> Delete
                </Button>
              </div>
              <Pagination
                data-testid="inv-scenarioList-pagination"
                className={s.pagination}
                current={currentPage}
                total={totalItems}
                pageSize={pageSize}
                showLessItems={true}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
              />
            </div>
          </div>
          <Table
            loading={scenarioLoading}
            style={{ border: '2px solid #E8E8E8' }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            sticky={{
              offsetScroll: 15
            }}
          />
        </div>
      }
    </>
  );
};

export default ScenarioList;
