import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { FeFeatureFlagsDocument } from './gql/__generated__/feFeatureFlags.query';
import { FeFeatureFlag, FeFeatureFlagInput } from 'graphql/__generated__/types';
import { isFeatureFlagOverrideEnabled } from './featureFlagOverrides';
import { isE2ETestSession } from 'common/helpers/identificateEnvironment';

export const useFeatureFlag = (
  flag: FeFeatureFlagInput
): { loading: boolean; enabled: boolean | undefined } => {
  // We use useFeatureFlags for an implementation, just limit it to 1 parameter.
  const { loading, featureFlags } = useFeatureFlags([flag]);

  return {
    loading,
    enabled: featureFlags[0].enabled
  };
};

export const useFeatureFlags = (
  flags: FeFeatureFlagInput[]
): { loading: boolean; featureFlags: FeFeatureFlag[] } => {
  const { loading, data } = useQuery(FeFeatureFlagsDocument, {
    variables: {
      flags: flags
    }
  });

  const featureFlags = useMemo(() => {
    if (data) return data.feFeatureFlags;
    // While loading, return all values as false, since otherwise destructuring will fail
    return flags.map((x) => ({ name: x.name, enabled: false }));
  }, [data, flags]);

  // this works ONLY for cypress (for now);
  const featureFlagsWithOverrides = useMemo(() => {
    if (!isE2ETestSession()) return featureFlags;
    // Notice that FF overrides could DISABLE feature flags too.
    return featureFlags.map(({ name, enabled }) => ({
      name,
      enabled: isFeatureFlagOverrideEnabled(name) ?? enabled
    }));
  }, [featureFlags]);

  return { loading, featureFlags: featureFlagsWithOverrides };
};
