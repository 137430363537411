import { isProductionBackend } from 'common/helpers/identificateEnvironment';
import { useBuildDetails } from 'common/helpers/buildDetails';
import { UserContext } from 'context/userContext';
import { useContext } from 'react';

export function useBuildDetailsShown() {
  const build = useBuildDetails();
  const user = useContext(UserContext);

  return !(
    !build.beEnv ||
    build.beEnv === 'Production' ||
    isProductionBackend() ||
    !user.user?.id ||
    import.meta.env.REACT_APP_HIDE_BUILD_DETAILS
  );
}
