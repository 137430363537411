export const setTempItem = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getTempItem = (key: string) => {
  return JSON.parse(sessionStorage.getItem(key) as string);
};

export const removeTempItem = (key: string) => {
  sessionStorage.removeItem(key);
};
