import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetSalesOrdersForExportQueryVariables = Types.Exact<{
  purchaseOrderIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type GetSalesOrdersForExportQuery = { __typename?: 'RootQueryType', soExportItems?: { __typename?: 'SoExportItems', items: Array<{ __typename?: 'SoExportItem', allocatedQty?: number | null, currency?: string | null, customerPo?: string | null, dcCode?: string | null, description?: string | null, externalId?: string | null, id: string, line?: string | null, price?: any | null, rdd?: any | null, sapMaterialId?: string | null, shipTo?: string | null, soldTo?: string | null, truck?: string | null, upc?: string | null, weight?: string | null }> } | null };


export const GetSalesOrdersForExportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSalesOrdersForExport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"soExportItems"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"purchaseOrderIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allocatedQty"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}},{"kind":"Field","name":{"kind":"Name","value":"customerPo"}},{"kind":"Field","name":{"kind":"Name","value":"dcCode"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"line"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"rdd"}},{"kind":"Field","name":{"kind":"Name","value":"sapMaterialId"}},{"kind":"Field","name":{"kind":"Name","value":"shipTo"}},{"kind":"Field","name":{"kind":"Name","value":"soldTo"}},{"kind":"Field","name":{"kind":"Name","value":"truck"}},{"kind":"Field","name":{"kind":"Name","value":"upc"}},{"kind":"Field","name":{"kind":"Name","value":"weight"}}]}}]}}]}}]} as unknown as DocumentNode<GetSalesOrdersForExportQuery, GetSalesOrdersForExportQueryVariables>;