import React, { useRef, useState } from 'react';
import s from './InputNumber.module.scss';
import clsx from 'clsx';
import RcInputNumber from 'rc-input-number';
import { InputProps } from '../Input/Input';

export const InputNumber = ({
  error,
  placeholder,
  onChange,
  className,
  disabled,
  size = 'regular',
  'data-testid': dataTestid,
  value,
  onBlur,
  id,
  style,
  min,
  max
}: Omit<InputProps<number>, 'prefix' | 'prefixIcon' | 'suffix' | 'suffixIcon'> & {
  min?: number;
  max?: number;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [filled, setFilled] = useState(!!value);

  return (
    <RcInputNumber<number>
      id={id}
      ref={inputRef}
      type="number"
      min={min}
      max={max}
      style={style}
      data-testid={dataTestid}
      placeholder={placeholder}
      onChange={(value) => {
        setFilled(value !== null);
        onChange && onChange(value ?? undefined);
      }}
      disabled={disabled}
      aria-errormessage={error}
      value={value}
      autoComplete="off"
      onBlur={(event) => {
        onBlur && onBlur(event);
      }}
      className={clsx(s.input_number, className, { [s.is_small]: size === 'small' })}
      aria-disabled={disabled}
      data-haserror={!!error}
      data-filled={filled}
    />
  );
};
