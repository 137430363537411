import { isSundayToday } from 'common/helpers/fiscalCalendar';

export const getReferenceDate = () => {
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // If past-mode only, we need to set previous day's period, since otherwise it will be empty.
  // This is a hacky way for past-due, we might rewrite it in future
  return isSundayToday() ? yesterday : today;
};
