import moment from 'moment';

export function dateFormat(date: string | null | undefined, mask?: string) {
  if (!date) return '';
  const mDate = moment(date);
  if (!mDate.isValid()) return '';
  return mDate.format(mask || 'M/D/YY');
}

/**
 * Compares two date strings using the Moment.js library, treating null, or invalid dates as earlier than any valid date.
 * Undefineds are NOT accepted because they will be sorted to be last always (it seems).
 */
export function compareDates(a: string | null, b: string | null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  const aDate = moment(a);
  const bDate = moment(b);

  if (!aDate.isValid() && !bDate.isValid()) return 0;
  if (!aDate.isValid()) return -1;
  if (!bDate.isValid()) return 1;

  if (aDate.isSame(bDate)) return 0;
  return aDate.isBefore(bDate) ? -1 : 1;
}

// Backend sometimes will send us 1900 as a date. We should interpret this as a "no value".
export function setDateToNullIf1900(date?: string) {
  return date === '1900-01-01' ? null : date;
}
