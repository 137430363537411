import { removeItem } from 'common/services/persistentStorageServices';

export const logout = async () => {
  removeItem('user');
  await fetch(`${import.meta.env.REACT_APP_API_URL}/sessions`, {
    method: 'DELETE',
    credentials: 'include',
    cache: 'no-cache'
  });
  window.location.href = '/login';
};
