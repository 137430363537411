/**
 * Read https://pepsico-ecomm.atlassian.net/wiki/spaces/ECOMM/pages/1015813/PepsiCo+Financial+Calendar for more details
 */
import { range } from 'lodash-es';
import moment from 'moment';

export interface FiscalCalendarInfo {
  year: number;
  quarter: number;
  period: number;
  week: number;
  day: number;
  periodWeek: number;
}

function getPeriodFromWeek(week: number) {
  return week < 53 ? Math.ceil(week / 4) : 13;
}

function getPeriodWeekFromWeek(week: number) {
  return week === 53 ? 5 : ((week - 1) % 4) + 1;
}

// Don't want to depend on Moment.js
function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getInfoFromDay(day: number) {
  const week = Math.ceil(day / 7);
  const period = getPeriodFromWeek(week);
  const periodWeek = getPeriodWeekFromWeek(week);
  const quarter = period < 13 ? Math.ceil(period / 3) : 4;

  // We use this for our calculations (to display things such as P1W1 P13W5)
  return { week, period, quarter, periodWeek };
}

export function getFinancialInfo(date: Date): FiscalCalendarInfo {
  const endOfYear = getFinancialYearEnd(date.getFullYear());

  // If the date is after the end of the financial year calculated above, it's actually contained in the next financial year
  let year = date.getFullYear();
  if (date.getMonth() === 11 && date.getDate() > endOfYear.getDate()) {
    year = year + 1;
  }

  const startOfYear = getFinancialYearStart(year);

  const day = getDiffInDays(date, startOfYear) + 1;

  return {
    year,
    day,
    ...getInfoFromDay(day)
  };
}

function getDiffInDays(startDate: Date, endDate: Date): number {
  // We rely on moment.js calculation because we don't want to take into account daylight savings/set start of date easily.
  // TODO: it would be nice to get rid of it. Just in case. :)
  return moment(startDate).startOf('day').diff(moment(endDate).startOf('day'), 'days');
}

// Financial year start = last Sunday of prev year.
function getFinancialYearStart(year: number): Date {
  const financialYearEnd = getFinancialYearEnd(year - 1);
  return addDays(financialYearEnd, 1);
}

// Financial year end = last Saturday of year.
function getFinancialYearEnd(year: number): Date {
  // Start at the end of the civil year which is always Dec 31
  const endOfYear = new Date(year, 11, 31);
  const dayOfWeek = endOfYear.getDay();

  // The  ISO day of week is Mon = 1...Sun = 7; the number of days
  // to go back in time is the following table:
  //   1 -> 2
  //   2 -> 3
  //   3 -> 4
  //   4 -> 5
  //   5 -> 6
  //   6 -> 0 (already at Saturday)
  //   7 -> 1
  const delta = (dayOfWeek + 1) % 7;

  return addDays(endOfYear, -delta);
}

export const isSundayToday = () => new Date().getDay() === 0;

export const isMOPDayToday = () => new Date().getDay() === 1;

export function convertWeekToPeriodWeek(week: number) {
  if (week < 1 || week > 53) throw new Error("Week can't be less than 1 or more than 53");
  const period = getPeriodFromWeek(week);
  const periodWeek = getPeriodWeekFromWeek(week);
  return { period, periodWeek, week };
}

export function convertWeekToPeriodWeekString(week: number) {
  const { period, periodWeek } = convertWeekToPeriodWeek(week);
  return `P${period}W${periodWeek}`;
}

export function parsePeriodWeekString(periodWeekString: string): {
  period: number;
  periodWeek: number;
} {
  const [period, periodWeek] = periodWeekString
    .substring(1)
    .toLowerCase()
    .split('w')
    .map((x) => parseInt(x));
  if (period < 1 || period > 13) throw new Error("Period can't be less than 1 or more than 13");
  if (periodWeek < 1 || periodWeek > 5) throw new Error("Week can't be less than 1 or more than 5");
  if (periodWeek === 5 && period !== 13) throw new Error('Week 5 can be only in period 13');
  return { period, periodWeek };
}

export function convertPeriodWeekToWeek(period: number, periodWeek: number) {
  if (period < 1 || period > 13) throw new Error("Period can't be less than 1 or more than 13");
  if (periodWeek < 1 || periodWeek > 5) throw new Error("Week can't be less than 1 or more than 5");
  if (periodWeek === 5 && period !== 13) throw new Error('Week 5 can be only in period 13');

  return (period - 1) * 4 + periodWeek;
}

export function getWeeksInFinancialYear(year: number) {
  const { week } = getFinancialInfo(getFinancialYearEnd(year));
  return week;
}

/**
 * Function which gets borders (start-end dates) for a fiscal week
 * @param week Fiscal week to find borders
 * @param year Fiscal year of mentioned week
 * @returns Start and end of needed period
 */
export function getFiscalWeekBorders(week: number, year: number): { start: Date; end: Date } {
  // start and end of P1W1
  const firstWeekStart = getFinancialYearStart(year);
  const firstWeekEnd = addDays(firstWeekStart, 6);

  // Add some days to week
  const delta = (week - 1) * 7;
  return { start: addDays(firstWeekStart, delta), end: addDays(firstWeekEnd, delta) };
}

export function getWeeksForPeriod(period: number): number[] {
  if (period < 1 || period > 13) throw new Error("Period can't be less than 1 or more than 13");
  // Always returns 5 weeks for 13 period
  if (period === 13) return range(49, 54);
  const start = (period - 1) * 4 + 1;
  const end = period * 4 + 1;
  return range(start, end);
}
