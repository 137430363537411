import React, { useState, useEffect } from 'react';
import s from './PurchaseOrderItemsTablesECOMM.module.scss';
import warningIcon from '../../../../assets/icons/common/error_icon.svg';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { InvalidPurchaseOrderItem, TradingPartnerActiveAssortmentItem } from 'common/interfaces';
import {
  PurchaseOrderItem,
  PurchaseOrderWithOverdue,
  SalesOrder,
  SalesOrderItem
} from 'pages/OrderDetailsPage/types';
import { InvalidItemsTableECOMM } from '../InvalidItemsTableECOMM/InvalidItemsTableECOMM';
import { POI_WARNING_MAP } from 'common/constants';
import { PurchaseOrderItemWarning } from 'graphql/__generated__/types';

interface PurchaseOrderItemsTablesECOMMProps {
  purchaseOrder: PurchaseOrderWithOverdue;
  purchaseOrderItems: PurchaseOrderItem[];
  salesOrders: SalesOrder[];
  openNewItemModal: (
    item?: Partial<TradingPartnerActiveAssortmentItem>,
    predefinedValues?: Partial<TradingPartnerActiveAssortmentItem>
  ) => void;
  loading: boolean;
}

type Bundle = Pick<PurchaseOrderItemData, 'externalId' | 'description' | 'quantity'>;

interface PurchaseOrderItemData {
  externalId: string;
  description: string;
  quantity?: number | null;
  purchaseOrderItemErrors: string[];
  bundleContent: Bundle[];
  purchaseOrderItemWarnings: PurchaseOrderItemWarning[];
}

export const PurchaseOrderItemsTablesECOMM = ({
  purchaseOrder,
  purchaseOrderItems,
  salesOrders,
  openNewItemModal,
  loading
}: PurchaseOrderItemsTablesECOMMProps) => {
  const [allInvalidItems, setAllInvalidItems] = useState<InvalidPurchaseOrderItem[]>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const { Summary } = AlloyTable;

  const findSalesOrderItems = (externalId: string): SalesOrderItem[] => {
    const soItems: SalesOrderItem[] = [];
    salesOrders.forEach((so) => {
      so.salesOrderItems.forEach((soItem) => {
        if (soItem.bundleVendorProduct && soItem.bundleVendorProduct.externalId === externalId)
          soItems.push(soItem);
      });
    });
    return soItems;
  };

  const invalidItems = purchaseOrderItems.filter(
    (item) => item.purchaseOrderItemErrors && item.purchaseOrderItemErrors.length > 0
  );

  const data: PurchaseOrderItemData[] = purchaseOrderItems
    .filter((item) => !invalidItems.includes(item))
    .map((poItem) => {
      const soItems = findSalesOrderItems(poItem.vendorProduct?.externalId || '');
      return {
        externalId: poItem.product?.upc || poItem.externalId || '',
        description: poItem.product?.name || poItem.productName || '',
        quantity: poItem.quantityOrdered,
        purchaseOrderItemErrors: poItem.purchaseOrderItemErrors,
        bundleContent:
          soItems.length > 0
            ? soItems.map((soItem) => ({
                externalId: soItem?.product?.upc || '',
                description: soItem?.product?.name || '',
                quantity: soItem.quantityOrdered
              }))
            : [],
        purchaseOrderItemWarnings: poItem.purchaseOrderItemWarnings
      };
    });

  useEffect(() => {
    setAllInvalidItems(
      invalidItems.map((poItem) => ({
        description:
          poItem?.vendorProduct?.substitutionProducts[0]?.product?.name || poItem.productName || '',
        purchaseOrderExternalId: purchaseOrder.externalId,
        purchaseOrderItemExternalId: poItem.externalId,
        quantity: poItem.quantityOrdered,
        shipTo: '',
        purchaseOrderItemErrors: poItem.purchaseOrderItemErrors,
        purchaseOrderItemWarnings: poItem.purchaseOrderItemWarnings
      }))
    );
  }, [purchaseOrder]);

  const columns: ColumnsType<PurchaseOrderItemData> = [
    {
      dataIndex: 'productWarning',
      width: '44px',
      render: (_, item) => (
        <>
          {!!item.purchaseOrderItemWarnings.length && (
            <div data-testid="warning-display">
              <AlloyTooltip
                data-testid="warning-display-tooltip"
                placement="right"
                title={item.purchaseOrderItemWarnings
                  .map((warning) => POI_WARNING_MAP[warning] || warning)
                  .join(', ')}
              >
                <img data-testid="warning-icon" src={warningIcon} alt="" />
              </AlloyTooltip>
            </div>
          )}
        </>
      )
    },
    {
      title: 'External Id',
      dataIndex: 'externalId',
      width: '280px'
    },
    {
      title: 'Item Description',
      dataIndex: 'description'
    },
    {
      title: 'Order Qty',
      dataIndex: 'quantity',
      width: '120px'
    },
    AlloyTable.EXPAND_COLUMN
  ];

  const expandedColumns: ColumnsType<Bundle> = [
    {
      width: '44px'
    },
    {
      title: 'External Id',
      width: '200px',
      dataIndex: 'externalId'
    },
    {
      title: 'Item Description',
      dataIndex: 'description'
    },
    {
      title: 'Order Qty',
      dataIndex: 'quantity',
      width: '120px'
    },
    {
      width: '49px'
    }
  ];

  const hasData = data && data.length > 0;

  return (
    <div>
      {allInvalidItems && allInvalidItems.length ? (
        <>
          <div data-testid="invalid-items-count-ecomm" className={s.quantity_display}>
            INVALID ITEMS ({invalidItems.length})
          </div>
          <InvalidItemsTableECOMM
            items={allInvalidItems}
            openNewItemModal={openNewItemModal}
            purchaseOrder={purchaseOrder}
            loading={loading}
          />
          <div style={{ marginTop: '26px' }}></div>
        </>
      ) : null}

      {hasData ? (
        <>
          <div data-testid="po-items-count-ecomm" className={s.quantity_display}>
            PO ITEMS ({data.length})
          </div>
          <AlloyTable
            data-testid="po-items-table-ecomm"
            className={s.table}
            loading={loading}
            dataSource={data}
            columns={columns}
            rowKey={(item) => item.externalId}
            expandable={{
              indentSize: 0,
              expandedRowRender: (item) => (
                <AlloyTable
                  data-testid="poi-expandable-row-table-ecomm"
                  loading={loading}
                  dataSource={item.bundleContent}
                  columns={expandedColumns}
                  rowKey="externalId" //TODO: find out what is better for rowKey value
                  pagination={false}
                  showHeader={false}
                  bordered={false}
                  summary={() => {
                    return (
                      <Summary.Row className={s.inner_total_row}>
                        <Summary.Cell index={0} colSpan={3} align="right">
                          Total
                        </Summary.Cell>
                        <Summary.Cell index={1} colSpan={4}>
                          <div>
                            {item.bundleContent.reduceRight(
                              (prev, current) => prev + (current.quantity || 0),
                              0
                            )}
                          </div>
                        </Summary.Cell>
                      </Summary.Row>
                    );
                  }}
                />
              ),
              rowExpandable: (item) => item.bundleContent && item.bundleContent.length > 0,
              onExpandedRowsChange: (expandedKeys) =>
                setExpandedRows(expandedKeys.map((key) => key.toLocaleString())),
              expandedRowClassName: () => s.expandable
            }}
            rowClassName={(item) =>
              item.bundleContent?.length > 0
                ? expandedRows.includes(item.externalId)
                  ? s.expanded_row
                  : s.expandable_row
                : ''
            }
            sticky
            tableLayout="auto"
            pagination={false}
            summary={() => {
              return (
                <Summary.Row className={s.total_row}>
                  <Summary.Cell className={s.bold} index={0} colSpan={3} align="right">
                    Total
                  </Summary.Cell>
                  <Summary.Cell className={s.bold} index={1} colSpan={4} align="left">
                    <div>
                      {data.reduceRight((prev, current) => prev + (current.quantity || 0), 0)}
                    </div>
                  </Summary.Cell>
                </Summary.Row>
              );
            }}
          />
        </>
      ) : null}
    </div>
  );
};
