import React, { useMemo, useRef } from 'react';
import s from './OldCutsGraph.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { CutOrdersFilters } from 'graphql/__generated__/types';
import { useQuery } from '@apollo/client';
import { EMPTY } from 'common/constants';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { CutOrdersPercentagesDocument } from '../gql/__generated__/cutOrdersPercentages.query';
import { safeNumberComparator } from 'common/helpers/comparators';
import { stringifyCalendarValue } from 'components/PeriodCalendar/helpers';
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer } from 'recharts';
import { CUTS_BY_REASON_CATEGORY, DEFAULT_CATEGORY_TAB } from '../../../types';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';

const formatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  return value.toFixed(1) + '%';
};

const safeParseFloat = (n: number | string | undefined) => {
  return (typeof n === 'string' ? parseFloat(n) : n) || 0;
};

const MIN_ANGLE_TOTAL_CUT = 36; // 10% of graph, smaller is absolutely unreadable. Maybe we should increase it?
const MIN_ANGLE_SINGLE_CUT = MIN_ANGLE_TOTAL_CUT / 5; // ~36/5

type ChartData = [{ name: string; value: number }];

export const OldCutsGraph = ({
  filters,
  period
}: {
  filters: CutOrdersFilters;
  period: CalendarValue;
}) => {
  const [cutsByCategory, setCutsByCategory] = useQueryParam(
    CUTS_BY_REASON_CATEGORY,
    withDefault(StringParam, DEFAULT_CATEGORY_TAB)
  );

  // TODO: actually send BUs here
  const { loading, data } = useQuery(CutOrdersPercentagesDocument, {
    variables: { filters: { fiscalCalendarWeeks: filters.fiscalCalendarWeeks } }
  });

  const totals = useMemo(() => {
    const totalCuts = safeParseFloat(data?.cutOrdersPercentages[0].totalCutsPercent) * 100;

    const minCutsSlice = Math.max(MIN_ANGLE_TOTAL_CUT / 360, totalCuts / 100) * 100;
    const fillSlice = 100 - minCutsSlice;
    const firstSliceAngle = Math.max(MIN_ANGLE_TOTAL_CUT, (totalCuts / 100) * 360);
    const endAngle = -0.25;
    const startAngle = endAngle + firstSliceAngle + 0.5;

    return {
      startAngle,
      endAngle,
      firstSliceAngle,
      data: [
        { name: 'Cuts', value: minCutsSlice, actualValue: totalCuts },
        { name: 'Fulfilled', value: fillSlice, actualValue: 100 - totalCuts }
      ]
    };
  }, [data]);

  const cuts = useMemo(() => {
    const cuts = data?.cutOrdersPercentages[0];
    return {
      data: [
        { name: 'Customer', value: safeParseFloat(cuts?.customerCutsPercent) * 100 },
        { name: 'Planning', value: safeParseFloat(cuts?.planningCutsPercent) * 100 },
        { name: 'Warehouse', value: safeParseFloat(cuts?.warehouseCutsPercent) * 100 },
        { name: 'Transport', value: safeParseFloat(cuts?.transportCutsPercent) * 100 },
        { name: 'Uncategorized', value: safeParseFloat(cuts?.uncategorizedCutsPercent) * 100 }
      ]
    };
  }, [data]);

  const areMultipleYearsSelected = period.years.length > 1;
  const years = useMemo(
    () => [...period.years].sort(safeNumberComparator).join(', ') + ' ',
    [period]
  );
  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>
        Cuts {`(${areMultipleYearsSelected ? years : ''}${stringifyCalendarValue(period)})`}
      </h2>
      <AlloySpin spinning={loading}>
        <div style={{ width: 500, height: 500 }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={500} height={500}>
              {/* Represents other part of a circle, so "uncut" */}
              <Pie
                data={totals.data}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={130}
                isAnimationActive={false}
                minAngle={0}
                label={(props) => renderCustomizedLabelForCuts({ ...props, data: totals.data })}
                labelLine={false}
                strokeWidth={0}
              >
                {totals.data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={index === 0 ? '#5D99EB' : '#162F80'} />
                ))}
              </Pie>
              <Pie
                data={cuts.data}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={250}
                innerRadius={132}
                isAnimationActive={false}
                startAngle={totals.startAngle}
                endAngle={totals.endAngle}
                label={(props) =>
                  renderCustomizedLabelForCutDetails({ ...props, data: cuts.data, cutsByCategory })
                }
                labelLine={false}
                strokeWidth={2}
                minAngle={MIN_ANGLE_SINGLE_CUT}
              >
                {cuts.data.map(({ name }, index) => {
                  const isSelected = name.toLowerCase() === cutsByCategory;
                  return (
                    <Cell
                      key={`cell-${index}-${name}`}
                      fill="#78E2EC"
                      opacity={isSelected ? '1' : '0.3'}
                      onClick={() => setCutsByCategory(name.toLowerCase())}
                      cursor={isSelected ? 'default' : 'pointer'}
                    />
                  );
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </AlloySpin>
    </div>
  );
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabelForCuts = ({
  cx: rawCx,
  cy: rawCy,
  midAngle: rawMidAngle,
  innerRadius: rawInnerRadius,
  outerRadius: rawOuterRadius,
  name,
  index: rawIndex,
  data
}: PieLabelRenderProps & {
  data: [
    {
      name: string;
      value: number;
      actualValue: number;
    }
  ];
}) => {
  if (name.toLowerCase() === '') return <></>;
  const cx = safeParseFloat(rawCx);
  const cy = safeParseFloat(rawCy);
  const innerRadius = safeParseFloat(rawInnerRadius);
  const outerRadius = safeParseFloat(rawOuterRadius);
  const midAngle = safeParseFloat(rawMidAngle);
  const index = safeParseFloat(rawIndex);

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN) - radius * 0.15;

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
      <tspan x={x} dy="0" className={s.cutsLabel}>
        {name}
      </tspan>
      <tspan x={x} dy="24px" className={s.cutsPercentage}>
        {formatPercentage(data[index].actualValue)}
      </tspan>
    </text>
  );
};

const renderCustomizedLabelForCutDetails = ({
  cx: rawCx,
  cy: rawCy,
  midAngle: rawMidAngle,
  innerRadius: rawInnerRadius,
  outerRadius: rawOuterRadius,
  name,
  index: rawIndex,
  data,
  cutsByCategory
}: PieLabelRenderProps & { data: ChartData; cutsByCategory: string }) => {
  if (name.toLowerCase() === '') return <></>;

  const isSelected = name.toLowerCase() === cutsByCategory;

  const cx = safeParseFloat(rawCx);
  const cy = safeParseFloat(rawCy);
  const innerRadius = safeParseFloat(rawInnerRadius);
  const outerRadius = safeParseFloat(rawOuterRadius);
  const midAngle = safeParseFloat(rawMidAngle);
  const index = safeParseFloat(rawIndex);

  if (data[index].value === 0) return <></>;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ opacity: isSelected ? '1' : '0.3', pointerEvents: 'none' }}
    >
      <tspan x={x} dy="0" className={s.cutsDetailsLabel}>
        {name} {formatPercentage(data[index].value)}
      </tspan>
    </text>
  );
};
