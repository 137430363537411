import clsx from 'clsx';
import React, { ReactNode } from 'react';
import s from './ViewRow.module.scss';

export const ViewRow = ({
  title,
  content,
  bordered
}: {
  title: string | ReactNode;
  content: JSX.Element;
  bordered: boolean;
}) => (
  <div className={clsx(s.view_row, { [s.bordered]: bordered })}>
    <div className={s.row_title}>{title}</div>
    <div className={s.content_container}>{content}</div>
  </div>
);
