import { useQuery } from '@apollo/client';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import { SearchField } from 'components/Common/SearchField/SearchField';
import { Paginator } from 'components/Paginator/Paginator';
import {
  SortOrderDirection,
  UpsertVendorMarketInput,
  VendorMarketSortColumn
} from 'graphql/__generated__/types';
import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { VendorMarketsDocument, VendorMarketsQuery } from './gql/__generated__/vendorMarkets.query';
import s from './RetailerChannelsPage.module.scss';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { RetailerChannelViewDrawer } from './components/RetailerChannelViewDrawer';
import { EditRetailerChannelModal } from './components/EditRetailerChannel/EditRetailerChannelModal';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';

export type VendorMarket = InferNodeType<VendorMarketsQuery, 'vendorMarkets'>;

export const RetailerChannelsPage = () => {
  const [retailerChannelId, setRetailerChannelId] = useQueryParam('dc', StringParam);
  const [pageSize, setPageSize] = useQueryParam('limit', NumberParam);
  const [sortColumn, setSortColumn] = useQueryParam('column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order', StringParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);

  const [editingVendorMarket, setEditingVendorMarket] = useState<UpsertVendorMarketInput>();
  const [viewChangeHistoryVendorMarket, setViewHistoryVendorMarket] = useState<VendorMarket>();

  const { data, loading, error } = useQuery(VendorMarketsDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as VendorMarketSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      after,
      before,
      searchLike: search
    },

    skip: !pageSize || !sortColumn || !sortOrder
  });

  const vendorMarketsList = useMemo(() => getNodesFromEdges(data?.vendorMarkets), [data]);

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(DEFAULT_PAGE_SIZE, 'replace');
      setSortColumn('UPDATED_AT', 'replaceIn');
      setSortOrder('DESC', 'replaceIn');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  useEffect(() => {
    if (retailerChannelId) {
      setViewHistoryVendorMarket(undefined);
      if (editingVendorMarket && !editingVendorMarket.id) {
        setEditingVendorMarket(undefined);
      }
    }
  }, [editingVendorMarket, retailerChannelId]);

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== (value || undefined)) {
        setSearch(value || undefined);
        setAfter(undefined);
        setBefore(undefined);
      }
    },
    [search, setAfter, setBefore, setSearch]
  );

  if (error) return <p>Error</p>;

  const columns: ColumnsType<VendorMarket> = [
    {
      title: 'External ID',
      render: (_, record) => <HighlightedText text={record.externalId} highlight={search ?? ''} />,
      key: 'EXTERNAL_ID'
    },
    {
      title: 'Name',
      render: (_, record) => <HighlightedText text={record.name} highlight={search ?? ''} />,
      key: 'NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'NAME'),
      sorter: true
    },
    {
      title: 'Retailer',
      render: (_, record) => (
        <HighlightedText text={record.vendor?.name || ''} highlight={search ?? ''} />
      ),
      key: 'VENDOR_NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'VENDOR_NAME'),
      sorter: true
    },
    {
      title: 'Country Code',
      width: 140,
      render: (_, record) => <HighlightedText text={record.countryCode} highlight={search ?? ''} />
    },
    {
      title: 'Currency Code',
      width: 140,
      render: (_, record) => <HighlightedText text={record.currencyCode} highlight={search ?? ''} />
    },
    {
      title: 'Last updated',
      width: 160,
      render: (_, record) => (
        <UpdatedAt
          onClick={(e) => {
            setViewHistoryVendorMarket(record);
            e.stopPropagation();
          }}
          updatedAt={record.updatedAt}
        />
      ),
      key: 'UPDATED_AT',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'UPDATED_AT'),
      sorter: true
    }
  ];

  const nextPage = async () => {
    setAfter(data?.vendorMarkets?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.vendorMarkets?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  return (
    <div className={s.page}>
      <div className={s.header}>
        <PageHeader className={s.trading_partners_title}>Retailer Channels</PageHeader>
        <div>
          <AlloyButton
            onClick={() => {
              setEditingVendorMarket({} as UpsertVendorMarketInput);
            }}
          >
            Add new Retailer Channel
          </AlloyButton>
        </div>
      </div>
      <SearchField handleSearch={handleSearch} loading={loading} value={search ?? ''} />
      <div className={s.pagination_container}>
        <QuantityDisplay
          count={data?.vendorMarkets?.totalCount}
          titleSingular="Retailer Channel"
          titleMultiple="Retailer Channels"
        />
        <Paginator
          hasNextPage={!!data?.vendorMarkets?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.vendorMarkets?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={false}
        />
      </div>
      <AlloyTable
        loading={loading}
        sticky
        tableLayout="auto"
        columns={columns}
        dataSource={vendorMarketsList}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              setRetailerChannelId(record.id);
            }
          };
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column = String((sorter as SorterResult<VendorMarket>).columnKey) || 'NAME';
          const order =
            (sorter as SorterResult<VendorMarket>).order === 'ascend' ||
            !(sorter as SorterResult<VendorMarket>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
      />
      <div className={s.pagination_container_end}>
        <Paginator
          hasNextPage={!!data?.vendorMarkets?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.vendorMarkets?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={true}
        />
      </div>
      <RetailerChannelViewDrawer
        onClose={() => setRetailerChannelId(undefined)}
        vendorMarketId={editingVendorMarket || !retailerChannelId ? undefined : retailerChannelId}
        startEditing={(vendorMarket) => {
          const retailerChannel = {
            ...vendorMarket,
            vendorId: vendorMarket?.vendor?.id || '',
            countryCode: vendorMarket?.countryCode || '',
            currencyCode: vendorMarket?.currencyCode || '',
            externalId: vendorMarket?.externalId || '',
            name: vendorMarket?.name || '',
            parentVendorMarketId: vendorMarket?.parentMarket?.id
          };
          setEditingVendorMarket(retailerChannel);
        }}
      />
      <EditRetailerChannelModal
        onClose={() => {
          setEditingVendorMarket(undefined);
          setRetailerChannelId(undefined);
        }}
        vendorMarket={editingVendorMarket}
      />
      <ChangeHistory
        entity={
          viewChangeHistoryVendorMarket
            ? {
                id: viewChangeHistoryVendorMarket.id || '',
                name: viewChangeHistoryVendorMarket.name || '',
                type: 'Retailer Channel'
              }
            : undefined
        }
        onClose={() => setViewHistoryVendorMarket(undefined)}
      />
    </div>
  );
};
