import { InfoCircleTwoTone, WarningOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';
import { get } from 'lodash-es';
import { DcSourceSystemParamsDocument } from 'pages/DistributionCentersPage/gql/__generated__/dcSourceSystemParams.query';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-final-form';

export const DcSourceSystemParamsCheck = ({
  sourceFieldName,
  codeFieldName
}: {
  sourceFieldName: string;
  codeFieldName: string;
}) => {
  const form = useForm();

  const [checkSource, { data, loading, error }] = useLazyQuery(DcSourceSystemParamsDocument, {});

  const source = useMemo(
    () => get(form.getState().values, sourceFieldName),
    [get(form.getState().values, sourceFieldName), sourceFieldName]
  );
  const code = useMemo(
    () => get(form.getState().values, codeFieldName),
    [get(form.getState().values, codeFieldName), codeFieldName]
  );

  useEffect(() => {
    if (source === 'CHANNELADVISOR' && code) {
      checkSource({
        variables: {
          code: code.trim(),
          source: source
        }
      });
    }
  }, [source, checkSource]); // start without delay

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (source === 'CHANNELADVISOR' && code) {
        checkSource({
          variables: {
            code: code.trim(),
            source: source
          }
        });
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [code]); // start with delay

  return (
    <div>
      {loading && (
        <>
          <Spin spinning size="small" />
          Checking source...
        </>
      )}
      {error && (
        <>
          <WarningOutlined style={{ color: '#cf1322' }} />
          An error occurred while checking the source. Please try again later.
        </>
      )}
      {!loading &&
        source === 'CHANNELADVISOR' &&
        data?.dcSourceSystemParams?.existenceChecked &&
        data.dcSourceSystemParams.viewUrl && (
          <>
            <InfoCircleTwoTone />
            You can view the existing source in CA{' '}
            <a href={data.dcSourceSystemParams.viewUrl} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </>
        )}
      {!loading &&
        source === 'CHANNELADVISOR' &&
        !data?.dcSourceSystemParams?.existenceChecked &&
        data?.dcSourceSystemParams?.listUrl && (
          <>
            <WarningOutlined style={{ color: '#f5b042' }} />
            This is an invalid code not present in CA. Please add a code that's{' '}
            <a href={data.dcSourceSystemParams.listUrl} target="_blank" rel="noopener noreferrer">
              present in CA
            </a>{' '}
            for the integrations to work.
          </>
        )}
    </div>
  );
};
