import React, { useState, useEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import './styles.scss';
import { AlignType } from 'common/types';
import { useMutation } from '@apollo/client';
import {
  PurchaseOrderConfirmation,
  PurchaseOrderItemConfirmation
} from 'graphql/__generated__/types';
import { ExtendedPOItemConfirmation, PartialPOConfirmation } from '../DownConfirmationTable/hooks';
import { GenerateAndSendPurchaseOrderConfirmationsFromItemsDocument } from './gql/__generated__/generateAndSendPurchaseOrderConfirmationsFromItems.mutation';
import { safeLocaleCompare, safeNumberComparator } from '@/src/common/helpers/comparators';
import { App } from 'ant5';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyInputNumber } from 'components/ui/AlloyInputNumber/AlloyInputNumber';

interface DownConfirmationExpandedRowsProps {
  confirmation: PartialPOConfirmation;
  onChange?: (confirmation: PurchaseOrderConfirmation) => void;
}

export const DownConfirmationExpandedRows = ({
  confirmation
}: DownConfirmationExpandedRowsProps) => {
  const { notification } = App.useApp();
  const parseConfirmation = (partialConfirmation: PartialPOConfirmation) => {
    let state: { [index: string]: { qty: number; status: string } } = {};
    for (const itemConfirmation of partialConfirmation.purchaseOrderItemConfirmations.filter(
      ({ purchaseOrderItem, acceptedUnits, orderedUnits }) =>
        purchaseOrderItem.asnStatus?.outstanding !== 0 || acceptedUnits !== orderedUnits
    ) as ExtendedPOItemConfirmation[]) {
      state[itemConfirmation.id] = {
        qty: itemConfirmation.acceptedUnits,
        status: itemConfirmation.status
      };
    }

    return state;
  };

  const [editedPOIConfirmation, setEditedPOIConfirmation] = useState(
    parseConfirmation(confirmation)
  );
  const [submitPoItemConfirmation, { loading }] = useMutation(
    GenerateAndSendPurchaseOrderConfirmationsFromItemsDocument
  );
  const [submittedId, setSubmittedId] = useState<string>();
  const [confirmedPOI, setConfirmedPOI] = useState<string | null>(null);

  useEffect(() => {
    setEditedPOIConfirmation(parseConfirmation(confirmation));
  }, [confirmation]);

  useEffect(() => {
    setConfirmedPOI(null);
  }, [confirmation.purchaseOrderItemConfirmations]);

  const submit = async (purchaseOrderItemConfirmation: PurchaseOrderItemConfirmation) => {
    setSubmittedId(purchaseOrderItemConfirmation.id);
    try {
      await submitPoItemConfirmation({
        variables: {
          input: {
            downConfirmPurchaseOrderItems: [
              {
                id: purchaseOrderItemConfirmation.id,
                confirmedQty: editedPOIConfirmation[purchaseOrderItemConfirmation.id].qty
              }
            ]
          }
        }
      });

      setEditedPOIConfirmation({
        ...editedPOIConfirmation,
        [purchaseOrderItemConfirmation.id]: {
          ...editedPOIConfirmation[purchaseOrderItemConfirmation.id],
          status: 'PROCESSING'
        }
      });

      notification.success({
        message: 'Success',
        description: 'Confirmation successfully submitted'
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description:
          'Unable to submit the confirmation. Please try again. An error has been logged for review.'
      });
    } finally {
      setConfirmedPOI(null);
    }
  };

  const handleConfirmButtonRender = (item: PurchaseOrderItemConfirmation) => {
    if (editedPOIConfirmation[item.id].status !== 'GENERATED' && confirmedPOI === item.id) {
      return (
        <AlloyButton
          disabled={false}
          loading={loading && submittedId === item.id}
          data-testid="poi-re-confirm-button"
          className="poi_confirm_button"
          onClick={() => submit(item)}
        >
          Re-confirm
        </AlloyButton>
      );
    } else if (editedPOIConfirmation[item.id].status !== 'GENERATED') {
      return (
        <AlloyButton
          disabled={true}
          data-testid="poi-confirm-button"
          className="poi_confirm_button"
        >
          Confirm
        </AlloyButton>
      );
    } else {
      return (
        <AlloyButton
          disabled={false}
          loading={loading && submittedId === item.id}
          data-testid="poi-confirm-button"
          className="poi_confirm_button"
          onClick={() => submit(item)}
        >
          Confirm
        </AlloyButton>
      );
    }
  };

  const columns: ColumnsType<PurchaseOrderItemConfirmation> = [
    {
      title: 'ASIN',
      key: 'product_asin',
      width: '135px',
      render: (_, item) => item.purchaseOrderItem.externalId,
      sorter: (a, b) =>
        safeLocaleCompare(a?.purchaseOrderItem.externalId, b?.purchaseOrderItem.externalId)
    },
    {
      title: 'UPC',
      key: 'product_upc',
      width: '130px',
      render: (_, item) => item.purchaseOrderItem.product?.upc,
      sorter: (a, b) =>
        safeLocaleCompare(a?.purchaseOrderItem.product?.upc, b?.purchaseOrderItem.product?.upc)
    },
    {
      title: 'Description',
      key: 'product',
      width: '115px',
      render: (_, item) => item.purchaseOrderItem.product?.name
    },
    {
      title: 'ASN',
      key: 'product_asn',
      render: (_, item) => {
        return (
          <>
            <div data-testid="asn-container" className="asn-container">
              <span data-testid="asn-received-indicator" className="ellipse-green asn-child" />
              <p data-testid="asn-received-count" className="asn-count">
                {item.purchaseOrderItem.asnStatus?.received}
              </p>
              <span data-testid="asn-outstanding-indicator" className="ellipse-red asn-child" />
              <p data-testid="asn-outstanding-count" className="asn-count">
                {item.purchaseOrderItem.asnStatus?.outstanding}
              </p>
            </div>
          </>
        );
      },
      width: '100px'
    },
    {
      title: 'Ordered Quantity',
      key: 'product_ordered_qty',
      render: (_, item) => item.orderedUnits,
      sorter: (a, b) => safeNumberComparator(a.orderedUnits, b.orderedUnits),
      width: 100
    },
    {
      title: 'Allocated Quantity',
      key: 'product_allocated_qty',
      render: (_, item) => item.acceptedUnits
    },
    {
      title: 'Confirmed Quantity',
      key: 'product_confirmed_qty',
      width: '120px',
      render: (_, item) => {
        if (editedPOIConfirmation[item.id].status === 'GENERATED' || confirmedPOI === item.id) {
          return (
            <div className="confirmed-qty">
              <AlloyInputNumber
                data-testid="purchase-order-item-confirmed-quantity"
                defaultValue={item.acceptedUnits}
                disabled={
                  editedPOIConfirmation[item.id].status !== 'GENERATED' && confirmedPOI === item.id
                    ? false
                    : editedPOIConfirmation[item.id].status !== 'GENERATED'
                }
                onChange={(value) =>
                  setEditedPOIConfirmation({
                    ...editedPOIConfirmation,
                    [item.id]: {
                      ...editedPOIConfirmation[item.id],
                      qty: value ?? 0
                    }
                  })
                }
              />
            </div>
          );
        } else {
          return (
            <div className="confirmed-qty">
              <AlloyInputNumber
                data-testid="purchase-order-item-confirmed-quantity"
                defaultValue={item.acceptedUnits}
                disabled={true}
              />
            </div>
          );
        }
      }
    },
    {
      title: '',
      width: '140px',
      align: 'center' as AlignType,
      render: (_, item) => {
        return handleConfirmButtonRender(item);
      }
    },
    {
      width: '50px',
      align: 'center' as AlignType,
      render: (_, item) => {
        if (editedPOIConfirmation[item.id].status === 'GENERATED') return null;
        return (
          <AlloyDropdown
            menu={{
              items: [
                {
                  key: '0',
                  label: 'Re-confirm Product',
                  onClick: () => setConfirmedPOI(item.id)
                }
              ]
            }}
          >
            <MoreOutlined className="more_icon" />
          </AlloyDropdown>
        );
      }
    }
  ];

  return (
    <AlloyTable
      data-testid="down_confirmation_expanded_rows_table"
      className="down_confirmation_expanded_rows_table"
      sticky
      pagination={false}
      rowKey={(row) => row.id}
      dataSource={confirmation.purchaseOrderItemConfirmations.filter(
        ({ purchaseOrderItem, acceptedUnits, orderedUnits }) =>
          purchaseOrderItem.asnStatus?.outstanding !== 0 || acceptedUnits !== orderedUnits
      )}
      columns={columns}
      summary={() => {
        if (!confirmation) return null;
        return (
          <tr className="summary">
            <td className="ant-table-cell"></td>
            <td className="ant-table-cell"></td>
            <td className="ant-table-cell"></td>
            <td className="ant-table-cell"></td>
          </tr>
        );
      }}
    />
  );
};
