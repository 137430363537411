import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import s from './ErrorsToolbar.module.scss';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { useLazyQuery } from '@apollo/client';
import { useUrlParams } from 'common/helpers/urlParams';
import { GetTradingPartnersDocument } from '../../gql/__generated__/filters.query';
import { TradingPartner, ReportableErrorType } from 'graphql/__generated__/types';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { useEnumValue } from 'common/useEnumValue';
import moment from 'moment';
import { ReportableErrorFromQuery } from 'pages/ErrorVisibility/types';
import DatePicker from 'components/DatePicker/DatePicker';

const { RangePicker } = DatePicker;

interface Filters {
  tradingPartners: string[];
  type?: ReportableErrorType[];
  startDate?: string;
  endDate?: string;
  [name: string]: any;
}

export interface ErrorToolbarState {
  filters: Filters;
}

interface ErrorsToolbarProps {
  selectedRows: ReportableErrorFromQuery[];
  toggleExportErrorsModal: () => void;
}

const { Option } = AlloySelect;

export const ErrorsToolbar = forwardRef(
  ({ selectedRows, toggleExportErrorsModal }: ErrorsToolbarProps, ref) => {
    const [urlParams, setUrlParams] = useUrlParams();
    const [tradingPartners, setTradingPartners] = useState<Partial<TradingPartner>[]>([]);
    const [filters, setFilters] = useState<Filters>({
      tradingPartners: Array.isArray(urlParams.tradingPartners)
        ? urlParams.tradingPartners
        : !!urlParams.tradingPartners
        ? [urlParams.tradingPartners]
        : [],
      type: Array.isArray(urlParams.type)
        ? (urlParams.type as ReportableErrorType[])
        : !!urlParams.type
        ? ([urlParams.type] as ReportableErrorType[])
        : ([] as ReportableErrorType[]),
      startDate: urlParams.startDate as string | undefined,
      endDate: urlParams.endDate as string | undefined
    });
    const { enumValues: errorTypes, loading: storeTypesLoading } =
      useEnumValue('ReportableErrorType');

    useImperativeHandle(ref, (): ErrorToolbarState => {
      return {
        filters
      };
    });

    const [getTradingPartners, { loading: loadingTradingPartners }] = useLazyQuery(
      GetTradingPartnersDocument,
      {
        variables: { first: 1000 },
        onCompleted: (data) => {
          const tradingPartners = getNodesFromEdges(data.tradingPartners);
          setTradingPartners(
            tradingPartners.sort((a, b) => a?.externalId?.localeCompare(b?.externalId ?? '') ?? 0)
          );
        }
      }
    );

    useEffect(() => {
      setUrlParams({ ...filters, after: undefined, before: undefined });
    }, [filters, setUrlParams]);

    return (
      <>
        <div data-testid="error-toolbar" className={s.toolbar}>
          <div data-testid="error-toolbar-filter-buttons" className={s.page_filters}>
            <AlloySelect
              data-testid="select-tp-filter"
              className={s.select}
              placeholder="All Trading Partners"
              mode="multiple"
              allowClear
              value={filters.tradingPartners}
              onClick={() => {
                if (!tradingPartners.length) getTradingPartners();
              }}
              onChange={(selections: string[]) =>
                setFilters({ ...filters, tradingPartners: selections })
              }
              loading={loadingTradingPartners}
              maxTagCount="responsive"
            >
              {tradingPartners.map((tp) => (
                <Option key={tp.id} value={tp.externalId}>
                  {tp.externalId}
                </Option>
              ))}
            </AlloySelect>
            <RangePicker
              data-testid="select-date-range-filter"
              className={s.range_picker}
              defaultValue={
                !!filters.startDate && !!filters.endDate
                  ? [moment(filters.startDate, 'YYYY-MM-DD'), moment(filters.endDate, 'YYYY-MM-DD')]
                  : undefined
              }
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'd'), moment().subtract(1, 'd')],
                'This week': [moment().startOf('W'), moment().endOf('W')],
                'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                'Last Week': [
                  moment().subtract(6, 'd').startOf('W'),
                  moment().subtract(6, 'd').endOf('W')
                ]
              }}
              onChange={(range, stringValues) =>
                setFilters({
                  ...filters,
                  startDate: stringValues[0] || undefined,
                  endDate: stringValues[1] || undefined
                })
              }
            />
            <AlloySelect
              data-testid="select-error-filter"
              className={s.select}
              placeholder="Select Error"
              mode="multiple"
              allowClear
              value={filters.type}
              onChange={(selections: ReportableErrorType[]) =>
                setFilters({ ...filters, type: selections })
              }
              onClear={() => setFilters({ ...filters, startDate: undefined, endDate: undefined })}
              maxTagCount="responsive"
            >
              {errorTypes?.map((errorType) => (
                <Option key={errorType} value={errorType}>
                  {errorType.replace(/_/g, ' ')}
                </Option>
              ))}
            </AlloySelect>
            <AlloySelect
              data-testid="export-errors-dropdown"
              className={s.select}
              value="Export"
              style={{ width: '130px' }}
              popupMatchSelectWidth={false}
              onSelect={() => toggleExportErrorsModal()}
              disabled={!(selectedRows.length > 0)}
              maxTagCount="responsive"
              options={[
                {
                  value: 'Export selected',
                  label: 'Export selected'
                }
              ]}
            />
          </div>
        </div>
      </>
    );
  }
);
