import { useMutation, useQuery } from '@apollo/client';
import { message, Spin } from 'antd';
import React, { useMemo } from 'react';
import { EditStoreInput, getDateValue, getTimeValue } from 'pages/ShipToPage/helper';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import moment from 'moment';
import { EditShipToTabs } from './components/EditShipToTabs';
import { RetailerChannelsForShipTo } from 'pages/ShipToPage/ShipToPage';
import { RetailerDeliveryDestination } from '../ShipToViewDrawer/ShipToViewDrawer';
import { UpsertRetailerDeliveryDestinationsDocument } from 'pages/ShipToPage/gql/__generated__/upsertRetailerDeliveryDestinations.mutation';
import { StoreType } from 'graphql/__generated__/types';
import { ShipToEditingInitDataDocument } from 'pages/ShipToPage/gql/__generated__/shipToEditingInitData.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { DistributionCenter, TradingPartner } from '../AddShipToModal/AddShipToModal';
import { v4 as uuidv4 } from 'uuid';

interface EditShipToModalProps {
  shipTo?: RetailerDeliveryDestination | null;
  onClose: () => void;
  vendorMarkets: RetailerChannelsForShipTo[];
}

type TpRdd = NonNullable<
  RetailerDeliveryDestination['tradingPartnerRetailerDeliveryDestinations']
>[number];

const getDcSoldTosToEdit = (tpRdd: TpRdd, distributionCenters: DistributionCenter[]) => {
  const mappedDcSoldTos = (tpRdd.dcSoldTos || []).map((dcSoldTo) => ({
    dcCode: distributionCenters.find((dc) => dc.code === dcSoldTo.dcCode)?.id,
    soldTo: dcSoldTo.soldTo ?? '',
    id: uuidv4()
  }));
  if (tpRdd.alternateSoldTo)
    mappedDcSoldTos.push({ soldTo: tpRdd.alternateSoldTo, dcCode: undefined, id: uuidv4() });
  if (mappedDcSoldTos.length === 0) mappedDcSoldTos.push({ dcCode: '', soldTo: '', id: uuidv4() });
  return mappedDcSoldTos;
};

export const EditShipToModal = ({ onClose, shipTo, vendorMarkets }: EditShipToModalProps) => {
  const [upsertRetailerDeliveryDestinations, { loading: upserting }] = useMutation(
    UpsertRetailerDeliveryDestinationsDocument,
    {
      refetchQueries: ['retailerDeliveryDestinations']
    }
  );

  const { data: initData, loading: initLoading } = useQuery(ShipToEditingInitDataDocument, {
    skip: !shipTo
  });

  const tradingPartners = useMemo<TradingPartner[]>(
    () => getNodesFromEdges(initData?.tradingPartners),
    [initData]
  );

  const distributionCenters = useMemo<DistributionCenter[]>(
    () => getNodesFromEdges(initData?.distributionCenters),
    [initData]
  );

  const requiredFields = useMemo<string[]>(() => {
    const newTradingPartnerIds = shipTo?.tradingPartners?.map((tp) => tp?.id) || [];
    return getNodesFromEdges(initData?.tradingPartners)
      .filter((tp) => newTradingPartnerIds.includes(tp.id))
      .reduce((result, current) => {
        if (current.storeConfig?.requiredIds) {
          current.storeConfig?.requiredIds.forEach((field) => {
            if (field && !result.includes(field)) {
              result.push(field);
            }
          });
        }
        return result;
      }, [] as string[]);
  }, [initData?.tradingPartners, shipTo?.tradingPartners]);

  const submitForm = async (
    values: EditStoreInput,
    form: FormApi<EditStoreInput, Partial<EditStoreInput>>
  ) => {
    try {
      const {
        id,
        active,
        allowedDcs,
        openingDate,
        sanCode,
        specialAssortment,
        storeType,
        vendorMarketId,
        tradingPartnerConfiguration
      } = values;
      const inputValues = {
        id,
        name: values.name?.trim() || null,
        active,
        address: values.address?.trim() || null,
        addressCity: values.addressCity?.trim() || null,
        addressState: values.addressState?.trim() || null,
        addressZip: values.addressZip?.trim() || null,
        allowedDcs,
        cisId: values.cisId?.trim() || null,
        cofId: values.cofId?.trim() || null,
        duns: values.duns?.trim() || null,
        externalId: values.externalId?.trim() || null,
        externalStoreNumber: values.externalStoreNumber?.trim() || null,
        metroArea: values.metroArea?.trim() || null,
        openingDate: getDateValue(openingDate) || null,
        sanCode,
        sapCustomerId: values.sapCustomerId?.trim() || null,
        soldToSapCustomerId: values.soldToSapCustomerId?.trim() || null,
        specialAssortment,
        storeType: storeType as StoreType,
        timezone: values.timezone?.trim() || null,
        vendorMarketId,
        tradingPartnerConfiguration: tradingPartnerConfiguration.map((tpc) => ({
          ...tpc,
          routeNumber: tpc.routeNumber ?? null,
          defaultForecastingSource: tpc.defaultForecastingSource ?? null,
          rounding: tpc.rounding ?? null,
          releaseMethod: tpc.releaseMethod ?? null,
          businessUnitDeliveryDestinationCode: tpc.businessUnitDeliveryDestinationCode ?? null,
          dcSoldTos: (tpc.dcSoldTos || [])
            .filter((dcSoldTo) => !!dcSoldTo.dcCode && !!dcSoldTo.soldTo)
            .map((dcSoldTo) => ({
              dcCode: distributionCenters.find((dc) => dc.id === dcSoldTo.dcCode)?.code,
              soldTo: dcSoldTo.soldTo
            })),
          alternateSoldTo:
            (tpc.dcSoldTos || []).find((dcSoldTo) => !dcSoldTo.dcCode)?.soldTo ?? null,
          firstDeliveryDate: getDateValue(tpc.firstDeliveryDate) ?? null,
          deliverySchedule: (tpc.deliverySchedule || []).map((ds) => ({
            ...ds,
            cutoffTime: getTimeValue(ds?.cutoffTime),
            expectedDeliveryTime: getTimeValue(ds?.expectedDeliveryTime),
            expectedAsnHoursBeforeDelivery: ds?.expectedAsnHoursBeforeDelivery
              ? Number(ds.expectedAsnHoursBeforeDelivery)
              : null,
            expectedInvoiceHoursAfterDelivery: ds?.expectedInvoiceHoursAfterDelivery
              ? Number(ds.expectedInvoiceHoursAfterDelivery)
              : null,
            expectedPoAcknowledgementHoursBeforeDelivery:
              ds?.expectedPoAcknowledgementHoursBeforeDelivery
                ? Number(ds?.expectedPoAcknowledgementHoursBeforeDelivery)
                : null,
            expectedPurchaseOrderHoursBeforeDelivery: ds?.expectedPurchaseOrderHoursBeforeDelivery
              ? Number(ds?.expectedPurchaseOrderHoursBeforeDelivery)
              : null,
            expectedSalesOrderHoursBeforeDelivery: ds?.expectedSalesOrderHoursBeforeDelivery
              ? Number(ds.expectedSalesOrderHoursBeforeDelivery)
              : null
          })),
          storeCapacity: tpc && tpc.storeCapacity ? Number(tpc.storeCapacity) : null,
          capacityCubicFeetBoh:
            tpc && tpc.capacityCubicFeetBoh ? Number(tpc.capacityCubicFeetBoh) : null,
          frontOfHouseFillPercentage:
            tpc && tpc.frontOfHouseFillPercentage ? Number(tpc.frontOfHouseFillPercentage) : null
        }))
      } as EditStoreInput;
      await upsertRetailerDeliveryDestinations({
        variables: {
          input: {
            stores: [
              {
                ...inputValues
              }
            ]
          }
        }
      });
      form.reset();
      message.success(
        <span data-testid="message_edit-ship-to-success">Ship-To successfully edited</span>
      );
      onClose();
    } catch (e) {
      form.initialize(values);
      console.log({ e });
      message.error(`Error happened during Ship-To saving. ${e.message}`);
    }
  };

  return (
    <Spin spinning={upserting || initLoading} style={{ height: '100%' }}>
      <Form<EditStoreInput>
        initialValues={{
          id: shipTo?.id,
          name: shipTo?.name ?? undefined,
          active: true,
          address: shipTo?.address ?? undefined,
          addressCity: shipTo?.addressCity ?? undefined,
          addressState: shipTo?.addressState ?? undefined,
          addressZip: shipTo?.addressZip ?? undefined,
          allowedDcs: shipTo?.allowedDistributionCenters?.map((value) => ({
            distributionCenterId: value?.distributionCenter?.id || '',
            externalId: value?.externalId || '',
            priority: value?.priority ?? 0
          })),
          cisId: shipTo?.cisId ?? undefined,
          cofId: shipTo?.cofId ?? undefined,
          duns: shipTo?.duns ?? undefined,
          externalId: shipTo?.externalId ?? undefined,
          externalStoreNumber: shipTo?.externalStoreNumber ?? undefined,
          metroArea: shipTo?.metroArea ?? undefined,
          openingDate: shipTo && shipTo?.openingDate ? moment(shipTo?.openingDate) : undefined,
          sanCode: shipTo?.sanCode ?? undefined,
          sapCustomerId: shipTo?.sapCustomerId ?? undefined,
          soldToSapCustomerId: shipTo?.soldToSapCustomerId ?? undefined,
          specialAssortment: shipTo?.specialAssortment,
          storeType: shipTo?.storeType ?? undefined,
          timezone: shipTo?.timezone ?? undefined,
          tradingPartnerConfiguration: shipTo?.tradingPartnerRetailerDeliveryDestinations?.map(
            (tpRdd) => ({
              autoForecast: tpRdd?.autoForecast ?? undefined,
              autoFulfill: tpRdd?.autoFulfill ?? undefined,
              alternateSoldTo: tpRdd?.alternateSoldTo ?? null,
              businessUnitDeliveryDestinationCode:
                tpRdd?.businessUnitDeliveryDestinationCode ?? null,
              capacityCubicFeetBoh: tpRdd?.capacityCubicFeetBoh ?? null,
              dcSoldTos: getDcSoldTosToEdit(tpRdd, distributionCenters),
              defaultForecastingSource: tpRdd?.defaultForecastingSource ?? null,
              deliverySchedule: tpRdd?.deliverySchedule?.map((ds) => ({
                dayOfWeek: ds?.dayOfWeek ?? 'MONDAY',
                cutoffTime: ds && ds.cutoffTime ? moment(ds.cutoffTime, 'HH:mm:ssZ') : moment(),
                expectedAsnHoursBeforeDelivery: ds?.expectedAsnHoursBeforeDelivery ?? null,
                expectedDeliveryTime:
                  ds && ds.expectedDeliveryTime
                    ? moment(ds.expectedDeliveryTime, 'HH:mm:ssZ')
                    : undefined,
                expectedInvoiceHoursAfterDelivery:
                  ds?.expectedInvoiceHoursAfterDelivery ?? undefined,
                expectedPoAcknowledgementHoursBeforeDelivery:
                  ds?.expectedPoAcknowledgementHoursBeforeDelivery ?? undefined,
                expectedPurchaseOrderHoursBeforeDelivery:
                  ds?.expectedPurchaseOrderHoursBeforeDelivery ?? undefined,
                expectedSalesOrderHoursBeforeDelivery:
                  ds?.expectedSalesOrderHoursBeforeDelivery ?? undefined
              })),
              extraDayOfCover: tpRdd?.extraDayOfCover ?? undefined,
              firstDeliveryDate:
                tpRdd && tpRdd.firstDeliveryDate ? moment(tpRdd.firstDeliveryDate) : undefined,
              frontOfHouseFillPercentage: tpRdd?.frontOfHouseFillPercentage ?? undefined,
              overrideExpirationDateFromShipmentItem:
                tpRdd?.overrideExpirationDateFromShipmentItem ?? undefined,
              releaseMethod: tpRdd?.releaseMethod ?? undefined,
              rounding: tpRdd?.rounding ?? undefined,
              routeNumber: tpRdd?.routeNumber ?? undefined,
              storeCapacity: tpRdd?.storeCapacity ?? undefined,
              tradingPartnerId: tpRdd?.tradingPartner?.id
            })
          ),
          vendorMarketId: shipTo?.vendorMarket?.id
        }}
        onSubmit={submitForm}
        mutators={{
          ...arrayMutators,
          setAllowedDcs: (args, state, utils) => {
            utils.changeValue(state, 'allowedDcs', () => args[0]);
          },
          setTpAssortments: (args, state, utils) => {
            utils.changeValue(state, 'tpAssortments', () => args[0]);
          },
          setTradingPartnerConfiguration: (args, state, utils) => {
            utils.changeValue(state, 'tradingPartnerConfiguration', () => args[0]);
          },
          setRetailerChannel: (args, state, utils) => {
            utils.changeValue(state, 'vendorMarketId', () => args[0]);
          }
        }}
        render={({ handleSubmit, form }) => {
          return (
            <form
              id="edit-ship-to-modal-form"
              onSubmit={(event) => {
                const { errors } = form.getState();
                if (errors && !!Object.keys(errors).length) {
                  if (
                    errors['externalId'] ||
                    errors['name'] ||
                    errors['vendorMarketId'] ||
                    errors['soldToSapCustomerId'] ||
                    errors['tradingPartnerId'] ||
                    errors['cisId'] ||
                    errors['duns'] ||
                    errors['sapCustomerId'] ||
                    errors['externalStoreNumber'] ||
                    errors['cofId'] ||
                    errors['sanCode']
                  ) {
                    message.warning(
                      <span data-testid="message_edit-ship-to-general-tab-required-fields">
                        Please fill all required fields on the General Settings tab
                      </span>
                    );
                  } else if (errors['tradingPartnerConfiguration']) {
                    message.warning(
                      <span data-testid="message_edit-ship-to-tp-spacific-tab-required-fields">
                        Please fill all required fields on the TP-Specific Settings tab
                      </span>
                    );
                  } else if (errors['allowedDcs']) {
                    message.warning(
                      <span data-testid="message_edit-ship-to-dc-tab-required">
                        Please select at least one Distribution Center on the DC tab
                      </span>
                    );
                  }
                }
                const promise = handleSubmit(event);
                promise &&
                  promise.then(() => {
                    // do nothing
                  });
                return promise;
              }}
            >
              <EditShipToTabs
                tradingPartners={tradingPartners}
                vendorMarkets={vendorMarkets}
                distributionCenters={distributionCenters}
                onClose={onClose}
                requiredFields={requiredFields}
                form={form}
                visible={!!shipTo}
              />
            </form>
          );
        }}
      />
    </Spin>
  );
};
