import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteTradingPartnerRetailerDeliveryDestinationsMutationVariables = Types.Exact<{
  input: Types.DeleteTradingPartnerRetailerDeliveryDestinationsInput;
}>;


export type DeleteTradingPartnerRetailerDeliveryDestinationsMutation = { __typename?: 'RootMutationType', deleteTradingPartnerRetailerDeliveryDestinations?: { __typename?: 'DeleteTradingPartnerRetailerDeliveryDestinationsPayload', tradingPartner?: { __typename?: 'TradingPartner', id: string, retailerDeliveryDestinations: Array<{ __typename?: 'RetailerDeliveryDestination', id: string, active?: boolean | null, externalId?: string | null, name?: string | null }> } | null } | null };


export const DeleteTradingPartnerRetailerDeliveryDestinationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteTradingPartnerRetailerDeliveryDestinations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteTradingPartnerRetailerDeliveryDestinationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteTradingPartnerRetailerDeliveryDestinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tradingPartner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"retailerDeliveryDestinations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<DeleteTradingPartnerRetailerDeliveryDestinationsMutation, DeleteTradingPartnerRetailerDeliveryDestinationsMutationVariables>;