import React from 'react';
import s from './RetailerDeliveryDestinationCell.module.scss';
import { RetailerDeliveryDestination } from 'graphql/__generated__/types';

type PartialRetailerDeliveryDestination =
  | Pick<RetailerDeliveryDestination, 'name' | 'addressState' | 'addressCity'>
  | null
  | undefined;

export default function RetailerDeliveryDestinationCell({
  retailerDeliveryDestination
}: {
  retailerDeliveryDestination: PartialRetailerDeliveryDestination;
}) {
  if (!retailerDeliveryDestination) return <div>N/A</div>;
  return (
    <div className={s.shipTo}>
      <div className={s.street}>{retailerDeliveryDestination?.name || ''}</div>
      <div className={s.cityAndState}>
        {`${retailerDeliveryDestination?.addressCity || ''} ${
          retailerDeliveryDestination?.addressState || ''
        }`.trim()}
      </div>
    </div>
  );
}
