import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryReconciliationTotalsQueryVariables = Types.Exact<{
  filters: Types.InventoryReconciliationFilters;
}>;


export type InventoryReconciliationTotalsQuery = { __typename?: 'RootQueryType', inventoryReconciliationTotals?: { __typename?: 'InventoryReconciliationTotals', inventoryAccuracy?: any | null, perfectBatchSwapCount: number, productsWithDiscrepancy: number, totalProducts: number } | null };


export const InventoryReconciliationTotalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryReconciliationTotals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryReconciliationFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryReconciliationTotals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryAccuracy"}},{"kind":"Field","name":{"kind":"Name","value":"perfectBatchSwapCount"}},{"kind":"Field","name":{"kind":"Name","value":"productsWithDiscrepancy"}},{"kind":"Field","name":{"kind":"Name","value":"totalProducts"}}]}}]}}]} as unknown as DocumentNode<InventoryReconciliationTotalsQuery, InventoryReconciliationTotalsQueryVariables>;