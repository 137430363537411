import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UnsubmitPurchaseOrderMutationVariables = Types.Exact<{
  input: Types.UnsubmitPurchaseOrderInput;
}>;


export type UnsubmitPurchaseOrderMutation = { __typename?: 'RootMutationType', unsubmitPurchaseOrder?: { __typename?: 'UnsubmitPurchaseOrderPayload', purchaseOrder?: { __typename?: 'PurchaseOrder', id: string, customerPo?: string | null, status?: Types.PurchaseOrderStatus | null } | null } | null };


export const UnsubmitPurchaseOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"unsubmitPurchaseOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UnsubmitPurchaseOrderInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unsubmitPurchaseOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"customerPo"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<UnsubmitPurchaseOrderMutation, UnsubmitPurchaseOrderMutationVariables>;