import { SalesOrderStatus } from 'graphql/__generated__/types';
import { groupBy } from 'lodash-es';
import moment from 'moment';
import { PurchaseOrderWithOverdue, SalesOrderItem } from 'pages/OrderDetailsPage/types';

export interface ParsedShipment {
  id: string;
  status: SalesOrderStatus | null | undefined;
  externalId: string | null | undefined;
  zeroPromise: boolean | null | undefined;
  ochDummySapId: string | null | undefined;
  alternateOrderId: string | null | undefined;
  date: moment.Moment | undefined;
  pickupDate: moment.Moment | undefined;
  tracking: string;
  items: SalesOrderItem[];
  shipmentSource: string | null | undefined;
  name?: string | undefined;
}

export interface SelectedShipmentGroup {
  shipmentGroup: string;
  shipments: ParsedShipment[];
}

export const parsePurchaseOrderWhdData = (
  data: PurchaseOrderWithOverdue
): SelectedShipmentGroup[] => {
  const parsedPurchaseOrderWhdData: SelectedShipmentGroup[] = [];

  //groups SOs by Distribution Center
  const groupedSalesOrders = groupBy(
    // fix to prevent 'Uncaught TypeError: Cannot assign to read only property '0' of object '[object Array]'' by copying array so we don't mutate state directly
    [...data.salesOrders].sort(
      (a1, a2) => a1.distributionCenter?.id.localeCompare(a2.distributionCenter?.id || '') || 0
    ),
    'distributionCenter.id'
  );

  for (const orderGroup in groupedSalesOrders) {
    //for every group of sales orders...
    if (groupedSalesOrders.hasOwnProperty(orderGroup)) {
      const groupData = groupedSalesOrders[orderGroup]; //creates a const for each separate SO group from the array of SO groups -- console.logs as many times as there are single groups, showing that group of SOs
      parsedPurchaseOrderWhdData.push({
        shipmentGroup: groupData[0].distributionCenter
          ? `${groupData[0].distributionCenter?.code} ${groupData[0].distributionCenter?.name}`
          : // : `Invalid DC: ${groupData[0].distributionCenterCode}`,
            '',
        shipments: groupData
          .map((so): ParsedShipment => {
            const date = moment(so.deliveryWindowEnd);
            const shipment = so.shipments && so.shipments.length > 0 ? so.shipments[0] : undefined;

            return {
              id: so.id,
              externalId: so.externalId, //TODO: remove this, since it isn't actually what we need. we need ochSourceOrderId from SO
              status: so.status,
              items: so.salesOrderItems,
              zeroPromise: so.zeroPromise, //TODO: remove this because it is deprecated. we need to be using cutSummaries, which is only accessible from purchaseOrder, not salesOrder
              ochDummySapId: so.ochDummySapId,
              alternateOrderId: so.alternateOrderId,
              date: date.isValid() ? date : undefined,
              pickupDate:
                shipment && shipment.requestedPickupDate
                  ? moment(so.shipments[0].requestedPickupDate)
                  : undefined,
              tracking:
                shipment?.shippingCarrier && shipment?.shippingClass && shipment?.trackingNumber
                  ? `${shipment?.shippingCarrier} ${shipment?.shippingClass} ${shipment?.trackingNumber}`
                  : 'TBD',
              shipmentSource: so.source
            };
          })
          .sort((s1, s2) => s1.id.localeCompare(s2.id))
      });
    }
  }

  return parsedPurchaseOrderWhdData;
};
