import React, { useState, useContext, useMemo } from 'react';
import s from './PurchaseOrderDetailsHeader.module.scss';
import clsx from 'clsx';
import { Button, Dropdown, MenuProps, message, Row, Space, Tooltip } from 'antd';
import { ClockCircleOutlined, DownOutlined } from '@ant-design/icons';
import backButtonIcon from 'assets/icons/back_button_icon.svg';
import Copy from 'components/Common/Copy';
import {
  PO_AMAZON_LINK,
  PO_STATUS_SUBMITTED,
  RETAILER_CHANNEL_SAMS_CLUB,
  PO_STATUS_DELIVERED,
  PO_STATUS_ACKNOWLEDGED
} from 'common/constants';
import { useMutation } from '@apollo/client';
import { SubscribedAction } from '../PurchaseOrderDetails/PurchaseOrderDetails';
import { AppHistoryContext } from 'context/AppHistoryContext';
import { Link } from 'react-router-dom';
import { UserContext } from 'context/userContext';
import { ReprocessPurchaseOrderDocument } from './gql/__generated__/reprocessPurchaseOrder.mutation';
import { ResetPurchaseOrderDocument } from './gql/__generated__/resetPurchaseOrder.mutation';
import ResendCustomizationModal from 'pages/OrderDetailsPage/components/ResendCustomizationModal/ResendCustomizationModal';
import { CustomizationHistoryModal } from '../CustomizationHistoryModal/CustomizationHistoryModal';
import { SalesOrder } from '@/src/pages/OrderDetailsPage/types';
import PurchaseOrderStatus from '../../../../components/PurchaseOrders/PurchaseOrderStatus/PurchaseOrderStatus';
import { PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';
import { PoHistoryDrawer } from 'components/PurchaseOrders/PoHistoryDrawer';
import { PurchaseOrderHistoryDrawerState } from 'pages/OrdersPage/components/OrdersTable/OrdersTable';
import { SyncChannelAdvisorDocument } from './gql/__generated__/syncChannelAdvisory.mutation';
import { PurchaseOrderRefetchQueries } from 'common/constants';

interface OrdersDetailsHeaderProps {
  customer: string;
  market: string;
  orderNumber: string;
  orderStatus: string;
  orderType: string;
  overdue: boolean;
  toggleDownloadFileModal: () => void;
  toggleCancelPurchaseOrderModal: () => void;
  purchaseOrder: PurchaseOrderWithOverdue;
  purchaseOrderId: string;
  externalId: string;
  setSubscribedActionInProgress: (value: SubscribedAction | undefined) => void;
  isReprocess: boolean;
  vendorMarket: string | undefined;
  poAvailableActions: string[] | undefined;
  salesOrders: SalesOrder[];
}

const PurchaseOrderDetailsHeader = ({
  customer,
  market,
  orderNumber,
  orderStatus,
  orderType,
  overdue,
  externalId, //TODO: do we need this?
  toggleDownloadFileModal,
  purchaseOrder,
  purchaseOrderId,
  toggleCancelPurchaseOrderModal,
  setSubscribedActionInProgress,
  isReprocess, //TODO: do we need this?
  vendorMarket,
  poAvailableActions,
  salesOrders
}: OrdersDetailsHeaderProps) => {
  const { user } = useContext(UserContext);
  const { getProximalLocation } = useContext(AppHistoryContext);
  const proximalLocation = getProximalLocation(['/', '/errors']);

  /********************  PO HISTORY DRAWER   ***************************/
  const [poHistoryDrawerState, setPoHistoryDrawerState] = useState<PurchaseOrderHistoryDrawerState>(
    {
      visible: false,
      poHistory: [],
      poNumber: ''
    }
  );

  const togglePOHistoryDrawer = () => {
    setPoHistoryDrawerState({
      ...poHistoryDrawerState,
      visible: !poHistoryDrawerState.visible
    });
  };
  /************************************************************************/

  /****************************  CA SYNC  *********************************/

  const [caSyncLoading, setCaSyncLoading] = useState(false);

  //TODO: why do we have this function
  const requestCaSync = () => {
    setCaSyncLoading(true);
    syncChannelAdvisory({
      variables: { input: { id: purchaseOrderId } }
    });
    stillLoadingCaSync(true);
  };

  /*
    The CA Sync button looks better if the loading animation completes at least 1 loop. The allows that to happen even though
    the request completes almost instantly.
  */
  const stillLoadingCaSync = (isLoading?: boolean) => {
    if (!caSyncLoadingStatus && !isLoading) {
      setCaSyncLoading(false);
    } else if (isLoading) {
      setTimeout(() => {
        stillLoadingCaSync();
      }, 2000);
    }
  };

  const [syncChannelAdvisory, { loading: caSyncLoadingStatus }] = useMutation(
    SyncChannelAdvisorDocument,
    {
      refetchQueries: PurchaseOrderRefetchQueries,
      onCompleted: () => {
        message.success('Channel Advisor sync completed successfully');
      },
      onError: (error) => {
        message.warning(
          `An error occurred while attempting to sync Channel Advisor: ${error.message}`
        );
      }
    }
  );

  /************************************************************************/

  /********************    RESEND CUSTOMIZATIONS    ***********************/
  const [showResendCustomizationModal, setShowResendCustomizationModal] = useState(false);
  const [showCustomizationHistoryModal, setShowCustomizationHistoryModal] = useState(false);
  const toggleResendCustomizationModal = () =>
    setShowResendCustomizationModal(!showResendCustomizationModal);

  /************************************************************************/

  /*************************     REPROCESS PO    **************************/
  const [reprocessPurchaseOrderMutation] = useMutation(ReprocessPurchaseOrderDocument, {
    refetchQueries: PurchaseOrderRefetchQueries,
    onCompleted: () => {
      message.success('PO successfully reprocessed');
      setSubscribedActionInProgress(undefined);
    },
    onError: () => {
      message.warning('It looks like there was a problem reprocessing the purchase order');
      setSubscribedActionInProgress(undefined);
    }
  });

  const reprocessPurchaseOrder = () => {
    setSubscribedActionInProgress(SubscribedAction.REPROCESS);
    reprocessPurchaseOrderMutation({
      variables: { input: { id: purchaseOrderId } }
    });
  };

  /************************************************************************/

  /**************************   RESET PO   ********************************/
  const [resetPurchaseOrderMutation, { loading: resetPending }] = useMutation(
    ResetPurchaseOrderDocument,
    {
      refetchQueries: PurchaseOrderRefetchQueries,
      onCompleted: () => {
        message.success('PO successfully reset');
        setSubscribedActionInProgress(undefined);
      },
      onError: () => {
        message.warning('It looks like there was a problem resetting the purchase order');
        setSubscribedActionInProgress(undefined);
      }
    }
  );

  const resetPurchaseOrder = () => {
    setSubscribedActionInProgress(SubscribedAction.RESET_PURCHASE_ORDER);
    resetPurchaseOrderMutation({
      variables: { input: { purchaseOrderIds: [purchaseOrderId] } }
    });
  };

  /************************************************************************/

  const formatPONumber = (poNumber: string) => {
    if (customer && customer.toLowerCase().match(/amazon/)) {
      return (
        <>
          <div>
            <span className={s.po_text}>PO:</span>
            <span className={s.po_number}>
              <Copy placement="bottom-right">{poNumber}</Copy>
            </span>
          </div>
          <div className={s.goto}>
            <Tooltip title="View on Vendor Central" placement="top">
              <a
                className={s.po_link}
                href={`${PO_AMAZON_LINK}${poNumber}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {/* Inlined https://fontawesome.com/v4/icon/external-link-square */}
                <svg
                  aria-hidden="true"
                  className={s.external_link}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z"
                  />
                </svg>
              </a>
            </Tooltip>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <span className={s.po_text}>PO:</span>
          <span className={s.po_number}>
            <Copy placement="top-right">{poNumber}</Copy>
          </span>
        </div>
      );
    }
  };

  /*******************   DISPLAY PO ACTIONS DROPDOWN   *********************/

  const isCustomizationAllowed = useMemo(() => {
    if (!!purchaseOrder.salesOrders.find((so) => so.hasCustomizations)) {
      return true;
    }
  }, [purchaseOrder]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'CA Sync',
      disabled:
        !poAvailableActions?.includes('CHANNEL_ADVISOR_SYNC') ||
        !user?.availableActions.includes('CHANNEL_ADVISOR_SYNC'),
      onClick: () => {
        requestCaSync();
      }
    },
    {
      key: '2',
      label: 'Customization Actions',
      disabled: !isCustomizationAllowed,
      children: [
        {
          key: 'resend_customization',
          label: 'Download or Resend'
        },
        {
          key: 'customization_history',
          label: 'Historical Files'
        }
      ],
      onClick: (e) => {
        switch (e.key) {
          case 'resend_customization':
            toggleResendCustomizationModal();
            break;
          case 'customization_history':
            setShowCustomizationHistoryModal(true);
            break;
        }
      }
    },
    {
      key: '4',
      label: 'Export Details (.csv)',
      disabled: orderType === 'ECOMM',
      onClick: () => {
        toggleDownloadFileModal();
      }
    },
    {
      key: '5',
      label: 'Reprocess PO',
      disabled:
        !poAvailableActions?.includes('REPROCESS_PO') ||
        !user?.availableActions.includes('REPROCESS_PO'),
      onClick: () => {
        reprocessPurchaseOrder();
      }
    },
    {
      key: '6',
      label: 'Reset PO',
      disabled:
        !poAvailableActions?.includes('RESET_PO') || !user?.availableActions.includes('RESET_PO'),
      onClick: () => {
        resetPurchaseOrder();
      }
    }
  ];

  const POActionsDropdown = () => {
    return (
      <Dropdown
        data-testid="po-actions-dropdown-button"
        className={s.po_actions_dropdown_button}
        trigger={['click']}
        menu={{ items }}
      >
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </Dropdown>
    );
  };
  const displayPOActionsDropdown = () => {
    if (
      poAvailableActions?.includes('CHANNEL_ADVISOR_SYNC') &&
      user?.availableActions.includes('CHANNEL_ADVISOR_SYNC')
    ) {
      return <POActionsDropdown />;
    } else if (
      orderType === 'ECOMM' &&
      vendorMarket !== RETAILER_CHANNEL_SAMS_CLUB &&
      [PO_STATUS_SUBMITTED, PO_STATUS_DELIVERED].includes(orderStatus) &&
      !!purchaseOrder.salesOrders.find((so) => so.hasCustomizations)
    ) {
      return <POActionsDropdown />;
    } else if (
      poAvailableActions?.includes('RESET_PO') &&
      user?.availableActions.includes('RESET_PO')
    ) {
      return <POActionsDropdown />;
    } else if (orderType === 'DSD') {
      return <POActionsDropdown />;
    } else if (orderType !== 'ECOMM') {
      return <POActionsDropdown />;
    } else if (
      poAvailableActions?.includes('REPROCESS_PO') &&
      user?.availableActions.includes('REPROCESS_PO')
    ) {
      return <POActionsDropdown />;
    } else {
      return null;
    }
  };

  /************************************************************************/

  return (
    <>
      <Row
        data-testid="order-details-header"
        className={clsx(s.po_details_header_row, { [s.po_header]: orderStatus === 'NEW' })}
      >
        <div data-testid="order-details-header-info-container" className={s.header_info_container}>
          <div data-testid="back-button-col" className={s.back_button_container}>
            {/* TODO: change the data-testid below, as well as the test itself */}
            <Link<{ poId?: string }>
              data-testid="all-purchase-orders-button"
              to={{
                ...proximalLocation,
                state: { poId: purchaseOrder.id }
              }}
            >
              <img data-testid="back-button-icon" src={backButtonIcon} alt="" />
            </Link>
          </div>

          <div data-testid="po-title-col" className={s.po_title_container}>
            <div className={s.market_name}>{market}</div>
            <div className={s.po_number_and_status_row}>
              <div data-testid="po-number-container" className={s.po_number_container}>
                {formatPONumber(orderNumber)}
              </div>
              <div data-testid="po-status-container" className={s.po_status_container}>
                <PurchaseOrderStatus
                  status={purchaseOrder?.statuses?.primary || ''}
                  secondaryStatuses={purchaseOrder?.statuses?.secondary}
                  poId={purchaseOrder.id}
                  poNumber={purchaseOrder.customerPo}
                  setPoHistoryDrawerState={setPoHistoryDrawerState}
                  poHistoryDrawerState={poHistoryDrawerState}
                />
                {overdue && (
                  <Tooltip title="Overdue">
                    <ClockCircleOutlined className={s.po_overdue} />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>

        <div data-testid="action-buttons-col" className={s.action_buttons_container}>
          {displayPOActionsDropdown()}

          {poAvailableActions?.includes('CANCEL_PO') &&
            user?.availableActions.includes('CANCEL_PO') && (
              <Button
                data-testid="cancel-order-button"
                className={s.po_cancel_order_button}
                onClick={toggleCancelPurchaseOrderModal}
              >
                Cancel Order
              </Button>
            )}
        </div>
      </Row>
      <ResendCustomizationModal
        salesOrdersWithCustomizations={salesOrders.filter((so) => so.hasCustomizations)}
        visible={showResendCustomizationModal}
        toggleVisibility={toggleResendCustomizationModal}
      />
      <CustomizationHistoryModal
        open={showCustomizationHistoryModal}
        close={() => setShowCustomizationHistoryModal(false)}
        salesOrders={purchaseOrder.salesOrders}
      />
      <PoHistoryDrawer {...poHistoryDrawerState} toggleDrawer={togglePOHistoryDrawer} />
    </>
  );
};

export default PurchaseOrderDetailsHeader;
