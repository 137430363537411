import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateSalesOrderShipmentMabdMutationVariables = Types.Exact<{
  input: Types.UpdateSalesOrderInput;
}>;


export type UpdateSalesOrderShipmentMabdMutation = { __typename?: 'RootMutationType', updateSalesOrder?: { __typename?: 'UpdateSalesOrderPayload', salesOrder?: { __typename?: 'SalesOrder', id: string, deliveryWindowEnd?: any | null } | null } | null };


export const UpdateSalesOrderShipmentMabdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateSalesOrderShipmentMABD"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateSalesOrderInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateSalesOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"deliveryWindowEnd"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateSalesOrderShipmentMabdMutation, UpdateSalesOrderShipmentMabdMutationVariables>;