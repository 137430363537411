import {
  convertWeekToPeriodWeek,
  convertWeekToPeriodWeekString,
  getFiscalWeekBorders,
  getWeeksForPeriod,
  getWeeksInFinancialYear
} from 'common/helpers/fiscalCalendar';
import { FiscalCalendarWeek } from 'common/interfaces';
import { range } from 'lodash-es';
import moment from 'moment';
import {
  CalendarValue,
  CalendarValueTemporary,
  Mode,
  isPeriod,
  isPeriodWeekRange,
  isPeriodWeekSpeicific
} from './types';
import { FiscalCalendarWeekInput } from 'graphql/__generated__/types';

export type FiscalCalendarWeekInputWithWeekOnly = Pick<
  FiscalCalendarWeekInput,
  'year' | 'period'
> & { week: number };
export type FiscalCalendarWeekInputWithPeriodWeekOnly = Pick<
  FiscalCalendarWeekInput,
  'year' | 'period'
> & { periodWeek: number };

type WeekYearPair = [number, number];

export const backendDateFormat = 'YYYY-MM-DD';

function joinArrays<T, U>(arr1: T[], arr2: U[]): [T, U][] {
  const result: [T, U][] = [];
  for (const item1 of arr1) {
    for (const item2 of arr2) {
      result.push([item1, item2]);
    }
  }
  return result;
}

function transformWeekYearPairToFiscalCalendarWeek([week, year]: [
  number,
  number
]): FiscalCalendarWeek {
  const { period, periodWeek } = convertWeekToPeriodWeek(week);
  const { start, end } = getFiscalWeekBorders(week, year);
  return {
    period,
    week: periodWeek,
    startDate: moment(start).format(backendDateFormat),
    endDate: moment(end).format(backendDateFormat)
  };
}

/**
 * @deprecated LEGACY FUNCTION FOR REPACK PLANNING ONLY SINCE IT REQUIRES WEEKS TO BE IN RANGE 1-4/5
 */
function transformWeekYearPairToFiscalCalendarWeekInput([week, year]: [
  number,
  number
]): FiscalCalendarWeekInputWithWeekOnly {
  const { period, periodWeek } = convertWeekToPeriodWeek(week);
  return {
    period,
    week: periodWeek,
    year
  };
}

function isCorrectWeekYearPair([week, year]: WeekYearPair): boolean {
  if (week < 1 || week > 53) return false;
  if (week < 53) return true;
  return getWeeksInFinancialYear(year) >= week;
}

export function isCorrectPeriod(period: number) {
  return 1 <= period && period <= 13;
}

function transformCalendarValueToWeekYearPairs(value: CalendarValue): WeekYearPair[] {
  const { years } = value;
  let weeks: number[] = [];

  // Figure out weeks
  if (isPeriodWeekSpeicific(value)) {
    weeks = [...value.weeks];
  } else if (isPeriodWeekRange(value)) {
    weeks = range(value.start, value.end + 1);
  } else if (isPeriod(value)) {
    weeks = value.periods
      .filter(isCorrectPeriod) // Filtering out incorrect weeks just in case
      .map((x) => getWeeksForPeriod(x))
      .flat();
  }

  return joinArrays(weeks, years).filter(isCorrectWeekYearPair);
}

export function transformCalendarValueToFiscalWeeks(value: CalendarValue): FiscalCalendarWeek[] {
  return transformCalendarValueToWeekYearPairs(value).map(
    transformWeekYearPairToFiscalCalendarWeek
  );
}

/**
 * @deprecated DON'T USE IT, IT'S FOR REPACK PLANNING ONLY
 * Transforms a CalendarValue into an array of FiscalCalendarWeekInput objects.
 * @param {CalendarValue} value - The calendar value to be transformed.
 * @returns {FiscalCalendarWeekInputWithWeekOnly[]} An array of FiscalCalendarWeekInput objects, each containing:
 *   - period (1-13): The fiscal period corresponding to the given week.
 *   - week (1-4): Actually it is a periodWeek. But it is what it is.
 *   - year: The year of the input value.
 */
export function transformCalendarValueToFiscalCalendarWeekInput(
  value: CalendarValue
): FiscalCalendarWeekInputWithWeekOnly[] {
  return transformCalendarValueToWeekYearPairs(value).map(
    transformWeekYearPairToFiscalCalendarWeekInput
  );
}

function transformWeekYearPairToPeriodWeekYear([week, year]: [
  number,
  number
]): FiscalCalendarWeekInputWithWeekOnly {
  const { period } = convertWeekToPeriodWeek(week);
  return {
    period,
    week,
    year
  };
}

/**
 * Transforms a CalendarValue into an array of FiscalCalendarWeekInput objects.
 * @param {CalendarValue} value - The calendar value to be transformed.
 * @returns {FiscalCalendarWeekInputWithWeekOnly[]} An array of FiscalCalendarWeekInput objects, each containing:
 *   - period (1-13): The fiscal period corresponding to the given week.
 *   - week (1-53): The week of the year.
 *   - year: The year of the input value.
 */
export function transformCalendarValueToPeriodWeekYear(
  value: CalendarValue
): FiscalCalendarWeekInputWithWeekOnly[] {
  return transformCalendarValueToWeekYearPairs(value).map(transformWeekYearPairToPeriodWeekYear);
}

export function transformCalendarValueToWholeYear(value: CalendarValue): FiscalCalendarWeekInput[] {
  const years = value.years;
  const weeks = range(1, 53);
  const weekYearPairs = joinArrays(weeks, years).filter(isCorrectWeekYearPair);

  return weekYearPairs.map(transformWeekYearPairToFiscalCalendarWeekInput);
}

export function stringifyCalendarValue(currentValue: CalendarValue | CalendarValueTemporary) {
  const isRangeSelector = currentValue.mode === Mode.PERIOD_WEEK && currentValue.isRange;
  const isSpecificSelector = currentValue.mode === Mode.PERIOD_WEEK && !currentValue.isRange;
  const isPeriodSelector = currentValue.mode === Mode.PERIOD;

  if (isRangeSelector) {
    const start = currentValue.start ? convertWeekToPeriodWeekString(currentValue.start) : '';
    const end = currentValue.end ? convertWeekToPeriodWeekString(currentValue.end) : '';
    return `${start} - ${end}`;
  } else if (isSpecificSelector) {
    return [...currentValue.weeks]
      .sort((a, b) => a - b)
      .map(convertWeekToPeriodWeekString)
      .join(', ');
  } else if (isPeriodSelector) {
    return [...currentValue.periods]
      .sort((a, b) => a - b)
      .map((x) => `P${x}`)
      .join(', ');
  } else {
    return '';
  }
}
