import React from 'react';
import s from './AlloyButton.module.scss';
import { Button as AntButton, ButtonProps } from 'ant5';
import clsx from 'clsx';

type ExtendedButtonProps = Omit<ButtonProps, 'type'> & {
  type?: ButtonProps['type'] | 'secondary';
};

export function AlloyButton({
  type: userType,
  className: userClassName,
  ...otherProps
}: ExtendedButtonProps) {
  const className = clsx({ [s.secondary]: userType === 'secondary' }, userClassName);
  // Hack to make it look good w/o much customizations
  const type = userType === 'secondary' ? 'text' : userType;

  return <AntButton className={className} type={type} {...otherProps} />;
}

// Re-exporting types
export type { ExtendedButtonProps as ButtonProps };
