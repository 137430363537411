import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AppliedFillRateReportFilters } from 'graphql/__generated__/types';
import {
  FillRatePurchaseOrdersReportDocument,
  FillRatePurchaseOrdersReportQuery
} from '../../gql/__generated__/fillRatePurchaseOrdersReport.query';
import {
  RateCellWithTargetCompact,
  onCellRateWithTargetCompact
} from 'components/RateCellWithTargetCompact/RateCellWithTarget';
import { compareDates, dateFormat } from 'common/helpers/date';
import s from './PurchaseOrdersTable.module.scss';
import { sumBy } from 'lodash-es';
import { PurchaseOrderDetailsTable } from '../PurchaseOrderDetailsTable/PurchaseOrderDetailsTable';
import RetailerDeliveryDestinationCell from 'components/RetailerDeliveryDestinationCell/RetailerDeliveryDestinationCell';
import { NOT_AVAILABLE } from 'common/constants';

type MetricItem = NonNullable<
  FillRatePurchaseOrdersReportQuery['fillRatePurchaseOrdersReport']
>['metrics'][number];

export const PurchaseOrdersTable = ({
  appliedFilters
}: {
  appliedFilters: AppliedFillRateReportFilters | undefined;
}) => {
  const { data, loading } = useQuery(FillRatePurchaseOrdersReportDocument, {
    variables: {
      appliedFilters: appliedFilters as AppliedFillRateReportFilters
    },
    skip: !appliedFilters
  });

  // We can do it in one pass, but it's more cumbersome
  const submittedQuantityTotal = useMemo(
    () => sumBy(data?.fillRatePurchaseOrdersReport?.metrics || [], 'submittedQuantity'),
    [data]
  );
  const acceptedQuantityTotal = useMemo(
    () => sumBy(data?.fillRatePurchaseOrdersReport?.metrics || [], 'acceptedQuantity'),
    [data]
  );
  const deliveredQuantityTotal = useMemo(
    () => sumBy(data?.fillRatePurchaseOrdersReport?.metrics || [], 'deliveredQuantity'),
    [data]
  );

  if (!data) {
    return null;
  }

  const columns: ColumnsType<MetricItem> = [
    {
      title: 'PO Number',
      key: 'customerPo',
      width: 110,
      render: (_, { customerPo }) => customerPo,
      sorter: (a, b) => (a?.customerPo || '').localeCompare(b?.customerPo || '')
    },
    {
      title: 'Trading Partner',
      key: 'tradingPartnerName',
      width: 260,
      render: (_, { tradingPartnerName }) => tradingPartnerName || NOT_AVAILABLE,
      sorter: (a, b) => (a?.tradingPartnerName || '').localeCompare(b?.tradingPartnerName || '')
    },
    {
      title: 'Status',
      key: 'status',
      width: 155,
      render: (_, { status }) => (status || NOT_AVAILABLE).toUpperCase(),
      sorter: (a, b) => (a?.status || '').localeCompare(b?.status || '')
    },
    {
      title: 'Customer',
      key: 'customer',
      width: 120,
      render: (_, { vendorMarketName }) => (vendorMarketName || NOT_AVAILABLE).toUpperCase(),
      sorter: (a, b) => (a?.vendorMarketName || '').localeCompare(b?.vendorMarketName || '')
    },
    {
      title: 'Ship-To',
      key: 'shipTo',
      width: 230,
      render: (_, { retailerDeliveryDestination }) => (
        <RetailerDeliveryDestinationCell
          retailerDeliveryDestination={retailerDeliveryDestination}
        />
      ),
      sorter: (a, b) =>
        (a?.retailerDeliveryDestination?.name || '').localeCompare(
          b?.retailerDeliveryDestination?.name || ''
        )
    },
    {
      title: 'Orig. QTY',
      width: 80,
      align: 'right',
      key: 'submittedQuantity',
      render: (_, { submittedQuantity }) => (
        <div className={s.number_cell}>{submittedQuantity.toLocaleString()}</div>
      ),
      sorter: (a, b) => a.submittedQuantity - b.submittedQuantity
    },
    {
      title: 'Accepted QTY',
      width: 80,
      align: 'right',
      key: 'acceptedQuantity',
      render: (_, { acceptedQuantity }) => (
        <div className={s.number_cell}>{acceptedQuantity.toLocaleString()}</div>
      ),
      sorter: (a, b) => a.acceptedQuantity - b.acceptedQuantity
    },
    {
      title: 'Delivered QTY',
      width: 80,
      align: 'right',
      key: 'deliveredQuantity',
      render: (_, { deliveredQuantity }) => (
        <div className={s.number_cell}>{deliveredQuantity.toLocaleString()}</div>
      ),
      sorter: (a, b) => a.deliveredQuantity - b.deliveredQuantity
    },
    {
      title: 'AFR',
      width: 90,
      key: 'acceptedFillRate',
      render: (_, { acceptedFillRate }) => <RateCellWithTargetCompact value={acceptedFillRate} />,
      onCell: ({ acceptedFillRate }) => onCellRateWithTargetCompact(acceptedFillRate),
      sorter: (a, b) => a.acceptedFillRate - b.acceptedFillRate
    },
    {
      title: 'SFR',
      width: 90,
      key: 'submittedFillRate',
      render: (_, { submittedFillRate }) => <RateCellWithTargetCompact value={submittedFillRate} />,
      onCell: ({ submittedFillRate }) => onCellRateWithTargetCompact(submittedFillRate),
      sorter: (a, b) => a.submittedFillRate - b.submittedFillRate
    },
    {
      title: 'PO Ship Date',
      width: 90,
      key: 'poShipDate',
      render: (_, { shipDate }) => dateFormat(shipDate) || NOT_AVAILABLE,
      sorter: (a, b) => compareDates(a.shipDate, b.shipDate)
    }
  ];

  const tableWidth = sumBy(columns, 'width');

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h2 className={s.title}>
          Purchase Orders (
          {loading ? 'Loading...' : (data?.fillRatePurchaseOrdersReport?.metrics || []).length})
        </h2>
      </div>
      <Table
        data-testid="fill_rate_orders_table"
        rowKey="id"
        className={`styled_report_table styled_report_table_top_pagination_margin legacy_borderless_bordered`}
        scroll={{ x: tableWidth }}
        sticky
        size="small"
        dataSource={data?.fillRatePurchaseOrdersReport?.metrics || []}
        columns={columns}
        pagination={{ position: ['topRight'], defaultPageSize: 50, showSizeChanger: true }}
        bordered
        loading={loading}
        expandable={{
          expandedRowRender: ({ customerPo, uniqueOrderId }) => (
            <div className={s.expanded_wrapper}>
              <PurchaseOrderDetailsTable
                customerPo={customerPo as string}
                uniqueOrderId={uniqueOrderId as string}
              />
            </div>
          ),
          // Don't show expander for rows w/o po, just in case
          rowExpandable: ({ uniqueOrderId }) => !!uniqueOrderId
        }}
        summary={(data) => {
          if (!data || data?.length === 0) return <></>;
          // We can do it in one pass, but it's more cumbersome
          const submittedQuantity = sumBy(data, 'submittedQuantity');
          const acceptedQuantity = sumBy(data, 'acceptedQuantity');
          const deliveredQuantity = sumBy(data, 'deliveredQuantity');

          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} className={s.total} colSpan={6}>
                  This page
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className={s.total}>
                  {submittedQuantity.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className={s.total}>
                  {acceptedQuantity.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className={s.total}>
                  {deliveredQuantity.toLocaleString()}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} className={s.total} colSpan={6}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className={s.total}>
                  {submittedQuantityTotal.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className={s.total}>
                  {acceptedQuantityTotal.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className={s.total}>
                  {deliveredQuantityTotal.toLocaleString()}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};
