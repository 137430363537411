import React from 'react';
import { Radio as AntRadio, RadioChangeEvent, RadioProps } from 'ant5';

export function AlloyRadio({ ...otherProps }: RadioProps) {
  return <AntRadio {...otherProps} />;
}

AlloyRadio.Group = AntRadio.Group;

// Re-exporting types
export type { RadioProps, RadioChangeEvent };
