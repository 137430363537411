import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProductsFillRateTotalsQueryVariables = Types.Exact<{
  filters: Types.ProductsFillRateFilters;
}>;


export type ProductsFillRateTotalsQuery = { __typename?: 'RootQueryType', productsFillRateTotals?: { __typename?: 'ProductsFillRateTotals', deliveredQuantity: number, acceptedFillRate?: any | null, acceptedQuantitySap: number, customerAcceptedQuantity: number, customerOriginalQuantity: number, grossValue?: any | null, remainingToShipQuantity: number, submittedFillRate?: any | null } | null };


export const ProductsFillRateTotalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"productsFillRateTotals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProductsFillRateFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productsFillRateTotals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deliveredQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedQuantitySap"}},{"kind":"Field","name":{"kind":"Name","value":"customerAcceptedQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"customerOriginalQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"grossValue"}},{"kind":"Field","name":{"kind":"Name","value":"remainingToShipQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"submittedFillRate"}}]}}]}}]} as unknown as DocumentNode<ProductsFillRateTotalsQuery, ProductsFillRateTotalsQueryVariables>;