import { useCombobox, useMultipleSelection } from 'downshift';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import s from '../Select.module.scss';
import { Label } from '../../../alloy/Label/Label';
import { DropdownList } from '../../DropdownList/DropdownList';
import dropdownArrowIcon from 'assets/icons/common/dropdown_arrow.svg';
import dropdownArrowDisabledIcon from 'assets/icons/common/dropdown_arrow_disabled.svg';
import removeIcon from 'assets/icons/common/remove.svg';
import { usePopper } from 'react-popper';
import { OptionType, SelectProps, optionTypeSorter } from '../Select';
import { isEqual } from 'lodash-es';
import { ChipsList } from 'components/alloy/ChipsList/ChipsList';
import { useElementSize, useElementBounding } from '@reactuses/core';
import { Size } from 'components/alloy/Utils';

export const MultipleSelect = ({
  allowClear,
  className,
  'data-testid': dataTestid,
  disabled,
  dropdownRender,
  error,
  label,
  options,
  placeholder,
  prefixIcon,
  required,
  size,
  value,
  onChange,
  loading = false,
  onBlur,
  style,
  sort
}: Omit<SelectProps<true>, 'multiple'>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [inputWidth] = useElementSize(inputRef);
  const [containerWidth] = useElementSize(containerRef);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null | undefined>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'auto-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-start', 'top-start'],
          rootBoundary: 'viewport',
          flipVariations: true,
          allowedAutoPlacements: ['bottom-start', 'top-start']
        }
      }
    ]
  });

  const { left, top } = useElementBounding(referenceElement);

  useEffect(() => {
    update && update();
  }, [left, top]);

  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);

  const items = useMemo(() => {
    const lowerCasedInputValue = inputValue.toLowerCase();
    return options.filter((option) => option.label.toLowerCase().includes(lowerCasedInputValue));
  }, [inputValue, options]);

  const { selectedItems, getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: value
        ?.map(
          (value) =>
            options.find((opt) => opt.value === value) || ({ value, label: value } as OptionType)
        )
        .sort(sort ?? optionTypeSorter),
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            onChange &&
              onChange(newSelectedItems?.sort(sort ?? optionTypeSorter).map((opt) => opt.value));
            break;
          default:
            break;
        }
      }
    });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.label : '';
    },
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            inputValue: ''
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          let newSelectedItems: OptionType[];
          if (newSelectedItem && !selectedItems.includes(newSelectedItem)) {
            newSelectedItems = [...selectedItems, newSelectedItem];
          } else {
            newSelectedItems = selectedItems
              .filter((item) => item !== newSelectedItem)
              .sort(sort ?? optionTypeSorter);
          }
          setInputValue('');
          if (!isEqual(selectedItems, newSelectedItems)) {
            onChange && onChange(newSelectedItems.map((opt) => opt.value));
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || '');

          break;
        default:
          break;
      }
    }
  });

  const selectedItemsValue = useMemo(() => {
    return selectedItems
      .map((item) => item.value)
      .filter((item): item is string => item !== null && item !== undefined);
  }, [selectedItems]);

  const tagsList = useMemo(() => {
    return selectedItems.map((selectedItemForRender, index) => ({
      label: `${selectedItemForRender.label}`,
      id: selectedItemForRender.value,
      onRemoveButtonClick: () => {
        removeSelectedItem(selectedItemForRender);
      },
      props: getSelectedItemProps({
        selectedItem: selectedItemForRender,
        index
      }),
      size: 'small' as Size
    }));
  }, [selectedItems]);

  return (
    <div data-testid={dataTestid} className={clsx(s.select, className)} style={style}>
      {!!label && (
        <Label
          label={label}
          required={required}
          focused={focused}
          error={error}
          {...getLabelProps({
            disabled
          })}
        />
      )}
      <div
        ref={setReferenceElement}
        className={clsx(s.control, {
          [s.has_icon_left]: !!prefixIcon
        })}
        data-size={size}
        aria-disabled={disabled || loading}
        data-focused={focused}
        data-open={isOpen}
        data-haserror={!!error}
        data-notempty={selectedItems.length > 0}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {prefixIcon && (
          <span className={s.prefix_icon} data-size={size}>
            <i>{prefixIcon}</i>
          </span>
        )}
        <div className={s.control_inner_wraper} ref={containerRef}>
          <ChipsList
            chips={tagsList}
            size="small"
            maxWidth={containerWidth - inputWidth - 12} // -4px padding -8px after input
          />
          <input
            className={s.input}
            data-hasvalue={selectedItems.length > 0}
            style={selectedItems.length > 0 && !inputRef.current?.value ? { width: '5px' } : {}}
            size={
              selectedItems.length > 0 || inputRef.current?.value
                ? (inputRef.current?.value.length || 0) + 1
                : (placeholder?.length || 0) + 1
            }
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isOpen,
                ref: inputRef,
                onClick: () => {
                  if (!isOpen) openMenu();
                },
                onFocus: () => {
                  setFocused(true);
                },
                onBlur: (event: any) => {
                  setFocused(false);
                  onBlur && onBlur(event);
                },
                placeholder: !selectedItems.length ? placeholder : undefined,
                disabled: disabled || loading
              })
            )}
          />
        </div>
        {allowClear && (
          <button
            className={s.remove_button}
            onClick={(event) => {
              event.stopPropagation();
              onChange && onChange([]);
              setInputValue('');
            }}
            type="button"
            disabled={disabled || loading}
          >
            <img src={removeIcon} />
          </button>
        )}
        <button
          aria-label="toggle menu"
          className={s.toggle_button}
          type="button"
          style={styles.arrow}
          {...getToggleButtonProps({
            disabled: disabled || loading
          })}
        >
          <img
            src={disabled || loading ? dropdownArrowDisabledIcon : dropdownArrowIcon}
            className={clsx({ [s.rotated]: isOpen })}
          />
        </button>
        <DropdownList
          data-testid={`${dataTestid}-dropdown`}
          dropdownRender={dropdownRender}
          menuProps={{
            ...getMenuProps({
              ref: setPopperElement
            }),
            style: { ...styles.popper, zIndex: 1001, minWidth: referenceElement?.clientWidth },
            ...attributes.popper
          }}
          items={items.map((item, index) => ({
            ...item,
            props: getItemProps({ item, index, disabled: item.disabled })
          }))}
          isOpen={isOpen}
          selectedItem={selectedItemsValue}
          highlightedIndex={highlightedIndex}
        />
      </div>
    </div>
  );
};
