import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RddAdvancedFiltersQueryVariables = Types.Exact<{
  advancedFilter?: Types.InputMaybe<Types.RddAdvancedFilters>;
  filter?: Types.InputMaybe<Types.RddFilters>;
}>;


export type RddAdvancedFiltersQuery = { __typename?: 'RootQueryType', retailerDeliveryDestinations?: { __typename?: 'RetailerDeliveryDestinationConnection', edges: Array<{ __typename?: 'RetailerDeliveryDestinationEdge', node?: { __typename?: 'RetailerDeliveryDestination', id: string, externalId?: string | null, name?: string | null, active?: boolean | null, tradingPartners: Array<{ __typename?: 'TradingPartner', id: string, deliveryType?: Types.DeliveryType | null }> } | null }> } | null };


export const RddAdvancedFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"rddAdvancedFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"advancedFilter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RddAdvancedFilters"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RddFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"retailerDeliveryDestinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"10000"}},{"kind":"Argument","name":{"kind":"Name","value":"advancedFilter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"advancedFilter"}}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"tradingPartners"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"deliveryType"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<RddAdvancedFiltersQuery, RddAdvancedFiltersQueryVariables>;