export enum Mode {
  PERIOD = 'PERIOD',
  PERIOD_WEEK = 'PERIOD_WEEK'
}
type CalendarValueYear = {
  years: number[];
};
type CalendarValuePeriod = CalendarValueYear & {
  mode: Mode.PERIOD;
  periods: number[];
};
type CalendarValuePeriodWeekRangeTemporary = CalendarValueYear & {
  mode: Mode.PERIOD_WEEK;
  isRange: true;

  start?: number;
  end?: number;
};

type CalendarValuePeriodWeekRange = Required<CalendarValuePeriodWeekRangeTemporary>;

type CalendarValuePeriodWeekSpecific = CalendarValueYear & {
  mode: Mode.PERIOD_WEEK;
  isRange: false;

  weeks: number[];
};

export type CalendarValueTemporary =
  | CalendarValuePeriod
  | CalendarValuePeriodWeekRangeTemporary
  | CalendarValuePeriodWeekSpecific;

// In process of selction, start/end might be undefined, but result should always have start/end.
export type CalendarValue =
  | CalendarValuePeriod
  | CalendarValuePeriodWeekRange
  | CalendarValuePeriodWeekSpecific;

export function isPeriodWeekRange(value: CalendarValue): value is CalendarValuePeriodWeekRange {
  return (
    !!(value as CalendarValuePeriodWeekRange).end &&
    !!(value as CalendarValuePeriodWeekRange).start &&
    value.mode === Mode.PERIOD_WEEK &&
    value.isRange
  );
}

export function isPeriodWeekSpeicific(
  value: CalendarValue
): value is CalendarValuePeriodWeekSpecific {
  return (
    !!(value as CalendarValuePeriodWeekSpecific).weeks &&
    value.mode === Mode.PERIOD_WEEK &&
    !value.isRange
  );
}

export function isPeriod(value: CalendarValue): value is CalendarValuePeriod {
  return !!(value as CalendarValuePeriod).periods && value.mode === Mode.PERIOD;
}
