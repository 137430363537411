import React from 'react';
import './styles.scss'; //TODO: convert to module.scss file
import { ApolloError } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { AlignType } from 'common/types';
import { ExportType } from 'pages/OrdersPage/components/ExportItemsModal/ExportItemsModal';
import { PoExportItem } from 'graphql/__generated__/types';
import { dateFormat } from 'common/helpers/date';
import { AlloyTable, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloySwitch } from 'components/ui/AlloySwitch/AlloySwitch';

interface ExportItemsTableProps {
  showIndividualItems: boolean;
  setShowIndividualItems: (value: boolean) => void;
  newCsvData: PoExportItem[];
  type: ExportType;
  exportLoading: boolean;
  exportError: ApolloError | undefined;
}

const ExportItemsTable = ({
  showIndividualItems,
  setShowIndividualItems,
  type,
  exportLoading,
  exportError,
  newCsvData
}: ExportItemsTableProps) => {
  // there will be two versions of the table to display: items showing multiple POs and items showing individual POs
  // user can view these two versions one at a time with toggling the Aggregate by item button, which will change the table data when clicked

  if (!exportLoading && !newCsvData && !exportError) return null;
  if (exportLoading) return <LoaderSpinner />;
  if (exportError)
    return (
      <div>
        <ErrorDisplay error={exportError} />
      </div>
    );

  let columns: ColumnType<PoExportItem>[] = [
    {
      title: 'External ID',
      dataIndex: 'externalId',
      render: (_, record) => record.externalId || ''
    },
    {
      title: 'Sold To #',
      dataIndex: 'soldTo',
      align: 'center' as AlignType,
      render: (_, record) => record.soldTo || ''
    },
    {
      title: 'RDD',
      dataIndex: 'rdd',
      align: 'center' as AlignType,
      render: (_, record) => dateFormat(record.rdd) || ''
    },
    {
      title: 'SAP Mat #',
      dataIndex: 'sapMaterialId',
      align: 'center' as AlignType,
      render: (_, record) => record.sapMaterialId || ''
    },
    {
      title: 'UPC',
      dataIndex: 'upc',
      align: 'center' as AlignType,
      render: (_, record) => record.upc || ''
    },
    {
      title: 'Original Order Qty',
      dataIndex: 'quantityOriginal',
      align: 'center' as AlignType,
      render: (_, record) => record.quantityOriginal || ''
    },
    {
      title: 'Ordered Qty',
      dataIndex: 'quantityOrdered',
      align: 'center' as AlignType,
      render: (_, record) => record.quantityOrdered || ''
    },
    {
      title: 'PO #',
      dataIndex: 'customerPo',
      align: 'center' as AlignType,
      render: (_, record) => record.customerPo || ''
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      align: 'center' as AlignType,
      render: (_, record) => record.orderDate || ''
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, record) => record.description || ''
    },
    {
      title: 'Ship-To location',
      dataIndex: 'shipTo',
      align: 'center' as AlignType,
      render: (_, record) => record.shipTo || ''
    },
    {
      title: 'DC',
      dataIndex: 'distributionCenters',
      render: (_, record) => record.distributionCenters.join(', ') || ''
    },
    {
      title: 'Trading Partner',
      dataIndex: 'tp',
      render: (_, record) => record.tp || ''
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (_, record) => Number(record.price).toFixed(2)
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      render: (_, record) => record.currency || ''
    }
  ];

  let toRemove = [];
  if (type === 'INVALID') {
    //invalid items export should not include ship to
    toRemove = ['soldTo', 'rdd', 'quantityOriginal'];
  } else {
    toRemove = ['externalId', 'orderDate'];
  }

  toRemove.forEach((targetKey) => {
    const targetIndex = columns.findIndex((column) => column?.dataIndex === targetKey);
    if (targetIndex >= 0) columns.splice(targetIndex, 1);
  });

  const displayAggregateItemsButton = () => {
    if (type === 'INVALID') {
      return (
        <div className="aggregate-items-toggle">
          <AlloySwitch
            data-testid="aggregate-items-toggle"
            checked={!showIndividualItems}
            onChange={(value) => setShowIndividualItems(!value)}
          />
          <span className="aggregate-items-toggle-title">Aggregate by item</span>
        </div>
      );
    }
  };

  return (
    <div className="item-export-table">
      {displayAggregateItemsButton()}
      <AlloyTable
        rowKey={(record) => `${record.id}_${record.customerPo}_${record.customerPo}`}
        pagination={false}
        dataSource={newCsvData}
        columns={columns}
        className="legacy_borderless_bordered"
        bordered
      />
    </div>
  );
};

export default ExportItemsTable;
