import React from 'react';
import { AddingDistributionCenterStepper } from './components/AddingDistributionCenterStepper';
import { DistributionCenterForm } from '../forms/DistributionCenterForm/DistributionCenterForm';
import { DistributionCenterQuery } from 'pages/DistributionCentersPage/gql/__generated__/distributionCenter.query';
import { EditingDistributionCenterTabs } from './components/EditDistributionCenterTabs';

interface EditDistributionCenterModalProps {
  distributionCenter?: DistributionCenterFullData;
  onClose: (id?: string) => void;
  isDCFacilityManagementEnabled: boolean | undefined;
}

export type DistributionCenterFullData = DistributionCenterQuery['distributionCenter'];

export const EditDistributionCenterModal = ({
  distributionCenter,
  onClose,
  isDCFacilityManagementEnabled
}: EditDistributionCenterModalProps) => {
  return (
    <DistributionCenterForm onClose={onClose} distributionCenter={distributionCenter}>
      {(upserting: boolean) =>
        !distributionCenter?.id ? (
          <AddingDistributionCenterStepper
            handleClose={onClose}
            upserting={upserting}
            visible={!!distributionCenter}
            isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
          />
        ) : (
          <EditingDistributionCenterTabs
            onClose={onClose}
            upserting={upserting}
            visible={!!distributionCenter}
            isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
          />
        )
      }
    </DistributionCenterForm>
  );
};
