import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpsertRetailerDeliveryDestinationsMutationVariables = Types.Exact<{
  input: Types.UpsertRetailerDeliveryDestinationsInput;
}>;


export type UpsertRetailerDeliveryDestinationsMutation = { __typename?: 'RootMutationType', upsertRetailerDeliveryDestinations?: { __typename?: 'UpsertRetailerDeliveryDestinationsPayload', retailerDeliveryDestinations: Array<{ __typename?: 'RetailerDeliveryDestination', id: string } | null> } | null };


export const UpsertRetailerDeliveryDestinationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"upsertRetailerDeliveryDestinations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertRetailerDeliveryDestinationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertRetailerDeliveryDestinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"retailerDeliveryDestinations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<UpsertRetailerDeliveryDestinationsMutation, UpsertRetailerDeliveryDestinationsMutationVariables>;