import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryReconciliationDiscrepanciesTotalsQueryVariables = Types.Exact<{
  discrepancyMainKeys?: Types.InputMaybe<Array<Types.InventoryReconciliationDiscrepanciesSapMaterialIdPlantKey> | Types.InventoryReconciliationDiscrepanciesSapMaterialIdPlantKey>;
  filters: Types.InventoryReconciliationFilters;
}>;


export type InventoryReconciliationDiscrepanciesTotalsQuery = { __typename?: 'RootQueryType', inventoryReconciliationDiscrepanciesTotals?: { __typename?: 'InventoryReconciliationDiscrepanciesTotals', discrepancy: any, openVariance: any, sapOnHand: any, wmsOnHand: any, byReason: Array<{ __typename?: 'InventoryReconciliationDiscrepanciesTotalsByReason', reason?: Types.InventoryDiscrepancyReason | null, value: any }> } | null };


export const InventoryReconciliationDiscrepanciesTotalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryReconciliationDiscrepanciesTotals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"discrepancyMainKeys"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryReconciliationDiscrepanciesSapMaterialIdPlantKey"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryReconciliationFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryReconciliationDiscrepanciesTotals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}},{"kind":"Argument","name":{"kind":"Name","value":"discrepancyMainKeys"},"value":{"kind":"Variable","name":{"kind":"Name","value":"discrepancyMainKeys"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"byReason"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}},{"kind":"Field","name":{"kind":"Name","value":"discrepancy"}},{"kind":"Field","name":{"kind":"Name","value":"openVariance"}},{"kind":"Field","name":{"kind":"Name","value":"sapOnHand"}},{"kind":"Field","name":{"kind":"Name","value":"wmsOnHand"}}]}}]}}]} as unknown as DocumentNode<InventoryReconciliationDiscrepanciesTotalsQuery, InventoryReconciliationDiscrepanciesTotalsQueryVariables>;