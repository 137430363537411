import { ShipToReleaseIdentifier } from 'graphql/__generated__/types';
import React from 'react';
import { SourcingRule, TradingPartner } from '../../../../TradingPartnerViewDrawer';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import s from './GeneralSettingsViewTab.module.scss';
import { getReleaseIdentifierName } from 'pages/TradingPartnersPage/components/forms/GeneralSettingsTab/GeneralSettingsTab';
import { SourcingRules } from './components/SourcingRules/SourcingRules';

export const GeneralSettingsViewTab = ({
  tradingPartner,
  defaultSourcingRules
}: {
  tradingPartner: TradingPartner;
  defaultSourcingRules: SourcingRule[];
}) => {
  const disabled = (
    <div className={s.disabled_container}>
      <ViewField title="Disabled" value={false} type="SWITCH" />
    </div>
  );

  const configurationDetails = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="External ID" value={tradingPartner?.externalId} />
        <ViewField type="INPUT" title="Name" value={tradingPartner?.name} />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Retailer Channel"
          value={tradingPartner?.vendorMarket.name}
        />
        <ViewField type="INPUT" title="Business Unit" value={tradingPartner?.businessUnit?.name} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Delivery Type" value={tradingPartner?.deliveryType} />
        <ViewField
          type="INPUT"
          title="PO Acknowledgement Transport"
          value={tradingPartner?.purchaseOrderAcknowledgementTransport}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Ship-To Release Identifier"
          value={tradingPartner?.shipToReleaseIdentifier}
        />
        <ViewField
          type="INPUT"
          title="Measurement System"
          value={tradingPartner?.measurementSystem}
        />
      </div>
      <div className={s.subtitle}>Active Features</div>
      <div className={s.features_container}>
        <ViewField
          type="CHECKBOX"
          title="Send Initial Purchase Order Acknowledgment"
          value={!!tradingPartner?.sendInitialPurchaseOrderAcknowledgment}
        />
        <ViewField
          type="CHECKBOX"
          title="Send Purchase Order Processing State"
          value={!!tradingPartner.sendPurchaseOrderProcessingState}
        />
      </div>
    </>
  );

  const processingFlow = tradingPartner?.processingFlow ? (
    <>
      <div style={{ marginBottom: '16px' }}>
        <ViewField
          type="CHECKBOX"
          title="Auto Release"
          value={!!tradingPartner.processingFlow.autoRelease}
        />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Reset PO" value={tradingPartner.processingFlow.resetPo} />
        <ViewField
          type="INPUT"
          title="Route To Optimal DC"
          value={tradingPartner.processingFlow.routeToOptimalDc}
        />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Ingest PO" value={tradingPartner.processingFlow.ingestPo} />
        <ViewField
          type="INPUT"
          title="Release Acknowledgement"
          value={tradingPartner.processingFlow.releaseAcknowledgement}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Prepare PO"
          value={tradingPartner.processingFlow.preparePo}
        />
        <ViewField
          type="INPUT"
          title="Release Exchange Return"
          value={tradingPartner.processingFlow.releaseExchangeReturn}
        />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Update PO" value={tradingPartner.processingFlow.updatePo} />
        <ViewField
          type="INPUT"
          title="Release Shipment"
          value={tradingPartner.processingFlow.releaseShipment}
        />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Cancel PO" value={tradingPartner.processingFlow.cancelPo} />
        <ViewField
          type="INPUT"
          title="Release Inventory"
          value={tradingPartner.processingFlow.releaseInventory}
        />
      </div>
    </>
  ) : (
    disabled
  );

  const storeConfig = tradingPartner?.storeConfig ? (
    <div>
      <ViewField
        title="Required IDs"
        data-testid="view-trading-partner-store-config-required-ids"
        value={
          tradingPartner.storeConfig.requiredIds
            ?.filter((id): id is ShipToReleaseIdentifier => !!id)
            .map((field) => getReleaseIdentifierName(field)) || []
        }
        type="LIST"
      />
    </div>
  ) : (
    disabled
  );

  const poValidationConfig = tradingPartner?.poValidationConfig ? (
    <>
      <div className={s.row}>
        <ViewField
          title="Price Mismatch"
          value={tradingPartner.poValidationConfig.priceMismatch}
          type="INPUT"
        />
        <ViewField
          title="Wrong UOM"
          value={tradingPartner.poValidationConfig.wrongUom}
          type="INPUT"
        />
      </div>
      <ViewField
        title="Under MOQ"
        value={tradingPartner.poValidationConfig.underMoq}
        type="INPUT"
      />
    </>
  ) : (
    disabled
  );

  const sourcingRules =
    tradingPartner.sourcingRules.length > 0 ? (
      <SourcingRules
        defaultSourcingRules={defaultSourcingRules}
        sourcingRules={tradingPartner.sourcingRules}
      />
    ) : (
      disabled
    );

  const additionalSettings = (
    <>
      <div className={s.row}>
        <ViewField
          title="Incoming Unit Of Measurement Override"
          value={tradingPartner?.incomingUnitOfMeasurementOverride}
          type="INPUT"
        />
        <ViewField
          title="Internal Product ID Qualifier"
          value={tradingPartner?.internalProductIdQualifier}
          type="INPUT"
        />
      </div>
      <div className={s.row}>
        <ViewField
          title="Outgoing Unit Of Measurement Override"
          value={tradingPartner?.outgoingUnitOfMeasurementOverride}
          type="INPUT"
        />
        <ViewField
          title="Product ID Qualifier"
          value={tradingPartner?.productIdQualifier}
          type="INPUT"
        />
      </div>
      <div className={s.row}>
        <ViewField title="Release Method" value={tradingPartner?.releaseMethod} type="INPUT" />
        <ViewField
          title="Order Item Rounding"
          value={tradingPartner?.orderItemRounding}
          type="INPUT"
        />
      </div>
    </>
  );

  return (
    <>
      <ViewRow title="Configuration details" content={configurationDetails} bordered={true} />
      <ViewRow title="Processing Flow" content={processingFlow} bordered={true} />
      <ViewRow title="Ship-To Required Fields" content={storeConfig} bordered={true} />
      <ViewRow title="PO Validation Config" content={poValidationConfig} bordered={true} />
      <ViewRow title="Release Rules" content={sourcingRules} bordered={true} />
      <ViewRow title="Additional Settings" content={additionalSettings} bordered={false} />
    </>
  );
};
