import { Select, Spin } from 'antd';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { RoundedRadioButton } from 'components/RoundedRadioButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './FillRateReport.module.scss';
import { BusinessUnitsFrt } from './components/BusinessUnitsFrt/BusinessUnitsFrt';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FiscalCalendarWeek } from 'common/interfaces';
import { useHistory } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import LoaderSpinner from 'components/LoaderSpinner';
import moment, { Moment } from 'moment';
import { StyledReportTable } from './components/common/StyledReportTable';
import { BusinesUnitsLineChart } from './components/BusinesUnitsLineChart/BusinesUnitsLineChart';
import { ColumnsPlotDataView } from './components/ColumnsPlotDataView/ColumnsPlotDataView';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip as ChartTooltip,
  LineController,
  BarController
} from 'chart.js';
import { TotalMetricsView } from './components/TotalMetricsView/TotalMetricsView';
import { BrandFillRateColumnChart } from './components/BrandFillRateColumnChart/BrandFillRateColumnChart';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DcCutTreeMap } from './components/DcCutTreeMap/DcCutTreeMap';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { PurchaseOrdersTable } from './components/PurchaseOrdersTable/PurchaseOrdersTable';
import { AppliedFillRateReportFilters, OrderRefDateType } from 'graphql/__generated__/types';
import { getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { transformCalendarValueToFiscalWeeks } from 'components/PeriodCalendar/helpers';
import { getPeriodName } from './components/helpers';
import { uniqBy } from 'lodash-es';
import {
  FillRateReportFiltersDocument,
  FillRateReportFiltersQuery
} from './gql/__generated__/fillRateReportFilters.query';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { PreviousFillRateSummaryReportDocument } from './gql/__generated__/previousFillRateSummaryReport.query';
import { FillRateSummaryReportDocument } from './gql/__generated__/fillRateSummaryReport.query';
import { FillRateReportMetricFragment } from './gql/__generated__/fillRateReportMetric.fragment';
import { BusinessUnit } from './types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ChartTooltip,
  Legend,
  LineController,
  BarController,
  annotationPlugin,
  TreemapController,
  TreemapElement
);

type VendorMarket = NonNullable<
  FillRateReportFiltersQuery['availableFillRateReportFilters']
>['vendorMarkets'][number];

const { Option } = Select;

interface Filters {
  bu?: string | string[] | null;
  customers?: string | string[] | null;
  dateType?: string | null;
}

const referenceDateTitles = {
  SHIPMENT_DATE: 'PO Ship Date',
  PURCHASE_ORDER_DATE: 'Order Creation Date'
} as {
  [key: string]: string;
};

const sumMetrics = (m1: FillRateReportMetricFragment, m2: FillRateReportMetricFragment) => {
  const newDeliveredQuantity = m1.deliveredQuantity + m2.deliveredQuantity;
  const newSubmittedQuantity = m1.submittedQuantity + m2.submittedQuantity;
  const newAcceptedQuantity = m1.acceptedQuantity + m2.acceptedQuantity;
  return {
    acceptedQuantity: newAcceptedQuantity,
    submittedQuantity: newSubmittedQuantity,
    deliveredQuantity: newDeliveredQuantity,
    cutQuantity: m1.cutQuantity + m2.cutQuantity,
    acceptedFillRate: Math.round((newDeliveredQuantity / newAcceptedQuantity) * 10000) / 100,
    submittedFillRate: Math.round((newDeliveredQuantity / newSubmittedQuantity) * 10000) / 100
  };
};

const getBuFilterValue = (location: Filters, subBusinessUnits: BusinessUnit[]) => {
  const bus = (typeof location.bu === 'string' ? [location.bu] : location.bu) || [];
  return bus?.includes('all')
    ? subBusinessUnits.map((bu) => bu.id)
    : subBusinessUnits.reduce((result, current) => {
        if (
          bus.includes(current.id) ||
          (current.parentBusinessUnit && bus.includes(current.parentBusinessUnit?.id))
        ) {
          result.push(current.id);
        }
        return result;
      }, [] as string[]);
};

const getCustomerFilterValue = (location: Filters, customersList: VendorMarket[]) => {
  const customers =
    (typeof location.customers === 'string' ? [location.customers] : location.customers) || [];
  return customers?.includes('all') ? customersList.map((customer) => customer.id) : customers;
};

const getPreviousPeriodsFilterValue = (
  currentPeriods: FiscalCalendarWeek[],
  fiscalCalendarWeeks: FiscalCalendarWeek[]
) => {
  let minCurrentPeriod: FiscalCalendarWeek;
  currentPeriods.forEach((period) => {
    if (
      !minCurrentPeriod ||
      period.period < minCurrentPeriod.period ||
      (period.period === minCurrentPeriod.period && period.week < minCurrentPeriod.week)
    ) {
      minCurrentPeriod = period;
    }
  });
  const sortedFiscalCalendarWeeks = fiscalCalendarWeeks.sort((fcw1, fcw2) =>
    fcw1.period > fcw2.period || (fcw1.period === fcw2.period && fcw1.week > fcw2.week)
      ? 1
      : fcw1.period === fcw2.period && fcw1.week === fcw2.week
      ? 0
      : -1
  );
  const startIntervalIndex = sortedFiscalCalendarWeeks.findIndex(
    (fcw) => fcw.period === minCurrentPeriod?.period && fcw.week === minCurrentPeriod.week
  );
  return currentPeriods.length <= startIntervalIndex
    ? sortedFiscalCalendarWeeks.slice(
        startIntervalIndex - currentPeriods.length,
        startIntervalIndex
      )
    : undefined;
};

const getTopLevelBusinessUnits = (businessUnits: BusinessUnit[]) =>
  // only one parents level
  businessUnits.reduce((result, current) => {
    if (current.parentBusinessUnit) {
      if (!result.find((bu) => bu.id === current.parentBusinessUnit?.id)) {
        result.push(current.parentBusinessUnit);
      }
    } else {
      result.push(current);
    }
    return result;
  }, [] as BusinessUnit[]);

const calculateAplliedFilters = (
  subBusinessUnits: BusinessUnit[] | undefined,
  topLevelBusinessUnits: BusinessUnit[] | undefined,
  customers: VendorMarket[] | undefined,
  history: { location: { search: string } },
  dates: CalendarValue
) => {
  if (
    subBusinessUnits &&
    topLevelBusinessUnits &&
    dates &&
    customers &&
    history.location.search !== ''
  ) {
    const location = parse(history.location.search) as Filters;
    const periods = transformCalendarValueToFiscalWeeks(dates);
    return {
      subBusinessUnitIds: getBuFilterValue(location, subBusinessUnits),
      fiscalCalendarWeeks: periods,
      referenceDateType: location.dateType,
      vendorMarketIds: getCustomerFilterValue(location, customers)
    };
  } else return undefined;
};

const { week: currentWeek } = getFinancialInfo(new Date());

export const FillRateReport = () => {
  // TODO: I can't easily put it back to query params, but we need to put it back as soon as it's needed. For now it can stay in state.
  const [dates, setDates] = useState<CalendarValue>({
    mode: Mode.PERIOD_WEEK,
    isRange: true,
    years: [2023],
    start: currentWeek,
    end: currentWeek
  });

  const history = useHistory();
  const location = parse(history.location.search) as Filters;
  // Parent BUs and sub BU without parents
  const [lastDataUpdated, setLastDataUpdated] = useState<Moment>();

  const [columnsPlotType, setColumnsPlotType] = useState<'bu' | 'category' | 'brand'>('bu');
  const [selectedBuForBrandsChart, setSelectedBuForBrandsChart] = useState<string>();

  const [previousPeriodStrings, setPreviousPeriodStrings] = useState<string[]>();

  const { loading: filtersLoading, data: filterData } = useQuery(FillRateReportFiltersDocument, {
    onCompleted: (data) => {
      const newTopLevelBusinessUnits = getTopLevelBusinessUnits(
        data?.availableFillRateReportFilters?.subBusinessUnits || []
      );
      setSelectedBuForBrandsChart(newTopLevelBusinessUnits[0]?.id);
    }
  });

  const customers = useMemo(
    () => filterData?.availableFillRateReportFilters?.vendorMarkets || undefined,
    [filterData]
  );
  const subBusinessUnits = useMemo(
    () => filterData?.availableFillRateReportFilters?.subBusinessUnits || undefined,
    [filterData]
  );
  const topLevelBusinessUnits = useMemo(
    () =>
      getTopLevelBusinessUnits(filterData?.availableFillRateReportFilters?.subBusinessUnits || []),
    [filterData]
  );
  const fiscalCalendarWeeks = useMemo(
    () =>
      filterData?.availableFillRateReportFilters?.fiscalCalendarWeeks.map((fcw) => ({
        period: fcw.period,
        week: fcw.week,
        startDate: fcw.startDate,
        endDate: fcw.endDate
      })),
    [filterData]
  );
  const referenceDateTypes = useMemo(() => {
    return filterData?.availableFillRateReportFilters?.referenceDateTypes
      .filter((x) => x !== 'DELIVERY_DATE') // This filter causes 500 anyway. TODO: figure out if we need it
      .sort((v1, v2) => v2.localeCompare(v1));
  }, [filterData]);

  const [loadReportData, { data, loading }] = useLazyQuery(FillRateSummaryReportDocument, {
    onCompleted: () => {
      setLastDataUpdated(moment());
    }
  });
  const [loadPreviousReportData, { data: previousData, loading: previousLoading }] = useLazyQuery(
    PreviousFillRateSummaryReportDocument,
    {}
  );

  const changeFilters = useCallback(
    (changedFilters: Partial<Filters>) => {
      history.push({
        search: stringify({
          ...parse(history.location.search),
          ...changedFilters
        })
      });
    },
    [history]
  );

  useEffect(() => {
    if (history.location.search === '' && fiscalCalendarWeeks && fiscalCalendarWeeks.length > 0) {
      changeFilters({
        bu: 'all',
        customers: 'all',
        dateType: 'SHIPMENT_DATE'
      });
    }
  }, [history.location.search, fiscalCalendarWeeks, changeFilters]);

  useEffect(() => {
    if (
      subBusinessUnits &&
      topLevelBusinessUnits &&
      fiscalCalendarWeeks &&
      customers &&
      history.location.search !== ''
    ) {
      const location = parse(history.location.search) as Filters;
      const periods = transformCalendarValueToFiscalWeeks(dates);
      loadReportData({
        variables: {
          appliedFilters: {
            subBusinessUnitIds: getBuFilterValue(location, subBusinessUnits),
            fiscalCalendarWeeks: periods,
            referenceDateType: location.dateType as OrderRefDateType,
            vendorMarketIds: getCustomerFilterValue(location, customers)
          }
        }
      });
      if (dates.mode === Mode.PERIOD_WEEK && dates.isRange) {
        const previousPeriods = getPreviousPeriodsFilterValue(periods, fiscalCalendarWeeks);
        if (previousPeriods) {
          setPreviousPeriodStrings([
            getPeriodName(previousPeriods[0]),
            getPeriodName(previousPeriods[previousPeriods?.length - 1])
          ]);
          loadPreviousReportData({
            variables: {
              appliedFilters: {
                subBusinessUnitIds: getBuFilterValue(location, subBusinessUnits),
                fiscalCalendarWeeks:
                  getPreviousPeriodsFilterValue(periods, fiscalCalendarWeeks) || [],
                referenceDateType: location.dateType as OrderRefDateType,
                vendorMarketIds: getCustomerFilterValue(location, customers)
              }
            }
          });
        }
      }
    }
  }, [
    topLevelBusinessUnits,
    subBusinessUnits,
    customers,
    fiscalCalendarWeeks,
    loadReportData,
    history.location.search,
    loadPreviousReportData,
    dates
  ]);

  if (!topLevelBusinessUnits || !subBusinessUnits || !customers || !fiscalCalendarWeeks)
    return <LoaderSpinner />;

  const getDataForColumnBrandsChart = (buId: string) => {
    if (subBusinessUnits.find((bu) => bu.id === buId)) {
      return data?.fillRateSummaryReport?.metricsBySubBusAndCatalogGroups.find(
        (buMetrics) => buMetrics.subBusinessUnitId === selectedBuForBrandsChart
      );
    } else {
      const subBuIds = subBusinessUnits.reduce((result, current) => {
        if (current.parentBusinessUnit && current.parentBusinessUnit?.id === buId) {
          result.push(current.id);
        }
        return result;
      }, [] as string[]);

      const result = {
        subBusinessUnitId: buId,
        metricsByBrands: [] as {
          catalogGroupName?: string | null | undefined;
          metrics: FillRateReportMetricFragment;
        }[]
      };

      data?.fillRateSummaryReport?.metricsBySubBusAndCatalogGroups.forEach((buMetric) => {
        if (subBuIds.includes(buMetric.subBusinessUnitId)) {
          buMetric.metricsByBrands.forEach((brandMetric) => {
            const existing = result.metricsByBrands.find(
              (metric) => metric.catalogGroupName === brandMetric.catalogGroupName
            );
            if (existing) {
              existing.metrics = sumMetrics(existing.metrics, brandMetric.metrics);
            } else {
              result.metricsByBrands.push(brandMetric);
            }
          });
        }
      });
      return result;
    }
  };

  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          Supply Chain Intelligence
          {lastDataUpdated && (
            <span className={s.last_update_date}>
              Data last updated: {lastDataUpdated.format('MM/DD/YYYY hh:mm:ss A')}
            </span>
          )}
        </div>
        <div className={s.page_filters}>
          <Select
            loading={filtersLoading}
            className={s.select}
            value={
              typeof location.customers === 'string' ? [location.customers] : location.customers
            }
            mode="multiple"
            onChange={(value) => {
              if (value.length === 0) {
                changeFilters({ customers: ['all'] });
              } else if (value.includes('all')) {
                if ((location.customers || []).includes('all')) {
                  changeFilters({ customers: value.filter((v) => v !== 'all') });
                } else {
                  changeFilters({ customers: ['all'] });
                }
              } else {
                changeFilters({ customers: value });
              }
            }}
          >
            <Option value="all">All Customers</Option>
            {(customers || []).map((customer) => (
              <Option key={customer.id} value={customer.id}>
                {customer.name}
              </Option>
            ))}
          </Select>
          <Select
            loading={filtersLoading}
            className={s.select}
            value={typeof location.bu === 'string' ? [location.bu] : location.bu}
            mode="multiple"
            onChange={(value) => {
              if (value.length === 0) {
                changeFilters({ bu: ['all'] });
              } else if (value.includes('all')) {
                if ((location.bu || []).includes('all')) {
                  changeFilters({ bu: value.filter((v) => v !== 'all') });
                } else {
                  changeFilters({ bu: ['all'] });
                }
              } else {
                changeFilters({ bu: value });
              }
            }}
          >
            <Option value="all">All BUs</Option>
            {(topLevelBusinessUnits || []).map((bu) => (
              <Option key={bu.id} value={bu.id}>
                {bu.name}
              </Option>
            ))}
          </Select>
          <PeriodCalendar loading={filtersLoading} value={dates} onChange={setDates} />
        </div>
      </div>
      <div className={s.po_date_selector}>
        <RoundedRadioButton
          buttons={(referenceDateTypes || []).map((b) => ({
            key: b,
            title: referenceDateTitles[b]
          }))}
          value={location.dateType as string}
          onChange={(key) => changeFilters({ dateType: key })}
        />
      </div>
      {(loading || previousLoading) && (
        <Spin spinning={loading || previousLoading} style={{ width: '100%' }} />
      )}
      {!loading && !previousLoading && (
        <>
          <div className={s.main_part_container}>
            <div>
              <TotalMetricsView
                totalMetrics={data?.fillRateSummaryReport?.totalMetrics}
                previousTotalMetrics={previousData?.fillRateSummaryReport?.totalMetrics}
                isRange={dates?.mode === Mode.PERIOD_WEEK && dates.isRange}
                previousPeriodStrings={previousPeriodStrings}
              />
              <BusinesUnitsLineChart
                subBusinessUnits={subBusinessUnits}
                metricsByFiscalCalendarWeeks={
                  data?.fillRateSummaryReport?.metricsByFiscalCalendarWeeksAndSubBus || []
                }
              />
            </div>
            <div>
              <BusinessUnitsFrt
                subBusinessUnits={subBusinessUnits || []}
                metricsBySubBus={data?.fillRateSummaryReport?.metricsBySubBusAndCatalogGroups || []}
              />
            </div>
          </div>
          <div>
            <PurchaseOrdersTable
              appliedFilters={
                calculateAplliedFilters(
                  subBusinessUnits,
                  topLevelBusinessUnits,
                  customers,
                  history,
                  dates
                ) as AppliedFillRateReportFilters | undefined
              }
            />
          </div>
          <div className={s.detail_view_title}>
            <span>Detail view</span>
            <div>
              <RoundedRadioButton
                buttons={[
                  { key: 'bu', title: 'BU' },
                  { key: 'category', title: 'Category' },
                  { key: 'brand', title: 'Brand' }
                ]}
                onChange={(key) => {
                  setColumnsPlotType(key);
                }}
                value={columnsPlotType}
              />
            </div>
          </div>
          {columnsPlotType === 'bu' && (
            <ColumnsPlotDataView
              subBusinessUnits={subBusinessUnits}
              metricsByEntity={data?.fillRateSummaryReport?.metricsBySubBusAndCatalogGroups || []}
            />
          )}
          {columnsPlotType === 'category' && (
            <ColumnsPlotDataView
              metricsByEntity={(data?.fillRateSummaryReport?.metricsByCategories || []).map(
                (d) => ({
                  title: d.catalogGroupName || 'Unknown',
                  metrics: d.metrics
                })
              )}
            />
          )}
          {columnsPlotType === 'brand' && (
            <ColumnsPlotDataView
              metricsByEntity={(data?.fillRateSummaryReport?.metricsByBrands || []).map((d) => ({
                title: d.catalogGroupName || 'Unknown',
                metrics: d.metrics
              }))}
            />
          )}
          <div className={s.bu_table}>
            {columnsPlotType === 'bu' && (
              <StyledReportTable
                data={
                  data?.fillRateSummaryReport?.metricsBySubBusAndCatalogGroups?.map((metric) => {
                    const foundBu = subBusinessUnits.find(
                      (businessUnit) => businessUnit.id === metric.subBusinessUnitId
                    );
                    return {
                      id: foundBu?.id,
                      bu: foundBu,
                      parentBu: foundBu?.parentBusinessUnit,
                      ...metric.metrics
                    };
                  }) || []
                }
                firstColumns={[
                  {
                    title: 'BU',
                    key: 'parent_bu',
                    render: (value) => value.parentBu?.name || value.bu.name,
                    sorter: (i1, i2) =>
                      safeLocaleCompare(
                        (i1['parentBu'] as BusinessUnit)?.name,
                        (i2['parentBu'] as BusinessUnit)?.name
                      )
                  },
                  {
                    title: 'Sub BU',
                    key: 'bu',
                    render: (value) => value.bu.name,
                    sorter: (i1, i2) =>
                      safeLocaleCompare(
                        (i1['bu'] as BusinessUnit)?.name,
                        (i2['bu'] as BusinessUnit)?.name
                      )
                  }
                ]}
              />
            )}
            {columnsPlotType === 'category' && (
              <StyledReportTable
                data={
                  data?.fillRateSummaryReport?.metricsByCategories?.map((metric) => ({
                    title: metric.catalogGroupName || 'Unknown',
                    ...metric.metrics
                  })) || []
                }
                firstColumns={[
                  {
                    title: 'Category',
                    key: 'title',
                    render: (value) => value.title,
                    sorter: (i1, i2) => (i1['title'] as string).localeCompare(i2['title'] as string)
                  }
                ]}
              />
            )}
            {columnsPlotType === 'brand' && (
              <StyledReportTable
                data={
                  data?.fillRateSummaryReport?.metricsByBrands?.map((metric) => ({
                    title: metric.catalogGroupName || 'Unknown',
                    ...metric.metrics
                  })) || []
                }
                firstColumns={[
                  {
                    title: 'Brand',
                    key: 'title',
                    render: (value) => value.title,
                    sorter: (i1, i2) => (i1['title'] as string).localeCompare(i2['title'] as string)
                  }
                ]}
              />
            )}
          </div>
          <div className={s.detail_view_title}>DC Cut View</div>
          <DcCutTreeMap metricsByDcs={data?.fillRateSummaryReport?.metricsByDcs || []} />
          <div className={s.detail_view_title}>
            <span>Brand Fill Rate Distribution</span>
            <div>
              <RoundedRadioButton
                buttons={uniqBy(topLevelBusinessUnits, 'id').map((bu) => ({
                  key: bu.id,
                  title: bu.name || bu.id
                }))}
                onChange={(key) => {
                  setSelectedBuForBrandsChart(key);
                }}
                value={selectedBuForBrandsChart}
              />
            </div>
          </div>
          {selectedBuForBrandsChart && (
            <BrandFillRateColumnChart
              metricsBySubBusAndCatalogGroups={getDataForColumnBrandsChart(
                selectedBuForBrandsChart
              )}
            />
          )}
        </>
      )}
    </>
  );
};
