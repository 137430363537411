import { escapeRegExp, startCase } from 'lodash-es';

import { InventoryReconciliationBatchDiscrepanciesQuery } from './gql/__generated__/inventoryReconciliationBatchDiscrepancies.query';
import { InventoryReconciliationDiscrepanciesQuery } from './gql/__generated__/inventoryReconciliationDiscrepancies.query';
import { InventoryDiscrepancyReason } from 'graphql/__generated__/types';

export type ReconcileMetric = NonNullable<
  InventoryReconciliationDiscrepanciesQuery['inventoryReconciliationDiscrepancies']
>['metrics'][number];
export type ReconcileBatchDetailsMetric = NonNullable<
  InventoryReconciliationBatchDiscrepanciesQuery['inventoryReconciliationBatchDiscrepancies']
>['metrics'][number];
export type DiscrepancyReason = ReconcileBatchDetailsMetric['reasons'][number];

export function makeReasonsReadable(reason: InventoryDiscrepancyReason): string {
  // TODO: expand?
  const abbreviations = ['sto'];

  const mapping: Record<InventoryDiscrepancyReason, string> = {
    DELAYED_ORDER_SHIPMENT_UPDATE: 'Shipment Timing',
    AWAITING_ORDER_SHIPMENT_CONFIRMATION: 'Open Shipment',
    SHIPMENT_QUANTITY: 'Shipment Discrepancy',
    DELAYED_STO_RECEIPT_UPDATE: 'STO Timing',
    AWAITING_STO_RECEIPT_CONFIRMATION: 'Open STO',
    STO_QUANTITY: 'STO Discrepancy',
    STALE: 'Stales',
    DAMAGE: 'Damages',
    SAMPLE: 'Samples',
    OTHER_ADJUSTMENTS: 'Others',
    SWAP: 'Swap'
  };

  if (mapping[reason]) return mapping[reason];

  // Just in case, if not present.
  let result = startCase(reason.toLowerCase());
  abbreviations.forEach((abbr) => {
    const regex = new RegExp(`\\b${escapeRegExp(abbr)}\\b`, 'gi');
    result = result.replace(regex, abbr.toUpperCase());
  });

  return result;
}
