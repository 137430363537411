import React from 'react';
import { calculateBgColorForRatio } from 'common/helpers/calculateColor';
import s from './RateCellWithTarget.module.scss';

export const RateCellWithTarget = ({ value, target = 50 }: { value: number; target?: number }) => {
  const color = calculateBgColorForRatio(value, target);

  return (
    <div className={s.rate_component}>
      <span className={s.percent}>{`${Math.round(value)}%`}</span>
      <div className={s.progress_container}>
        <div className={s.progress_bar} style={{ backgroundColor: color, width: `${value}%` }} />
      </div>
    </div>
  );
};
