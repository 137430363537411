import { difference, groupBy } from 'lodash-es';
import moment from 'moment';
import { AuditTrailDiff, AuditTrailVersion, RetailerProductRddAssortment } from '../ChangeHistory';
import {
  AuditTrailDiffItemFragment_AuditTrailAddition_Fragment,
  AuditTrailValueFragment_AuditTrailPrimitive_Fragment
} from '../gql/__generated__/rddAuditVersions.query';
import {
  getEventNameByAction,
  getValues,
  TradingPartnerForAudit,
  ChangeHistoryItem,
  RetailerDeliveryDestinationForAssortmentAudit
} from './baseHelper';

// AA audit versions structure
// RetailerProductRddAssortment:
//   - retailerProduct:
//       * toCaseQuantity
//       * active
//       * substitutionUpcs
//     - substitutionProducts:
//       - product:
//         - allInventory:
//           * active
//           * source
//         * warehouseMeasurements (layersPerPalletCount, etc)
//     - tradingPartnerVendorProducts:
//       * inventoryReserve
//       * currency
//       * moqMinimum
//       * etc
//   - assortments:
//     * active

const combineAuditVersions = (
  auditVersions: (AuditTrailVersion | undefined)[],
  ignoreAction = false
) => {
  const auditVersionsDict = groupBy(
    auditVersions,
    (v) => `${v?.appliedAt}_${v?.user?.id}${!ignoreAction ? `_${v?.action}` : ''}_${v?.actor}`
  );
  const auditVersionsRes = [];
  for (const t in auditVersionsDict) {
    const complexVersion = auditVersionsDict[t].reduce(
      (result, version) => {
        if (version?.action !== 'ADDITION' && result.action === 'ADDITION') {
          result.action = 'CHANGE';
        }
        return {
          ...result,
          diff: [...result.diff, ...(version?.diff || [])]
        };
      },
      { ...auditVersionsDict[t][0], diff: [] as AuditTrailDiff[] }
    );
    auditVersionsRes.push(complexVersion);
  }
  return auditVersionsRes;
};

export const prepareAssortmentAuditVersions = (
  versions: AuditTrailVersion[],
  entity: RetailerProductRddAssortment,
  retailerDeliveryDestinations: RetailerDeliveryDestinationForAssortmentAudit[],
  tradingPartnerId: string
) => {
  const tpSettingsVersions =
    entity?.retailerProduct?.tradingPartnerVendorProducts
      ?.filter((tpVp) => tpVp?.tradingPartnerId === tradingPartnerId)
      ?.flatMap((tpvp) => tpvp?.auditTrailVersions) || [];

  const substitutionVersions =
    entity?.retailerProduct?.substitutionProducts?.flatMap((substitution) => {
      const auditTrailVersionsForSubstitutionProduct = substitution.product.auditTrailVersions.map(
        (version: AuditTrailVersion) => ({
          ...version,
          diff: version.diff.reduce((result, diff) => {
            const indicator = '.warehouseMeasurements[';
            if (diff.fieldPath.includes(indicator) && diff.fieldPath.includes('.value')) {
              const measurementStart = diff.fieldPath.lastIndexOf(indicator);
              const measurementNamePart = diff.fieldPath.substring(
                measurementStart + indicator.length
              );
              const measurementName = measurementNamePart.substring(
                0,
                measurementNamePart.indexOf(']')
              );

              result.push({
                ...diff,
                fieldPath: `.${measurementName}`
              });
            } else if (!diff.fieldPath.includes(indicator)) {
              if (diff.fieldPath.endsWith('.upc') && diff.__typename === 'AuditTrailAddition') {
                if (diff.__typename === 'AuditTrailAddition') {
                  diff.fieldPath = `.upc_${
                    (
                      (diff as AuditTrailDiffItemFragment_AuditTrailAddition_Fragment)
                        .value as AuditTrailValueFragment_AuditTrailPrimitive_Fragment
                    ).value
                  }_Added`;
                }
              }
              result.push(diff);
            }
            return result;
          }, [] as AuditTrailDiff[])
        })
      );
      const appliedToUpc = auditTrailVersionsForSubstitutionProduct.map((atv) => ({
        ...atv,
        diff: atv.diff.map((diff) => ({
          ...diff,
          fieldPath: `.substitutionProduct_${substitution.product.upc || ''}${diff.fieldPath}`
        }))
      }));
      return appliedToUpc;
    }) || [];

  // remove "UPC reordered" event when add only first UPC
  const vendorProductVersions = versions.map((version) => ({
    ...version,
    diff: version.diff
      .filter(
        (diff) =>
          !diff.fieldPath.endsWith('.substitutionUpcs') || diff.__typename !== 'AuditTrailAddition'
      )
      .map((diff) =>
        diff.fieldPath.endsWith('.substitutionUpcs')
          ? {
              ...diff,
              fieldPath: `.multipleUpcMgmt${diff.fieldPath}`
            }
          : diff
      )
  }));

  // for adding "UPC added" and "UPC removed" event
  const upcChanges = versions.reduce((result, version) => {
    const upcChange = version.diff.find((diff) => diff.fieldPath.endsWith('.substitutionUpcs'));
    if (upcChange) {
      const { previousValue, newValue } = getValues(upcChange);
      const previousList = previousValue
        ? typeof previousValue === 'string'
          ? previousValue.split(',')
          : previousValue
        : [];
      const newList = newValue
        ? typeof newValue === 'string'
          ? newValue.split(',')
          : newValue
        : [];
      const removed = difference(previousList, newList);
      const newDiff: AuditTrailDiff[] = [];
      removed.forEach((removedUpc) => {
        newDiff.push({
          __typename: 'AuditTrailRemoval',
          fieldPath: `.multipleUpcMgmt.upc_${removedUpc}_Removed`,
          value: {
            __typename: 'AuditTrailPrimitive',
            value: removedUpc
          }
        });
      });
      result.push({
        ...version,
        diff: newDiff
      });
    }
    return result;
  }, [] as AuditTrailVersion[]);

  const inventoryVersions =
    entity?.retailerProduct?.substitutionProducts
      ?.flatMap((substitution) => substitution.product.allInventory)
      ?.flatMap((tpvp) =>
        tpvp?.auditTrailVersions.map((version: AuditTrailVersion) => ({
          ...version,
          diff: version.diff.map((d) => ({
            ...d,
            fieldPath: `.${tpvp?.dcName} ${tpvp?.dcCode}${d.fieldPath}`
          }))
        }))
      ) || [];

  const distributionCentersVersions = getDistributionCentersVersions(inventoryVersions);

  const assortmentsVersions =
    entity?.assortments?.flatMap((assortment) =>
      assortment.auditTrailVersions.map((version: AuditTrailVersion) => ({
        ...version,
        diff: version.diff.map((d) => ({
          ...d,
          fieldPath: `.${assortment.retailerDeliveryDestinationId}${d.fieldPath}`
        }))
      }))
    ) || [];

  const shipToVersions = getShipToVersions(assortmentsVersions, retailerDeliveryDestinations);

  const allVersions = [
    ...combineAuditVersions(
      [...vendorProductVersions, ...tpSettingsVersions, ...substitutionVersions, ...upcChanges],
      true
    ),
    ...shipToVersions,
    ...distributionCentersVersions
  ].sort(
    (r1, r2) => moment(r2?.appliedAt).toDate().getTime() - moment(r1?.appliedAt).toDate().getTime()
  );
  return allVersions;
};

export const getAssortmentEventName = (
  path: string,
  simpleChanges: ChangeHistoryItem[],
  eventType: string,
  tradingPartners: TradingPartnerForAudit[]
) => {
  if (path.startsWith('.tradingPartnerVendorProducts')) {
    const tpId = path.split('.')[2];
    const tradingPartnerName = tradingPartners.find((tp) => tp.id === tpId)?.name || '';
    return getEventNameByAction(simpleChanges, `${tradingPartnerName} settings`);
  } else if (path.startsWith('.substitutionProducts')) {
    return getEventNameByAction(simpleChanges, `UPC settings`);
  } else if (path.startsWith('.distributionCentersEnabling')) {
    return 'Activate for DC';
  } else if (path.startsWith('.shipTosEnabling')) {
    return 'Activate for Ship-To';
  } else if (path.startsWith('.distributionCentersDisabling')) {
    return 'Inactivate for DC';
  } else if (path.startsWith('.shipTosDisabling')) {
    return 'Inactivate for Ship-To';
  } else if (path.startsWith('.multipleUpcMgmt')) {
    return 'Multiple UPC Management';
  } else if (path.startsWith('.substitutionProduct_')) {
    const upc = path.split('.')[1].split('_')[1];
    return `${eventType} Substitution Product with UPC ${upc}`;
  }
  return `${eventType} Product`;
};

const getDistributionCentersVersions = (inventoryVersions: (AuditTrailVersion | undefined)[]) => {
  const invVersionsRes = combineAuditVersions(inventoryVersions, true);
  const distributionCentersVersions = invVersionsRes.flatMap((version) => {
    const diffs = version.diff
      .filter((diff) => diff.fieldPath.endsWith('.active'))
      .map((diff) => ({ ...getValues(diff), code: diff.fieldPath.split('.')[1] }));
    const distributionCenters = diffs.reduce(
      (result, diff) => {
        if (diff.previousValue === 'true') {
          result.previousValue.push(diff.code);
        }
        if (diff.newValue === 'true') {
          result.newValue.push(diff.code);
        }
        return result;
      },
      { previousValue: [] as string[], newValue: [] as string[] }
    );
    const enabled = difference(distributionCenters.newValue, distributionCenters.previousValue);
    const disabled = difference(distributionCenters.previousValue, distributionCenters.newValue);

    const resultVersions = [];
    if (enabled.length > 0) {
      resultVersions.push({
        ...version,
        diff: [
          {
            fieldPath: '.distributionCentersEnabling.forDistributionCenters',
            value: {
              __typename: 'AuditTrailListOfPrimitives',
              values: enabled
            },
            __typename: 'AuditTrailAddition'
          }
        ]
      });
    }
    if (disabled.length > 0) {
      resultVersions.push({
        ...version,
        diff: [
          {
            fieldPath: '.distributionCentersDisabling.forDistributionCenters',
            value: {
              __typename: 'AuditTrailListOfPrimitives',
              values: disabled
            },
            __typename: 'AuditTrailRemoval'
          }
        ]
      });
    }
    return resultVersions;
  });
  return distributionCentersVersions;
};

const getShipToVersions = (
  assortmentsVersions: (AuditTrailVersion | undefined)[],
  retailerDeliveryDestinations: RetailerDeliveryDestinationForAssortmentAudit[]
) => {
  const assortmentsVersionsRes = combineAuditVersions(assortmentsVersions, true);
  const rddsVersions = assortmentsVersionsRes.flatMap((version) => {
    const diffs = version.diff
      .filter((diff) => diff.fieldPath.endsWith('.active'))
      .map((diff) => ({ ...getValues(diff), rddId: diff.fieldPath.split('.')[1] }));
    const rdds = diffs.reduce(
      (result, diff) => {
        if (diff.previousValue === 'true') {
          result.previousValue.push(diff.rddId);
        }
        if (diff.newValue === 'true') {
          result.newValue.push(diff.rddId);
        }
        return result;
      },
      { previousValue: [] as string[], newValue: [] as string[] }
    );
    const enabledShipTos = difference(rdds.newValue, rdds.previousValue).filter((rddId) =>
      retailerDeliveryDestinations.find((rdd) => rdd.id === rddId && !rdd.specialAssortment)
    );
    const enabledVendorFlex = difference(rdds.newValue, rdds.previousValue).filter((rddId) =>
      retailerDeliveryDestinations.find((rdd) => rdd.id === rddId && rdd.specialAssortment)
    );
    const disabledShipTos = difference(rdds.previousValue, rdds.newValue).filter((rddId) =>
      retailerDeliveryDestinations.find((rdd) => rdd.id === rddId && !rdd.specialAssortment)
    );
    const disabledVendorFlex = difference(rdds.previousValue, rdds.newValue).filter((rddId) =>
      retailerDeliveryDestinations.find((rdd) => rdd.id === rddId && rdd.specialAssortment)
    );

    const resultVersions = [];
    if (enabledShipTos.length > 0 || enabledVendorFlex.length > 0) {
      const diff = [];
      if (enabledShipTos.length > 0) {
        diff.push({
          fieldPath: '.shipTosEnabling.forShipTos',
          value: {
            __typename: 'AuditTrailListOfPrimitives',
            values: enabledShipTos
          },
          __typename: 'AuditTrailAddition'
        });
      }
      if (enabledVendorFlex.length > 0) {
        diff.push({
          fieldPath: '.shipTosEnabling.forVendorFlex',
          value: {
            __typename: 'AuditTrailListOfPrimitives',
            values: enabledVendorFlex
          },
          __typename: 'AuditTrailAddition'
        });
      }
      resultVersions.push({
        ...version,
        diff
      });
    }
    if (disabledShipTos.length > 0 || disabledVendorFlex.length > 0) {
      const diff = [];
      if (disabledShipTos.length > 0) {
        diff.push({
          fieldPath: '.shipTosDisabling.forShipTos',
          value: {
            __typename: 'AuditTrailListOfPrimitives',
            values: disabledShipTos
          },
          __typename: 'AuditTrailRemoval'
        });
      }
      if (disabledVendorFlex.length > 0) {
        diff.push({
          fieldPath: '.shipTosDisabling.forVendorFlex',
          value: {
            __typename: 'AuditTrailListOfPrimitives',
            values: disabledVendorFlex
          },
          __typename: 'AuditTrailRemoval'
        });
      }
      resultVersions.push({
        ...version,
        diff
      });
    }
    return resultVersions;
  });
  return rddsVersions;
};
