import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DetachLabelFromPurchaseOrdersMutationVariables = Types.Exact<{
  input: Types.DetachLabelFromPurchaseOrdersInput;
}>;


export type DetachLabelFromPurchaseOrdersMutation = { __typename?: 'RootMutationType', detachLabelFromPurchaseOrders?: { __typename?: 'DetachLabelFromPurchaseOrdersPayload', purchaseOrders: Array<{ __typename?: 'PurchaseOrder', id: string, labels: Array<{ __typename?: 'Label', id: string, text: string }> }> } | null };


export const DetachLabelFromPurchaseOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"detachLabelFromPurchaseOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DetachLabelFromPurchaseOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"detachLabelFromPurchaseOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"labels"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]}}]} as unknown as DocumentNode<DetachLabelFromPurchaseOrdersMutation, DetachLabelFromPurchaseOrdersMutationVariables>;