import React from 'react';
import { InputNumber as AntInputNumber, InputNumberProps } from 'ant5';
import { ValueType } from 'rc-input-number';

export function AlloyInputNumber<T extends ValueType>({ ...otherProps }: InputNumberProps<T>) {
  return <AntInputNumber<T> {...otherProps} />;
}

// Re-exporting types
export type { InputNumberProps };
