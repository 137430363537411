import React, { useState, useEffect } from 'react';
import { App } from 'ant5';
import { cloneDeep, uniq } from 'lodash-es';
import * as Sentry from '@sentry/browser';
import selectIcon from 'assets/icons/select_icon.svg';
import clsx from 'clsx';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import {
  DistributionCenterForPermission,
  ExtendedUser,
  LocationUpdate,
  TradingPartnerForPermission
} from 'pages/PermissionsPage/PermissionsPage';
import moment from 'moment';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { getSortOrder } from 'common/helpers/sorting';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import s from './PermissionsList.module.scss';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyTag } from 'components/ui/AlloyTag/AlloyTag';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';

const { Option } = AlloySelect;

interface PermissionListProps {
  users: ExtendedUser[];
  saveUser: (user: ExtendedUser) => Promise<void>;
  reactivateUser: (user: ExtendedUser) => void;
  deactivateUser: (user: ExtendedUser) => void;
  tradingPartnerList: TradingPartnerForPermission[];
  distributionCentersList: DistributionCenterForPermission[];
  loading: boolean;
  search: string;
  updatePaging: (update?: LocationUpdate) => void;
}

export const PermissionsList = ({
  users,
  saveUser,
  reactivateUser,
  deactivateUser,
  tradingPartnerList,
  distributionCentersList,
  loading,
  search,
  updatePaging
}: PermissionListProps) => {
  const { message, modal: antModal } = App.useApp();

  const [viewHistoryUser, setViewHistoryUser] = useState<ExtendedUser>();

  const history = useHistory();
  const location = parse(history.location.search);
  const [editUser, setEditUser] = useState<Partial<ExtendedUser>>({
    email: '',
    roles: [],
    tradingPartnerIds: []
  });
  const [editTarget, setEditTarget] = useState<null | undefined | Partial<ExtendedUser>>(null);
  const [originalData] = useState(cloneDeep(users));

  const [newUserEmail, setNewUserEmail] = useState('');
  const [modal, setModal] = useState({
    show: false,
    error: ''
  });

  useEffect(() => {
    const newUserFound = users.findIndex((user) => !user.id);
    if (newUserFound > -1) {
      setEditTarget(users[0]);
      setEditUser({ email: '', roles: [], tradingPartnerIds: [] });
    }
  }, [users]);

  const handleTableSorting = (column: string, order: string) => {
    updatePaging({
      column,
      order,
      after: null,
      before: null
    });
  };

  //ensure the new user input is always at the top
  const sortWithInput = (
    a: ExtendedUser,
    b: ExtendedUser,
    sorter: (a: ExtendedUser, b: ExtendedUser) => number
  ) => (!a.id || !b.id ? (location.order === 'ASC' ? 1 : -1) : sorter(a, b));

  const invalidEmail = (email: string) => {
    if (!email) {
      return 'Please include an email address';
    } else if (!(email.split('@').length === 2 && email.includes('.'))) {
      return 'The email address does not appear to be valid';
    }
  };

  // editing TPs for user
  const save = async (user: ExtendedUser) => {
    if (editUser.roles && !editUser.roles.length) {
      return message.warning('Please include at least 1 user role');
    }

    Object.assign(user, editUser);

    try {
      await saveUser(user);
      setEditTarget(null);
      setEditUser({ email: '', roles: [], tradingPartnerIds: [] });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const cancel = (user: ExtendedUser) => {
    setEditTarget(null);
    const originalUser = originalData.find((o) => o.id === user.id);
    if (originalUser) {
      user.email = originalUser.email;
      user.roles = originalUser.roles;
    }
  };

  const ControlsEdit = ({ user }: { user: ExtendedUser }) => {
    return (
      <div className={s.buttons}>
        <AlloyButton
          disabled={user.deletedAt}
          onClick={() => {
            if (!user.deletedAt) {
              setEditUser({
                email: user.email,
                roles: user.roles,
                tradingPartnerIds: user.tradingPartnerIds
              });
              setEditTarget(user);
            }
          }}
        >
          Edit
        </AlloyButton>
        {user.deletedAt ? (
          <AlloyButton
            data-testid="permissions-list-reactivate-button"
            onClick={() => {
              antModal.confirm({
                title: 'Confirm reactivate user',
                okText: 'Reactivate',
                onOk: () => reactivateUser(user)
              });
            }}
          >
            Reactivate
          </AlloyButton>
        ) : (
          <AlloyButton
            data-testid="permissions-list-deactivate-button"
            danger
            onClick={() => {
              antModal.confirm({
                title: 'Confirm deactivate user',
                okText: 'Deactivate',
                onOk: () => deactivateUser(user)
              });
            }}
          >
            Deactivate
          </AlloyButton>
        )}
      </div>
    );
  };

  const ControlsSave = ({ user }: { user: ExtendedUser }) => {
    return (
      <div className={s.buttons}>
        <AlloyButton onClick={() => save(user)}>Save</AlloyButton>
        <AlloyButton danger onClick={() => cancel(user)}>
          Cancel
        </AlloyButton>
      </div>
    );
  };

  const columns: ColumnsType<ExtendedUser> = [
    {
      title: <span data-testid="permissions-list-name-column-title">Name</span>,
      key: 'NAME',
      render: (_, user) => <HighlightedText text={user.name} highlight={search} />,
      sortOrder: getSortOrder(location, 'NAME'),
      sorter: (a, b) => sortWithInput(a, b, (a, b) => a.name.localeCompare(b.name))
    },
    {
      title: <span data-testid="permissions-list-email-column-title">Email</span>,
      key: 'EMAIL',
      render: (_, user) => <HighlightedText text={user.email} highlight={search} />,
      sortOrder: getSortOrder(location, 'EMAIL'),
      sorter: (a, b) => sortWithInput(a, b, (a, b) => a.email.localeCompare(b.email))
    },
    {
      title: <span data-testid="permissions-list-role-column-title">Role</span>,
      key: 'roles',
      className: 'roles_cell',
      render: (_, { roles, deletedAt }) => (
        <div className={s.tag_container}>
          {roles.map((role, i) => (
            <AlloyTag key={role + i} disabled={!!deletedAt}>
              {role}
            </AlloyTag>
          ))}
        </div>
      )
    },
    {
      title: (
        <span data-testid="permissions-list-trading-partners-column-title">Trading partners</span>
      ),
      key: 'tradingPartnerIds',
      render: (_, user) => {
        if (user === editTarget || !user.id) {
          return (
            <div className={clsx(user === editTarget && s.editing)}>
              <AlloySelect
                data-testid={`${user.email}_tradingPartner_select`}
                allowClear
                style={{ width: '100%' }}
                maxTagCount={3}
                mode="multiple"
                showSearch
                popupMatchSelectWidth={false}
                filterOption={(input, option?: { children: string }) =>
                  !!option && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<img alt="" width="18px" src={selectIcon} />}
                placeholder="Trading partners"
                onClear={() => setEditUser({ ...editUser, tradingPartnerIds: [] })}
                defaultValue={user.tradingPartnerIds ?? undefined}
                onChange={(value) => setEditUser({ ...editUser, tradingPartnerIds: value })}
              >
                {uniq(tradingPartnerList)
                  ?.sort((a, b) => (a?.externalId ?? '').localeCompare(b.externalId ?? ''))
                  .map((partner, index) => (
                    <Option key={index} value={partner.id}>
                      {partner.externalId}
                    </Option>
                  ))}
              </AlloySelect>
            </div>
          );
        } else {
          return (
            <div data-testid={`${user.email}_tradingPartner_list`} className={s.tag_container}>
              {user.tradingPartnerIds
                .reduce((result, tradingPartner) => {
                  const found = tradingPartnerList.find((tp) => tp.id === tradingPartner);
                  if (found)
                    result.push({ externalId: found.externalId || '', id: tradingPartner });
                  return result;
                }, [] as { externalId: string; id: string }[])
                .sort((a, b) => a.externalId?.localeCompare(b.externalId))
                .map((tp) => (
                  <AlloyTag key={`${tp.id}`} disabled={!!user.deletedAt}>
                    {tp.externalId}
                  </AlloyTag>
                ))}
            </div>
          );
        }
      }
    },
    {
      title: (
        <span data-testid="permissions-list-distribution-centers-column-title">
          Distribution Centers
        </span>
      ),
      key: 'distributionCentersIds',
      render: (_, user) => {
        if (user === editTarget || !user.id) {
          return (
            <div className={clsx(user === editTarget && s.editing)}>
              <AlloySelect
                data-testid={`${user.email}_distributionCenter_select`}
                allowClear
                style={{ width: '100%' }}
                maxTagCount={3}
                mode="multiple"
                showSearch
                popupMatchSelectWidth={false}
                filterOption={(input, option?: { children: string[] }) =>
                  !!option && option.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<img alt="" width="18px" src={selectIcon} />}
                placeholder="Distribution Centers"
                onClear={() => setEditUser({ ...editUser, distributionCenterIds: [] })}
                defaultValue={user.distributionCenterIds ?? undefined}
                onChange={(value) => setEditUser({ ...editUser, distributionCenterIds: value })}
              >
                {uniq(distributionCentersList)
                  ?.sort((a, b) => `${a.name} ${a.code}`.localeCompare(`${b.name} ${b.code}`))
                  .map((dc, index) => (
                    <Option key={index} value={dc.id}>
                      {dc.name} {dc.code}
                    </Option>
                  ))}
              </AlloySelect>
            </div>
          );
        } else {
          return (
            <div data-testid={`${user.email}_distributionCenter_list`} className={s.tag_container}>
              {user.distributionCenterIds
                .reduce((result, dcId) => {
                  const found = distributionCentersList.find((dc) => dc.id === dcId);
                  if (found) result.push({ title: `${found.name} ${found.code}`, id: dcId });
                  return result;
                }, [] as { title: string; id: string }[])
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((dc) => (
                  <AlloyTag key={`${dc.id}`} disabled={!!user.deletedAt}>
                    {dc.title}
                  </AlloyTag>
                ))}
            </div>
          );
        }
      }
    },
    {
      title: <span data-testid="permissions-list-last-updated-column-title">Last Updated</span>,
      key: 'UPDATED_AT',
      width: 160,
      render: (_, user) => {
        return (
          <UpdatedAt
            allowForNonAdminUsers
            onClick={(e) => {
              setViewHistoryUser(user);
              e.stopPropagation();
            }}
            updatedAt={user.updatedAt}
          />
        );
      },
      sortOrder: getSortOrder(location, 'UPDATED_AT'),
      sorter: (a, b) => sortWithInput(a, b, (a, b) => moment(a.updatedAt).diff(b.updatedAt))
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'right',
      width: 195,
      render: (_, user) => (
        <div className={s.controls}>
          {user === editTarget || !user.id ? (
            <ControlsSave user={user} />
          ) : (
            <ControlsEdit user={user} />
          )}
        </div>
      )
    }
  ];

  return (
    <>
      <div>
        <AlloyTable
          loading={loading}
          sticky
          rowKey={(record) => `${record.id}_${record.email}`}
          rowClassName={(user) => {
            const baseRowClass = clsx(user.deletedAt && s.inactive_user, s.row);
            if (user === editTarget || !user.id) {
              return clsx(baseRowClass, s.editing);
            } else {
              return baseRowClass;
            }
          }}
          dataSource={users}
          columns={columns}
          onChange={(_pagination, _filters, sorter) => {
            const oneSorter = Array.isArray(sorter) ? sorter[0] : sorter;
            const column = String(oneSorter.columnKey) || 'UPDATED_AT';
            const order = oneSorter.order === 'ascend' || !oneSorter.order ? 'ASC' : 'DESC';
            handleTableSorting(column, order);
          }}
          pagination={false}
        />
      </div>
      <AlloyModal
        open={modal.show}
        onOk={() => {
          let error = invalidEmail(newUserEmail);
          if (error) {
            setModal({
              show: true,
              error
            });
          } else {
            setModal({
              show: false,
              error: ''
            });

            setTimeout(() => {
              message.success(`${newUserEmail} invited`);
            }, 1000);

            setNewUserEmail('');
          }
        }}
        onCancel={() => {
          setModal({
            show: false,
            error: ''
          });
          setNewUserEmail('');
        }}
      >
        <div className={s.invite_user}>
          <div className={s.title}>Invite user</div>
          <label>Email</label>
          <AlloyInput
            className={`${modal.error ? s.errored : ''}`}
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
          <div className={s.error}>{modal.error}</div>
        </div>
      </AlloyModal>
      {viewHistoryUser && (
        <ChangeHistory
          entity={{
            type: 'User',
            id: viewHistoryUser.id,
            name: viewHistoryUser.name
          }}
          onClose={() => {
            setViewHistoryUser(undefined);
          }}
        />
      )}
    </>
  );
};
