import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpsertProductFillRateCommentMutationVariables = Types.Exact<{
  input: Types.UpsertProductFillRateCommentInput;
}>;


export type UpsertProductFillRateCommentMutation = { __typename?: 'RootMutationType', upsertProductFillRateComment?: { __typename?: 'UpsertProductFillRateCommentPayload', vendorProductId: number, purchaseOrderId: number, salesOrderId?: number | null, comment?: string | null } | null };


export const UpsertProductFillRateCommentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"upsertProductFillRateComment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertProductFillRateCommentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertProductFillRateComment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendorProductId"}},{"kind":"Field","name":{"kind":"Name","value":"purchaseOrderId"}},{"kind":"Field","name":{"kind":"Name","value":"salesOrderId"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<UpsertProductFillRateCommentMutation, UpsertProductFillRateCommentMutationVariables>;