import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Modal, Button, Upload, Table, message, notification, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import Papa from 'papaparse';
import {
  AllowedDc,
  Fields,
  itemHeaders,
  RddFromFile,
  RddMappedFromFile,
  rowsToData,
  sanitize,
  TpRddFields
} from '../../helper';
import { useStores } from 'pages/ShipToPage/components/BulkAddStoreModal/hooks';
import { useMutation, useQuery } from '@apollo/client';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  RetailerChannelsForShipTo,
  RetailerDeliveryDestinationFromList
} from 'pages/ShipToPage/ShipToPage';
import { UpsertRetailerDeliveryDestinationsDocument } from 'pages/ShipToPage/gql/__generated__/upsertRetailerDeliveryDestinations.mutation';
import { isEqual, uniq } from 'lodash-es';
import { ShipToEditingInitDataDocument } from 'pages/ShipToPage/gql/__generated__/shipToEditingInitData.query';
import { DownloadCurrentShipTos } from './components/DownloadCurrentShipTos';
import { AllStoresForBulkUploadDocument } from 'pages/ShipToPage/gql/__generated__/allStores.query';

export const VALIDATION_RULES_ROW = {
  externalId: 'Required',
  name: 'Required',
  tradingPartner: 'Required',
  vendorMarket: 'Required',
  distributionCenterCodes: 'Required'
};

export interface StoreUpdates {
  new: RddMappedFromFile[];
  updated: RddMappedFromFile[];
  unchanged: RddMappedFromFile[];
}

interface BulkAddStoreModalProps {
  visible: boolean;
  stores: RetailerDeliveryDestinationFromList[];
  retailerChannels: RetailerChannelsForShipTo[];
  onClose?: (cancel?: true) => void;
}

const BulkAddStoreModal = ({
  visible,
  stores, //TODO: remove if we don't need this? Why do we not use it?
  retailerChannels,
  onClose
}: BulkAddStoreModalProps) => {
  const [upsertRetailerDeliveryDestinations] = useMutation(
    UpsertRetailerDeliveryDestinationsDocument,
    {
      refetchQueries: ['retailerDeliveryDestinations']
    }
  );

  //these are the files selected by the user. setup to allow only 1
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  //csv data extracted from the file
  const [fileData, setFileData] = useState<RddFromFile[]>([]);
  const [fileError, setFileError] = useState<string>();
  const [uploadedData, setUploadedData] = useState<RddMappedFromFile[]>([]);
  const [changes, setChanges] = useState<StoreUpdates>({ new: [], updated: [], unchanged: [] });

  //vendor market external ids are pulled from the uploaded csv
  const [vendorMarketExternalIds, setVendorMarketExternalIds] = useState<string[]>([]);

  const { data: initData, loading: initLoading } = useQuery(ShipToEditingInitDataDocument, {
    skip: !visible
  });

  const { data: allShipTosData, loading: allShipTosDataLoading } = useQuery(
    AllStoresForBulkUploadDocument,
    {
      skip: !visible
    }
  );

  //detailed store data is loaded for each trading partner
  const { stores: currentStores, storesLoading } = useStores(vendorMarketExternalIds);

  //the existing store data is compared with the uploaded file, and rows are sorted as updates and new stores
  const [comparisonComplete, setComparisonComplete] = useState(false);

  //step 1: select a file, step 2: view and approve the updates / inserts
  const [step, setStep] = useState<number>(1);

  //if the fileData is updated this indicates that a new file has been uploaded. Comparison with existing data will now take place
  useEffect(() => {
    setComparisonComplete(false);
  }, [fileData, setComparisonComplete]);

  //this compares the current store data from the backend, with the uploaded file.
  useEffect(() => {
    if (storesLoading || !fileList.length) return;

    const newChanges: StoreUpdates = { new: [], updated: [], unchanged: [] };
    for (const rdd of uploadedData) {
      const currentStore = currentStores.find(
        (store) =>
          store.externalId === rdd.externalId && store.vendorMarket?.id === rdd.vendorMarketId
      );
      rdd.errors = [];

      const currentStoreVendorMarketId = currentStore?.vendorMarket?.id;
      const currentStoreVendorId = currentStore?.vendorMarket?.vendor?.id;

      const vendorsFromTps: string[] = [];

      let firstTpVmId: string | undefined;

      if (!rdd.tradingPartnerRetailerDeliveryDestinations?.length) {
        rdd.errors.push(`Trading Partner is Required`);
      } else {
        //attach tradingPartnerId to tpRdd and vendorMarketId to rdd
        for (let tpRdd of rdd.tradingPartnerRetailerDeliveryDestinations) {
          const tradingPartner = getNodesFromEdges(initData?.tradingPartners).find(
            (tp) => tp.externalId === tpRdd.externalId
          );
          if (!tradingPartner) {
            rdd.errors.push(`Unknown Trading Partner: ${tpRdd.externalId}`);
          } else if (
            currentStoreVendorId &&
            tradingPartner.vendorMarket.vendor?.id !== currentStoreVendorId
          ) {
            rdd.errors.push(
              `Ship-To ${currentStore.externalId} and Trading Partner ${tpRdd.externalId} associated with different Retailer`
            );
          } else {
            tpRdd.tradingPartnerId = tradingPartner?.id;
            if (!firstTpVmId) firstTpVmId = tradingPartner.vendorMarket.id;
            if (
              tradingPartner.vendorMarket.vendor?.id &&
              !vendorsFromTps.includes(tradingPartner.vendorMarket.vendor?.id)
            ) {
              vendorsFromTps.push(tradingPartner.vendorMarket.vendor?.id);
            }
          }
        }
      }

      if (vendorsFromTps.length > 1) {
        rdd.errors.push('Trading Partners associated with different Retailers');
      } else if (!currentStore && firstTpVmId) {
        rdd.vendorMarketId = firstTpVmId.trim();
      } else if (currentStoreVendorMarketId) {
        rdd.vendorMarketId = currentStoreVendorMarketId.trim();
      } else {
        rdd.errors.push('Unknown Retailer Channel');
      }

      if (!rdd.name) {
        rdd.errors.push(`Name is Required`);
      }

      rdd.allowedDistributionCenters = [];
      if (!rdd.distributionCenterCodes || rdd.distributionCenterCodes.trim().length === 0) {
        rdd.errors.push('Distribution Centers are required');
      } else {
        rdd.allowedDcCodes = [];
        const codes = rdd.distributionCenterCodes.trim().split(',');
        codes.forEach((code, i) => {
          const dc = getNodesFromEdges(initData?.distributionCenters).find(
            (dc) => dc.code === code.trim()
          );
          if (!dc) {
            rdd.errors?.push(`Unknown Distribution Center: ${code}`);
          } else {
            rdd.allowedDistributionCenters?.push({
              distributionCenterId: dc.id,
              priority: i + 1
            } as AllowedDc);
            rdd.allowedDcCodes?.push(dc.code);
          }
        });
      }

      //If the store's external ID was not found then it must be a new store
      if (!currentStore || !rdd.vendorMarketId) {
        newChanges.new.push(rdd);
        continue;
      }

      //add the correct ID based on the external ID, which is later used for update submission
      rdd.id = currentStore.id;

      /* compare the keys within each TPRDD for changes or additions. This needs
      to run even if the RDD has changed in order to match the provided tpRdds to
      existing tpRdd and attach the ID */
      for (let tpRdd of rdd.tradingPartnerRetailerDeliveryDestinations || []) {
        const currentTpRdd = currentStore.tradingPartnerRetailerDeliveryDestinations?.find((tp) => {
          return tp?.tradingPartner?.externalId === tpRdd.externalId;
        });

        if (!currentTpRdd && !newChanges.updated.includes(rdd)) {
          newChanges.updated.push(rdd);
          continue;
        }

        //add the ID to allow update of the correct tpRdd
        tpRdd.id = currentTpRdd?.id;

        //compare the tpRdd keys, and deliverySchedule values
        for (const key of Object.keys(tpRdd) as (
          | TpRddFields
          | 'tradingPartnerId'
          | 'id'
          | 'deliverySchedule'
        )[]) {
          if (key === 'deliverySchedule') {
            for (const day of tpRdd.deliverySchedule || []) {
              const currentDay = currentTpRdd?.deliverySchedule?.find(
                (obj: any) => obj.dayOfWeek === day.dayOfWeek
              );
              if (!currentDay || currentDay.cutoffTime !== day.cutoffTime) {
                if (newChanges.updated.includes(rdd)) newChanges.updated.includes(rdd);
                break;
              }
            }
          } else if (
            !['externalId', 'tradingPartnerId'].includes(key) &&
            String(tpRdd[key]) !== String((currentTpRdd as any)?.[key]) &&
            !newChanges.updated.includes(rdd)
          ) {
            newChanges.updated.push(rdd);
            break;
          }
        }
      }

      for (const key of Object.keys(rdd) as (
        | Fields
        | 'id'
        | 'vendorMarketId'
        | 'errors'
        | 'tradingPartnerRetailerDeliveryDestinations'
        | 'allowedDistributionCenters'
      )[]) {
        // We shouldn't compare fields-helpers
        if (
          ![
            'tradingPartnerRetailerDeliveryDestinations',
            'vendorMarket',
            'vendorMarketId',
            'errors',
            'distributionCenterCodes',
            'allowedDcCodes'
          ].includes(key)
        ) {
          if (key === 'allowedDistributionCenters') {
            const storeAllowedDcs: AllowedDc[] | undefined = currentStore.allowedDistributionCenters
              ?.map((dc: any) => ({
                distributionCenterId: dc.distributionCenter.id,
                priority: dc.priority
              }))
              .sort((dc1, dc2) => dc1.priority - dc2.priority);
            const rddAllowedDcs = rdd.allowedDistributionCenters;
            if (!isEqual(storeAllowedDcs, rddAllowedDcs)) {
              if (!newChanges.updated.includes(rdd)) newChanges.updated.push(rdd);
              break;
            }
          } else {
            if (rdd[key] !== currentStore[key]) {
              if (!newChanges.updated.includes(rdd)) newChanges.updated.push(rdd);
              break;
            }
          }
        }
      }

      if (!newChanges.new.includes(rdd) && !newChanges.updated.includes(rdd))
        newChanges.unchanged.push(rdd);
    }

    setChanges(newChanges);

    setComparisonComplete(true);
  }, [currentStores, retailerChannels, storesLoading, initData, uploadedData]);

  const close = () => {
    if (onClose) onClose();
    resetState();
  };

  const resetState = () => {
    setFileList([]);
    setFileData([]);
    setFileError(undefined);
    setUploadedData([]);
    setStep(1);
    setComparisonComplete(false);
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const parseData = (file: File) => {
    Papa.parse<RddFromFile>(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data: rows, errors } = results;
        if (errors.length) {
          setFileError(errors[0].message);
        } else if (fileError) {
          setFileError(undefined);
        }

        setFileData(rows);
        const storeUpdates = rowsToData(rows);
        setUploadedData(storeUpdates);

        const storeExternalIdsFromFile = storeUpdates.map((store) => store.externalId);
        const existingStores = getNodesFromEdges(
          allShipTosData?.retailerDeliveryDestinations
        ).filter((store) => storeExternalIdsFromFile.includes(store.externalId || ''));

        const tpExternalIds = storeUpdates.flatMap((store) =>
          store?.tradingPartnerRetailerDeliveryDestinations?.map((tpRdd) => tpRdd.externalId)
        );
        const vmExternalIds = uniq([
          ...getNodesFromEdges(initData?.tradingPartners)
            .filter((tp) => tp.externalId && tpExternalIds.includes(tp.externalId))
            .map((tp) => tp.vendorMarket?.externalId || ''),
          ...existingStores.map((store) => store.vendorMarket?.externalId || '')
        ]);
        setVendorMarketExternalIds(vmExternalIds);
      }
    });
  };

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    maxCount: 1,
    multiple: false,
    onRemove: () => setFileList([]),
    customRequest: dummyRequest,
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        parseData(file.originFileObj as File);
      }

      if (file.status !== 'removed') {
        setFileList([
          {
            uid: file.uid,
            name: file.name,
            status: file.status,
            url: file.url ?? ''
          }
        ]);
      }
    }
  };

  const submit = async () => {
    try {
      const stores = sanitize(changes, currentStores);
      await upsertRetailerDeliveryDestinations({
        variables: {
          input: { stores }
        }
      });

      message.success('Stores successfully updated');
      close();
    } catch (error) {
      notification.error({
        message: 'An error occurred',
        description: error.message
      });
    }
  };

  const stepOne = () => {
    return (
      <>
        <div>
          <span>
            Update Ship-To information in bulk via a csv upload. Table data needs to follow{' '}
          </span>
          <CSVLink
            className="download_csv"
            filename={'stores_template.csv'}
            headers={itemHeaders}
            data={[VALIDATION_RULES_ROW]}
          >
            this csv format
          </CSVLink>
          <span>.</span>
        </div>
        <div className="upload_container">
          <Upload {...uploadProps} fileList={fileList}>
            <Button>Choose a file</Button>
          </Upload>
        </div>
        {!fileError && (
          <div className={`row_count ${comparisonComplete && 'show'}`}>
            The selected file contains data for <span className="blue">{uploadedData.length}</span>{' '}
            stores.
          </div>
        )}

        {fileError && (
          <div className="fileError">
            <div className="title">There was an error reading the selected file</div>
            <div>{fileError}</div>
          </div>
        )}

        <div className="footer">
          <DownloadCurrentShipTos />
          <div className="action_group">
            <Button onClick={() => close()}>Cancel</Button>
            <Button
              loading={storesLoading}
              type="primary"
              disabled={
                !comparisonComplete || !uploadedData.length || initLoading || allShipTosDataLoading
              }
              onClick={() => setStep(2)}
            >
              Update
            </Button>
          </div>
        </div>
      </>
    );
  };

  const stepTwo = () => {
    const columns = [
      {
        title: 'SHIP-TO',
        key: 'externalId',
        render: (rdd: any) => rdd.externalId
      },
      {
        title: 'REGION',
        render: (rdd: any) => {
          let address = `${rdd.addressCity ?? ''}`;
          if (address.length && rdd.addressState) address += `, ${rdd.addressState}`;
          else address += `${rdd.addressState ?? ''}`;
          return address;
        }
      },
      {
        title: 'TRADING PARTNERS',
        render: (rdd: any) =>
          rdd.tradingPartnerRetailerDeliveryDestinations
            .map((tpRdd: any) => tpRdd.externalId)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(', ')
      },
      {
        title: 'DC CODES',
        render: (rdd: RddMappedFromFile) => (rdd.allowedDcCodes || []).join(', ')
      }
    ];

    let errors =
      [...changes.updated, ...changes.new].filter((rdd) => rdd.errors?.length).length > 0;

    return (
      <>
        {!!changes.updated.length && (
          <section className="update_section">
            <div className="title">Updated Stores ({changes.updated.length})</div>
            <Table
              dataSource={changes.updated}
              rowClassName={(row) => (row.errors?.length ? 'row_error' : '')}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (row) =>
                  row.errors?.map((error: string) => <p key={error}>{error}</p>),
                rowExpandable: (row) => !!row.errors?.length,
                expandedRowClassName: (row) => 'expanded_row',
                expandIcon: (row) => null
              }}
              columns={columns}
              pagination={false}
              rowKey={(rdd) => String(rdd.externalId)}
            />
          </section>
        )}

        {!!changes.new.length && (
          <section className="update_section">
            <div className="title">New Stores ({changes.new.length})</div>
            <Table
              dataSource={changes.new}
              rowClassName={(row) => (row.errors?.length ? 'row_error' : '')}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (row) =>
                  row.errors?.map((error: string) => <p key={error}>{error}</p>),
                rowExpandable: (row) => !!row.errors?.length,
                expandedRowClassName: (row) => 'expanded_row',
                expandIcon: (row) => null
              }}
              columns={columns}
              pagination={false}
              rowKey={(rdd) => String(rdd.externalId)}
            />
          </section>
        )}

        {!changes.new.length && !changes.updated.length && (
          <section className="update_section">
            <div className="title">Unchanged Stores ({changes.unchanged.length})</div>
          </section>
        )}

        <div className="footer">
          <div>
            {errors && <span className="errors">Please correct any errors before continuing</span>}
          </div>
          <div className="action_group">
            <Button onClick={() => close()}>Cancel</Button>
            <Tooltip
              title={!errors && ![...changes.updated, ...changes.new].length && `No changes found`}
            >
              <Button
                type="primary"
                onClick={() => submit()}
                disabled={errors || [...changes.updated, ...changes.new].length === 0}
              >
                Update
              </Button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      className="bulk_action_modal"
      width="600px"
      title="Bulk add Ship-To action"
      open={visible}
      footer={null}
      onCancel={() => close()}
    >
      {step === 1 && stepOne()}
      {step === 2 && stepTwo()}
    </Modal>
  );
};

export default BulkAddStoreModal;
