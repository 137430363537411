import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import {
  AtRiskPurchaseOrderDetailsDocument,
  AtRiskPurchaseOrderDetailsQuery
} from '../../gql/__generated__/purchaseOrderDetailsReport.query';
import {
  RateCellWithTargetCompact,
  onCellRateWithTargetCompact
} from 'components/RateCellWithTargetCompact/RateCellWithTarget';
import s from './PurchaseOrderDetailsTable.module.scss';
import { sumBy } from 'lodash-es';
import clsx from 'clsx';
import { ColumnsWithExportRender, removeExportConfig } from 'common/helpers/tableExport';
import { EMPTY } from 'common/constants';

export type DetailItem = NonNullable<
  AtRiskPurchaseOrderDetailsQuery['purchaseOrderDetailsReport']
>['metrics'][number];

export const poDetailsColumnsWithCsvRender: ColumnsWithExportRender<DetailItem> = [
  {
    title: 'DC',
    key: 'dc',
    render: (_, { dc }) => dc || EMPTY,
    exportConfig: {
      render: ({ dc }) => dc || EMPTY
    },
    sorter: (a, b) => (a?.dc || '').localeCompare(b?.dc || '')
  },
  {
    title: 'UPC',
    key: 'upc',
    render: (_, { upc }) => upc || EMPTY,
    exportConfig: {
      render: ({ upc }) => upc || EMPTY
    },
    sorter: (a, b) => (a?.upc || '').localeCompare(b?.upc || '')
  },
  {
    title: 'External ID',
    key: 'externalId',
    render: (_, { externalId }) => externalId || EMPTY,
    exportConfig: {
      render: ({ externalId }) => externalId || EMPTY
    },
    sorter: (a, b) => (a?.externalId || '').localeCompare(b?.externalId || '')
  },
  {
    title: 'Description',
    key: 'description',
    render: (_, { description }) => description || EMPTY,
    exportConfig: {
      render: ({ description }) => description || EMPTY
    },
    sorter: (a, b) => (a?.description || '').localeCompare(b?.description || '')
  },
  {
    title: 'Orig. QTY',
    key: 'submittedQuantity',
    render: (_, { submittedQuantity }) => (
      <div className={s.number_cell}>{submittedQuantity.toLocaleString()}</div>
    ),
    exportConfig: {
      render: ({ submittedQuantity }) => submittedQuantity
    },
    sorter: (a, b) => a.submittedQuantity - b.submittedQuantity
  },
  {
    title: 'Accepted QTY',
    key: 'acceptedQuantity',
    render: (_, { acceptedQuantity }) => (
      <div className={s.number_cell}>{acceptedQuantity.toLocaleString()}</div>
    ),
    exportConfig: {
      render: ({ acceptedQuantity }) => acceptedQuantity
    },
    sorter: (a, b) => a.acceptedQuantity - b.acceptedQuantity
  },
  {
    title: 'Delivered QTY',
    key: 'deliveredQuantity',
    render: (_, { deliveredQuantity }) => (
      <div className={s.number_cell}>{deliveredQuantity.toLocaleString()}</div>
    ),
    exportConfig: {
      render: ({ deliveredQuantity }) => deliveredQuantity
    },
    sorter: (a, b) => a.deliveredQuantity - b.deliveredQuantity
  },
  {
    title: 'AFR',
    width: 90,
    key: 'acceptedFillRate',
    render: (_, { acceptedFillRate }) => <RateCellWithTargetCompact value={acceptedFillRate} />,
    exportConfig: {
      render: ({ acceptedFillRate }) => parseFloat(acceptedFillRate)
    },
    onCell: ({ acceptedFillRate }) => onCellRateWithTargetCompact(acceptedFillRate),
    sorter: (a, b) => a.acceptedFillRate - b.acceptedFillRate
  },
  {
    title: 'SFR',
    width: 90,
    key: 'submittedFillRate',
    render: (_, { submittedFillRate }) => <RateCellWithTargetCompact value={submittedFillRate} />,
    exportConfig: {
      render: ({ submittedFillRate }) => parseFloat(submittedFillRate)
    },
    onCell: ({ submittedFillRate }) => onCellRateWithTargetCompact(submittedFillRate),
    sorter: (a, b) => a.submittedFillRate - b.submittedFillRate
  },
  {
    title: 'Cut Reason',
    key: 'reason',
    exportConfig: {
      render: ({ reason }) => reason || EMPTY
    },
    render: (_, { reason }) => reason || EMPTY,
    sorter: (a, b) => (a?.reason || '').localeCompare(b?.reason || '')
  }
];

const columns = removeExportConfig(poDetailsColumnsWithCsvRender);

export const PurchaseOrderDetailsTable = ({
  uniqueOrderId,
  customerPo
}: {
  uniqueOrderId: string;
  customerPo: string;
}) => {
  const { data, loading, error } = useQuery(AtRiskPurchaseOrderDetailsDocument, {
    variables: {
      uniqueOrderId
    }
  });

  const metrics = useMemo(() => data?.purchaseOrderDetailsReport?.metrics || [], [data]);
  // We can do it in one pass, but it's more cumbersome
  const submittedQuantityTotal = useMemo(() => sumBy(metrics, 'submittedQuantity'), [metrics]);
  const acceptedQuantityTotal = useMemo(() => sumBy(metrics, 'acceptedQuantity'), [metrics]);
  const deliveredQuantityTotal = useMemo(() => sumBy(metrics, 'deliveredQuantity'), [metrics]);

  const INITIAL_PAGINATION = 50;
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION);
  const isSinglePage = metrics.length <= pageSize;
  const isPaginationHidden = metrics.length <= INITIAL_PAGINATION;

  if (error || !uniqueOrderId) {
    return <div>Can not load PO details for {customerPo}</div>;
  }

  return (
    <div className={clsx(s.container, isPaginationHidden && s.containerSinglePage)}>
      <Table
        rowKey={(row) => `${row.id || ''}-${row.externalId || ''}`}
        className="styled_report_table inner_table_top_pagination_margin"
        size="small"
        dataSource={data?.purchaseOrderDetailsReport?.metrics || []}
        columns={columns}
        pagination={
          isPaginationHidden
            ? false
            : {
                position: ['topRight'],
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200'],
                pageSize: pageSize,
                size: 'small',
                onShowSizeChange: (_current, size) => setPageSize(size)
              }
        }
        bordered
        loading={loading}
        summary={(data) => {
          if (!data || data?.length === 0) return <></>;
          // We can do it in one pass, but it's more cumbersome
          const submittedQuantity = sumBy(data, 'submittedQuantity');
          const acceptedQuantity = sumBy(data, 'acceptedQuantity');
          const deliveredQuantity = sumBy(data, 'deliveredQuantity');

          return (
            <Table.Summary>
              {isSinglePage ? (
                <></>
              ) : (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1} className={s.total} colSpan={4}>
                    This page
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} className={s.total}>
                    {submittedQuantity.toLocaleString()}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className={s.total}>
                    {acceptedQuantity.toLocaleString()}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className={s.total}>
                    {deliveredQuantity.toLocaleString()}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} className={s.total} colSpan={4}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className={s.total}>
                  {submittedQuantityTotal.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className={s.total}>
                  {acceptedQuantityTotal.toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className={s.total}>
                  {deliveredQuantityTotal.toLocaleString()}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};
