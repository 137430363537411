export const PO_STATUS_NEW = 'NEW';
export const PO_STATUS_OPEN = 'OPEN'; // REMOVE THIS UNUSED CODE IF BE HAS REMOVED THIS STATUS
export const PO_STATUS_SUBMITTED = 'SUBMITTED';
export const PO_STATUS_ACKNOWLEDGED = 'ACKNOWLEDGED';
export const PO_STATUS_ASN_RECEIVED = 'ASN RECEIVED';
export const PO_STATUS_ASN_SENT = 'ASN SENT';
export const PO_STATUS_DELIVERED = 'DELIVERED';
export const PO_STATUS_INVOICED = 'INVOICED';
export const PO_STATUS_CANCELLED = 'CANCELLED';
export const PO_STATUS_SCRUBBED = 'SCRUBBED';
export const PO_STATUS_EXPORTED = 'EXPORTED';
export const PO_STATUS_CLOSED = 'CLOSED'; // REMOVE THIS UNUSED CODE IF BE HAS REMOVED THIS STATUS
export const PO_STATUS_CONFIRMED = 'CONFIRMED';
export const PO_STATUS_READY_FOR_DELIVERY = 'READY FOR DELIVERY';
export const PO_STATUS_ERROR = 'ERROR'; // added for error visibility

export const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100, 200];
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[1];

export const SALES_ORDER_ITEM_CUT_REASONS = [
  'Oversell',
  'Comp Delay',
  'Comp Shortage',
  'Invalid',
  'Comp Expiry',
  'Full Pallet Rounding',
  'Quality Issue',
  'DISCO',
  'No Forecast',
  'SAP Item Error',
  'TL Rounding',
  'Repack Delay'
];

export const PO_STATUS_LIST = [
  PO_STATUS_NEW,
  PO_STATUS_SUBMITTED,
  PO_STATUS_ACKNOWLEDGED,
  PO_STATUS_ASN_RECEIVED,
  PO_STATUS_ASN_SENT,
  PO_STATUS_DELIVERED,
  PO_STATUS_INVOICED,
  PO_STATUS_CANCELLED
];

interface PO_ERROR_MAP_TYPE {
  [index: string]: string;
}
interface POI_ERROR_MAP_TYPE {
  [index: string]: string;
}

interface POI_WARNING_MAP_TYPE {
  [index: string]: string;
}

export const PO_ERROR_FILTER_MAP: PO_ERROR_MAP_TYPE = {
  DISTRIBUTION_CENTER_NOT_FOUND: 'Distribution center not found',
  DUPLICATE_ITEM: 'Duplicate item',
  MISSING_SALES_ORDERS: 'Missing sales order(s)',
  PRICE_MISMATCH: 'Price mismatch',
  PRODUCT_NOT_AVAILABLE_FOR_SALE: 'Product not available for sale',
  PRODUCT_NOT_FOUND: 'Product not found',
  SHIP_TO_NOT_FOUND: 'Ship-to destination not found',
  UNDER_MOQ: 'Under MOQ',
  WRONG_UOM: 'Wrong UOM'
};

export const PO_ERROR_MAP: PO_ERROR_MAP_TYPE = {
  ...PO_ERROR_FILTER_MAP,
  // we don't use these anymore? is this true?
  // FAILED_TO_SEND_INVOICE: 'Failed to send invoice',
  // INACTIVE_ASSORTMENT: 'Inactive assortment,
  // MISSING_ASSORTMENT: 'Missing assortment',
  // RELEASE_ERROR: 'Release Error',
  // MISSING_SALES_ORDERS: 'Missing sales order(s)',
  // Where do the below errors exist in the schema?
  FAILED_CONFIRMATION: 'Failed Confirmation',
  FAILED_RELEASE: 'Failed to Release'
};

export const POI_ERROR_MAP: POI_ERROR_MAP_TYPE = {
  DUPLICATE_ITEM: 'Duplicate item',
  PRICE_MISMATCH: 'Price mismatch',
  PRODUCT_NOT_AVAILABLE_FOR_SALE: 'Product not available for sale',
  PRODUCT_NOT_FOUND: 'Product not found',
  UNDER_MOQ: 'Under MOQ',
  WRONG_UOM: 'Wrong UOM',
  DISTRIBUTION_CENTER_NOT_FOUND: 'DC not found'
};

export const POI_WARNING_MAP: POI_WARNING_MAP_TYPE = {
  PRICE_MISMATCH: 'Price mismatch',
  UNDER_MOQ: 'Under MOQ',
  WRONG_UOM: 'Wrong UOM'
};

export const ALGORITHM_MAP = {
  P50: 'Amazon P50',
  P90: 'Amazon P90',
  SALES_AVERAGE_28_DAYS: 'Trailing 28 day avg',
  SALES_AVERAGE_30_DAYS: 'Trailing 30 day avg',
  SMARTR: 'SmartR algorithm'
};

export const AUTHORIZATION_ERROR = 'forbidden';
export const AUTHENTICATION_ERROR = 'unauthenticated';

export const RESPONSE_STATUS_SUCCESS = 'success';
export const RESPONSE_STATUS_ERROR = 'error';

export const BULK_UPDATE_SUCCESS_MESSAGE = 'Your PO has been updated';
export const BULK_UPDATE_ERROR_MESSAGE = 'There was a problem uploading your PO file';

export const PO_AMAZON_LINK =
  'https://vendorcentral.amazon.com/po/vendor/members/po-mgmt/order?poId=';

export enum ModalAction {
  ADD,
  EDIT
}

export const RETAILER_CHANNEL_COM = 'com';
export const RETAILER_CHANNEL_SAMS_CLUB = 'SAMS_CLUB';

// TODO: use from generated?
export enum DeliveryType {
  DSD = 'DSD',
  ECOMM = 'ECOMM',
  WHD = 'WHD'
}

export const DEFAULT_TIME_FRAME = 90;

export const ORDER_ACCEPTED_CONFIRMATION_TYPE = 'ORDER_ACCEPTED';
export const ConfirmationType = [
  {
    name: 'DOWN_CONFIRM',
    description: 'Down confirm'
  },
  {
    name: ORDER_ACCEPTED_CONFIRMATION_TYPE,
    description: 'Order Accepted'
  }
];

export enum FilterOperator {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE'
}

export enum CurrencyCodes {
  RZB = 'RZB',
  CAD = 'CAD',
  USD = 'USD',
  PLN = 'PLN',
  GBP = 'GBP'
}

export enum CountryCodes {
  US = 'US',
  PL = 'PL',
  CA = 'CA',
  GB = 'GB'
}

export const ActiveType = [
  {
    name: 'ACTIVE',
    description: 'Active'
  },
  {
    name: 'INACTIVE',
    description: 'Inactive'
  }
];

export enum AvailableCarrierCodes {
  UPSS = 'UPSS',
  ODFL = 'ODFL'
}

export enum MeasurementSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC'
}

export const NOT_AVAILABLE = 'N/A';
export const EMPTY = '--';

//details page, purchase orders page, purchase order status component for history
export const PurchaseOrderRefetchQueries = [
  'orderDetails',
  'purchaseOrdersForPurchaseOrdersPage',
  'purchaseOrder',
  'purchaseOrderCounts'
];
