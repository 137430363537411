import * as Types from '../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExportSeedDataQueryVariables = Types.Exact<{
  purchaseOrderIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  kind?: Types.InputMaybe<Types.ExportSeedDataKind>;
}>;


export type ExportSeedDataQuery = { __typename?: 'RootQueryType', exportSeedData?: string | null };


export const ExportSeedDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"exportSeedData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"kind"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ExportSeedDataKind"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportSeedData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"purchaseOrderIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"kind"},"value":{"kind":"Variable","name":{"kind":"Name","value":"kind"}}}]}]}}]} as unknown as DocumentNode<ExportSeedDataQuery, ExportSeedDataQueryVariables>;