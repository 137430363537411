import { Label } from 'components/alloy/Label/Label';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { FieldInfo } from 'components/alloy/FieldInfo/FieldInfo';
import { v4 as uuidv4 } from 'uuid';
import { Input, InputProps } from 'components/alloy/Input/Input';

export interface FormInputProps extends InputProps<string> {
  info?: string;
  required?: boolean;
  hideFieldInfo?: boolean;
}

export const FormInput = ({
  info,
  label,
  error,
  disabled,
  className,
  required,
  'data-testid': dataTestid,
  hideFieldInfo = false,
  ...others
}: FormInputProps) => {
  const uuid = useMemo(() => uuidv4(), []);

  return (
    <div className={clsx(className)} data-testid={dataTestid}>
      {!!label && (
        <Label
          label={label}
          required={required}
          filled={true}
          disabled={disabled}
          error={error}
          htmlFor={`input-${uuid}`}
        />
      )}
      <Input id={`input-${uuid}`} label={label} error={error} disabled={disabled} {...others} />
      {!hideFieldInfo && <FieldInfo info={info} error={error} disabled={disabled} />}
    </div>
  );
};
