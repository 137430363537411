import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReattemptPurchaseOrdersMutationVariables = Types.Exact<{
  input: Types.ReattemptPurchaseOrdersInput;
}>;


export type ReattemptPurchaseOrdersMutation = { __typename?: 'RootMutationType', reattemptPurchaseOrders?: { __typename?: 'ReattemptPurchaseOrdersPayload', purchaseOrdersEnqueuedForReattempt: Array<string> } | null };


export const ReattemptPurchaseOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"reattemptPurchaseOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReattemptPurchaseOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reattemptPurchaseOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrdersEnqueuedForReattempt"}}]}}]}}]} as unknown as DocumentNode<ReattemptPurchaseOrdersMutation, ReattemptPurchaseOrdersMutationVariables>;