import {
  convertWeekToPeriodWeek,
  getFinancialInfo,
  getWeeksInFinancialYear
} from 'common/helpers/fiscalCalendar';
import { YearPeriodWeek } from 'graphql/__generated__/types';
import { range } from 'lodash-es';

const mapWeekAndYearToShortFiscalInfo = (week: number, year: number): ShortFiscalInfo => {
  const { periodWeek, period } = convertWeekToPeriodWeek(week);
  return { periodWeek, week, period, year };
};

interface ShortFiscalInfo {
  week: number;
  year: number;
  period: number;
  periodWeek: number;
}

export function getPeriodWeekYearForRange(start: Date, end: Date): ShortFiscalInfo[] {
  const periodStartFiscal = getFinancialInfo(start);
  const periodEndFiscal = getFinancialInfo(end);

  if (periodStartFiscal.year === periodEndFiscal.year) {
    return range(periodStartFiscal.week, periodEndFiscal.week + 1).map((x) =>
      mapWeekAndYearToShortFiscalInfo(x, periodStartFiscal.year)
    );
  } else {
    const firstRange = range(
      periodStartFiscal.week,
      getWeeksInFinancialYear(periodStartFiscal.year) + 1
    ).map((x) => mapWeekAndYearToShortFiscalInfo(x, periodStartFiscal.year));

    const secondRange = range(1, periodEndFiscal.week + 1).map((x) =>
      mapWeekAndYearToShortFiscalInfo(x, periodEndFiscal.year)
    );

    const otherYears = range(periodStartFiscal.year + 1, periodEndFiscal.year).map((x) =>
      range(1, getWeeksInFinancialYear(x) + 1).map((week) =>
        mapWeekAndYearToShortFiscalInfo(week, x)
      )
    );

    return [...firstRange, ...otherYears.flat(), ...secondRange];
  }
}

export function yearPeriodWeekSorter(a: YearPeriodWeek, b: YearPeriodWeek) {
  if (a.year !== b.year) {
    return a.year - b.year;
  }
  if (a.period !== b.period) {
    return a.period - b.period;
  }
  return a.week - b.week;
}

export function yearWeekSorter(
  a: { year: number; week: number },
  b: { year: number; week: number }
) {
  if (a.year !== b.year) {
    return a.year - b.year;
  }
  return a.week - b.week;
}

/**
 * Transforms a CalendarValue into an array of FiscalCalendarWeekInput objects.
 * @param week (1-53): The week of the year.
 * @param year The year of the input value.
 *
 * @returns next week-year pair
 */
export function getNextWeekAndYear(week: number, year: number) {
  const maxWeek = getWeeksInFinancialYear(year);
  const nextWeek = week + 1;

  if (nextWeek > maxWeek) {
    return { week: 1, year: year + 1 };
  } else {
    return { week: nextWeek, year };
  }
}

/**
 * Transforms a CalendarValue into an array of FiscalCalendarWeekInput objects.
 * @param week (1-53): The week of the year.
 * @param year The year of the input value.
 *
 * @returns previous week-year pair
 */
export function getPreviousWeekAndYear(week: number, year: number) {
  const previousWeek = week - 1;

  if (previousWeek < 1) {
    const previousYear = year - 1;
    const maxWeekPreviousYear = getWeeksInFinancialYear(previousYear);
    return { week: maxWeekPreviousYear, year: previousYear };
  } else {
    return { week: previousWeek, year };
  }
}

/**
 * Wraps getNextWeekAndYear and getPreviousWeekAndYear functions to iterate N times.
 * @param week (1-53): The week of the year.
 * @param year The year of the input value.
 * @param steps The number of steps to move forward (positive) or backward (negative) in the calendar.
 *
 * @returns final week-year pair after moving N steps
 */
export function getWeekAndYearAfterSteps(week: number, year: number, steps: number) {
  let currentWeek = week;
  let currentYear = year;

  if (steps > 0) {
    for (let i = 0; i < steps; i++) {
      const nextWeekYear = getNextWeekAndYear(currentWeek, currentYear);
      currentWeek = nextWeekYear.week;
      currentYear = nextWeekYear.year;
    }
  } else if (steps < 0) {
    for (let i = 0; i < Math.abs(steps); i++) {
      const previousWeekYear = getPreviousWeekAndYear(currentWeek, currentYear);
      currentWeek = previousWeekYear.week;
      currentYear = previousWeekYear.year;
    }
  }

  return { week: currentWeek, year: currentYear };
}
