import { useQuery } from '@apollo/client';
import { App } from 'ant5';
import { RepackOptimizerStage } from 'graphql/__generated__/types';
import { RepackOptimizerStatusDocument } from 'pages/RepackPlanning/gql/__generated__/repackOptimizerStatus.query';
import { useEffect, useState } from 'react';

export const useRepackOptimizerStatus = ({
  refetch,
  stage
}: {
  refetch: () => void;
  stage: RepackOptimizerStage;
}) => {
  const { message } = App.useApp();
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [shouldShowSuccessMessage, setShouldShowSuccessMessage] = useState(false);

  // If stage changes, reset shouldRefetch and shouldShowSuccessMessage
  useEffect(() => {
    setShouldRefetch(true);
    setShouldShowSuccessMessage(false);
  }, [stage]);

  const status = useQuery(RepackOptimizerStatusDocument, {
    variables: { filters: { stage } },
    pollInterval: shouldRefetch ? 15_000 : undefined,
    onCompleted: (data) => {
      if (data.repackOptimizerStatus?.status === 'NOT_STARTED') {
        setShouldShowSuccessMessage(true);
      }
      if (data.repackOptimizerStatus?.status === 'STARTED') {
        setShouldShowSuccessMessage(true);
      }
      if (data.repackOptimizerStatus?.status === 'SUCCESS') {
        refetch();
        if (shouldShowSuccessMessage) {
          message.success('Optimization process successful');
          setShouldShowSuccessMessage(false);
        }
        setShouldRefetch(false);
      }
      if (data.repackOptimizerStatus?.status === 'FAILED') {
        setShouldRefetch(false);
      }
    }
  });

  const shouldShowTable = !status.data || status.data?.repackOptimizerStatus?.status === 'SUCCESS';

  return {
    shouldShowTable,
    status
  };
};
