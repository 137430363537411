import React from 'react';
import './UploadShortMonitorModal.scss';
import { WarningFilled } from '@ant-design/icons';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

interface ShortMonitorErrorModalProps {
  toggleShortMonitorErrorModal: () => void;
  toggleUploadShortMonitor: () => void;
  fileName: string;
  visible: boolean;
  errors: string[];
}

const ShortMonitorErrorModal = ({
  visible,
  errors,
  fileName,
  toggleShortMonitorErrorModal,
  toggleUploadShortMonitor
}: ShortMonitorErrorModalProps) => {
  const reopenUploadShortMonitorModal = () => {
    toggleUploadShortMonitor();
    toggleShortMonitorErrorModal();
  };

  return (
    <AlloyModal
      title={
        <div>
          <span className="error_title_icon">
            <WarningFilled />
          </span>
          Short Monitor file error
        </div>
      }
      className="short_monitor_error"
      destroyOnClose
      open={visible}
      footer={
        <div>
          <AlloyButton
            type="link"
            onClick={reopenUploadShortMonitorModal}
            className="short_monitor_error_modal_button"
          >
            Upload Again
          </AlloyButton>
          <AlloyButton
            type="link"
            onClick={toggleShortMonitorErrorModal}
            className="short_monitor_error_modal_button"
          >
            Dismiss
          </AlloyButton>
        </div>
      }
      onCancel={toggleShortMonitorErrorModal}
    >
      <div>
        <div className="error_file_name">File {fileName} errors</div>
        <ul>
          {errors.map((error, i) => (
            <li key={i}>{error}</li>
          ))}
        </ul>
        <div className="error_footer_msg">Please review and upload again</div>
      </div>
    </AlloyModal>
  );
};

export default ShortMonitorErrorModal;
