import { OtifFilters } from 'graphql/__generated__/types';
import { OtifReportsByPeriodQuery } from './gql/__generated__/otifReportsByPeriod.query';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';

export type OTIFReportData = OtifReportsByPeriodQuery['otifReportsByPeriod'][number];
export type OtifFiltersOnlyWeekInFilter = Omit<OtifFilters, 'fiscalCalendarWeeks'> & {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
};

export const CUT_CATEGORIES = [
  'customer',
  'planning',
  'warehouse',
  'transport',
  'uncategorized'
] as const;
export type CutCategory = (typeof CUT_CATEGORIES)[number];

export const DEFAULT_CATEGORY_TAB: CutCategory = 'customer';

export const CUTS_BY_REASON_CATEGORY = 'category';
