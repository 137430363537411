import React from 'react';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import s from './StatsRow.module.scss';

interface StatsTile {
  title: string;
  value: number | string | React.ReactChild;
  loading?: boolean;
  tooltip?: string;
}
export const StatsRow = ({ stats }: { stats: StatsTile[] }) => {
  return (
    <div className={s.row}>
      <div className={s.row_wrapper}>
        {stats.map((stat) => (
          <div className={s.tile} key={stat.title + stat.value}>
            <div className={s.title}>
              {stat.title}
              {stat.tooltip && (
                <AlloyTooltip title={stat.tooltip} placement="topRight">
                  <InfoCircleOutlined className={s.tooltip_icon} />
                </AlloyTooltip>
              )}
            </div>
            <div className={s.bottom}>
              {stat.loading ? (
                <div className={s.loading} />
              ) : (
                <div className={s.value}> {stat.value} </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
