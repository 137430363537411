import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FillRateReportMetricFragmentDoc } from './fillRateReportMetric.fragment';
export type PreviousFillRateSummaryReportQueryVariables = Types.Exact<{
  appliedFilters: Types.AppliedFillRateReportFilters;
}>;


export type PreviousFillRateSummaryReportQuery = { __typename?: 'RootQueryType', fillRateSummaryReport?: { __typename?: 'FillRateSummaryReport', totalMetrics: { __typename?: 'FillRateReportMetrics', submittedQuantity: number, acceptedQuantity: number, deliveredQuantity: number, cutQuantity: number, acceptedFillRate: any, submittedFillRate: any } } | null };


export const PreviousFillRateSummaryReportDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"previousFillRateSummaryReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"appliedFilters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AppliedFillRateReportFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fillRateSummaryReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"appliedFilters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"appliedFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalMetrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FillRateReportMetric"}}]}}]}}]}},...FillRateReportMetricFragmentDoc.definitions]} as unknown as DocumentNode<PreviousFillRateSummaryReportQuery, PreviousFillRateSummaryReportQueryVariables>;