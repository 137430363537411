import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateTradingPartnerActiveAssortmentStatusMutationVariables = Types.Exact<{
  input: Types.UpdateTradingPartnerActiveAssortmentStatusInput;
}>;


export type UpdateTradingPartnerActiveAssortmentStatusMutation = { __typename?: 'RootMutationType', updateTradingPartnerActiveAssortmentStatus?: { __typename?: 'UpdateTradingPartnerActiveAssortmentStatusPayload', impactedPurchaseOrders?: Array<string> | null } | null };


export const UpdateTradingPartnerActiveAssortmentStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateTradingPartnerActiveAssortmentStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateTradingPartnerActiveAssortmentStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTradingPartnerActiveAssortmentStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"impactedPurchaseOrders"}}]}}]}}]} as unknown as DocumentNode<UpdateTradingPartnerActiveAssortmentStatusMutation, UpdateTradingPartnerActiveAssortmentStatusMutationVariables>;