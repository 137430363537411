import React from 'react';
import s from './ValidationMessage.module.scss';
import clsx from 'clsx';
import { get } from 'lodash-es';
import { camelCaseToSpaces } from 'common/helpers/stringsConverter';

const getArrayOfErrorsToString = (errorArray: any[]): string => {
  const uniqueError: string[] = [];
  errorArray.forEach((errorItem) => {
    if (!errorItem) return;
    Object.getOwnPropertyNames(errorItem).forEach((fieldError) => {
      const error = `${camelCaseToSpaces(fieldError)}: ${get(errorItem, fieldError)}`;
      if (!uniqueError.includes(error)) {
        uniqueError.push(error);
      }
    });
  });

  return uniqueError.join('. ');
};

export const ValidationMessage = ({
  meta,
  overrideValidationMessage
}: {
  meta: any;
  overrideValidationMessage?: string;
}) => (
  <div
    data-testid="validation-message"
    className={clsx(
      s.validation_counter,
      meta.error && meta.touched ? s.invalid : meta.valid && meta.touched ? s.valid : ''
    )}
  >
    {meta.error && meta.touched && (
      <span>
        {overrideValidationMessage
          ? overrideValidationMessage
          : typeof meta.error === 'object'
          ? getArrayOfErrorsToString(meta.error)
          : meta.error}
      </span>
    )}
  </div>
);
