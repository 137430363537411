import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GenerateBulkCustomizationsMutationVariables = Types.Exact<{
  input: Types.GenerateSalesOrdersCustomizationsInput;
}>;


export type GenerateBulkCustomizationsMutation = { __typename?: 'RootMutationType', generateSalesOrdersCustomizations?: { __typename?: 'GenerateSalesOrdersCustomizationsPayload', customizations: Array<{ __typename?: 'GeneratedCustomization', content?: string | null, filename?: string | null, merged?: boolean | null, success?: boolean | null, salesOrders: Array<{ __typename?: 'SalesOrder', id: string, externalId?: string | null } | null> }>, customizationsErrors: Array<{ __typename?: 'GeneratedCustomizationError', status?: Types.CustomizationSendingStatus | null, salesOrder: { __typename?: 'SalesOrder', id: string, externalId?: string | null } }> } | null };


export const GenerateBulkCustomizationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"generateBulkCustomizations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateSalesOrdersCustomizationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateSalesOrdersCustomizations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"merged"}},{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"salesOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"success"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customizationsErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"salesOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GenerateBulkCustomizationsMutation, GenerateBulkCustomizationsMutationVariables>;