import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CancelPurchaseOrdersMutationVariables = Types.Exact<{
  input: Types.CancelPurchaseOrdersInput;
}>;


export type CancelPurchaseOrdersMutation = { __typename?: 'RootMutationType', cancelPurchaseOrders?: { __typename?: 'CancelPurchaseOrdersPayload', purchaseOrders: Array<{ __typename?: 'PurchaseOrder', id: string, statuses?: { __typename?: 'PurchaseOrderStatuses', primary?: string | null, secondary: Array<string> } | null }> } | null };


export const CancelPurchaseOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"cancelPurchaseOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelPurchaseOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelPurchaseOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"primary"}},{"kind":"Field","name":{"kind":"Name","value":"secondary"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CancelPurchaseOrdersMutation, CancelPurchaseOrdersMutationVariables>;