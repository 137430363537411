import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import { validateRequired } from 'components/Common/fields/Utils';
import React from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import s from './CustomizationForm.module.scss';
import { FinalFormSelect } from '@/src/components/Common/fields/FinalFormSelect/FinalFormSelect';
import { useEnumValue } from '@/src/common/useEnumValue';

export const CustomizationForm = ({ upserting }: { upserting: boolean }) => {
  const form = useForm();

  const { enumValues: fieldTypeValues, loading: fieldTypeValuesLoading } = useEnumValue(
    'CustomizationHeaderFieldType'
  );

  return (
    <div>
      <FieldArray name="customizationHeaders">
        {({ fields }) => (
          <>
            <Row className={s.customization_fields_title}>
              Customization fields
              <Button
                shape="circle"
                type="text"
                onClick={() => {
                  fields.push({ field: '', title: '', fieldType: 'TEXT' });
                }}
              >
                <PlusOutlined />
              </Button>
            </Row>
            <Row
              style={{
                display: !form.getFieldState('customizationHeaders')?.value ? 'none' : undefined
              }}
            >
              {fields.map((name, index) => (
                <div key={index} className={s.customization_field_item}>
                  <div className={s.customization_priority}>{index + 1}</div>
                  <FinalFormInput
                    name={`${name}.field`}
                    title="Field"
                    required
                    validate={upserting ? validateRequired : undefined}
                  />
                  <FinalFormInput
                    name={`${name}.title`}
                    title="Title"
                    required
                    validate={upserting ? validateRequired : undefined}
                  />

                  <FinalFormSelect
                    name={`${name}.fieldType`}
                    loading={fieldTypeValuesLoading}
                    data-testid="dc-customization-type"
                    title="Type"
                    options={(fieldTypeValues || []).map((type) => ({
                      value: type,
                      label: type
                    }))}
                    required
                    validate={upserting ? validateRequired : undefined}
                  />

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              ))}
            </Row>
          </>
        )}
      </FieldArray>
    </div>
  );
};
