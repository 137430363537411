import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetTradingPartnerQueryVariables = Types.Exact<{
  tpExternalIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type GetTradingPartnerQuery = { __typename?: 'RootQueryType', getTradingPartners?: Array<{ __typename?: 'TradingPartner', id: string, name?: string | null, externalId: string } | null> | null };

export type GetDCsQueryVariables = Types.Exact<{
  dcCodes: Array<Types.DcInput> | Types.DcInput;
}>;


export type GetDCsQuery = { __typename?: 'RootQueryType', getDcs?: Array<{ __typename?: 'DcDetails', distributionCenterId?: number | null, distributionCenterCode?: string | null, distributionCenterName?: string | null, distributionCenterSource?: string | null } | null> | null };

export type GetGtinDataQueryVariables = Types.Exact<{
  tpIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  gtin14s?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type GetGtinDataQuery = { __typename?: 'RootQueryType', getGtin14Dcs?: Array<{ __typename?: 'Gtin14Dc', gtin14?: string | null, gtin14ProductId?: number | null, distributionCenterIds?: Array<number | null> | null, tradingPartnerIds?: Array<number | null> | null, gtin14ProductDescription?: string | null } | null> | null };


export const GetTradingPartnerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTradingPartner"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tpExternalIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTradingPartners"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"tpExternalIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tpExternalIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]} as unknown as DocumentNode<GetTradingPartnerQuery, GetTradingPartnerQueryVariables>;
export const GetDCsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getDCs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dcCodes"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DcInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getDcs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dcCodes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dcCodes"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"distributionCenterId"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterCode"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterName"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterSource"}}]}}]}}]} as unknown as DocumentNode<GetDCsQuery, GetDCsQueryVariables>;
export const GetGtinDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGtinData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tpIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gtin14s"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getGtin14Dcs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"tpIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tpIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"gtin14s"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gtin14s"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gtin14"}},{"kind":"Field","name":{"kind":"Name","value":"gtin14ProductId"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterIds"}},{"kind":"Field","name":{"kind":"Name","value":"tradingPartnerIds"}},{"kind":"Field","name":{"kind":"Name","value":"gtin14ProductDescription"}}]}}]}}]} as unknown as DocumentNode<GetGtinDataQuery, GetGtinDataQueryVariables>;