import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdatePurchaseOrderConfirmationExpectedDeliveryDateMutationVariables = Types.Exact<{
  input: Types.UpdatePurchaseOrderConfirmationExpectedDeliveryDateInput;
}>;


export type UpdatePurchaseOrderConfirmationExpectedDeliveryDateMutation = { __typename?: 'RootMutationType', updatePurchaseOrderConfirmationExpectedDeliveryDate?: { __typename?: 'UpdatePurchaseOrderConfirmationExpectedDeliveryDatePayload', purchaseOrderConfirmation?: { __typename?: 'PurchaseOrderConfirmation', id: string, expectedDeliveryDate?: any | null } | null } | null };


export const UpdatePurchaseOrderConfirmationExpectedDeliveryDateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePurchaseOrderConfirmationExpectedDeliveryDate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePurchaseOrderConfirmationExpectedDeliveryDateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePurchaseOrderConfirmationExpectedDeliveryDate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrderConfirmation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expectedDeliveryDate"}}]}}]}}]}}]} as unknown as DocumentNode<UpdatePurchaseOrderConfirmationExpectedDeliveryDateMutation, UpdatePurchaseOrderConfirmationExpectedDeliveryDateMutationVariables>;