import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import s from 'pages/LoginPage/LoginPage.module.scss';
import { setItem } from 'common/services/persistentStorageServices';
import { ApolloLink, HttpLink, toPromise } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { AUTHENTICATION_ERROR } from 'common/constants';
import { isLoggedInThroughSso } from 'common/helpers/routerHelper';
import { removeTempItem, setTempItem } from 'common/services/temporaryStorageServices';
import { GetMeDocument } from 'queries/__generated__/users';
import { isProductionBackend } from 'common/helpers/identificateEnvironment';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyCard } from 'components/ui/AlloyCard/AlloyCol';

// Emulate Axios error
class FetchError extends Error {
  response: {
    status: number;
    statusText: string;
    headers: Headers;
    data: any;
  };

  constructor(message: string, response: Response, data: any) {
    super(message);
    this.response = {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      data: data
    };
  }
}

interface LoginFormFieldValues {
  email: string;
  password: string;
}
const getHttpLink = (uri: string, token?: string) =>
  new HttpLink({
    uri,
    credentials: 'include',
    headers: token
      ? {
          authorization: `Bearer ${token}`
        }
      : undefined
  });

const uri = `${import.meta.env.REACT_APP_API_URL}/supply_chain/graphql`;
const link = getHttpLink(uri);
const operation = {
  query: GetMeDocument
};

const LoginPage = () => {
  const { message } = App.useApp();
  let [spinner, setSpinner] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const history = useHistory();
  const returnAddress = encodeURIComponent(window.location?.href);

  toPromise(ApolloLink.execute(link, operation))
    .then(({ data, errors }: any) => {
      if (data.me) {
        const { name, websocketToken } = data.me;
        setItem('user', { name, websocket_token: websocketToken });
        removeTempItem('sso_login_flag');
        history.push('/');
      } else if (
        errors.some(
          (error: GraphQLError) => error.message === AUTHENTICATION_ERROR && isLoggedInThroughSso()
        )
      ) {
        setLoginErrorMessage('SSO Authentication Failed');
      }
    })
    .catch((error) => setLoginErrorMessage(error.message));

  const onSubmit = async ({ email, password }: LoginFormFieldValues) => {
    setSpinner(true);
    try {
      const response = await fetch(`${import.meta.env.REACT_APP_API_URL}/sessions`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          session: {
            email,
            password
          }
        })
      });

      const responseData = await response.json();

      // Since it's fetch and not axios, we need to manually throw on not ok status
      // If the HTTP status code is not in the 2xx range,
      // we throw an error.
      if (!response.ok) {
        throw new FetchError('An error occurred while fetching the data.', response, responseData);
      }

      const { api_token } = responseData.data;

      const { websocketToken, name } = (
        await toPromise(
          ApolloLink.execute(getHttpLink(uri, api_token), {
            query: GetMeDocument
          })
        )
      ).data?.me;
      setItem('user', { api_token, name, websocket_token: websocketToken });
      history.push('/');
    } catch (error) {
      const detail = (error as FetchError)?.response?.data?.errors?.detail;

      if (detail?.includes('Already logged in')) {
        message.error('Please, logout from https://marketing-dashboard.pepsi-ecom.co/');
      } else if (detail) {
        message.error(detail);
      } else {
        message.error('Error: try again later');
        console.error(error);
      }
      setSpinner(false);
    }
  };

  return (
    <AlloyRow className={s.login_page}>
      <AlloyCol span={6} offset={9}>
        <AlloyCard>
          {!isProductionBackend() && (
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form data-testid="login_form" className={s.login_form} onSubmit={handleSubmit}>
                  <h1 className={s.title}>Please login</h1>
                  <Field
                    name="email"
                    data-testid="email"
                    component="input"
                    type="email"
                    placeholder="Email"
                  />
                  <Field
                    name="password"
                    data-testid="password"
                    component="input"
                    type="password"
                    placeholder="Password"
                  />
                  <div>
                    <AlloyButton
                      type="primary"
                      data-testid="SignInSubmit"
                      htmlType="submit"
                      disabled={submitting || pristine}
                      loading={spinner}
                    >
                      Submit
                    </AlloyButton>
                  </div>
                </form>
              )}
            />
          )}
          {isProductionBackend() && (
            <div className={s.sso_login}>
              <a
                href={`https://marketing-api.pepsi-ecom.co/sso_redirect?return_to=${returnAddress.replace(
                  'login',
                  ''
                )}`}
                onClick={() => setTempItem('sso_login_flag', true)}
              >
                Sign in using PepsiCo SSO
              </a>
            </div>
          )}
          <div className={s.loginErrorMessage}>{loginErrorMessage}</div>
        </AlloyCard>
      </AlloyCol>
    </AlloyRow>
  );
};
export default LoginPage;
