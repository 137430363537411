import React, { ReactNode, useState } from 'react';
import {
  notificationModalInitialValues,
  destinationScheduleModalInitialValues
} from 'common/initialValues';

type ProviderProps = {
  children: ReactNode;
};

export const ModalContext = React.createContext({
  modalProps: {
    notificationModal: notificationModalInitialValues,
    destinationScheduleModal: destinationScheduleModalInitialValues
  },
  setModalProps: (props: any) => {}
});

const ModalContextProvider = (props: ProviderProps) => {
  const [modalState, setModalState] = useState({
    notificationModal: notificationModalInitialValues,
    destinationScheduleModal: destinationScheduleModalInitialValues
  });

  return (
    <ModalContext.Provider value={{ modalProps: modalState, setModalProps: setModalState }}>
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
