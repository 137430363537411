import React from 'react';
import { DatePicker as AntDatePicker, DatePickerProps, TimeRangePickerProps } from 'ant5';

export function AlloyDatePicker({ ...otherProps }: DatePickerProps) {
  return <AntDatePicker {...otherProps} />;
}

const { RangePicker: AntRangePicker } = AntDatePicker;
export function AlloyRangePicker({ ...otherProps }: TimeRangePickerProps) {
  return <AntRangePicker {...otherProps} />;
}

// Re-exporting types
export type { DatePickerProps };
export type { TimeRangePickerProps };
