import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReprocessPurchaseOrderMutationVariables = Types.Exact<{
  input: Types.ReprocessPurchaseOrderInput;
}>;


export type ReprocessPurchaseOrderMutation = { __typename?: 'RootMutationType', reprocessPurchaseOrder?: { __typename?: 'ReprocessPurchaseOrderPayload', purchaseOrder?: { __typename?: 'PurchaseOrder', id: string, status?: Types.PurchaseOrderStatus | null, statuses?: { __typename?: 'PurchaseOrderStatuses', primary?: string | null, secondary: Array<string> } | null } | null } | null };


export const ReprocessPurchaseOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"reprocessPurchaseOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReprocessPurchaseOrderInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reprocessPurchaseOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"primary"}},{"kind":"Field","name":{"kind":"Name","value":"secondary"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ReprocessPurchaseOrderMutation, ReprocessPurchaseOrderMutationVariables>;