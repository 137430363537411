import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validateRequired } from '../../Common/fields/Utils';
import {
  FormInputNumber,
  FormInputNumberProps
} from 'components/alloy/formFields/FormInputNumber/FormInputNumber';

interface FinalFormInputNumberProps extends FormInputNumberProps {
  name: string;
  title?: string;
  validate?: FieldValidator<number>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  showCount?: string;
}

export const FinalFormInputNumber = ({
  name,
  title,
  disabled,
  validate,
  required,
  onBlur,
  showCount,
  'data-testid': dataTestid = '',
  placeholder,
  className,
  hideFieldInfo
}: FinalFormInputNumberProps) => {
  return (
    <Field<number>
      name={name}
      required={required}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
    >
      {({ input, meta }) => {
        return (
          <FormInputNumber
            {...input}
            label={title}
            data-testid={dataTestid}
            disabled={disabled}
            onBlur={(event) => {
              onBlur && onBlur(event);
              input.onBlur(event);
            }}
            className={className}
            placeholder={placeholder}
            error={meta.error && (meta.modified || meta.touched) ? meta.error : undefined}
            required={required}
            hideFieldInfo={hideFieldInfo}
            info={showCount ? `${`${input.value}`.length}/${showCount}` : undefined}
          />
        );
      }}
    </Field>
  );
};
