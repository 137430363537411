import React from 'react';
import s from './OldCutsByReasonGraph.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { CutOrdersFilters } from 'graphql/__generated__/types';
import { useQuery } from '@apollo/client';
import { EMPTY } from 'common/constants';
import { safeNumberComparator } from 'common/helpers/comparators';
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer } from 'recharts';
import { CutReasonsByCategoryDocument } from '../gql/__generated__/cutReasonsByCategory.query';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import {
  CUT_CATEGORIES,
  CUTS_BY_REASON_CATEGORY,
  DEFAULT_CATEGORY_TAB
} from 'pages/OnTimeInFull/types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { capitalize, truncate } from 'lodash-es';

const formatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  return value.toFixed(1) + '%';
};

const safeParseFloat = (n: number | string | undefined) => {
  return (typeof n === 'string' ? parseFloat(n) : n) || 0;
};

const CATEGORIES = CUT_CATEGORIES.map((x) => ({ value: x, label: capitalize(x) }));

const colors = [
  {
    color: '#162F80'
  },
  {
    color: '#3260B5'
  },
  {
    color: '#5D99EB'
  },
  {
    color: '#78E2EC',
    text: 'black'
  },
  {
    color: '#96F9F3',
    text: 'black'
  },
  {
    color: '#F7F48C',
    text: 'black'
  },
  {
    color: '#FFBD57',
    text: 'black'
  },
  {
    color: '#CF8915'
  },
  {
    color: '#9A611B'
  },
  {
    color: '#724806'
  }
];

const getColor = (idx: number) => {
  if (idx >= colors.length) return colors[colors.length - 1];
  return colors[idx];
};

export const OldCutsByReasonGraph = ({ filters }: { filters: CutOrdersFilters }) => {
  const [category, setCategory] = useQueryParam(
    CUTS_BY_REASON_CATEGORY,
    withDefault(StringParam, DEFAULT_CATEGORY_TAB)
  );

  // TODO: actually send BUs here
  const { loading, data } = useQuery(CutReasonsByCategoryDocument, {
    variables: { filters: { category, fiscalCalendarWeeks: filters.fiscalCalendarWeeks } }
  });

  const categories = (data?.cutReasonsByCategory || []).sort((a, b) =>
    safeNumberComparator(b.percentage, a.percentage)
  );
  const categoriesForGraph = categories.map((x) => ({ name: x.reason, value: x.percentage * 100 }));

  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>
        <AlloySelect
          value={category}
          onChange={(value) => {
            setCategory(value);
          }}
          variant="borderless"
          dropdownStyle={{ minWidth: '140px', fontWeight: 'bold' }}
          options={CATEGORIES}
          className={s.borderlessSelect}
        />{' '}
        breakdown
      </h2>
      <div style={{ width: '100%' }}>
        <AlloySpin spinning={loading} className={s.spinner}>
          <div className={s.graphAndLegendWrapper}>
            {categoriesForGraph.length ? (
              <>
                <div className={s.graphWrapper}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={260} height={260}>
                      {/* Represents other part of a circle, so "uncut" */}
                      <Pie
                        data={categoriesForGraph}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={130}
                        isAnimationActive={false}
                        minAngle={0}
                        label={(props) =>
                          renderCustomizedLabelForCuts({ ...props, data: categoriesForGraph })
                        }
                        labelLine={false}
                        strokeWidth={categoriesForGraph.length > 1 ? 1 : 0}
                      >
                        {categoriesForGraph.map((_, index) => (
                          <Cell key={`cell-${index}`} fill={getColor(index).color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div>
                  {categories.map(({ reason, percentage }, idx) => (
                    <div key={reason} className={s.legend}>
                      <div>{reason}</div>
                      <div>{formatPercentage(safeParseFloat(percentage) * 100)}</div>
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          borderRadius: '2px',
                          backgroundColor: getColor(idx).color
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              !loading && <div className={s.noCuts}>No cuts in this category</div>
            )}
          </div>
        </AlloySpin>
      </div>
    </div>
  );
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabelForCuts = ({
  cx: rawCx,
  cy: rawCy,
  midAngle: rawMidAngle,
  innerRadius: rawInnerRadius,
  outerRadius: rawOuterRadius,
  name,
  index: rawIndex,
  data
}: PieLabelRenderProps & {
  data: [
    {
      name: string;
      value: number;
      actualValue: number;
    }
  ];
}) => {
  if (name.toLowerCase() === '') return <></>;
  const cx = safeParseFloat(rawCx);
  const cy = safeParseFloat(rawCy);
  const innerRadius = safeParseFloat(rawInnerRadius);
  const outerRadius = safeParseFloat(rawOuterRadius);
  const midAngle = safeParseFloat(rawMidAngle);
  const index = safeParseFloat(rawIndex);

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN) - radius * 0.15;

  return Math.abs(data[index].value) > 10 ? (
    <text
      x={x}
      y={y}
      fill={getColor(index).text || 'white'}
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan x={x} dy="0" className={s.cutsLabel}>
        {truncate(name, {
          omission: '…',
          length: data[index].value > 50 ? 25 : 15
        })}
      </tspan>
      <tspan x={x} dy="24px" className={s.cutsPercentage}>
        {formatPercentage(data[index].value)}
      </tspan>
    </text>
  ) : (
    <></>
  );
};
