import { useState, useEffect } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import {
  PurchaseOrderConfirmation,
  PurchaseOrderItemConfirmation
} from 'graphql/__generated__/types';
import { chunk } from 'lodash-es';
import {
  PurchaseOrdersDocument,
  PurchaseOrdersQuery
} from './gql/__generated__/getDownConfrimedOrders.query';
import { GeneratePurchaseOrderConfirmationsDocument } from '../DownConfirmationModal/gql/__generated__/generatePurchaseOrderConfirmations.mutation';
import * as Sentry from '@sentry/browser';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { App } from 'ant5';

// This hook takes in an array PO ids and batchSize (# of POs) as arguments

export type ExtendedPOItemConfirmation = PurchaseOrderItemConfirmation & { status: string };

export type PartialPOConfirmation = Pick<
  PurchaseOrderConfirmation,
  'status' | 'purchaseOrderItemConfirmations' | 'confirmationType'
>;

type DownConfirmedPurchaseOrderFromQuery = InferNodeType<PurchaseOrdersQuery, 'purchaseOrders'>;

export type PurchaseOrderItemConfirmationButFromHook =
  DownConfirmedPurchaseOrderFromQuery['purchaseOrderConfirmations'][number]['purchaseOrderItemConfirmations'][number] & {
    status: string;
    confirmationId: string;
  };

export interface DownConfirmedPurchaseOrder extends DownConfirmedPurchaseOrderFromQuery {
  confirmation: PartialPOConfirmation;
  allConfirmations: PurchaseOrderConfirmation[];
}

interface UseDownConfirmedPOsState {
  orders: DownConfirmedPurchaseOrderFromQuery[];
  loading: boolean;
}

interface UseDownConfirmedPOsProps {
  ids: string[];
  batchSize: number;
}

const batchAndQueryConfirmedOrders = async (
  ids: string[],
  batchSize: number,
  client: ApolloClient<object>
) => {
  const chunks = chunk(ids, batchSize);

  const result = await Promise.all(
    chunks.map(async (ids) => {
      const { data } = await client.query({
        query: PurchaseOrdersDocument,

        variables: {
          first: ids?.length,
          ids: ids
        }
      });
      return getNodesFromEdges(data.purchaseOrders);
    })
  );
  return result.flat();
};

const generateConfirmations = async (
  orders: DownConfirmedPurchaseOrderFromQuery[],
  client: ApolloClient<object>
) => {
  const noConfirmations = orders.filter(
    ({ purchaseOrderConfirmations }) =>
      !purchaseOrderConfirmations.find(
        ({ confirmationType }) => confirmationType === 'DOWN_CONFIRM'
      )
  );

  if (noConfirmations.length > 0) {
    client.mutate({
      mutation: GeneratePurchaseOrderConfirmationsDocument,
      variables: {
        input: {
          purchaseOrderIds: noConfirmations.map(({ id }) => id)
        }
      }
    });
  }
};

export const useDownConfirmedPOsAndGenerateConfirmations = ({
  ids,
  batchSize = 10
}: UseDownConfirmedPOsProps): UseDownConfirmedPOsState & { refetch: () => Promise<void> } => {
  const { notification } = App.useApp();
  const [fetched, setFetched] = useState<boolean>(false);

  const [returnValues, setReturnValues] = useState<UseDownConfirmedPOsState>({
    orders: [],
    loading: false
  });

  const client = useApolloClient();

  // Should run only once on mount
  useEffect(() => {
    if (ids.length === 0) return;
    if (fetched) return;

    setFetched(true);
    setReturnValues({ orders: [], loading: true });

    const getDataAndGenerateConfirmations = async () => {
      try {
        const orders = await batchAndQueryConfirmedOrders(ids, batchSize, client);
        await generateConfirmations(orders, client);
        const updatedOrders = await batchAndQueryConfirmedOrders(ids, batchSize, client);
        setReturnValues({ orders: updatedOrders, loading: false });
      } catch (error) {
        Sentry.captureException(error);
        notification.error({
          message: 'Failed to send',
          description: error.message
        });
      }
    };

    getDataAndGenerateConfirmations();
  }, [ids, batchSize, client, fetched, notification]);

  const refetch = async () => {
    try {
      setReturnValues({ orders: [], loading: true });
      const orders = await batchAndQueryConfirmedOrders(ids, batchSize, client);
      setReturnValues({ orders, loading: false });
    } catch (error) {
      Sentry.captureException(error);
      notification.error({
        message: 'Failed to send',
        description: error.message
      });
      setReturnValues({ orders: [], loading: false });
    }
  };

  return { ...returnValues, refetch };
};
