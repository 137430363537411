import React, { forwardRef } from 'react';
import s from './AlloyInputForEventTracker.module.scss';
import { Input as AntInput, InputProps, InputRef } from 'ant5';
import { TextAreaProps } from 'ant5/es/input';

interface AlloyInputForEventTrackerProps extends InputProps {
  error?: string | boolean;
  label?: string;
}

export const AlloyInputForEventTracker = forwardRef<InputRef, AlloyInputForEventTrackerProps>(
  ({ error, label, ...otherProps }, ref) => {
    const status = error ? 'error' : otherProps.status;

    return (
      <div className={`${s.input} ${error ? s.input_error : ''}`}>
        {!!label && <div className={`${s.label} ${error ? s.error_label : ''}`}>{label}</div>}
        <AntInput {...otherProps} ref={ref} status={status} />
        {!!error && <div className={s.error}>{error}</div>}
      </div>
    );
  }
);

export function AlloyTextArea({ ...otherProps }: TextAreaProps) {
  const { TextArea: AntTextArea } = AntInput;
  return <AntTextArea {...otherProps} />;
}

// Re-exporting types
export type { InputProps, InputRef, TextAreaProps };
