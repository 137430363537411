import { FinalFormMultipleSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormMultipleSelect';
import React from 'react';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { DeliveryDestination } from '../../EditTradingPartnerAssortment';
import s from './ShipToField.module.scss';

interface ShipToFieldProps {
  deliveryDestinations: DeliveryDestination[];
}

export const ShipToField = ({ deliveryDestinations }: ShipToFieldProps) => {
  const form = useForm();

  return (
    <>
      <FinalFormMultipleSelect
        label="Ship-To"
        name="shipTo"
        required
        data-testid="aa-edit-modal-ship-to-select"
        options={[
          {
            value: 'all',
            label: 'All Ship-To'
          },
          ...deliveryDestinations
            .filter((rdd) => !rdd.specialAssortment)
            ?.map(({ id, name, externalId }) => ({ value: id, label: name || externalId || '' }))
        ]}
      />
      <OnChange name="shipTo">
        {(values: string[], previous: string[]) => {
          if (values.includes('all')) {
            const allIds = [
              'all',
              ...deliveryDestinations.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id)
            ];
            if (values.length < previous.length) {
              form.mutators.setShipTos([...values].filter((id) => id !== 'all'));
            } else if (values.length < allIds.length) {
              form.mutators.setShipTos([
                'all',
                ...deliveryDestinations.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id)
              ]);
            }
          }
        }}
      </OnChange>
    </>
  );
};
