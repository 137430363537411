import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BulkUpdateVendorProductDtcInventoryReserveMutationVariables = Types.Exact<{
  input: Types.BulkUpdateVendorProductDtcInventoryReserveInput;
}>;


export type BulkUpdateVendorProductDtcInventoryReserveMutation = { __typename?: 'RootMutationType', bulkUpdateVendorProductDtcInventoryReserve?: { __typename?: 'BulkUpdateVendorProductDtcInventoryReservePayload', ok: boolean } | null };


export const BulkUpdateVendorProductDtcInventoryReserveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"bulkUpdateVendorProductDtcInventoryReserve"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BulkUpdateVendorProductDtcInventoryReserveInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bulkUpdateVendorProductDtcInventoryReserve"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<BulkUpdateVendorProductDtcInventoryReserveMutation, BulkUpdateVendorProductDtcInventoryReserveMutationVariables>;