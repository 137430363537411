import React from 'react';
import clsx from 'clsx';

interface ExpandButtonProps {
  expanded: boolean;
  onClick: (expanded: boolean) => void;
  collapseMessage?: string;
  expandMessage?: string;
}

// It's not the best idea to use internal antd classes, but it's unlikely they change them
export const ExpandButton = ({
  expanded,
  onClick,
  collapseMessage = 'Collapse',
  expandMessage = 'Expand'
}: ExpandButtonProps) => (
  <div style={{ textAlign: 'center' }}>
    <button
      type="button"
      className={clsx(
        'ant-table-row-expand-icon',
        expanded ? 'ant-table-row-expand-icon-expanded' : 'ant-table-row-expand-icon-collapsed'
      )}
      aria-label={expanded ? collapseMessage : expandMessage}
      onClick={() => onClick(expanded)}
    />
  </div>
);
