import { useQuery } from '@apollo/client';
import React from 'react';
import {
  RetailerDeliveryDestinationDocument,
  RetailerDeliveryDestinationQuery
} from '../../gql/__generated__/retailerDeliveryDestination.query';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import { ViewTabs } from 'components/Common/ViewMode/ViewTabs/ViewTabs';
import { GeneralSettingsViewTab } from './components/GeneralSettingsViewTab/GeneralSettingsViewTab';
import { TpSpecificSettingsViewTab } from './components/TpSpecificSettingsViewTab/TpSpecificSettingsViewTab';
import { AllowedDistributionCentersViewTab } from './components/AllowedDistributionCentersViewTab/AllowedDistributionCentersViewTab';

interface ShipToDrawerProps {
  shipToId?: string;
  onClose: () => void;
  startEditing: (shipTo: RetailerDeliveryDestination) => void;
}

export type RetailerDeliveryDestination = NonNullable<
  RetailerDeliveryDestinationQuery['retailerDeliveryDestination']
>;

export type AllowedDistributionCenter = NonNullable<
  NonNullable<RetailerDeliveryDestination['allowedDistributionCenters']>[number]
>;

export const ShipToViewDrawer = ({ onClose, shipToId, startEditing }: ShipToDrawerProps) => {
  const { data: deliveryDestinationData, loading: deliveryDestinationLoading } = useQuery(
    RetailerDeliveryDestinationDocument,
    {
      variables: {
        id: shipToId || ''
      },

      skip: !shipToId
    }
  );

  return (
    <EntityDrawer
      title={`
        ${
          deliveryDestinationData?.retailerDeliveryDestination?.name ||
          deliveryDestinationData?.retailerDeliveryDestination?.externalId ||
          ''
        }${!deliveryDestinationData?.retailerDeliveryDestination?.active ? ' (inactive)' : ''}`}
      open={!!shipToId}
      onClose={onClose}
      viewMode={
        <ViewTabs
          tabs={[
            {
              label: 'General Settings',
              key: 'general',
              children: (
                <GeneralSettingsViewTab
                  shipTo={deliveryDestinationData?.retailerDeliveryDestination}
                />
              )
            },
            {
              label: 'TP-Specific Settings',
              key: 'tp_specific',
              children: (
                <TpSpecificSettingsViewTab
                  shipTo={deliveryDestinationData?.retailerDeliveryDestination}
                />
              )
            },
            {
              label: 'DC',
              key: 'dc',
              children: (
                <AllowedDistributionCentersViewTab
                  shipTo={deliveryDestinationData?.retailerDeliveryDestination}
                />
              )
            }
          ]}
        />
      }
      loading={!deliveryDestinationData?.retailerDeliveryDestination || deliveryDestinationLoading}
      disableEditing={!deliveryDestinationData?.retailerDeliveryDestination?.active}
      startEditing={() =>
        deliveryDestinationData?.retailerDeliveryDestination &&
        startEditing(deliveryDestinationData?.retailerDeliveryDestination)
      }
      withTabsContent
    />
  );
};
