import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GeographicDistributionMetadataQueryVariables = Types.Exact<{
  distributionCenterId: Types.Scalars['Int'];
  purchaseOrderNumbers: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type GeographicDistributionMetadataQuery = { __typename?: 'RootQueryType', geographicDistributionMetadata: Array<{ __typename?: 'PurchaseOrderMetadata', cases: number, combinedWeight: number, distributionCenterId: number, distributionCenterName: string, miles: number, products: number, purchaseOrderNumber: string, retailerDeliveryDestination?: string | null }> };


export const GeographicDistributionMetadataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"geographicDistributionMetadata"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"distributionCenterId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderNumbers"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"geographicDistributionMetadata"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"distributionCenterId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"distributionCenterId"}}},{"kind":"Argument","name":{"kind":"Name","value":"purchaseOrderNumbers"},"value":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderNumbers"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cases"}},{"kind":"Field","name":{"kind":"Name","value":"combinedWeight"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterId"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterName"}},{"kind":"Field","name":{"kind":"Name","value":"miles"}},{"kind":"Field","name":{"kind":"Name","value":"products"}},{"kind":"Field","name":{"kind":"Name","value":"purchaseOrderNumber"}},{"kind":"Field","name":{"kind":"Name","value":"retailerDeliveryDestination"}}]}}]}}]} as unknown as DocumentNode<GeographicDistributionMetadataQuery, GeographicDistributionMetadataQueryVariables>;