import React from 'react';
import s from './ViewTable.module.scss';
import clsx from 'clsx';
import { get } from 'lodash-es';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

interface Props<ObjectType> {
  data: ObjectType[];
  columns: {
    title: string;
    dataIndex: string;
    withTooltip: boolean;
    classname?: string;
  }[];
  rowClassName?: (record?: ObjectType) => string;
  lastRowBottomBorder?: boolean;
  size?: 'small' | 'medium';
}

export const ViewTable = <ObjectType,>({
  data,
  columns,
  rowClassName,
  lastRowBottomBorder = true,
  size = 'medium'
}: Props<ObjectType>) => {
  return (
    <>
      <div className={s.row}>
        {columns.map((col) => (
          <div
            key={col.dataIndex}
            className={clsx(s.header, s[`size_${size}`], {
              [col.classname || '']: !!col.classname,
              [s.default_column_style]: !col.classname
            })}
          >
            {col.title}
          </div>
        ))}
      </div>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <div className={clsx(s.row, s.margin_row, s[`size_${size}`])}>
            {columns.map((col) => (
              <div
                key={col.dataIndex}
                className={clsx(s.content, {
                  [col.classname || '']: !!col.classname,
                  [s.default_column_style]: !col.classname,
                  [rowClassName ? rowClassName(item) : '']: !!rowClassName
                })}
              >
                {col.withTooltip ? (
                  <AlloyTooltip
                    placement="topLeft"
                    title={get(item, col.dataIndex)}
                    autoAdjustOverflow
                  >
                    <div className={s.overflowed}>{get(item, col.dataIndex)}</div>
                  </AlloyTooltip>
                ) : (
                  get(item, col.dataIndex)
                )}
              </div>
            ))}
          </div>
          {(index !== data.length - 1 || lastRowBottomBorder) && size !== 'small' && (
            <div className={s.divider} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
