import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChargebackReportsByPeriodQueryVariables = Types.Exact<{
  dimension: Types.ChargebackDimension;
  filters: Types.ExecutiveReportingFilters;
}>;


export type ChargebackReportsByPeriodQuery = { __typename?: 'RootQueryType', chargebackReportsByPeriod: Array<{ __typename?: 'ChargebackReportByPeriod', dimensionValue?: string | null, financialCharge: any, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', week: number, year: number } }> };


export const ChargebackReportsByPeriodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"chargebackReportsByPeriod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dimension"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChargebackDimension"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargebackReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dimension"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dimension"}}},{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dimensionValue"}},{"kind":"Field","name":{"kind":"Name","value":"financialCharge"}},{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}}]}}]}}]} as unknown as DocumentNode<ChargebackReportsByPeriodQuery, ChargebackReportsByPeriodQueryVariables>;