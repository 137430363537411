import { SearchOutlined } from '@ant-design/icons';
import { Input, Spin } from 'ant5';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useState } from 'react';
import s from './SearchField.module.scss';

export const SearchField = ({
  handleSearch,
  loading,
  width,
  saveToHistory = true,
  placeholder,
  className,
  value
}: {
  handleSearch: (value: string) => void;
  loading: boolean;
  width?: number | string;
  saveToHistory?: boolean;
  placeholder?: string;
  className?: string;
  value: string;
}) => {
  const [searchFieldValue, setSearchFieldValue] = useState(saveToHistory ? value || '' : '');

  useEffect(() => {
    setSearchFieldValue(value);
  }, [value]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        handleSearch(searchFieldValue.trim());
      },
      searchFieldValue.length > 0 ? 700 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [searchFieldValue, handleSearch]);

  return (
    <Spin className={s.search_field} spinning={loading}>
      <Input
        data-testid="search-field"
        className={clsx(s.search_field, className)}
        style={
          width ? (typeof width === 'string' ? { width } : { width: `${width}px` }) : undefined
        }
        prefix={<SearchOutlined className={s.search_icon} />}
        placeholder={placeholder ?? 'Search'}
        value={searchFieldValue}
        allowClear
        onChange={(event) => setSearchFieldValue(event.currentTarget.value)}
        onPressEnter={() => {
          handleSearch(searchFieldValue.trim());
        }}
      />
    </Spin>
  );
};
