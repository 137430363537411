import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import s from './BatchSwapForm.module.scss';
import clsx from 'clsx';
import { Action, findMaxAmountWithAction } from './helpers';
import { Button, message } from 'antd';
import { ReconcileBatchDetailsMetric } from '../types';

interface BatchSwap {
  firstAction: Action;
  secondAction: Action;
  targetBatchId: string;
  amount: number;
}

const SWAP_OPTIONS: { key: String; label: string; value: Action }[] = [
  {
    key: 'Add',
    value: 'Add',
    label: 'Add'
  },
  {
    key: 'Remove',
    value: 'Remove',
    label: 'Remove'
  }
];

export const BatchSwapForm = ({
  initialBatch,
  batches,
  onCancel
}: {
  initialBatch: ReconcileBatchDetailsMetric | undefined;
  batches: ReconcileBatchDetailsMetric[];
  onCancel: () => void;
}) => {
  if (!initialBatch) return <div>No initial batch. Try again.</div>;
  return (
    <div className={s.form}>
      <Form<BatchSwap>
        initialValues={{
          firstAction: initialBatch.openVariance > 0 ? 'Remove' : 'Add',
          secondAction: initialBatch.openVariance > 0 ? 'Add' : 'Remove',
          amount: 0
        }}
        onSubmit={(_values) => message.success('Swapped batches')}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={s.formRow}>
              <div className={clsx(s.select, s.initialBatch)}>
                <div className={s.tooltip}>Initial Batch</div>
                <div
                  className={s.name}
                >{`${initialBatch.batchNumber} (${initialBatch.openVariance})`}</div>
              </div>
              <div className={s.action}>
                <FinalFormSelect title="" name="firstAction" options={SWAP_OPTIONS} />
              </div>
              <div className={s.amountInput}>
                <FinalFormInput name="amount" title="Amount" type="number" min={0} />
              </div>
            </div>
            <div className={s.formRow}>
              <div className={s.select}>
                <FinalFormSelect
                  title="Target Batch"
                  name="targetBatchId"
                  options={batches
                    .filter((x) => x.batchNumber !== initialBatch.batchNumber)
                    .map(({ batchNumber, openVariance }) => ({
                      key: batchNumber,
                      value: batchNumber,
                      label: `${batchNumber} (${openVariance})`
                    }))}
                />
              </div>
              <div className={s.action}>
                <FinalFormSelect title="" name="secondAction" options={SWAP_OPTIONS} />
              </div>
              <div className={s.amountInput}>
                <FinalFormInput name="amount" title="Amount" type="number" min={0} />
              </div>
            </div>
            <div className={s.buttons}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit" disabled={!values.targetBatchId}>
                Confirm
              </Button>
            </div>
            <OnChange name="targetBatchId">
              {(value) => {
                form.change(
                  'amount',
                  findMaxAmountWithAction(
                    initialBatch.batchNumber,
                    value,
                    batches,
                    form.getFieldState('firstAction')?.value as Action
                  )
                );
              }}
            </OnChange>
            <OnChange name="firstAction">
              {(value, prevValue) => {
                form.change('secondAction', prevValue);
                form.change(
                  'amount',
                  findMaxAmountWithAction(
                    initialBatch.batchNumber,
                    form.getFieldState('targetBatchId')?.value,
                    batches,
                    value
                  )
                );
              }}
            </OnChange>
            <OnChange name="secondAction">
              {(_value, prevValue) => {
                form.change('firstAction', prevValue);
              }}
            </OnChange>
          </form>
        )}
      />
    </div>
  );
};
