import React, { useMemo } from 'react';
import s from './CuttingModal.module.scss';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { FieldArray } from 'react-final-form-arrays';
import { Form } from 'react-final-form';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import arrayMutators from 'final-form-arrays';
import { useWindowSize } from '@reactuses/core';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import { FulfillItem } from '../FulfillTable/FulfillTable';
import { useMutation } from '@apollo/client';
import { MoveRepackFulfillToCutsDocument } from './gql/__generated__/moveRepackFulfillToCuts';
import { App } from 'ant5';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { OnChange } from 'react-final-form-listeners';
import { parseToInt } from 'pages/RepackPlanning/helpers';
import { RepackOptimizerStage } from 'graphql/__generated__/types';

type Plant = 'Memphis' | 'Grand Prairie' | 'Hamburg';

interface CuttingForm {
  changes: Change[];
  reason?: string;
  comment?: string | null;
  plant?: Plant;
}

interface Change {
  title: string;
  itemId: string;
  id: string;
  quantity: number;
  max: number;
}

export const CuttingModal = ({
  items,
  isModalVisible,
  setIsModalVisible,
  resetSelection,
  stage
}: {
  items: FulfillItem[];
  isModalVisible: boolean;
  setIsModalVisible: (shown: boolean) => void;
  resetSelection: () => void;
  stage: RepackOptimizerStage;
}) => {
  const { message } = App.useApp();

  const [mutate, { loading }] = useMutation(MoveRepackFulfillToCutsDocument, {
    onCompleted: () => {
      message.success(`Successfully moved items to cuts list`);
      resetSelection();
      setIsModalVisible(false);
    },
    onError: (error) => {
      message.error(`Could not move items to cuts list: ${error}`);
    },
    refetchQueries: [
      'inventoryRepackFulfillReport',
      'inventoryRepackCutsReport',
      'inventoryRepackWeeklyTotalsReport'
    ]
  });

  const { height } = useWindowSize();

  // Some magic numbers
  const tableHeight = Math.max(height - 80 - 70 - 190 - 140 - 75, 100);

  const initialValues: CuttingForm = useMemo(
    () => ({
      reason: undefined,
      comment: null,
      plant: undefined,
      changes: (items || []).map((x) => ({
        title: x.productTitle || '',
        id: x.id,
        itemId: x.itemId,
        quantity: 0,
        max: Number.MAX_SAFE_INTEGER
      }))
    }),
    [items]
  );

  const onSubmit = async (values: CuttingForm) => {
    await mutate({
      variables: {
        input: {
          reason: values.reason || '',
          // There's a bug on BE, we need to send null
          comment: values.comment || null,
          changes: values.changes
            .map((change) => ({
              howMany: parseToInt(change.quantity),
              id: change.id,
              plant: values.plant || ''
            }))
            .filter((x) => x.howMany),
          stage
        }
      }
    });
  };

  const columns: ColumnsType<Change> = [
    {
      title: 'Item ID',
      render: (_, { itemId }) => <span className={s.number}>{itemId}</span>,
      width: 90
    },
    {
      title: 'Product Title',
      dataIndex: 'title',
      ellipsis: true,
      sorter: (a, b) => safeLocaleCompare(a.title, b.title)
    },
    {
      title: 'QTY',
      render: (_, _record, index) => (
        <FinalFormInput
          name={`changes[${index}].quantity`}
          type="number"
          min={0}
          hideValidation
          validate={(value, allValues) => {
            const parsedValue = parseFloat(value);

            // Usual required doesn't work because it breaks on 0
            if (value === '' || value === null || value === undefined) {
              return 'Should not be empty';
            }

            if (!Number.isInteger(parsedValue)) {
              return "Can't be fractional";
            }

            if (parsedValue && parsedValue < 0) {
              return `Can't be less than 0 `;
            }

            // It's the best way to access it, since it ensures that value is present at that moment
            const max = (allValues as CuttingForm).changes[index].max;
            if (parsedValue > (max || 0)) {
              return `Can't exceed ${max}`;
            }

            return undefined;
          }}
        />
      ),
      width: 160
    }
  ];

  return (
    <Form<CuttingForm>
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, form }) => (
        <AlloyModal
          open={isModalVisible}
          title="Cutting Confirmation"
          afterOpenChange={(visible) => {
            // Doing it only on "visible" to avoid visual glitch
            if (visible) {
              form.restart();
            }
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          okText="Cut"
          okButtonProps={{
            onClick: () => handleSubmit(),
            disabled: form.getState().invalid || loading
          }}
          width={530}
        >
          <AlloySpin spinning={loading} className={s.window}>
            <p className={s.text}>Do you want to move this qty. to cut list?</p>
            <form onSubmit={handleSubmit}>
              <OnChange name="plant">
                {(value: Plant) => {
                  if (value) {
                    const fulfillQtyMapping = {
                      'Grand Prairie': 'gpFulfillQty',
                      Memphis: 'memphisFulfillQty',
                      Hamburg: 'hamburgFulfillQty'
                    } as const;

                    const fieldToChange = fulfillQtyMapping[value] || '';
                    form.change(
                      'changes',
                      (form.getFieldState('changes')?.value || []).map((x, idx) => ({
                        ...x,
                        quantity: items[idx][fieldToChange] || 0,
                        max: items[idx][fieldToChange] || 0
                      }))
                    );
                  }
                }}
              </OnChange>
              <FinalFormSelect
                selectProps={{ placeholder: 'Select Option' }}
                name="plant"
                title="Select a plant"
                required
                options={[
                  { label: 'Memphis', value: 'Memphis' },
                  { label: 'Grand Prairie', value: 'Grand Prairie' },
                  { label: 'Hamburg', value: 'Hamburg' }
                ]}
              />
              <FieldArray name="changes">
                {({ fields }) => (
                  <>
                    <AlloyTable
                      className={s.table}
                      dataSource={fields.value}
                      columns={columns}
                      rowKey="id"
                      pagination={false}
                      bordered={false}
                      size="small"
                      scroll={{ y: tableHeight }}
                    />
                  </>
                )}
              </FieldArray>
              <FinalFormSelect
                selectProps={{ placeholder: 'Select Option' }}
                name="reason"
                title="Reason"
                required
                options={[
                  { label: 'Components Unavailable', value: 'Components Unavailable' },
                  { label: 'Down-confirm per Sales', value: 'Down-confirm per Sales' },
                  { label: 'Excess Customer WOS', value: 'Excess Customer WOS' },
                  { label: 'Invalid Product', value: 'Invalid Product' },
                  { label: 'Repack Delays', value: 'Repack Delays' }
                ]}
              />
              {/* TODO: use src/components/ui/formFields/AlloyFormField/AlloyFormField.tsx */}
              <FinalFormInput name="comment" title="Add a comment (optional)" textarea />
            </form>
          </AlloySpin>
        </AlloyModal>
      )}
    />
  );
};
