import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { ApolloClient, useQuery } from '@apollo/client';
import { Button, Checkbox, Modal, Spin, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import clsx from 'clsx';
import { EMPTY } from 'common/constants';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { notEmpty } from 'common/helpers/notEmpty';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import {
  RateCellWithTargetCompact,
  onCellRateWithTargetCompact
} from 'components/RateCellWithTargetCompact/RateCellWithTarget';
import { ProductsFillRateFilters } from 'graphql/__generated__/types';
import { groupBy } from 'lodash-es';
import moment from 'moment';
import { MultipleOrdersByProductFillRateReportDocument } from 'pages/Visibility/gql/__generated__/multipleOrdersByProductFillRateReport.query';
import { ProductsFillRateReportDocument } from 'pages/Visibility/gql/__generated__/productsFillRateReport';
import { ProductsFiltersDocument } from 'pages/Visibility/gql/__generated__/productsFilters.query';
import {
  ColumnsWithExportRender,
  prepareRecordsForExport,
  prepareSingleRecordForExport,
  removeExportConfig
} from 'common/helpers/tableExport';
import { CSVLink } from 'react-csv';
import {
  useQueryParam,
  withDefault,
  JsonParam,
  BooleanParam,
  DelimitedArrayParam
} from 'use-query-params';
import { Filter, TableFilters } from 'components/TableFilters/TableFilters';
import { poColumnsWithCsv, ProductDetailsTable, sapColumnsWithCsv } from './ProductDetailsTable';
import s from './ProductsViewTable.module.scss';
import { safeNumberComparator } from 'common/helpers/comparators';
import { ProductsStats } from '../ProductsStats/ProductsStats';
import { PrimeDayProduct, mapOrderDetailsToOrderDetailsEnhanced } from './mapper';
import { ProductViewContext } from './ProductViewContext';
import { useUrlParams } from 'common/helpers/urlParams';
import { SortOrder } from 'antd/lib/table/interface';

const FILTERS_QUERY_PARAM_STRING = 'productFilters';
type FilterKeys = 'dcIds' | 'rddIds' | 'tpIds';

const getKey = (record: PrimeDayProduct): string =>
  `${record?.productId || ''}-${record?.productExternalId || ''}`;

interface SortData {
  column: string;
  direction: string;
}

export const ProductsViewTable = ({
  filters,
  selectedRows,
  setSelectedRows,
  exportModalVisible,
  setExportModalVisible,
  resetSelectedRows
}: {
  filters: ProductsFillRateFilters;
  selectedRows: PrimeDayProduct[];
  setSelectedRows: React.Dispatch<React.SetStateAction<PrimeDayProduct[]>>;
  exportModalVisible: boolean;
  setExportModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelectedRows: () => void;
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const tableElement = useRef<HTMLElement>();
  const viewContext = useContext(ProductViewContext);
  const [urlParams, setUrlParams] = useUrlParams();
  const [sortedInfo, setSortedInfo] = useState<SortData>({
    column: (urlParams.column as string) ?? 'netVariance',
    direction: (urlParams.direction as string) ?? 'descend'
  });

  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(DelimitedArrayParam, []));

  const [onlyPrimeDayAsins, setOnlyPrimeDayAsins] = useQueryParam(
    'onlyPrimeDayAsins',
    withDefault(BooleanParam, false)
  );

  const [tableFiltersFromQuery] = useQueryParam<Record<FilterKeys, string[]>>(
    FILTERS_QUERY_PARAM_STRING,
    withDefault(JsonParam, {})
  );

  const productColumnsWithCsv: ColumnsWithExportRender<PrimeDayProduct> = [
    {
      title: 'GTIN',
      key: 'gtin',
      width: 140,
      render: (_, { upc }) => upc || EMPTY,
      exportConfig: {
        render: ({ upc }) => upc || EMPTY,
        modes: ['NO_TOTALS']
      },
      sorter: (a, b) => (a?.upc || '').localeCompare(b?.upc || ''),
      sortOrder: sortedInfo.column === 'gtin' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Sap Material ID',
      key: 'sapMaterialId',
      width: 160,
      render: (_, { sapMaterialId }) => sapMaterialId || EMPTY,
      exportConfig: {
        render: ({ sapMaterialId }) => sapMaterialId || EMPTY,
        modes: ['NO_TOTALS']
      },
      sorter: (a, b) => (a?.sapMaterialId || '').localeCompare(b?.sapMaterialId || ''),
      sortOrder: sortedInfo.column === 'sapMaterialId' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Customer Product ID',
      key: 'asin',
      width: 150,
      render: (_, { productExternalId }) => productExternalId || EMPTY,
      exportConfig: {
        render: ({ productExternalId }) => productExternalId || EMPTY,
        modes: ['NO_TOTALS']
      },
      sorter: (a, b) => (a?.productExternalId || '').localeCompare(b?.productExternalId || ''),
      sortOrder: sortedInfo.column === 'asin' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Description',
      key: 'description',
      width: 300,
      render: (_, { productDescription }) => (
        <span className={s.description}>{productDescription || EMPTY}</span>
      ),
      exportConfig: {
        render: ({ productDescription }) => productDescription || EMPTY,
        modes: ['NO_TOTALS']
      },
      sorter: (a, b) =>
        (a?.productDescription?.trim() || '').localeCompare(b?.productDescription?.trim() || ''),
      sortOrder: sortedInfo.column === 'description' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Submitted Fill Rate',
      width: 80,
      showSorterTooltip: {
        title: 'Ship Qty / Customer Original Qty',
        placement: 'bottom'
      },
      key: 'sfr',
      align: 'right',
      render: (_, { submittedFillRate }) =>
        submittedFillRate === null ? (
          EMPTY
        ) : (
          <RateCellWithTargetCompact value={submittedFillRate} target={100} />
        ),
      exportConfig: {
        title: 'Total Submitted Fill Rate',
        render: ({ submittedFillRate }) => submittedFillRate ?? EMPTY
      },
      onCell: ({ submittedFillRate }) =>
        submittedFillRate === null ? {} : onCellRateWithTargetCompact(submittedFillRate, 100),
      sorter: (a, b) => safeNumberComparator(a.submittedFillRate, b.submittedFillRate),
      sortOrder: sortedInfo.column === 'sfr' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Accepted Fill Rate',
      width: 80,
      key: 'afr',
      align: 'right',
      showSorterTooltip: {
        title: 'Ship Qty / Customer Accepted Qty',
        placement: 'bottom'
      },
      render: (_, { acceptedFillRate }) =>
        acceptedFillRate === null ? (
          EMPTY
        ) : (
          <RateCellWithTargetCompact value={acceptedFillRate} target={100} />
        ),
      exportConfig: {
        title: 'Total Accepted Fill Rate',
        render: ({ acceptedFillRate }) => acceptedFillRate ?? EMPTY
      },
      onCell: ({ acceptedFillRate }) =>
        acceptedFillRate === null ? {} : onCellRateWithTargetCompact(acceptedFillRate, 100),
      sorter: (a, b) => safeNumberComparator(a.acceptedFillRate, b.acceptedFillRate),
      sortOrder: sortedInfo.column === 'afr' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Total Customer Original Qty',
      width: 100,
      align: 'right',
      key: 'totalCustomerOriginalQuantity',
      render: (_, { totalCustomerOriginalQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>
          {totalCustomerOriginalQuantity === null
            ? EMPTY
            : (totalCustomerOriginalQuantity || 0).toLocaleString()}
        </span>
      ),
      exportConfig: {
        render: ({ totalCustomerOriginalQuantity }) => totalCustomerOriginalQuantity ?? EMPTY
      },
      sorter: (a, b) =>
        safeNumberComparator(a.totalCustomerOriginalQuantity, b.totalCustomerOriginalQuantity),
      sortOrder:
        sortedInfo.column === 'totalCustomerOriginalQuantity'
          ? (sortedInfo.direction as SortOrder)
          : null
    },
    {
      title: 'Total Customer Accepted Qty',
      width: 105,
      align: 'right',
      key: 'totalCustomerAcceptedQuantity',
      render: (_, { totalCustomerAcceptedQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>
          {totalCustomerAcceptedQuantity === null
            ? EMPTY
            : (totalCustomerAcceptedQuantity || 0).toLocaleString()}
        </span>
      ),
      exportConfig: {
        render: ({ totalCustomerAcceptedQuantity }) => totalCustomerAcceptedQuantity ?? EMPTY
      },
      sorter: (a, b) =>
        safeNumberComparator(a.totalCustomerAcceptedQuantity, b.totalCustomerAcceptedQuantity),
      sortOrder:
        sortedInfo.column === 'totalCustomerAcceptedQuantity'
          ? (sortedInfo.direction as SortOrder)
          : null
    },
    {
      title: 'Total SAP Accepted QTY',
      width: 100,
      align: 'right',
      key: 'submittedQuantity',
      render: (_, { totalQuantity }) => (
        <span style={{ fontWeight: 'bold' }}>
          {totalQuantity === null ? EMPTY : (totalQuantity || 0).toLocaleString()}
        </span>
      ),
      exportConfig: {
        render: ({ totalQuantity }) => totalQuantity ?? EMPTY
      },
      sorter: (a, b) => safeNumberComparator(a.totalQuantity, b.totalQuantity),
      sortOrder:
        sortedInfo.column === 'submittedQuantity' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Total Shipped QTY',
      width: 100,
      align: 'right',
      key: 'totalShippedQty',
      render: (_, { deliveredQuantity }) => {
        return (
          <span style={{ fontWeight: 'bold' }}>
            {deliveredQuantity === null || undefined
              ? EMPTY
              : (deliveredQuantity || 0).toLocaleString()}
          </span>
        );
      },
      exportConfig: {
        render: ({ deliveredQuantity }) => {
          return deliveredQuantity ?? EMPTY;
        }
      },
      sorter: (a, b) => safeNumberComparator(a.deliveredQuantity, b.deliveredQuantity),
      sortOrder:
        sortedInfo.column === 'totalShippedQty' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Total Remaining To Ship',
      width: 100,
      align: 'right',
      key: 'totalRemainingToShip',
      showSorterTooltip: {
        title: 'Zero if Ship Qty exists otherwise SAP Accepted Qty',
        placement: 'bottom'
      },
      render: (_, { totalRemainingToShip }) => (
        <span style={{ fontWeight: 'bold' }}>
          {totalRemainingToShip === null ? EMPTY : (totalRemainingToShip || 0).toLocaleString()}
        </span>
      ),
      exportConfig: {
        render: ({ totalRemainingToShip }) => totalRemainingToShip ?? EMPTY
      },
      sorter: (a, b) => safeNumberComparator(a.totalRemainingToShip, b.totalRemainingToShip),
      sortOrder:
        sortedInfo.column === 'totalRemainingToShip' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Order Qty Variance (CS)',
      width: 105,
      align: 'right',
      showSorterTooltip: {
        title: 'Sum of Net Variance',
        placement: 'bottom'
      },
      key: 'netVariance',
      exportConfig: {
        render: ({ orderQuantityVariance }) => orderQuantityVariance ?? EMPTY
      },
      render: (_, { orderQuantityVariance }) => (
        <span style={{ fontWeight: 'bold' }}>
          {orderQuantityVariance === null ? EMPTY : (orderQuantityVariance || 0).toLocaleString()}
        </span>
      ),
      sorter: (a, b) => safeNumberComparator(a.orderQuantityVariance, b.orderQuantityVariance),
      sortOrder: sortedInfo.column === 'netVariance' ? (sortedInfo.direction as SortOrder) : null,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Variance Impact %',
      width: 80,
      align: 'right',
      showSorterTooltip: {
        title: 'Order Qty Variance / Total Order Qty',
        placement: 'bottom'
      },
      key: 'variance',
      render: (_, { varianceImpact }) =>
        varianceImpact === null ? (
          EMPTY
        ) : (
          <RateCellWithTargetCompact value={varianceImpact} moreIsWorse absoluteValue />
        ),
      exportConfig: {
        render: ({ varianceImpact }) => varianceImpact ?? EMPTY
      },
      onCell: ({ varianceImpact }) =>
        varianceImpact === null
          ? {}
          : onCellRateWithTargetCompact(Math.abs(varianceImpact), 100, true),
      sorter: (a, b) => safeNumberComparator(a.varianceImpact, b.varianceImpact),
      sortOrder: sortedInfo.column === 'variance' ? (sortedInfo.direction as SortOrder) : null
    },
    {
      title: 'Window End Alert',
      width: 85,
      align: 'right',
      key: 'windowEndAlert',
      exportConfig: {
        title: 'Window End Alert Total',
        render: ({ windowEndAlert }) => windowEndAlert ?? EMPTY
      },
      render: (_, { windowEndAlert }) => windowEndAlert ?? EMPTY,
      onCell: ({ windowEndAlert }) =>
        (windowEndAlert || 0) > 0 ? { style: { backgroundColor: '#E13C42' } } : {},
      sorter: (a, b) => safeNumberComparator(a.windowEndAlert, b.windowEndAlert),
      sortOrder: sortedInfo.column === 'windowEndAlert' ? (sortedInfo.direction as SortOrder) : null
    }
  ];

  const productColumns: ColumnsType<PrimeDayProduct> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    ...removeExportConfig(productColumnsWithCsv)
  ];

  const prepareProductsWithDetailsForExport = async (
    client: ApolloClient<object>,
    products: PrimeDayProduct[],
    filters: ProductsFillRateFilters
  ): Promise<{ [x: string]: string | number | undefined }[]> => {
    const productIds = products.map((x) => x.productId).filter(notEmpty);

    const orders = await client.query({
      query: MultipleOrdersByProductFillRateReportDocument,
      variables: {
        filters,
        productIds
      }
    });

    const ordersByProductId = groupBy(
      orders.data.ordersByProductFillRateReport?.metrics,
      'productId'
    );

    const result: Record<string, string | number | undefined>[] = [];
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const productCsv = prepareSingleRecordForExport(product, productColumnsWithCsv);
      const productCsvNoTotals = prepareSingleRecordForExport(
        product,
        productColumnsWithCsv,
        'NO_TOTALS'
      );
      const pos = ordersByProductId[product.productId || ''] || [];

      // If there are no POs, we want to push part of product anyway + we push empty columns also, just in case.
      if (pos.length === 0) {
        result.push({
          ...productCsv,
          ...prepareSingleRecordForExport(undefined, poColumnsWithCsv),
          ...prepareSingleRecordForExport(undefined, sapColumnsWithCsv)
        });
      }

      for (let j = 0; j < pos.length; j++) {
        const isFirstPo = j === 0;

        const po = pos[j];
        const poCsv = prepareSingleRecordForExport(po, poColumnsWithCsv);
        const poCsvNoTotals = prepareSingleRecordForExport(po, poColumnsWithCsv, 'NO_TOTALS');
        const orders = po.orders || [];

        // If there are no orders inside of PO, we want to push part of product + po anyway,  + we push empty columns also, just in case.
        if (orders.length === 0) {
          // If it's not our first PO of a product, don't import totals.
          const productCsvToAppend = isFirstPo ? productCsv : productCsvNoTotals;
          result.push({
            ...productCsvToAppend,
            ...poCsv,
            ...prepareSingleRecordForExport(undefined, sapColumnsWithCsv)
          });
        }

        for (let k = 0; k < orders.length; k++) {
          const isFirstOrder = k === 0;

          const productCsvToAppend = isFirstPo && isFirstOrder ? productCsv : productCsvNoTotals;
          const poCsvToAppend = isFirstOrder ? poCsv : poCsvNoTotals;

          const order = orders[k];
          const orderCsv = prepareSingleRecordForExport(
            mapOrderDetailsToOrderDetailsEnhanced(order, po),
            sapColumnsWithCsv
          );
          result.push({ ...productCsvToAppend, ...poCsvToAppend, ...orderCsv });
        }
      }
    }
    return result;
  };

  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  const combinedFilters: ProductsFillRateFilters = {
    ...filters,
    smartSearch: nonEmptySearch,
    distributionCenterIds: tableFiltersFromQuery?.dcIds || [],
    rddIds: tableFiltersFromQuery?.rddIds || [],
    tradingPartnerIds: tableFiltersFromQuery?.tpIds || [],
    onlyPrimeDayAsins
  };

  const { data, loading, error, client } = useQuery(ProductsFillRateReportDocument, {
    variables: {
      filters: combinedFilters
    }
  });

  useEffect(() => {
    setExpandedRows([]);
  }, [data]);

  const productFilters = useQuery(ProductsFiltersDocument);

  const tableFilters = useMemo(() => {
    const dcs = getNodesFromEdges(productFilters.data?.distributionCenters).map((x) => ({
      name: `${x.name} (${x.code})`,
      value: x.id
    }));
    const tps = getNodesFromEdges(productFilters.data?.tradingPartners).map((x) => ({
      name: x.name || x.id,
      value: x.id
    }));
    const rdds = getNodesFromEdges(productFilters.data?.retailerDeliveryDestinations).map((x) => ({
      name: x.name || x.id,
      value: x.id
    }));
    const tableFiltersRaw: Filter<FilterKeys>[] = [
      {
        name: 'Ship From',
        type: 'multiple',
        field: 'dcIds',
        options: dcs
      },
      {
        name: 'Ship-To',
        type: 'multiple',
        field: 'rddIds',
        options: rdds
      },
      {
        name: 'Vendor Code',
        type: 'multiple',
        field: 'tpIds',
        options: tps
      }
    ];
    return tableFiltersRaw.sort((a, b) => a.name.localeCompare(b.name));
  }, [productFilters.data]);

  const metrics = data?.productsFillRateReport?.metrics || [];
  const INITIAL_PAGINATION = 50;
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION);
  const [page, setPage] = useState(1);
  const isSinglePage = metrics.length <= pageSize;
  const isPaginationHidden = metrics.length <= INITIAL_PAGINATION;

  // Reset page after filters/mode change
  useEffect(() => {
    setPage(1);
  }, [filters]);

  // I don't like to write it manually, I wish Apollo could receive any async function like React Query
  // https://github.com/react-csv/react-csv/issues/350#issuecomment-1291003571 we need to do this ref magic till it's fixed
  const [exportWithDetails, setExportWithDetails] = useState(false);
  const csvWithDetailsLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(
    null
  );
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [downloadTimestamp, setDownloadTimestamp] = useState(moment());
  const [csvAsyncWithDetails, setCsvAsyncWithDetails] = useState<{
    loading: boolean;
    error: boolean;
    data: { [x: string]: string | number | undefined }[];
  }>({ loading: false, data: [], error: false });
  const onDownloadWithDetailsClick = async (orders: PrimeDayProduct[]) => {
    try {
      setCsvAsyncWithDetails({ loading: true, error: false, data: [] });
      const productsWithDetails = await prepareProductsWithDetailsForExport(
        client,
        orders,
        combinedFilters
      );
      setCsvAsyncWithDetails({
        loading: false,
        error: false,
        data: productsWithDetails
      });
      setDownloadTimestamp(moment());
      resetSelectedRows();
      csvWithDetailsLink.current?.link.click();
      setExportModalVisible(false);
    } catch {
      setCsvAsyncWithDetails({ loading: false, error: true, data: [] });
    }
  };

  const onDownloadClick = () => {
    setDownloadTimestamp(moment());
    csvLink.current?.link.click();
    resetSelectedRows();
    setExportModalVisible(false);
  };

  const tableRendered = (node: HTMLDivElement) => {
    if (node) {
      viewContext.primaryTable.current = node;
      tableElement.current = node;
      const tableBody = node.querySelector('.ant-table-body') as HTMLElement;
      viewContext.primaryTable.current = tableBody;

      //This scroll event listener is used to keep the expanded row product table within the view while the primary table is scrolled horizontally
      tableBody?.addEventListener('scroll', (e: any) => {
        if (e.target) {
          viewContext.primaryTable.current = e.target;
          viewContext.evaluateSubscriberPosition();
        }
      });
    }
  };

  const setHorizontalScroll = () => {
    if (!tableElement.current) return;

    const allScrolls = document.body.querySelectorAll('.ant-table-sticky-scroll');
    if (!allScrolls.length) return;

    //it appears to find the primary scroll last. I assume because it traverses each child before siblings. so we want the last element in tha array
    const parentScroll = allScrolls[allScrolls.length - 1] as HTMLElement;
    const bottomElements = document.elementsFromPoint(
      document.body.clientWidth / 2,
      document.body.clientHeight - 5
    );

    const childTable = bottomElements.find((element) => {
      let found = false;
      for (let i = 0; i < element.classList.length; i++) {
        if (element.classList[i].includes('details_table_wrapper')) {
          found = true;
          break;
        }
        if (found) break;
      }
      return found;
    });

    const childHeader = bottomElements.find((element) => {
      let found = false;
      for (let i = 0; i < element.classList.length; i++) {
        if (
          element.classList[i].includes('ant-table-header') ||
          element.classList[i].includes('spacer')
        ) {
          found = true;
          break;
        }
        if (found) break;
      }
      return found;
    });

    //set childScroll to fixed
    if (childTable && parentScroll && !childHeader) {
      parentScroll.style.display = 'none';
      const childScroll = childTable.querySelector('.ant-table-sticky-scroll') as HTMLElement;
      if (childScroll && childScroll.style.position !== 'fixed') {
        childScroll.style.position = 'fixed';
        if (viewContext.primaryTable.current) {
          childScroll.style.left = `${
            viewContext.primaryTable.current.getBoundingClientRect().left
          }px`;
        }
      }
    } else if (childTable) {
      parentScroll.style.display = 'flex';
      const childScroll = childTable.querySelector('.ant-table-sticky-scroll') as HTMLElement;
      if (childScroll && childScroll.style.position === 'fixed') {
        childScroll.style.display = 'none';
      }
    } else {
      parentScroll.style.display = 'flex';
    }
  };

  //when the page is scrolled, this will decide which horizontal scroll should be displayed. The primary table vs expanded row tables
  useEffect(() => {
    window.addEventListener('scroll', setHorizontalScroll);
    return () => window.removeEventListener('scroll', setHorizontalScroll);
  }, []);

  return (
    <div>
      <Modal
        title="Export view"
        open={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        width={400}
        destroyOnClose
        footer={
          <>
            <div>
              <Button
                className={clsx('filled_grey_btn_no_border', s.bold)}
                size="large"
                onClick={() => setExportModalVisible(false)}
                data-testid="product-visibility-export-modal-close-button"
              >
                Cancel
              </Button>
              {exportWithDetails ? (
                <Button
                  className={clsx(s.bold)}
                  size="large"
                  type="primary"
                  disabled={
                    (!selectedRows?.length && !metrics?.length) || csvAsyncWithDetails.loading
                  }
                  data-testid="product-visibility-export-modal-download-button-with-details"
                  onClick={() =>
                    onDownloadWithDetailsClick(selectedRows?.length ? selectedRows : metrics)
                  }
                  loading={csvAsyncWithDetails.loading}
                >
                  Download {selectedRows.length > 0 ? `selected` : `all`} with details
                </Button>
              ) : (
                <Button
                  className={clsx(s.bold)}
                  size="large"
                  type="primary"
                  disabled={!selectedRows?.length && !metrics?.length}
                  data-testid="product-visibility-export-modal-download-button"
                  onClick={() => onDownloadClick()}
                >
                  Download {selectedRows.length > 0 ? `selected` : `all`}
                </Button>
              )}
            </div>
            {csvAsyncWithDetails.error && (
              <p className={s.error}>Error occured while loading csv, try again later.</p>
            )}
            <CSVLink
              ref={csvLink}
              filename={`product-visibility-${downloadTimestamp?.format(
                'MM-DD-YYYY_hh-mm-ss_A'
              )}.csv`}
              data={prepareRecordsForExport(
                selectedRows?.length ? selectedRows : metrics,
                productColumnsWithCsv
              )}
            />
            <CSVLink
              ref={csvWithDetailsLink}
              filename={`product-visibility-details-${downloadTimestamp?.format(
                'MM-DD-YYYY_hh-mm-ss_A'
              )}.csv`}
              data={csvAsyncWithDetails.data}
            />
          </>
        }
      >
        <Spin spinning={csvAsyncWithDetails.loading}>
          {!selectedRows?.length && !metrics?.length ? (
            'Nothing to export. Please change filters.'
          ) : (
            <>
              <p>
                We will export{' '}
                {selectedRows.length > 0
                  ? `${selectedRows.length} selected`
                  : `all ${metrics.length}`}{' '}
                products into a <code>.csv</code> file.
              </p>
              <Checkbox
                checked={exportWithDetails}
                onChange={(event) => setExportWithDetails(event.target.checked)}
                className={s.checkbox}
                data-testid="product-visibility-export-modal-details-checkbox"
              >
                Include details
              </Checkbox>
            </>
          )}
        </Spin>
      </Modal>
      <ProductsStats filters={combinedFilters} />
      <div className={s.topBar}>
        <div className={s.search}>
          <MultipleValuesInput
            placeholder="Search by PO Number, GTIN, SAP ID, SAP Order Number or description"
            value={
              nonEmptySearch
                ? typeof nonEmptySearch === 'string'
                  ? [nonEmptySearch]
                  : nonEmptySearch
                : []
            }
            onChange={setSearchTerm}
            allowClear={true}
            prefix={<SearchOutlined width="14px" height="14px" />}
            splitInputValue={/[^0-9a-zA-Z-]+/g}
          />
          <label className={s.asinSwitch}>
            <Switch
              checked={onlyPrimeDayAsins}
              onChange={(value) => setOnlyPrimeDayAsins(value)}
              size="small"
              loading={loading}
            />
            <span>Prime day ASINs only</span>
          </label>
        </div>
        <TableFilters
          filters={tableFilters}
          queryParamName={FILTERS_QUERY_PARAM_STRING}
          loading={productFilters.loading}
        />
      </div>

      {error ? (
        <div style={{ marginTop: '40px' }}>Could not load data due to an error. Try again.</div>
      ) : (
        <>
          <div className={s.totalAndSelectedWrapper}>
            <div className={s.totalAndSelected}>
              <div>
                {loading ? '...' : metrics.length} product{metrics.length === 1 ? '' : 's'}
                {selectedRows.length > 0 && ', '}
              </div>
              {selectedRows.length > 0 && (
                <>
                  <div>{selectedRows.length} selected</div>
                  <Button
                    className="filled_grey_btn_no_border"
                    onClick={resetSelectedRows}
                    data-testid="visibility-clear-selection"
                  >
                    Clear selection
                  </Button>
                </>
              )}
            </div>
          </div>
          <Table
            ref={tableRendered}
            rowKey={getKey}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={false}
            sticky={{
              offsetScroll: 6
            }}
            rowSelection={{
              preserveSelectedRowKeys: true,
              onChange: (_keys, rows) => {
                setSelectedRows(rows);
              },
              type: 'checkbox',
              selectedRowKeys: selectedRows.map(getKey),
              //@ts-ignore
              getCheckboxProps(record) {
                return { 'data-testid': getKey(record) };
              }
            }}
            columns={productColumns}
            loading={loading}
            dataSource={metrics}
            className={clsx(
              `styled_report_table`,
              s.table,
              isPaginationHidden && s.tableNoPagination
            )}
            size="small"
            //@ts-ignore
            onChange={(pagination, filter, sorter: { columnKey: string; order: string }, extra) => {
              setExpandedRows([]);
              if (extra.action === 'sort') {
                setSortedInfo({
                  column: sorter.columnKey,
                  direction: sorter.order
                });
                setUrlParams({
                  ...urlParams,
                  column: sorter.columnKey ?? null,
                  direction: sorter.order ?? null
                });
              }
            }}
            pagination={
              isPaginationHidden
                ? false
                : {
                    position: ['topRight'],
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200'],
                    pageSize: pageSize,
                    size: 'small',
                    onShowSizeChange: (_current, size) => setPageSize(size),
                    current: page,
                    onChange: (page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                    }
                  }
            }
            bordered
            expandable={{
              expandedRowKeys: expandedRows,
              onExpand: (expand, record) => {
                const key = getKey(record);
                if (expandedRows.includes(key)) {
                  setExpandedRows([...expandedRows.filter((row) => row !== key)]);
                } else {
                  setExpandedRows([...expandedRows, key]);
                }
              },
              rowExpandable: (record) => !!record?.productId,
              expandedRowRender: (record, _index, _indent, expanded) =>
                expanded && (
                  <ProductDetailsTable
                    productId={record.productId as number}
                    filters={combinedFilters}
                    onReady={setHorizontalScroll}
                  />
                )
            }}
          />
        </>
      )}
    </div>
  );
};
