import React, { useEffect, useMemo, useState } from 'react';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import s from './DemandVisibility.module.scss';
import { StatsRow } from './components/StatsRow';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { ArrayParam, JsonParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { EMPTY, NOT_AVAILABLE } from 'common/constants';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import {
  formatDemand,
  formatMoney,
  abbreviateAndFormatDemand,
  abbreviateAndFormatGrossValue
} from './components/helper';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  DemandVisibilityByBrandDocument,
  DemandVisibilityByBrandQuery
} from './gql/__generated__/demandVisibilityByBrand.query';
import { safeNumberComparator } from 'common/helpers/comparators';
import { Paginator } from 'components/Paginator/Paginator';
import { SortingType } from 'common/types';
import { DemandVisibilitySortColumn } from 'graphql/__generated__/enums';
import { SearchOutlined } from '@ant-design/icons';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { notEmpty } from 'common/helpers/notEmpty';
import { useQuery } from '@apollo/client';
import { DemandVisibilityByWeekDocument } from './gql/__generated__/demandVisibilityByWeek.query';
import { ExecutiveReportingFilters, SortOrderDirection } from 'graphql/__generated__/types';
import { useDeepCompareEffect, useDeepCompareMemo } from 'use-deep-compare';
import { transformCalendarValueToPeriodWeekYear } from 'components/PeriodCalendar/helpers';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { convertWeekToPeriodWeekString, getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { AvailableDemandVisbilityFiltersDocument } from './gql/__generated__/demandVisibilityAvailableFilters.query';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import clsx from 'clsx';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { range } from 'lodash-es';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { DemandVisibilityTotalsDocument } from './gql/__generated__/demandVisibilityTotals.query';

type DemandVisibilityByBrand = InferNodeType<
  DemandVisibilityByBrandQuery,
  'demandVisibilityByBrand'
>;

const getRowKey = (item: DemandVisibilityByBrand) =>
  `${item.brand}-${item.demandInCases}-${item.grossValue}`;

interface DemandVisibilityProps {
  showDots: boolean;
}

//filters default values
const { week: currentWeek, year: currentYear } = getFinancialInfo(new Date());
const startingYear = 2020;

//Stats Tiles
const NumberFormat = ({ number = '', tooltip }: { number: string | number; tooltip?: string }) => {
  return (
    <div className={s.number_format}>
      {tooltip ? (
        <AlloyTooltip title={tooltip} placement="bottom">
          <span className={s.number}>{number}</span>
        </AlloyTooltip>
      ) : (
        <span className={s.number}>{number}</span>
      )}
    </div>
  );
};

//Graph
const generateTicks = (current: number, upper: number, step: number): number[] => {
  if (current > upper) return [];
  return [current, ...generateTicks(current + step, upper, step)];
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={s.tooltip}>
        <div>
          Week of: <span className={s.value}>{label}</span>
        </div>
        {payload.map((item, index) => (
          <div key={index}>
            Demand of
            <span className={s.value}>
              {item.dataKey || item.dataKey === EMPTY ? ` ${item.dataKey}` : ' NO YEAR'}
            </span>
            : {''}
            <span className={s.value}>
              {item.value || item.value === 0 ? `${item.value.toLocaleString()}` : 'EMPTY'}
            </span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const colors = ['#0597F2', '#FDB515', '#CF1322', '#228B22'];

export const DemandVisibility: React.FC<DemandVisibilityProps> = ({
  showDots
}: {
  showDots: boolean;
}) => {
  //Filters
  const [selectedCountry, setSelectedCountry] = useQueryParam(
    'countries',
    withDefault(StringParam, 'US')
  );

  const filter = useQuery(AvailableDemandVisbilityFiltersDocument, {
    variables: {
      countryCode: selectedCountry
    }
  });

  const countriesOptions = useMemo(
    () =>
      [...(filter.data?.demandVisibilityAvailableFilters?.countries || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filter.data?.demandVisibilityAvailableFilters?.countries]
  );

  const [customers, setCustomers] = useQueryParam('customers', withDefault(ArrayParam, ['all']));

  const customersOptions = useMemo(
    () =>
      [...(filter.data?.demandVisibilityAvailableFilters?.vendorMarkets || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filter.data?.demandVisibilityAvailableFilters?.vendorMarkets]
  );

  const customerIds = useMemo(
    () =>
      getValueWithAll(
        customers as string[],
        customersOptions.map((x) => x.externalId)
      ),
    [customers, customersOptions]
  );

  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));

  const businessOptions = useMemo(
    () =>
      [...(filter.data?.demandVisibilityAvailableFilters?.businessUnits || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filter.data?.demandVisibilityAvailableFilters?.businessUnits]
  );

  const businessUnitCodes = useMemo(
    () =>
      getValueWithAll(
        bus as string[],
        businessOptions.map((x) => x.code ?? '') //businessUnitCode is null on the BE, but it technically is never null ~ ?? '' is a fix until BE modifies it
      ),
    [bus, businessOptions]
  );

  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [currentYear],
      start: 1,
      end: currentWeek
    })
  );

  //Setup filters to BE
  const filters: ExecutiveReportingFilters = useDeepCompareMemo(
    () => ({
      fiscalCalendarWeeks: transformCalendarValueToPeriodWeekYear(period),
      countryCode: selectedCountry,
      retailerChannelExternalIds: customerIds,
      business_unit_codes: businessUnitCodes
    }),
    [period, selectedCountry, customerIds, businessUnitCodes]
  );

  //Demand and Gross Value Tiles
  const tiles = useQuery(DemandVisibilityTotalsDocument, {
    variables: {
      filters
    }
  });

  //Demand graph
  const graph = useQuery(DemandVisibilityByWeekDocument, {
    variables: {
      filters
    }
  });

  interface CleanDataPoint {
    label: string;
    year: number;
    demand: number;
  }
  interface GraphDataPoint {
    label: string;
    [year: string]: string | number;
  }

  const cleanGraphData: CleanDataPoint[] = (() => {
    if (graph.data === undefined) {
      return [];
    } else {
      return (
        graph.data.demandVisibilityByWeek?.map((item) => ({
          label: `${convertWeekToPeriodWeekString(item.fiscalCalendarWeek.week)}`,
          year: item.fiscalCalendarWeek.year,
          demand: parseFloat(item.demandInCases)
        })) ?? []
      );
    }
  })();

  let maxValue, upperBound, ticks, graphData, keys;
  if (
    cleanGraphData.length === 0 ||
    (cleanGraphData.length === 1 && cleanGraphData[0].label === '')
  ) {
    maxValue = 0;
    upperBound = 10000;
    graphData = [{}];
    keys = [''];
  } else {
    maxValue = Math.max(...cleanGraphData.map((item) => item.demand));
    if (maxValue <= 100000) {
      upperBound = Math.round((maxValue + 500) / 1000) * 1000;
      ticks = generateTicks(0, upperBound, 100);
    } else {
      upperBound = Math.round((maxValue + 5000) / 10000) * 10000;
      ticks = generateTicks(0, upperBound, 10000);
    }
    graphData = cleanGraphData.reduce<GraphDataPoint[]>((data, row: CleanDataPoint) => {
      const existingPoint = data.find((obj) => obj.label === row.label);
      if (existingPoint) {
        existingPoint[row.year] = row.demand;
      } else {
        data.push({
          label: row.label,
          [row.year]: row.demand
        });
      }
      return data;
    }, []);
    keys = Object.keys(graphData[0]).filter((key) => key !== 'label');
  }

  //Brands table
  const INITIAL_PAGINATION = 10;
  const PAGE_SIZE_OPTIONS_DEMAND_VISIBILITY = [10, 25, 50, 100, 200];
  const PAGE_SIZE_DEMAND_VISIBILITY = PAGE_SIZE_OPTIONS_DEMAND_VISIBILITY[0];
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION);
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);
  const [sortColumn, setSortColumn] = useQueryParam('column', withDefault(StringParam, 'BRAND'));
  const [sortOrder, setSortOrder] = useQueryParam('order', StringParam);
  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(ArrayParam, []));
  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  const validateSortColumn = (column: string): DemandVisibilitySortColumn => {
    if (Object.values(DemandVisibilitySortColumn).includes(column as DemandVisibilitySortColumn)) {
      return column as DemandVisibilitySortColumn;
    }
    return DemandVisibilitySortColumn.BRAND; //defaults to brand if anything that's not part of DemandVisibilitySortColumn comes up like: 'UPDATED_AT'
  };

  useEffect(() => {
    if (!pageSize || !sortOrder || !sortColumn) {
      setPageSize(PAGE_SIZE_DEMAND_VISIBILITY);
      setSortOrder('ASC');
      setSortColumn('BRAND');
    }
  }, [
    PAGE_SIZE_DEMAND_VISIBILITY,
    pageSize,
    setPageSize,
    setSortColumn,
    setSortOrder,
    sortColumn,
    sortOrder
  ]);

  //reseting pagination and table search bar after filter changes
  useDeepCompareEffect(() => {
    setSearchTerm([]);
    setBefore(null);
    setAfter(null);
    setPageSize(INITIAL_PAGINATION);
  }, [filters, setAfter, setBefore, setSearchTerm, setPageSize]);

  const table = useQuery(DemandVisibilityByBrandDocument, {
    variables: {
      filters,
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: validateSortColumn(sortColumn),
        direction: sortOrder as SortOrderDirection
      },
      after,
      before,
      searchTerm: nonEmptySearch
    },
    skip: !pageSize || !sortColumn || !sortOrder
  });

  const brandTableData = useMemo(
    () => getNodesFromEdges(table.data?.demandVisibilityByBrand),
    [table.data?.demandVisibilityByBrand]
  );

  const nextPage = async () => {
    setAfter(table.data?.demandVisibilityByBrand?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(table.data?.demandVisibilityByBrand?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number, options: number[]) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(validateSortColumn(column));
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const columns: ColumnsType<DemandVisibilityByBrand> = [
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'BRAND',
      render: (_, { brand }) => (brand || NOT_AVAILABLE).toUpperCase(),
      sorter: (a, b) => (a?.brand || '').localeCompare(b?.brand || ''),
      width: 578
    },
    {
      title: 'Gross Value',
      dataIndex: 'grossValue',
      key: 'GROSS_VALUE',
      render: (_, record) => <span>{formatMoney(record.grossValue)}</span>,
      sorter: (a, b) =>
        safeNumberComparator(parseFloat(a?.grossValue.amount), parseFloat(b?.grossValue.amount)),
      width: 544
    },
    {
      title: 'Demand',
      dataIndex: 'demand',
      key: 'DEMAND_IN_CASES',
      render: (_, { demandInCases }) => (
        <span className={clsx(s.number)}>{`${formatDemand(demandInCases)} CS`}</span>
      ),
      sorter: (a, b) => safeNumberComparator(parseInt(a.demandInCases), parseInt(b.demandInCases)),
      width: 544
    }
  ];

  //Error Message
  if (filter.error)
    return (
      <ErrorDisplay error={filter.error} header="An error occurred while loading the filters" />
    );
  if (tiles.error)
    return (
      <ErrorDisplay error={tiles.error} header="An error occurred while loading the tiles data" />
    );
  if (graph.error)
    return (
      <ErrorDisplay error={graph.error} header="An error occurred while loading the tiles data" />
    );
  if (table.error)
    return (
      <ErrorDisplay error={table.error} header="An error occurred while loading the table data" />
    );

  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}> Demand Visibility </PageHeader>
        </div>
        <div className={s.page_filters}>
          <AlloySelect
            loading={filter.loading}
            className={s.select}
            showSearch={false}
            value={selectedCountry}
            onChange={(value) => {
              setSelectedCountry(value as string);
              setCustomers(['all']);
              setBus(['all']);
            }}
            options={[
              ...countriesOptions.map((country) => ({
                label: country.name,
                value: country.code
              }))
            ]}
          />
          <AlloySelect
            loading={filter.loading}
            className={clsx(s.select, customers.length > 1 && s.moreThanOne)}
            showSearch={true}
            value={customers}
            mode="multiple"
            onChange={(value) =>
              setArrayWithAll(value as string[], customers as string[], setCustomers)
            }
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'All Customers', value: 'all' },
              ...customersOptions.map((customer) => ({
                label: customer.name,
                value: customer.externalId
              }))
            ]}
          />
          <AlloySelect
            loading={filter.loading}
            className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
            showSearch={true}
            value={bus}
            mode="multiple"
            onChange={(value) => setArrayWithAll(value as string[], bus as string[], setBus)}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'All BUs', value: 'all' },
              ...businessOptions.map((bus) => ({
                label: bus.name,
                value: bus.code
              }))
            ]}
          />
          <PeriodCalendar
            loading={filter.loading}
            value={period}
            onChange={setPeriod}
            years={startingYear === currentYear ? undefined : range(startingYear, currentYear + 1)}
            pastOnly={startingYear === currentYear}
          />
        </div>
      </div>
      <div className={s.stats}>
        <StatsRow
          stats={[
            {
              title: 'Total Demand',
              value: (
                <NumberFormat
                  number={`${abbreviateAndFormatDemand(
                    tiles.data?.demandVisibilityTotals.totalDemandInCases
                  )} CS`}
                  tooltip={`${formatDemand(
                    tiles.data?.demandVisibilityTotals.totalDemandInCases
                  )} CS`}
                />
              ),
              loading: tiles.loading
            },
            {
              title: 'Gross Value',
              value: (
                <NumberFormat
                  number={`${abbreviateAndFormatGrossValue(
                    tiles.data?.demandVisibilityTotals.grossValue
                  )}`}
                  tooltip={`${formatMoney(tiles.data?.demandVisibilityTotals.grossValue)}`}
                />
              ),
              loading: tiles.loading
            }
          ]}
        />
      </div>
      <div className={s.section_wrapper}>
        <AlloySpin spinning={graph.loading}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={graphData} margin={{ top: 0, right: 10, bottom: 30, left: 0 }}>
              <XAxis
                axisLine={{ stroke: '#B1B1B1' }}
                dataKey="label"
                tick={{ textAnchor: 'end', fill: 'black' }}
                dx={-5}
                tickLine={{ transform: 'translate(0, -3)', stroke: '#B1B1B1' }}
                angle={-90}
                tickMargin={2}
                interval={0}
                tickFormatter={(value) => (value ? `${value}` : '')}
              />
              <YAxis
                axisLine={{ stroke: '#B1B1B1' }}
                domain={[0, upperBound]}
                width={70}
                ticks={ticks}
                tick={{ fill: 'black' }}
                tickSize={0}
                tickMargin={5}
                tickFormatter={(value) => {
                  if (value >= 1000000000) {
                    return `${(value / 1000000000).toFixed(value % 1000000000 !== 0 ? 1 : 0)}B CS`;
                  } else if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(value % 1000000 !== 0 ? 1 : 0)}MM CS`;
                  } else if (value >= 1000) {
                    return `${(value / 1000).toFixed(value % 1000 !== 0 ? 1 : 0)}K CS`;
                  } else {
                    return `${value} CS`;
                  }
                }}
              />
              <Tooltip
                cursor={{ stroke: 'black', strokeDasharray: '3 3' }}
                content={<CustomTooltip />}
              />
              {keys.map((year, index) => (
                <Line
                  key={year}
                  strokeWidth={2}
                  name={`Demand ${year}`}
                  type="linear"
                  dataKey={year}
                  stroke={colors[index % colors.length]}
                  dot={showDots ? { stroke: colors[index % colors.length], strokeWidth: 1 } : false}
                  legendType={'square'}
                  connectNulls={false}
                  isAnimationActive={false}
                />
              ))}
              <Legend verticalAlign="top" align="right" />
            </LineChart>
          </ResponsiveContainer>
        </AlloySpin>
      </div>
      <div className={s.container}>
        <div className={s.search}>
          <MultipleValuesInput
            placeholder="Search by brand"
            value={
              nonEmptySearch
                ? typeof nonEmptySearch === 'string'
                  ? [nonEmptySearch]
                  : nonEmptySearch
                : []
            }
            onChange={setSearchTerm}
            allowClear={true}
            prefix={<SearchOutlined width="14px" height="14px" />}
            splitInputValue={/[^0-9a-zA-Z-]+/g}
          />
        </div>
        <div className={s.pagination_container}>
          <QuantityDisplay
            count={table.data?.demandVisibilityByBrand?.totalCount}
            titleSingular="Brand"
            titleMultiple="Brands"
          />
          <Paginator
            hasNextPage={!!table.data?.demandVisibilityByBrand?.pageInfo?.hasNextPage}
            hasPreviousPage={!!table.data?.demandVisibilityByBrand?.pageInfo?.hasPreviousPage}
            nextPage={nextPage}
            prevPage={prevPage}
            pageSize={pageSize || PAGE_SIZE_DEMAND_VISIBILITY}
            handlePageSizeChange={(newPageSize) =>
              handlePageSizeChange(newPageSize, PAGE_SIZE_OPTIONS_DEMAND_VISIBILITY)
            }
            onlyButtons={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS_DEMAND_VISIBILITY}
          />
        </div>
        <AlloySpin spinning={table.loading}>
          <AlloyTable
            columns={columns}
            dataSource={brandTableData}
            pagination={false}
            rowKey={getRowKey}
            sortDirections={[
              'ascend' as SortingType,
              'descend' as SortingType,
              'ascend' as SortingType
            ]}
            onChange={(_, __, sorter) => {
              const column =
                String((sorter as SorterResult<DemandVisibilityByBrand>).columnKey) || 'BRAND';
              const order =
                (sorter as SorterResult<DemandVisibilityByBrand>).order === 'ascend' ||
                !(sorter as SorterResult<DemandVisibilityByBrand>).order
                  ? 'ASC'
                  : 'DESC';
              handleTableSorting(column, order);
            }}
          />
        </AlloySpin>
      </div>
    </>
  );
};
