import React, { useMemo, useState } from 'react';
import s from './ExecutiveSummaryTable.module.scss';
import { EMPTY } from 'common/constants';
import { OtifFilters } from 'graphql/__generated__/types';
import { mapDataWithPeriods, MappedResponseOtif } from '../../helpers';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';
import { yearPeriodWeekSorter } from 'common/helpers/periodHelper';
import { safeNumberComparator } from 'common/helpers/comparators';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { stringifyCalendarValue } from 'components/PeriodCalendar/helpers';
import { OtifReportsByPeriodDocument } from 'pages/OnTimeInFull/gql/__generated__/otifReportsByPeriod.query';
import { ApolloClient, useQuery } from '@apollo/client';
import {
  ColumnsWithExportRender,
  prepareSingleRecordForXLSXExport
} from 'common/helpers/tableExportAlloy';
import { notEmpty } from 'common/helpers/notEmpty';
import moment from 'moment';
import { utils as XLSXutils, writeFileXLSX } from '@/src/non_npm_dependencies/xlsx';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import clsx from 'clsx';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { OtifFiltersOnlyWeekInFilter } from 'pages/OnTimeInFull/types';

const formatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  return value.toFixed(2) + '%';
};

const IncreaseDecrease = ({ type }: { type: 'increase' | 'decrease' | string | undefined }) => {
  if (type === 'increase') {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.00003 0V1H8.29503L3.05176e-05 9.295L0.70503 10L9.00003 1.705V8H10V0H2.00003Z"
          fill="#48B94C"
        />
      </svg>
    );
  }
  if (type === 'decrease') {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.00003 10V9H8.29503L3.05176e-05 0.705L0.70503 0L9.00003 8.295V2H10V10H2.00003Z"
          fill="#B94C4C"
        />
      </svg>
    );
  }
  return <div style={{ width: '10px' }}></div>;
};

const prepareOrdersForExport = async (
  client: ApolloClient<object>,
  filters: OtifFiltersOnlyWeekInFilter,
  columns: ColumnsWithExportRender<MappedResponseOtif>
) => {
  const data = await client.query({ query: OtifReportsByPeriodDocument, variables: { filters } });
  const { metrics } = mapDataWithPeriods(
    data?.data.otifReportsByPeriod,
    filters.fiscalCalendarWeeks
  );

  return metrics
    .filter(notEmpty)
    .sort((a, b) => yearPeriodWeekSorter(a.fiscalCalendarWeek, b.fiscalCalendarWeek))
    .map((x) => ({
      ...prepareSingleRecordForXLSXExport(x, columns)
    }));
};

const columns: ColumnsWithExportRender<MappedResponseOtif> = [
  {
    // TODO: remove hard-coded value
    title: 'Customer',
    render: () => 'Amazon',
    exportConfig: {
      render: () => 'Amazon'
    }
  },
  {
    // TODO: remove hard-coded value
    title: 'BU',
    render: () => 'PBC',
    exportConfig: {
      render: () => 'PBC'
    }
  },
  {
    title: 'Period Week',
    key: 'periodWeek',
    dataIndex: 'label',
    sorter: (a, b) => yearPeriodWeekSorter(a.fiscalCalendarWeek, b.fiscalCalendarWeek),
    defaultSortOrder: 'descend',
    exportConfig: {
      render: ({ label }) => label
    }
  },
  {
    title: 'OTIF',
    align: 'right',
    key: 'otif',
    render: (_, { otif }) => <div>{formatPercentage(otif)}</div>,
    sorter: (a, b) => safeNumberComparator(a?.otif, b?.otif),
    exportConfig: {
      render: ({ otif }) => formatPercentage(otif)
    }
  },
  {
    title: 'OT',
    align: 'right',
    key: 'ot',
    render: (_, { onTime }) => <div>{formatPercentage(onTime)}</div>,
    sorter: (a, b) => safeNumberComparator(a?.onTime, b?.onTime),
    exportConfig: {
      render: ({ onTime }) => formatPercentage(onTime)
    }
  },
  {
    title: 'IF',
    align: 'right',
    key: 'if',
    render: (_, { inFull }) => <div>{formatPercentage(inFull)}</div>,
    sorter: (a, b) => safeNumberComparator(a?.inFull, b?.inFull),
    exportConfig: {
      render: ({ inFull }) => formatPercentage(inFull)
    }
  },
  {
    title: 'SFR',
    align: 'right',
    key: 'sfr',
    render: (_, { submittedFillRate, submittedFillRateChange }) => (
      <div className={s.fill_rate}>
        {formatPercentage(submittedFillRate)}{' '}
        <div>
          <IncreaseDecrease type={submittedFillRateChange} />
        </div>
      </div>
    ),
    sorter: (a, b) => safeNumberComparator(a?.submittedFillRate, b?.submittedFillRate),
    exportConfig: {
      render: ({ submittedFillRate }) => formatPercentage(submittedFillRate)
    }
  },
  {
    title: 'AFR',
    align: 'right',
    key: 'afr',
    render: (_, { acceptedFillRate, acceptedFillRateChange }) => (
      <div className={s.fill_rate}>
        {formatPercentage(acceptedFillRate)}{' '}
        <div>
          <IncreaseDecrease type={acceptedFillRateChange} />
        </div>
      </div>
    ),
    sorter: (a, b) => safeNumberComparator(a?.acceptedFillRate, b?.acceptedFillRate),
    exportConfig: {
      render: ({ acceptedFillRate }) => formatPercentage(acceptedFillRate)
    }
  }
];

export const ExecutiveSummaryTable = ({
  filters,
  period,
  exportModalVisible,
  setExportModalVisible
}: {
  filters: OtifFiltersOnlyWeekInFilter;
  period: CalendarValue;
  exportModalVisible: boolean;
  setExportModalVisible: (value: boolean) => void;
}) => {
  const { loading, data, client } = useQuery(OtifReportsByPeriodDocument, {
    variables: { filters }
  });
  const { metrics, areMultipleYearsSelected } = useMemo(
    () => mapDataWithPeriods(data?.otifReportsByPeriod, filters.fiscalCalendarWeeks),
    [data?.otifReportsByPeriod, filters.fiscalCalendarWeeks]
  );

  const years = useMemo(
    () => [...new Set(metrics.map((x) => x.fiscalCalendarWeek.year))].join(', ') + ' ',
    [metrics]
  );

  const [exportAsync, setExportAsync] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });

  const exportViewData = async () => {
    try {
      setExportAsync({ loading: true, error: false });
      const ordersForExport = await prepareOrdersForExport(client, filters, columns);
      const filename = `otif_${moment().format('MM-DD-YYYY_hh-mm-ss_A')}.xlsx`;
      const worksheet = XLSXutils.json_to_sheet(ordersForExport);
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, 'OTIF Executive Summary');
      writeFileXLSX(workbook, filename, { compression: true });

      setExportModalVisible(false);
      setExportAsync({
        loading: false,
        error: false
      });
    } catch {
      setExportAsync({ loading: false, error: true });
    }
  };

  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>
        Period week breakdown{' '}
        {`(${areMultipleYearsSelected ? years : ''}${stringifyCalendarValue(period)})`}
      </h2>
      <AlloyTable
        dataSource={metrics}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          size: 'small',
          position: ['bottomRight'],
          hideOnSinglePage: true
        }}
        columns={columns}
        rowKey="label"
      />
      <AlloyModal
        title="Export view"
        open={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        width={400}
        destroyOnClose
        footer={
          <>
            <div className={s.modal_buttons}>
              <AlloyButton
                className={clsx('filled_grey_btn_no_border', s.bold)}
                size="large"
                onClick={() => setExportModalVisible(false)}
                data-testid="otif-export-modal-close-button"
              >
                Cancel
              </AlloyButton>
              <AlloyButton
                size="large"
                style={{ width: 'auto' }}
                type="primary"
                onClick={() => exportViewData()}
                loading={exportAsync.loading}
                data-testid="otif-export-modal-download-button"
                disabled={exportAsync.loading || metrics.length === 0}
              >
                Download
              </AlloyButton>
            </div>
            {exportAsync.error && (
              <p className={s.error}>
                Error occured while loading <code>.xlsx</code>, try again later.
              </p>
            )}
          </>
        }
      >
        <AlloySpin spinning={exportAsync.loading}>
          {metrics.length === 0 ? (
            <p>There is no data to export</p>
          ) : (
            <p>
              We will export filtered orders into a <code>.xlsx</code> file.
            </p>
          )}
        </AlloySpin>
      </AlloyModal>
    </div>
  );
};
