import type { ThemeConfig } from 'ant5';

export const prefixCls = 'ant5';

// We need this theme for storybook
// For some reason, I couldn't encapsulate it in a custom ConfigProvider, so let's share theme itself for now
export const customTheme: ThemeConfig = {
  token: {
    colorPrimary: '#0597F2',
    colorInfo: '#0597F2',
    fontFamily: 'Roboto, sans-serif',
    borderRadius: 4,
    colorError: '#cf1322',
    colorBgContainerDisabled: '#F2F2F2',
    controlItemBgActiveDisabled: '#cccccc'
  },
  components: {
    Button: {
      // TODO: decide if we want to do it. If so, we also want to modify selects, otherwise it looks strange.
      // colorBorder: '#757575'
      colorLink: 'var(--color-prime-blue)'
    },
    Table: {
      headerSplitColor: 'rgba(0, 0, 0, 0)',
      rowHoverBg: '#E6EEFE',
      borderRadius: 0,
      headerBorderRadius: 0,
      // TODO: separate narrow/wide styles for tables?
      cellPaddingBlock: 8
    },
    Tag: {
      defaultBg: 'rgb(255, 255, 255)',
      defaultColor: '#000000',
      colorBorder: 'rgb(0, 0, 0)'
    },
    Segmented: {
      borderRadius: 1000,
      borderRadiusXS: 1000,
      borderRadiusSM: 1000,
      borderRadiusLG: 1000,
      itemSelectedBg: '#0597F2',
      itemSelectedColor: '#ffffff',
      itemHoverBg: '#cccccc',
      itemHoverColor: '#000000',
      itemColor: '#000000',
      fontSize: 12
    },
    Checkbox: {
      borderRadiusSM: 2
    },
    Input: {
      paddingBlock: 8,
      paddingInline: 8,
      paddingBlockSM: 4,
      paddingInlineSM: 8
    },
    InputNumber: {
      paddingBlock: 8,
      paddingInline: 8,
      paddingBlockSM: 4,
      paddingInlineSM: 8
    }
  }
};
