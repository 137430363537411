import { RepackLastChangeInfo } from 'graphql/__generated__/types';
import s from './ChangeTooltip.module.scss';
import React, { ReactNode } from 'react';
import moment from 'moment';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

export const ChangeTooltip = ({
  lastChangeInfo,
  children
}: {
  lastChangeInfo?: RepackLastChangeInfo | null;
  children: ReactNode;
}) => {
  return lastChangeInfo ? (
    <AlloyTooltip
      overlayStyle={{ maxWidth: '300px' }}
      overlayInnerStyle={{ padding: '12px' }}
      title={
        <div className={s.tooltip}>
          <div>
            <b>Reason: </b>
            {lastChangeInfo.reason}
          </div>
          <div>
            <b>Comment: </b> {lastChangeInfo.comment}
          </div>
          <div className={s.authorAndDate}>
            <div>{lastChangeInfo.userName}</div>
            <div>{moment(lastChangeInfo.updatedAt).format('MMMM D YYYY, h:mm a')}</div>
          </div>
        </div>
      }
    >
      {children}
    </AlloyTooltip>
  ) : (
    <AlloyTooltip title="Was moved">{children}</AlloyTooltip>
  );
};
