import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RemoveScenariosMutationVariables = Types.Exact<{
  scenarioIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type RemoveScenariosMutation = { __typename?: 'RootMutationType', removeScenarios?: { __typename?: 'RemoveScenariosPayload', message: string } | null };


export const RemoveScenariosDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveScenarios"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeScenarios"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"scenarioIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioIds"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<RemoveScenariosMutation, RemoveScenariosMutationVariables>;