import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Label as PurchaseOrderLabel } from 'graphql/__generated__/types';
import { intersectionBy } from 'lodash-es';
import { AttachLabelToPurchaseOrdersDocument } from './gql/__generated__/attachLabelToPurchaseOrders.mutation';
import { notEmpty } from 'common/helpers/notEmpty';
import { DetachLabelFromPurchaseOrdersDocument } from './gql/__generated__/detachLabelFromPurchaseOrders.mutation';
import { PoLabelsListDocument } from './gql/__generated__/poLabelsList.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTag } from 'components/ui/AlloyTag/AlloyTag';
import clsx from 'clsx';
import s from './EditPoTagsModal.module.scss';

interface EditPoTagsModalProps {
  visibility: boolean;
  toggleEditPoTagsModal: () => void;
  poTagsList: PurchaseOrderLabel[];
  orderId: string | string[];
  bulkForm?: boolean;
}

const EditPoTagsModal = ({
  visibility,
  toggleEditPoTagsModal,
  poTagsList,
  orderId,
  bulkForm
}: EditPoTagsModalProps) => {
  const [labelsList, setLabelsList] = useState<PurchaseOrderLabel[]>([]);
  const [attachedLabels, setAttachedLabels] = useState<PurchaseOrderLabel[]>([]);

  const [addLabelToPo] = useMutation(AttachLabelToPurchaseOrdersDocument, {
    onCompleted: (data) => {
      const mappedData = (data?.attachLabelToPurchaseOrders?.purchaseOrders || [])
        .map((po) => po?.labels || [])
        .flat()
        .filter(notEmpty);
      setAttachedLabels(intersectionBy(mappedData, 'id'));
    }
  });

  const [removeLabelFromPo] = useMutation(DetachLabelFromPurchaseOrdersDocument, {
    onCompleted: (data) => {
      const mappedData = (data?.detachLabelFromPurchaseOrders?.purchaseOrders || [])
        .map((po) => po?.labels || [])
        .flat()
        .filter(notEmpty);
      setAttachedLabels(intersectionBy(mappedData, 'id'));
    }
  });

  const [getPoLabelsList] = useLazyQuery(PoLabelsListDocument, {
    onCompleted: ({ labels }) => {
      setLabelsList(getNodesFromEdges(labels));
      setAttachedLabels(poTagsList);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const addLabel = async (labelId: string) => {
    try {
      await addLabelToPo({
        variables: {
          input: {
            labelId: labelId,
            purchaseOrderIds: Array.isArray(orderId) ? orderId : [orderId]
          }
        }
      });
    } catch (e) {
      console.log('Add label error', e);
    }
  };
  const removeLabel = async (labelId: string) => {
    try {
      await removeLabelFromPo({
        variables: {
          input: {
            labelId: labelId,
            purchaseOrderIds: Array.isArray(orderId) ? orderId : [orderId]
          }
        }
      });
    } catch (e) {
      console.log('Remove label error', e);
    }
  };

  useEffect(() => {
    if (visibility) {
      getPoLabelsList({
        variables: {
          first: 100
        }
      });
    }
  }, [visibility, getPoLabelsList]);

  return (
    <AlloyModal
      data-testid="edit-po-tags-modal"
      title={
        <span data-testid="edit-po-tags-title">
          {bulkForm ? `Add Tag (${orderId.length} PO's)` : 'Add Tag'}
        </span>
      }
      open={visibility}
      onCancel={toggleEditPoTagsModal}
      footer={
        <>
          <AlloyButton
            key="apply"
            data-testid="done-button"
            type="primary"
            onClick={toggleEditPoTagsModal}
          >
            Done
          </AlloyButton>
        </>
      }
      destroyOnClose
    >
      {labelsList?.map((item) => {
        const checked = attachedLabels?.some((tag) => item.id === tag.id);
        return (
          <AlloyTag.CheckableTag
            key={item.id}
            data-testid={`${item.text.toLowerCase()}-tag`}
            className={clsx(s.tag, { [s.checked]: checked })}
            onClick={() => (checked ? removeLabel(item.id) : addLabel(item.id))}
            checked={checked}
          >
            {checked && <CheckOutlined data-testid={`check-${item.text.toLowerCase()}-tag`} />}
            <span data-testid={`${item.text.toLowerCase()}-tag-text`}>{item.text}</span>
          </AlloyTag.CheckableTag>
        );
      })}
    </AlloyModal>
  );
};

export default EditPoTagsModal;
