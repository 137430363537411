import { Checkbox, CheckboxProps } from 'components/alloy/Checkbox/Checkbox';
import { Label } from 'components/alloy/Label/Label';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import s from './FormCheckbox.module.scss';
import clsx from 'clsx';

interface FormCheckboxProps extends CheckboxProps {
  inline?: 'before' | 'after';
}

export const FormCheckbox = ({ label, disabled, inline, style, ...other }: FormCheckboxProps) => {
  const uuid = uuidv4();
  return (
    <div
      className={clsx(s.form_checkbox, {
        [s.inline_before]: inline === 'before',
        [s.inline_after]: inline === 'after'
      })}
      style={style}
    >
      {!!label && <Label label={label} htmlFor={`input-${uuid}`} disabled={disabled} />}
      <Checkbox label={label} disabled={disabled} {...other} />
    </div>
  );
};
