import { FieldInfo } from 'components/alloy/FieldInfo/FieldInfo';
import { Select, SelectProps } from 'components/alloy/Select/Select';
import React from 'react';

export interface FormSelectProps<Multiple extends boolean | undefined = undefined>
  extends SelectProps<Multiple> {
  info?: string;
  hideFieldInfo?: boolean;
}

export const FormSelect = <Multiple extends boolean | undefined = undefined>({
  disabled,
  error,
  info,
  'data-testid': dataTestid,
  required,
  className,
  multiple,
  hideFieldInfo = false,
  ...other
}: FormSelectProps<Multiple>) => {
  return (
    <div data-testid={`${dataTestid}-form-select`} className={className}>
      <Select
        disabled={disabled}
        error={error}
        required={required}
        multiple={multiple}
        data-testid={dataTestid}
        {...other}
      />
      {!hideFieldInfo && <FieldInfo info={info} error={error} disabled={disabled} />}
    </div>
  );
};
