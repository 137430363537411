import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllStoDashboardDistributionCentersQueryVariables = Types.Exact<{
  destinationCode?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  originCode?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type AllStoDashboardDistributionCentersQuery = { __typename?: 'RootQueryType', allStoDashboardDistributionCenters?: { __typename?: 'AllStoDashboardDistributionCenter', destinationCodes?: Array<string | null> | null, originCodes?: Array<string | null> | null } | null };


export const AllStoDashboardDistributionCentersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"allStoDashboardDistributionCenters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"destinationCode"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"originCode"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allStoDashboardDistributionCenters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"destinationCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"destinationCode"}}},{"kind":"Argument","name":{"kind":"Name","value":"originCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"originCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"destinationCodes"}},{"kind":"Field","name":{"kind":"Name","value":"originCodes"}}]}}]}}]} as unknown as DocumentNode<AllStoDashboardDistributionCentersQuery, AllStoDashboardDistributionCentersQueryVariables>;