import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AttachLabelToPurchaseOrdersMutationVariables = Types.Exact<{
  input: Types.AttachLabelToPurchaseOrdersInput;
}>;


export type AttachLabelToPurchaseOrdersMutation = { __typename?: 'RootMutationType', attachLabelToPurchaseOrders?: { __typename?: 'AttachLabelToPurchaseOrdersPayload', purchaseOrders: Array<{ __typename?: 'PurchaseOrder', id: string, labels: Array<{ __typename?: 'Label', id: string, text: string }> }> } | null };


export const AttachLabelToPurchaseOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"attachLabelToPurchaseOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AttachLabelToPurchaseOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"attachLabelToPurchaseOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"labels"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AttachLabelToPurchaseOrdersMutation, AttachLabelToPurchaseOrdersMutationVariables>;