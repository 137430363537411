import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RepackOptimizerStatusQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.RepackOptimizerStatusFilters>;
}>;


export type RepackOptimizerStatusQuery = { __typename?: 'RootQueryType', repackOptimizerStatus?: { __typename?: 'RepackOptimizerStatus', isValid?: boolean | null, status: Types.OptimizerStatus } | null };


export const RepackOptimizerStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"repackOptimizerStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RepackOptimizerStatusFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"repackOptimizerStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isValid"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<RepackOptimizerStatusQuery, RepackOptimizerStatusQueryVariables>;