import React from 'react';
import {
  CustomizationHeader,
  DistributionCenterFullData
} from '../../DistributionCenterViewDrawer';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import { ViewTable } from 'components/Common/ViewMode/ViewTable/ViewTable';
import s from './CustomizationViewTab.module.scss';

export const CustomizationViewTab = ({
  distributionCenter
}: {
  distributionCenter: DistributionCenterFullData;
}) => {
  const customizationTable = (
    <ViewTable<CustomizationHeader>
      columns={[
        {
          title: '',
          dataIndex: 'priority',
          withTooltip: false,
          classname: s.priority_column
        },
        {
          title: 'Field',
          dataIndex: 'field',
          withTooltip: true,
          classname: s.field_column
        },
        {
          title: 'Title',
          dataIndex: 'title',
          withTooltip: true,
          classname: s.title_column
        },
        {
          title: 'Type',
          dataIndex: 'fieldType',
          withTooltip: false,
          classname: s.type_column
        }
      ]}
      data={
        distributionCenter.customizationHeaders
          ?.filter((header): header is CustomizationHeader => !!header)
          .map((row, index) => {
            return { ...row, priority: index + 1 };
          }) || []
      }
    />
  );

  return (
    <>
      <ViewRow title="Customization fields" content={customizationTable} bordered={false} />
    </>
  );
};
