import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RefreshScenarioMutationVariables = Types.Exact<{
  input: Types.RefreshScenarioInput;
}>;


export type RefreshScenarioMutation = { __typename?: 'RootMutationType', refreshScenario?: { __typename?: 'RefreshScenarioPayload', refreshCompleted: boolean } | null };


export const RefreshScenarioDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RefreshScenario"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RefreshScenarioInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshScenario"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshCompleted"}}]}}]}}]} as unknown as DocumentNode<RefreshScenarioMutation, RefreshScenarioMutationVariables>;