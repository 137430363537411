import { InventoryReconciliationMainTableQuery } from './components/InventoryTable/gql/__generated__/inventoryReconciliationMainTable.query';
import { InventoryReconciliationBatchDetailsQuery } from './components/InventoryTable/gql/__generated__/inventoryReconciliationBatchDetails.query';
import { AvailableInventoryReconciliationFiltersQuery } from './gql/__generated__/availableInventoryReconciliationFilters.query';

export type DistributionCenter = NonNullable<
  AvailableInventoryReconciliationFiltersQuery['availableInventoryReconciliationFilters']
>['distributionCenters'][number];

export type InventoryMetric = NonNullable<
  NonNullable<
    InventoryReconciliationMainTableQuery['inventoryReconciliationMainTable']
  >['metrics'][number]
>;
type InventoryMetricDetails = NonNullable<
  NonNullable<
    InventoryReconciliationBatchDetailsQuery['inventoryReconciliationBatchDetails']
  >['metrics'][number]
>;

export function mapInventoryMetricDetailsToIncludeUOM(metric: InventoryMetricDetails) {
  return {
    ...metric,
    gtinDetails: metric.gtinDetails.map((gtinDetails) => ({
      ...gtinDetails,
      baseUnitOfMeasure: metric.baseUnitOfMeasure
    }))
  };
}
export type MappedInventoryMetricDetails = ReturnType<typeof mapInventoryMetricDetailsToIncludeUOM>;
export type MappedInventoryMetricBatchDetails = MappedInventoryMetricDetails['gtinDetails'][number];

export const FILTERS_QUERY_PARAM_STRING = 'inventory_filters';
export type FilterKeys = 'uom' | 'status';
