import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DemandVisibilityTotalsQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type DemandVisibilityTotalsQuery = { __typename?: 'RootQueryType', demandVisibilityTotals: { __typename?: 'DemandVisibilityTotals', totalDemandInCases: string, grossValue: { __typename?: 'Currency', amount: string, units: string } } };


export const DemandVisibilityTotalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"demandVisibilityTotals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"demandVisibilityTotals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"grossValue"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"units"}}]}},{"kind":"Field","name":{"kind":"Name","value":"totalDemandInCases"}}]}}]}}]} as unknown as DocumentNode<DemandVisibilityTotalsQuery, DemandVisibilityTotalsQueryVariables>;