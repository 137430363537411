import { AuditTrailVersion } from '../ChangeHistory';
import { DistributionCenterForShipToAudit, TradingPartnerForAudit, getValues } from './baseHelper';
import { difference } from 'lodash-es';

export const prepareUserAuditVersions = (
  versions: AuditTrailVersion[],
  tradingPartners: TradingPartnerForAudit[],
  distributionCenters: DistributionCenterForShipToAudit[]
) => {
  return versions.map((version) => {
    const addedTps = version.diff
      .filter(
        (diff) =>
          diff.fieldPath.endsWith('.tradingPartnerId') && diff.__typename === 'AuditTrailAddition'
      )
      .map((diff) => getValues(diff).newValue);
    const addedDcs = version.diff
      .filter(
        (diff) =>
          diff.fieldPath.endsWith('.distributionCenterId') &&
          diff.__typename === 'AuditTrailAddition'
      )
      .map((diff) => getValues(diff).newValue);

    const removedTps = version.diff
      .filter(
        (diff) =>
          diff.fieldPath.endsWith('.tradingPartnerId') && diff.__typename === 'AuditTrailRemoval'
      )
      .map((diff) => getValues(diff).previousValue);
    const removedDcs = version.diff
      .filter(
        (diff) =>
          diff.fieldPath.endsWith('.distributionCenterId') &&
          diff.__typename === 'AuditTrailRemoval'
      )
      .map((diff) => getValues(diff).previousValue);

    const otherChanges = version.diff.filter(
      (diff) =>
        !diff.fieldPath.endsWith('.tradingPartnerId') &&
        !diff.fieldPath.endsWith('.entirelySet') &&
        !diff.fieldPath.endsWith('.distributionCenterId')
    );

    const versionDiff = [...otherChanges];

    const addTradingPartners = difference(addedTps, removedTps);
    if (addTradingPartners.length > 0) {
      versionDiff.push({
        __typename: 'AuditTrailAddition',
        fieldPath: '.addTradingPartners',
        value: {
          __typename: 'AuditTrailListOfPrimitives',
          values: addTradingPartners.map(
            (tpId) => tradingPartners.find((tp) => tp.id === tpId)?.name || 'DELETED'
          )
        }
      });
    }

    const addShipTo = difference(addedDcs, removedDcs);
    if (addShipTo.length > 0) {
      versionDiff.push({
        __typename: 'AuditTrailAddition',
        fieldPath: '.addDistributionCenters',
        value: {
          __typename: 'AuditTrailListOfPrimitives',
          values: addShipTo.map(
            (dcId) => distributionCenters.find((dc) => dc.id === dcId)?.name || 'DELETED'
          )
        }
      });
    }

    const removeTradingPartners = difference(removedTps, addedTps);
    if (removeTradingPartners.length > 0) {
      versionDiff.push({
        __typename: 'AuditTrailRemoval',
        fieldPath: '.removeTradingPartners',
        value: {
          __typename: 'AuditTrailListOfPrimitives',
          values: removeTradingPartners.map(
            (tpId) => tradingPartners.find((tp) => tp.id === tpId)?.name || 'DELETED'
          )
        }
      });
    }

    const removeShipTo = difference(removedDcs, addedDcs);
    if (removeShipTo.length > 0) {
      versionDiff.push({
        __typename: 'AuditTrailRemoval',
        fieldPath: '.removeDistributionCenters',
        value: {
          __typename: 'AuditTrailListOfPrimitives',
          values: removeShipTo.map(
            (dcId) => distributionCenters.find((dc) => dc.id === dcId)?.name || 'DELETED'
          )
        }
      });
    }
    return {
      ...version,
      diff: versionDiff
    };
  });
};
