import { Checkbox, Switch } from 'antd';
import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import s from './FinalFormSwitch.module.scss';
import clsx from 'clsx';

interface FinalFormSwitchProps {
  name: string;
  title: string;
  checkbox?: boolean;
  stopPropagation?: boolean;
  validate?: FieldValidator<string | string[]>;
  inline?: boolean;
  disabled?: boolean;
  classname?: string;
  'data-testid'?: string;
}

export const FinalFormSwitch = ({
  name,
  title,
  checkbox = false,
  validate,
  inline = false,
  disabled = false,
  classname,
  'data-testid': dataTestid = ''
}: FinalFormSwitchProps) => {
  return (
    <div className={clsx(inline ? s.checkbox : s.switch, classname)} data-testid={dataTestid}>
      <span data-testid={`final-form-${checkbox ? 'checkbox' : 'switch'}-title`}>{title}</span>
      <Field name={name} required type="checkbox" validate={validate}>
        {({ input }) => {
          // Deconstructing value so we don't pass it to the component.
          // Doesn't matter for antd v4 (because there is no such prop), but important for antd v5
          const { value, ...restInput } = input;

          return checkbox ? (
            <Checkbox
              {...restInput}
              data-testid="final-form-checkbox"
              disabled={disabled}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          ) : (
            <Switch
              {...restInput}
              data-testid="final-form-switch"
              disabled={disabled}
              onClick={(checked, event) => {
                event.stopPropagation();
              }}
            />
          );
        }}
      </Field>
    </div>
  );
};
