export const notificationModalInitialValues = {
  isOpen: false,
  configureNotifications: (notificationDays: number) => {},
  closeModal: () => {}
};

export const destinationScheduleModalInitialValues = {
  isOpen: false,
  updateDestinationSchedule: (deliveryDays: string[]) => {},
  closeModal: () => {}
};
