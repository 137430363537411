import clsx from 'clsx';
import { groupBy, uniqBy } from 'lodash-es';
import moment from 'moment';
import { DistributionCenter } from 'pages/AssortmentPage/AssortmentPage';
import { InventoryDcsQuery } from 'pages/AssortmentPage/gql/__generated__/inventoryDcs.query';
import React from 'react';
import s from './InventoryView.module.scss';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';

interface InventoryViewProps {
  distributionCenters: DistributionCenter[];
  inventory: Inventory[];
}

type Inventory = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<InventoryDcsQuery['tradingPartnerAssortmentsByRpIds']>[number]
        >['substitutions']
      >[number]
    >['inventory']
  >[number]
>;

export const InventoryView = ({ distributionCenters, inventory }: InventoryViewProps) => {
  const columns: ColumnsType<Inventory & { rowSpan: number }> = [
    {
      key: 'dc',
      title: 'DC NAME',
      render: (_, item) => {
        const dc = distributionCenters.find((dc) => dc.id === item.distributionCenterId);
        return (
          <span>
            {dc?.name} {dc?.code}
          </span>
        );
      },
      onCell: (item) => ({
        rowSpan: item.rowSpan
      })
    },
    {
      key: 'source',
      title: 'SOURCE',
      width: 165,
      render: (_, item) => item.source
    },
    {
      key: 'quantity_on_hand',
      title: 'QUANTITY ON-HAND',
      width: 80,
      render: (_, item) => (item.inventoryOnHand ? item.inventoryOnHand.toLocaleString() : '-')
    },
    {
      key: 'status',
      title: 'Status',
      width: 80,
      render: (_, item) => (item.active ? 'Active' : 'Inactive')
    },
    {
      key: 'last_updated',
      title: 'LAST UPDATED',
      width: 92,
      render: (_, item) =>
        item.inventoryOnHandUpdatedAt
          ? moment(item.inventoryOnHandUpdatedAt).format('MM/DD/YYYY HH:mm:ss')
          : '-'
    }
  ];

  const getInventoryData = () => {
    const result: (Inventory & { rowSpan: number })[] = [];
    const groupedByDc = groupBy(inventory, 'distributionCenterId');
    for (const distributionCenterId in groupedByDc) {
      const items = groupedByDc[distributionCenterId].sort((inv1, inv2) =>
        inv1.active === inv2.active ? 0 : inv1.active ? -1 : 1
      );
      items.forEach((item, index) => {
        result.push({
          ...item,
          rowSpan: index === 0 ? items.length : 0
        });
      });
    }
    return result;
  };

  return (
    <>
      <div className={s.details_subtitle}>
        Available at DCs ({uniqBy(inventory, (inv) => `${inv.distributionCenterId}`).length})
      </div>
      <AlloyTable<Inventory & { rowSpan: number }>
        columns={columns}
        rowKey={(row) => `${row.distributionCenterId}${row.source}`}
        dataSource={getInventoryData()}
        pagination={false}
        sticky
        size="small"
        className={s.inventory_details_table}
        rowClassName={(record) => clsx({ [s.inactive]: !record.active })}
      />
      <hr />
    </>
  );
};
