import { useQuery } from '@apollo/client';
import { Table, Timeline } from 'antd';
import LoaderSpinner from 'components/LoaderSpinner';
import React, { ReactNode } from 'react';
import s from './ChangeHistory.module.scss';
import clsx from 'clsx';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  ChangeHistoryData,
  ChangeHistoryEntityType,
  mapAuditTrailVersionToChangeHistoryData,
  isChangeHistoryValue
} from './helpers/baseHelper';
import {
  RddAuditTrailVersionsDocument,
  RddAuditTrailVersionsQuery
} from './gql/__generated__/rddAuditVersions.query';
import { DistributionCentersForShipToAuditDocument } from './gql/__generated__/distributionCentersForShipToAudit.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { TradingPartnersForShipToAuditDocument } from './gql/__generated__/tradingPartnersForShipToAudit.query';
import { useEnumValue } from 'common/useEnumValue';
import { VendorAuditTrailVersionsDocument } from './gql/__generated__/retailerAuditVersions.query';
import { CloseOutlined } from '@ant-design/icons';
import { VendorMarketAuditTrailVersionsDocument } from './gql/__generated__/retailerChannelAuditVersions.query';
import { RetailersForRetailerChannelAuditDocument } from './gql/__generated__/retailersForRetailerChannelAudit.query';
import { RetailerChannelsForShipToAuditDocument } from './gql/__generated__/retailerChannelsForShipToAudit.query';
import { DistributionCenterAuditTrailVersionsDocument } from './gql/__generated__/distributionCenterAuditVersions.query';
import { TpAuditTrailVersionsDocument } from './gql/__generated__/tpAuditVersions.query';
import {
  RetailerProductRddAssortmentsDocument,
  RetailerProductRddAssortmentsQuery
} from './gql/__generated__/retailerProductRddAssortments.query';
import { Tooltip } from 'antd';
import { RetailerDeliveryDestinationsForAssortmentAuditDocument } from './gql/__generated__/retailerDeliveryDestinationsForAssortmentAudit.query';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { BusinessUnitsForAuditDocument } from './gql/__generated__/businessUnitsForAudit.query';
import { UserForAuditTrailDocument } from './gql/__generated__/userForAuditTrail.query';
import { ChannelAdvisorProfilesDocument } from 'pages/TradingPartnersPage/gql/__generated__/channelAdvisorProfiles.query';
import { FacilitiesForDistributionCenterAuditDocument } from './gql/__generated__/facilitiesForDistributionCenterAudit.query';
import { AlloyDrawer } from 'components/ui/AlloyDrawer/AlloyDrawer';
import { CatalogProductAuditTrailVersionsDocument } from '../../components/ChangeHistory/gql/__generated__/catalogProductAuditTrailVersions.query';

export type AuditTrailVersion = NonNullable<
  RetailerProductRddAssortment['retailerProduct']
>['auditTrailVersions'][number];

export type AuditTrailDiff = AuditTrailVersion['diff'][number];

export type RetailerDeliveryDestinationForAudit = NonNullable<
  RddAuditTrailVersionsQuery['retailerDeliveryDestination']
>;

export type RetailerProductRddAssortment = NonNullable<
  RetailerProductRddAssortmentsQuery['retailerProductRddAssortments']
>;

interface ChangeHistoryProps {
  entity?: {
    id: string;
    name: string;
    externalId?: string;
    type: ChangeHistoryEntityType;
    tradingPartnerId?: string;
  };
  onClose: () => void;
  title?: ReactNode;
}

const withSubPage = (type: ChangeHistoryEntityType | undefined) =>
  type !== 'Assortment' &&
  type !== 'New Assortment' &&
  type !== 'User' &&
  type !== 'unknown-use-for-mocks-only';

export const ChangeHistory = ({ entity, onClose, title = '' }: ChangeHistoryProps) => {
  // data by type
  const { data: retailerData, loading: retailerLoading } = useQuery(
    VendorAuditTrailVersionsDocument,
    {
      skip: entity?.type !== 'Retailer',
      variables: {
        id: entity?.id || ''
      }
    }
  );

  const { data: retailerChannelData, loading: retailerChannelLoading } = useQuery(
    VendorMarketAuditTrailVersionsDocument,
    {
      skip: entity?.type !== 'Retailer Channel',
      variables: {
        id: entity?.id || ''
      }
    }
  );

  const { data: tradingPartnerData, loading: tradingPartnerLoading } = useQuery(
    TpAuditTrailVersionsDocument,
    {
      skip: entity?.type !== 'Trading Partner',
      variables: {
        id: entity?.id || ''
      }
    }
  );

  const { data: shipToData, loading: shipToLoading } = useQuery(RddAuditTrailVersionsDocument, {
    skip: entity?.type !== 'Ship-To',
    variables: {
      id: entity?.id || ''
    }
  });

  const { data: distributionCenterData, loading: distributionCenterLoading } = useQuery(
    DistributionCenterAuditTrailVersionsDocument,
    {
      skip: entity?.type !== 'Distribution Center',
      variables: {
        id: entity?.id || ''
      }
    }
  );

  const { data: distributionCenterFacilitiesData, loading: distributionCenterFacilitiesLoading } =
    useQuery(FacilitiesForDistributionCenterAuditDocument, {
      skip: entity?.type !== 'Distribution Center'
    });

  // OLD ASSORTMENT
  const { data: assortmentData, loading: assortmentLoading } = useQuery(
    RetailerProductRddAssortmentsDocument,
    {
      skip: entity?.type !== 'Assortment',
      variables: {
        retailerProductId: entity?.id || '',
        tradingPartnerId: entity?.tradingPartnerId || ''
      }
    }
  );

  // NEW ASSORTMENT AUDIT TRAIL DATA WITH OLD ASSORTMENT
  const { data: newAssortmentData, loading: newAssortmentLoading } = useQuery(
    CatalogProductAuditTrailVersionsDocument,
    {
      skip: entity?.type !== 'New Assortment',
      variables: {
        retailerProductId: entity?.id || '',
        tradingPartnerId: entity?.tradingPartnerId || ''
      }
    }
  );

  const { data: userData, loading: userLoading } = useQuery(UserForAuditTrailDocument, {
    skip: entity?.type !== 'User',
    variables: {
      id: entity?.id || ''
    }
  });

  // additional data
  const { data: dcData, loading: dcLoading } = useQuery(DistributionCentersForShipToAuditDocument, {
    skip:
      entity?.type !== 'Ship-To' && entity?.type !== 'User' && entity?.type !== 'Trading Partner'
  });

  const { data: tpData, loading: tpLoading } = useQuery(TradingPartnersForShipToAuditDocument, {
    skip:
      entity?.type !== 'Ship-To' &&
      entity?.type !== 'Assortment' &&
      entity?.type !== 'New Assortment' &&
      entity?.type !== 'User'
  });

  const { data: channelAdvisoryProfileData, loading: profileLoading } = useQuery(
    ChannelAdvisorProfilesDocument,
    {
      skip: entity?.type !== 'Trading Partner'
    }
  );

  const { data: retailerChannelsData, loading: retailerChannelsLoading } = useQuery(
    RetailerChannelsForShipToAuditDocument,
    {
      skip:
        entity?.type !== 'Ship-To' &&
        entity?.type !== 'Trading Partner' &&
        entity?.type !== 'Retailer Channel'
    }
  );

  const { data: retailersData, loading: retailersLoading } = useQuery(
    RetailersForRetailerChannelAuditDocument,
    {
      skip: entity?.type !== 'Retailer Channel'
    }
  );

  const { data: buData, loading: buLoading } = useQuery(BusinessUnitsForAuditDocument, {
    skip: entity?.type !== 'Trading Partner'
  });

  const { data: rddsData, loading: rddsLoading } = useQuery(
    RetailerDeliveryDestinationsForAssortmentAuditDocument,
    {
      skip:
        entity?.type !== 'Assortment' &&
        entity?.type !== 'New Assortment' &&
        entity?.type !== 'Trading Partner'
    }
  );

  const { enumValues: daysOfWeek, loading: daysOfWeekLoading } = useEnumValue('DayOfWeek');

  const mapVersions = (type: ChangeHistoryEntityType) => {
    switch (type) {
      case 'Ship-To':
        return mapAuditTrailVersionToChangeHistoryData(
          shipToData?.retailerDeliveryDestination?.auditTrailVersions || [],
          type,
          shipToData?.retailerDeliveryDestination,
          {
            daysOfWeek: daysOfWeek || [],
            distributionCenters: getNodesFromEdges(dcData?.distributionCenters),
            tradingPartners: getNodesFromEdges(tpData?.tradingPartners),
            retailerChannels: getNodesFromEdges(retailerChannelsData?.vendorMarkets)
          }
        );
      case 'Trading Partner':
        return mapAuditTrailVersionToChangeHistoryData(
          tradingPartnerData?.tradingPartner?.auditTrailVersions || [],
          type,
          tradingPartnerData?.tradingPartner,
          {
            daysOfWeek: daysOfWeek || [],
            retailerChannels: getNodesFromEdges(retailerChannelsData?.vendorMarkets),
            businessUnits: getNodesFromEdges(buData?.businessUnits),
            distributionCenters: getNodesFromEdges(dcData?.distributionCenters),
            profileIds: channelAdvisoryProfileData?.channelAdvisorProfiles || [],
            retailerDeliveryDestinations: getNodesFromEdges(rddsData?.retailerDeliveryDestinations)
          }
        );
      case 'Retailer':
        return mapAuditTrailVersionToChangeHistoryData(
          retailerData?.vendor?.auditTrailVersions || [],
          type,
          retailerData?.vendor,
          {}
        );
      case 'Retailer Channel':
        return mapAuditTrailVersionToChangeHistoryData(
          retailerChannelData?.vendorMarket?.auditTrailVersions || [],
          type,
          retailerChannelData?.vendorMarket,
          {
            daysOfWeek: daysOfWeek || [],
            retailers: getNodesFromEdges(retailersData?.vendors),
            retailerChannels: getNodesFromEdges(retailerChannelsData?.vendorMarkets)
          }
        );
      case 'Distribution Center':
        return mapAuditTrailVersionToChangeHistoryData(
          distributionCenterData?.distributionCenter?.auditTrailVersions || [],
          type,
          distributionCenterData?.distributionCenter,
          {
            daysOfWeek: daysOfWeek || [],
            retailers: getNodesFromEdges(retailersData?.vendors),
            distributionCenterFacilities: getNodesFromEdges(
              distributionCenterFacilitiesData?.distributionCenterFacilities
            )
          }
        );
      case 'Assortment':
        return mapAuditTrailVersionToChangeHistoryData(
          assortmentData?.retailerProductRddAssortments?.retailerProduct.auditTrailVersions || [],
          type,
          assortmentData?.retailerProductRddAssortments,
          {
            daysOfWeek: daysOfWeek || [],
            tradingPartners: getNodesFromEdges(tpData?.tradingPartners),
            retailerDeliveryDestinations: getNodesFromEdges(rddsData?.retailerDeliveryDestinations)
          },
          entity?.tradingPartnerId
        );
      case 'New Assortment':
        const newAssortmentsAuditTrailVersions = [
          ...(newAssortmentData?.retailerProductRddAssortments?.retailerProduct
            ?.auditTrailVersions || []),
          ...getNodesFromEdges(newAssortmentData?.catalogProductAuditTrailVersions)
        ];
        return mapAuditTrailVersionToChangeHistoryData(
          newAssortmentsAuditTrailVersions,
          type,
          newAssortmentData?.retailerProductRddAssortments,
          {
            daysOfWeek: daysOfWeek || [],
            tradingPartners: getNodesFromEdges(tpData?.tradingPartners),
            retailerDeliveryDestinations: getNodesFromEdges(rddsData?.retailerDeliveryDestinations)
          },
          entity?.tradingPartnerId
        );
      case 'User':
        return mapAuditTrailVersionToChangeHistoryData(
          userData?.user?.auditTrailVersions || [],
          type,
          userData?.user,
          {
            daysOfWeek: daysOfWeek || [],
            tradingPartners: getNodesFromEdges(tpData?.tradingPartners),
            distributionCenters: getNodesFromEdges(dcData?.distributionCenters)
          }
        );
      default:
        return [];
    }
  };

  const loading =
    tradingPartnerLoading ||
    shipToLoading ||
    retailerLoading ||
    dcLoading ||
    tpLoading ||
    daysOfWeekLoading ||
    retailerChannelLoading ||
    retailersLoading ||
    retailerChannelsLoading ||
    distributionCenterLoading ||
    distributionCenterFacilitiesLoading ||
    assortmentLoading ||
    newAssortmentLoading ||
    rddsLoading ||
    buLoading ||
    profileLoading ||
    userLoading;

  const titleText = entity?.externalId ? `${entity?.externalId}: ${entity?.name}` : entity?.name;

  const getColumns = () => {
    const columns = [
      {
        title: 'Event',
        width: 172,
        render: (record: ChangeHistoryData) => (
          <span data-testid={`change-history-event-${record.key}`} className={s.event_title}>
            <span data-testid={`change-history-actor-${record.key}-eventName`}>
              {record.eventName}
            </span>
            {(entity?.type === 'Assortment' || entity?.type === 'New Assortment') &&
              record.meta.tradingPartnerId &&
              record.meta.tradingPartnerId !== entity.tradingPartnerId && (
                <span
                  className={s.trading_partner_meta}
                  data-testid={`change-history-actor-${record.key}-meta`}
                >
                  <span className={s.indicator} />
                  <Tooltip title={record.meta.tradingPartnerName}>
                    {record.meta.tradingPartnerExternalId}
                  </Tooltip>
                </span>
              )}
          </span>
        )
      },
      {
        title: 'Actor',
        width: withSubPage(entity?.type) ? 160 : 150,
        render: (record: ChangeHistoryData) => (
          <span data-testid={`change-history-actor-${record.key}`}>{record.actor}</span>
        )
      },
      {
        title: `Time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
        width: 174,
        render: (record: ChangeHistoryData) => (
          <span data-testid={`change-history-time-${record.key}`}>
            {moment(record.updatedAt).format('MMM D YYYY, h:mm a')}
          </span>
        )
      }
    ];
    if (withSubPage(entity?.type)) {
      columns.splice(1, 0, {
        title: 'Subpage',
        width: 170,
        render: (record: ChangeHistoryData) => (
          <span data-testid={`change-history-subpage-${record.key}`}>{record.subpageName}</span>
        )
      });
    }
    return columns;
  };

  return (
    <AlloyDrawer
      className={s.change_history_drawer}
      data-testid={`change-history-${entity?.type}-${entity?.id}`}
      width={!withSubPage(entity?.type) ? 570 : 765}
      open={!!entity}
      styles={{
        body: {
          padding: '0 24px',
          margin: '24px 0'
        }
      }}
      title={
        <div className={s.change_history_container}>
          <Tooltip title={titleText} placement="bottom">
            <div className={s.title}>
              <span className={s.bold}>Audit Trail |</span> {title ? title : titleText}
            </div>
          </Tooltip>
        </div>
      }
      onClose={onClose}
      destroyOnClose
      closeIcon={<CloseOutlined data-testid={`change-history-close`} />}
    >
      {loading && <LoaderSpinner />}
      {entity && !loading && (
        <Table<ChangeHistoryData>
          data-testid={`change-history-table`}
          className={s.history_table}
          size="small"
          columns={getColumns()}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              record.changes.length > 0 ? (
                expanded ? (
                  <div
                    data-testid={`change-history-hide-${record.key}`}
                    className={s.expand_icon_wraper}
                    onClick={(e) => onExpand(record, e)}
                  >
                    <UpOutlined sizes={'12px, 10px'} />
                  </div>
                ) : (
                  <div
                    data-testid={`change-history-expand-${record.key}`}
                    className={s.expand_icon_wraper}
                    onClick={(e) => onExpand(record, e)}
                  >
                    <DownOutlined />
                  </div>
                )
              ) : null,
            expandedRowRender: (record) => (
              <Timeline
                data-testid={`change-history-changes-${record.key}`}
                className={clsx(s.list_of_changes, {
                  [s.assortment]: !withSubPage(entity.type)
                })}
              >
                {record.changes.map((change) =>
                  isChangeHistoryValue(change) ? (
                    <Timeline.Item key={change.fieldTitle}>
                      <span className={s.field_name}>{change.fieldTitle}</span>
                      <ValueItem value={change.value} className={s.value} />
                    </Timeline.Item>
                  ) : (
                    <Timeline.Item key={change.fieldTitle}>
                      <span className={s.field_name}>{change.fieldTitle}</span>
                      <ValueItem value={change.previousValue} className={s.previous} />→{' '}
                      <ValueItem value={change.newValue} className={s.new} />
                    </Timeline.Item>
                  )
                )}
              </Timeline>
            ),
            rowExpandable: (record) => record.changes.length > 0
          }}
          dataSource={mapVersions(entity.type)}
          rowKey={(record) => `${record.updatedAt}-${record.eventName}-${record.key}}`}
          sticky
          rowClassName={s.expanded_row}
          pagination={false}
        />
      )}
    </AlloyDrawer>
  );
};

const ValueItem = ({
  value,
  className
}: {
  value: string | string[] | null | undefined;
  className: string;
}) =>
  Array.isArray(value) ? (
    value.length > 0 ? (
      <span className={className}>
        <ListOfItemsColumn items={value} />
      </span>
    ) : (
      <span className={className}>{'<Empty>'}</span>
    )
  ) : (
    <span className={className}>{value || '<Empty>'}</span>
  );
