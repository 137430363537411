import React, { useMemo } from 'react';
import { InventoryView } from '../InventoryView/InventoryView';
import { SubstitutionDropdownConfig } from 'pages/AssortmentPage/AssortmentPage';
import s from './ProductDetailsDrawer.module.scss';
import { useQuery } from '@apollo/client';
import { InventoryDcsDocument } from 'pages/AssortmentPage/gql/__generated__/inventoryDcs.query';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import {
  TradingPartnerAssortmentDocument,
  TradingPartnerAssortmentQuery
} from 'pages/AssortmentPage/gql/__generated__/tradingPartnerAssortment.query';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

export type TradingPartnerAssortmentFullData = NonNullable<
  NonNullable<
    NonNullable<TradingPartnerAssortmentQuery['tradingPartnerAssortmentsByRpIds']>[number]
  >
>;

interface ProductDetailsDrawerProps {
  isNewAssortmentEnabled?: boolean;
  onClose: () => void;
  productId: string | undefined;
  substitutionProductView: SubstitutionDropdownConfig;
  distributionCenters: {
    id: string;
    code: string;
    name: string;
  }[];
  tradingPartnerId: string;
  startEditing: (retailerProduct: TradingPartnerAssortmentFullData) => void;
}

export const ProductDetailsDrawer = ({
  isNewAssortmentEnabled,
  onClose,
  productId,
  substitutionProductView,
  distributionCenters,
  tradingPartnerId,
  startEditing
}: ProductDetailsDrawerProps) => {
  const { data: dcsData, loading: dcsLoading } = useQuery(InventoryDcsDocument, {
    skip: !productId,
    variables: {
      retailerProductIds: [productId || ''],
      tradingPartnerId,
      active: true
    }
  });

  const { data, loading } = useQuery(TradingPartnerAssortmentDocument, {
    skip: !productId,
    variables: {
      retailerProductIds: [productId || ''],
      tradingPartnerId,
      active: true
    }
  });

  const productInfo =
    data?.tradingPartnerAssortmentsByRpIds && data?.tradingPartnerAssortmentsByRpIds.length > 0
      ? data?.tradingPartnerAssortmentsByRpIds[0]
      : undefined;

  const inventory =
    dcsData?.tradingPartnerAssortmentsByRpIds &&
    dcsData?.tradingPartnerAssortmentsByRpIds.length > 0
      ? dcsData.tradingPartnerAssortmentsByRpIds[0]?.substitutions[
          substitutionProductView[productId || ''] || 0
        ]?.inventory?.filter((inv) => inv !== null) || []
      : [];

  const viewContent = (
    <div>
      <AlloySpin spinning={loading}>
        <div className={s.details_subtitle}>Product details</div>
        <div className={s.details_item}>
          External Id <span>{productInfo?.retailerProductExternalId}</span>
        </div>
        <div className={s.details_item}>
          UPC{' '}
          <div>
            <span>
              {
                productInfo?.substitutions[
                  substitutionProductView[productInfo?.retailerProductId] || 0
                ]?.upc
              }
            </span>
          </div>
        </div>
        <div className={s.details_item}>
          Material ID{' '}
          <span>
            {
              productInfo?.substitutions[
                substitutionProductView[productInfo?.retailerProductId] || 0
              ]?.sapMaterialId
            }
          </span>
        </div>
        <hr />
        <div className={s.details_subtitle}>Dimensions</div>
        <div className={s.details_item}>
          Width{' '}
          <span>
            {
              productInfo?.substitutions[
                substitutionProductView[productInfo?.retailerProductId] || 0
              ]?.width
            }{' '}
            in
          </span>
        </div>
        <div className={s.details_item}>
          Height{' '}
          <span>
            {
              productInfo?.substitutions[
                substitutionProductView[productInfo?.retailerProductId] || 0
              ]?.height
            }{' '}
            in
          </span>
        </div>
        <div className={s.details_item}>
          Depth{' '}
          <span>
            {
              productInfo?.substitutions[
                substitutionProductView[productInfo?.retailerProductId] || 0
              ]?.depth
            }{' '}
            in
          </span>
        </div>
        <hr />
      </AlloySpin>
      <AlloySpin spinning={dcsLoading}>
        <InventoryView distributionCenters={distributionCenters} inventory={inventory} />
      </AlloySpin>
    </div>
  );

  return (
    <EntityDrawer
      title={
        productInfo?.substitutions[substitutionProductView[productInfo?.retailerProductId] || 0]
          ?.name || ''
      }
      open={!!productId}
      onClose={onClose}
      viewMode={viewContent}
      loading={false}
      startEditing={() => productInfo && startEditing(productInfo)}
    />
  );
};
