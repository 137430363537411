import {
  ConfirmationType,
  DeliveryType,
  FilterOperator,
  PO_ERROR_FILTER_MAP
} from 'common/constants';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { Filter, FilterData, LocationUpdate } from 'common/interfaces';
import { useLazyQuery } from '@apollo/client';
import {
  AdvancedFilterComponent,
  getInitialFilters
} from 'components/AdvancedFilter/AdvancedFilterComponent';
import { EXCLUDE_PREFIX, filtersToLocalUpdate } from 'common/helpers/advancedFiltersHelper';
import { parse, stringify } from 'query-string';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import {
  getNodesFromEdges,
  queryStringToArrayOrUndefined,
  locationPropertyToStringOrNull
} from 'common/helpers/mappingHelper';
import {
  AdvancedPurchaseOrderFilters,
  PurchaseOrderFilters,
  PurchaseOrderStatus,
  ConfirmationType as ConfirmationTypeGenerated,
  PurchaseOrderErrorsCondition,
  PurchaseOrderError,
  PurchaseOrderLabelIdsCondition,
  PurchaseOrderRddExternalIdsCondition
} from 'graphql/__generated__/types';
import { notEmpty } from 'common/helpers/notEmpty';
import { LabelsDocument } from './gql/__generated__/labels.query';
import { BusinessUnitsOrdersPageDocument } from './gql/__generated__/businessUnitsOrdersPage.query';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { VendorMarketsAdvancedFiltersOrdersPageDocument } from './gql/__generated__/vendorMarketsAdvancedFiltersOrdersPage.query';
import { RddAdvancedFiltersDocument } from './gql/__generated__/rddAdvancedFilters.query';
import { TradingPartnersAdvancedFiltersDocument } from './gql/__generated__/tradingPartnersAdvancedFiltersOrdersPage.query';
import { DistributionCentersAdvancedFiltersDocument } from './gql/__generated__/distributionCentersAdvancedFilters.query';
import { SizeType } from 'ant5/es/config-provider/SizeContext';

interface AdvancedFiltersProps {
  onApplyAdvancedFilter: (update: LocationUpdate, name?: string) => void;
  handleOrdersFilter: (
    filterName: string,
    filterValue: string | { start: string; end: string } | string[]
  ) => void;
  size?: SizeType;
}

interface OrdersFilterData {
  filterData: PurchaseOrderFilters;
  advancedFilterData: AdvancedPurchaseOrderFilters;
}

const getVendorMarketsVariables = (filterData: OrdersFilterData) => {
  const { businessUnits, deliveryType, tradingPartners, distributionCenters } =
    filterData.filterData;
  const { purchaseOrderRddExternalIdsConditions } = filterData.advancedFilterData;
  const rddExternalIds = purchaseOrderRddExternalIdsConditions?.find(
    (condition) => condition?.operator === 'INCLUDE'
  );

  return {
    filter: {
      tradingPartners,
      businessUnits,
      deliveryType,
      deliveryDestinations: rddExternalIds?.value,
      distributionCenters: queryStringToArrayOrUndefined(distributionCenters)
    }
  };
};

//map query string to queryFilter object to capture the current filter state
export const searchStringToQueryFilter = (search: string): OrdersFilterData => {
  const locationSearchProps = parse(search);

  //add new filter const here
  const {
    status,
    customers,
    businessUnits,
    deliveryDestinations,
    tradingPartners,
    purchaseOrderErrors,
    labels,
    dateReceived_start,
    dateReceived_end,
    mustArriveByDate_start,
    mustArriveByDate_end,
    salesOrderMustArriveByDate_start,
    salesOrderMustArriveByDate_end,
    deliveryWindow_start,
    deliveryWindow_end,
    deliveryType,
    customerPos,
    purchaseOrderConfirmation,
    distributionCenters,
    withCuts,
    shipmentsRange_max,
    shipmentsRange_min
  } = locationSearchProps;

  // TODO: Proper string parsing with ZOD?
  const purchaseOrderErrorConditions = [] as PurchaseOrderErrorsCondition[];
  const { includePurchaseOrderErrors, excludePurchaseOrderErrors } = (
    queryStringToArrayOrUndefined(purchaseOrderErrors) || []
  ).reduce(
    (
      result: {
        includePurchaseOrderErrors: PurchaseOrderError[];
        excludePurchaseOrderErrors: PurchaseOrderError[];
      },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludePurchaseOrderErrors.push(value.substr(7) as PurchaseOrderError)
        : result.includePurchaseOrderErrors.push(value as PurchaseOrderError);
      return result;
    },
    {
      includePurchaseOrderErrors: [] as PurchaseOrderError[],
      excludePurchaseOrderErrors: [] as PurchaseOrderError[]
    }
  );
  if (includePurchaseOrderErrors.length > 0) {
    purchaseOrderErrorConditions.push({
      operator: 'INCLUDE',
      value: includePurchaseOrderErrors
    });
  }
  if (excludePurchaseOrderErrors.length > 0) {
    purchaseOrderErrorConditions.push({
      operator: 'EXCLUDE',
      value: excludePurchaseOrderErrors
    });
  }

  const purchaseOrderLabelsConditions = [] as PurchaseOrderLabelIdsCondition[];
  const { includePurchaseOrderLabels, excludePurchaseOrderLabels } = (
    queryStringToArrayOrUndefined(labels) || []
  ).reduce(
    (
      result: { includePurchaseOrderLabels: string[]; excludePurchaseOrderLabels: string[] },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludePurchaseOrderLabels.push(value.substr(7))
        : result.includePurchaseOrderLabels.push(value);
      return result;
    },
    { includePurchaseOrderLabels: [] as string[], excludePurchaseOrderLabels: [] as string[] }
  );
  if (includePurchaseOrderLabels.length > 0) {
    purchaseOrderLabelsConditions.push({
      operator: 'INCLUDE',
      value: includePurchaseOrderLabels
    });
  }
  if (excludePurchaseOrderLabels.length > 0) {
    purchaseOrderLabelsConditions.push({
      operator: 'EXCLUDE',
      value: excludePurchaseOrderLabels
    });
  }

  const purchaseOrderRddExternalIdsConditions = [] as PurchaseOrderRddExternalIdsCondition[];
  const { includePurchaseOrderRddExternalIds, excludePurchaseOrderRddExternalIds } = (
    queryStringToArrayOrUndefined(deliveryDestinations) || []
  ).reduce(
    (
      result: {
        includePurchaseOrderRddExternalIds: string[];
        excludePurchaseOrderRddExternalIds: string[];
      },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludePurchaseOrderRddExternalIds.push(value.substr(7))
        : result.includePurchaseOrderRddExternalIds.push(value);
      return result;
    },
    {
      includePurchaseOrderRddExternalIds: [] as string[],
      excludePurchaseOrderRddExternalIds: [] as string[]
    }
  );
  if (includePurchaseOrderRddExternalIds.length > 0) {
    purchaseOrderRddExternalIdsConditions.push({
      operator: 'INCLUDE',
      value: includePurchaseOrderRddExternalIds
    });
  }
  if (excludePurchaseOrderRddExternalIds.length > 0) {
    purchaseOrderRddExternalIdsConditions.push({
      operator: 'EXCLUDE',
      value: excludePurchaseOrderRddExternalIds
    });
  }

  //return new filter variable here with value
  return {
    filterData: {
      // TODO: instead of as validate with ZOD?
      status: queryStringToArrayOrUndefined(status) as PurchaseOrderStatus[],
      customers: queryStringToArrayOrUndefined(customers),
      businessUnits: queryStringToArrayOrUndefined(businessUnits),
      tradingPartners: queryStringToArrayOrUndefined(tradingPartners),
      deliveryType: queryStringToArrayOrUndefined(deliveryType) as DeliveryType[],
      customerPos: queryStringToArrayOrUndefined(customerPos),
      distributionCenters: queryStringToArrayOrUndefined(distributionCenters),
      withCuts: withCuts === 'posWithCuts' ? true : withCuts === 'posWithoutCuts' ? false : null,
      purchaseOrderConfirmation: purchaseOrderConfirmation
        ? {
            type: locationPropertyToStringOrNull(
              purchaseOrderConfirmation
            ) as ConfirmationTypeGenerated,
            status: 'GENERATED'
          }
        : null,
      dateReceived: dateReceived_start
        ? {
            start: dateReceived_start,
            end: dateReceived_end
          }
        : null,
      mustArriveByDate: mustArriveByDate_start
        ? {
            start: mustArriveByDate_start,
            end: mustArriveByDate_end
          }
        : null,
      salesOrderMustArriveByDate: salesOrderMustArriveByDate_start
        ? {
            start: salesOrderMustArriveByDate_start,
            end: salesOrderMustArriveByDate_end
          }
        : null,
      deliveryWindow: deliveryWindow_start
        ? {
            start: deliveryWindow_start,
            end: deliveryWindow_end
          }
        : null,
      shipmentsRange:
        (shipmentsRange_min !== null && shipmentsRange_min !== undefined) ||
        (shipmentsRange_max !== null && shipmentsRange_max !== undefined)
          ? `${shipmentsRange_min ?? ''},${shipmentsRange_max ?? ''}`
          : undefined
    },
    advancedFilterData: {
      purchaseOrderErrorConditions: purchaseOrderErrorConditions,
      purchaseOrderRddExternalIdsConditions: purchaseOrderRddExternalIdsConditions,
      purchaseOrderLabelIdsConditions: purchaseOrderLabelsConditions
    }
  };
};

//add new query filter conditional here
//map current queryFilter object to filter object which is editable
export const queryFilterToFilters = (queryFilter: OrdersFilterData) => {
  //when adding new filter, check for it in queryFilter here

  const filters = [] as Filter[];
  if (queryFilter.filterData.distributionCenters)
    filters.push({
      name: 'distributionCenters',
      value: queryFilter.filterData.distributionCenters.filter(notEmpty)
    });
  if (queryFilter.filterData.tradingPartners)
    filters.push({
      name: 'tradingPartners',
      value: queryFilter.filterData.tradingPartners.filter(notEmpty)
    });
  if (queryFilter.filterData.customers)
    filters.push({ name: 'customers', value: queryFilter.filterData.customers.filter(notEmpty) });
  if (queryFilter.filterData.businessUnits)
    filters.push({
      name: 'businessUnits',
      value: queryFilter.filterData.businessUnits.filter(notEmpty)
    });
  if (queryFilter.filterData.deliveryType)
    filters.push({
      name: 'deliveryType',
      value: queryFilter.filterData.deliveryType.filter(notEmpty)
    });
  if (queryFilter.filterData.purchaseOrderConfirmation)
    filters.push({
      name: 'purchaseOrderConfirmation',
      value: queryFilter.filterData.purchaseOrderConfirmation.type
    });
  if (queryFilter.filterData.mustArriveByDate)
    filters.push({ name: 'mustArriveByDate', value: queryFilter.filterData.mustArriveByDate });
  if (queryFilter.filterData.salesOrderMustArriveByDate)
    filters.push({
      name: 'salesOrderMustArriveByDate',
      value: queryFilter.filterData.salesOrderMustArriveByDate
    });
  if (queryFilter.filterData.dateReceived)
    filters.push({ name: 'dateReceived', value: queryFilter.filterData.dateReceived });
  if (queryFilter.filterData.deliveryWindow)
    filters.push({ name: 'deliveryWindow', value: queryFilter.filterData.deliveryWindow });
  if (queryFilter.filterData.customerPos)
    filters.push({
      name: 'customerPos',
      value: queryFilter.filterData.customerPos.filter(notEmpty)
    });
  if (typeof queryFilter.filterData.withCuts === 'boolean')
    filters.push({
      name: 'withCuts',
      value: queryFilter.filterData.withCuts ? 'posWithCuts' : 'posWithoutCuts'
    });

  if (queryFilter.filterData.shipmentsRange) {
    const [min, max] = queryFilter.filterData.shipmentsRange.split(',');
    filters.push({
      name: 'shipmentsRange',
      value: {
        min: min === '' ? null : parseInt(min),
        max: max === '' ? null : parseInt(max)
      }
    });
  }

  const errorsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData.purchaseOrderErrorConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = errorsMap.get(condition.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    errorsMap.set(condition.operator, value);
  });
  errorsMap.forEach((value, key) => {
    filters.push({
      name: 'purchaseOrderErrors',
      value: value,
      option: key
    });
  });

  const labelsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData.purchaseOrderLabelIdsConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = labelsMap.get(condition.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    labelsMap.set(condition.operator, value);
  });
  labelsMap.forEach((value, key) => {
    filters.push({
      name: 'labels',
      value: value,
      option: key
    });
  });

  const deliveryDestinationsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData.purchaseOrderRddExternalIdsConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = deliveryDestinationsMap.get(condition?.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    deliveryDestinationsMap.set(condition.operator, value);
  });
  deliveryDestinationsMap.forEach((value, key) => {
    filters.push({
      name: 'deliveryDestinations',
      value: value,
      option: key
    });
  });

  return filters;
};

export const AdvancedFilters = ({
  onApplyAdvancedFilter,
  handleOrdersFilter,
  size
}: AdvancedFiltersProps) => {
  const history = useHistory();
  const [currentFilters, setCurrentFilters] = useState<Filter[]>(
    getInitialFilters(searchStringToQueryFilter(history.location.search), queryFilterToFilters)
  );
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [loadVendorMarkets, { loading: vendorMarketsLoading, data: vendorMarketsData }] =
    useLazyQuery(VendorMarketsAdvancedFiltersOrdersPageDocument, {});
  const vendorMarketList = useMemo(
    () =>
      vendorMarketsData
        ? getNodesFromEdges(vendorMarketsData?.vendorMarkets).sort((a, b) =>
            safeLocaleCompare(a.name, b.name)
          )
        : undefined,
    [vendorMarketsData]
  );

  const getTradingPartnersVariables = useCallback(
    (filterData: OrdersFilterData, preloadedData?) => {
      const { businessUnits, deliveryType, customers, distributionCenters } = filterData.filterData;
      const { purchaseOrderRddExternalIdsConditions } = filterData.advancedFilterData;
      const rddExternalIds = purchaseOrderRddExternalIdsConditions?.find(
        (condition) => condition?.operator === 'INCLUDE'
      );

      return {
        filter: {
          businessUnits,
          deliveryType,
          deliveryDestinations: rddExternalIds?.value,
          vendorMarkets: (queryStringToArrayOrUndefined(customers) || [])
            .map(
              (customer) =>
                vendorMarketList?.find((vendorMarket) => vendorMarket.name === customer)?.externalId
            )
            .filter(notEmpty),
          distributionCenters: queryStringToArrayOrUndefined(distributionCenters)
        }
      };
    },
    [vendorMarketList]
  );

  const [loadTradingPartners, { loading: tradingPartnersLoading, data: tradingPartnersData }] =
    useLazyQuery(TradingPartnersAdvancedFiltersDocument, {});

  const tradingPartnerList = useMemo(
    () =>
      tradingPartnersData
        ? getNodesFromEdges(tradingPartnersData?.tradingPartners).sort((a, b) =>
            safeLocaleCompare(a.name, b.name)
          )
        : undefined,
    [tradingPartnersData]
  );

  const getDistributionCentersVariables = useCallback(
    (filterData: OrdersFilterData) => {
      const { tradingPartners, customers, businessUnits, deliveryType } = filterData.filterData;
      const { purchaseOrderRddExternalIdsConditions } = filterData.advancedFilterData;
      const rddExternalIds = purchaseOrderRddExternalIdsConditions?.find(
        (condition) => condition?.operator === 'INCLUDE'
      );
      return {
        filter: {
          deliveryTypes: queryStringToArrayOrUndefined(deliveryType) || [],
          tradingPartners,
          vendorMarkets: (queryStringToArrayOrUndefined(customers) || [])
            .map(
              (customer) =>
                vendorMarketList?.find((vendorMarket) => vendorMarket.name === customer)?.externalId
            )
            .filter(notEmpty),
          businessUnits: queryStringToArrayOrUndefined(businessUnits) || [],
          deliveryDestinations: rddExternalIds?.value
        }
      };
    },
    [vendorMarketList]
  );

  const getDeliveryDestinationsVariables = useCallback(
    (filterData: OrdersFilterData) => {
      const { tradingPartners, customers, businessUnits, deliveryType, distributionCenters } =
        filterData.filterData;

      return {
        filter: {
          active: true,
          tradingPartners,
          vendorMarkets: (queryStringToArrayOrUndefined(customers) || [])
            .map(
              (customer) =>
                vendorMarketList?.find((vendorMarket) => vendorMarket.name === customer)?.externalId
            )
            .filter(notEmpty),
          businessUnits,
          deliveryTypes: deliveryType,
          distributionCenters: queryStringToArrayOrUndefined(distributionCenters)
        }
      };
    },
    [vendorMarketList]
  );

  const getBusinessUnitsVariables = useCallback(
    (filterData: OrdersFilterData) => {
      const { deliveryType, tradingPartners, customers, distributionCenters } =
        filterData.filterData;
      const { purchaseOrderRddExternalIdsConditions } = filterData.advancedFilterData;
      const rddExternalIds = purchaseOrderRddExternalIdsConditions?.find(
        (condition) => condition?.operator === 'INCLUDE'
      );

      return {
        filter: {
          tradingPartners,
          vendorMarkets: (queryStringToArrayOrUndefined(customers) || [])
            .map(
              (customer) =>
                vendorMarketList?.find((vendorMarket) => vendorMarket.name === customer)?.externalId
            )
            .filter(notEmpty),
          deliveryTypes: deliveryType,
          deliveryDestinations: rddExternalIds?.value,
          distributionCenters: queryStringToArrayOrUndefined(distributionCenters)
        }
      };
    },
    [vendorMarketList]
  );

  const [
    loadDistributionCenters,
    { loading: distributionCentersLoading, data: distributionCentersData }
  ] = useLazyQuery(DistributionCentersAdvancedFiltersDocument, {});

  const distributionCentersList = useMemo(
    () =>
      distributionCentersData
        ? getNodesFromEdges(distributionCentersData?.distributionCenters).sort((a, b) =>
            safeLocaleCompare(a?.name, b?.name)
          )
        : undefined,
    [distributionCentersData]
  );

  const [
    loadDeliveryDestinations,
    { loading: deliveryDestinationsLoading, data: deliveryDestinationsData }
  ] = useLazyQuery(RddAdvancedFiltersDocument, {});

  const deliveryDestinationsList = useMemo(
    () =>
      deliveryDestinationsData
        ? getNodesFromEdges(deliveryDestinationsData?.retailerDeliveryDestinations).sort((a, b) =>
            safeLocaleCompare(a?.name, b?.name)
          )
        : undefined,
    [deliveryDestinationsData]
  );

  const [loadBusinessUnits, { loading: businessUnitsLoading, data: businessUnitsData }] =
    useLazyQuery(BusinessUnitsOrdersPageDocument, {});

  const businessUnitsList = useMemo(
    () =>
      businessUnitsData
        ? getNodesFromEdges(businessUnitsData?.businessUnits)
            .map((x) => x.code)
            .filter(notEmpty)
            .sort((a, b) => safeLocaleCompare(a, b))
        : undefined,
    [businessUnitsData]
  );

  const [loadLabelsList, { loading: labelsListLoading, data: labelsListData }] = useLazyQuery(
    LabelsDocument,
    {
      variables: {
        first: 1000
      }
    }
  );

  const labelsList = useMemo(
    () => (labelsListData ? getNodesFromEdges(labelsListData?.labels) : undefined),
    [labelsListData]
  );

  //add filter UI object, attributes and values here
  //Drop down values
  const filters: FilterData[] = useMemo(
    () => [
      {
        name: 'deliveryType',
        title: 'Order Type',
        type: 'string',
        multiple: true,
        values: Object.keys(DeliveryType)
          .map((value) => ({ name: value, title: value }))
          .filter((deliveryType) => {
            const currentTpFilter = currentFilters.find(
              (filter) => filter.name === 'tradingPartners'
            );
            const currentShipTosFilter = currentFilters.find(
              (filter) => filter.name === 'deliveryDestinations'
            );
            if (
              (!tradingPartnerList ||
                !currentTpFilter ||
                !currentTpFilter.value ||
                (currentTpFilter.value as string[]).length === 0) &&
              (!deliveryDestinationsList ||
                !currentShipTosFilter ||
                !currentShipTosFilter.value ||
                (currentShipTosFilter.value as string[]).length === 0)
            )
              return true;
            const existInTradingPartnersDeliveryTypes = currentTpFilter
              ? (queryStringToArrayOrUndefined(currentFilters) || []).map(
                  (tpId) => tradingPartnerList?.find((tp) => tp.externalId === tpId)?.deliveryType
                )
              : Object.keys(DeliveryType);
            const existInShipTosDeliveryTypes = currentShipTosFilter
              ? (queryStringToArrayOrUndefined(currentShipTosFilter) || []).reduce(
                  (result, current) => {
                    const shipTo = deliveryDestinationsList?.find(
                      (dd) => dd?.externalId === current
                    );
                    if (shipTo && shipTo.tradingPartners) {
                      result = result.concat(
                        shipTo?.tradingPartners?.map((tp) => tp.deliveryType).filter(notEmpty)
                      );
                    }
                    return result;
                  },
                  [] as string[]
                )
              : Object.keys(DeliveryType);
            return (
              existInTradingPartnersDeliveryTypes.includes(deliveryType.name as DeliveryType) &&
              existInShipTosDeliveryTypes.includes(deliveryType.name)
            );
          }),
        lazyLoading: false,
        dependsOn: ['tradingPartners', 'deliveryDestinations']
      },
      {
        name: 'tradingPartners',
        title: 'Trading Partners',
        type: 'string',
        multiple: true,
        values: (tradingPartnerList || []).map((tradingPartner) => ({
          name: tradingPartner?.externalId,
          title: tradingPartner?.name
        })),
        lazyLoading: true,
        dependsOn: [
          'deliveryType',
          'businessUnits',
          'deliveryDestinations',
          'customers',
          'distributionCenters'
        ]
      },
      {
        name: 'distributionCenters',
        title: 'Distribution Centers',
        type: 'string',
        multiple: true,
        values: (distributionCentersList || []).map((distributionCenter) => ({
          name: distributionCenter?.externalId,
          title: `${distributionCenter?.name} - ${distributionCenter?.code}`
        })),
        lazyLoading: true,
        dependsOn: [
          'deliveryType',
          'businessUnits',
          'deliveryDestinations',
          'customers',
          'tradingPartners'
        ]
      },
      {
        name: 'customers',
        title: 'Customers',
        type: 'string',
        multiple: true,
        values: (vendorMarketList || []).map((vendorMarket) => ({
          name: vendorMarket.name,
          title: vendorMarket.name,
          id: vendorMarket.id
        })),
        lazyLoading: true,
        dependsOn: ['tradingPartners', 'deliveryDestinations', 'deliveryType', 'businessUnits']
      },
      {
        name: 'deliveryDestinations',
        title: 'Ship-To',
        type: 'string',
        multiple: true,
        additionalOptions: [FilterOperator.EXCLUDE, FilterOperator.INCLUDE],
        values: (deliveryDestinationsList || []).map((deliveryDestination) => ({
          name: deliveryDestination?.externalId,
          title: deliveryDestination?.name,
          id: deliveryDestination?.id
        })),
        lazyLoading: true,
        dependsOn: ['tradingPartners', 'customers', 'deliveryType', 'businessUnits']
      },
      { name: 'mustArriveByDate', title: 'MABD', type: 'dateRange', lazyLoading: false },
      {
        name: 'salesOrderMustArriveByDate',
        title: 'Sales Order MABD',
        type: 'dateRange',
        lazyLoading: false
      },
      { name: 'dateReceived', title: 'Order Date', type: 'dateRange', lazyLoading: false },
      { name: 'deliveryWindow', title: 'Delivery Window', type: 'dateRange', lazyLoading: false },
      {
        name: 'businessUnits',
        title: 'BU',
        type: 'string',
        multiple: true,
        values: (businessUnitsList || []).map((businessUnit) => ({
          name: businessUnit,
          title: businessUnit
        })),
        lazyLoading: true,
        dependsOn: ['tradingPartners', 'customers', 'deliveryType', 'deliveryDestinations']
      },
      {
        name: 'purchaseOrderErrors',
        title: 'Error Type',
        additionalOptions: [FilterOperator.EXCLUDE, FilterOperator.INCLUDE],
        type: 'string',
        multiple: true,
        values: Object.entries(PO_ERROR_FILTER_MAP).map((error) => ({
          name: error[0],
          title: error[1]
        })),
        lazyLoading: false
      },
      {
        name: 'purchaseOrderConfirmation',
        title: 'Confirmation',
        type: 'string',
        multiple: false,
        values: ConfirmationType.map((confirmationType) => ({
          name: confirmationType.name,
          title: confirmationType.description
        })),
        lazyLoading: false
      },
      {
        name: 'labels',
        title: 'Tags',
        additionalOptions: [FilterOperator.EXCLUDE, FilterOperator.INCLUDE],
        type: 'string',
        multiple: true,
        values: (labelsList || []).map((label) => ({
          name: label.id,
          title: label.text
        })),
        lazyLoading: true
      },
      {
        name: 'withCuts',
        title: 'PO Cuts',
        type: 'string',
        multiple: false,
        values: [
          {
            name: 'posWithCuts',
            title: 'POs with cuts'
          }
          // {
          //   name: 'posWithoutCuts',
          //   title: 'POs without cuts'
          // }
        ],
        lazyLoading: false
      },
      { name: 'shipmentsRange', title: 'Total Shipments', type: 'numberRange', lazyLoading: false }
    ],
    [
      businessUnitsList,
      currentFilters,
      deliveryDestinationsList,
      distributionCentersList,
      labelsList,
      tradingPartnerList,
      vendorMarketList
    ]
  );

  //lazy loading of drop down options is down on user focus for each select
  const onFilterValuesFocus = (fieldName: string, currentFilters: Filter[]) => {
    const filterData = searchStringToQueryFilter(stringify(filtersToLocalUpdate(currentFilters)));
    if (fieldName === 'tradingPartners' && !tradingPartnersLoading) {
      loadTradingPartners({
        variables: getTradingPartnersVariables(filterData)
      });
    }

    if (fieldName === 'distributionCenters' && !distributionCentersLoading) {
      loadDistributionCenters({
        variables: getDistributionCentersVariables(filterData)
      });
    }
    if (fieldName === 'customers' && !vendorMarketsLoading) {
      loadVendorMarkets({
        variables: getVendorMarketsVariables(filterData)
      });
    }
    if (fieldName === 'deliveryDestinations' && !deliveryDestinationsLoading) {
      loadDeliveryDestinations({
        variables: getDeliveryDestinationsVariables(filterData)
      });
    }
    // don't depends on other fields, so don't load if data was already loaded
    if (fieldName === 'businessUnits' && !businessUnitsLoading) {
      loadBusinessUnits({
        variables: getBusinessUnitsVariables(filterData)
      });
    }
    if (fieldName === 'labels' && !labelsListLoading && !labelsListData) {
      loadLabelsList();
    }
  };

  const getLoading = () => {
    const result = [];
    if (tradingPartnersLoading) {
      result.push('tradingPartners');
    }
    if (distributionCentersLoading) {
      result.push('distributionCenters');
    }
    if (deliveryDestinationsLoading) {
      result.push('deliveryDestinations');
    }
    if (vendorMarketsLoading) {
      result.push('customers');
    }
    if (businessUnitsLoading) {
      result.push('businessUnits');
    }
    return result;
  };

  const loadLazyLoadingData = useCallback(
    async (
      previous: Filter[],
      newCurrentFilters: Filter[],
      reloadAll?: boolean,
      manualReload?: Filter[]
    ) => {
      const filterData = searchStringToQueryFilter(
        stringify(filtersToLocalUpdate(newCurrentFilters))
      );

      const reload: string[] = [];

      manualReload?.forEach((filter) => {
        if (filter.name && !reload.includes(filter.name)) reload.push(filter.name);
      });

      if (previous.length < newCurrentFilters.length) {
        const addedFilter = newCurrentFilters.filter(
          (filter) => !previous.find((prevFilter) => prevFilter.name === filter.name)
        );
        addedFilter.forEach((filter) => {
          if (filter.name && filter.value) {
            reload.push(filter.name);
          }
        });
      } else if (previous.length > newCurrentFilters.length) {
        const deletedFilter = previous.find(
          (filter) => !newCurrentFilters.find((newFilter) => newFilter.name === filter.name)
        );
        if (!deletedFilter || !deletedFilter?.value || !deletedFilter?.name) return;

        filters.forEach((filter) => {
          if (filter.lazyLoading && filter.dependsOn) {
            if (
              deletedFilter &&
              deletedFilter.name &&
              deletedFilter.name !== filter.name &&
              newCurrentFilters.find((currentFilter) => currentFilter.name === filter.name) &&
              filter.dependsOn?.includes(deletedFilter.name)
            ) {
              reload.push(filter.name);
            }
          }
        });
      } else {
        const editedFilter = newCurrentFilters.find((newFilter) =>
          previous.find(
            (prevFilter) =>
              newFilter.name === prevFilter.name &&
              !isEqual(
                newFilter.value
                  ? typeof newFilter.value === 'string'
                    ? [newFilter.value]
                    : Array.isArray(newFilter.value)
                    ? newFilter.value.sort()
                    : newFilter.value
                  : [],
                prevFilter.value
                  ? typeof prevFilter.value === 'string'
                    ? [prevFilter.value]
                    : Array.isArray(prevFilter.value)
                    ? prevFilter.value.sort()
                    : prevFilter.value
                  : []
              )
          )
        );
        if (editedFilter && editedFilter.name) {
          // edited
          filters.forEach((filter) => {
            if (filter.lazyLoading && filter.dependsOn) {
              if (
                editedFilter &&
                editedFilter.name &&
                editedFilter.name !== filter.name &&
                newCurrentFilters.find((currentFilter) => currentFilter.name === filter.name) &&
                filter.dependsOn?.includes(editedFilter.name)
              ) {
                reload.push(filter.name);
              }
            }
          });
        }
      }

      if (
        newCurrentFilters.find((filter) => filter.name === 'labels') &&
        !labelsListData &&
        !labelsListLoading
      ) {
        reload.push('labels');
      }

      if (reload.includes('distributionCenters') || reloadAll) {
        loadDistributionCenters({
          variables: getDistributionCentersVariables(filterData)
        });
      }

      if (reload.includes('tradingPartners') || reloadAll) {
        loadTradingPartners({
          variables: getTradingPartnersVariables(filterData)
        });
      }

      if (reload.includes('customers') || reloadAll) {
        loadVendorMarkets({
          variables: getVendorMarketsVariables(filterData)
        });
      }

      if (reload.includes('deliveryDestinations') || reloadAll) {
        loadDeliveryDestinations({
          variables: getDeliveryDestinationsVariables(filterData)
        });
      }

      if (reload.includes('labels') || reloadAll) {
        loadLabelsList();
      }

      if (reload.includes('businessUnits') || reloadAll) {
        loadBusinessUnits({
          variables: getBusinessUnitsVariables(filterData)
        });
      }
    },
    [
      filters,
      labelsListData,
      labelsListLoading,
      loadBusinessUnits,
      loadDeliveryDestinations,
      loadLabelsList,
      loadTradingPartners,
      loadDistributionCenters,
      loadVendorMarkets,
      getBusinessUnitsVariables,
      getDeliveryDestinationsVariables,
      getTradingPartnersVariables,
      getDistributionCentersVariables
    ]
  );

  return (
    <AdvancedFilterComponent
      size={size}
      filters={filters}
      onApplyAdvancedFilter={onApplyAdvancedFilter}
      onFilterValuesFocus={onFilterValuesFocus}
      loading={getLoading()}
      searchStringToQueryFilter={searchStringToQueryFilter}
      withSavingToLocalStorage={true}
      queryFilterToFilters={queryFilterToFilters}
      handleSearch={handleOrdersFilter}
      loadLazyLoadingData={loadLazyLoadingData}
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
      setShowAdvancedFilters={setShowAdvancedFilters}
      showAdvancedFilters={showAdvancedFilters}
    />
  );
};
