import { Tooltip } from 'antd';
import React from 'react';
import s from './GapToTarget.module.scss';

const totalColor = 'rgb(232, 232, 232)';
const targetColor = 'rgb(255, 113, 125)';
const fulfilledColor = 'rgb(150, 205, 150)';

export const GapToTargetLegend = () => (
  <div className={s.legend}>
    <div className={s.section}>
      <div className={s.goal} />
      Goal Target
    </div>
    <div className={s.section}>
      <div className={s.tile} style={{ backgroundColor: totalColor }} />
      Total
    </div>
    <div className={s.section}>
      <div className={s.tile} style={{ backgroundColor: targetColor }} />
      Gap to target
    </div>
    <div className={s.section}>
      <div className={s.tile} style={{ backgroundColor: fulfilledColor }} />
      Target fulfilled
    </div>
  </div>
);

export const GapToTarget = ({ value, target = 50 }: { value: number; target: number }) => {
  const gap = target - value;
  const left = 100 - target;
  const isFulfilled = gap < 0;

  return (
    <Tooltip
      title={
        <div className={s.tooltip}>
          <div>Current: {`${value}%`}</div>
          <div>Goal target: {`${target}%`}</div>
          <div>Gap to target: {isFulfilled ? '0%' : `${gap}%`}</div>
        </div>
      }
    >
      <div className={s.gap_component}>
        <span className={s.percent}>{isFulfilled ? '0%' : `${Math.round(gap)}%`}</span>
        <div className={s.progress_container}>
          {isFulfilled ? (
            <div className={s.progress_container}>
              <div
                className={s.progress_bar}
                style={{ background: fulfilledColor, width: `${target}%` }}
              />
              <div className={s.target} />
              <div
                className={s.progress_bar}
                style={{ background: fulfilledColor, width: `calc(${left}% - 1px)` }}
              />
            </div>
          ) : (
            <div className={s.progress_container}>
              <div
                className={s.progress_bar}
                style={{ background: totalColor, width: `${value}%` }}
              />
              <div className={s.target} />
              <div
                className={s.progress_bar}
                style={{ background: targetColor, width: `${gap}%` }}
              />
              <div
                className={s.progress_bar}
                style={{ background: fulfilledColor, width: `calc(${left}% - 1px)` }}
              />
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
