import { MultipleOrdersByProductFillRateReportQuery } from 'pages/Visibility/gql/__generated__/multipleOrdersByProductFillRateReport.query';
import { ProductsFillRateReportQuery } from 'pages/Visibility/gql/__generated__/productsFillRateReport';

export type PrimeDayProduct = NonNullable<
  ProductsFillRateReportQuery['productsFillRateReport']
>['metrics'][number];

export type ProductDetails = NonNullable<
  MultipleOrdersByProductFillRateReportQuery['ordersByProductFillRateReport']
>['metrics'][number];
type SapOrderDetails = NonNullable<ProductDetails['orders']>[number];

export const mapOrderDetailsToOrderDetailsEnhanced = (
  orderDetails: SapOrderDetails,
  order: ProductDetails,
  refetch = () => {}
) => {
  const { productId, purchaseOrderId, customerName } = order;
  return {
    ...orderDetails,
    refetch,
    productId,
    purchaseOrderId,
    customerName
  };
};
// We are manually mapping these fields onto each order because backend has them 1 level higher
export type SapOrderDetailsEnhanced = ReturnType<typeof mapOrderDetailsToOrderDetailsEnhanced>;
