import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/client';
import './styles.scss';
import { dateFormat } from 'common/helpers/date';
import LoaderSpinner from 'components/LoaderSpinner';
import { DownConfirmationExpandedRows } from '../DownConfirmationExpandedRows/DownConfirmationExpandedRows';
import { DownConfirmedPurchaseOrder } from './hooks';
import { PurchaseOrderConfirmation } from 'graphql/__generated__/types';
import { SubmitPurchaseOrderConfirmationsDocument } from './gql/__generated__/submitConfirmation.mutation';
import { App } from 'ant5';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';

interface DownConfirmationTableProps {
  purchaseOrders: DownConfirmedPurchaseOrder[] | [];
  setPurchaseOrders: Dispatch<SetStateAction<DownConfirmedPurchaseOrder[]>>;
  poDataLoading: boolean;
  selectedRows: DownConfirmedPurchaseOrder[];
  setSelectedRows: Dispatch<SetStateAction<DownConfirmedPurchaseOrder[]>>;
}

export const DownConfirmationTable = ({
  poDataLoading,
  purchaseOrders,
  setPurchaseOrders,
  selectedRows,
  setSelectedRows
}: DownConfirmationTableProps) => {
  const { notification, modal: antModal } = App.useApp();
  const [submitPoConfirmation] = useMutation(SubmitPurchaseOrderConfirmationsDocument);

  const submit = async (confirmations: PurchaseOrderConfirmation[], poId: string) => {
    const ids = confirmations.map((confirmation) => confirmation.id);

    try {
      await submitPoConfirmation({
        variables: {
          input: {
            ids
          }
        }
      });

      const newPos: DownConfirmedPurchaseOrder[] = [...purchaseOrders];
      const po: DownConfirmedPurchaseOrder | undefined = newPos.find(
        (purchaseOrder) => purchaseOrder.id === poId
      );
      if (po && po.confirmation) {
        po.confirmation = { ...po.confirmation, status: 'PROCESSING' };
        po.confirmation.purchaseOrderItemConfirmations =
          po.confirmation.purchaseOrderItemConfirmations.map((item) => {
            return { ...item, status: 'PROCESSING' };
          });
      }
      setPurchaseOrders(newPos);

      notification.success({
        message: 'Success',
        description: 'Confirmation successfully submitted'
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description:
          'Unable to submit the confirmation. Please try again. An error has been logged for review.'
      });
    }
  };

  const columns = [
    {
      title: 'PO #',
      key: 'po',
      render: (po: DownConfirmedPurchaseOrder) => (
        <span style={{ fontWeight: 'bold' }}>{po.customerPo}</span>
      ),
      width: '172px',
      sorter: (a: DownConfirmedPurchaseOrder, b: DownConfirmedPurchaseOrder) =>
        (a.customerPo || '')?.localeCompare(b.customerPo || '')
    },
    {
      title: 'ASN (Received / Outstanding)',
      key: 'asn',
      render: (po: DownConfirmedPurchaseOrder) => {
        return (
          <div>
            {po.asnStatus?.received} / {po.asnStatus?.outstanding}
          </div>
        );
      },
      width: '220px'
    },
    {
      title: 'Qty (Allocated / Ordered)',
      render: (po: DownConfirmedPurchaseOrder) =>
        po.totalAcceptedQuantity === null
          ? `${po.totalOrderedQuantity} / ${po.totalOrderedQuantity}`
          : `${po.totalAcceptedQuantity} / ${po.totalOrderedQuantity}`,
      width: '200px'
    },
    {
      title: 'Delivery Window',
      render: (po: DownConfirmedPurchaseOrder) =>
        po.deliveryWindowStart && po.deliveryWindowEnd
          ? `${dateFormat(po.deliveryWindowStart)} - ${dateFormat(po.deliveryWindowEnd)} `
          : '',
      width: '200px'
    },
    {
      title: '',
      render: (po: DownConfirmedPurchaseOrder) => {
        const generated = po.allConfirmations.filter(
          (confirmation) => confirmation.status.toUpperCase() === 'GENERATED'
        );

        const disabled = po.confirmation.status !== 'GENERATED';
        return (
          <AlloyButton
            data-testid="purchase-order-confirm-button"
            disabled={disabled}
            className={`${
              !disabled && po.asnStatus?.outstanding === 0
                ? 'down-confirmation-modal-green-button'
                : ''
            }`}
            type="primary"
            onClick={() => {
              antModal.confirm({
                title: 'Down Confirm all POs',
                // @ts-ignore
                'data-testid': 'down-confirmation-modal-single-confirmation',
                content: `The action you are about to perform will impact PO: ${
                  po.customerPo
                } and ${
                  generated
                    .map(({ purchaseOrderItemConfirmations }) => purchaseOrderItemConfirmations)
                    .flat().length
                } products.
          Are you sure you want to proceed?`,
                okText: 'Confirm',
                okButtonProps: {
                  // @ts-ignore
                  'data-testid': 'yes-continue-button'
                },
                cancelText: 'Cancel',
                cancelButtonProps: {
                  // @ts-ignore
                  'data-testid': 'cancel-button'
                },
                onOk: () => {
                  submit(generated, po.id);
                }
              });
            }}
          >
            {disabled ? 'Submitted' : 'Confirm'}
          </AlloyButton>
        );
      }
    }
  ];

  return poDataLoading ? (
    <LoaderSpinner />
  ) : (
    <AlloyTable
      data-testid="down_confirmation_table"
      className="down_confirmation_table"
      sticky
      rowKey={(record) => record.id}
      rowSelection={{
        onChange: (keys, rows) => {
          setSelectedRows(rows);
        },
        type: 'checkbox',
        selectedRowKeys: selectedRows.map((row: DownConfirmedPurchaseOrder) => row.id)
      }}
      pagination={false}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => {
          return <DownConfirmationExpandedRows confirmation={record.confirmation} />;
        }
      }}
      dataSource={purchaseOrders}
    />
  );
};
