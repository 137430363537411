import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { uniq } from 'lodash-es';
import { DistributionCenter } from 'pages/AssortmentPage/AssortmentPage';
import React from 'react';

interface RddsListProps {
  activeDcIds: string[];
  firstDcId: string;
  tpDcs: DistributionCenter[];
}

const getListByIds = (tpDcs: DistributionCenter[], firstDcId: string, activeDcIds: string[]) => {
  return uniq(
    activeDcIds
      .sort((id1, id2) => (id1 === firstDcId ? -1 : 1))
      .map((dcId) => {
        const found = tpDcs.find((dc) => dc.id === dcId);
        return `${found?.name || ''} ${found?.code || ''}`;
      })
  );
};

export const DcsList = ({ firstDcId, tpDcs, activeDcIds }: RddsListProps) => (
  <div>
    <ListOfItemsColumn items={getListByIds(tpDcs, firstDcId, activeDcIds)} />
  </div>
);
