import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import s from './InvalidItemsTableDSD.module.scss';
import { App } from 'ant5';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { MoreOutlined } from '@ant-design/icons';
import errorIcon from '../../../../assets/icons/common/error_icon.svg';
import { PurchaseOrderItem, PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';
import {
  POI_ERROR_MAP,
  PO_STATUS_ACKNOWLEDGED,
  PO_STATUS_EXPORTED,
  PO_STATUS_NEW,
  PO_STATUS_SCRUBBED,
  PO_STATUS_SUBMITTED
} from 'common/constants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { TradingPartnerActiveAssortmentItem } from 'common/interfaces';
import PurchaseOrderQuantityUpdate from '../PurchaseOrderQuantityUpdate';
import {
  UpdateQuantityDsdDocument,
  UpdateQuantityDsdMutationVariables
} from '../PurchaseOrderItemsTableDSD/gql/__generated__/updateQuantityDSD.mutation';
import { AlignType } from 'common/types';

interface InvalidItemsTableDSDProps {
  loading: boolean;
  invalidItems: PurchaseOrderItem[];
  purchaseOrder: PurchaseOrderWithOverdue;
  isConditionalColumnsEnabled: boolean | undefined;
  openNewItemModal: (
    item?: Partial<TradingPartnerActiveAssortmentItem>,
    predefinedValues?: Partial<TradingPartnerActiveAssortmentItem>
  ) => void;
}

type ToUpdate = NonNullable<UpdateQuantityDsdMutationVariables['input']['toUpdate']>;

export const InvalidItemsTableDSD = ({
  loading,
  invalidItems,
  purchaseOrder,
  isConditionalColumnsEnabled,
  openNewItemModal
}: InvalidItemsTableDSDProps) => {
  const { message } = App.useApp();
  const { statuses, businessUnit, capacityCubicFeetBoh } = purchaseOrder;

  const [updateQuantity] = useMutation(UpdateQuantityDsdDocument, {
    refetchQueries: ['purchaseOrderItems'],
    onCompleted: () => {
      message.success('Processing complete!');
    }
  });

  const updatePurchaseOrderSingleQuantity = useCallback(
    (itemToUpdate: ToUpdate) => {
      if (itemToUpdate) {
        return async (itemToUpdate: ToUpdate) => {
          await updateQuantity({ variables: { input: { toUpdate: itemToUpdate } } });
        };
      }
    },
    [updateQuantity]
  );

  const columns: ColumnsType<PurchaseOrderItem> = useMemo(
    () => [
      {
        dataIndex: 'product',
        width: '44px',
        render: (_, item) => (
          <>
            {!!item.purchaseOrderItemErrors.length && (
              <div data-testid="error-display">
                <AlloyTooltip
                  data-testid="error-display-tooltip"
                  placement="right"
                  title={item.purchaseOrderItemErrors
                    .map((error) => POI_ERROR_MAP[error] || error)
                    .join(', ')}
                >
                  <img data-testid="error-icon" src={errorIcon} alt="" />
                </AlloyTooltip>
              </div>
            )}
          </>
        )
      },
      {
        title: 'GTIN',
        dataIndex: ['catalogProduct', 'gtin12'] || ['product', 'upc'],
        width: '160px',
        render: (_, item) => {
          if (item.catalogProduct?.gtin12) {
            return item.catalogProduct?.gtin12;
          } else {
            return item.product?.upc;
          }
        },
        sorter: (a, b) => {
          if (a?.catalogProduct) {
            return safeLocaleCompare(a?.catalogProduct?.gtin12, b?.catalogProduct?.gtin12);
          } else {
            return safeLocaleCompare(a?.product?.upc, b?.product?.upc);
          }
        }
      },
      {
        title: 'EXTERNAL ID',
        dataIndex: 'externalId',
        width: '130px',
        render: (_, item) => {
          const hasErrors =
            item?.purchaseOrderItemErrors && item.purchaseOrderItemErrors.length > 0;

          return (
            <div className={!hasErrors ? s.externalId : s.externalId_with_error}>
              {item.externalId}
            </div>
          );
        },
        sorter: (a, b) => safeLocaleCompare(a?.externalId, b?.externalId)
      },
      {
        title: 'ITEM DESCRIPTION',
        dataIndex: ['catalogProduct', 'name'] || ['product', 'name'],
        render: (_, item) => {
          if (item.catalogProduct?.name) {
            return item.catalogProduct?.name;
          } else {
            return item.product?.name;
          }
        },
        sorter: (a, b) => {
          if (a?.catalogProduct) {
            return safeLocaleCompare(a?.catalogProduct?.name, b?.catalogProduct?.name);
          } else {
            return safeLocaleCompare(a?.product?.name, b?.product?.name);
          }
        }
      },
      {
        title: 'BU',
        dataIndex: 'businessUnit',
        width: '100px',
        render: (_, item) => businessUnit?.code
      },
      {
        title: 'ORDER QTY',
        key: 'quantityOrdered',
        width: '103px',
        align: 'left' as AlignType,
        render: (_, item) => {
          if (isConditionalColumnsEnabled) {
            const hasError =
              item.purchaseOrderItemErrors && item.purchaseOrderItemErrors.length > 0;
            return [
              PO_STATUS_NEW,
              PO_STATUS_EXPORTED,
              PO_STATUS_SCRUBBED,
              PO_STATUS_ACKNOWLEDGED
            ].includes((statuses?.primary || '').toUpperCase()) ? (
              <div className={item?.vendorProduct && !hasError ? '' : s.item_error}>
                <PurchaseOrderQuantityUpdate
                  updatePurchaseOrderSingleQuantity={updatePurchaseOrderSingleQuantity}
                  itemId={item.id}
                  productId={item.product?.id || ''}
                  quantity={
                    item.quantityOrdered !== null ? (item.quantityOrdered || 0).toString() : '0'
                  }
                  unit={item?.submittedUnitOfMeasure || ''}
                />
              </div>
            ) : item.quantityOrdered != null ? (
              `${item.quantityOrdered} ${item?.submittedUnitOfMeasure}`
            ) : (
              0
            );
          } else if (item.quantityOrdered != null) {
            return `${item.quantityOrdered} ${item?.submittedUnitOfMeasure}`;
          } else {
            return 0;
          }
        }
      },
      {
        width: '46px',
        render: (_, item) =>
          (item.purchaseOrderItemErrors.includes('PRODUCT_NOT_FOUND') ||
            item.purchaseOrderItemErrors.includes('PRODUCT_NOT_AVAILABLE_FOR_SALE')) &&
          (purchaseOrder.statuses?.primary || '').toUpperCase() !== PO_STATUS_SUBMITTED ? (
            <AlloyDropdown
              placement="bottomRight"
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: '0',
                    label: item.purchaseOrderItemErrors.includes('PRODUCT_NOT_FOUND')
                      ? 'Add new product to assortment'
                      : 'Add to assortment',
                    onClick: () => {
                      openNewItemModal(undefined, {
                        retailerDeliveryDestinations:
                          purchaseOrder.tradingPartner?.retailerDeliveryDestinations,
                        vendorProduct: {
                          externalId: item.externalId
                        }
                      } as Partial<TradingPartnerActiveAssortmentItem>);
                    }
                  }
                ]
              }}
            >
              <MoreOutlined data-testid="more-icon" />
            </AlloyDropdown>
          ) : null
      }
    ],
    [
      businessUnit?.code,
      isConditionalColumnsEnabled,
      openNewItemModal,
      purchaseOrder.statuses?.primary,
      purchaseOrder.tradingPartner?.retailerDeliveryDestinations,
      statuses?.primary,
      updatePurchaseOrderSingleQuantity
    ]
  );

  const updatedColumns = useMemo(() => {
    if (isConditionalColumnsEnabled) {
      columns.splice(5, 0, {
        title: (
          <>
            <div>VOLUME</div>
            <span className={s.subtitle}>
              MAX: {Number(capacityCubicFeetBoh).toFixed(2) || '-'}
            </span>
          </>
        ),
        key: 'capacity',
        width: '110px',
        render: (_, { products, quantityOrdered }) => {
          const volume = (Number(products[0]?.cubicFeet) * (quantityOrdered || 0)).toFixed(2);
          return `${products[0]?.cubicFeet === undefined ? '-' : volume} CFT`;
        }
      });

      columns.splice(7, 0, {
        title: 'NO. OF PALLETS',
        key: 'numberOfPallets',
        width: '100px',
        render: (_, item) =>
          (item?.quantityOrdered || 0) > 0 && item?.product?.quantityPerPallet
            ? ((item?.quantityOrdered || 0) / item?.product?.quantityPerPallet).toFixed(1)
            : 0
      });
    }
    return columns;
  }, [capacityCubicFeetBoh, columns, isConditionalColumnsEnabled]);

  return (
    <>
      <div data-testid="invalid-items-count-dsd" className={s.quantity_display}>
        INVALID ITEMS ({invalidItems.length})
      </div>
      <AlloyTable
        data-testid="invalid-items-table-dsd"
        className={s.table}
        loading={loading}
        dataSource={invalidItems}
        columns={updatedColumns}
        rowKey={(item) => item.id}
        sticky
        tableLayout="auto"
        pagination={false}
      />
    </>
  );
};
