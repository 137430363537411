import * as Types from '../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SplitSalesOrderItemMutationVariables = Types.Exact<{
  input: Types.SplitSalesOrderItemInput;
}>;


export type SplitSalesOrderItemMutation = { __typename?: 'RootMutationType', splitSalesOrderItem?: { __typename?: 'SplitSalesOrderItemPayload', salesOrderItems: Array<{ __typename?: 'SalesOrderItem', id: string, externalId?: string | null, salesOrder: { __typename?: 'SalesOrder', id: string } }> } | null };


export const SplitSalesOrderItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"splitSalesOrderItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SplitSalesOrderItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"splitSalesOrderItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"salesOrderItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"salesOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<SplitSalesOrderItemMutation, SplitSalesOrderItemMutationVariables>;