import { SourcingRule } from 'pages/TradingPartnersPage/components/TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import React, { useMemo, useState } from 'react';
import s from './SourcingRules.module.scss';
import clsx from 'clsx';
import { DrawerTitle } from 'components/Common/ViewMode/DrawerTitle/DrawerTitle';
import { AlloyDrawer } from 'components/ui/AlloyDrawer/AlloyDrawer';

export const SourcingRules = ({
  sourcingRules,
  defaultSourcingRules
}: {
  sourcingRules: SourcingRule[];
  defaultSourcingRules: SourcingRule[];
}) => {
  const [showAllSourcingRules, setShowSourcingRules] = useState(false);
  const allSourcingRules = useMemo(
    () => [...sourcingRules, ...defaultSourcingRules],
    [sourcingRules, defaultSourcingRules]
  );

  const sourcingRule = (rule: SourcingRule, showBottomBorder: boolean) => (
    <div
      key={rule.id}
      className={clsx(s.sourcing_rule, { [s.remove_bottom_border]: !showBottomBorder })}
    >
      <div className={s.sourcing_rule_priority}>{rule.priority}</div>
      <div>
        <div className={s.sourcing_rule_title}>Description</div>
        <div className={s.sourcing_rule_text}>{rule.description}</div>
        <div className={s.sourcing_rule_text}>
          <span className={s.sourcing_rule_title}>DC</span>
          {rule.distributionCenter?.name || '-'}
        </div>
        <div className={s.sourcing_rule_text}>
          <span className={s.sourcing_rule_title}>Weight Limit</span>
          {rule.minWeightLbs || rule.maxWeightLbs
            ? `${rule.minWeightLbs ?? 0} - ${rule.maxWeightLbs ?? '...'}`
            : '-'}
        </div>
        <div className={s.sourcing_rule_text}>
          <span className={s.sourcing_rule_title}>Release Method</span>
          {rule.releaseMethod}
        </div>
      </div>
    </div>
  );

  return (
    <div className={s.margin_bottom}>
      {allSourcingRules
        .sort((rule1, rule2) => rule1.priority - rule2.priority)
        .map((rule, index) =>
          index < 4 ? sourcingRule(rule, index !== Math.min(allSourcingRules.length, 4) - 1) : null
        )}
      {!showAllSourcingRules && allSourcingRules.length > 4 && (
        <div className={s.button_container}>
          <button onClick={() => setShowSourcingRules(true)}>See more</button>
        </div>
      )}
      <AlloyDrawer
        open={showAllSourcingRules}
        width={540}
        closable={false}
        onClose={() => setShowSourcingRules(false)}
        styles={{
          body: { padding: 0 }
        }}
        title={
          <DrawerTitle
            title="Release Rules"
            onBack={() => setShowSourcingRules(false)}
            onCancel={() => setShowSourcingRules(false)}
          />
        }
        className={s.drawer}
      >
        <div className={s.container}>
          {allSourcingRules
            .sort((rule1, rule2) => rule1.priority - rule2.priority)
            .map((rule, index) => sourcingRule(rule, index !== allSourcingRules.length - 1))}
        </div>
      </AlloyDrawer>
    </div>
  );
};
