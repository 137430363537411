import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpsertTradingPartnerActiveAssortmentMutationVariables = Types.Exact<{
  input: Types.UpsertTradingPartnerActiveAssortmentInput;
}>;


export type UpsertTradingPartnerActiveAssortmentMutation = { __typename?: 'RootMutationType', upsertTradingPartnerActiveAssortment?: { __typename?: 'UpsertTradingPartnerActiveAssortmentPayload', impactedPurchaseOrders?: Array<string> | null } | null };


export const UpsertTradingPartnerActiveAssortmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"upsertTradingPartnerActiveAssortment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertTradingPartnerActiveAssortmentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertTradingPartnerActiveAssortment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"impactedPurchaseOrders"}}]}}]}}]} as unknown as DocumentNode<UpsertTradingPartnerActiveAssortmentMutation, UpsertTradingPartnerActiveAssortmentMutationVariables>;