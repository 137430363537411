import { Divider, message } from 'antd';
import React, { useRef } from 'react';
import { UpsertVendorMarketInput } from 'graphql/__generated__/types';
import { FinalFormSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormSelect';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { AddVendorForm } from 'pages/RetailersPage/components/AddVendorForm/AddVendorForm';
import {
  composeValidators,
  getMaxLengthValidation,
  validateRequired
} from 'components/Common/fields/Utils';
import { CountryCodes, CurrencyCodes } from 'common/constants';
import { FinalFormInput } from 'components/FinalFormFields/FinalFormInput/FinalFormInput';
import { FormApi } from 'final-form';
import { useMutation, useQuery } from '@apollo/client';
import { EditVendorMarketInitDataDocument } from '../../gql/__generated__/editVendorMarketInitData.query';
import { UpsertVendorMarketDocument } from '../../gql/__generated__/upsertVendorMatket.mutation';
import LoaderSpinner from 'components/LoaderSpinner';
import { Form } from 'react-final-form';
import { Button } from 'components/alloy/Button/Button';
import s from './EditRetailerChannelModal.module.scss';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';

interface EditVendorMarketFormProps {
  vendorMarket?: UpsertVendorMarketInput;
  onClose: (id?: string) => void;
}

export const EditRetailerChannelModal = ({ onClose, vendorMarket }: EditVendorMarketFormProps) => {
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const { data: initData, loading } = useQuery(EditVendorMarketInitDataDocument, {
    skip: !vendorMarket
  });
  const [upsertVendorMarket, { loading: upserting }] = useMutation(UpsertVendorMarketDocument, {
    refetchQueries: ['vendorMarkets', 'businessUnitsAndVendorMarkets']
  });

  if (loading && !initData) return <LoaderSpinner />;

  const submitForm = async (values: any, form: FormApi<any, any>) => {
    try {
      const { data } = await upsertVendorMarket({
        variables: {
          input: values
        }
      });
      form.reset();
      form.resetFieldState('vendorId');
      form.resetFieldState('countryCode');
      form.resetFieldState('currencyCode');
      form.resetFieldState('externalId');
      form.resetFieldState('name');
      message.success(`Retailer Channel successfully ${vendorMarket?.id ? 'updated' : 'added'}.`);
      onClose(data?.upsertVendorMarket?.vendorMarket?.id);
    } catch (e) {
      console.log({ e });
      message.error(`Error happened during Retailer Channel saving. ${e.message}`);
    }
  };

  return (
    <FullScreenEditingModal
      title={`${vendorMarket?.id ? 'Edit' : 'Add'} Retailer Channel`}
      open={!!vendorMarket}
      onClose={() => onClose()}
      buttons={
        <>
          <Button onClick={() => onClose()} type="secondary">
            Cancel
          </Button>
          <Button
            ref={addButtonRef}
            buttonType="submit"
            onClick={() => {
              document
                ?.getElementById('edit-retailer-channel-modal-form')
                ?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          >
            {vendorMarket?.id ? 'Save' : 'Add'}
          </Button>
        </>
      }
    >
      <Form
        initialValues={{
          name: vendorMarket?.name || '',
          vendorId: vendorMarket?.vendorId || null,
          externalId: vendorMarket?.externalId || null,
          id: vendorMarket?.id || null,
          countryCode: vendorMarket?.countryCode || '',
          currencyCode: vendorMarket?.currencyCode || '',
          parentVendorMarketId: vendorMarket?.parentVendorMarketId
        }}
        mutators={{
          setVendorId: (args, state, utils) => {
            utils.changeValue(state, 'vendorId', () => args[0]);
          }
        }}
        onSubmit={submitForm}
        render={({ handleSubmit, form }) => (
          <form
            id="edit-retailer-channel-modal-form"
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  // do nothing
                });
              return promise;
            }}
          >
            <FinalFormInput
              name="externalId"
              title="External ID"
              validate={validateRequired}
              required
            />
            <FinalFormInput name="name" title="Name" validate={validateRequired} required />
            <FinalFormSelect
              name="countryCode"
              label="Country Code"
              options={Object.keys(CountryCodes).map((countryCode) => ({
                value: countryCode,
                label: countryCode
              }))}
              validate={composeValidators([validateRequired, getMaxLengthValidation(2)])}
              required
            />
            <FinalFormSelect
              name="currencyCode"
              label="Currency Code"
              options={Object.keys(CurrencyCodes).map((countryCode) => ({
                value: countryCode,
                label: countryCode
              }))}
              validate={composeValidators([validateRequired, getMaxLengthValidation(3)])}
              required
            />
            <FinalFormSelect
              name="vendorId"
              label="Retailer"
              options={getNodesFromEdges(initData?.vendors).map((vendor) => ({
                value: vendor.id,
                label: vendor.name
              }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <AddVendorForm
                    onVendorAdded={(id) => {
                      form.mutators.setVendorId(id);
                      addButtonRef.current?.focus();
                    }}
                  />
                </>
              )}
              validate={validateRequired}
              required
            />
            <FinalFormSelect
              name="parentVendorMarketId"
              label="Parent Retailer Channel"
              options={getNodesFromEdges(initData?.vendorMarkets).map((vendorMarket) => ({
                value: vendorMarket.id,
                label: vendorMarket.name
              }))}
              allowClear
            />
          </form>
        )}
      />
    </FullScreenEditingModal>
  );
};
