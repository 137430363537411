import React from 'react';
import { useMutation } from '@apollo/client';
import s from './CancelPurchaseOrder.module.scss';
import { Modal, notification } from 'antd';
import LoaderSpinner from 'components/LoaderSpinner';
import { CancelPurchaseOrdersDocument } from 'components/PurchaseOrders/gql/__generated__/cancelPurchaseOrders.mutation';
import { PurchaseOrderRefetchQueries } from 'common/constants';

interface CancelPurchaseOrderModalProps {
  purchaseOrderId: string;
  customerPO: string;
  toggleCancelPurchaseOrderModal: () => void;
  visibility: boolean;
}

const CancelPurchaseOrderModal = ({
  purchaseOrderId,
  customerPO,
  toggleCancelPurchaseOrderModal,
  visibility
}: CancelPurchaseOrderModalProps) => {
  const [cancelPO, { loading }] = useMutation(CancelPurchaseOrdersDocument, {
    refetchQueries: PurchaseOrderRefetchQueries,
    variables: {
      input: { purchaseOrderIds: [purchaseOrderId] }
    },
    onCompleted: (data) => {
      notification.success({
        message: 'Purchase order successfully cancelled'
      });
      toggleCancelPurchaseOrderModal();
    },
    onError: (error) => {
      notification.error({
        message: 'An error occurred while cancelling purchase order',
        description: error.message
      });
      toggleCancelPurchaseOrderModal();
    }
  });

  const displayPO = () => {
    return (
      <>
        {purchaseOrderId ? (
          <>
            <div data-testid="cancel-po-description" className={s.cancel_po_description}>
              {'The following purchase order will be cancelled:'}
            </div>
            <div data-testid="cancel-po-customer-po" className={s.cancel_po_customer_po}>
              {customerPO}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const onConfirm = (confirmed: boolean) => {
    if (purchaseOrderId && confirmed) {
      cancelPO();
    } else {
      toggleCancelPurchaseOrderModal();
    }
  };

  return (
    <Modal
      centered
      data-testid="po-details-cancel-po-modal"
      title={<span data-testid="po-details-cancel-po-modal-title">Cancel Purchase Order</span>}
      open={visibility}
      okText={<span data-testid="cancel-po-confirm-button">Yes, Continue</span>}
      okButtonProps={{ disabled: !purchaseOrderId }}
      onOk={() => onConfirm(true)}
      onCancel={() => onConfirm(false)}
      destroyOnClose={true}
    >
      {loading ? <LoaderSpinner /> : <>{displayPO()}</>}
    </Modal>
  );
};

export default CancelPurchaseOrderModal;
