import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChargebackTotalsReportQueryVariables = Types.Exact<{
  dimension: Types.ChargebackDimension;
  filters: Types.ExecutiveReportingFilters;
}>;


export type ChargebackTotalsReportQuery = { __typename?: 'RootQueryType', chargebackTotalsReport: Array<{ __typename?: 'ChargebackDimensionReport', dimensionValue?: string | null, financialCharge: any }> };


export const ChargebackTotalsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"chargebackTotalsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dimension"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChargebackDimension"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargebackTotalsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dimension"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dimension"}}},{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dimensionValue"}},{"kind":"Field","name":{"kind":"Name","value":"financialCharge"}}]}}]}}]} as unknown as DocumentNode<ChargebackTotalsReportQuery, ChargebackTotalsReportQueryVariables>;