export const capitalizeFirstLetter = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const fieldNameToCapitalize = (name: string) =>
  name
    .split('_')
    .reduce(
      (result: string, part: string, currentIndex: number) =>
        result.concat(currentIndex === 0 ? part.toLowerCase() : capitalizeFirstLetter(part)),
      ''
    );

export const fieldNameToString = (name: string) =>
  name
    .split('_')
    .map((part: string, currentIndex: number) =>
      currentIndex === 0 ? capitalizeFirstLetter(part) : part.toLowerCase()
    )
    .join(' ');

export const camelCaseToSpaces = (text: string) =>
  (text || '').replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

export const acronymsToUpperCase = (text: string) =>
  text
    .split(' ')
    .reduce((result, current) => {
      let partValue;
      switch (current.toLowerCase()) {
        case 'ids':
          partValue = 'IDs';
          break;
        case 'upcs':
          partValue = 'UPCs';
          break;
        case 'id':
        case 'sap':
        case 'upc':
        case 'och':
        case 'edi':
        case 'po':
        case 'uom':
        case 'moq':
        case 'duns':
        case 'cof':
        case 'cis':
        case 'bu':
        case 'isa':
        case 'gs':
        case 'dc':
        case 'sftp':
        case 'gtin14':
        case 'gtin12':
          partValue = current.toUpperCase();
          break;
        case 'inven':
          partValue = 'Inventory';
          break;
        default:
          partValue = current;
      }
      return `${result} ${partValue}`;
    }, '')
    .trim();
