import React, { useMemo, useRef, useState } from 'react';
import { AlignType } from 'common/types';
import { CSVLink } from 'react-csv';
import s from './ExportSoItemsModal.module.scss';
import { dateFormat } from 'common/helpers/date';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import { PosWithSalesOrdersDocument } from './gql/__generated__/posWithSalesOrders.query';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { GetSalesOrdersForExportDocument } from './gql/__generated__/getSalesOrdersForExport.query';
import { SoExportItem } from 'graphql/__generated__/types';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import { SO_EXPORT_ITEMS } from 'common/featureFlags';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { safeLocaleCompare } from 'common/helpers/comparators';

interface ExportSoItemsModalProps {
  visibility: boolean;
  toggleModal: () => void;
  selectedRowIds: string[];
}

/********************************   OLD CSV HEADER LOGIC  ******************************************/
interface SOExportItem {
  lineNumber: string;
  soldTo: string;
  shipTo: string;
  poId: string;
  rdd: string;
  soIndex: number;
  sapMaterialId: string;
  asin: string;
  upc: string;
  description: string;
  qty: number;
  warehouse: string;
  weight: number;
  key: string;
  price: string;
  currency: string;
}

const oldSOItemHeaders: { label: string; key: keyof SOExportItem }[] = [
  { label: 'Line #', key: 'lineNumber' },
  { label: 'Sold To #', key: 'soldTo' },
  { label: 'Ship-To #', key: 'shipTo' },
  { label: 'RDD', key: 'rdd' },
  { label: 'Truck #', key: 'soIndex' },
  { label: 'SAP Mat #', key: 'sapMaterialId' },
  { label: 'Allocated Qty', key: 'qty' },
  { label: 'PO #', key: 'poId' },
  { label: 'External ID', key: 'asin' },
  { label: 'UPC', key: 'upc' },
  { label: 'Description', key: 'description' },
  { label: 'Weight', key: 'weight' },
  { label: 'Warehouse', key: 'warehouse' },
  { label: 'Price', key: 'price' },
  { label: 'Currency', key: 'currency' }
];
/**************************************************************************************************/

/********************************   NEW CSV HEADER LOGIC  ******************************************/
const newSOItemHeaders: { label: string; key: keyof SoExportItem }[] = [
  { label: 'Line #', key: 'line' },
  { label: 'Sold To #', key: 'soldTo' },
  { label: 'Ship-To #', key: 'shipTo' },
  { label: 'RDD', key: 'rdd' },
  { label: 'Truck #', key: 'truck' },
  { label: 'SAP Mat #', key: 'sapMaterialId' },
  { label: 'Allocated Qty', key: 'allocatedQty' },
  { label: 'PO #', key: 'customerPo' },
  { label: 'External ID', key: 'externalId' },
  { label: 'UPC', key: 'upc' },
  { label: 'Description', key: 'description' },
  { label: 'Weight', key: 'weight' },
  { label: 'Warehouse', key: 'dcCode' },
  { label: 'Price', key: 'price' },
  { label: 'Currency', key: 'currency' }
];
/**************************************************************************************************/

const ExportSoItemsModal = ({
  visibility,
  toggleModal,
  selectedRowIds
}: ExportSoItemsModalProps) => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const { enabled: isSOExportItemsEnabled } = useFeatureFlag({
    name: SO_EXPORT_ITEMS
  });

  /********************************   OLD CSV DATA LOGIC  ******************************************/
  const { data, loading } = useQuery(PosWithSalesOrdersDocument, {
    variables: {
      ids: selectedRowIds,
      first: 1000
    },
    skip: isSOExportItemsEnabled || !visibility || selectedRowIds.length === 0
  });

  const oldCsvData = useMemo(() => {
    if (!data) return [];
    let items: SOExportItem[] = [];
    for (const item of data?.purchaseOrders?.edges || []) {
      const purchaseOrder = item.node;
      if (!purchaseOrder?.salesOrders) continue;
      let localItems: SOExportItem[] = [];
      purchaseOrder.salesOrders.forEach((salesOrder, soIndex) => {
        if (!salesOrder.salesOrderItems) return;
        for (const salesOrderItem of salesOrder.salesOrderItems) {
          const poItem = purchaseOrder.purchaseOrderItems.find(
            (poItem) => poItem.externalId === salesOrderItem.externalId
          );

          const weight =
            (Number(salesOrderItem.weightInLbs) || 0) + (salesOrderItem?.palletsWeightInLbs || 0);

          localItems.push({
            lineNumber: salesOrderItem.alternateLineItem || '',
            soldTo: purchaseOrder.retailerDeliveryDestination?.sapCustomerId || '',
            shipTo: purchaseOrder.retailerDeliveryDestination?.externalId || '',
            poId: purchaseOrder.customerPo || '',
            rdd: purchaseOrder?.orderDate ? dateFormat(purchaseOrder?.deliveryWindowEnd) : '',
            soIndex: soIndex + 1,
            key: `${purchaseOrder.id}_${salesOrder.id}_${salesOrderItem.id}`,
            sapMaterialId:
              poItem?.vendorProduct?.substitutionProducts[0]?.product?.sapMaterialId || '',
            asin: salesOrderItem.externalId || '',
            upc: salesOrderItem.product?.upc || '',
            description: salesOrderItem.product?.name || '',
            qty: salesOrderItem.acceptedUnits || 0,
            warehouse: salesOrder?.distributionCenter?.code || '',
            weight: weight ? Math.ceil(weight) : 0,
            price: Number(salesOrderItem?.unitPrice).toFixed(2),
            currency: purchaseOrder?.tradingPartner?.vendorMarket?.currencyCode || ''
          });

          localItems.sort((a, b) => {
            if (a.lineNumber < b.lineNumber) return -1;
            else if (a.lineNumber > b.lineNumber) return 1;
            else return 0;
          });
        }
      });
      items = items.concat(localItems);
    }

    items.sort((a, b) => {
      if (a.sapMaterialId < b.sapMaterialId) return -1;
      else if (a.sapMaterialId > b.sapMaterialId) return 1;
      else return 0;
    });

    return items;
  }, [data]);

  const oldColumns: ColumnsType<SOExportItem> = [
    {
      title: 'Line #',
      dataIndex: 'lineNumber',
      align: 'center' as AlignType
    },
    {
      title: 'Sold To #',
      dataIndex: 'soldTo',
      align: 'center' as AlignType
    },
    {
      title: 'Ship-To #',
      dataIndex: 'shipTo',
      align: 'center' as AlignType
    },
    {
      title: 'RDD',
      dataIndex: 'rdd',
      align: 'center' as AlignType
    },
    {
      title: 'Truck #',
      dataIndex: 'soIndex',
      align: 'center' as AlignType
    },
    {
      title: 'SAP Mat #',
      dataIndex: 'sapMaterialId',
      align: 'center' as AlignType
    },
    {
      title: 'Allocated Qty',
      dataIndex: 'qty',
      align: 'center' as AlignType
    },
    {
      title: 'PO #',
      dataIndex: 'customerPo',
      align: 'center' as AlignType
    },
    {
      title: 'External ID',
      dataIndex: 'externalId'
    },
    {
      title: 'UPC',
      dataIndex: 'upc'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Weight (lbs)',
      dataIndex: 'weight',
      align: 'center' as AlignType
    },
    {
      title: 'Warehouse',
      dataIndex: 'dcCode',
      align: 'center' as AlignType
    },
    {
      title: 'Price',
      dataIndex: 'price'
    },
    {
      title: 'Currency',
      dataIndex: 'currency'
    }
  ];
  /**************************************************************************************************/

  /********************************   NEW CSV DATA LOGIC  ******************************************/
  const {
    error: exportSOError,
    loading: exportSOLoading,
    data: exportSOData
  } = useQuery(GetSalesOrdersForExportDocument, {
    variables: {
      purchaseOrderIds: selectedRowIds
    },
    skip: !isSOExportItemsEnabled || !visibility
  });

  const newCsvData: SoExportItem[] = useMemo(() => {
    if (exportSOData) {
      let newMappedData: SoExportItem[] = [];

      exportSOData.soExportItems?.items.forEach((soToExport) => {
        newMappedData.push(soToExport);
      });

      newMappedData = newMappedData.sort((a, b) => safeLocaleCompare(a.customerPo, b.customerPo));

      const formattedData = [...newMappedData].map((item) => ({
        ...item,
        rdd: dateFormat(item.rdd)
        // price: Number(item.price).toFixed(2)
      }));

      return formattedData;
    } else {
      return [];
    }
  }, [exportSOData]);

  if (!exportSOLoading && !newCsvData && !exportSOError) return null;
  if (exportSOLoading) return <LoaderSpinner />;
  if (exportSOError)
    return (
      <div>
        <ErrorDisplay error={exportSOError} />
      </div>
    );

  const newColumns: ColumnsType<SoExportItem> = [
    {
      title: 'Line #',
      dataIndex: 'line',
      align: 'center' as AlignType
    },
    {
      title: 'Sold To #',
      dataIndex: 'soldTo',
      align: 'center' as AlignType
    },
    {
      title: 'Ship-To #',
      dataIndex: 'shipTo',
      align: 'center' as AlignType
    },
    {
      title: 'RDD',
      key: 'rdd',
      align: 'center' as AlignType,
      render: (_, soItem) => dateFormat(soItem.rdd) || ''
    },
    {
      title: 'Truck #',
      dataIndex: 'truck',
      align: 'center' as AlignType
    },
    {
      title: 'SAP Mat #',
      dataIndex: 'sapMaterialId',
      align: 'center' as AlignType
    },
    {
      title: 'Allocated Qty',
      dataIndex: 'allocatedQty',
      align: 'center' as AlignType
    },
    {
      title: 'PO #',
      dataIndex: 'customerPo',
      align: 'center' as AlignType
    },
    {
      title: 'External ID',
      dataIndex: 'externalId'
    },
    {
      title: 'UPC',
      dataIndex: 'upc'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Weight (lbs)',
      dataIndex: 'weight',
      align: 'center' as AlignType
    },
    {
      title: 'Warehouse',
      dataIndex: 'dcCode',
      align: 'center' as AlignType
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, soItem) => Number(soItem.price).toFixed(2)
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
    }
  ];
  /**************************************************************************************************/

  const controls = [
    <AlloyButton key="cancel" className="filled_grey_btn" onClick={() => toggleModal()}>
      Cancel
    </AlloyButton>,

    isSOExportItemsEnabled ? (
      <AlloyButton
        key="export"
        className="filled_blue_btn"
        onClick={() => {
          csvLinkRef.current?.link.click();
          toggleModal();
        }}
        disabled={!(newCsvData.length > 0)}
        type="primary"
      >
        Download csv
      </AlloyButton>
    ) : (
      <AlloyButton
        key="export"
        className="filled_blue_btn"
        onClick={() => {
          csvLinkRef.current?.link.click();
          toggleModal();
        }}
        disabled={!(oldCsvData.length > 0)}
        type="primary"
      >
        Download csv
      </AlloyButton>
    ),

    isSOExportItemsEnabled ? (
      <CSVLink
        key="Sales-Order-Item-List"
        ref={csvLinkRef}
        filename={'Sales-Order-Item-List.csv'}
        headers={newSOItemHeaders}
        data={newCsvData}
      />
    ) : (
      <CSVLink
        key="Sales-Order-Item-List"
        ref={csvLinkRef}
        filename={'Sales-Order-Item-List.csv'}
        headers={oldSOItemHeaders}
        data={oldCsvData}
      />
    )
  ];

  return (
    <AlloyModal
      data-testid="export_so_items_modal"
      className={s.export_so_items_modal}
      title={
        <div className={s.header}>
          Export Sales Order Items
          {(!loading || !exportSOLoading) && <div className={s.controls}>{controls}</div>}
        </div>
      }
      closable={false}
      open={visibility}
      onCancel={() => toggleModal()}
      width={1400}
      footer={(!loading || !exportSOLoading) && controls}
      classNames={{
        footer: s.footer
      }}
    >
      {(loading || exportSOLoading) && <LoaderSpinner />}
      {(!loading || !exportSOLoading) &&
        (isSOExportItemsEnabled ? (
          <AlloyTable
            data-testid="so_items_table legacy_borderless_bordered"
            rowKey={(item: SoExportItem) => item.id}
            pagination={false}
            dataSource={newCsvData}
            columns={newColumns}
            scroll={{ x: '100%' }}
            bordered
          />
        ) : (
          <AlloyTable
            data-testid="so_items_table legacy_borderless_bordered"
            rowKey={(item: SOExportItem) => item.key}
            pagination={false}
            dataSource={oldCsvData}
            columns={oldColumns}
            scroll={{ x: '100%' }}
            bordered
          />
        ))}
    </AlloyModal>
  );
};

export default ExportSoItemsModal;
