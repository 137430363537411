import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetInventoryOptimizerExportQueryVariables = Types.Exact<{
  scId: Types.Scalars['String'];
}>;


export type GetInventoryOptimizerExportQuery = { __typename?: 'RootQueryType', getInventoryOptimizerExport?: string | null };


export const GetInventoryOptimizerExportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInventoryOptimizerExport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getInventoryOptimizerExport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scId"}}}]}]}}]} as unknown as DocumentNode<GetInventoryOptimizerExportQuery, GetInventoryOptimizerExportQueryVariables>;