import { Button, Steps } from 'antd';
import { FormApi } from 'final-form';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { ActiveAssortmentTab } from '../../../forms/ActiveAssortmentTab/ActiveAssortmentTab';
import { GeneralSettingsTab } from '../../../forms/GeneralSettingsTab/GeneralSettingsTab';
import { IntegrationsTab } from '../../../forms/IntegrationsTab/IntegrationsTab';
import s from './AddTradingPartnerStepper.module.scss';
import {
  SourcingRule,
  TradingPartner
} from 'pages/TradingPartnersPage/components/TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { AddShipTosStep } from '../AddShipTosStep/AddShipTosStep';
import {
  BusinessUnitsAndVendorMarketsDocument,
  BusinessUnitsAndVendorMarketsQuery
} from 'pages/TradingPartnersPage/gql/__generated__/buAndVendorMarketLists.query';
import { useQuery } from '@apollo/client';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';

interface AddTradingPartnerStepperProps {
  form: FormApi<any, Partial<any>>;
  tradingPartner: TradingPartner | undefined;
  setVendorMarketId: (id: string) => void;
  upserting: boolean;
  onCancel: () => void;
  defaultSourcingRules: SourcingRule[];
  visible: boolean;
}

export type BusinessUnit = InferNodeType<BusinessUnitsAndVendorMarketsQuery, 'businessUnits'>;
export type VendorMarket = InferNodeType<BusinessUnitsAndVendorMarketsQuery, 'vendorMarkets'>;

export const AddTradingPartnerStepper = ({
  form,
  tradingPartner,
  setVendorMarketId,
  upserting,
  onCancel,
  defaultSourcingRules,
  visible
}: AddTradingPartnerStepperProps) => {
  const [current, setCurrent] = useState(0);

  const { data: initData, loading: initLoading } = useQuery(
    BusinessUnitsAndVendorMarketsDocument,
    {}
  );

  const businessUnits = useMemo(() => getNodesFromEdges(initData?.businessUnits), [initData]);
  const vendorMarkets = useMemo(() => getNodesFromEdges(initData?.vendorMarkets), [initData]);

  // move to 0 step if the window closes by browser's back button
  useEffect(() => {
    setCurrent(0);
  }, [tradingPartner]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCancel = () => {
    onCancel();
    setCurrent(0);
  };

  const steps = [
    {
      title: 'General Settings',
      content: (
        <GeneralSettingsTab
          setVendorMarketId={setVendorMarketId}
          upserting={upserting}
          defaultSourcingRules={defaultSourcingRules}
          allowedDistributionCenters={tradingPartner?.distributionCenters || []}
          businessUnits={businessUnits}
          vendorMarkets={vendorMarkets}
          initLoading={initLoading}
        />
      )
    },
    {
      title: 'Ship-Tos Settings',
      content: <AddShipTosStep vendorMarkets={vendorMarkets} initLoading={initLoading} />
    },
    {
      title: 'Integrations',
      content: (
        <IntegrationsTab
          tradingPartner={tradingPartner}
          upserting={upserting}
          setUseConfig={(integrationName: string | undefined, value: boolean) => {
            switch (integrationName) {
              case 'channelAdvisorConfig':
                form.mutators.useChannelAdvisorConfig(value);
                break;
              case 'ochConfig':
                form.mutators.useOchConfig(value);
                break;
              case 'pepdirectConfig':
                form.mutators.usePepdirectConfig(value);
                break;
              case 'shippingConfig':
                form.mutators.useShippingConfig(value);
                break;
              case 'ediMessageConfiguration':
                form.mutators.useEdiMessageConfiguration(value);
                break;
              case 'purchaseOrderCustomization850':
                form.mutators.usePurchaseOrderCustomization850(value);
                break;
              case 'purchaseOrderAckCustomization855':
                form.mutators.usePurchaseOrderAckCustomization855(value);
                break;
              case 'purchaseOrderCustomization856':
                form.mutators.usePurchaseOrderCustomization856(value);
                break;
              case 'purchaseOrderCustomization810':
                form.mutators.usePurchaseOrderCustomization810(value);
                break;
              case 'ediConfigInternal':
                form.mutators.useEdiConfigInternal(value);
                break;
              case 'ediConfigExternal':
                form.mutators.useEdiConfigExternal(value);
                break;
              case 'emailConfigSalesOrderRelease':
                form.mutators.useEmailConfigSalesOrderRelease(value);
                break;
              case 'emailConfigSalesOrderExport':
                form.mutators.useEmailConfigSalesOrderExport(value);
                break;
              case 'internalAlertEmailConfig':
                form.mutators.useInternalAlertEmailConfig(value);
                break;
              case 'internalAlertConfig':
                form.mutators.useInternalAlertConfig(value);
                break;
              case 'storeConfig':
                form.mutators.useStoreConfig(value);
                break;
              case 'amazonSellingPartnerConfig':
                form.mutators.useAmazonSellingPartnerConfig(value);
                break;
              case 'samsClubConfig':
                form.mutators.useSamsClubConfig(value);
                break;
            }
          }}
        />
      )
    },
    {
      title: 'Active Assortment',
      content: <ActiveAssortmentTab />
    }
  ];

  return (
    <>
      <FullScreenEditingModal
        title={`${tradingPartner?.id ? 'Edit' : 'Add'} Trading Partner`}
        open={visible}
        onClose={handleCancel}
        buttons={
          <>
            <Button className={s.steps_button} onClick={handleCancel}>
              Cancel
            </Button>
            {current > 0 && (
              <Button className={s.steps_button} onClick={() => prev()}>
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                className={s.steps_button}
                onClick={() => {
                  const { errors } = form.getState();
                  if (
                    (errors &&
                      current === 0 &&
                      (errors['businessUnitId'] ||
                        errors['deliveryType'] ||
                        errors['externalId'] ||
                        errors['name'] ||
                        errors['purchaseOrderAcknowledgementTransport'] ||
                        errors['shipToReleaseIdentifier'] ||
                        errors['vendorMarketId'] ||
                        errors['measurementSystem'] ||
                        errors['ingestPo'] ||
                        errors['showProcessingFlow'] ||
                        errors['sourcingRules'])) ||
                    (errors &&
                      current === 2 &&
                      (errors['channelAdvisorConfigProfileId'] ||
                        errors['channelAdvisorConfigSiteId'] ||
                        errors['channelAdvisorConfigSiteName'] ||
                        errors['ochConfigScope'] ||
                        errors['pepdirectConfigAppId'] ||
                        errors['shippingConfigMaximalPickupDays'] ||
                        errors['shippingConfigMinimalPickupDays'] ||
                        errors['ediConfigInternalGsLocalCode'] ||
                        errors['ediConfigInternalGsRemoteCode'] ||
                        errors['ediConfigInternalIsaLocalId'] ||
                        errors['ediConfigInternalIsaLocalQualifier'] ||
                        errors['ediConfigInternalIsaRemoteId'] ||
                        errors['ediConfigInternalIsaRemoteQualifier'] ||
                        errors['ediConfigExternalGsLocalCode'] ||
                        errors['ediConfigExternalGsRemoteCode'] ||
                        errors['ediConfigExternalIsaLocalId'] ||
                        errors['ediConfigExternalIsaLocalQualifier'] ||
                        errors['ediConfigExternalIsaRemoteId'] ||
                        errors['ediConfigExternalIsaRemoteQualifier'] ||
                        errors['ediMessageConfigurationExternalProductId'] ||
                        errors['ediMessageConfigurationInternalProductId'] ||
                        errors['ediMessageConfigurationFormat'] ||
                        errors['ediMessageConfigurationGsLocalCode'] ||
                        errors['ediMessageConfigurationGsRemoteCode'] ||
                        errors['ediMessageConfigurationIsaInterchangeUsageIndicator'] ||
                        errors['ediMessageConfigurationIsaComponentElementSeparator'] ||
                        errors['ediMessageConfigurationIsaInterchangeControlVersionNumber'] ||
                        errors['ediMessageConfigurationIsaLocalId'] ||
                        errors['ediMessageConfigurationIsaLocalQualifier'] ||
                        errors['ediMessageConfigurationIsaRemoteId'] ||
                        errors['ediMessageConfigurationIsaRemoteQualifier'] ||
                        errors['ediMessageConfigurationIsaRepetitionSeparator'] ||
                        errors['ediMessageConfigurationSupplierId'] ||
                        errors['ediMessageConfigurationSupplierIdPath'] ||
                        errors['ediMessageConfigurationWebhookEndpoint'] ||
                        errors['purchaseOrderCustomization850ConfigShipTo'] ||
                        errors['purchaseOrderCustomization850ConfigProductUnit'] ||
                        errors['purchaseOrderCustomization850ConfigPassUpc'] ||
                        errors['purchaseOrderAckCustomization855ConfigItemNotAvailInput'] ||
                        errors['purchaseOrderAckCustomization855ConfigItemInvalidInput'] ||
                        errors['purchaseOrderAckCustomization855ConfigMatch'] ||
                        errors['purchaseOrderCustomization856ConfigMatch'] ||
                        errors['purchaseOrderCustomization856ConfigPassShipTo'] ||
                        errors['purchaseOrderCustomization810ConfigResName'] ||
                        errors['purchaseOrderCustomization810ConfigResAddress'] ||
                        errors['purchaseOrderCustomization810ConfigResCityZip'] ||
                        errors['purchaseOrderCustomization810ConfigMatch'] ||
                        errors['purchaseOrderCustomization810ConfigPassRemAddress'] ||
                        errors['purchaseOrderCustomization810ConfigPassBillToAddress'] ||
                        errors['emailConfigSalesOrderExportEmailFromAddress'] ||
                        errors['emailConfigSalesOrderExportEmailToList'] ||
                        errors['emailConfigSalesOrderReleaseEmailFromAddress'] ||
                        errors['emailConfigSalesOrderReleaseEmailToList'] ||
                        errors['internalAlertEmailConfigEmailFromAddress'] ||
                        errors['internalAlertEmailConfigEmailToList'] ||
                        errors['storeConfigRequiredIds']))
                  ) {
                    form.submit();
                  } else {
                    next();
                  }
                }}
                type="primary"
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className={s.steps_button} type="primary" onClick={() => form.submit()}>
                {tradingPartner?.id ? 'Save' : 'Add'}
              </Button>
            )}
          </>
        }
        steps={<Steps current={current} size="small" style={{ width: '684px' }} items={steps} />}
      >
        <div>{steps[current].content}</div>
      </FullScreenEditingModal>
    </>
  );
};
