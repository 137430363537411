import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DemandVisibilityByWeekQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type DemandVisibilityByWeekQuery = { __typename?: 'RootQueryType', demandVisibilityByWeek: Array<{ __typename?: 'DemandVisibilityWeeklyTotal', demandInCases: string, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', year: number, period: number, week: number }, grossValue: { __typename?: 'Currency', amount: string, units: string } }> };


export const DemandVisibilityByWeekDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DemandVisibilityByWeek"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"demandVisibilityByWeek"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"week"}}]}},{"kind":"Field","name":{"kind":"Name","value":"demandInCases"}},{"kind":"Field","name":{"kind":"Name","value":"grossValue"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"units"}}]}}]}}]}}]} as unknown as DocumentNode<DemandVisibilityByWeekQuery, DemandVisibilityByWeekQueryVariables>;