import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpsertTradingPartnerAssortmentsMutationVariables = Types.Exact<{
  input: Types.UpsertTradingPartnerAssortmentsInput;
}>;


export type UpsertTradingPartnerAssortmentsMutation = { __typename?: 'RootMutationType', upsertTradingPartnerAssortments?: { __typename?: 'UpsertTradingPartnerAssortmentsPayload', errorsCount: number, successCount: number, errors: Array<{ __typename?: 'UpsertAssortmentError', upc: string, externalId: string, reason: string }> } | null };


export const UpsertTradingPartnerAssortmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"upsertTradingPartnerAssortments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertTradingPartnerAssortmentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertTradingPartnerAssortments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upc"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}}]}},{"kind":"Field","name":{"kind":"Name","value":"errorsCount"}},{"kind":"Field","name":{"kind":"Name","value":"successCount"}}]}}]}}]} as unknown as DocumentNode<UpsertTradingPartnerAssortmentsMutation, UpsertTradingPartnerAssortmentsMutationVariables>;