import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryRepackFulfillReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryRepackFilters>;
}>;


export type InventoryRepackFulfillReportQuery = { __typename?: 'RootQueryType', inventoryRepackFulfillReport?: { __typename?: 'InventoryRepackFulfillReport', metrics?: Array<{ __typename?: 'InventoryRepackFulfillReportMetrics', id: string, asin?: string | null, gpFulfillQty?: number | null, hamburgFulfillQty?: number | null, itemId: string, memphisFulfillQty?: number | null, productTitle?: string | null, shortfallQty?: number | null, totalNeedQty?: number | null, type?: Types.RepackItemType | null, isMoved: boolean, lastChangeInfo?: { __typename?: 'RepackLastChangeInfo', comment?: string | null, reason: string, updatedAt: any, userName: string } | null }> | null } | null };


export const InventoryRepackFulfillReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryRepackFulfillReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryRepackFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryRepackFulfillReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"asin"}},{"kind":"Field","name":{"kind":"Name","value":"gpFulfillQty"}},{"kind":"Field","name":{"kind":"Name","value":"hamburgFulfillQty"}},{"kind":"Field","name":{"kind":"Name","value":"itemId"}},{"kind":"Field","name":{"kind":"Name","value":"memphisFulfillQty"}},{"kind":"Field","name":{"kind":"Name","value":"productTitle"}},{"kind":"Field","name":{"kind":"Name","value":"shortfallQty"}},{"kind":"Field","name":{"kind":"Name","value":"totalNeedQty"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"lastChangeInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isMoved"}}]}}]}}]}}]} as unknown as DocumentNode<InventoryRepackFulfillReportQuery, InventoryRepackFulfillReportQueryVariables>;