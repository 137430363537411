import React from 'react';
import s from './ViewTabs.module.scss';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';

export const ViewTabs = ({
  tabs,
  firstOpenTab
}: {
  tabs: {
    label: string;
    key: string;
    children: JSX.Element;
  }[];
  firstOpenTab?: string;
}) => {
  return (
    <AlloyTabs
      defaultActiveKey={firstOpenTab}
      className={s.tabs}
      rootClassName={s.view_tabs_root}
      items={tabs.map((tab) => ({
        ...tab,
        label: <span data-testid={`${tab.key}-tab-title`}>{tab.label}</span>,
        children: <div className={s.container}>{tab.children}</div>
      }))}
    />
  );
};
