import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorState from '@/src/components/ErrorState/ErrorState';

interface ErrorDisplayProps {
  error: Error;
  header?: string;
}

const ErrorDisplay = ({ header, error }: ErrorDisplayProps) => {
  Sentry.captureException(error);
  console.log('ERROR:', error);

  return <ErrorState title={header || error.name} explanation={error.message} />;
};

export default ErrorDisplay;
