import React from 'react';
import s from './ListOfItemsColumn.module.scss';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

interface ListOfItemsColumnProps {
  items: string[];
  splitToLines?: boolean;
  loading?: boolean;
}

export const ListOfItemsColumn = ({ items, loading, splitToLines }: ListOfItemsColumnProps) => {
  const getItem = (key: string, text: string, last: boolean) =>
    splitToLines ? (
      <div key={key}>{text}</div>
    ) : (
      <span key={key}>
        {text}
        {!last ? ', ' : ''}
      </span>
    );

  return (
    <>
      {items?.length > 0 ? (
        items.length > 1 ? (
          <AlloyTooltip
            overlayStyle={{ maxWidth: '700px' }}
            placement="topLeft"
            title={
              !loading ? (
                items.map((rdd, index) =>
                  index > 0 ? getItem(`${rdd}_${index}`, rdd, index === items.length - 1) : null
                )
              ) : (
                <AlloySpin />
              )
            }
          >
            {items[0]} <span className={s.shipToTooltip}>+{items.length - 1}</span>
          </AlloyTooltip>
        ) : (
          items[0]
        )
      ) : (
        ''
      )}
    </>
  );
};
