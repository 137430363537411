import React from 'react';
import {
  AllowedDistributionCenter,
  RetailerDeliveryDestination
} from 'pages/ShipToPage/components/ShipToViewDrawer/ShipToViewDrawer';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import { ViewTable } from 'components/Common/ViewMode/ViewTable/ViewTable';
import s from './AllowedDistributionCentersViewTab.module.scss';

export const AllowedDistributionCentersViewTab = ({
  shipTo
}: {
  shipTo: RetailerDeliveryDestination | null | undefined;
}) => {
  const shipToContent = (
    <ViewTable<AllowedDistributionCenter>
      data={
        shipTo?.allowedDistributionCenters
          ?.filter((allowedDc): allowedDc is AllowedDistributionCenter => !!allowedDc)
          ?.sort((dc1, dc2) => (dc1?.priority || 0) - (dc2?.priority || 0)) || []
      }
      columns={[
        {
          title: '',
          dataIndex: 'priority',
          withTooltip: false,
          classname: s.priority_column
        },
        {
          title: 'Name',
          dataIndex: 'distributionCenter.name',
          withTooltip: true,
          classname: s.name_column
        },
        {
          title: 'Code',
          dataIndex: 'distributionCenter.code',
          withTooltip: true,
          classname: s.code_column
        }
      ]}
    />
  );

  return (
    <>
      <ViewRow title="Allowed DC" content={shipToContent} bordered={false} />
    </>
  );
};
