import React from 'react';
import s from './Chip.module.scss';
import crossIcon from 'assets/icons/common/cross.svg';
import checkIcon from 'assets/icons/common/check.svg';
import { ReactSVG } from 'react-svg';
import { BaseProperties } from '../Utils';
import clsx from 'clsx';

export interface ChipProps extends BaseProperties {
  id?: string;
  label: string;
  onRemoveButtonClick?: () => void;
  onSelectionChange?: (selected: boolean) => void;
  selected?: boolean;
  showSelectedIcon?: boolean;
}

export const Chip = ({
  id,
  label,
  onRemoveButtonClick,
  size = 'regular',
  selected = false,
  onSelectionChange,
  style,
  disabled,
  showSelectedIcon = true,
  className,
  ...props
}: ChipProps) => {
  const chipProps = {
    className: clsx(s.chip, className),
    'data-size': size,
    'data-selected': selected,
    'data-selectable': !!onSelectionChange,
    'data-removable': !!onRemoveButtonClick,
    style,
    id,
    ...props
  };

  const chipContent = (
    <>
      {showSelectedIcon && <ReactSVG src={checkIcon} className={s.chip_check} />}
      {label}
      <button
        className={s.chip_cross}
        aria-label="Remove"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onRemoveButtonClick && onRemoveButtonClick();
        }}
      >
        <ReactSVG src={crossIcon} />
      </button>
    </>
  );

  return onSelectionChange ? (
    <button
      {...chipProps}
      onClick={(e) => {
        e.stopPropagation();
        onSelectionChange && onSelectionChange(!selected);
      }}
      aria-label="Change selection"
      type="button"
      disabled={disabled}
    >
      {chipContent}
    </button>
  ) : (
    <span {...chipProps}>{chipContent}</span>
  );
};
