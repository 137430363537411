import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExecutiveSummaryShipmentOnTimeRateTrendQueryVariables = Types.Exact<{
  filters: Types.AppliedExecutiveSummaryFilters;
  aggregateBy?: Types.InputMaybe<Types.OnTimeRateTrendAggregation>;
}>;


export type ExecutiveSummaryShipmentOnTimeRateTrendQuery = { __typename?: 'RootQueryType', executiveSummaryShipmentOnTimeRateTrend?: { __typename?: 'ExecutiveSummaryShipmentOnTimeRateTrend', metrics: Array<{ __typename?: 'ExecutiveSummaryShipmentOnTimeRateMetric', onTimeShipmentRate: any, period: number, week?: number | null, year: number } | null> } | null };


export const ExecutiveSummaryShipmentOnTimeRateTrendDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"executiveSummaryShipmentOnTimeRateTrend"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AppliedExecutiveSummaryFilters"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"aggregateBy"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"OnTimeRateTrendAggregation"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"executiveSummaryShipmentOnTimeRateTrend"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}},{"kind":"Argument","name":{"kind":"Name","value":"aggregateBy"},"value":{"kind":"Variable","name":{"kind":"Name","value":"aggregateBy"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onTimeShipmentRate"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}}]}}]}}]} as unknown as DocumentNode<ExecutiveSummaryShipmentOnTimeRateTrendQuery, ExecutiveSummaryShipmentOnTimeRateTrendQueryVariables>;