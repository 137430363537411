import * as Types from '../../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SubmitPurchaseOrderConfirmationsMutationVariables = Types.Exact<{
  input: Types.SubmitPurchaseOrderConfirmationsInput;
}>;


export type SubmitPurchaseOrderConfirmationsMutation = { __typename?: 'RootMutationType', submitPurchaseOrderConfirmations?: { __typename?: 'SubmitPurchaseOrderConfirmationsPayload', confirmationsEnqueued: number } | null };


export const SubmitPurchaseOrderConfirmationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"submitPurchaseOrderConfirmations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubmitPurchaseOrderConfirmationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitPurchaseOrderConfirmations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmationsEnqueued"}}]}}]}}]} as unknown as DocumentNode<SubmitPurchaseOrderConfirmationsMutation, SubmitPurchaseOrderConfirmationsMutationVariables>;