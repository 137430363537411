import React from 'react';
import { Tag as AntTag, TagProps } from 'ant5';
import clsx from 'clsx';
import s from './AlloyTag.module.scss';

export function AlloyTag({
  className: userClassname,
  disabled,
  ...otherProps
}: TagProps & { disabled?: boolean }) {
  return <AntTag className={clsx(s.tag, userClassname)} {...otherProps} aria-disabled={disabled} />;
}

AlloyTag.CheckableTag = AntTag.CheckableTag;

// Re-exporting types
export type { TagProps };
