import React from 'react';
import { OptimizerStatus } from 'graphql/__generated__/types';
import s from './RepackOptimizerStatus.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import tableIcon from 'assets/icons/repack/table.svg';
import roundArrowsIcon from 'assets/icons/repack/round_arrows.svg';
import successIcon from 'assets/icons/repack/success.svg';
import warningIcon from 'assets/icons/repack/warning.svg';
import arrowUpIcon from 'assets/icons/repack/arrow_up.svg';
import clsx from 'clsx';

export const RepackOptimizerStatus = ({
  status,
  loading = true
}: {
  status?: OptimizerStatus | null;
  loading?: boolean;
}) => {
  return (
    <AlloySpin spinning={loading}>
      <div className={s.wrapper}>
        {status === 'NOT_STARTED' && (
          <div className={s.status}>
            <div className={s.image}>
              <img src={tableIcon} alt="" className={s.table} />
              <img src={arrowUpIcon} alt="" className={s.subicon} />
            </div>
            <h3 className={s.title}>No optimization job is running</h3>
          </div>
        )}
        {status === 'STARTED' && (
          <div className={s.status}>
            <div className={s.image}>
              <img src={tableIcon} alt="" className={s.table} />
              <img src={roundArrowsIcon} alt="" className={clsx(s.subicon, s.loading)} />
            </div>
            <h3 className={s.title}>Optimization process in progress</h3>
          </div>
        )}
        {status === 'SUCCESS' && (
          <div className={s.status}>
            <div className={s.image}>
              <img src={tableIcon} alt="" className={s.table} />
              <img src={successIcon} alt="" className={s.subicon} />
            </div>
            <h3 className={s.title}>Optimization process successful</h3>
          </div>
        )}
        {status === 'FAILED' && (
          <div className={s.status}>
            <div className={s.image}>
              <img src={tableIcon} alt="" className={s.table} />
              <img src={warningIcon} alt="" className={s.subicon} />
            </div>
            <h3 className={s.title}>Optimization process failed</h3>
            <span className={s.subtitle}>Please re-submit to re-start process</span>
          </div>
        )}
      </div>
    </AlloySpin>
  );
};
