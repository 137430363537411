import React, { ReactNode } from 'react';
import s from './FullScreenEditingModal.module.scss';
import clsx from 'clsx';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface FullScreenEditingModalProps {
  title: ReactNode;
  open: boolean;
  onClose: () => void;
  steps?: JSX.Element;
  children: JSX.Element;
  buttons: ReactNode;
  containerClassName?: string;
}

export const FullScreenEditingModal = ({
  title,
  open,
  onClose,
  children,
  buttons,
  steps,
  containerClassName
}: FullScreenEditingModalProps) => {
  return (
    <AlloyModal
      title={title}
      open={open}
      footer={buttons === null ? null : <div className={s.buttons_container}>{buttons}</div>}
      closable
      destroyOnClose
      onCancel={() => onClose()}
      width="100%"
      className={s.modal}
      classNames={{
        body: s.modal_body,
        content: s.modal_content,
        header: s.header,
        footer: s.footer
      }}
    >
      {steps && <div className={s.stepper_container}>{steps}</div>}
      <div className={clsx(s.main_container, containerClassName)}>{children}</div>
    </AlloyModal>
  );
};
