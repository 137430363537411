import React, { useMemo } from 'react';
import { ReconcileBatchDetailsMetric, makeReasonsReadable } from '../types';
import { Form } from 'react-final-form';
import { Button, message } from 'antd';
import s from './EditReasonsForm.module.scss';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import clsx from 'clsx';
import { sumBy } from 'lodash-es';
import { OnChange } from 'react-final-form-listeners';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { InventoryDiscrepancyReason } from 'graphql/__generated__/types';
import { useEnumValue } from 'common/useEnumValue';

type Reason = {
  reason: InventoryDiscrepancyReason;
  quantity: String;
};
interface EditReasons {
  reasons: Reason[];
  openVariance: number;
  totalDiscrepancy: number;
}
export const EditReasonsForm = ({
  editBatch,
  onCancel
}: {
  editBatch: ReconcileBatchDetailsMetric | undefined;
  onCancel: () => void;
}) => {
  const reasons = useEnumValue('InventoryDiscrepancyReason');

  const initialTotal = useMemo(
    () => sumBy(editBatch?.reasons, 'quantity') + editBatch?.openVariance,
    [editBatch]
  );
  return (
    <div className={s.form}>
      <Form<EditReasons>
        initialValues={{
          reasons: [
            ...(editBatch?.reasons || []).map((x) => ({
              reason: x.reason as InventoryDiscrepancyReason,
              quantity: x.quantity
            }))
          ],
          openVariance: editBatch?.openVariance
        }}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={(_values) => message.success('Reasons updated')}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="reasons">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => (
                    <div key={name} className={s.formRow}>
                      <div className={s.amount}>
                        <FinalFormInput name={`${name}.quantity`} title="" />
                      </div>
                      <div className={s.reason}>
                        <FinalFormSelect
                          loading={reasons.loading}
                          name={`${name}.reason`}
                          title=""
                          options={[...reasons.enumValues]
                            .sort(safeLocaleCompare)
                            .filter((x) => x !== 'SWAP') // We don't need SWAP here, we have a separate modal for that
                            .map((x) => ({ label: makeReasonsReadable(x), value: x }))}
                          disabledOptionsList={values.reasons
                            .map((x) => x.reason)
                            .filter(
                              (x) => x !== form.getFieldState('reasons')?.value?.[index].reason
                            )}
                        />
                      </div>
                      <div>
                        <Button onClick={() => fields.remove(index)} icon={<MinusOutlined />} />
                      </div>
                    </div>
                  ))}
                  <div className={clsx(s.formRow, s.amountAndReason)}>
                    <div className={s.amount}>{values.openVariance}</div>
                    <div className={s.reason}>Open Variance</div>
                    <Button
                      onClick={() =>
                        fields.push({ quantity: values.openVariance, reason: undefined })
                      }
                      disabled={(values.reasons || []).some((x) => !x.reason || !x.quantity)}
                      icon={<PlusOutlined />}
                    />
                  </div>
                </>
              )}
            </FieldArray>

            <div className={s.divider} />
            <div className={clsx(s.formRow, s.amountAndReason)}>
              <div className={s.amount}>{editBatch?.discrepancy}</div>
              <div className={s.reason}>Total Discrepancy</div>
            </div>
            <div className={s.buttons}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Confirm
              </Button>

              <OnChange name="reasons">
                {(value) => {
                  const total = sumBy(value, (item: { quantity: string }) =>
                    parseFloat(item.quantity)
                  );
                  form.change('openVariance', initialTotal - (total || 0));
                }}
              </OnChange>
            </div>
          </form>
        )}
      />
    </div>
  );
};
