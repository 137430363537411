import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ResetPurchaseOrderMutationVariables = Types.Exact<{
  input: Types.ResetPurchaseOrdersToIngestedInput;
}>;


export type ResetPurchaseOrderMutation = { __typename?: 'RootMutationType', resetPurchaseOrdersToIngested?: { __typename?: 'ResetPurchaseOrdersToIngestedPayload', purchaseOrdersReset: Array<string> } | null };


export const ResetPurchaseOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resetPurchaseOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPurchaseOrdersToIngestedInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPurchaseOrdersToIngested"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrdersReset"}}]}}]}}]} as unknown as DocumentNode<ResetPurchaseOrderMutation, ResetPurchaseOrderMutationVariables>;