import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import React from 'react';
import s from './ViewRetailerChannel.module.scss';
import { VendorMarketFullData } from '../RetailerChannelViewDrawer';

export const ViewRetailerChannel = ({
  vendorMarket
}: {
  vendorMarket: VendorMarketFullData | null | undefined;
}) => (
  <div>
    <ViewRow
      title="General setting"
      bordered={false}
      content={
        <>
          <ViewField title="External ID" type="INPUT" value={vendorMarket?.externalId} />
          <ViewField title="Name" type="INPUT" value={vendorMarket?.name} />
          <ViewField title="Country Code" type="INPUT" value={vendorMarket?.countryCode} />
          <ViewField title="Currency Code" type="INPUT" value={vendorMarket?.currencyCode} />
          <ViewField title="Retailer" type="INPUT" value={vendorMarket?.vendor?.name} />
          <ViewField
            title="Parent Retailer Channel"
            type="INPUT"
            value={vendorMarket?.parentMarket?.name}
          />
        </>
      }
    />
  </div>
);
