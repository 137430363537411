/*
This component contains code for Scenario Create Page.
*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SKUSScenarioPlanning from './SKUSScenarioPlanning';
import SKUScenariosConstraints from './SKUScenariosConstraints';
import s from './ScenarioCreatePage.module.scss';
import NewScenarioAddItem from './NewScenarioAddItem';
import { LeftOutlined } from '@ant-design/icons';
import { ScenarioConstraintsData } from './SKUScenariosConstraints';

const ScenarioCreatePage = () => {
  const [sharedGroupSkusList, setSharedGroupSkusList] = useState<ScenarioConstraintsData[]>([]);

  const handleSelectedSkusList = (updatedSelectedSkusState: ScenarioConstraintsData[]) => {
    setSharedGroupSkusList(updatedSelectedSkusState);
  };
  const handleDelete = (key: React.Key[]) => {
    const updatedData = sharedGroupSkusList.filter((item) => !key.includes(item.key));
    setSharedGroupSkusList(updatedData);
  };

  return (
    <div>
      <div className={s.scenarioPlannig}>
        <Link
          to={'/inventory-optimization/'}
          data-testid="inv-createScenario-backIcon"
          className={s.title}
        >
          <LeftOutlined style={{ color: 'black', width: '16px', height: '16px' }} />
          <span data-testid="inv-createScenario-title">Scenario Planning</span>
        </Link>
      </div>

      <div className={s.scenarioCost} data-testid="inv-createScenario-objective">
        <p>
          Objective:<span data-testid="inv-createScenario-objective-name">Min. Total Cost</span>
        </p>
      </div>

      <div className={s.scenarioContainer}>
        <SKUSScenarioPlanning
          handleSelectedSkusList={handleSelectedSkusList}
          sharedGroupSkusList={sharedGroupSkusList}
        />
        <SKUScenariosConstraints
          sharedGroupSkusList={sharedGroupSkusList}
          onDelete={handleDelete}
        />
      </div>

      <div>
        <NewScenarioAddItem ScenarioConstraintsData={sharedGroupSkusList} />
      </div>
    </div>
  );
};

export default ScenarioCreatePage;
