import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Modal, notification, Table, Tooltip, Checkbox } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { SalesOrder } from '@/src/pages/OrderDetailsPage/types';
import { useMutation } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import s from './ResendCustomizationModel.module.scss';
import { GenerateSalesOrdersCustomizationsDocument } from './gql/__generated__/generateSalesOrdersCustomizations.mutation';
import { GenerateAndSendSalesOrdersCustomizationsDocument } from './gql/__generated__/generateAndSendSalesOrdersCustomizations.mutation';
import { GeneratedCustomization } from 'graphql/__generated__/types';
import { Button } from 'components/alloy/Button';
import { PurchaseOrderRefetchQueries } from 'common/constants';
import { CSVLink } from 'react-csv';

interface ResendCustomizationModalProps {
  visible: boolean;
  toggleVisibility: () => void;
  salesOrdersWithCustomizations: SalesOrder[];
}

interface CustomizationWithCsv extends GeneratedCustomization {
  csvData: string[][];
}

interface CsvRefs {
  [index: string]: HTMLAnchorElement;
}

type SalesOrderWithIndex = SalesOrder & { index: number };

export const parseCustomizationContent = (content: string) => {
  const rows = content.split('\n').filter((row, index) => {
    if (row.length === 0) return false;
    return true;
  });

  const csvData = rows.map((row) => row.replace(/[\r\n]/g, '').split(','));

  return csvData;
};

const ResendCustomizationModal = ({
  visible,
  toggleVisibility,
  salesOrdersWithCustomizations
}: ResendCustomizationModalProps) => {
  const salesOrdersWithIndex: SalesOrderWithIndex[] = useMemo(
    () =>
      salesOrdersWithCustomizations.map((salesOrder, index) => ({
        ...salesOrder,
        index: index + 1
      })),
    [salesOrdersWithCustomizations]
  );

  const [selectedRows, setSelectedRows] = useState<SalesOrderWithIndex[]>([]);
  const [mergeByDC, setMergeByDC] = useState(true);
  const [requestedCustomizationSalesOrderId, setRequestedCustomizationSalesOrderId] =
    useState<string>();
  const [customizationFiles, setCustomizationFiles] = useState<CustomizationWithCsv[]>([]);
  const csvLinks = useRef<CsvRefs>({});

  const [getGeneratedCustomization] = useMutation(GenerateSalesOrdersCustomizationsDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });
  const [generateAndSendSalesOrdersCustomizations, { loading }] = useMutation(
    GenerateAndSendSalesOrdersCustomizationsDocument,
    {
      refetchQueries: PurchaseOrderRefetchQueries
    }
  );

  useEffect(() => {
    setRequestedCustomizationSalesOrderId(undefined);
  }, [visible]);

  const generateCustomizations = async (salesOrderId: string) => {
    setRequestedCustomizationSalesOrderId(undefined);

    //gather all the sales order ids to include in the customization request
    //if the current row isn't selected, select it automatically as the user action implies they want that customization at a minimum
    const allSelected = selectedRows.map((so) => so.id);
    if (!allSelected.includes(salesOrderId)) {
      allSelected.push(salesOrderId);
      const salesOrder = salesOrdersWithIndex.find((so) => so.id === salesOrderId);
      if (salesOrder) setSelectedRows([...selectedRows, salesOrder]);
    }

    try {
      const result = await getGeneratedCustomization({
        variables: {
          input: {
            salesOrderIds: allSelected,
            mergeCustomizationsByDc: mergeByDC
          }
        }
      });

      const customizationFiles =
        result?.data?.generateSalesOrdersCustomizations?.customizations.map((customization) => {
          const csvData = parseCustomizationContent(customization.content || '');
          return { ...customization, csvData: csvData } as CustomizationWithCsv;
        }) || [];

      setCustomizationFiles(customizationFiles);
      setRequestedCustomizationSalesOrderId(salesOrderId);

      //initiate download of each selected sales order csvLink element
      for (const soId of allSelected) {
        if (csvLinks.current[soId]) csvLinks.current[soId].click();
      }
    } catch (error) {}
  };

  const columns: ColumnsType<SalesOrderWithIndex> = [
    {
      title: 'Sales Order',
      key: 'sales_order_number',
      render: (_, record) => {
        return `Sales Order ${record.index}`;
      }
    },
    {
      title: 'DC',
      key: 'sales_order_dc',
      render: (_, record) => record.distributionCenter?.name
    },
    {
      title: 'Customization File',
      align: 'center',
      width: 40,
      render: (_, so) => {
        const customization = customizationFiles.find((customization) =>
          customization.salesOrders.find((soTarget) => soTarget?.id === so.id)
        );

        const fileName =
          customization?.filename || salesOrdersWithCustomizations[0].purchaseOrder.customerPo
            ? `${salesOrdersWithCustomizations[0].purchaseOrder.customerPo}.csv`
            : 'customization.csv';

        return (
          <div className={s.file}>
            <Tooltip title="Download">
              <FileOutlined
                className={s.icon}
                onClick={() => {
                  generateCustomizations(so.id);
                }}
              />
              {requestedCustomizationSalesOrderId === so.id && !!customization && (
                <div
                  ref={(element) => {
                    if (element?.children[0])
                      csvLinks.current[so.id] = element?.children[0] as HTMLAnchorElement;
                  }}
                >
                  <CSVLink data={customization.csvData} filename={fileName} enclosingCharacter="" />
                </div>
              )}
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const generateAndSendCustomizations = async () => {
    try {
      const result = await generateAndSendSalesOrdersCustomizations({
        variables: {
          input: {
            salesOrderIds: selectedRows.map((row) => row.id),
            mergeCustomizationsByDc: mergeByDC
          }
        }
      });

      if (result.data?.generateAndSendSalesOrdersCustomizations?.customizations.length) {
        if (result.data?.generateAndSendSalesOrdersCustomizations?.customizationsErrors.length) {
          let description = '';

          result.data?.generateAndSendSalesOrdersCustomizations?.customizationsErrors.map(
            (error) => {
              description += `Sales Order ${error.salesOrders
                .map((so) => so?.externalId)
                .join(',')}: ${error.status} \n`;
            }
          );
          notification.warning({
            message: 'Partial Success',
            description: description
          });
        } else {
          toggleVisibility();
          notification.success({
            message: 'Success',
            description: `${selectedRows.length} customization${
              selectedRows.length > 1 ? 's' : ''
            } regenerated and re-sent`
          });
        }
      } else {
        let description = '';

        result.data?.generateAndSendSalesOrdersCustomizations?.customizationsErrors.map((error) => {
          description += `Sales Order ${error.salesOrders.map((so) => so?.externalId).join(',')}: ${
            error.status
          } \n`;
        });
        notification.error({
          message: 'Customization Generation Error',
          description: description
        });
      }
    } catch (error) {
      notification.error({
        message: 'Customization Generation Error',
        description: error.message
      });
    }
  };

  return (
    <Modal
      onCancel={() => toggleVisibility()}
      title={`Download or Resend Customization${selectedRows.length > 1 ? 's' : ''}`}
      width={600}
      footer={
        <div className={s.footer}>
          <Button type="outline" onClick={() => toggleVisibility()}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={selectedRows.length <= 0 || loading}
            onClick={() => {
              generateAndSendCustomizations();
            }}
          >
            {`Regenerate & re-send ${selectedRows.length > 0 ? `(${selectedRows.length})` : ''}`}
          </Button>
        </div>
      }
      destroyOnClose
      open={visible}
    >
      <Table
        pagination={false}
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={salesOrdersWithIndex}
        loading={loading}
        rowSelection={{
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
          type: 'checkbox',
          selectedRowKeys: selectedRows.map((row) => row.id)
        }}
      />
      <div className={s.merge}>
        <Checkbox checked={mergeByDC} onClick={() => setMergeByDC(!mergeByDC)}>
          Merge customization files going to the same DC
        </Checkbox>
      </div>
    </Modal>
  );
};

export default ResendCustomizationModal;
