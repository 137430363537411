import * as Types from '../../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GenerateAndSendPurchaseOrderConfirmationsFromItemsMutationVariables = Types.Exact<{
  input: Types.GenerateAndSendPurchaseOrderConfirmationsFromItemsInput;
}>;


export type GenerateAndSendPurchaseOrderConfirmationsFromItemsMutation = { __typename?: 'RootMutationType', generateAndSendPurchaseOrderConfirmationsFromItems?: { __typename?: 'GenerateAndSendPurchaseOrderConfirmationsFromItemsPayload', confirmationsEnqueued: number } | null };


export const GenerateAndSendPurchaseOrderConfirmationsFromItemsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"generateAndSendPurchaseOrderConfirmationsFromItems"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateAndSendPurchaseOrderConfirmationsFromItemsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateAndSendPurchaseOrderConfirmationsFromItems"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmationsEnqueued"}}]}}]}}]} as unknown as DocumentNode<GenerateAndSendPurchaseOrderConfirmationsFromItemsMutation, GenerateAndSendPurchaseOrderConfirmationsFromItemsMutationVariables>;