export function parseToInt(value: number | string): number {
  let parsedValue: number | null = 0;

  if (typeof value === 'number') {
    parsedValue = Math.floor(value);
  } else if (typeof value === 'string') {
    parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 0;
    }
  }

  return parsedValue;
}
