import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import type * as Enums from 'graphql/__generated__/enums';

type AllEnums = typeof Enums;

const ENUM_VALUES = gql`
  query ($enumName: String!) {
    __type(name: $enumName) {
      enumValues {
        name
      }
    }
  }
`;

// In theory, we don't even need to fetch that value from backend. But actually we do, since BE can change.
export const useEnumValue = <EnumKey extends keyof AllEnums>(
  enumName: EnumKey
): { loading: boolean; enumValues: Array<keyof AllEnums[EnumKey]> } => {
  const { loading, data } = useQuery(ENUM_VALUES, {
    variables: {
      enumName
    }
  });

  const enumValues = useMemo(() => {
    if (data)
      return (data.__type?.enumValues || [])?.map(
        (value: { name: string }) => value?.name
      ) as Array<keyof AllEnums[EnumKey]>;
    return [];
  }, [data]);

  return { loading, enumValues };
};
