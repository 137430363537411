import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { CSVLink } from 'react-csv';
import {
  PurchaseOrderItem,
  PurchaseOrderWithOverdue,
  SalesOrder
} from 'pages/OrderDetailsPage/types';

interface DownloadFileModalProps {
  visibility: boolean;
  toggleDownloadFileModal: () => void;
  isConditionalColumnsEnabled: boolean | undefined;
  purchaseOrderItems?: PurchaseOrderItem[];
  salesOrders?: SalesOrder[];
  orderNumber: string;
  orderType: string;
  purchaseOrder: PurchaseOrderWithOverdue;
}

interface CsvHeader {
  label: string;
  key: string;
}

interface CsvPoItems {
  poId: string;
  gtin: string;
  quantityOrdered: string;
}

interface CsvSoItems {
  poId: string;
  gtin: string;
  name: string;
  unitOfMeasure: string;
  quantityOrdered: string;
  quantityPromised: string;
  quantityDelivered: string;
  volume: string;
}

const DownloadFileModal = ({
  purchaseOrderItems,
  salesOrders,
  visibility,
  toggleDownloadFileModal,
  isConditionalColumnsEnabled,
  orderNumber,
  orderType,
  purchaseOrder
}: DownloadFileModalProps) => {
  const [csvHeaders, setCsvHeaders] = useState<CsvHeader[]>([]);
  const [csvData, setCsvData] = useState<CsvSoItems[] | CsvPoItems[]>([]);

  //TODO: need to check with PMs about what we want to see for columns and headers - this is very old FE code that may need to be updated
  useEffect(() => {
    if (salesOrders && salesOrders.length) {
      orderType === 'WHD'
        ? setCsvHeaders([
            { label: 'PO #', key: 'poId' },
            { label: 'UPC', key: 'gtin' },
            { label: 'Name', key: 'name' },
            { label: 'UOM', key: 'unitOfMeasure' },
            { label: 'Ordered Qty', key: 'quantityOrdered' },
            { label: 'Qty Promised', key: 'quantityPromised' },
            { label: 'Qty Delivered', key: 'quantityDelivered' },
            { label: 'Volume (CFT)', key: 'volume' }
          ])
        : //Affected TPs are for Amazon GO with this FF enabled
        orderType === 'DSD' && isConditionalColumnsEnabled
        ? setCsvHeaders([
            { label: 'PO #', key: 'poId' },
            { label: 'GTIN', key: 'gtin' },
            { label: 'Name', key: 'name' },
            { label: 'UOM', key: 'unitOfMeasure' },
            { label: 'Ordered Qty', key: 'quantityOrdered' },
            { label: 'Qty Promised', key: 'quantityPromised' }, //TODO: understand do we want to show this as quantityOrdered like we do in the SalesOrderItemTable?
            { label: 'Qty Delivered', key: 'quantityDelivered' },
            { label: 'Volume (CFT)', key: 'volume' }
          ])
        : //Affected TPs are all other DSD TPs EXCEPT Amazon GO
        orderType === 'DSD' && !isConditionalColumnsEnabled
        ? setCsvHeaders([
            { label: 'PO #', key: 'poId' },
            { label: 'GTIN', key: 'gtin' },
            { label: 'Name', key: 'name' },
            { label: 'UOM', key: 'unitOfMeasure' },
            { label: 'Ordered Qty', key: 'quantityOrdered' },
            { label: 'Qty Promised', key: 'quantityPromised' },
            { label: 'Qty Delivered', key: 'quantityDelivered' }
          ])
        : //Affected TPs will be ALL ECOMM TPs - No WHD OR DSD.
          //TODO: need to check with PMs about what we want to see here - this is very old FE code that may need to be updated
          setCsvHeaders([
            { label: 'PO #', key: 'poId' },
            { label: 'GTIN', key: 'gtin' },
            { label: 'Name', key: 'name' },
            { label: 'UOM', key: 'unitOfMeasure' },
            { label: 'Ordered Qty', key: 'quantityOrdered' },
            { label: 'Qty Promised', key: 'quantityPromised' },
            { label: 'Qty Delivered', key: 'quantityDelivered' },
            { label: 'Volume (CFT)', key: 'volume' }
          ]);

      const soItems: CsvSoItems[] = salesOrders
        .map((salesOrder) => {
          return salesOrder.salesOrderItems.map((item) => {
            const qty = item.quantityOrdered || 0;
            if (item.catalogProduct && item.catalogProduct !== null) {
              return {
                poId: orderNumber,
                gtin: item.catalogProduct?.gtin12 || '',
                name: item.catalogProduct?.name || '',
                unitOfMeasure: item.unitOfMeasure || '',
                quantityOrdered: item.quantityOrdered?.toString() || '0',
                quantityPromised: item.quantityPromised?.toString() || '0',
                quantityDelivered: item.quantityDelivered?.toString() || '0',
                volume: `${(Number(item.product?.cubicFeet || 0) * qty).toFixed(2) || '0'}`
              };
            } else {
              return {
                poId: orderNumber,
                gtin: item.product?.upc || '',
                name: item.product?.name || '',
                unitOfMeasure: item.unitOfMeasure || '',
                quantityOrdered: item.quantityOrdered?.toString() || '0',
                quantityPromised: item.quantityPromised?.toString() || '0',
                quantityDelivered: item.quantityDelivered?.toString() || '0',
                volume: `${(Number(item.product?.cubicFeet || 0) * qty).toFixed(2) || '0'}`
              };
            }
          });
        })
        .flat();

      setCsvData(soItems);
    } else if (purchaseOrderItems && purchaseOrderItems.length) {
      if (purchaseOrderItems[0].productIdQualifier === 'UP') {
        setCsvHeaders([
          { label: 'PO #', key: 'poId' },
          { label: 'Ship-To', key: 'shipTo' },
          { label: 'UPC', key: 'gtin' },
          { label: 'Qty', key: 'quantityOrdered' }
        ]);
      } else {
        setCsvHeaders([
          { label: 'PO #', key: 'poId' },
          { label: 'Ship-To', key: 'shipTo' },
          { label: 'GTIN', key: 'gtin' },
          { label: 'Qty', key: 'quantityOrdered' }
        ]);
      }

      const poItems: CsvPoItems[] = purchaseOrderItems.map((item) => {
        if (item.catalogProduct && item.catalogProduct !== null) {
          return {
            poId: orderNumber,
            shipTo: purchaseOrder.retailerDeliveryDestination?.externalId,
            gtin: item.catalogProduct?.gtin12 || '',
            quantityOrdered: item.quantityOrdered?.toString() || '0'
          };
        } else {
          return {
            poId: orderNumber,
            shipTo: purchaseOrder.retailerDeliveryDestination?.externalId,
            gtin: item.product?.upc || '',
            quantityOrdered: item.quantityOrdered?.toString() || '0'
          };
        }
      });

      setCsvData(poItems);
    } else {
      setCsvData([]);
      setCsvHeaders([]);
    }
  }, [
    salesOrders,
    purchaseOrderItems,
    setCsvHeaders,
    orderType,
    orderNumber,
    purchaseOrder,
    isConditionalColumnsEnabled
  ]);

  return (
    <Modal
      open={visibility}
      onCancel={toggleDownloadFileModal}
      footer={
        <>
          <Button key="cancel" onClick={toggleDownloadFileModal}>
            Cancel
          </Button>
          <Button className="ant-btn-primary">
            <CSVLink
              onClick={toggleDownloadFileModal}
              filename={`${orderNumber}-items-quantity.csv`}
              headers={csvHeaders}
              data={csvData}
            >
              Download
            </CSVLink>
          </Button>
        </>
      }
      destroyOnClose
    >
      <div>Are you sure you want to download list of item quantities?</div>
    </Modal>
  );
};

export default DownloadFileModal;
