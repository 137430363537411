// Cool headers for the column
export const CLEAR_NOT_CLEAR = 'Clear / Not Clear';
export const PLANT = 'Plant';
export const MATERIAL = 'Material';
export const MATERIAL_DESCRIPTION = 'Material Description';
export const SAP_SL = 'SAP SL';
export const BATCH = 'Batch';
export const BUN = 'BUn';
export const DAYS = 'Days';
export const MB5B_QTY = 'MB5B Qty';
export const WMS_QTY = 'WMS Qty';
export const DIFF = 'Diff';
export const SHIP_TIMING_QTY = 'Ship Timing QTY';
export const REC_TIMING_QTY = 'Rec Timing Qty';
export const REC_DISCP_QTY = 'Rec Discp Qty';
export const MIGO = 'MIGO';
export const WHSE_ADJ_SD = 'WHSE ADJ (S/D)';
export const WHSE_ADJ_M10 = 'WHSE ADJ (MI10)';
export const OPEN_VARIANCE = 'Open Variance';
export const GTIN = 'GTIN';
export const BASE_UOM = 'Base UOM';

// Order is described in DESC-7834
export const INVENTORY_HEADERS = [
  CLEAR_NOT_CLEAR,
  PLANT,
  MATERIAL,
  MATERIAL_DESCRIPTION,
  GTIN,
  SAP_SL,
  BATCH,
  BUN,
  DAYS,
  MB5B_QTY,
  WMS_QTY,
  BASE_UOM,
  DIFF,
  SHIP_TIMING_QTY,
  REC_TIMING_QTY,
  REC_DISCP_QTY,
  MIGO,
  WHSE_ADJ_SD,
  WHSE_ADJ_M10,
  OPEN_VARIANCE
];

export const TRANSACTION_NUMBER = 'Transaction #';
export const ITEM = 'Item';
export const FROM_SL = 'From SL';
export const FROM_BATCH = 'From Batch';
export const TO_SL = 'To SL';
export const TO_BATCH = 'To Batch';
export const QTY = 'Qty';

export const PERFECT_SWAP_HEADERS = [
  TRANSACTION_NUMBER,
  PLANT,
  ITEM,
  TO_SL,
  TO_BATCH,
  FROM_SL,
  FROM_BATCH,
  QTY
];
