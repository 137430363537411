import { useQuery } from '@apollo/client';
import React from 'react';
import {
  TradingPartnerDocument,
  TradingPartnerQuery
} from 'pages/TradingPartnersPage/gql/__generated__/tradingPartner.query';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import { TradingPartnerFallbacksDocument } from 'pages/TradingPartnersPage/gql/__generated__/tradingPartnerFallbacks.query';
import { GeneralSettingsViewTab } from './components/ViewTradingPartner/components/GeneralSettingsViewTab/GeneralSettingsViewTab';
import { ShipToViewTab } from './components/ViewTradingPartner/components/ShipToViewTab/ShipToViewTab';
import { IntegrationsViewTab } from './components/ViewTradingPartner/components/IntegrationsViewTab/IntegrationsViewTab';
import { ActiveAssortmentViewTab } from './components/ViewTradingPartner/components/ActiveAssortmentViewTab/ActiveAssortmentViewTab';
import { ViewTabs } from 'components/Common/ViewMode/ViewTabs/ViewTabs';
import { StringParam, useQueryParam } from 'use-query-params';

interface EditingTradingPartnerModalProps {
  tradingPartnerId?: string;
  onClose: () => void;
  startEditing: (tradingPartner: TradingPartner) => void;
}

export type TradingPartner = NonNullable<TradingPartnerQuery['tradingPartner']>;
export type AssortmentConfig = NonNullable<TradingPartner['assortmentConfig']>;
export type SourcingRule = NonNullable<TradingPartner['sourcingRules'][number]>;
export type AllowedDistributionCenter = NonNullable<TradingPartner['distributionCenters'][number]>;

export const TradingPartnerViewDrawer = ({
  onClose,
  tradingPartnerId,
  startEditing
}: EditingTradingPartnerModalProps) => {
  const [firstOpenTab] = useQueryParam('editTab', StringParam);

  const { data: tradingPartnerData } = useQuery(TradingPartnerDocument, {
    variables: {
      id: tradingPartnerId || ''
    },
    skip: !tradingPartnerId
  });

  const { data: fallbacksData, loading: loadingFallbacksData } = useQuery(
    TradingPartnerFallbacksDocument
  );

  const tabs = tradingPartnerData?.tradingPartner
    ? [
        {
          label: 'General Settings',
          key: 'general',
          children: (
            <GeneralSettingsViewTab
              tradingPartner={tradingPartnerData?.tradingPartner}
              defaultSourcingRules={fallbacksData?.tradingPartnerFallbacks?.sourcingRules || []}
            />
          )
        },
        {
          label: 'Ship-Tos',
          key: 'shipTo',
          children: <ShipToViewTab tradingPartner={tradingPartnerData?.tradingPartner} />
        },
        {
          label: 'Integrations',
          key: 'integrations',
          children: <IntegrationsViewTab tradingPartner={tradingPartnerData?.tradingPartner} />
        },
        {
          label: 'Active Assortment',
          key: 'activeAssortment',
          children: (
            <ActiveAssortmentViewTab
              tradingPartnerExternalId={tradingPartnerData.tradingPartner.externalId}
              config={tradingPartnerData?.tradingPartner?.assortmentConfig}
            />
          )
        }
      ]
    : [];

  return (
    <EntityDrawer
      title={
        tradingPartnerData?.tradingPartner?.name ||
        tradingPartnerData?.tradingPartner?.externalId ||
        ''
      }
      open={!!tradingPartnerId}
      onClose={onClose}
      viewMode={<ViewTabs tabs={tabs} firstOpenTab={firstOpenTab ?? undefined} />}
      loading={!tradingPartnerData?.tradingPartner || loadingFallbacksData}
      startEditing={() =>
        tradingPartnerData?.tradingPartner && startEditing(tradingPartnerData?.tradingPartner)
      }
      withTabsContent
    />
  );
};
