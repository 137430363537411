import React, { useState } from 'react';
import { OptimizerStatus, RepackOptimizerStage } from 'graphql/__generated__/types';
import s from './StageSelector.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import clsx from 'clsx';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { AllStagesRepackOptimiserStatusesDocument } from './gql/__generated__/allStagesRepackOptimiserStatuses.query';
import { useQuery } from '@apollo/client';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';

const cheickIfIsMainStageCompleted = (
  mainStageStatus: OptimizerStatus | null | undefined,
  secondStageStatus: OptimizerStatus | null | undefined
): boolean => {
  return mainStageStatus === 'SUCCESS' && secondStageStatus !== 'NOT_STARTED';
};

export const StageSelector = () => {
  const [shouldRefetch, setShouldRefetch] = useState(true);

  // We DO NOT do withDefault here, so if there's no value (when user just opens the page)
  // they CAN get redirected to the second stage,
  // but if they DELIBERATELY selected 1st stage, then they won't be redirected.
  // If we want to ALWAYS redirect, put ` withDefault(StringParam, 'MAIN')` back
  const [stage, setStage] = useQueryParam('stage');

  // But we default to MAIN
  const activeStage = stage || 'MAIN';

  const { data, loading } = useQuery(AllStagesRepackOptimiserStatusesDocument, {
    pollInterval: shouldRefetch ? 15_000 : undefined,
    onCompleted: (data) => {
      // We stop refetching only when second stage stops working
      if (
        data.secondStageStatus?.status === 'SUCCESS' ||
        data.secondStageStatus?.status === 'FAILED'
      ) {
        setShouldRefetch(false);
      }

      // If second stage have started, switch to it
      if (
        cheickIfIsMainStageCompleted(
          data.mainStageStatus?.status,
          data.secondStageStatus?.status
        ) &&
        stage !== 'MAIN'
      ) {
        setStage('SECOND');
      }
    }
  });

  const mainStageStatus = data?.mainStageStatus?.status;
  const secondStageStatus = data?.secondStageStatus?.status;

  const isMainStageCompleted = cheickIfIsMainStageCompleted(mainStageStatus, secondStageStatus);

  return (
    <AlloySpin spinning={loading}>
      <div className={s.wrapper}>
        <button
          className={clsx(s.stage, { [s.active]: activeStage === 'MAIN' })}
          onClick={() => setStage('MAIN')}
        >
          <div
            className={clsx(s.icon, {
              [s.completed]: isMainStageCompleted,
              [s.failed]: mainStageStatus === 'FAILED'
            })}
          >
            <StatusIcon
              status={mainStageStatus}
              stage="MAIN"
              isMainStageCompleted={isMainStageCompleted}
            />
          </div>
          <div>
            <div className={s.title}>POSTO run</div>
            <div className={s.status}>
              <div className={s.status}>
                <Status
                  status={mainStageStatus}
                  stage="MAIN"
                  isMainStageCompleted={isMainStageCompleted}
                />
              </div>
            </div>
          </div>
        </button>
        <div className={s.divider} />
        <button
          className={clsx(s.stage, { [s.active]: activeStage === 'SECOND' })}
          onClick={() => setStage('SECOND')}
        >
          <div className={clsx(s.icon, { [s.failed]: secondStageStatus === 'FAILED' })}>
            <StatusIcon
              status={secondStageStatus}
              stage="SECOND"
              isMainStageCompleted={isMainStageCompleted}
            />
          </div>
          <div>
            <div className={s.title}>Forecasted demand</div>
            <div className={s.status}>
              <Status
                status={secondStageStatus}
                stage="SECOND"
                isMainStageCompleted={isMainStageCompleted}
              />
            </div>
          </div>
        </button>
      </div>
    </AlloySpin>
  );
};

const Status = ({
  status,
  stage,
  isMainStageCompleted
}: {
  status: OptimizerStatus | null | undefined;
  stage: RepackOptimizerStage;
  isMainStageCompleted: boolean;
}) => {
  if (stage === 'MAIN' && isMainStageCompleted) return <>Completed</>;
  if (status === 'FAILED') return <>Failed</>;
  if (status === 'NOT_STARTED') return <>Not Started</>;
  if (status === 'STARTED') return <>In Progress</>;
  if (status === 'SUCCESS') return <>Active</>;
  return <>...</>;
};

const StatusIcon = ({
  status,
  stage,
  isMainStageCompleted
}: {
  status?: OptimizerStatus | null;
  stage: RepackOptimizerStage;
  isMainStageCompleted: boolean;
}) => {
  if (stage === 'MAIN' && isMainStageCompleted)
    return <CheckOutlined style={{ fontSize: '12px' }} />;

  if (status === 'FAILED') return <>!</>;
  if (status === 'NOT_STARTED' && stage === 'MAIN') return <>1</>;
  if (status === 'NOT_STARTED' && stage === 'SECOND') return <>2</>;

  if (status === 'STARTED') return <LoadingOutlined style={{ fontSize: '12px' }} />;

  if (status === 'SUCCESS' && stage === 'MAIN') return <>1</>;
  if (status === 'SUCCESS' && stage === 'SECOND') return <>2</>;

  return <></>;
};
