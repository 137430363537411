import React from 'react';
import s from './InvalidItemsTableWHD.module.scss';
import { MoreOutlined } from '@ant-design/icons';
import errorIcon from '../../../../assets/icons/common/error_icon.svg';
import { PurchaseOrderItem, PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';
import { POI_ERROR_MAP, PO_STATUS_SUBMITTED } from 'common/constants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { TradingPartnerActiveAssortmentItem } from 'common/interfaces';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';

interface InvalidItemsTableWHDProps {
  loading: boolean;
  invalidItems: PurchaseOrderItem[];
  purchaseOrder: PurchaseOrderWithOverdue;
  openNewItemModal: (
    item?: Partial<TradingPartnerActiveAssortmentItem>,
    predefinedValues?: Partial<TradingPartnerActiveAssortmentItem>
  ) => void;
}

export const InvalidItemsTableWHD = ({
  loading,
  invalidItems,
  purchaseOrder,
  openNewItemModal
}: InvalidItemsTableWHDProps) => {
  const columns: ColumnsType<PurchaseOrderItem> = [
    {
      dataIndex: 'product',
      width: '44px',
      render: (_, item) => (
        <>
          {!!item.purchaseOrderItemErrors.length && (
            <div data-testid="error-display">
              <AlloyTooltip
                data-testid="error-display-tooltip"
                placement="right"
                title={item.purchaseOrderItemErrors
                  .map((error) => POI_ERROR_MAP[error] || error)
                  .join(', ')}
              >
                <img data-testid="error-icon" src={errorIcon} alt="" />
              </AlloyTooltip>
            </div>
          )}
        </>
      )
    },
    {
      title: 'UPC',
      dataIndex: ['product', 'upc'],
      width: '160px'
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      width: '124px',
      render: (_, item) => {
        const hasErrors = item?.purchaseOrderItemErrors && item.purchaseOrderItemErrors.length > 0;

        return (
          <div className={!hasErrors ? s.externalId : s.externalId_with_error}>
            {item.externalId}
          </div>
        );
      }
    },
    {
      title: 'Item Description',
      dataIndex: ['product', 'name']
    },
    {
      title: 'Order Qty',
      dataIndex: 'quantityOrdered',
      width: '103px'
    },
    {
      width: '46px',
      dataIndex: 'product',
      render: (_, item) => {
        const poItem = purchaseOrder.purchaseOrderItems.find(
          (i) => i.externalId === item.externalId
        );
        const itemErrors = poItem?.purchaseOrderItemErrors || [];
        const inCatalog = !itemErrors.includes('PRODUCT_NOT_FOUND');

        const showAddMenu =
          (itemErrors.includes('PRODUCT_NOT_FOUND') ||
            itemErrors.includes('PRODUCT_NOT_AVAILABLE_FOR_SALE')) &&
          (purchaseOrder.statuses?.primary || '').toUpperCase() !== PO_STATUS_SUBMITTED;

        return showAddMenu ? (
          <AlloyDropdown
            data-testid="add-item-dropdown"
            placement="bottomRight"
            trigger={['click']}
            menu={{
              items: [
                {
                  // Need to figure out how to expand this type, so it accepts data-testid
                  // @ts-ignore
                  'data-testid': 'add-item-menu-item',
                  key: '0',
                  label: inCatalog ? 'Add to assortment' : 'Add new product to assortment',
                  onClick: () => {
                    if (inCatalog) {
                      openNewItemModal({
                        active: true,
                        retailerDeliveryDestinations:
                          purchaseOrder.tradingPartner?.retailerDeliveryDestinations,
                        vendorProduct: poItem?.vendorProduct
                      } as Partial<TradingPartnerActiveAssortmentItem>);
                    } else {
                      openNewItemModal(undefined, {
                        retailerDeliveryDestinations:
                          purchaseOrder.tradingPartner?.retailerDeliveryDestinations,
                        vendorProduct: {
                          externalId: item.externalId,
                          substitutionProducts: [
                            {
                              product: {
                                name: item.productName,
                                // item doesn't have sapMaterialId. I'll leave it here for now.
                                // sapMaterialId: item.sapMaterialId,
                                upc:
                                  purchaseOrder.tradingPartner?.deliveryType === 'ECOMM'
                                    ? item.externalId
                                    : undefined
                              }
                            }
                          ]
                        }
                      } as Partial<TradingPartnerActiveAssortmentItem>);
                    }
                  }
                }
              ]
            }}
          >
            <MoreOutlined data-testid="more-icon" />
          </AlloyDropdown>
        ) : null;
      }
    }
  ];
  return (
    <>
      <div data-testid="invalid-items-count-whd" className={s.quantity_display}>
        INVALID ITEMS ({invalidItems.length})
      </div>
      <AlloyTable
        data-testid="invalid-items-table-whd"
        className={s.table}
        loading={loading}
        dataSource={invalidItems}
        columns={columns}
        rowKey={(item) => item.id}
        sticky
        tableLayout="auto"
        pagination={false}
      />
    </>
  );
};
