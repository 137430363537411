import React, { Dispatch, SetStateAction } from 'react';
import './styles.scss';
import LoaderSpinner from 'components/LoaderSpinner';
import { PO_STATUS_SUBMITTED } from 'common/constants';
import { AvailableActionSort, JoinedPo } from '../../BulkProcessSelect';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface BulkProcessCancelPOsModalProps {
  cancellablePOs: AvailableActionSort;
  loading: boolean;
  onConfirm: () => void;
  setShowCancelPOsModal: Dispatch<SetStateAction<boolean>>;
}

const BulkProcessCancelPOsModal = ({
  cancellablePOs,
  loading,
  onConfirm,
  setShowCancelPOsModal
}: BulkProcessCancelPOsModalProps) => {
  const hasCancellablePOs = cancellablePOs.allowed.length > 0;

  const displayPOs = () => {
    const allSubmitted =
      cancellablePOs.allowed.filter(
        (po) => (po.statuses?.primary || '').toUpperCase() === PO_STATUS_SUBMITTED
      ).length === cancellablePOs.allowed.length;
    const allUnsubmitted =
      cancellablePOs.allowed.filter(
        (po) => (po.statuses?.primary || '').toUpperCase() !== PO_STATUS_SUBMITTED
      ).length === cancellablePOs.allowed.length;
    const plural = cancellablePOs.allowed.length > 1 ? 's' : '';

    return (
      <>
        {hasCancellablePOs ? (
          <>
            <div data-testid="cancel-pos-title" style={{ fontWeight: 'bold' }}>
              {allUnsubmitted &&
                `The following (${cancellablePOs.allowed.length}) purchase order${plural} will be moved to a cancelled state in OrEx`}
              {allSubmitted &&
                `The following (${cancellablePOs.allowed.length}) purchase order${plural} will be cancelled in SAP`}
              {!allUnsubmitted &&
                !allSubmitted &&
                `The following (${cancellablePOs.allowed.length}) purchase order${plural} will be either cancelled internally or in SAP, depending on their current state`}
            </div>
            <div data-testid="cancel-pos-accepted-list" className="cancel_pos_accepted_list">
              {cancellablePOs?.allowed.map((po) => (
                <PoItem po={po} />
              ))}
            </div>
          </>
        ) : null}

        {cancellablePOs.disallowed.length ? (
          <>
            <div
              data-testid="rejected-pos-title"
              style={{ fontWeight: 'bold', paddingTop: '20px' }}
            >
              {`The following (${cancellablePOs.disallowed.length}) purchase order${
                cancellablePOs.disallowed.length > 1 ? 's' : ''
              } will NOT be cancelled:`}
            </div>
            <div data-testid="rejected-pos-accepted-list" className="cancel_pos_accepted_list">
              {cancellablePOs.disallowed?.map((po) => (
                <PoItem po={po} />
              ))}
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <AlloyModal
      data-testid="bulk-process-cancel-pos-modal"
      title={
        <span data-testid="bulk-process-cancel-pos-modal-title">Bulk Cancel Purchase Orders</span>
      }
      open={true}
      centered
      cancelButtonProps={{
        // @ts-ignore
        'data-testid': 'bulk-cancel-po-cancel-button'
      }}
      cancelText={<span>Cancel</span>}
      okText={<span>Yes, Continue</span>}
      okButtonProps={{
        disabled: !hasCancellablePOs,
        // @ts-ignore
        'data-testid': 'bulk-cancel-po-confirm-button'
      }}
      onOk={() => {
        onConfirm();
        setShowCancelPOsModal(false);
      }}
      onCancel={() => setShowCancelPOsModal(false)}
      destroyOnClose={true}
    >
      {loading ? <LoaderSpinner /> : <>{displayPOs()}</>}
    </AlloyModal>
  );
};

const PoItem = ({ po }: { po: JoinedPo }) => (
  <AlloyRow key={po.id} data-testid={`${po.customerPo}-row`} className="po_row">
    <AlloyCol span={9} data-testid={`${po.customerPo}-col`} className="poId">
      {po.customerPo}
    </AlloyCol>
    <AlloyCol span={15} data-testid={`${po.customerPo}-rdd-col`} className="rdd">
      {po.retailerDeliveryDestination
        ? `${po.retailerDeliveryDestination.name} - ${po.retailerDeliveryDestination.externalId}`
        : po.retailerDeliveryDestinationExternalId}
    </AlloyCol>
  </AlloyRow>
);

export default BulkProcessCancelPOsModal;
