import React, { Dispatch, useEffect, useState } from 'react';
import s from './EventsTable.module.scss';
import { App } from 'ant5';
import { DashOutlined, InfoCircleFilled } from '@ant-design/icons';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { MockData } from './mockData';
import moment from 'moment';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { useUrlParams } from 'common/helpers/urlParams';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

interface Event {
  id: string;
  name: string;
  createdBy: string;
  lastUpdated: string;
}

type SortBy = 'name' | 'createdBy' | 'lastUpdated';
type SortDirection = 'ASC' | 'DESC';

interface EventsTableFilters {
  search?: string;
  sort_by?: SortBy;
  direction?: SortDirection;
}

interface EventsTableProps {
  newEvent: Dispatch<React.SetStateAction<boolean>>;
}

export const EventsTable = ({ newEvent }: EventsTableProps) => {
  const { message } = App.useApp();
  const [filters, setFilters] = useState<EventsTableFilters>({});
  const [urlParams, setUrlParams] = useUrlParams();
  //TODO I could add something to urlParams that has allowed string values for columns and sort directions to prevent a weird URL from being used

  const [modalState, setModalState] = useState({
    clone: {
      show: false
    },
    edit: {
      show: false
    },
    delete: {
      show: false
    }
  });

  useEffect(() => {
    setFilters({
      search: urlParams.search as string,
      sort_by: urlParams.sort_by as SortBy,
      direction: urlParams.direction as SortDirection
    });
  }, [urlParams]);

  const columns: ColumnsType<Event> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '150px'
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdated',
      key: 'LastUpdated',
      width: '150px',
      render: (lastUpdated) => moment(lastUpdated).format('MM/DD/YY')
    },
    {
      title: '',
      width: '30px',
      render: (event) => {
        return (
          <AlloyDropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: <div className={s.dropdown}>Edit</div>,
                  key: 'edit'
                },
                {
                  label: 'Clone',
                  key: 'clone'
                },
                {
                  label: <span className={s.red}>Delete</span>,
                  key: 'delete'
                }
              ]
            }}
          >
            <DashOutlined
              data-testid={`po-state-dropdown-${event.id}`}
              rotate={90}
              className={s.menu_icon}
            />
          </AlloyDropdown>
        );
      }
    }
  ];

  return (
    <div className={s.events_table}>
      <div className={s.controls}>
        <div className={s.results}>2 Events {urlParams.search}</div>
        <div className={s.actions}>
          <AlloyButton type="primary" onClick={() => newEvent(true)}>
            New event
          </AlloyButton>
          <AlloyButton>Clone</AlloyButton>
          <AlloyButton>Delete</AlloyButton>
        </div>
      </div>
      <AlloyTable
        columns={columns}
        dataSource={MockData as Event[]}
        pagination={false}
        rowKey={(row) => row.id}
        rowSelection={{
          onChange: (selection) => console.log(selection)
        }}
      />
      <AlloyModal
        className={s.modal}
        title={<div className={s.title}>Clone event</div>}
        open={modalState.clone.show}
        onCancel={() => setModalState({ ...modalState, clone: { show: false } })}
        width={433}
        okText="Clone"
        onOk={() => {
          console.log('Clone event');
          setModalState({ ...modalState, clone: { show: false } });
          message.success('Cloned');
        }}
      >
        <div className={s.content}>
          <div className={s.clone_input}>
            <div className={s.label}>Event name</div>
            <AlloyInput placeholder="Choose a name" />
          </div>

          <div className={s.private}>
            <AlloyCheckbox></AlloyCheckbox>
            <div className={s.label}>Make event private</div>
            <AlloyTooltip title="Only you will be able to see this event" placement="bottom">
              <InfoCircleFilled className={s.icon} />
            </AlloyTooltip>
          </div>
        </div>

        <div className={s.divider_top}></div>
        <div className={s.divider_bottom}></div>
      </AlloyModal>
      <AlloyModal
        className={s.modal}
        title={<div className={s.title}>Delete event</div>}
        open={modalState.delete.show}
        onCancel={() => setModalState({ ...modalState, delete: { show: false } })}
        width={433}
        okButtonProps={{
          danger: true
        }}
        okText="Delete"
        onOk={() => {
          console.log('delete event');
          setModalState({ ...modalState, delete: { show: false } });
          message.success('Deleted');
        }}
      >
        <div className={s.content}>Are you sure you want to delete this event?</div>

        <div className={s.divider_top}></div>
        <div className={s.divider_bottom}></div>
      </AlloyModal>
    </div>
  );
};
