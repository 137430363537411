interface ExternalId {
  TP_EXTERNAL_ID: string[];
}

interface PlantCodes {
  PLANT_CODES: string[];
}

interface DcCodes {
  DC_CODES: string[];
}
export const dcSourceName: string = 'ChannelAdvisor';
export const plantSourceName: string = 'SAP';
export const invConstExternalId: ExternalId = {
  TP_EXTERNAL_ID: [
    'Amazon Drop Ship - ECOMM',
    'Amazon Drop Ship - Gatorade',
    'Amazon Drop Ship - PBC',
    'Amazon Drop Ship - Quaker',
    'GATORADETEAMZONE',
    'GATORADESHOP',
    'Kroger US Marketplace - Frito',
    'PANTRYSHOP',
    'PEPSICOSHOP',
    'ROCKSTAR',
    '183020',
    '412213',
    'Target Drop Ship - ECOMM',
    'Target Drop Ship - Quaker',
    'TikTok Marketplace - ECOMM',
    'Walmart.com Drop Ship - ECOMM',
    'TikTok Marketplace - Frito'
  ]
};
export const invConstPlantCodes: PlantCodes = {
  PLANT_CODES: [
    '1285',
    '1599',
    '1372',
    '1334',
    '1552',
    '1708',
    '1609',
    '1546',
    '1548',
    '1511',
    '3502',
    '1590',
    '1519',
    '1578',
    '3944',
    '3389',
    '4054',
    '3442',
    '2112',
    '2283',
    '3216',
    '3936',
    '3227',
    '1002',
    '2138',
    '2172',
    '4049',
    '3264',
    '2136',
    '2076',
    '1089',
    '3399',
    '2179',
    '2200',
    '2042',
    '2137',
    '1017',
    '2060',
    '3783',
    '1390',
    '1524',
    '1537',
    '2129',
    '2017',
    '2272',
    '2251',
    '2119',
    '4024',
    '2147',
    '2066',
    '1008'
  ]
};
export const invConstDcCodes: DcCodes = {
  DC_CODES: ['-1008', '-1005', '-1004', '-1002', '-1000', '10', '16', '17', '30', '35']
};
