import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetScenarioDetailsQueryVariables = Types.Exact<{
  scenarioId: Types.Scalars['ID'];
}>;


export type GetScenarioDetailsQuery = { __typename?: 'RootQueryType', getScenarioResultProductList: Array<{ __typename?: 'ScenarioResultProductDetails', id: string, existingDc: number, optimal: number, trend: boolean, demand: number, isNewProduct: number, gtin14Product?: { __typename?: 'Gtin14Product', id: string, gtin14: string, description?: string | null, salesBrand?: string | null, salesBusinessUnit?: string | null, ecommerceCategory?: string | null } | null }> };

export type GetCostInfoDataQueryVariables = Types.Exact<{
  scenarioId: Types.Scalars['ID'];
}>;


export type GetCostInfoDataQuery = { __typename?: 'RootQueryType', getScenariosList?: Array<{ __typename?: 'Scenario', id: string, scenarioName: string, base?: boolean | null, scenarioResult?: { __typename?: 'ScenarioResult', wastageCost: number, holdingCost: number, transportationCost: number, optimalHoldingCost: number, optimalWastageCost: number, optimalTransportationCost: number } | null } | null> | null };

export type GetInventoryAllocationDetailsQueryVariables = Types.Exact<{
  scenarioId: Types.Scalars['ID'];
  scenarioResultProductIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type GetInventoryAllocationDetailsQuery = { __typename?: 'RootQueryType', getScenarioResultInventoryAllocationDetailsList?: Array<{ __typename?: 'ScenarioResultInventoryAllocationDetails', id: string, dcName: string, optimalStock: number, optimalLayers: number, optimalPallets: any, currentStock: number, currentLayers: number, currentPallets: any } | null> | null };

export type GetSourcingStrategyDetailsQueryVariables = Types.Exact<{
  scenarioId: Types.Scalars['ID'];
  scenarioResultProductIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type GetSourcingStrategyDetailsQuery = { __typename?: 'RootQueryType', getScenarioResultSourcingStrategyDetailsList?: Array<{ __typename?: 'ScenarioResultSourcingStrategyDetails', id: string, plantName: string, dcName: string, optimalLayers: number, optimalPallets: any, optimalSourcing: number, currentLayers: number, currentPallets: any, currentSourcing: number } | null> | null };

export type GetScenarioDcListQueryVariables = Types.Exact<{
  scenarioId: Types.Scalars['ID'];
}>;


export type GetScenarioDcListQuery = { __typename?: 'RootQueryType', getScenarioDcList?: Array<{ __typename?: 'ScenarioDcDetails', id: string, holdingCost?: number | null, wastageCost: number, operatingCost: number, dcDetails?: { __typename?: 'DistributionCenter', name: string, id: string } | null } | null> | null };

export type GetScenarioConstraintsQueryVariables = Types.Exact<{
  scenarioId: Types.Scalars['ID'];
}>;


export type GetScenarioConstraintsQuery = { __typename?: 'RootQueryType', getScenarioConstraints?: Array<{ __typename?: 'ScenarioConstraint', id: string, tradingPartner?: { __typename?: 'TradingPartner', name?: string | null } | null, gtin14Product?: { __typename?: 'Gtin14Product', gtin14: string, description?: string | null } | null, existingScenarioConstraintDcDetails?: Array<{ __typename?: 'DistributionCenter', name: string } | null> | null, scenarioConstraintDcDetails?: Array<{ __typename?: 'DistributionCenter', name: string } | null> | null } | null> | null };


export const GetScenarioDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getScenarioDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenarioResultProductList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"existingDc"}},{"kind":"Field","name":{"kind":"Name","value":"optimal"}},{"kind":"Field","name":{"kind":"Name","value":"trend"}},{"kind":"Field","name":{"kind":"Name","value":"demand"}},{"kind":"Field","name":{"kind":"Name","value":"isNewProduct"}},{"kind":"Field","name":{"kind":"Name","value":"gtin14Product"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"gtin14"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"salesBrand"}},{"kind":"Field","name":{"kind":"Name","value":"salesBusinessUnit"}},{"kind":"Field","name":{"kind":"Name","value":"ecommerceCategory"}}]}}]}}]}}]} as unknown as DocumentNode<GetScenarioDetailsQuery, GetScenarioDetailsQueryVariables>;
export const GetCostInfoDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getCostInfoData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenariosList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"scenarioName"}},{"kind":"Field","name":{"kind":"Name","value":"scenarioResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wastageCost"}},{"kind":"Field","name":{"kind":"Name","value":"holdingCost"}},{"kind":"Field","name":{"kind":"Name","value":"transportationCost"}},{"kind":"Field","name":{"kind":"Name","value":"optimalHoldingCost"}},{"kind":"Field","name":{"kind":"Name","value":"optimalWastageCost"}},{"kind":"Field","name":{"kind":"Name","value":"optimalTransportationCost"}}]}},{"kind":"Field","name":{"kind":"Name","value":"base"}}]}}]}}]} as unknown as DocumentNode<GetCostInfoDataQuery, GetCostInfoDataQueryVariables>;
export const GetInventoryAllocationDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getInventoryAllocationDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioResultProductIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenarioResultInventoryAllocationDetailsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}}},{"kind":"Argument","name":{"kind":"Name","value":"scenarioResultProductIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioResultProductIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"dcName"}},{"kind":"Field","name":{"kind":"Name","value":"optimalStock"}},{"kind":"Field","name":{"kind":"Name","value":"optimalLayers"}},{"kind":"Field","name":{"kind":"Name","value":"optimalPallets"}},{"kind":"Field","name":{"kind":"Name","value":"currentStock"}},{"kind":"Field","name":{"kind":"Name","value":"currentLayers"}},{"kind":"Field","name":{"kind":"Name","value":"currentPallets"}}]}}]}}]} as unknown as DocumentNode<GetInventoryAllocationDetailsQuery, GetInventoryAllocationDetailsQueryVariables>;
export const GetSourcingStrategyDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSourcingStrategyDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioResultProductIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenarioResultSourcingStrategyDetailsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}}},{"kind":"Argument","name":{"kind":"Name","value":"scenarioResultProductIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioResultProductIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"plantName"}},{"kind":"Field","name":{"kind":"Name","value":"dcName"}},{"kind":"Field","name":{"kind":"Name","value":"optimalLayers"}},{"kind":"Field","name":{"kind":"Name","value":"optimalPallets"}},{"kind":"Field","name":{"kind":"Name","value":"optimalSourcing"}},{"kind":"Field","name":{"kind":"Name","value":"currentLayers"}},{"kind":"Field","name":{"kind":"Name","value":"currentPallets"}},{"kind":"Field","name":{"kind":"Name","value":"currentSourcing"}}]}}]}}]} as unknown as DocumentNode<GetSourcingStrategyDetailsQuery, GetSourcingStrategyDetailsQueryVariables>;
export const GetScenarioDcListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getScenarioDcList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenarioDcList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"dcDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"holdingCost"}},{"kind":"Field","name":{"kind":"Name","value":"wastageCost"}},{"kind":"Field","name":{"kind":"Name","value":"operatingCost"}}]}}]}}]} as unknown as DocumentNode<GetScenarioDcListQuery, GetScenarioDcListQueryVariables>;
export const GetScenarioConstraintsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getScenarioConstraints"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenarioConstraints"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"tradingPartner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"gtin14Product"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gtin14"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}},{"kind":"Field","name":{"kind":"Name","value":"existingScenarioConstraintDcDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"scenarioConstraintDcDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetScenarioConstraintsQuery, GetScenarioConstraintsQueryVariables>;