import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BulkGenerateAndSendSalesOrdersCustomizationsMutationVariables = Types.Exact<{
  input: Types.BulkGenerateAndSendSalesOrdersCustomizationsInput;
}>;


export type BulkGenerateAndSendSalesOrdersCustomizationsMutation = { __typename?: 'RootMutationType', bulkGenerateAndSendSalesOrdersCustomizations?: { __typename?: 'BulkGenerateAndSendSalesOrdersCustomizationsPayload', customizations: Array<{ __typename?: 'SentCustomization', status?: Types.CustomizationSendingStatus | null, salesOrders: Array<{ __typename?: 'SalesOrder', id: string, externalId?: string | null } | null> }>, customizationsErrors: Array<{ __typename?: 'SentCustomizationError', status?: Types.CustomizationSendingStatus | null, salesOrders: Array<{ __typename?: 'SalesOrder', id: string, externalId?: string | null } | null> }> } | null };


export const BulkGenerateAndSendSalesOrdersCustomizationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"bulkGenerateAndSendSalesOrdersCustomizations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BulkGenerateAndSendSalesOrdersCustomizationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bulkGenerateAndSendSalesOrdersCustomizations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"salesOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"customizationsErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"salesOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]}}]}}]} as unknown as DocumentNode<BulkGenerateAndSendSalesOrdersCustomizationsMutation, BulkGenerateAndSendSalesOrdersCustomizationsMutationVariables>;