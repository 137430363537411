import { yearPeriodWeekSorter } from 'common/helpers/periodHelper';
import { YearPeriodWeek } from 'graphql/__generated__/types';
import { OTIFReportData } from './types';
import { convertWeekToPeriodWeek } from 'common/helpers/fiscalCalendar';

const safeParsePercentage = (number: any) => {
  const value = parseFloat(number);
  if (Number.isNaN(value) || value === undefined) return undefined;
  return value * 100;
};

const calculateFillRate = (current: any, prev: any) => {
  const parsedCurrent = safeParsePercentage(current);
  const parsedPrev = safeParsePercentage(prev);
  if (parsedCurrent === undefined || parsedPrev === undefined) return undefined;
  if (parsedCurrent > parsedPrev) return 'increase';
  if (parsedCurrent < parsedPrev) return 'decrease';
  return undefined;
};

// Filter + add empty periods
export const mapDataWithPeriods = (
  data: OTIFReportData[] | undefined,
  periods: YearPeriodWeek[]
) => {
  const areMultipleYearsSelected = (periods || []).some((x) => x.year !== periods?.[0]?.year);

  const sortedMetrics = [...(data || [])].sort((a, b) =>
    yearPeriodWeekSorter(a.fiscalCalendarWeek, b.fiscalCalendarWeek)
  );

  const mappedMetrics = (sortedMetrics || []).map((x, idx) => {
    const previous = (sortedMetrics || [])[idx - 1];
    const { period, periodWeek } = convertWeekToPeriodWeek(x.fiscalCalendarWeek.week);
    return {
      ...x,
      label: `${
        areMultipleYearsSelected ? x.fiscalCalendarWeek.year : ''
      } P${period}W${periodWeek}`.trim(),
      otif: safeParsePercentage(x.otif),
      onTime: safeParsePercentage(x.onTime),
      inFull: safeParsePercentage(x.inFull),
      acceptedFillRate: safeParsePercentage(x.acceptedFillRate),
      submittedFillRate: safeParsePercentage(x.submittedFillRate),
      acceptedFillRateChange: calculateFillRate(x.acceptedFillRate, previous?.acceptedFillRate),
      submittedFillRateChange: calculateFillRate(x.submittedFillRate, previous?.submittedFillRate)
    };
  });

  return { metrics: mappedMetrics, areMultipleYearsSelected };
};

export type MappedResponseOtif = ReturnType<typeof mapDataWithPeriods>['metrics'][number];
