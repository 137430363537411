import React, { useState } from 'react';
import s from './ErrorsTable.module.scss';
import { clsx } from 'clsx';
import { sumBy } from 'lodash-es';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { RightOutlined, CheckCircleFilled, FlagFilled } from '@ant-design/icons';
import { SortOrder } from 'antd/lib/table/interface'; //TODO: find a way to migrate this to ant5
import { SortingType } from 'common/types';
import moment from 'moment';
import { ReportableErrorFromQuery } from '../../types';
import { Link } from 'react-router-dom';
import { NOT_AVAILABLE } from 'common/constants';

interface ErrorTableProps {
  errorsList: ReportableErrorFromQuery[];
  getSortOrder: (columnKey: string) => SortingType | undefined;
  handleTableSorting: (columnKey: string, order: string) => void | undefined;
  loading: boolean;
  selectedRows: ReportableErrorFromQuery[];
  setSelectedRows: (selectedRows: ReportableErrorFromQuery[]) => void;
}

export const ErrorsTable = ({
  errorsList,
  handleTableSorting,
  getSortOrder,
  loading,
  selectedRows,
  setSelectedRows
}: ErrorTableProps) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const toggleRow = (id: string) => {
    if (expandedRowKeys.includes(id)) setExpandedRowKeys([]);
    else setExpandedRowKeys([id]);
  };

  const columns: ColumnsType<ReportableErrorFromQuery> = [
    {
      title: 'DATE & TIME',
      key: 'INSERTED_AT',
      width: '140px',
      sorter: true,
      sortOrder: getSortOrder('INSERTED_AT'),
      render: (_, { insertedAt }) => (
        <div data-testid="reportable-error-timeline">
          {moment(insertedAt).format('MMM D YYYY, hh:mm A') || NOT_AVAILABLE}
        </div>
      )
    },
    AlloyTable.EXPAND_COLUMN,
    {
      title: 'EVENT',
      key: 'TYPE',
      sorter: true,
      sortOrder: getSortOrder('TYPE'),
      render: ({ updatedAt, message, count, details, id, type }) => {
        const isExpandable = !!message || !!details;
        return (
          <div data-testid="event-message-row" className={s.event_message_row}>
            <div data-testid="event-message-column">
              <div
                data-testid="event-message-content"
                className={clsx({ [s.short]: isExpandable })}
              >
                <div
                  onClick={(e) => {
                    if (isExpandable) {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleRow(id);
                    }
                  }}
                >
                  {type.replace(/_/g, ' ')}
                </div>
              </div>
              {count && count > 1 && (
                <div data-testid="event-last-updated-at" className={s.last_triggered_datetime}>
                  {`${'last triggered: '} ${moment(updatedAt).format('MMM D YYYY, hh:mm A')}` ||
                    NOT_AVAILABLE}
                </div>
              )}
            </div>

            <div data-testid="event-count" className={s.error_count_badge}>
              {count && count > 1 && <div className={s.error_count}>{count}</div>}
            </div>
          </div>
        );
      }
    },
    {
      title: 'ERROR STATUS',
      key: 'status',
      width: '150px',
      //sorter: true
      sortOrder: getSortOrder('STATUS'), //waiting for backend
      render: (_, { status }) => (
        <div className={s.error_visibility_status}>
          {status === 'RESOLVED' && (
            <>
              <CheckCircleFilled className={s.green} />
              <div>Resolved</div>
            </>
          )}

          {(status === 'OPEN' || !status) && (
            <>
              <FlagFilled className={s.red} />
              <div>Open</div>
            </>
          )}
        </div>
      )
    },
    {
      title: 'PO',
      key: 'CUSTOMER_PO',
      width: '150px',
      sorter: true,
      sortOrder: getSortOrder('CUSTOMER_PO'),
      render: ({ purchaseOrder }) => {
        return (
          <>
            {!!purchaseOrder?.id && (
              <div
                data-testid="reportable-error-po"
                className={clsx({ [s.highlight]: purchaseOrder.customerPo!! })}
              >
                <div className={s.container}>
                  <Link
                    to={`/orders/${purchaseOrder.id}`}
                    data-testid="link-to-po-details"
                    className={s.customer_po}
                  >
                    {purchaseOrder.customerPo}
                    <div
                      data-testid="live-vs-non-live-order-status"
                      className={clsx(purchaseOrder.live ? [s.live_order] : [s.non_live_order])}
                    />
                  </Link>
                </div>
              </div>
            )}
            {!purchaseOrder?.id && <div data-testid="po-not-available">{NOT_AVAILABLE}</div>}
          </>
        );
      }
    },
    {
      title: 'TRADING PARTNER',
      key: 'TRADING_PARTNER_EXTERNAL_ID',
      width: '160px',
      sorter: true,
      sortOrder: getSortOrder('TRADING_PARTNER_EXTERNAL_ID'),
      render: (_, { tradingPartner }) => (
        <div data-testid="reportable-error-tp">{tradingPartner?.externalId || NOT_AVAILABLE}</div>
      )
    },
    {
      title: 'CURRENT PO STATUS',
      key: 'PO_STATUS',
      width: '150px',
      // sorter: true,
      sortOrder: getSortOrder('PO_STATUS'), // waiting for BE
      render: (_, { purchaseOrder }) => (
        <div data-testid="reportable-errors-po-status">
          {purchaseOrder?.status || NOT_AVAILABLE}
        </div>
      )
    }
  ];

  const tableWidth = sumBy(columns, 'width');

  const mapOrder = (order: SortOrder | undefined) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return 'DESC';
  };

  return (
    <AlloyTable
      data-testid="errors-table"
      loading={loading}
      sticky
      scroll={{ x: tableWidth }}
      tableLayout="auto"
      pagination={false}
      columns={columns}
      dataSource={errorsList}
      rowKey={(row) => row.id}
      rowSelection={{
        preserveSelectedRowKeys: true,
        onChange: (_keys, rows) => {
          setSelectedRows(rows);
        },
        type: 'checkbox',
        selectedRowKeys: selectedRows?.map((row: any) => row.id),
        //@ts-ignore
        getCheckboxProps(record) {
          return { 'data-testid': record.purchaseOrder?.customerPo };
        }
      }}
      onChange={(_pagination, _filters, sorter) => {
        const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
        const { columnKey, order } = singleSorter;
        handleTableSorting(columnKey?.toString() || 'INSERTED_AT', mapOrder(order));
        return order === 'descend' || !order ? 'DESC' : 'ASC';
      }}
      sortDirections={['ascend', 'descend', 'ascend']}
      expandable={{
        expandedRowKeys,
        rowExpandable: (row) => !!row.message || !!row.details,
        expandIcon: ({ expandable, expanded, record }) => {
          return (
            <>
              {expandable && (
                <div data-testid="pointer-container" className={s.pointer_container}>
                  <RightOutlined
                    data-testid="pointer"
                    className={clsx(s.pointer, { [s.expanded]: expanded })}
                    onClick={(e) => toggleRow(record.id)}
                  />
                </div>
              )}
            </>
          );
        },
        expandedRowRender: (row) => {
          return (
            <div data-testid="expanded-row" className={s.expanded_row}>
              {!!row.message && (
                <div data-testid="details-with-shortMessage" className={s.details}>
                  <pre>{row.message}</pre>
                </div>
              )}
              {!!row.details && (
                <div data-testid="details-without-shortMessage" className={s.details}>
                  <pre>{row.details}</pre>
                </div>
              )}
            </div>
          );
        }
      }}
    />
  );
};
