import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ConsecutivelyCutOrdersQueryVariables = Types.Exact<{
  filters: Types.ConsecutivelyCutOrdersFilters;
}>;


export type ConsecutivelyCutOrdersQuery = { __typename?: 'RootQueryType', consecutivelyCutOrders: Array<{ __typename?: 'CutOrder', upc: string, productName: string, cutUnits: number, cutReason: string, lostOpportunity: number }> };


export const ConsecutivelyCutOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"consecutivelyCutOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConsecutivelyCutOrdersFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consecutivelyCutOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upc"}},{"kind":"Field","name":{"kind":"Name","value":"productName"}},{"kind":"Field","name":{"kind":"Name","value":"cutUnits"}},{"kind":"Field","name":{"kind":"Name","value":"cutReason"}},{"kind":"Field","name":{"kind":"Name","value":"lostOpportunity"}}]}}]}}]} as unknown as DocumentNode<ConsecutivelyCutOrdersQuery, ConsecutivelyCutOrdersQueryVariables>;