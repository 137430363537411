import React, { useMemo } from 'react';
import { SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { fieldNameToCapitalize } from 'common/helpers/stringsConverter';
import {
  DEFAULT_FORM_FIELDS_ORDER_ON_FORM,
  SHIP_TO_FORM_FIELD,
  SPECIAL_ASSORTMENT_FORM_FIELD,
  sorterBySlugs
} from '../../fieldsHelper';
import {
  AssortmentConfig,
  DistributionCenter,
  RetailerDeliveryDestination,
  SubstitutionProduct,
  TradingPartnerAssortment
} from 'pages/AssortmentPage/AssortmentPage';
import { LazyLoadingRddsList } from '../LazyLoadingRddsList/LazyLoadingRddsList';
import { DcsList } from '../LazyLoadingRddsList/DcsList';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import Copy from 'components/Common/Copy';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { capitalize } from 'lodash-es';

const columnsConfig = new Map<
  string,
  {
    width: number;
    render: (
      tradingPartnerId: string,
      substitutionProductView: SubstitutionDropdownConfig,
      retailerDeliveryDestinationsList: RetailerDeliveryDestination[],
      assortmentConfig: AssortmentConfig | undefined,
      distributionCenters: DistributionCenter[],
      setSubstitutionProductView: (substitutionProductView: SubstitutionDropdownConfig) => void,
      setViewChangeHistoryAssortment: (item: TradingPartnerAssortment | undefined) => void
    ) => (
      record: TradingPartnerAssortment
    ) => React.JSX.Element | string | number | null | undefined;
    title: (name: string | undefined) => React.JSX.Element | string;
  }
>([
  [
    'SHIP_TO',
    {
      width: 200,
      render:
        (tradingPartnerId, _, retailerDeliveryDestinationsList) =>
        (record: TradingPartnerAssortment) =>
          (
            <LazyLoadingRddsList
              count={(record.totalRdds || 0) - (record.vendorFlexIds?.length || 0)}
              firstRddId={record.firstRddId || ''}
              tpRdds={retailerDeliveryDestinationsList}
              tradingPartnerId={tradingPartnerId}
              retailerProductId={record?.retailerProductId}
            />
          ),
      title: (name) => <span data-testid="table-ship-to-title">{name || 'SHIP-TO'}</span>
    }
  ],
  [
    'CASES_PER_PALLET',
    {
      width: 100,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
          ?.casesPerPallet,
      title: (name) => <span data-testid="table-case-pal-title">{name || 'CASE/PAL'}</span>
    }
  ],
  [
    'HEIGHT',
    {
      width: 100,
      title: (name) => <span data-testid="table-height-title">{name || 'HEIGHT'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.height
    }
  ],
  [
    'DEPTH',
    {
      width: 100,
      title: (name) => <span data-testid="table-depth-title">{name || 'DEPTH'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.depth
    }
  ],
  [
    'EXTERNAL_ID',
    {
      width: 248,
      title: (name) => <div data-testid="table-ext-id-title">{name}</div>,
      render: () => (record: TradingPartnerAssortment) =>
        <Copy placement="top-left">{record.retailerProductExternalId}</Copy>
    }
  ],
  [
    'WIDTH',
    {
      width: 100,
      title: (name) => <span data-testid="table-width-title">{name || 'WIDTH'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.width
    }
  ],
  [
    'TO_CASE_QUANTITY',
    {
      width: 100,
      title: (name) => (
        <span data-testid="table-qtypercase-title">{name || 'QUANTITY PER CASE'}</span>
      ),
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
          ?.toCaseQuantity
    }
  ],
  [
    'INVENTORY_RESERVE',
    {
      width: 110,
      title: (name) => <span data-testid="table-reserve-title">{name || 'RESERVE'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        `${
          record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
            ?.inventoryReserve
        }`
    }
  ],
  [
    'GROSS_WEIGHT',
    {
      width: 130,
      title: (name) => <span data-testid="table-weight-title">{name}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.grossWeight
    }
  ],
  [
    'CURRENCY',
    {
      width: 100,
      title: (name) => <span data-testid="table-currency-title">{name || 'CURRENCY'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.currency ||
        ''
    }
  ],
  [
    'PRICE',
    {
      width: 90,
      title: (name) => <span data-testid="table-price-title">{name || 'PRICE'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.price || ''
    }
  ],
  [
    'MOQ_MINIMUM',
    {
      width: 100,
      title: (name) => <span data-testid="table-moqMinimum-title">{name || 'MOQ Minimum'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.moqMinimum ||
        ''
    }
  ],
  [
    'MOQ_UNIT_OF_MEASURE',
    {
      width: 100,
      title: (name) => (
        <span data-testid="table-moqUnitOfMeasure-title">{name || 'MOQ Unit Of Measure'}</span>
      ),
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
          ?.moqUnitOfMeasure || ''
    }
  ],
  [
    'SHIPS_IN_OWN_CONTAINER',
    {
      width: 110,
      title: (name) => <span data-testid="table-depth-title">{name || 'SIOC'}</span>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        `${
          record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
            ?.shipsInOwnContainer
            ? 'Y'
            : 'N'
        }`
    }
  ],
  [
    'REQUIRED_ORDER_UNIT_OF_MEASURE',
    {
      width: 120,
      title: (name) => (
        <span data-testid="table-requiredOrderUnitOfMeasure-title">
          {name || 'Required Order Unit Of Measure'}
        </span>
      ),
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
          ?.requiredOrderUnitOfMeasure || ''
    }
  ],
  [
    'SPECIAL_ASSORTMENT',
    {
      width: 90,
      title: () => 'VENDOR FLEX',
      render: (_, __, retailerDeliveryDestinationsList) => (record: TradingPartnerAssortment) =>
        (
          <ListOfItemsColumn
            items={retailerDeliveryDestinationsList
              ?.filter(
                (retailerDeliveryDestination) =>
                  record.vendorFlexIds?.includes(retailerDeliveryDestination.id) &&
                  retailerDeliveryDestination.specialAssortment
              )
              .map((destination: RetailerDeliveryDestination) => destination.externalId || '')}
          />
        )
    }
  ],
  [
    'SAP_MATERIAL_ID',
    {
      width: 200,
      title: (name) => <div data-testid="table-sapmatid-title">{name || 'SAP MATERIAL ID'}</div>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.sapMaterialId
    }
  ],
  [
    'UPC',
    {
      width: 180,
      title: (name) => <div data-testid="table-upc-title">{name || 'UPC'}</div>,
      render:
        (_, substitutionProductView, __, assortmentConfig, ___, setSubstitutionProductView) =>
        (record: TradingPartnerAssortment) =>
          ((assortmentConfig && assortmentConfig.multipleUpc) || !assortmentConfig) &&
          record.substitutions.length > 1 ? (
            <AlloySelect
              value={
                substitutionProductView[record?.retailerProductId] !== undefined
                  ? `${substitutionProductView[record?.retailerProductId]}`
                  : '0'
              }
              style={{ width: 160 }}
              onChange={(value) =>
                setSubstitutionProductView({
                  ...substitutionProductView,
                  [record?.retailerProductId]: value ? Number(value) : 0
                })
              }
              options={record.substitutions.map((product: SubstitutionProduct, index: number) => ({
                label: product?.upc || '',
                value: `${index}`
              }))}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ) : (
            <Copy placement="top-left">{record.substitutions[0]?.upc || ''}</Copy>
          )
    }
  ],
  [
    'NAME',
    {
      width: 240,
      title: (name) => <div data-testid="table-description-title">{name || 'DESCRIPTION'}</div>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.name
    }
  ],
  [
    'ORACLE_INVEN_ID',
    {
      width: 180,
      title: (name) => (
        <div data-testid="table-oracle-inventory-id-title">{name || 'ORACLE INVENTORY ID'}</div>
      ),
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        record.substitutions[substitutionProductView[record?.retailerProductId] || 0]?.oracleInvenId
    }
  ],
  [
    'DISTRIBUTION_CENTER_IDS',
    {
      width: 160,
      title: (name) => (
        <span data-testid="table-ship-to-title">{name || 'DISTRIBUTION CENTERS'}</span>
      ),
      render:
        (_, substitutionProductView, __, ___, distributionCenters) =>
        (record: TradingPartnerAssortment) => {
          const product =
            record.substitutions[substitutionProductView[record?.retailerProductId] || 0];
          return (
            <DcsList
              activeDcIds={
                record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
                  ?.activeDcIds
              }
              firstDcId={product.firstActiveDcId || ''}
              tpDcs={distributionCenters}
            />
          );
        }
    }
  ],
  [
    'PRODUCT_TYPE',
    {
      width: 140,
      title: (name) => <div data-testid="table-product-type">{name || 'Product Type'}</div>,
      render: (_, substitutionProductView) => (record: TradingPartnerAssortment) =>
        capitalize(
          record.substitutions[substitutionProductView[record?.retailerProductId] || 0]
            ?.productType || ''
        )
    }
  ]
]);

interface ActiveAssortmentTableProps {
  tradingPartnerId: string;
  selectedRows: TradingPartnerAssortment[];
  setSelectedRows: (selectedRows: TradingPartnerAssortment[]) => void;
  retailerDeliveryDestinationsList: RetailerDeliveryDestination[];
  activeAssortmentItems: TradingPartnerAssortment[];
  setViewChangeHistoryAssortment: (item: TradingPartnerAssortment | undefined) => void;
  updating: boolean;
  substitutionProductView: SubstitutionDropdownConfig;
  setSubstitutionProductView: (substitutionProductView: SubstitutionDropdownConfig) => void;
  config?: AssortmentConfig;
  distributionCenters: DistributionCenter[];
}

interface SubstitutionDropdownConfig {
  [key: string]: number;
}

const ActiveAssortmentTable = ({
  tradingPartnerId,
  selectedRows,
  setSelectedRows,
  activeAssortmentItems,
  retailerDeliveryDestinationsList,
  setViewChangeHistoryAssortment,
  updating,
  config,
  substitutionProductView,
  setSubstitutionProductView,
  distributionCenters
}: ActiveAssortmentTableProps) => {
  const [, setProductId] = useQueryParam('product', StringParam);
  const columnWidth = useMemo(() => {
    const width =
      config?.fields.reduce((result, current) => {
        const currentColumn = current.slug ? columnsConfig.get(current.slug) : undefined;
        return (result += currentColumn ? currentColumn.width : 140);
      }, 0) || window.innerWidth;
    // add value for updated at, selection, ship-to, product type and vendor flex
    return (
      width +
      182 +
      (columnsConfig.get('SHIP_TO')?.width || 0) +
      (config?.specialAssortment ? columnsConfig.get('SPECIAL_ASSORTMENT')?.width || 0 : 0)
    );
  }, [config?.fields, config?.specialAssortment]);

  const [sortColumnKey, setSortColumnKey] = useQueryParam<string>(
    'columnKey',
    withDefault(StringParam, 'UPDATED_AT')
  );
  const [sortOrder, setSortOrder] = useQueryParam<string>(
    'order',
    withDefault(StringParam, 'DESC')
  );

  const getColumnBySlug = (name?: string, slug?: string | null) => {
    if (slug && columnsConfig.get(slug)) {
      const columnData = columnsConfig.get(slug);
      return {
        title: columnData?.title(name),
        key: slug,
        width: columnData?.width,
        render: columnData?.render(
          tradingPartnerId,
          substitutionProductView,
          retailerDeliveryDestinationsList,
          config,
          distributionCenters,
          setSubstitutionProductView,
          setViewChangeHistoryAssortment
        )
      };
    } else {
      return {
        title: <div data-testid={`table-${name?.toLowerCase()}-title`}>{name}</div>,
        key: slug || '',
        render: (record: TradingPartnerAssortment) => {
          return (
            <span>
              {
                (
                  record.substitutions[
                    substitutionProductView[record?.retailerProductId] || 0
                  ] as any
                )[fieldNameToCapitalize(slug || name || '')]
              }
            </span>
          );
        }
      };
    }
  };

  const getUpdatedAtColumn = (name?: string) => ({
    title: <span data-testid="table-updated-title">{name || 'Last updated'}</span>,
    key: 'UPDATED_AT',
    sortOrder: getColumnSortOrder(sortColumnKey, sortOrder, 'UPDATED_AT'),
    sorter: true,
    width: 150,
    render: (record: TradingPartnerAssortment) => (
      <UpdatedAt
        onClick={(e) => {
          setViewChangeHistoryAssortment(record);
          e.stopPropagation();
        }}
        updatedAt={record.updatedAt}
        allowForNonAdminUsers
      />
    )
  });

  const getColumnsByConfig = (config: AssortmentConfig) => {
    const fields = (
      config.specialAssortment
        ? [...config.fields, SPECIAL_ASSORTMENT_FORM_FIELD, SHIP_TO_FORM_FIELD]
        : [...config.fields, SHIP_TO_FORM_FIELD]
    ).sort(sorterBySlugs(DEFAULT_FORM_FIELDS_ORDER_ON_FORM));

    const columns = fields.map((field) => {
      return getColumnBySlug(field.name, field.slug);
    });

    columns.push(getUpdatedAtColumn());

    return columns;
  };

  return config ? (
    <AlloyTable
      data-testid="aa-page-table"
      dataSource={activeAssortmentItems}
      scroll={{ x: columnWidth }}
      columns={getColumnsByConfig(config)}
      pagination={false}
      sticky
      rowKey={(record) => record?.retailerProductId}
      sortDirections={['ascend', 'descend', 'ascend']}
      onChange={(_, __, sorter) => {
        const columnKey =
          String((sorter as SorterResult<TradingPartnerAssortment>).columnKey) || 'PRODUCT_UPC';
        const order =
          (sorter as SorterResult<TradingPartnerAssortment>).order === 'ascend' ? 'ASC' : 'DESC';
        setSortColumnKey(columnKey);
        setSortOrder(order, 'replaceIn');
      }}
      loading={updating}
      onRow={(item) => {
        return {
          onClick: () => {
            setProductId(item.retailerProductId);
          }
        };
      }}
      rowSelection={{
        onChange: (keys, rows) => {
          setSelectedRows(rows);
        },
        type: 'checkbox',
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows.map((row) => row?.retailerProductId)
      }}
    />
  ) : null;
};

export default ActiveAssortmentTable;
