import React from 'react';
import { DrawerTitle } from '../DrawerTitle/DrawerTitle';
import s from './EntityDrawer.module.scss';
import { AlloyDrawer } from 'components/ui/AlloyDrawer/AlloyDrawer';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import clsx from 'clsx';

interface EntityDrawerProps {
  title: string;
  onClose: () => void;
  open: boolean;
  startEditing: () => void;
  viewMode: JSX.Element | null;
  loading: boolean;
  disableEditing?: boolean;
  withTabsContent?: boolean;
}

export const EntityDrawer = ({
  title,
  onClose,
  open,
  startEditing,
  viewMode,
  loading,
  disableEditing,
  withTabsContent
}: EntityDrawerProps) => {
  return (
    <AlloyDrawer
      width={540}
      data-testid="entity-drawer"
      closable={false}
      onClose={onClose}
      title={
        <DrawerTitle
          title={title}
          onEdit={startEditing}
          onCancel={onClose}
          loading={loading}
          disableEditing={disableEditing}
        />
      }
      open={open}
      destroyOnClose
      className={s.drawer}
      classNames={{
        header: s.header,
        body: clsx(s.body, { [s.withTabsContent]: withTabsContent })
      }}
    >
      {loading ? <AlloySpin spinning className={s.loading_spin} /> : viewMode}
    </AlloyDrawer>
  );
};
