import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import './styles.scss';
import LoaderSpinner from 'components/LoaderSpinner';
import { DownConfirmationTable } from '../DownConfirmationTable/DownConfirmationTable';
import {
  DownConfirmedPurchaseOrder,
  PurchaseOrderItemConfirmationButFromHook,
  PartialPOConfirmation,
  useDownConfirmedPOsAndGenerateConfirmations
} from '../DownConfirmationTable/hooks';
import { uniqBy } from 'lodash-es';
import { SubmitPurchaseOrderConfirmationsDocument } from './gql/__generated__/submitPurchaseOrderConfirmations.mutation';
import { ConfirmationType, PurchaseOrderConfirmation } from 'graphql/__generated__/types';
import { PurchaseOrderRefetchQueries } from 'common/constants';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { App } from 'ant5';

interface DownConfirmationModalProps {
  purchaseOrderIds: string[];
  setShowDownConfirmationModal: (value: boolean) => void;
}

type SimpleConfirmation = Pick<PurchaseOrderConfirmation, 'status'> & {
  purchaseOrderItemConfirmations: unknown[];
};

const getStatus = (confirmations: SimpleConfirmation[]) => {
  const generated = confirmations.find(
    (confirmation) => confirmation.status.toUpperCase() === 'GENERATED'
  );
  const processing = confirmations.find(
    (confirmation) => confirmation.status.toUpperCase() === 'PROCESSING'
  );
  const success = confirmations.find(
    (confirmation) => confirmation.status.toUpperCase() === 'SUCCESS'
  );
  const failure = confirmations.find(
    (confirmation) => confirmation.status.toUpperCase() === 'FAILURE'
  );

  let status = 'UNKNOWN_PLEASE_CONTACT_ADMIN';
  if (generated && generated.purchaseOrderItemConfirmations.length) {
    status = 'GENERATED';
  } else if (processing && processing.purchaseOrderItemConfirmations.length) {
    status = 'PROCESSING';
  } else if (success && success.purchaseOrderItemConfirmations.length) {
    status = 'SUCCESS';
  } else if (failure && failure.purchaseOrderItemConfirmations.length) {
    status = 'FAILURE';
  }

  return status;
};

export const DownConfirmationModal = ({
  purchaseOrderIds,
  setShowDownConfirmationModal
}: DownConfirmationModalProps) => {
  const { notification, modal: antModal } = App.useApp();
  const [purchaseOrders, setPurchaseOrders] = useState<DownConfirmedPurchaseOrder[]>([]);
  const [selectedRows, setSelectedRows] = useState<DownConfirmedPurchaseOrder[]>([]);

  const [submitBulkConfirmation, { loading: submitBulkConfirmationLoading }] = useMutation(
    SubmitPurchaseOrderConfirmationsDocument,
    {
      refetchQueries: PurchaseOrderRefetchQueries
    }
  );

  const { orders: poData, loading: poDataLoading } = useDownConfirmedPOsAndGenerateConfirmations({
    ids: purchaseOrderIds,
    batchSize: 15
  });

  useEffect(() => {
    if (!poData) return;

    const newPurchaseOrders = poData
      .filter(
        ({ asnStatus, totalAcceptedQuantity, totalOrderedQuantity }) =>
          asnStatus?.outstanding !== 0 ||
          (totalAcceptedQuantity !== null && totalAcceptedQuantity !== totalOrderedQuantity)
      )
      .reduce((previous: DownConfirmedPurchaseOrder[], purchaseOrder) => {
        let confirmations = purchaseOrder.purchaseOrderConfirmations?.filter(
          (conf) => conf.confirmationType === 'DOWN_CONFIRM'
        );

        if (confirmations?.length) {
          let confirmation = {
            purchaseOrderItemConfirmations: confirmations
              .reduce((items: PurchaseOrderItemConfirmationButFromHook[], confirmation) => {
                const modifiedItemConfirmations = confirmation.purchaseOrderItemConfirmations.map(
                  (item) => {
                    return {
                      ...item,
                      status: confirmation.status,
                      confirmationId: confirmation.id
                    };
                  }
                );
                return items.concat(modifiedItemConfirmations);
              }, [])
              .sort((a, b) =>
                (a.purchaseOrderItem.externalId || '').localeCompare(
                  b.purchaseOrderItem.externalId || ''
                )
              ),
            confirmationType: 'DOWN_CONFIRM' as ConfirmationType,
            status: getStatus(confirmations)
          };

          previous.push({
            asnStatus: purchaseOrder.asnStatus,
            // We need to try better to fix it
            confirmation: confirmation as any as PartialPOConfirmation,
            allConfirmations: confirmations as any as PurchaseOrderConfirmation[],
            customerPo: purchaseOrder.customerPo,
            deliveryWindowEnd: purchaseOrder.deliveryWindowEnd,
            deliveryWindowStart: purchaseOrder.deliveryWindowStart,
            externalId: purchaseOrder.externalId,
            id: purchaseOrder.id,
            totalAcceptedQuantity: purchaseOrder.totalAcceptedQuantity,
            totalOrderedQuantity: purchaseOrder.totalOrderedQuantity,
            purchaseOrderConfirmations: purchaseOrder.purchaseOrderConfirmations
          });
        }
        return previous;
      }, []);

    setPurchaseOrders(newPurchaseOrders);
    setSelectedRows(newPurchaseOrders);
  }, [poData]);

  const submit = async (ids: string[]) => {
    try {
      await submitBulkConfirmation({
        variables: {
          input: {
            ids: ids
          }
        }
      });

      const newPos = [...purchaseOrders];

      setPurchaseOrders(
        newPos.map((po) => {
          if (po.confirmation) {
            po.confirmation = { ...po.confirmation, status: 'PROCESSING' };
            po.confirmation.purchaseOrderItemConfirmations =
              po.confirmation.purchaseOrderItemConfirmations.map((item) => {
                return { ...item, status: 'PROCESSING' };
              });
          }
          return po;
        })
      );

      notification.success({
        message: 'Success',
        description: 'Bulk confirmation successfully submitted'
      });
    } catch (error) {
      console.error(error.message);
      notification.error({
        message: 'Error',
        description:
          'Unable to submit bulk confirmation. Please try again. An error has been logged for review.'
      });
    }
  };

  return (
    <AlloyModal
      title="Down Confirmation"
      data-testid="down-confirmation-modal"
      className="down_confirmation_modal"
      width={1100}
      onCancel={() => setShowDownConfirmationModal(false)}
      onOk={() => {
        const ids = purchaseOrders.reduce((ids, po: DownConfirmedPurchaseOrder) => {
          const generatedIds = po.allConfirmations
            .filter((confirmation) => confirmation.status.toUpperCase() === 'GENERATED')
            .map((confirmation) => confirmation.id);
          return ids.concat(generatedIds);
        }, [] as string[]);
        antModal.confirm({
          title: 'Down Confirm all POs',
          content: `The action you are about to perform will impact all ${ids.length} POs.
          Are you sure you want to proceed?`,
          okText: 'Confirm All',
          // @ts-ignore
          'data-testid': 'down-confirmation-modal-bulk-confirmation',
          okButtonProps: {
            // @ts-ignore
            'data-testid': 'yes-continue-button'
          },
          cancelText: 'Cancel',
          cancelButtonProps: {
            // @ts-ignore
            'data-testid': 'cancel-button'
          },
          onOk: () => {
            submit(ids);
          }
        });
      }}
      okText="Submit All"
      okButtonProps={
        !purchaseOrders.find((po) => po.confirmation.status === 'GENERATED')
          ? { disabled: true }
          : { disabled: false }
      }
      open={true}
    >
      {submitBulkConfirmationLoading ? (
        <LoaderSpinner />
      ) : (
        <DownConfirmationTable
          poDataLoading={poDataLoading || submitBulkConfirmationLoading}
          purchaseOrders={uniqBy(purchaseOrders, 'id')}
          setPurchaseOrders={setPurchaseOrders}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
    </AlloyModal>
  );
};
