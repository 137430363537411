import React, { useState, useEffect, ButtonHTMLAttributes, ReactElement } from 'react';
import './Button.scss';
import clsx from 'clsx';
import { BaseProperties } from '../Utils';

import '@fontsource/roboto';

interface Option {
  label: string;
  value: string;
  dataTestid?: string;
}

interface OptionMap {
  [index: string]: string;
}

const buttonArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.66666 5.33334L7.82608 11.1643C7.84784 11.1889 7.87457 11.2086 7.90451 11.2221C7.93445 11.2356 7.96692 11.2425 7.99976 11.2425C8.0326 11.2425 8.06506 11.2356 8.095 11.2221C8.12495 11.2086 8.15168 11.1889 8.17344 11.1643L13.3333 5.33334"
      stroke="white"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

type OptionsType = (Option | string)[] | OptionMap;

interface ButtonProps extends Omit<BaseProperties, 'size'> {
  type?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'outline' | 'default';
  size?: 'small' | 'medium' | 'large';
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLElement>, selection?: string) => void;
  name?: string;
  icon?: JSX.Element;
  options?: OptionsType;
  align?: 'left' | 'right';
  children?: string | ReactElement;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  ref?: React.LegacyRef<HTMLButtonElement>;
}

const getOptions = (options: OptionsType) => {
  let optionsArray: Option[] = [];

  if (Array.isArray(options)) {
    for (let option of options) {
      if (typeof option !== 'object') {
        optionsArray.push({
          label: option,
          value: option
        });
      } else {
        optionsArray.push(option);
      }
    }
  } else if (options) {
    for (let option of Object.keys(options)) {
      optionsArray.push({
        label: option,
        value: options[option]
      });
    }
  }

  return optionsArray;
};

export const Button = (props: ButtonProps) => {
  const {
    type = 'primary',
    size = 'large',
    label,
    disabled = false,
    onClick,
    name,
    icon,
    options,
    align = 'right',
    children,
    buttonType = 'button',
    ref,
    className,
    style
  } = props;

  const dataTestId = props['data-testid'];

  const [optionsArray, setOptionsArray] = useState(getOptions(options || []));
  const [allowOptions, setAllowOptions] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(false);

  useEffect(() => {
    if (!displayOptions) return;

    function handleClickOutside() {
      if (displayOptions) setDisplayOptions(false);
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [displayOptions, setDisplayOptions]);

  //The options argument allows multiple types. Mapping is required to standardize the type for use.
  useEffect(() => {
    if (!options && !optionsArray.length) return;
    let normalizedOptions = getOptions(options || []);
    setOptionsArray(normalizedOptions);

    if (size === 'small' || type === 'tertiary' || type === 'danger') {
      console.warn(
        'button dropdown options are defined, but are not allowed when using "small", "tertiary", or "danger"'
      );
      setAllowOptions(false);
    } else {
      setAllowOptions(true);
    }
  }, [options, size, type, optionsArray.length]);

  const getClasses = () => {
    let classes: string[] = [type, size];

    if (disabled) classes.push('disabled');

    return classes.join(' ');
  };

  return (
    <button
      ref={ref}
      data-testid={dataTestId}
      name={name}
      className={clsx(`orex-button ${getClasses()}`, className)}
      type={buttonType}
      onClick={(e) => {
        if (disabled) return;

        if (optionsArray.length && allowOptions) {
          setDisplayOptions(!displayOptions);
        } else {
          if (onClick) onClick(e);
        }
      }}
      style={style}
    >
      <div className={`orex-button-content ${getClasses()}`}>
        {icon && <div className="icon">{icon}</div>}
        <div className="text">{label || children}</div>
        {!!optionsArray.length && allowOptions && <span className="arrow">{buttonArrow}</span>}
      </div>
      {!!optionsArray.length && allowOptions && (
        <ul className={`options ${align} ${displayOptions ? 'show' : ''}`}>
          {optionsArray.map((option, i) => {
            return (
              <li
                key={option.value}
                className="option"
                onClick={(e) => {
                  if (onClick) onClick(e, option.value);
                  else console.warn('Button option clicked, but no onClick is defined');
                }}
                data-testid={option.dataTestid}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      )}
    </button>
  );
};
