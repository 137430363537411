import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InventoryRepackCutsReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryRepackFilters>;
}>;


export type InventoryRepackCutsReportQuery = { __typename?: 'RootQueryType', inventoryRepackCutsReport?: { __typename?: 'InventoryRepackCutsReport', metrics?: Array<{ __typename?: 'InventoryRepackCutsReportMetrics', id: string, asin?: string | null, cutReason?: string | null, cutQty?: number | null, itemId: string, productTitle?: string | null, recoveryPeriod?: string | null, type?: Types.RepackItemType | null, isMoved: boolean, lastChangeInfo?: { __typename?: 'RepackLastChangeInfo', comment?: string | null, reason: string, updatedAt: any, userName: string } | null }> | null } | null };


export const InventoryRepackCutsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"inventoryRepackCutsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryRepackFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryRepackCutsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"asin"}},{"kind":"Field","name":{"kind":"Name","value":"cutReason"}},{"kind":"Field","name":{"kind":"Name","value":"cutQty"}},{"kind":"Field","name":{"kind":"Name","value":"itemId"}},{"kind":"Field","name":{"kind":"Name","value":"productTitle"}},{"kind":"Field","name":{"kind":"Name","value":"recoveryPeriod"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"lastChangeInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isMoved"}}]}}]}}]}}]} as unknown as DocumentNode<InventoryRepackCutsReportQuery, InventoryRepackCutsReportQueryVariables>;