import React from 'react';
import s from './TotalMetricsView.module.scss';
import { FillRateReportMetricFragment } from 'pages/FillRateReport/gql/__generated__/fillRateReportMetric.fragment';
import { MetricIndicator } from 'components/MetricIndicator/MetricIndicator';
import { FrtType } from 'pages/FillRateReport/types';

interface TotalMetricsViewProps {
  totalMetrics?: FillRateReportMetricFragment;
  previousTotalMetrics?: FillRateReportMetricFragment;
  isRange: boolean;
  previousPeriodStrings?: string[];
}

const indicators: {
  name: FrtType;
  title: string;
  lessIsBetter?: boolean;
  treshold?: number;
  isPercentage?: boolean;
}[] = [
  {
    name: 'submittedQuantity',
    title: 'Orig. Order Cases'
  },
  {
    name: 'deliveredQuantity',
    title: 'Delivered Cases'
  },
  {
    name: 'cutQuantity',
    title: 'Cut Quantity',
    lessIsBetter: true
  },
  {
    name: 'acceptedFillRate',
    title: 'Pep Fill Rate',
    treshold: 80,
    isPercentage: true
  },
  {
    name: 'submittedFillRate',
    title: 'Customer Fill Rate',
    treshold: 75,
    isPercentage: true
  }
];

export const TotalMetricsView = ({
  totalMetrics,
  previousTotalMetrics,
  isRange,
  previousPeriodStrings
}: TotalMetricsViewProps) => {
  return (
    <div className={s.indicators_container}>
      {totalMetrics &&
        indicators.map((indicator) => (
          <MetricIndicator
            key={indicator.name}
            title={indicator.title}
            // We need to parse them as numbers because types are incorrect. Oops.
            value={parseFloat(totalMetrics[indicator.name] as unknown as string)}
            previous={
              isRange && previousTotalMetrics
                ? {
                    value: parseFloat(previousTotalMetrics?.[indicator.name] as unknown as string),
                    tooltip: previousPeriodStrings
                      ? `${previousPeriodStrings[0]} - ${previousPeriodStrings[1]}`
                      : '',
                    text: 'Than Last Interval',
                    lessIsBetter: indicator.lessIsBetter
                  }
                : undefined
            }
            treshold={indicator?.treshold}
            isPercentage={indicator.isPercentage}
          />
        ))}
    </div>
  );
};
