/*
This component acts as a container for scenario details page
It embeds DCAnalysisHeader, CostInfo and AllSKUsInfo components
*/
import React from 'react';
import DCAnalysisHeader from '../DCAnalysisHeader/DCAnalysisHeader';
import CostInfo from '../CostInfo/CostInfo';
import s from './ScenarioDetailsContainer.module.scss';
import AllSKUsInfo from '../AllSKUsInfo/AllSKUsInfo';

const ScenarioDetailsContainer: React.FC = () => {
  return (
    <div className={s.container}>
      <div>
        <DCAnalysisHeader />
        <CostInfo />
      </div>
      <AllSKUsInfo />
    </div>
  );
};

export default ScenarioDetailsContainer;
