import clsx from 'clsx';
import React from 'react';
import s from './DropdownList.module.scss';
import { OptionType } from '../../alloy/Select/Select';
import ReactDOM from 'react-dom';

interface DropdownListProps {
  menuProps: any;
  items: (OptionType & { props: any })[];
  selectedItem?: string | (string | number)[] | number | null;
  isOpen: boolean;
  highlightedIndex: number;
  dropdownRender?: (menu: React.ReactNode) => React.ReactNode;
  'data-testid'?: string;
}

const isGroup = (item: OptionType) => {
  return !!(item as OptionType).children;
};

const isSelected = (
  value: string | number | null | undefined,
  selectedItem?: string | (string | number)[] | number | null
) => {
  if (!selectedItem || !value) return false;
  if (Array.isArray(selectedItem)) return selectedItem.includes(value);
  return value === selectedItem;
};

export const DropdownList = ({
  menuProps,
  items,
  selectedItem,
  isOpen,
  highlightedIndex,
  dropdownRender,
  'data-testid': dataTestid
}: DropdownListProps) => {
  const menu = (
    <>
      {items.map((item, index) =>
        isGroup(item) ? (
          <>
            <li className={s.group} key={`${item.value}${index}`} {...item.props}>
              <span>{item.label}</span>
            </li>
            {item.children?.map((child) => (
              <li
                className={clsx(s.item, s.child, {
                  [s.highlighted]: highlightedIndex === index,
                  [s.selected]: isSelected(child.value, selectedItem)
                })}
                key={`${child.value}${index}`}
                role="option"
                {...item.props}
              >
                <span>{child.label}</span>
              </li>
            ))}
          </>
        ) : (
          <li
            className={clsx(s.item, {
              [s.highlighted]: highlightedIndex === index,
              [s.selected]: isSelected(item.value, selectedItem)
            })}
            key={`${item.value}${index}`}
            aria-disabled={item.disabled}
            role="option"
            {...item.props}
          >
            <span>{item.label}</span>
          </li>
        )
      )}
    </>
  );

  return ReactDOM.createPortal(
    <div {...menuProps} data-testid={dataTestid}>
      <ul className={clsx(s.dropdown_list, { [s.open]: isOpen })}>
        {dropdownRender ? dropdownRender(menu) : menu}
      </ul>
    </div>,
    document.body
  );
};
