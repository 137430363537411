import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './PermissionsPage.module.scss';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { getPageSize, Paginator } from 'components/Paginator/Paginator';
import { PermissionsList } from 'pages/PermissionsPage/components/PermissionsList/PermissionsList';
import { parse, stringify } from 'query-string';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { App } from 'ant5';
import { difference } from 'lodash-es';
import { SearchField } from 'components/Common/SearchField/SearchField';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { ExportAllUsers } from './components/ExportAllUsers';
import { GetUsersListDocument, GetUsersListQuery } from './gql/__generated__/listUsers.query';
import {
  DistributionCentersForPermissionsDocument,
  DistributionCentersForPermissionsQuery
} from './gql/__generated__/distributionCentersForPermissions.query';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  TradingPartnersForPermissionsDocument,
  TradingPartnersForPermissionsQuery
} from './gql/__generated__/tradingPartnersForPermissions.query';
import { SortOrderDirection, UserSort, UserSortColumn } from 'graphql/__generated__/types';
import { DeactivateUserDocument } from './gql/__generated__/deactivateUser.mutation';
import { ReactivateUserDocument } from './gql/__generated__/reactivateUser.mutation';
import { UpdateUserDocument } from './gql/__generated__/updateUser.mutation';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';

export interface LocationUpdate {
  limit?: number | null;
  after?: string | null;
  before?: string | null;
  searchLike?: string | null;
  [index: string]: string | number | undefined | null | string[];
}

type User = InferNodeType<GetUsersListQuery, 'listUsers'>;

export interface ExtendedUser extends User {
  tradingPartnerIds: string[];
  distributionCenterIds: string[];
}

export type TradingPartnerForPermission = InferNodeType<
  TradingPartnersForPermissionsQuery,
  'tradingPartners'
>;

export type DistributionCenterForPermission = InferNodeType<
  DistributionCentersForPermissionsQuery,
  'distributionCenters'
>;

export const PermissionsPage = () => {
  const { message } = App.useApp();
  const history = useHistory();
  const location = parse(history.location.search);

  const search = useMemo(() => {
    return typeof location.search === 'string' ? location.search : '';
  }, [location]);

  const sortConfig = useMemo<UserSort>(() => {
    return {
      direction:
        typeof location.order === 'string' ? (location.order as SortOrderDirection) : 'DESC',
      column:
        typeof location.column === 'string' ? (location.column as UserSortColumn) : 'UPDATED_AT'
    };
  }, [location]);

  const { loading: tpLoading, data: tpData } = useQuery(TradingPartnersForPermissionsDocument, {
    variables: {
      first: 10000,
      ignoreAllowedTradingPartners: true,
      sort: { column: 'NAME', direction: 'ASC' }
    }
  });

  const { data: dcsData, loading: dcsLoading } = useQuery(
    DistributionCentersForPermissionsDocument,
    {
      variables: {
        first: 100000,
        ignoreAllowedTradingPartners: true,
        sort: { column: 'NAME', direction: 'ASC' }
      }
    }
  );

  // updates page to show users and their permissions
  const updateUsers = (data: GetUsersListQuery) => {
    const userList = getNodesFromEdges(data.listUsers).map((user, i) => {
      const tradingPartnerIds: string[] = [];
      const distributionCenterIds: string[] = [];

      for (const scopedAuthorization of user.scopedAuthorizations || []) {
        const tradingPartner = scopedAuthorization?.tradingPartner;
        if (tradingPartner) {
          tradingPartnerIds.push(tradingPartner.id);
        }
        const distributionCenter = scopedAuthorization?.distributionCenter;
        if (distributionCenter) {
          distributionCenterIds.push(distributionCenter.id);
        }
      }

      const modifiedUser: ExtendedUser = {
        ...user,
        roles: user.roles || [],
        email: user.email || '',
        authMethod: user.authMethod || 'USER_PASS',
        name: user.name || '',
        scopedAuthorizations: user.scopedAuthorizations || [],
        tradingPartnerIds,
        distributionCenterIds
      };

      return modifiedUser;
    });

    return userList;
  };

  // queries users, runs updateUsers function to set the users
  const {
    error,
    data,
    loading: usersLoading
  } = useQuery(GetUsersListDocument, {
    variables: {
      first: location.after || !location.before ? getPageSize(location) : null,
      last: !location.after && location.before ? getPageSize(location) : null,
      after: location.after ? (location.after as string) : undefined,
      before: location.before && !location.after ? (location.before as string) : undefined,
      searchLike: search || null,
      sort: sortConfig
    }
  });

  const updatePaging = useCallback(
    (update?: LocationUpdate) => {
      const location = parse(history.location.search);
      if (update) {
        Object.keys(update).forEach((key) => {
          const t = update[key];
          location[key] = t === undefined ? null : typeof t === 'number' ? `${t}` : t;
          if (!update[key]) {
            delete location[key];
          }
        });
      }
      history.push({
        search: stringify(location)
      });
    },
    [history]
  );

  useEffect(() => {
    if (!data || history.location.search || usersLoading) return;

    updatePaging({
      column: 'UPDATED_AT',
      order: 'DESC',
      cursor: null,
      limit: DEFAULT_PAGE_SIZE,
      before: null,
      after: null,
      searchLike: null
    });
  }, [data, updatePaging, usersLoading, history.location.search, history]);

  const users = useMemo<ExtendedUser[]>(() => {
    if (data) return updateUsers(data);
    return [];
  }, [data]);

  const handlePageSizeChange = async (value: number) => {
    updatePaging({ limit: value, after: null, before: null });
  };

  const nextPage = async () => {
    updatePaging({ after: data?.listUsers?.pageInfo?.endCursor, before: null });
  };

  const prevPage = async () => {
    updatePaging({ before: data?.listUsers?.pageInfo?.startCursor, after: null });
  };

  const handleSearch = (value: string) => {
    if (search !== value) {
      updatePaging({ search: value, before: null, after: null });
    }
  };

  const [updateUser] = useMutation(UpdateUserDocument, {
    refetchQueries: [GetUsersListDocument],
    onCompleted: () => {
      message.success('User was successfully updated');
    }
  });

  const [reactivateUser] = useMutation(ReactivateUserDocument, {
    refetchQueries: [GetUsersListDocument],
    onCompleted: () => {
      message.success('User was successfully reactivated');
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`An error occurred while reactivating user. ${error.message}`);
    }
  });

  const [deactivateUser] = useMutation(DeactivateUserDocument, {
    refetchQueries: [GetUsersListDocument],
    onCompleted: () => {
      message.success('User was successfully deactivated');
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`An error occurred while deactivating user. ${error.message}`);
    }
  });

  if (error)
    return (
      <div>
        <ErrorDisplay error={error} />
      </div>
    );

  const processDeactivateUser = (user: User) => {
    deactivateUser({
      variables: {
        input: {
          id: user.id
        }
      }
    });
  };

  const processReactivateUser = (user: User) => {
    // "Albric Malfait"
    reactivateUser({
      variables: {
        input: {
          id: user.id
        }
      }
    });
  };

  const saveUser = async (user: ExtendedUser) => {
    const tpToAdd = difference(
      user.tradingPartnerIds,
      user.scopedAuthorizations.map((auth) => auth?.tradingPartner?.id)
    ).map((item) => {
      return { tradingPartnerId: item || '' };
    });

    const dcToAdd = difference(
      user.distributionCenterIds,
      user.scopedAuthorizations.map((auth) => auth?.distributionCenter?.id)
    ).map((item) => {
      return { distributionCenterId: item || '' };
    });

    const toRemove = user.scopedAuthorizations
      .filter((scopedAuthorization) => {
        if (!user.tradingPartnerIds.length && !user.distributionCenterIds.length) return true;

        return (
          !user.tradingPartnerIds.find((tp) => tp === scopedAuthorization?.tradingPartner?.id) &&
          !user.distributionCenterIds.find(
            (dc) => dc === scopedAuthorization?.distributionCenter?.id
          )
        );
      })
      .map((scopedAuthorization) => {
        return { scopedAuthorizationId: scopedAuthorization?.id || '' };
      });

    if (!user.tradingPartnerIds.length && !user.distributionCenterIds.length) {
      message.error(`Please select at least 1 Trading Partner or Distribution Center`);
    } else {
      const userPayload = {
        id: user.id,
        scopedAuthorizations: {
          toAdd: [...tpToAdd, ...dcToAdd],
          toRemove
        }
      };

      try {
        await updateUser({ variables: { input: userPayload } });
      } catch (error) {
        console.error(error);
        message.error(error);
      }
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <PageHeader className={styles.permissions_title}>Permissions Management</PageHeader>
        <div className={styles.user_add}>
          {/* This is part of a future design, but not feature complete. Hide for now */}
          {/* <Button
            type="primary"
            onClick={() => {
              setModal({ ...modal, show: true });
            }}>
            Invite user
          </Button> */}
        </div>
      </div>
      <div className={styles.search_container}>
        <SearchField
          handleSearch={handleSearch}
          loading={usersLoading || dcsLoading || tpLoading}
          width={480}
          value={search}
        />
        <ExportAllUsers />
      </div>
      <div className={styles.permissions_pagination_container}>
        <QuantityDisplay
          count={data?.listUsers?.totalCount}
          titleSingular="User"
          titleMultiple="Users"
        />
        <Paginator
          pageSize={getPageSize(location, DEFAULT_PAGE_SIZE)}
          hasNextPage={!!data?.listUsers?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.listUsers?.pageInfo?.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          nextPage={nextPage}
          prevPage={prevPage}
          onlyButtons={false}
        />
      </div>
      <PermissionsList
        users={users}
        saveUser={saveUser}
        deactivateUser={processDeactivateUser}
        reactivateUser={processReactivateUser}
        tradingPartnerList={getNodesFromEdges(tpData?.tradingPartners)}
        distributionCentersList={getNodesFromEdges(dcsData?.distributionCenters)}
        loading={usersLoading || dcsLoading || tpLoading}
        search={search}
        updatePaging={updatePaging}
      />
      <div className={styles.permissions_pagination_container_end}>
        <Paginator
          pageSize={getPageSize(location, DEFAULT_PAGE_SIZE)}
          hasNextPage={!!data?.listUsers?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.listUsers?.pageInfo?.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          nextPage={nextPage}
          prevPage={prevPage}
          onlyButtons={true}
        />
      </div>
    </div>
  );
};
