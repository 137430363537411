import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Modal, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { compareDates, dateFormat } from 'common/helpers/date';
import s from './OrdersTable.module.scss';
import { sumBy } from 'lodash-es';
import {
  DetailItem,
  poDetailsColumnsWithCsvRender,
  PurchaseOrderDetailsTable
} from '../PurchaseOrderDetailsTable/PurchaseOrderDetailsTable';
import { PurchaseOrdersAtRiskReportDocument } from 'pages/Visibility/gql/__generated__/atRisk.query';
import { AppliedFillRateReportFilters } from 'graphql/__generated__/types';
import { ApolloClient, ApolloQueryResult, useQuery } from '@apollo/client';
import RetailerDeliveryDestinationCell from 'components/RetailerDeliveryDestinationCell/RetailerDeliveryDestinationCell';
import { DisplayMode, MetricItem, MetricItemWithArrayIndex } from 'pages/Visibility/types';
import { getTableFilters, useFiltersOnFrontend } from '../../frontendFilter';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import currency from 'currency.js';
import { chunk } from 'lodash-es';
import {
  ColumnsWithExportRender,
  removeExportConfig,
  prepareRecordsForExport,
  prepareSingleRecordForExport
} from 'common/helpers/tableExport';
import {
  AtRiskPurchaseOrderDetailsDocument,
  AtRiskPurchaseOrderDetailsQuery
} from 'pages/Visibility/gql/__generated__/purchaseOrderDetailsReport.query';
import { TableFilters } from '../../../../components/TableFilters';
import { NOT_AVAILABLE } from 'common/constants';

const getAtRiskClassName = (onTimeShipmentStatus: string | null | undefined) => {
  switch ((onTimeShipmentStatus || '').toLowerCase()) {
    case 'at risk':
      return s.atRisk;
    case 'due today':
      return s.dueToday;
    default:
      return '';
  }
};

type OrderWithDetails = { order: MetricItem; details: DetailItem[] | undefined };
const DETAILS_MODE = 'DETAILS';

const getDetailsForOrdersBatched = async (
  client: ApolloClient<object>,
  orders: MetricItem[],
  batchSize = 10
): Promise<OrderWithDetails[]> => {
  // TODO: replace this with proper BE call
  const orderChunks = chunk(orders, batchSize);
  const results: (ApolloQueryResult<AtRiskPurchaseOrderDetailsQuery> | undefined)[] = [];

  for (const chunk of orderChunks) {
    const promises = chunk.map((order) =>
      order.uniqueOrderId
        ? client.query({
            query: AtRiskPurchaseOrderDetailsDocument,
            variables: {
              uniqueOrderId: order.uniqueOrderId
            }
          })
        : undefined
    );
    const batchResults = await Promise.all(promises);
    results.push(...batchResults);
  }

  return orders.map((order, idx) => ({
    order,
    details: results[idx]?.data?.purchaseOrderDetailsReport?.metrics
  }));
};

const prepareCombinedDataForExport = (ordersWithDetails: OrderWithDetails[]) => {
  return ordersWithDetails
    .map(({ order, details }) => {
      const mappedOrder = prepareSingleRecordForExport(
        order,
        rawColumnsWithCsvRenderer,
        DETAILS_MODE
      );
      const mappedDetails = prepareRecordsForExport(details || [], poDetailsColumnsWithCsvRender);
      return mappedDetails.map((details) => ({ ...mappedOrder, ...details }));
    })
    .flat();
};

const rawColumnsWithCsvRenderer: ColumnsWithExportRender<MetricItem> = [
  {
    title: 'Requested Delivery Date',
    key: 'deliveryDate',
    width: 112,
    defaultSortOrder: 'descend',
    render: (_, { deliveryDate }) => dateFormat(deliveryDate) || NOT_AVAILABLE,
    sorter: (a, b) => compareDates(a.deliveryDate || null, b.deliveryDate || null),
    exportConfig: {
      render: ({ deliveryDate }) => dateFormat(deliveryDate) || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    }
  },
  {
    title: 'On Time Shipment',
    key: 'atRiskStatus',
    width: 90,
    onCell: ({ onTimeShipmentStatus }) => ({
      className: getAtRiskClassName(onTimeShipmentStatus)
    }),
    render: (_, { onTimeShipmentStatus }) => onTimeShipmentStatus || NOT_AVAILABLE,
    exportConfig: {
      render: ({ onTimeShipmentStatus }) => onTimeShipmentStatus || NOT_AVAILABLE
    },
    className: s.atRiskColumn,
    sorter: (a, b) => (a?.onTimeShipmentStatus || '').localeCompare(b?.onTimeShipmentStatus || '')
  },
  {
    title: 'Pick Status',
    key: 'pickStatus',
    width: 85,
    render: (_, { pickStatus }) => pickStatus || NOT_AVAILABLE,
    exportConfig: {
      render: ({ pickStatus }) => pickStatus || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) => (a?.pickStatus || '').localeCompare(b?.pickStatus || '')
  },
  {
    title: 'Ship Status',
    key: 'shipStatus',
    width: 85,
    render: (_, { shipStatus }) => shipStatus || NOT_AVAILABLE,
    exportConfig: {
      render: ({ shipStatus }) => shipStatus || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) => (a?.shipStatus || '').localeCompare(b?.shipStatus || '')
  },
  {
    title: 'BU',
    width: 90,
    key: 'bu',
    render: (_, { businessUnitName }) => businessUnitName || NOT_AVAILABLE,
    exportConfig: {
      render: ({ businessUnitName }) => businessUnitName || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) => (a?.businessUnitName || '').localeCompare(b?.businessUnitName || '')
  },
  {
    title: 'Total Price',
    width: 135,
    align: 'right',
    key: 'totalPrice',
    render: (_, { totalCost }) => (
      <div className={s.number_cell}>{currency(totalCost || 0).format({ pattern: '! #' })}</div>
    ),
    exportConfig: {
      render: ({ totalCost }) => currency(totalCost || 0).format()
    },
    sorter: (a, b) => (a?.totalCost || 0) - (b?.totalCost || 0)
  },
  {
    title: 'Original QTY',
    width: 100,
    align: 'right',
    key: 'submittedQuantity',
    render: (_, { submittedQuantity }) => (
      <div className={s.number_cell}>{(submittedQuantity || 0).toLocaleString()}</div>
    ),
    exportConfig: {
      render: ({ submittedQuantity }) => submittedQuantity || 0
    },
    sorter: (a, b) => (a.submittedQuantity || 0) - (b.submittedQuantity || 0)
  },
  {
    title: 'Accepted QTY',
    width: 100,
    align: 'right',
    key: 'acceptedQuantity',
    render: (_, { acceptedQuantity }) => (
      <div className={s.number_cell}>{(acceptedQuantity || 0).toLocaleString()}</div>
    ),
    exportConfig: {
      render: ({ acceptedQuantity }) => acceptedQuantity || 0
    },
    sorter: (a, b) => (a.acceptedQuantity || 0) - (b.acceptedQuantity || 0)
  },
  {
    title: 'Delivered QTY',
    width: 100,
    align: 'right',
    key: 'deliveredQuantity',
    render: (_, { deliveredQuantity }) => (
      <div className={s.number_cell}>{(deliveredQuantity || 0).toLocaleString()}</div>
    ),
    exportConfig: {
      render: ({ deliveredQuantity }) => deliveredQuantity || 0
    },
    sorter: (a, b) => (a.deliveredQuantity || 0) - (b.deliveredQuantity || 0)
  },
  {
    title: 'Order Number',
    key: 'orderNumber',
    align: 'right',
    width: 105,
    render: (_, { salesOrderId }) => salesOrderId || NOT_AVAILABLE,
    exportConfig: {
      render: ({ salesOrderId }) => salesOrderId || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) => (a?.salesOrderId || '').localeCompare(b?.salesOrderId || '')
  },
  {
    title: 'PO Number',
    key: 'customerPo',
    width: 110,
    render: (_, { customerPo }) => customerPo || NOT_AVAILABLE,
    exportConfig: {
      render: ({ customerPo }) => customerPo || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) => (a?.customerPo || '').localeCompare(b?.customerPo || '')
  },
  {
    title: 'DC',
    key: 'dc',
    width: 100,
    render: (_, { distributionCenterName }) => distributionCenterName || NOT_AVAILABLE,
    exportConfig: {
      render: ({ distributionCenterName }) => distributionCenterName || NOT_AVAILABLE
    },
    sorter: (a, b) =>
      (a?.distributionCenterName || '').localeCompare(b?.distributionCenterName || '')
  },
  {
    title: 'Customer',
    key: 'vendorMarketName',
    width: 130,
    render: (_, { vendorMarketName }) => vendorMarketName || NOT_AVAILABLE,
    exportConfig: {
      render: ({ vendorMarketName }) => vendorMarketName || NOT_AVAILABLE
    },
    sorter: (a, b) => (a?.vendorMarketName || '').localeCompare(b?.vendorMarketName || '')
  },
  {
    title: 'Customer ID',
    width: 105,
    key: 'sapCustomerId',
    render: (_, { retailerDeliveryDestination }) =>
      retailerDeliveryDestination?.sapCustomerId || NOT_AVAILABLE,
    exportConfig: {
      render: ({ retailerDeliveryDestination }) =>
        retailerDeliveryDestination?.sapCustomerId || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) =>
      (a?.retailerDeliveryDestination?.sapCustomerId || '').localeCompare(
        b?.retailerDeliveryDestination?.sapCustomerId || ''
      )
  },
  {
    title: 'Ship-To',
    key: 'shipTo',
    width: 230,
    render: (_, { retailerDeliveryDestination }) => (
      <RetailerDeliveryDestinationCell retailerDeliveryDestination={retailerDeliveryDestination} />
    ),
    exportConfig: {
      render: ({ retailerDeliveryDestination }) =>
        `${retailerDeliveryDestination?.name || ''} ${
          retailerDeliveryDestination?.addressCity || ''
        } ${retailerDeliveryDestination?.addressState || ''}`.trim() || NOT_AVAILABLE,
      modes: [DETAILS_MODE]
    },
    sorter: (a, b) =>
      (a?.retailerDeliveryDestination?.name || '').localeCompare(
        b?.retailerDeliveryDestination?.name || ''
      )
  },
  {
    title: 'Vendor Code',
    key: 'tpExternalId',
    width: 150,
    render: (_, { tpExternalId }) => tpExternalId || NOT_AVAILABLE,
    exportConfig: {
      render: ({ tpExternalId }) => tpExternalId || NOT_AVAILABLE
    },
    sorter: (a, b) => (a?.tpExternalId || '').localeCompare(b?.tpExternalId || '')
  }
];

const rawColumns: ColumnsType<MetricItemWithArrayIndex> = [
  Table.SELECTION_COLUMN,
  Table.EXPAND_COLUMN,
  ...removeExportConfig(rawColumnsWithCsvRenderer)
];

// TODO: remove this as soon as we have a proper ID.
const getKey = (record: MetricItemWithArrayIndex): string =>
  `${record?.indexInResultArray}-${record?.uniqueOrderId || ''}`;

export const OrdersTable = ({
  filters,
  mode,
  selectedRows,
  setSelectedRows,
  exportModalVisible,
  setExportModalVisible,
  resetSelectedRows
}: {
  filters: AppliedFillRateReportFilters;
  mode: DisplayMode;
  selectedRows: MetricItemWithArrayIndex[];
  setSelectedRows: React.Dispatch<React.SetStateAction<MetricItemWithArrayIndex[]>>;
  exportModalVisible: boolean;
  setExportModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelectedRows: () => void;
}) => {
  const { data, loading, error, called, client } = useQuery(PurchaseOrdersAtRiskReportDocument, {
    variables: {
      appliedFilters: filters
    },
    skip: filters.subBusinessUnitIds?.length === 0 || filters.vendorMarketIds.length === 0
  });

  // I don't like to write it manually, I wish Apollo could receive any async function like React Query
  // https://github.com/react-csv/react-csv/issues/350#issuecomment-1291003571 we need to do this ref magic till it's fixed
  const [exportWithDetails, setExportWithDetails] = useState(false);
  const csvWithDetailsLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(
    null
  );
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [downloadTimestamp, setDownloadTimestamp] = useState(moment());
  const [csvAsyncWithDetails, setCsvAsyncWithDetails] = useState<{
    loading: boolean;
    error: boolean;
    data: { [x: string]: string | number | undefined }[];
  }>({ loading: false, data: [], error: false });
  const onDownloadWithDetailsClick = async (orders: MetricItem[]) => {
    try {
      setCsvAsyncWithDetails({ loading: true, error: false, data: [] });
      const ordersWithDetails = await getDetailsForOrdersBatched(client, orders, 50);
      setCsvAsyncWithDetails({
        loading: false,
        error: false,
        data: prepareCombinedDataForExport(ordersWithDetails)
      });
      setDownloadTimestamp(moment());
      resetSelectedRows();
      csvWithDetailsLink.current?.link.click();
      setExportModalVisible(false);
    } catch {
      setCsvAsyncWithDetails({ loading: false, error: true, data: [] });
    }
  };

  const onDownloadClick = () => {
    setDownloadTimestamp(moment());
    csvLink.current?.link.click();
    resetSelectedRows();
    setExportModalVisible(false);
  };

  const metrics = useFiltersOnFrontend(data?.purchaseOrdersAtRiskReport?.metrics || []);

  const INITIAL_PAGINATION = 50;
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION);
  const [page, setPage] = useState(1);
  const isSinglePage = metrics.length <= pageSize;
  const isPaginationHidden = metrics.length <= INITIAL_PAGINATION; // Because we don't ALWAYS hide pagination even if it's single page

  // Reset page after filters/mode change
  useEffect(() => {
    setPage(1);
  }, [mode, filters]);

  // Calculating w/o currency.js creates errors. We can remove it as soon as we get it from BE.
  const totalCostTotal = useMemo(
    () => metrics.reduce((acc, cur) => currency(acc).add(cur?.totalCost || 0), currency(0)),
    [metrics]
  );
  // We can do it in one pass, but it's more cumbersome
  const submittedQuantityTotal = useMemo(
    () => sumBy(metrics || [], 'submittedQuantity'),
    [metrics]
  );
  const acceptedQuantityTotal = useMemo(() => sumBy(metrics || [], 'acceptedQuantity'), [metrics]);
  const deliveredQuantityTotal = useMemo(
    () => sumBy(metrics || [], 'deliveredQuantity'),
    [metrics]
  );
  const tableFilters = useMemo(() => getTableFilters(metrics), [metrics]);

  const columns =
    mode === 'upcoming' ? rawColumns : rawColumns.filter((x) => x.key !== 'atRiskStatus');
  const columnsForCsv =
    mode === 'upcoming'
      ? rawColumnsWithCsvRenderer
      : rawColumnsWithCsvRenderer.filter((x) => x.key !== 'atRiskStatus');

  const tableWidth = sumBy(columns, 'width');

  return (
    <div className={s.container}>
      <TableFilters filters={tableFilters} resetSelectedRows={resetSelectedRows} />
      <Modal
        title="Export view"
        open={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        width={400}
        destroyOnClose
        footer={
          <>
            <div>
              <Button
                className={clsx('filled_grey_btn_no_border', s.bold)}
                size="large"
                onClick={() => setExportModalVisible(false)}
                data-testid="order-visibility-export-modal-close-button"
              >
                Cancel
              </Button>
              {exportWithDetails ? (
                <Button
                  className={clsx(s.bold)}
                  size="large"
                  type="primary"
                  disabled={
                    (!selectedRows?.length && !metrics?.length) || csvAsyncWithDetails.loading
                  }
                  data-testid="order-visibility-export-modal-download-button-with-details"
                  onClick={() =>
                    onDownloadWithDetailsClick(selectedRows?.length ? selectedRows : metrics)
                  }
                  loading={csvAsyncWithDetails.loading}
                >
                  Download {selectedRows.length > 0 ? `selected` : `all`} with details
                </Button>
              ) : (
                <Button
                  className={clsx(s.bold)}
                  size="large"
                  type="primary"
                  disabled={!selectedRows?.length && !metrics?.length}
                  data-testid="order-visibility-export-modal-download-button"
                  onClick={() => onDownloadClick()}
                >
                  Download {selectedRows.length > 0 ? `selected` : `all`}
                </Button>
              )}
            </div>
            {csvAsyncWithDetails.error && (
              <p className={s.error}>Error occured while loading csv, try again later.</p>
            )}
            <CSVLink
              ref={csvLink}
              filename={`order-visibility-${downloadTimestamp?.format(
                'MM-DD-YYYY_hh-mm-ss_A'
              )}.csv`}
              data={prepareRecordsForExport(
                selectedRows?.length ? selectedRows : metrics,
                columnsForCsv
              )}
            />
            <CSVLink
              ref={csvWithDetailsLink}
              filename={`order-visibility-details-${downloadTimestamp?.format(
                'MM-DD-YYYY_hh-mm-ss_A'
              )}.csv`}
              data={csvAsyncWithDetails.data}
            />
          </>
        }
      >
        <Spin spinning={csvAsyncWithDetails.loading}>
          {!selectedRows?.length && !metrics?.length ? (
            'Nothing to export. Please change filters.'
          ) : (
            <>
              <p>
                We will export{' '}
                {selectedRows.length > 0
                  ? `${selectedRows.length} selected`
                  : `all ${metrics.length}`}{' '}
                items into a <code>.csv</code> file.
              </p>
              <Checkbox
                checked={exportWithDetails}
                onChange={(event) => setExportWithDetails(event.target.checked)}
                className={s.checkbox}
                data-testid="order-visibility-export-modal-details-checkbox"
              >
                Include PO details
              </Checkbox>
            </>
          )}
        </Spin>
      </Modal>
      {error ? (
        <div>Could not load data due to an error. Try again.</div>
      ) : (
        <>
          <div className={s.totalAndSelectedWrapper}>
            {selectedRows.length > 0 ? (
              <div className={clsx(s.totalAndSelected)}>
                {selectedRows.length} order{selectedRows.length > 1 ? 's' : ''} selected
                {selectedRows.length > 0 ? (
                  <Button
                    className="filled_grey_btn_no_border"
                    onClick={() => setSelectedRows([])}
                    data-testid="visibility-clear-selection"
                  >
                    Clear selection
                  </Button>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          <Table
            data-testid="at_risk_orders_table"
            rowKey={getKey}
            rowSelection={{
              preserveSelectedRowKeys: true,
              onChange: (_keys, rows) => {
                setSelectedRows(rows);
              },
              type: 'checkbox',
              selectedRowKeys: selectedRows.map((row) => getKey(row)),
              //@ts-ignore
              getCheckboxProps(record) {
                return { 'data-testid': record.customerPo };
              }
            }}
            className={clsx(
              `styled_report_table`,
              s.table,
              isPaginationHidden && s.tableNoPagination
            )}
            scroll={{ x: tableWidth }}
            sticky={{
              offsetScroll: 6
            }}
            size="small"
            dataSource={metrics || []}
            columns={columns}
            pagination={
              isPaginationHidden
                ? false
                : {
                    position: ['topRight'],
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200'],
                    pageSize: pageSize,
                    size: 'small',
                    onShowSizeChange: (_current, size) => setPageSize(size),
                    current: page,
                    onChange: (page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                    }
                  }
            }
            bordered
            loading={loading || !called}
            expandable={{
              expandedRowRender: ({ customerPo, uniqueOrderId }) => (
                <div className={s.expanded_wrapper}>
                  <PurchaseOrderDetailsTable
                    customerPo={customerPo as string}
                    uniqueOrderId={uniqueOrderId as string}
                  />
                </div>
              ),
              // Don't show expander for rows w/o po, just in case
              rowExpandable: ({ uniqueOrderId }) => !!uniqueOrderId
            }}
            summary={(data) => {
              if (!data || data?.length === 0) return <></>;
              // Calculating w/o currency.js creates errors. We can remove it as soon as we get it from BE.
              const totalCost = data.reduce(
                (acc, cur) => currency(acc).add(cur?.totalCost || 0),
                currency(0)
              );
              // We can do it in one pass, but it's more cumbersome
              const submittedQuantity = sumBy(data, 'submittedQuantity');
              const acceptedQuantity = sumBy(data, 'acceptedQuantity');
              const deliveredQuantity = sumBy(data, 'deliveredQuantity');

              const colSpan = mode === 'upcoming' ? 7 : 6;
              return (
                <Table.Summary>
                  {isSinglePage ? (
                    <></>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1} className={s.total} colSpan={colSpan}>
                        This Page
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} className={s.total}>
                        {totalCost.format({ pattern: '! #' })}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className={s.total}>
                        {submittedQuantity.toLocaleString()}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className={s.total}>
                        {acceptedQuantity.toLocaleString()}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className={s.total}>
                        {deliveredQuantity.toLocaleString()}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} className={s.total} colSpan={colSpan}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} className={s.total}>
                      {totalCostTotal.format({ pattern: '! #' })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} className={s.total}>
                      {submittedQuantityTotal.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} className={s.total}>
                      {acceptedQuantityTotal.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} className={s.total}>
                      {deliveredQuantityTotal.toLocaleString()}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </>
      )}
    </div>
  );
};
