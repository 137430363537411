/* 
This component contains source code for Displaying Product Info for a scenario.
It displays Product details GTIN, Description, Demand, DCs.
User can search by GTINs, filter by Market Place, Business Unit, Brand and Category
*/
import React, { useState, useMemo } from 'react';
import s from './AllSKUsInfo.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Tooltip, message, Spin, Tag } from 'antd';
import { AllSKUsFilters } from './AllSKUsFilters';
import { useParams } from 'react-router';
import { filterValues } from './AllSKUsFilters';
import InventoryAllocationTable from '../InventoryAllocationTable/InventoryAllocationTable';
import SourcingStrategyTable from '../SourcingStrategyTable/SourcingStrategyTable';
import {
  GetScenarioDetailsDocument,
  GetInventoryAllocationDetailsDocument,
  GetSourcingStrategyDetailsDocument
} from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioDetails.query';
import {
  GetGtinDataDocument,
  GetTradingPartnerDocument
} from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioCreate.query';
import { invConstExternalId } from 'pages/InventoryOptimizationPage/inventoryOptimizationConstants';
import { notEmpty } from 'common/helpers/notEmpty';

export interface ProductDetails {
  id: string;
  skuNumber: string | undefined;
  price: number;
  productInfo: string | null | undefined;
  existingDC: number;
  optimalCount: number;
  change: number;
  selected: boolean;
  businessUnit: string;
  brand: string;
  category: string;
  isNewProduct: number;
}
type FiltersOptions = {
  name: string | null | undefined;
  value: string | null | undefined;
};

const tradingPartnersArray = invConstExternalId.TP_EXTERNAL_ID;

const AllSKUsInfo: React.FC = () => {
  const [checkboxes, setCheckboxes] = useState<ProductDetails[]>();
  const [productsData, setProductsData] = useState<ProductDetails[]>();
  const [searchData, setSearchData] = useState<ProductDetails[]>();
  const [selectAll, setSelectAll] = useState<boolean | undefined>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [businessUnits, setBusinessUnits] = useState<FiltersOptions[]>([]);
  const [brands, setBrands] = useState<FiltersOptions[]>([]);
  const [categories, setCategories] = useState<FiltersOptions[]>([]);
  const [tradingPartners, setTradingPartners] = useState<FiltersOptions[]>([]);
  const [gtins, setGtins] = useState<(string | null | undefined)[]>([]);
  const tableHeaderName = [
    { id: 1, name: 'Inventory Allocation' },
    { id: 2, name: 'Sourcing Strategy' }
  ];
  const { key } = useParams<{ key: string }>();
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  /* 
  GraphQl query to get the product info of all the GITNs for a scenario.
  It takes scenario ID as input parameter and fetches the product Info of a scenario
  */

  const {
    data: productInfoData,
    error: productInfoError,
    loading: procuctInfoLoading
  } = useQuery(GetScenarioDetailsDocument, {
    variables: { scenarioId: key },

    onCompleted: (productInfoData) => {
      const sKUsData = productInfoData?.getScenarioResultProductList?.map((sku) => {
        return {
          id: sku.id,
          skuNumber: sku.gtin14Product?.gtin14,
          price: sku.demand,
          productInfo: sku.gtin14Product?.description,
          existingDC: sku.existingDc,
          optimalCount: sku.optimal,
          change: sku.existingDc - sku.optimal,
          selected: true,
          businessUnit: String(sku.gtin14Product?.salesBusinessUnit),
          brand: String(sku.gtin14Product?.salesBrand),
          category: String(sku.gtin14Product?.ecommerceCategory),
          isNewProduct: sku.isNewProduct
        };
      });
      const bunits = businessUnits;
      const brandNames = brands;
      const categoryNames = categories;
      const gtinValues = gtins;

      productInfoData?.getScenarioResultProductList.map((val) => {
        val.gtin14Product?.salesBusinessUnit &&
          bunits.push({
            name: val.gtin14Product?.salesBusinessUnit,
            value: val.gtin14Product?.salesBusinessUnit
          });
        val.gtin14Product?.salesBrand &&
          brandNames.push({
            name: val.gtin14Product?.salesBrand,
            value: val.gtin14Product?.salesBrand
          });
        val.gtin14Product?.ecommerceCategory &&
          categoryNames.push({
            name: val.gtin14Product?.ecommerceCategory,
            value: val.gtin14Product?.ecommerceCategory
          });
        gtinValues.push(val.gtin14Product?.gtin14);
      });
      setBusinessUnits(bunits);
      setCategories(categoryNames);
      setBrands(brandNames);
      setGtins(gtinValues);
      setProductsData(sKUsData);
      setSearchData(sKUsData);
      setCheckboxes(sKUsData);
    }
  });
  /*
  GraphQL Query to get the inventory allocation details for a scenario
  It accepts scenario ID as Input parameter and fetches details based on ID
  */
  const {
    data: inventoryTableData,
    error: inventoryTableError,
    loading: inventoryTableLoading,
    refetch: refetchInventoryTableData
  } = useQuery(GetInventoryAllocationDetailsDocument, {
    variables: { scenarioId: key },
    notifyOnNetworkStatusChange: true
  });
  /*
  GraphQL Query to get the sourcing strategy details for a scenario
  It accepts scenario ID as Input parameter and fetches details based on ID
  */
  const {
    data: sourcingStrategyTableData,
    error: sourcingStrategyTableError,
    loading: sourcingStrategyTableLoading,
    refetch: refetchSourcingTableData
  } = useQuery(GetSourcingStrategyDetailsDocument, {
    variables: { scenarioId: key },
    notifyOnNetworkStatusChange: true
  });

  /*
  GraphQL Query to get the Trading Partner names.
  It accepts Trading Partner external IDs as Input parameter and fetches details based on ID
  */
  const { data: marketPlaceData } = useQuery(GetTradingPartnerDocument, {
    variables: { tpExternalIds: tradingPartnersArray }
  });

  const getTPName = (val: number | null) => {
    let mpName = marketPlaceData?.getTradingPartners?.filter((data) => {
      return data?.id === btoa('TradingPartner:' + val);
    });
    return mpName && mpName[0];
  };

  const getTpNames = (tparr: (number | null)[] | null | undefined) => {
    let tpNamesarr = tparr?.map((tpid) => {
      let tpName = getTPName(tpid);
      return tpName;
    });
    return tpNamesarr;
  };

  const { data: gtinsForMarketPlaces } = useQuery(GetGtinDataDocument, {
    variables: {
      tpIds: marketPlaceData?.getTradingPartners?.map((data) => String(data?.id)) || [],
      gtin14s: gtins.map((gtinsId) => String(gtinsId))
    },
    onCompleted: (gtinsForMarketPlaces) => {
      const tpobjs = tradingPartners;
      gtinsForMarketPlaces?.getGtin14Dcs?.map((tpvalue) => {
        let tpNames = getTpNames(tpvalue?.tradingPartnerIds);
        tpNames?.map((tpNameValue) => {
          tpobjs.push({
            name: tpNameValue?.name,
            value: tpNameValue?.name
          });
        });
      });
      setTradingPartners(tpobjs);
    }
  });

  const inventoryData = useMemo(
    () =>
      inventoryTableData?.getScenarioResultInventoryAllocationDetailsList?.map((inventory) => {
        return {
          key: inventory?.id,
          dc: inventory?.dcName,
          currentStock: inventory?.currentStock,
          optimalStock: inventory?.optimalStock,
          currentPallets: Number(inventory?.currentPallets),
          optimalPallets: Number(inventory?.optimalPallets),
          currentLayers: inventory?.currentLayers,
          optimalLayers: inventory?.optimalLayers
        };
      }),
    [inventoryTableData?.getScenarioResultInventoryAllocationDetailsList]
  );

  const sourcingData = useMemo(
    () =>
      sourcingStrategyTableData?.getScenarioResultSourcingStrategyDetailsList?.map(
        (sourcing, index) => {
          return {
            key: String(index) + sourcing?.dcName + sourcing?.id,
            dc: sourcing?.dcName,
            plant: sourcing?.plantName,
            currentSourcing: sourcing?.currentSourcing,
            optimalSourcing: sourcing?.optimalSourcing,
            currentPallets: Number(sourcing?.currentPallets),
            optimalPallets: Number(sourcing?.optimalPallets),
            currentLayers: sourcing?.currentLayers,
            optimalLayers: sourcing?.optimalLayers
          };
        }
      ),
    [sourcingStrategyTableData?.getScenarioResultSourcingStrategyDetailsList]
  );
  // Function to search the product Info based on GTIN/SKU number and product description
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = productsData?.filter(
      (item) =>
        item?.productInfo?.toLowerCase().includes(query.toLowerCase()) ||
        item?.skuNumber?.toString().includes(query)
    );
    setCheckboxes(filtered);
  };
  /* 
  Function to filter the GTINs/products based on check or uncheck of checkboxes.
  Onchange of checkboxes , APIs to get inventory allocation and sourcing strategy details is triggered.
  */
  const handleCheckboxChange = (id: string) => {
    const updatedCheckboxes = checkboxes?.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, selected: !checkbox.selected } : checkbox
    );
    const updatedSKUs = updatedCheckboxes
      ?.filter((checkbox) => checkbox.selected)
      .map((checkbox) => checkbox.id);
    setCheckboxes(updatedCheckboxes);
    setSelectAll(updatedCheckboxes?.every((checkbox) => checkbox.selected));
    refetchInventoryTableData({ scenarioId: key, scenarioResultProductIds: updatedSKUs });
    refetchSourcingTableData({ scenarioId: key, scenarioResultProductIds: updatedSKUs });
  };

  const handleSelectAllChange = async () => {
    const updatedCheckboxes = checkboxes?.map((checkbox) => ({
      ...checkbox,
      selected: !selectAll
    }));
    const updatedSKUs = updatedCheckboxes
      ?.filter((checkbox) => checkbox.selected)
      .map((checkbox) => checkbox.id);
    setCheckboxes(updatedCheckboxes);
    setSelectAll(!selectAll);
    refetchInventoryTableData({ scenarioId: key, scenarioResultProductIds: updatedSKUs });
    refetchSourcingTableData({ scenarioId: key, scenarioResultProductIds: updatedSKUs });
  };

  const handleTableInfoClick = (index: number) => {
    setSelectedButtonIndex(index);
  };

  const getFilteredPrds = (
    keys: string[] | undefined,
    data: ProductDetails[] | undefined
  ): (ProductDetails | undefined)[] | undefined => {
    return keys?.filter(notEmpty).map((key) => {
      const matchingObject = data?.find((obj) => obj.skuNumber === key);
      return matchingObject;
    });
  };

  // Function to filter the GTINs/ products when the user selects Trading Partner filters.
  const getTradingPartnerData = (selectedTP: filterValues, gtin: string | undefined) => {
    if (selectedTP) {
      const filteredMarketPlaces = marketPlaceData?.getTradingPartners
        ?.filter((marketVal) => {
          return selectedTP.tradingPartner.includes(String(marketVal?.name));
        })
        .map((data) => atob(String(data?.id)).split(':')[1]);
      const filteredTPGTINs = gtinsForMarketPlaces?.getGtin14Dcs
        ?.filter((marketVal) =>
          marketVal?.tradingPartnerIds?.some((str) => filteredMarketPlaces?.includes(String(str)))
        )
        .map((data) => data?.gtin14);

      const updData = getFilteredPrds(filteredTPGTINs?.filter(notEmpty), searchData);
      return updData?.some((item) => item?.skuNumber === gtin);
    }
  };
  /* 
  Function to filter the GTINs/products on applying filters.
  User can filter by Business Units, Market Places, Brands and Categories
  */
  const handleFilters = (filterKey: filterValues) => {
    const updatedData = searchData?.filter(
      (data) =>
        (!filterKey?.businessUnit || filterKey.businessUnit.includes(data.businessUnit)) &&
        (!filterKey?.brand || filterKey.brand.includes(data.brand)) &&
        (!filterKey?.category || filterKey.category.includes(data.category)) &&
        (!filterKey?.tradingPartner || getTradingPartnerData(filterKey, data.skuNumber))
    );
    setProductsData(updatedData);
    setCheckboxes(updatedData);
    const productIds = updatedData?.map((data) => {
      return data.id;
    });
    refetchInventoryTableData({ scenarioId: key, scenarioResultProductIds: productIds });
    refetchSourcingTableData({ scenarioId: key, scenarioResultProductIds: productIds });
  };

  return (
    <div className={s.allInfoContainer}>
      <div className={s.leftSideSKUsInfo}>
        <div className={s.allSKUsContainer}>
          {productInfoError && message.error(productInfoError.message)}
          <div className={s.fixedItems}>
            <div className={s.allFilterContainer}>
              <div className={s.skusInputSearchContainer}>
                <SearchOutlined />
                <input
                  data-testid="inv-allInfoPage-allSKUsSearchInputFiled"
                  placeholder="Search with GTIN & description"
                  className={s.skusInputFiled}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              <div style={{ width: '100%' }}>
                <AllSKUsFilters
                  onFilter={handleFilters}
                  data-testid="inv-allInfoPage-allSkusAddFilterButton"
                  filters={[
                    {
                      name: 'Business Unit',
                      type: 'multiple',
                      options: businessUnits,
                      field: 'businessUnit'
                    },
                    {
                      name: 'Trading Partner',
                      type: 'multiple',
                      options: tradingPartners,
                      field: 'tradingPartner'
                    },
                    {
                      name: 'Brand',
                      type: 'multiple',
                      options: brands,
                      field: 'brand'
                    },
                    {
                      name: 'Category',
                      type: 'multiple',
                      options: categories,
                      field: 'category'
                    }
                  ]}
                />
              </div>
            </div>

            <div>
              <hr className={s.separatorLine} />
            </div>
            <div className={s.allSKUsSelectedContainer}>
              <div className={s.allSKUsSelected}>
                <input
                  data-testid="inv-allInfoPage-allSKUsParentCheckboxBtn"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <span data-testid="inv-allInfoPage-allSKUsParentCheckboxHeader">
                  {checkboxes?.length} GTINs Selected
                </span>
              </div>
            </div>
          </div>

          <div className={s.scrollableItems}>
            {<Spin spinning={procuctInfoLoading} />}
            {checkboxes?.map((skuDetails) => {
              return (
                <div
                  data-testid="inv-allInfoPage-allSKUsChildDetails"
                  key={skuDetails.id}
                  className={s.skusdetailsContainer}
                  style={{
                    backgroundColor: skuDetails.selected ? '#ffffff' : '#EEEEEE'
                  }}
                >
                  <div className={s.skusNumberdetails}>
                    <div>
                      <input
                        data-testid="inv-allInfoPage-allSKUsChildCheckboxBtn"
                        type="checkbox"
                        checked={skuDetails.selected || selectAll}
                        onChange={() => handleCheckboxChange(skuDetails.id)}
                      />
                      <span data-testid="inv-allInfoPage-allSKUsChildCheckboxIdInfo">
                        {skuDetails.skuNumber}
                      </span>
                      {skuDetails.isNewProduct ? <Tag className={s.newItemTag}>New</Tag> : ''}
                    </div>

                    <Tooltip placement="right" title="Daily Demand">
                      <div
                        style={{
                          backgroundColor: '#88B4411A'
                        }}
                      >
                        <span
                          data-testid="inv-allInfoPage-allSKUsChildCheckboxDemandInfo"
                          style={{ color: skuDetails.selected ? ' #88B441' : '#009639' }}
                        >
                          {skuDetails.price}
                        </span>
                      </div>
                    </Tooltip>
                  </div>

                  <div style={{ paddingLeft: '12px' }}>
                    <div>
                      <Tooltip placement="right" title={skuDetails.productInfo}>
                        <span
                          data-testid="inv-allInfoPage-allSKUsChildCheckboxProductInfo"
                          className={s.skusProductName}
                        >
                          {skuDetails.productInfo}
                        </span>
                      </Tooltip>
                    </div>

                    <div className={s.skusSubDetails}>
                      <span data-testid="inv-allInfoPage-allSKUsChildCheckboxExistingtInfo">
                        DC Existing:{skuDetails.existingDC}
                      </span>
                      <span data-testid="inv-allInfoPage-allSKUsChildCheckboxOptimalInfo">
                        Optimal:{skuDetails.optimalCount}
                      </span>
                      <span data-testid="inv-allInfoPage-allSKUsChildCheckboxChangeInfo">
                        Change:{Math.abs(skuDetails.change)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={s.rightSideSKUsInfo}>
        <div className={s.tableContainerHeader}>
          {tableHeaderName.map((tableContent, index) => (
            <p
              key={tableContent.id}
              data-testid="inv-allInfoPage-tableHeader"
              onClick={() => handleTableInfoClick(index)}
              className={`tableContent ${selectedButtonIndex === index ? s.tableHeaderStyle : ''}`}
            >
              {tableContent.name}
            </p>
          ))}
        </div>

        <div>
          {selectedButtonIndex === 0 ? (
            <InventoryAllocationTable
              data={inventoryData}
              error={inventoryTableError}
              loading={inventoryTableLoading}
            />
          ) : (
            <SourcingStrategyTable
              data={sourcingData}
              error={sourcingStrategyTableError}
              loading={sourcingStrategyTableLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllSKUsInfo;
