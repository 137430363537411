import { Select, SelectProps, Tooltip } from 'antd';
import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validateRequired } from '../Utils';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import s from './FinalFormSelect.module.scss';

const { Option, OptGroup } = Select;

interface SimpleSelectOption {
  value: string | boolean | number;
  label: string;
}
interface GroupedSelectOption {
  label: string;
  options: { label: string; value: string }[];
}

interface FinalFormSelectProps {
  name: string;
  title?: string;
  options: SimpleSelectOption[] | GroupedSelectOption[];
  disabled?: boolean;
  sortByName?: boolean;
  showSearch?: boolean;
  allowClear?: boolean;
  required?: boolean;
  dropdownRender?: (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  validate?: FieldValidator<string | string[]>;
  mode?: 'multiple' | 'tags';
  hideDropdown?: boolean;
  loading?: boolean;
  disabledOptionsList?: string[] | number[];
  selectProps?: SelectProps<
    any,
    {
      children: string;
      key: string;
      value: string;
    }
  >;
  'data-testid'?: string;
}

export const FinalFormSelect = ({
  name,
  title,
  options,
  sortByName,
  showSearch,
  dropdownRender,
  disabled,
  validate,
  allowClear,
  required,
  mode,
  hideDropdown,
  loading,
  selectProps,
  disabledOptionsList,
  'data-testid': dataTestid = ''
}: FinalFormSelectProps) => {
  return (
    <div className={s.select} data-testid={dataTestid}>
      {title && (
        <>
          {required && (
            <Tooltip overlay="Required" className={s.required}>
              *{' '}
            </Tooltip>
          )}
          <span data-testid="final-form-select-title">{title}</span>
        </>
      )}
      <Field
        name={name}
        validate={
          required
            ? validate
              ? composeValidators([validateRequired, validate])
              : validateRequired
            : validate
        }
      >
        {({ input, meta }) => {
          return (
            <div>
              <Select
                loading={loading}
                {...input}
                mode={mode}
                allowClear={allowClear}
                disabled={disabled}
                style={{ width: '100%' }}
                showSearch={showSearch}
                optionFilterProp="children"
                filterOption={
                  showSearch
                    ? (input: any, option: any) => {
                        const isGrouped = isGroupedSelectOption(option);
                        return (
                          !isGrouped &&
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }
                    : undefined
                }
                filterSort={
                  sortByName
                    ? (optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    : undefined
                }
                dropdownRender={dropdownRender}
                dropdownStyle={hideDropdown ? { display: 'none' } : {}}
                {...selectProps}
              >
                {isGroupedSelectOptions(options) &&
                  options
                    .sort((opt1, opt2) => opt1.label.localeCompare(opt2.label))
                    .map((option, index) => (
                      <OptGroup label={option.label} key={`${option.label}${index}`}>
                        {option.options.map((simpleOption) => (
                          <Option key={simpleOption.value} value={simpleOption.value}>
                            {simpleOption.label}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                {!isGroupedSelectOptions(options) &&
                  options.map((option) => (
                    <Option
                      key={option.value.toString()}
                      value={option.value}
                      disabled={(disabledOptionsList || []).some(
                        (element) => element === option.value
                      )}
                    >
                      {option.label}
                    </Option>
                  ))}
              </Select>
              <ValidationMessage meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

function isGroupedSelectOptions(
  items: GroupedSelectOption[] | SimpleSelectOption[]
): items is GroupedSelectOption[] {
  if (items.length === 0) return false;
  return isGroupedSelectOption(items[0]);
}

export function isGroupedSelectOption(
  item: GroupedSelectOption | SimpleSelectOption
): item is GroupedSelectOption {
  return (item as GroupedSelectOption).options !== undefined;
}
