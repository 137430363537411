import React, { useCallback, useEffect, useState, useContext } from 'react';
import { PO_STATUS_SUBMITTED } from 'common/constants';
import { CheckCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import UploadQtyFileModal from './UploadQtyFileModal/UploadQtyFileModal';
import { UploadFileModal } from 'components/Modals/UploadFileModal/UploadFileModal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ItemsToUpdateFromFile } from 'common/types';
import { isStagingBackend } from 'common/helpers/identificateEnvironment';
import * as Sentry from '@sentry/browser';
import UploadShortMonitorModal from 'components/PurchaseOrders/UploadShortMonitorModal/UploadShortMonitorModal';
import ShortMonitorErrorModal from 'components/PurchaseOrders/UploadShortMonitorModal/ShortMonitorErrorModal';
import { ImportSeedDataDocument } from './gql/__generated__/importSeedData.mutation';
import { UpdateQuantityDocument } from './gql/__generated__/updateQuantity.mutation';
import {
  PurchaseOrderItemsImportSelectDocument,
  PurchaseOrderItemsImportSelectQuery
} from './gql/__generated__/purchaseOrderItemsImportSelect.query';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { UserContext } from 'context/userContext';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { App } from 'ant5';
import { SizeType } from 'ant5/es/config-provider/SizeContext';

const { Option } = AlloySelect;

type PurchaseOrder = InferNodeType<PurchaseOrderItemsImportSelectQuery, 'purchaseOrders'>;

interface PoUpdateMap {
  [x: string]: {
    po?: PurchaseOrder;
    items: ItemsToUpdateFromFile[];
    complete?: boolean;
    failed?: boolean;
    erroredItems?: ItemsToUpdateFromFile[];
  };
}

export const ImportSelect = ({ size }: { size?: SizeType }) => {
  const { message, notification: AntNotification } = App.useApp();
  const { user } = useContext(UserContext);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showImportDataModal, setShowImportDataModal] = useState(false);
  const [poQtyUpdates, setPOQtyUpdates] = useState<PoUpdateMap>({});
  const [poTimeout, setPOTimeout] = useState<ReturnType<typeof setTimeout>>();
  const [processingUpload, setProcessingUpload] = useState(false);
  const [showShortMonitor, setShowShortMonitor] = useState(false);
  const [shortMonitorErrorState, setShortMonitorErrorState] = useState({
    visible: false,
    errors: [''],
    fileName: ''
  });
  const [updateQuantity] = useMutation(UpdateQuantityDocument);
  const [sendImportSeed] = useMutation(ImportSeedDataDocument, {
    onCompleted: () => {
      message.success('Data was successfully uploaded.');
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`An error occured while uploading data. ${error.message}`);
    }
  });
  const [pendingReqId, setPendingReqId] = useState('');

  const importAction = (type: string) => {
    switch (type) {
      case 'BULK_UPDATE_QTY':
        setShowUploadFileModal(true);
        break;
      case 'IMPORT_SEED':
        setShowImportDataModal(true);
        break;
      case 'SHORT_MONITOR':
        setShowShortMonitor(true);
        break;
      default:
        console.log('Out of type');
    }

    // if (type === 'BULK_UPDATE_QTY') {
    //   setShowUploadFileModal(true);
    // } else if (type === 'IMPORT_SEED') {
    //   setShowImportDataModal(true);
    // }
  };

  const completeQtyUpdate = useCallback(() => {
    const pending = Object.values(poQtyUpdates).filter((map) => !map.failed && !map.complete);
    const complete = Object.values(poQtyUpdates).filter((map) => map.complete);
    const failed = Object.keys(poQtyUpdates).filter((key) => poQtyUpdates[key].failed);
    if (pending.length === 0) {
      let title = '';
      let icon = <CheckCircleOutlined style={{ fontSize: 30, color: '#88b441' }} />;
      if (failed.length && complete.length) {
        title = 'Partial Success';
        icon = <WarningOutlined style={{ fontSize: 30, color: '#f5b042' }} />;
      } else if (!failed.length) {
        title = 'Success';
      } else if (!complete.length) {
        title = 'Unsuccessful';
        icon = <ExclamationCircleOutlined style={{ fontSize: 30, color: '#f53131' }} />;
      }

      AntNotification.open({
        message: title,
        description: `${complete.length} purchase ${
          complete.length !== 1 ? 'orders' : 'order'
        } updated. ${
          !failed.length ? '' : `${failed.length} POs failed to upload: ${failed.join(',')}`
        }`,
        icon
      });

      setProcessingUpload(false);
      setShowUploadFileModal(false);
      setPOQtyUpdates({});
    }
  }, [poQtyUpdates]);

  const updateQty = async (poExternalId: string) => {
    const data = poQtyUpdates[poExternalId].po;
    const itemArray = poQtyUpdates[poExternalId].items;

    if (!data) {
      AntNotification.warning({
        message: 'Unable to find PO',
        description: `${poExternalId} was not found. The updates could not be applied for this PO.`,
        props: {
          'data-testid': 'import-unable-to-find-po-notification'
        }
      });
      poQtyUpdates[poExternalId].failed = true;
      completeQtyUpdate();
      return;
    } else if (data.tradingPartner?.deliveryType !== 'DSD') {
      AntNotification.warning({
        message: 'Unsupported PO Type',
        description: `${poExternalId} is not a DSD order. Updates to it's quantities will not be included`
      });
      poQtyUpdates[poExternalId].failed = true;
      completeQtyUpdate();
      return;
    } else if ((data.statuses?.primary || '').toUpperCase() === PO_STATUS_SUBMITTED) {
      AntNotification.warning({
        message: 'PO is already submitted',
        description: `${poExternalId} has already been released and can not be changed.`
      });
      poQtyUpdates[poExternalId].failed = true;
      completeQtyUpdate();
      return;
    }

    poQtyUpdates[poExternalId].erroredItems = [];
    const toUpdate = itemArray
      .map((item) => {
        const poItemId = data.purchaseOrderItems.find((poItem) => {
          let cleanItemId = poItem.product?.upc?.replace(/^0+/, '') || '';

          while (cleanItemId.charAt(cleanItemId.length - 1) === '0') {
            cleanItemId = cleanItemId.substr(0, cleanItemId.length - 1);
          }

          let cleanUploadId = item?.GTIN?.replace(/^0+/, '') || item?.UPC?.replace(/^0+/, '');

          while (cleanUploadId.charAt(cleanUploadId.length - 1) === '0') {
            cleanUploadId = cleanUploadId.substr(0, cleanUploadId.length - 1);
          }

          return cleanItemId && cleanUploadId && cleanItemId === cleanUploadId;
        })?.id;

        const poItemProductId = (data.purchaseOrderItems || []).find(
          (poItem) => poItem.id === poItemId
        )?.product?.id;

        if (!poItemId) {
          poQtyUpdates[poExternalId].erroredItems?.push(item);
          return null;
        }

        const qty = item['Ordered Qty'] || item.Qty || item.Quantity;

        if (typeof qty === 'undefined') {
          return null;
        }

        return {
          id: poItemId || '',
          productId: poItemProductId || '',
          quantityOrdered: parseInt(qty)
        };
      })
      .filter((item) => !!item);

    if (poQtyUpdates[poExternalId].erroredItems?.length) {
      AntNotification.warning({
        message: 'UPC(s) not found',
        description: `Unable to match the below UPCs with existing products on the purchase order. ${poQtyUpdates[
          poExternalId
        ].erroredItems
          ?.map((item) => item.GTIN || item.UPC)
          .filter((upc) => !!upc)
          .concat(',')}`
      });
    }

    try {
      await updateQuantity({ variables: { input: { toUpdate: toUpdate } } });
      poQtyUpdates[poExternalId].complete = true;
      completeQtyUpdate();
    } catch (error) {
      setProcessingUpload(false);
      Sentry.captureException(error);
    }
  };

  const [getPO] = useLazyQuery(PurchaseOrderItemsImportSelectDocument, {
    onCompleted: ({ purchaseOrders }) => {
      if (purchaseOrders?.edges.length) {
        const data = purchaseOrders.edges[0].node;
        if (data?.customerPo && poQtyUpdates[data.customerPo]) {
          poQtyUpdates[data.customerPo].po = data;
          if (!poQtyUpdates[data.customerPo].failed) {
            updateQty(data.customerPo);
          }
        }
      }

      const pending = Object.values(poQtyUpdates).filter((map) => !map.po && !map.failed);
      if (pending.length === 0) {
        if (poTimeout) {
          clearTimeout(poTimeout);
          setPOTimeout(undefined);
        }
      } else {
        getNextPo(poQtyUpdates);
      }
    },
    onError: (error) => {
      if (poQtyUpdates) poQtyUpdates[pendingReqId].failed = true;
      Sentry.captureException(error);
    }
  });

  useEffect(() => {
    if (!poQtyUpdates || !Object.keys(poQtyUpdates).length) return;

    const timer = setTimeout(() => {
      const timeouts = Object.keys(poQtyUpdates).filter(
        (key) => !poQtyUpdates[key].complete && !poQtyUpdates[key].failed
      );

      if (!timeouts.length) return;

      timeouts.forEach((key) => {
        poQtyUpdates[key].failed = true;
      });

      AntNotification.warning({
        message: 'Unable to find PO(s)',
        description: `The updates could not be applied for the following PO(s): ${timeouts.join(
          ','
        )}`
      });

      setPOTimeout(undefined);
      completeQtyUpdate();
    }, 6000);
    return () => clearTimeout(timer);
  }, [poQtyUpdates, setPOTimeout, completeQtyUpdate]);

  const updatePurchaseOrderQuantity = async (itemsToUpdate: ItemsToUpdateFromFile[]) => {
    setProcessingUpload(true);
    const poNumbers = [...new Set(itemsToUpdate.map((item) => String(item['PO #']).trim()))];

    const updates: PoUpdateMap = {};
    for (const poNumber of poNumbers) {
      updates[poNumber] = {
        items: itemsToUpdate.filter((item) => String(item['PO #']).trim() === poNumber && item.GTIN)
      };
    }
    setPOQtyUpdates(updates);
    getNextPo(updates);
  };

  const getNextPo = (updates: PoUpdateMap) => {
    const pendingIds = Object.keys(updates).filter((key) => {
      return !updates[key].po && !updates[key].failed;
    });
    if (pendingIds.length) {
      setPendingReqId(pendingIds[0]);
      getPO({
        variables: {
          filter: { searchableIdentifiers: [pendingIds[0]] }
        }
      });
    }
  };

  return (
    <>
      <AlloySelect
        data-testid="po-page-import-select"
        value="Import"
        onSelect={importAction}
        popupMatchSelectWidth={false}
        size={size}
      >
        <Option value="BULK_UPDATE_QTY" data-testid="import-select-bulk-update-qty">
          Bulk update qty (.csv)
        </Option>
        {user?.availableActions.includes('UPLOAD_SHORT_MONITOR') && (
          <Option value="SHORT_MONITOR" data-testid="import-select-short-monitor">
            Upload Short Monitor
          </Option>
        )}
        {isStagingBackend() && user?.availableActions.includes('IMPORT_SEED_DATA') && (
          <Option value="IMPORT_SEED" data-testid="import-select-import-seed">
            Import data
          </Option>
        )}
      </AlloySelect>
      <UploadQtyFileModal
        visible={showUploadFileModal}
        toggleUploadFileModal={() => setShowUploadFileModal(!showUploadFileModal)}
        updatePurchaseOrderQuantity={updatePurchaseOrderQuantity}
        loading={processingUpload}
      />
      <UploadShortMonitorModal
        visible={showShortMonitor}
        setShortMonitorErrorState={setShortMonitorErrorState}
        toggleUploadShortMonitor={() => setShowShortMonitor(!showShortMonitor)}
      />
      <ShortMonitorErrorModal
        toggleShortMonitorErrorModal={() =>
          setShortMonitorErrorState({
            ...shortMonitorErrorState,
            visible: !shortMonitorErrorState.visible
          })
        }
        visible={shortMonitorErrorState.visible}
        errors={shortMonitorErrorState.errors}
        fileName={shortMonitorErrorState.fileName}
        toggleUploadShortMonitor={() => setShowShortMonitor(!showShortMonitor)}
      />
      {showImportDataModal && (
        <UploadFileModal
          visible={showImportDataModal}
          toggleUploadFileModal={() => setShowImportDataModal(!showImportDataModal)}
          onConfirmUpload={async (file) => {
            sendImportSeed({
              variables: {
                input: {
                  data: file
                }
              }
            });
            setShowImportDataModal(false);
          }}
          uploadButtonTitle="Import"
        />
      )}
    </>
  );
};
