import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReprocessPurchaseOrdersMutationVariables = Types.Exact<{
  input: Types.ReprocessPurchaseOrdersInput;
}>;


export type ReprocessPurchaseOrdersMutation = { __typename?: 'RootMutationType', reprocessPurchaseOrders?: { __typename?: 'ReprocessPurchaseOrdersPayload', purchaseOrders: Array<{ __typename?: 'PurchaseOrder', id: string }> } | null };


export const ReprocessPurchaseOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"reprocessPurchaseOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReprocessPurchaseOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reprocessPurchaseOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<ReprocessPurchaseOrdersMutation, ReprocessPurchaseOrdersMutationVariables>;