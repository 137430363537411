import React from 'react';
import s from './BrandFillRateColumnChart.module.scss';
import { Bar } from 'react-chartjs-2';
import { FillRateReportMetricFragment } from 'pages/FillRateReport/gql/__generated__/fillRateReportMetric.fragment';

interface BrandFillRateColumnChartProps {
  metricsBySubBusAndCatalogGroups?: {
    subBusinessUnitId: string;
    metrics?: FillRateReportMetricFragment;
    metricsByBrands: {
      catalogGroupName?: string | null | undefined;
      metrics: FillRateReportMetricFragment;
    }[];
  };
}

const FILL_RATE_LIMIT_VALUE = 64;

export const BrandFillRateColumnChart = ({
  metricsBySubBusAndCatalogGroups
}: BrandFillRateColumnChartProps) => {
  const data = {
    labels:
      metricsBySubBusAndCatalogGroups?.metricsByBrands?.map(
        (brandMetrics) => brandMetrics.catalogGroupName || 'Unknown'
      ) || [],
    datasets: [
      {
        data:
          metricsBySubBusAndCatalogGroups?.metricsByBrands?.map(
            (brandMetrics) => brandMetrics.metrics.submittedFillRate
          ) || [],
        barThickness: 28,
        backgroundColor: (ctx: any) =>
          Number(ctx.raw) > FILL_RATE_LIMIT_VALUE
            ? Number(ctx.raw) > 100
              ? '#FF565F'
              : '#91C48A'
            : '#FFA59C'
      }
    ]
  };
  return metricsBySubBusAndCatalogGroups ? (
    <div className={s.chart_container}>
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: () => {
                  return '';
                },
                label: (tooltipItem) => `${tooltipItem.formattedValue}%`
              }
            },
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  yMin: FILL_RATE_LIMIT_VALUE,
                  yMax: FILL_RATE_LIMIT_VALUE,
                  borderColor: '#000000',
                  borderWidth: 1,
                  borderDash: () => [4],
                  label: {
                    content: `${FILL_RATE_LIMIT_VALUE}%`,
                    display: true,
                    position: 'end',
                    backgroundColor: 'rgba(0,0,0,0)',
                    yAdjust: -8,
                    xAdjust: 10,
                    color: '#000000'
                  }
                }
              }
            }
          },
          scales: {
            x: {
              ticks: {
                maxRotation: 90,
                minRotation: 90
              },
              grid: {
                drawOnChartArea: false
              }
            },
            y: {
              ticks: {
                callback: function (value: string | number) {
                  return value + '%';
                }
              },
              min: 0,
              suggestedMax: 100,
              grid: {
                drawOnChartArea: false
              }
            }
          }
        }}
      />
    </div>
  ) : null;
};
