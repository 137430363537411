import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Row } from 'antd';
import { FinalFormDatePicker } from 'components/Common/fields/FinalFormDatePicker/FinalFormDatePicker';
import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import React, { useRef } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import {
  getCoordinatesValidation,
  getLengthValidation,
  getTestRegexpValidationWithCustomMessage,
  parseIntValue,
  validateEmails,
  validateRequired
} from 'components/Common/fields/Utils';
import { FinalFormSwitch } from 'components/Common/fields/FinalFormSwitch/FinalFormSwitch';
import s from './GeneralSettingsForm.module.scss';
import { useEnumValue } from 'common/useEnumValue';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { DcSourceSystemParamsCheck } from '../DcSourceSystemParamsCheck';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import { EditDistributionCenterFacilitiesDocument } from 'pages/DistributionCentersPage/gql/__generated__/editDistributionCenterFacilitiesData.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { AddFacilityForm } from '../AddFacilityForm/AddFacilityForm';

const { Panel } = Collapse;

const INCORRECT_DECIMAL_VALUE = 'Incorrect value. Please enter a positive number.';
const DECIMAL_REGEXP = /^[0-9]+(?:\.[0-9]+)?$/;
const decimalNumberValidator = getTestRegexpValidationWithCustomMessage(
  DECIMAL_REGEXP,
  INCORRECT_DECIMAL_VALUE
);

interface GeneralSettingsFormProps {
  upserting: boolean;
  isDCFacilityManagementEnabled: boolean | undefined;
}

export const GeneralSettingsForm = ({
  upserting,
  isDCFacilityManagementEnabled
}: GeneralSettingsFormProps) => {
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const {
    enumValues: distributionCenterSourceValues,
    loading: distributionCenterSourceValuesLoading
  } = useEnumValue('DistributionCenterSource');
  const { enumValues: inventorySourceValues, loading: inventorySourceValuesLoading } =
    useEnumValue('InventorySource');

  const form = useForm();

  /***********   FACILITIES: CALL & PREPARE LIST  *************/
  const { data: facilitiesData, loading: facilitiesDataLoading } = useQuery(
    EditDistributionCenterFacilitiesDocument,
    {
      skip: !isDCFacilityManagementEnabled
    }
  );

  if (facilitiesDataLoading && !facilitiesData) return <LoaderSpinner />;

  const facilitiesList = getNodesFromEdges(facilitiesData?.distributionCenterFacilities);
  /********************************************************* */

  return (
    <div style={{ flex: '1' }}>
      <Row className={s.title_row}>General Settings</Row>
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={24} className={s.integration_settings_info}>
          <DcSourceSystemParamsCheck codeFieldName="code" sourceFieldName="source" />
        </Col>
        <Col span={12} style={{ paddingLeft: '0' }}>
          <FinalFormInput
            name="code"
            title="Code"
            validate={!upserting ? validateRequired : undefined}
            required
          />
          <FinalFormSelect
            loading={distributionCenterSourceValuesLoading}
            name="source"
            title="Source"
            required
            validate={!upserting ? validateRequired : undefined}
            options={(distributionCenterSourceValues || []).map((dcSource) => ({
              value: dcSource,
              label: dcSource
            }))}
            allowClear
          />
        </Col>
        <Col span={12} style={{ paddingRight: '0' }}>
          <FinalFormInput
            name="name"
            title="Name"
            validate={!upserting ? validateRequired : undefined}
            required
          />
          {isDCFacilityManagementEnabled && (
            <FinalFormSelect
              loading={facilitiesDataLoading}
              data-testid="dc-facility-select"
              name="distributionCenterFacilityId"
              title="Facility"
              options={facilitiesList.map((facility) => ({
                value: facility.id,
                label: facility.name
              }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <AddFacilityForm
                    onFacilityAdded={(id) => {
                      form.mutators.setDistributionCenterFacilityId(id);
                      addButtonRef.current?.focus();
                    }}
                  />
                </>
              )}
              allowClear
              // required
              // validate={!upserting ? validateRequired : undefined}
            />
          )}
        </Col>
      </Row>
      <Row className={s.title_row}>Address</Row>
      <Row gutter={16} justify="space-between" style={{ margin: 0 }}>
        <Col span={24} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <FinalFormInput name="careOf" title="Care Of" />
        </Col>
        <Col span={12} style={{ paddingLeft: '0' }}>
          <FinalFormInput name="address" title="Address" />
          <FinalFormInput name="state" title="State" validate={getLengthValidation(2)} />
          <FinalFormInput
            name="lat"
            validate={getCoordinatesValidation(/^((\-?|\+?)?\d+(\.\d+)?)/g)}
            title="Latitude"
          />
          <FinalFormInput name="phone" title="Phone" />
        </Col>
        <Col span={12} style={{ paddingRight: '0' }}>
          <FinalFormInput name="city" title="City" />
          <FinalFormInput name="zip" title="Zip" />
          <FinalFormInput
            name="lng"
            validate={getCoordinatesValidation(/^((\-?|\+?)?\d+(\.\d+)?)/g)}
            title="Longitude"
          />
          <FinalFormSelect
            name="emails"
            title="Emails"
            mode="tags"
            options={[]}
            hideDropdown
            validate={validateEmails}
          />
        </Col>
      </Row>

      {/* Identifiers and Sources section */}
      {/* TODO: delete else condition after schip_dc_facility_management FF removed */}
      {isDCFacilityManagementEnabled ? (
        <FieldArray name="siblings">
          {({ fields }) => (
            <>
              <Row className={s.title_row}>Identifiers and Sources</Row>
              <Row
                style={{
                  display: !form.getFieldState('siblings')?.value ? 'none' : undefined
                }}
              >
                {fields.map((name, index) => (
                  <div key={index} style={{ width: '100%' }}>
                    <div className={s.item_integration_settings_info}>
                      {/* TODO: do we need this component below if we are using distributionCenter.siblings and not distributionCenter.codes? */}
                      <DcSourceSystemParamsCheck
                        sourceFieldName={`codes[${index}].source`}
                        codeFieldName={`codes[${index}].code`}
                      />
                    </div>
                    <div key={index} className={s.blackout_item}>
                      <FinalFormInput
                        name={`${name}.code`}
                        title="Code"
                        disabled={isDCFacilityManagementEnabled}
                        required
                        validate={upserting ? validateRequired : undefined}
                      />
                      <FinalFormSelect
                        name={`${name}.source`}
                        title="Source"
                        disabled={isDCFacilityManagementEnabled}
                        required
                        validate={upserting ? validateRequired : undefined}
                        loading={distributionCenterSourceValuesLoading}
                        options={(distributionCenterSourceValues || []).map((source) => ({
                          value: source,
                          label: source
                        }))}
                      />
                    </div>
                  </div>
                ))}
              </Row>
            </>
          )}
        </FieldArray>
      ) : (
        <FieldArray name="codes">
          {({ fields }) => (
            <>
              <Row className={s.title_row}>
                Identifiers and Sources
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => {
                    fields.push({ field: '', title: '', fieldType: 'TEXT' });
                  }}
                >
                  <PlusOutlined />
                </Button>
              </Row>
              <Row
                style={{
                  display: !form.getFieldState('codes')?.value ? 'none' : undefined
                }}
              >
                {fields.map((name, index) => (
                  <div style={{ width: '100%' }}>
                    <div className={s.item_integration_settings_info}>
                      <DcSourceSystemParamsCheck
                        sourceFieldName={`codes[${index}].source`}
                        codeFieldName={`codes[${index}].code`}
                      />
                    </div>
                    <div key={index} className={s.blackout_item}>
                      <FinalFormInput
                        name={`${name}.code`}
                        title="Code"
                        required
                        validate={upserting ? validateRequired : undefined}
                      />
                      <FinalFormSelect
                        name={`${name}.source`}
                        title="Source"
                        required
                        validate={upserting ? validateRequired : undefined}
                        loading={distributionCenterSourceValuesLoading}
                        options={(distributionCenterSourceValues || []).map((source) => ({
                          value: source,
                          label: source
                        }))}
                      />
                      <FinalFormInput name={`${name}.dummySapSoldTo`} title="Dummy SAP Sold To" />
                      <Button
                        shape="circle"
                        type="text"
                        onClick={() => {
                          fields.remove(index);
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                    </div>
                  </div>
                ))}
              </Row>
            </>
          )}
        </FieldArray>
      )}

      <Row className={s.title_row}>Truck capacity</Row>
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12} style={{ paddingLeft: '0' }}>
          <FinalFormInput
            name="truckCapacityPallets"
            title="Pallets"
            required
            validate={validateRequired}
            parse={parseIntValue}
            type="number"
          />
        </Col>
        <Col span={12} style={{ paddingRight: '0' }}>
          <FinalFormInput
            name="truckCapacityWeight"
            title="Weight (lb)"
            required
            validate={validateRequired}
            parse={parseIntValue}
            type="number"
          />
        </Col>
      </Row>
      <Row className={s.title_row}>Cost & Capacity</Row>
      <Row gutter={16} justify="space-between" style={{ margin: 0 }}>
        <Col span={12} style={{ paddingLeft: '0' }}>
          <FinalFormInput
            name="currentPalletCapacity"
            title="Current Pallet Capacity"
            parse={parseIntValue}
            type="number"
            min="0"
          />
          <FinalFormInput
            name="monthlyHoldingCostPerPalletPosition"
            title="Monthly Holding Cost Per Pallet Position (US$)"
            validate={decimalNumberValidator}
            type="number"
            min="0"
          />
          <FinalFormInput
            name="operatingCostPerHour"
            title="Operating Cost Per Hour (US$)"
            validate={decimalNumberValidator}
            type="number"
            min="0"
          />
        </Col>
        <Col span={12} style={{ paddingRight: '0' }}>
          <FinalFormInput
            name="maxPalletCapacity"
            title="Max Pallet Capacity"
            parse={parseIntValue}
            type="number"
            min="0"
          />
          <FinalFormInput
            name="monthlyLeaseCost"
            title="Monthly Lease Cost (US$)"
            validate={decimalNumberValidator}
            type="number"
            min="0"
          />
          <FinalFormInput
            name="operatingCostPerCase"
            title="Operating Cost Per Case (US$)"
            validate={decimalNumberValidator}
            type="number"
            min="0"
          />
        </Col>
      </Row>
      <Collapse
        bordered={false}
        className={s.collapse}
        activeKey={[form.getFieldState('useWmsConfig')?.value ? '1' : '']}
      >
        <Panel
          header={
            <div className={s.wms_config}>
              WMS config
              <FinalFormSwitch
                name="useWmsConfig"
                title=""
                stopPropagation={true}
                data-testid="edit-dc-wmsConfig-switch"
              />
            </div>
          }
          key="1"
        >
          {form.getFieldState('useWmsConfig')?.value ? (
            <Row gutter={16} style={{ margin: 0 }}>
              <Col span={24} style={{ paddingLeft: '0', paddingRight: '0' }}>
                <FinalFormInput
                  title="External ID"
                  name="wmsConfigExternalId"
                  data-testid="edit-dc-wmsConfig-externalId"
                  required
                />
              </Col>
            </Row>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </Panel>
      </Collapse>
      <Row className={s.title_row}>Additional settings</Row>
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12} style={{ paddingLeft: '0' }}>
          <FinalFormInput name="dummySapSoldTo" title="Dummy SAP Sold To" />
          <FinalFormInput name="transaverRef" title="Transaver Reference" />
        </Col>
        <Col span={12} style={{ paddingRight: '0' }}>
          <FinalFormSelect
            loading={inventorySourceValuesLoading}
            name="inventorySource"
            title="Inventory Source"
            options={(inventorySourceValues || []).map((source) => ({
              value: source,
              label: source
            }))}
            allowClear
          />
        </Col>
      </Row>
      <FieldArray name="blackouts">
        {({ fields }) => (
          <>
            <Row className={s.title_row}>
              Blackouts
              <Button
                shape="circle"
                type="text"
                onClick={() => {
                  fields.push({ date: null, dayOfWeek: null });
                }}
              >
                <PlusOutlined />
              </Button>
            </Row>
            <Row style={{ margin: 0 }}>
              {fields.map((name, index) => (
                <div key={index} className={s.blackout_item}>
                  <FinalFormDatePicker
                    name={`${name}.date`}
                    title="Date"
                    disabled={fields.value[index].dayOfWeek}
                  />
                  <p> / </p>
                  <FinalFormSelect
                    disabled={fields.value[index].date}
                    name={`${name}.dayOfWeek`}
                    title="Day Of Week"
                    allowClear
                    options={moment.weekdays().map((d) => ({
                      value: d.toUpperCase(),
                      label: d.toUpperCase()
                    }))}
                  />
                  <Button
                    shape="circle"
                    type="text"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              ))}
            </Row>
          </>
        )}
      </FieldArray>
    </div>
  );
};
