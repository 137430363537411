import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  StoresForBulkUploadDocument,
  StoresForBulkUploadQuery
} from '../../gql/__generated__/stores.query';

export type RddForBulkUpload = InferNodeType<
  StoresForBulkUploadQuery,
  'retailerDeliveryDestinations'
>;

export type TradingPartnerNode = {
  id: string;
  externalId: string;
  distributionCenters: {
    id: string;
    code: string;
  }[];
};

/* This hook will request all retailer delivery destinations for the provided array of trading partner ids. This can be replaced if RDD is attached to vendorMarket node */
export const useStores = (ids: string[]) => {
  const [stores, setStores] = useState<(RddForBulkUpload & { [key: string]: any })[]>([]);

  const [getStores, { loading }] = useLazyQuery(StoresForBulkUploadDocument, {
    onCompleted: (data) => {
      setStores(getNodesFromEdges(data.retailerDeliveryDestinations));
    }
  });

  // TODO: throw away this useeffect and setState
  useEffect(() => {
    if (ids && ids.length > 0) {
      getStores({
        variables: {
          filter: {
            vendorMarkets: ids
          }
        }
      });
    } else {
      setStores([]);
    }
  }, [ids, getStores]);

  const refetch = () => {
    setStores([]);
    getStores({
      variables: {
        filter: {
          vendorMarkets: ids
        }
      }
    });
  };

  return { stores, storesLoading: loading, refetch };
};
