import clsx from 'clsx';
import { useEnumValue } from 'common/useEnumValue';
import { getDefaultFieldTitles } from 'components/ChangeHistory/helpers/baseHelper';
import { AssortmentConfigFieldSlug } from 'graphql/__generated__/types';
import { getValidationTypeTitle } from 'pages/TradingPartnersPage/components/forms/ActiveAssortmentTab/components/ValidationRulesModal/ValidationRulesModal';
import React from 'react';
import { AssortmentConfig } from '../../../../TradingPartnerViewDrawer';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import s from './ActiveAssortmentViewTab.module.scss';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';

type FieldData = AssortmentConfig['fields'][number] & { active: boolean };

type Validation = NonNullable<FieldData['validation']>;

const NAME_FIELDS: AssortmentConfigFieldSlug[] = ['NAME'];

const ID_FIELDS: AssortmentConfigFieldSlug[] = [
  'UPC',
  'GTIN14',
  'SAP_MATERIAL_ID',
  'ORACLE_INVEN_ID',
  'EXTERNAL_ID'
];

const ID_FIELDS_OLD: AssortmentConfigFieldSlug[] = [
  'UPC',
  'EXTERNAL_ID',
  'SAP_MATERIAL_ID',
  'ORACLE_INVEN_ID'
];

const DC_FIELDS: AssortmentConfigFieldSlug[] = ['DISTRIBUTION_CENTER_IDS'];

const MEASUREMENTS_FIELDS: AssortmentConfigFieldSlug[] = [
  'GROSS_WEIGHT',
  'WIDTH',
  'HEIGHT',
  'DEPTH'
];

const QUANTITY_FIELDS: AssortmentConfigFieldSlug[] = [
  'TO_CASE_QUANTITY',
  'CASES_PER_LAYER',
  'CASES_PER_PALLET'
];

const PRICE_FIELDS: AssortmentConfigFieldSlug[] = ['CURRENCY', 'PRICE'];

const MOQ_FIELDS: AssortmentConfigFieldSlug[] = ['MOQ_MINIMUM', 'MOQ_UNIT_OF_MEASURE'];

const ALL_PREORDERED_FIELDS = [
  ID_FIELDS,
  NAME_FIELDS,
  DC_FIELDS,
  MEASUREMENTS_FIELDS,
  QUANTITY_FIELDS,
  PRICE_FIELDS,
  MOQ_FIELDS
];

const ALL_PREORDERED_FIELDS_OLD = [
  NAME_FIELDS,
  ID_FIELDS_OLD,
  DC_FIELDS,
  MEASUREMENTS_FIELDS,
  QUANTITY_FIELDS,
  PRICE_FIELDS,
  MOQ_FIELDS
];

const getValidationString = (validation: Validation) => {
  return validation.rules.length > 0
    ? `${validation.rules
        .map(
          (rule) =>
            `${getValidationTypeTitle(rule.type)}, ${
              rule.value ? `With character limit (${rule.value})` : 'No character limit'
            }`
        )
        .join(' or ')}.`
    : null;
};

export const ActiveAssortmentViewTab = ({
  // TODO: remove with FF
  tradingPartnerExternalId,
  config
}: {
  // TODO: remove with FF
  tradingPartnerExternalId: string;
  config: AssortmentConfig | null | undefined;
}) => {
  const { enumValues: allSlugs } = useEnumValue('AssortmentConfigFieldSlug');

  const { enabled: useNewAssortment } = useFeatureFlag({
    name: 'schip_assortment_v3_management_for_tp',
    tradingPartnerExternalId: tradingPartnerExternalId
  });

  const additionalFeatures = (
    <div className={s.features_container}>
      <div className={s.subtitle}>Included features for active assortment</div>
      <ViewField type="CHECKBOX" title="Multiple UPCs" value={config?.multipleUpc} />
      {useNewAssortment && (
        <ViewField
          type="CHECKBOX"
          title="Use Multiple Substitutions"
          value={config?.useMultipleSubstitutions}
        />
      )}
      {useNewAssortment && (
        <ViewField
          type="CHECKBOX"
          title="Use DC Availabilities"
          value={config?.useDcAvailabilities}
        />
      )}
      <ViewField type="CHECKBOX" title="Vendor Flex" value={config?.specialAssortment} />
    </div>
  );

  const getFieldView = (fieldData: FieldData, single?: boolean) => (
    <div
      key={`${fieldData.slug}-${fieldData.name}`}
      className={clsx(s.field, { [s.not_active_single]: !fieldData.active && !!single })}
    >
      <div className={s.row}>
        <ViewField
          type="SWITCH"
          value={fieldData.active}
          title={fieldData.name}
          className={s.margin_left}
        />
        <div
          className={clsx({ [s.reqiered]: fieldData.required, [s.optional]: !fieldData.required })}
        >
          {fieldData.active ? (fieldData.required ? 'Required' : 'Optional') : ''}
        </div>
      </div>
      {fieldData.validation && (
        <div className={s.validation}>{getValidationString(fieldData.validation)}</div>
      )}
    </div>
  );

  const getFieldDataBySlug = (slug: AssortmentConfigFieldSlug) => {
    const existing = config?.fields.find((field) => field.slug === slug);
    return {
      ...existing,
      active: !!existing,
      name: existing?.name || getDefaultFieldTitles(slug),
      required: !!existing?.required
    };
  };

  const fields = (
    <>
      {useNewAssortment && (
        <ViewField title="Product Identifier Type" type="INPUT" value={config?.catalogItemType} />
      )}
      <div className={clsx(s.subtitle, s.margin_bottom)}>
        Specify the rules associated with each field when adding a new product
      </div>
      {(useNewAssortment ? ALL_PREORDERED_FIELDS : ALL_PREORDERED_FIELDS_OLD).map((fieldSlugs) => (
        <React.Fragment key={fieldSlugs.join('-')}>
          {fieldSlugs.map((slug) => getFieldView(getFieldDataBySlug(slug)))}
          <div className={s.divider} />
        </React.Fragment>
      ))}
      {allSlugs
        ?.filter(
          (slug): slug is AssortmentConfigFieldSlug =>
            !(useNewAssortment ? ALL_PREORDERED_FIELDS : ALL_PREORDERED_FIELDS_OLD)
              .flat()
              .includes(slug as AssortmentConfigFieldSlug)
        )
        .map((slug) => (
          <React.Fragment key={slug}>
            {getFieldView(getFieldDataBySlug(slug), true)}
            <div className={s.divider} />
          </React.Fragment>
        ))}
    </>
  );

  return (
    <div className={s.config_container}>
      <ViewRow title="Additional Features" content={additionalFeatures} bordered={true} />
      <ViewRow title="Configure Active Assortment from fields" content={fields} bordered={false} />
    </div>
  );
};
