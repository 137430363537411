export function setArrayWithAll(
  newValue: string[],
  prevValue: string[],
  setState: (value: string[]) => void,
  allValue = 'all'
) {
  if (newValue.length === 0) {
    setState([allValue]);
  } else if (newValue.includes('all')) {
    if ((prevValue || []).includes('all')) {
      setState(newValue.filter((v) => v !== 'all'));
    } else {
      setState([allValue]);
    }
  } else {
    setState(newValue);
  }
}

export function getValueWithAll(value: string[], allValues: string[]): string[] {
  if (value[0].toLowerCase() === 'all') {
    return allValues;
  } else {
    return value;
  }
}
