import { ExclamationCircleFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Modal, Select, Spin, Table } from 'antd';
import { isGroupedSelectOption } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import { SearchField } from 'components/Common/SearchField/SearchField';
import {
  RetailerDeliveryDestinationDocument,
  RetailerDeliveryDestinationQuery
} from 'pages/ShipToPage/gql/__generated__/retailerDeliveryDestination.query';
import {
  TradingPartnerRddsDocument,
  TradingPartnerRddsQuery
} from 'pages/ShipToPage/gql/__generated__/tradingPartnerRdds.query';
import { RetailerChannelsForShipTo } from 'pages/ShipToPage/ShipToPage';
import React, { useCallback, useEffect, useState } from 'react';
import { TradingPartner } from '../../AddShipToModal';
import { getTradingPartnerOptions } from '../AddShipToStepper/AddShipToStepper';
import s from './SelectShipToModal.module.scss';

export const TP_RDD_ERROR = 'Ship-To is already associated with the Trading Partner';

interface SelectShipToModalProps {
  tradingPartners: TradingPartner[];
  onSelect: (tpTo: string, shipTo: RetailerDeliveryDestinationToCopy) => void;
  onCancel: () => void;
  vendorMarkets: RetailerChannelsForShipTo[];
}

type TradingPartnerRdd = NonNullable<
  NonNullable<
    NonNullable<TradingPartnerRddsQuery['tradingPartner']>['retailerDeliveryDestinations']
  >[number]
>;

export type RetailerDeliveryDestinationToCopy = Omit<
  NonNullable<NonNullable<RetailerDeliveryDestinationQuery['retailerDeliveryDestination']>>,
  'id'
> & { id: string | undefined };

export const SelectShipToModal = ({
  tradingPartners,
  onSelect,
  onCancel,
  vendorMarkets
}: SelectShipToModalProps) => {
  const [selectedTradingPartnerFrom, setSelectedTradingPartnerFrom] = useState<
    string | undefined
  >();
  const [selectedTradingPartnerTo, setSelectedTradingPartnerTo] = useState<string | undefined>();
  const [selectedShipTo, setSelectedShipTo] = useState<string | undefined>();

  const [shipTos, setShipTos] = useState<TradingPartnerRdd[]>([]);

  const [searchValue, setSearchValue] = useState<string>();
  const [error, setError] = useState<string>();

  const [getTradingPartnerShipTos, { loading, data }] = useLazyQuery(TradingPartnerRddsDocument, {
    onCompleted: (result) => {
      setShipTos(
        (result.tradingPartner?.retailerDeliveryDestinations || [])
          .filter(
            (rdd) => !!rdd && rdd.name?.toLowerCase().includes((searchValue || '').toLowerCase())
          )
          .sort((rdd1, rdd2) => (rdd1.name || '').localeCompare(rdd2.name || ''))
      );
    }
  });

  const [loadDeliveryDestination, { loading: deliveryDestinationLoading }] = useLazyQuery(
    RetailerDeliveryDestinationDocument,
    {
      onCompleted: (result) => {
        const { retailerDeliveryDestination } = result;
        if (selectedTradingPartnerTo && retailerDeliveryDestination)
          onSelect(selectedTradingPartnerTo, {
            ...retailerDeliveryDestination
          } as RetailerDeliveryDestinationToCopy);
      }
    }
  );

  const handleSearch = useCallback(
    (value) => {
      setSearchValue(value);
      if (selectedTradingPartnerFrom) {
        setShipTos(
          ((data?.tradingPartner?.retailerDeliveryDestinations || []) as TradingPartnerRdd[])
            .filter((rdd) => rdd.name?.toLowerCase().includes(value.toLowerCase()))
            .sort((rdd1, rdd2) => (rdd1.name || '').localeCompare(rdd2.name || ''))
        );
      }
    },
    [data?.tradingPartner?.retailerDeliveryDestinations, selectedTradingPartnerFrom]
  );

  useEffect(() => {
    setShipTos([]);
    if (selectedTradingPartnerFrom) {
      getTradingPartnerShipTos({
        variables: {
          id: selectedTradingPartnerFrom
        }
      });
    }
  }, [selectedTradingPartnerFrom, getTradingPartnerShipTos]);

  useEffect(() => {
    if (
      selectedShipTo &&
      selectedTradingPartnerTo &&
      shipTos
        .find((shipTo) => shipTo.id === selectedShipTo)
        ?.tradingPartners?.find((tp) => tp?.id === selectedTradingPartnerTo)
    ) {
      setError(TP_RDD_ERROR);
    } else {
      setError(undefined);
    }
  }, [selectedShipTo, selectedTradingPartnerTo, shipTos]);

  return (
    <Spin spinning={deliveryDestinationLoading}>
      <Modal
        data-testid="select-ship-to-modal"
        title={<span className={s.modal_title}>Choose an existing Ship-To</span>}
        open={true}
        centered
        okText="Choose selected Ship-To"
        onOk={() => {
          if (selectedTradingPartnerFrom && selectedTradingPartnerTo && selectedShipTo) {
            loadDeliveryDestination({
              variables: {
                id: selectedShipTo
              }
            });
          }
        }}
        onCancel={onCancel}
        okButtonProps={{
          disabled:
            !selectedTradingPartnerFrom || !selectedTradingPartnerTo || !selectedShipTo || !!error,
          title: error
        }}
        className={s.modal}
      >
        {error && (
          <div className={s.error} data-testid="select-ship-to-error">
            <ExclamationCircleFilled />
            {error}
          </div>
        )}
        <div className={s.tp_container}>
          <div>
            Select Trading Partner to copy from
            <Select
              data-testid="select-ship-to-from"
              onChange={(value) => {
                setSelectedTradingPartnerFrom(value);
              }}
              showSearch
              filterOption={(input: any, option: any) => {
                const isGrouped = isGroupedSelectOption(option);
                return !isGrouped && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              filterSort={(opt1, opt2) => opt1.label.localeCompare(opt2.label)}
              allowClear
              options={getTradingPartnerOptions(
                tradingPartners.filter(
                  (tp) =>
                    !selectedTradingPartnerTo ||
                    tp.vendorMarket?.vendor?.id ===
                      tradingPartners.find(
                        (selectedTp) => selectedTp.id === selectedTradingPartnerTo
                      )?.vendorMarket?.vendor?.id
                ),
                vendorMarkets
              )}
            />
          </div>
          <div>
            Select Trading Partner to copy to
            <Select
              data-testid="select-ship-to-to"
              onChange={(value) => {
                setSelectedTradingPartnerTo(value);
              }}
              showSearch
              filterOption={(input: any, option: any) => {
                const isGrouped = isGroupedSelectOption(option);
                return !isGrouped && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              filterSort={(opt1, opt2) => opt1.label.localeCompare(opt2.label)}
              allowClear
              options={getTradingPartnerOptions(
                tradingPartners.filter(
                  (tp) =>
                    !selectedTradingPartnerFrom ||
                    tp.vendorMarket?.vendor?.id ===
                      tradingPartners.find(
                        (selectedTp) => selectedTp.id === selectedTradingPartnerFrom
                      )?.vendorMarket?.vendor?.id
                ),
                vendorMarkets
              )}
            />
          </div>
        </div>
        <div className={s.search_panel}>
          <div className={s.ship_to_count}>{shipTos.length} Ship-Tos</div>
          <SearchField
            handleSearch={handleSearch}
            loading={false}
            width={200}
            saveToHistory={false}
            value={searchValue || ''}
          />
        </div>
        <Table
          data-testid="select-ship-to-table"
          loading={loading}
          size="small"
          rowKey={(record) => record.id}
          dataSource={shipTos}
          columns={[
            {
              title: 'Ship-Tos',
              key: 'name',
              render: (_, rdd) => (
                <HighlightedText text={rdd.name || ''} highlight={searchValue || ''} />
              )
            }
          ]}
          rowSelection={{
            selectedRowKeys: selectedShipTo ? [selectedShipTo] : [],
            hideSelectAll: true,
            type: 'radio',
            onSelect: (record) => {
              setSelectedShipTo(record.id);
            }
          }}
          className={s.ship_to_table}
          pagination={{
            defaultPageSize: 8,
            pageSizeOptions: ['8', '10', '20', '50', '100']
          }}
        />
      </Modal>
    </Spin>
  );
};
