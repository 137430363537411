import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import s from './FinalFormCheckbox.module.scss';
import clsx from 'clsx';
import { Checkbox, CheckboxProps } from 'components/alloy/Checkbox/Checkbox';
import { Label } from 'components/alloy/Label/Label';
import { FieldInfo } from 'components/alloy/FieldInfo/FieldInfo';

interface FinalFormCheckboxProps extends CheckboxProps {
  name: string;
  validate?: FieldValidator<string | string[]>;
  inline?: boolean;
  info?: string;
  hasInfo?: boolean;
}

export const FinalFormCheckbox = ({
  name,
  label,
  validate,
  inline = false,
  className,
  'data-testid': dataTestid = '',
  info,
  hasInfo,
  disabled,
  ...other
}: FinalFormCheckboxProps) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <div
          className={clsx(inline ? s.inline_checkbox : s.checkbox, className)}
          data-testid={dataTestid}
        >
          <Label label={label || ''} />
          <Checkbox
            {...input}
            {...other}
            label={label}
            disabled={disabled}
            checked={!!input.value}
          />
          {(validate || hasInfo) && !inline && (
            <FieldInfo
              info={info}
              error={meta.error && (meta.modified || meta.touched) ? meta.error : undefined}
              disabled={disabled}
            />
          )}
        </div>
      )}
    </Field>
  );
};
