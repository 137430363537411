import React from 'react';
import s from './ConfigNotfication.module.scss';
import { WarningFilled } from '@ant-design/icons';

export const ConfigNotfication = ({
  forAdmin,
  tradingPartnerId
}: {
  forAdmin: boolean;
  tradingPartnerId: string;
}) => {
  if (forAdmin) {
    return (
      <div className={s.config_notification}>
        <WarningFilled /> Active Assortment configuration is not set for this Trading Partner.
        Please set the fields needed in{' '}
        <a
          href={`/trading-partners?column=VENDOR_MARKET_NAME&order=ASC&limit=50&edit=${tradingPartnerId}&editTab=activeAssortment`}
        >
          Trading Partner settings
        </a>
      </div>
    );
  }

  return (
    <div className={s.config_notification}>
      <WarningFilled /> Active Assortment configuration is not set for this Trading Partner. Please
      contact admin
    </div>
  );
};
