import React, { useState } from 'react';
import './styles.scss';

interface CopyProps {
  children: string;
  placement?: string;
}

const Copy = ({ children, placement }: CopyProps) => {
  const [copied, setCopied] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  return (
    <div
      className="copy"
      onClick={(e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(children);
        setCopied(true);
        setTimeout(() => {
          setFadeOut(true);
          setTimeout(() => {
            setCopied(false);
            setFadeOut(false);
          }, 200);
        }, 350);
      }}
    >
      <div className="content">{children}</div>
      <div className={`info ${copied ? 'show' : ''} ${placement ? placement : ''}`}>
        <div className={`text ${copied ? 'hide' : ''}`}>Copy</div>
        <div
          className={`confirm ${copied ? '' : 'hide'} ${fadeOut ? 'fade' : ''}`}
          onTransitionEnd={() => {
            setCopied(false);
            setFadeOut(false);
          }}
        >
          Copied
        </div>
      </div>
    </div>
  );
};

export default Copy;
