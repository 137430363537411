import React from 'react';
import ModalSteps from './components/Steps/ModalSteps';
import { ItemsToUpdateFromFile } from 'common/types';
import { PurchaseOrderItem } from 'pages/OrderDetailsPage/types';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface UploadFileModalProps {
  visible: boolean;
  toggleUploadFileModal: () => void;
  itemsForCompare?: PurchaseOrderItem[];
  updatePurchaseOrderQuantity: (
    itemsToUpdate: ItemsToUpdateFromFile[],
    itemsToDelete: PurchaseOrderItem[]
  ) => void;
  poNumber?: string;
  loading?: boolean;
}

const UploadQtyFileModal = ({
  visible,
  toggleUploadFileModal,
  itemsForCompare,
  updatePurchaseOrderQuantity,
  poNumber,
  loading
}: UploadFileModalProps) => {
  return (
    <AlloyModal
      title="Upload File"
      open={visible}
      footer={null}
      onCancel={toggleUploadFileModal}
      destroyOnClose
    >
      <ModalSteps
        poNumber={poNumber}
        closeModal={toggleUploadFileModal}
        updatePurchaseOrderQuantity={updatePurchaseOrderQuantity}
        itemsForCompare={itemsForCompare}
        loading={loading}
      />
    </AlloyModal>
  );
};

export default UploadQtyFileModal;
