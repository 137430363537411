import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';
import { ModalAction } from 'common/constants';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { composeValidators } from 'components/Common/fields/Utils';
import { FinalFormInput } from 'components/FinalFormFields/FinalFormInput/FinalFormInput';
import { getValidators } from './FormFieldComponent/FormFieldComponent';
import { getShowCountData } from 'pages/AssortmentPage/fieldsHelper';
import { spaceRemover } from '../EditTradingPartnerAssortment';
import {
  AssortmentConfig,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { FoundItemLink } from './FoundItemLink/FoundItemLink';
import {
  FindCatalogProductsDocument,
  FindCatalogProductsQuery
} from 'pages/AssortmentPage/gql/__generated__/findCatalogProducts.query';

export type CatalogProduct = InferNodeType<FindCatalogProductsQuery, 'catalogProducts'>;

interface CatalogProductUpcFieldProps {
  tabKey?: string;
  vendorId: string | undefined;
  fillFormWithCatalogProduct: (product: CatalogProduct) => void;
  action: ModalAction;
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
}

export const CatalogProductUpcField = ({
  tabKey,
  vendorId,
  fillFormWithCatalogProduct,
  action,
  formField,
  assortmentConfig
}: CatalogProductUpcFieldProps) => {
  const form = useForm();

  const [showFoundItems, setShowFoundItems] = useState(false);

  const [findCatalogProductItem, { data, loading }] = useLazyQuery(FindCatalogProductsDocument, {
    fetchPolicy: 'no-cache'
  });

  const foundItems = useMemo(() => {
    if (data && data.catalogProducts && getNodesFromEdges(data.catalogProducts).length > 0) {
      return getNodesFromEdges(data.catalogProducts).sort((i1, i2) =>
        safeLocaleCompare(i1?.gtin12, i2?.gtin12)
      );
    } else {
      return undefined;
    }
  }, [data]);

  useEffect(() => {
    if (
      !!vendorId &&
      form.getFieldState(tabKey ? `${tabKey}.upc` : 'upc')?.value?.length > 3 &&
      form.getFieldState(tabKey ? `${tabKey}.upc` : 'upc')?.modified
    ) {
      findCatalogProductItem({
        variables: {
          filter: {
            gtin12Like: form.getFieldState(tabKey ? `${tabKey}.upc` : 'upc')?.value
          }
        }
      });
      setShowFoundItems(true);
    }
  }, [findCatalogProductItem, vendorId, form, action, tabKey]);

  return (
    <>
      <Spin spinning={loading}>
        <FinalFormInput
          data-testid={`aa-edit-modal-${tabKey ? `${tabKey}.upc` : 'upc'}-field`}
          title={formField.name}
          name={tabKey ? `${tabKey}.upc` : 'upc'}
          required={formField.required}
          validate={(value, allValues: any, meta) => {
            const checkDuplicates =
              meta?.active &&
              allValues.multipleUpc?.filter(({ upc }: { upc: string }) => value === upc)?.length >
                1;

            return checkDuplicates
              ? 'Found duplicated UPC'
              : composeValidators(getValidators(formField))(value);
          }}
          parse={spaceRemover}
          onBlur={() => {
            const newValue = form.getFieldState('upc')?.value?.trim();
            form.getFieldState('upc')?.change(newValue?.replaceAll(/[\s]+/g, ' '));
          }}
          showCount={getShowCountData(formField)}
        />
        {foundItems && showFoundItems && !!foundItems.length && (
          <FoundItemLink
            foundItems={foundItems}
            setShowFoundItems={setShowFoundItems}
            fillFormWithCatalogProduct={fillFormWithCatalogProduct}
            assortmentConfig={assortmentConfig}
            tooltip="bottom"
          />
        )}
      </Spin>
      <OnChange name={tabKey ? `${tabKey}.upc` : 'upc'}>
        {(value: string) => {
          if (!!vendorId && value && value.length > 3) {
            if (form.getFieldState(tabKey ? `${tabKey}.upc` : 'upc')?.modified) {
              findCatalogProductItem({
                variables: {
                  filter: {
                    gtin12Like: value
                  }
                }
              });
              setShowFoundItems(true);
            }
          }
        }}
      </OnChange>
    </>
  );
};
