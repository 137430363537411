import { getFrontendEnvironmentName, getGitCommit } from 'common/helpers/identificateEnvironment';
import { useEffect, useState } from 'react';

const BE_BUILD_DETAILS = 'BE_BUILD_DETAILS';
const BE_BUILD_UPDATE_EVENT = 'BE_BUILD_UPDATE_EVENT';

interface FEBuild {
  fe: string;
  feEnv: string;
}

interface BEBuild {
  be?: string;
  beEnv?: string;
}

type BuildDetailsType = FEBuild & BEBuild;

const getFEBuild = (): FEBuild => {
  return {
    fe: getGitCommit(),
    feEnv: getFrontendEnvironmentName()
  };
};

const IS_SENTRY_BE_CONTEXT_SET = 'IS_SENTRY_BE_CONTEXT_SET';

export const cleanIsSentryBEContextSet = () => {
  localStorage.removeItem(IS_SENTRY_BE_CONTEXT_SET);
};

export const isSentryBEContextSet = (): boolean => {
  return !!(JSON.parse(localStorage.getItem(IS_SENTRY_BE_CONTEXT_SET) || 'null') || false);
};

export const setSentryBEContextSet = () => {
  localStorage.setItem(IS_SENTRY_BE_CONTEXT_SET, 'true');
};

export const cleanBEBuildDetails = () => {
  localStorage.removeItem(BE_BUILD_DETAILS);
  window.dispatchEvent(new Event(BE_BUILD_UPDATE_EVENT));
};

export const getBEBuildDetails = (): BEBuild => {
  const parsedBEDetails = JSON.parse(localStorage.getItem(BE_BUILD_DETAILS) || '{}');
  return {
    be: parsedBEDetails?.be || '',
    beEnv: parsedBEDetails?.beEnv || ''
  };
};

export const setBEBuildDetails = (beBuild: BEBuild) => {
  const updateObject = {
    be: beBuild.be,
    beEnv: beBuild.beEnv
  };

  localStorage.setItem(BE_BUILD_DETAILS, JSON.stringify(updateObject));
  window.dispatchEvent(new Event(BE_BUILD_UPDATE_EVENT));
};

export const getBuildDetails = (): BuildDetailsType => {
  return {
    ...getFEBuild(),
    ...getBEBuildDetails()
  };
};

export const logBuild = (build: BuildDetailsType) => {
  console.log(`%cData Source: ${build.beEnv}`, 'color:blue; font-size: 18px');
  console.log(`%cfe: ${build.fe} , be: ${build.be}`, 'color:green; font-size: 12px');
};

export const parseXVersionString = (xVersion: string): BEBuild => {
  if (!xVersion) return { be: 'Unknown commit', beEnv: 'Unknown' };

  let be = xVersion.split(',')[0]?.split('=')[1];
  let beEnv = xVersion.split(',')[1]?.split('=')[1].replace('-', '');

  // currently the x-version header returns an empty string for the production env
  if (!beEnv) beEnv = be ? 'Production' : 'Unknown';

  // TODO: currently it happens for local image
  if (!be) be = 'Unknown commit';

  if (beEnv.length >= 2) beEnv = beEnv.charAt(0).toUpperCase() + beEnv.slice(1);

  return { be, beEnv };
};

// We might want to abstract it to a more generic hook later.
export const useBuildDetails = () => {
  const [storedValue, setStoredValue] = useState(getBuildDetails);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(getBuildDetails());
    };

    window.addEventListener(BE_BUILD_UPDATE_EVENT, handleStorageChange);
    return () => {
      window.removeEventListener(BE_BUILD_UPDATE_EVENT, handleStorageChange);
    };
  }, []);

  return storedValue;
};
