import React from 'react';
import { Tooltip as AntTooltip, TooltipProps } from 'ant5';
import { TooltipPlacement } from 'ant5/es/tooltip';

export function AlloyTooltip({ ...otherProps }: TooltipProps) {
  return <AntTooltip {...otherProps} />;
}

// Re-exporting types
export type { TooltipProps, TooltipPlacement };
