import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TradingPartnersAdvancedFiltersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TradingPartnerFilters>;
}>;


export type TradingPartnersAdvancedFiltersQuery = { __typename?: 'RootQueryType', tradingPartners?: { __typename?: 'TradingPartnerConnection', edges: Array<{ __typename?: 'TradingPartnerEdge', node?: { __typename?: 'TradingPartner', id: string, name?: string | null, externalId: string, deliveryType?: Types.DeliveryType | null, distributionCenters: Array<{ __typename?: 'DistributionCenter', id: string }> } | null }> } | null };


export const TradingPartnersAdvancedFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"tradingPartnersAdvancedFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"TradingPartnerFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tradingPartners"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"10000"}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"deliveryType"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<TradingPartnersAdvancedFiltersQuery, TradingPartnersAdvancedFiltersQueryVariables>;