import React from 'react';
import { useState } from 'react';
import { Col, Row, Switch, Table } from 'antd';
import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import { validateRequired } from 'components/Common/fields/Utils';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { useForm } from 'react-final-form';
import { ColumnsType } from 'antd/lib/table';
import s from './IntegrationsForm.module.scss';

interface IntegrationsFormProps {
  setUseConfig: (integrationName: string | undefined, value: boolean) => void;
  upserting: boolean;
}

const columns: ColumnsType<{
  title: string;
  name: string;
}> = [
  {
    title: 'Type',
    render: (_, record) => <span className={s.title_row}>{record.title}</span>
  },
  Table.EXPAND_COLUMN
];

export const IntegrationsForm = ({ setUseConfig, upserting }: IntegrationsFormProps) => {
  const form = useForm();

  const [integrations, setIntegration] = useState<{
    [name: string]: {
      description: string;
      show: boolean;
      children?: { [name: string]: { description: string; show: boolean } };
    };
  }>({
    sftpConfig: {
      description: 'SFTP Configuration',
      show: !!form.getState().values.useSftpConfig
    },
    s3Config: {
      description: 'S3 Configuration',
      show: !!form.getState().values.useS3Config
    }
  });

  const validateIfSelected = (integrationName: string, validator: any) => {
    return integrations[integrationName]?.show && !upserting ? validator : undefined;
  };

  const sftpConfigForm = (
    <Row gutter={8}>
      <Col span={24}>
        <FinalFormInput
          name="sftpConfigHost"
          title="Host"
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <FinalFormInput
          name="sftpConfigPort"
          title="Port"
          required
          type="number"
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <FinalFormInput
          name="sftpConfigUser"
          title="User"
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <PasswordField
          title="Password"
          fieldName="sftpConfigPassword"
          editFieldName="sftpConfigEditPassword"
          upserting={upserting}
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <FinalFormInput name="sftpConfigCustomizationPath" title="Customization Path" />
        <FinalFormInput name="sftpConfigCustomizationImagePath" title="Customization Image Path" />
        <FinalFormInput name="sftpConfigInventoryPath" title="Inventory Path" />
      </Col>
    </Row>
  );

  const s3ConfigForm = (
    <Row gutter={8}>
      <Col span={24}>
        <PasswordField
          title="AWS Key ID"
          data-testid="s3ConfigAwsKeyId"
          fieldName="s3ConfigAwsKeyId"
          editFieldName="s3ConfigEditAwsKeyId"
          upserting={upserting}
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <PasswordField
          title="AWS Secret"
          data-testid="s3ConfigAwsSecret"
          fieldName="s3ConfigAwsSecret"
          editFieldName="s3ConfigEditAwsSecret"
          upserting={upserting}
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <FinalFormInput
          name="s3ConfigS3BucketName"
          data-testid="s3ConfigS3BucketName"
          title="S3 Bucket Name"
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <FinalFormInput
          name="s3ConfigS3Region"
          data-testid="s3ConfigS3Region"
          title="S3 Region"
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <FinalFormInput
          name="s3ConfigCustomizationPath"
          data-testid="s3ConfigCustomizationPath"
          title="Customization Path"
        />
        <FinalFormInput
          name="s3ConfigCustomizationImagePath"
          data-testid="s3ConfigCustomizationImagePath"
          title="Customization Image Path"
        />
      </Col>
    </Row>
  );

  return (
    <>
      <Table
        showHeader={false}
        rowKey={(row) => row.name}
        columns={columns}
        dataSource={Object.keys(integrations).map((integration) => ({
          title: integrations[integration].description,
          name: integration
        }))}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => {
            switch (record.name) {
              case 'sftpConfig':
                return integrations['sftpConfig'].show ? sftpConfigForm : null;
              case 's3Config':
                return integrations['s3Config'].show ? s3ConfigForm : null;
              default:
                return null;
            }
          },
          expandedRowKeys: Object.keys(integrations).reduce<string[]>((result, integration) => {
            if (integrations[integration].show) result.push(integration);
            return result;
          }, []),
          expandIcon: ({ expanded, record }) => (
            <Switch
              checked={expanded}
              onChange={(checked) => {
                setIntegration({
                  ...integrations,
                  [record.name]: { ...integrations[record.name], show: checked }
                });
                setUseConfig(record.name, checked);
              }}
            />
          )
        }}
      />
    </>
  );
};
