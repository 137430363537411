import { UserContext } from 'context/userContext';
import React, { useContext } from 'react';
import { AlloyButton } from '../AlloyButton/AlloyButton';
import s from './UpdatedAt.module.scss';
import moment from 'moment';

const fromNow = (date?: string): string => {
  // sometime the server and client clocks aren't in sync so we can get 'in a few seconds'
  if (moment().diff(moment(date)) < 0) {
    return moment().add(-1, 'second').fromNow();
  }
  return moment(date).fromNow();
};

export function UpdatedAt({
  onClick,
  updatedAt,
  allowForNonAdminUsers
}: {
  onClick: React.MouseEventHandler<HTMLElement>;
  updatedAt?: string;
  allowForNonAdminUsers?: boolean;
}) {
  const { isAdmin } = useContext(UserContext);

  return isAdmin() || allowForNonAdminUsers ? (
    <div className={s.updated}>
      <AlloyButton type="link" className={s.button} onClick={onClick}>
        {updatedAt ? fromNow(updatedAt) : 'Unknown'}
      </AlloyButton>
    </div>
  ) : (
    <span>{updatedAt ? fromNow(updatedAt) : ''}</span>
  );
}
